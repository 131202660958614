import {useCallback, useState} from 'react';
import * as ImagePicker from 'expo-image-picker';
import {LOG} from 'utils';
import {i18n} from 'localize';
import { pick } from 'lodash';

type TProps =  {
  initial?: any,
  minHeight: number
  maxHeight: number,
  minWidth: number
  maxWidth: number,
  base64?: boolean

  quality?: number
  allowsEditing?: boolean,
  aspect?: [number, number],
}

type TPickerOptions = {
  allowsEditing?: boolean
}

export const useImagePicker = (params?: TProps) => {
  const [image, setImage] = useState<null | {uri: string}>(params?.initial || null);
  const [error, setError] = useState('');

  const pickerOptions = {
    allowsEditing: params?.allowsEditing || true,
    aspect: params?.aspect || undefined,
    //   aspect: [4, 3],
    quality: params?.quality || 0.5,
  }

  const isImageSuited = (imageSizes: {width: number, height: number}) => {
    if(params?.maxHeight 
      && params?.minHeight
      && params?.minWidth
      && params?.maxWidth){
        return imageSizes?.width > params?.minWidth &&
        imageSizes?.height > params?.minHeight &&
        imageSizes.width < params?.maxWidth &&
        imageSizes.height < params?.maxHeight;
    }
    return true
  }

  const openPicker = useCallback(async (options?:TPickerOptions) => {
    if(params?.base64){
      return openBase64Picker(options)
    }
    else{
      return openUriPicker(options)
    }
  }, [params?.base64]);

  const openBase64Picker = useCallback(async (options?:TPickerOptions) => {
    try {

      const opts = pick(options || {}, [
        'allowsEditing'
      ])

      const result = await ImagePicker.launchImageLibraryAsync({
        ...(pickerOptions || {}),
        //todo: think, delete one options (leave pickerOptions or options)
        ...opts,
        mediaTypes: ImagePicker.MediaTypeOptions.Images,
        base64: true,
      });
      // console.log('result',result)
      if (!!result?.assets?.[0]) {
        if (isImageSuited(result.assets[0])) {
          setImage({uri: 'data:image/jpeg;base64,' + result.assets[0].base64 });
          setError("")
        } else {
          setError(i18n.t('errors.imageSizeInvalid', {
            minWidth: params?.minWidth,
            minHeight: params?.minHeight,
            maxWidth: params?.maxWidth,
            maxHeight: params?.maxHeight,
            width: result.assets[0].width,
            height: result.assets[0].height,
          }));
        }
      }
    } catch (err) {
      LOG(err);
    }
  }, [])

  const openUriPicker = useCallback(async (options?: TPickerOptions) => {
    try {
      const opts = pick(options || {}, [
        'allowsEditing'
      ])

      const result = await ImagePicker.launchImageLibraryAsync({
        ...(pickerOptions || {}),
        ...opts,
        mediaTypes: ImagePicker.MediaTypeOptions.Images,
        allowsEditing: true,
      });

      if (!!result?.assets?.[0]) {
        if (isImageSuited(result?.assets?.[0])) {
          setImage({uri: result.assets[0].uri || "" });
          setError("")
        } else {
          setError(i18n.t('errors.imageSizeInvalid', {
            minWidth: params?.minWidth,
            minHeight: params?.minHeight,
            maxWidth: params?.maxWidth,
            maxHeight: params?.maxHeight,
            width: result.assets[0].width,
            height: result.assets[0].height,
          }));
        }
      }
    } catch (err) {
      LOG(err);
    }
  },[])

  return {
    error,
    image,
    openPicker,
  };
};
