import {ScrollView, TouchableOpacity} from 'react-native';
import React, {useCallback, useMemo, useState} from 'react';
import {useSelector} from 'react-redux';
import {selectCreatorCurrencyBalance, TStore} from 'store';
import {ActionResult, Button, ContentContainer, FormInputLabel, Text, View} from 'components';
import {Picker, PickerItem} from '../../../components/src/Picker/Picker';
import {map} from 'lodash';
import {WithdrawMethodContainer} from '../../../money-withdraw-methods/WithdrawMethod.container';
import {useDispatchAction, useNavigation, useParams} from 'hooks';
import {i18n} from 'localize';
import {EscreenNames} from 'navigation';
import {withdrawCreatorBalance} from '../../../money-withdraw/money-withdraw.saga';
import { allPaymentCurrencies } from '../../../payment/payment.constants';
import { selectCardWithdrawMethods } from '../../../money-withdraw-methods/monet-withdraw-methods.selectors';
import { WithdrawalInfo } from '../../../money-withdraw/WithdrawalInfo.component';
import { ActionResultDefaultRenders } from '../../../components/src/ActionResult/ActionResultDefaultRenders';

export const FiatWithdrawalScreen = ({}) => {

  const {currencyName:initialCurrencyName}: {currencyName: string} = useParams();

  const {navigate} = useNavigation();

  const [currencyName, setCurrency] = useState(initialCurrencyName || '');
  const [withdrawMethodId, setWithdrawalMethodId] = useState<null | string>(
    null,
  );
  
  const balance = useSelector((state: TStore) =>
    selectCreatorCurrencyBalance(state, currencyName),
  );

  const cardWithdrawMethods = useSelector(selectCardWithdrawMethods);
  const cardWithdrawMethodsIds = useMemo(() => {
    return map(cardWithdrawMethods, w => w?.id);
  }, [cardWithdrawMethods]);

  const {
    error,
    isLoading,
    submitAction
  } = useDispatchAction(withdrawCreatorBalance, {
    data: {
      withdrawMethodId : withdrawMethodId || "",
      currencyName,
    },
    //todo: create system which will be detect screen with navigation needed
    onSuccess: () => navigate(EscreenNames.Revenue)
  });

  const renderItem = useCallback(({item}: {item:typeof cardWithdrawMethodsIds[0]}) => (
        <>
          <WithdrawMethodContainer
            id={item}
            isChoosen={withdrawMethodId === item}
            onChoose={() => {
              setWithdrawalMethodId(item);
            }}
          />
          <View variant='verticalListSeparator'/>
        </>

    ),
    [withdrawMethodId],
  )

  return (
    <ContentContainer>
      <View variant='screenContainer'>
      <ScrollView>
        <View variant="sectionContainer">
          <FormInputLabel 
            label={i18n.t('fiatWithdrawalScreen.currencyPickerLabel')}
            requiredField
          />
          <Picker
            data={allPaymentCurrencies}
            selectedValue={currencyName}
            onValueChange={item => {
              setCurrency(item?.value);
            }}
            renderItem={({item}) => (
              <PickerItem label={item?.label} value={item?.value} />
            )}
          />
        </View>

        <View variant="sectionContainer">
          <Text variant="inputLabel">
            {i18n.t('fiatWithdrawalScreen.fiatWithdrawMethodsPickerLabel')}
          </Text>
          {cardWithdrawMethodsIds.map(item => renderItem({item}))}
          {!cardWithdrawMethodsIds?.length && (
              <TouchableOpacity
                onPress={() => {
                  navigate(EscreenNames.CreatorPaymentsEdit);
                }}>
                <Text bold variant="inputLabel">
                  {i18n.t(
                    'fiatWithdrawalScreen.fiatWithdrawMethodsEmptyListTitle',
                  )}
                </Text>
              </TouchableOpacity>
          )}
        </View>

        {!!withdrawMethodId && (
          <View variant='sectionContainer'>
            <Button
              title={i18n.t('cryptoWithdrawalScreen.withdrawBtnTitle')}
              onPress={() => submitAction()}
            />
            <ActionResult 
              error={error}
              isLoading={isLoading}
              {...ActionResultDefaultRenders}
            />
          </View>
        )}
        
        <View variant="sectionContainer">
          <WithdrawalInfo
            availableBalance={{
              currencyName,
              amount: balance?.amount || 0,
            }}
          />
        </View>
      </ScrollView>
      </View>
    </ContentContainer>
  );
};
