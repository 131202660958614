import 'react-native-gesture-handler';
import React, {useEffect, useState} from 'react';
import {Provider, useSelector, useDispatch} from 'react-redux';
import {PersistGate} from 'redux-persist/integration/react';
import {RootNavigator} from 'navigation';
import {useFonts} from 'expo-font';

import {store, persistor, selectUser} from 'store';
import {NetworkService} from '../../services/src/NetworkService';
import {i18n, availableLocales} from 'localize';
import {getUserLocales} from 'utils';
import {ActivityIndicator, StatusBar} from 'react-native';
import moment from 'moment';
import { CrashlyticsService } from '../../services';

// CrashlyticsService.init()
/**
 * we do imports because other locales
 * are removed during webpack
 */
import 'moment/locale/en-gb.js';
import 'moment/locale/es-us.js';
import 'moment/locale/uk.js';
import {
  NetworkAuthorizationService
} from '../../services';
import {
  ActionSheetProvider
} from '@expo/react-native-action-sheet';
import { useActiveLanguage } from 'hooks';
import { colors } from '@sponsor/assets';

const LanguageHelper = () => {

  const activeLocale = useActiveLanguage()
  i18n.locale = activeLocale;
  moment.locale(activeLocale);

  return null;
};

const NetworkHelper = () => {
  const dispatch = useDispatch();
  NetworkAuthorizationService.connectToStore(dispatch);
  return null;
};

const AppContainer = () => {
  const [isNetworkReady, setIsNetworkReady] = useState(false);

  useEffect(() => {
    NetworkService.onReady(() => {
      setIsNetworkReady(true);
    });
  }, []);

  const [fontsLoaded] = useFonts({
    icomoon: require('../../assets/src/icons/icomoon.ttf'),
    'GolosText': require('../../assets/src/GolosText-VariableFont_wght.ttf'),
    'GolosTextBold': require('../../assets/src/GolosText-Bold.ttf'),
  });

  if (!fontsLoaded && !isNetworkReady) {
    return <ActivityIndicator />;
  }
  
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <NetworkHelper />
        <StatusBar backgroundColor={colors.GREEN_OFFICIAL}/>
        <LanguageHelper />
        <ActionSheetProvider>
          <RootNavigator />
        </ActionSheetProvider>
      </PersistGate>
    </Provider>
  );
};

export {
  AppContainer
}