import { colors } from "@sponsor/assets";
import { Platform, StyleSheet } from "react-native";

export const pickerStyles = StyleSheet.create({
    container: {
      backgroundColor: 'white',
    },
    disabledContainer: {
      backgroundColor: colors.CONCRETE,
    },
    contentContainer:{
        height: Platform.OS !== 'android' ? 32 : undefined,
        padding:0,
        borderWidth: 0,
        //@ts-ignore
        outline: 0,
        ...Platform.select({
          web: {backgroundColor: 'transparent'},
          default: {},
        })
  },
  text: {
    fontSize: 16,
    color: 'black',
  }
});
  