import {useCallback} from 'react';
import {ApiService} from 'services';
import {TComment} from 'types';
import {LOG} from 'utils';

export const usePostCommentCRUDOperations = () => {
  const fetchMoreComments = useCallback(
    // todo: add pagination
    async (_, {postId}) => {
      // TODO: add pagination, return not array;
      let comments: TComment[] = [];
      try {
        const {data} = await ApiService.getPostComments(postId);
        comments = data?.results;
      } catch (err) {
        LOG(err);
      } finally {
        return comments;
      }
    },
    [],
  );

  // TODO: move to hook
  const submitComment = useCallback(async (text, meta) => {
    try {
      const {data} = await ApiService.postAddNewComment({
        text,
        post: meta?.postId,
      });
      return data;
    } catch (err) {
      LOG(err);
    }
  }, []);

  const deleteComment = useCallback(async (comment: TComment) => {
    try {
      await ApiService.deleteComment(comment?.id);
    } catch (err) {
      LOG(err);
    }
  }, []);

  return {
    submitComment,
    fetchMoreComments,
    deleteComment,
  };
};
