import {ContentContainer, View, Text} from 'components';
import React from 'react';
import {ScrollView} from 'react-native';
import {i18n} from 'localize';

export const TermOfUse = () => {
  return (
    <ContentContainer>
      <ScrollView>
        <View style={{alignItems: 'center', padding: 15}}>
          <Text variant="sectionTitle">
            {i18n.t('termOfUse.termOfUseTitle')}
          </Text>
          <Text variant="thirdTitle">
            {i18n.t('termOfUse.aboutCompany')}
          </Text>

          <Text variant="sectionTitle">
            {i18n.t('termOfUse.liabilityTitle')}
          </Text>
          <Text variant="thirdTitle">{i18n.t('termOfUse.liability')}</Text>

          <Text variant="sectionTitle">{i18n.t('termOfUse.rateTitle')}</Text>
          <Text variant="thirdTitle">{i18n.t('termOfUse.rate')}</Text>

          <Text variant="sectionTitle">
            {i18n.t('termOfUse.aboutCreatorTitle')}
          </Text>
          <Text variant="thirdTitle">{i18n.t('termOfUse.aboutCreator')}</Text>

          <Text variant="sectionTitle">{i18n.t('termOfUse.sponsorTitle')}</Text>
          <Text variant="thirdTitle">{i18n.t('termOfUse.aboutSponsors')}</Text>

          <Text variant="sectionTitle">
            {i18n.t('termOfUse.yourAccountTitle')}
          </Text>
          <Text variant="thirdTitle">{i18n.t('termOfUse.yourAccount')}</Text>

          <Text variant="sectionTitle">
            {i18n.t('termOfUse.warantlyDisclaimerTitle')}
          </Text>
          <Text variant="thirdTitle">
            {i18n.t('termOfUse.warantlyDisclaimer')}
          </Text>

          <Text variant="sectionTitle">
            {i18n.t('termOfUse.accountDeletionTitle')}
          </Text>
          <Text variant="thirdTitle">
            {i18n.t('termOfUse.accountDeletion')}
          </Text>

          <Text variant="thirdTitle">{i18n.t('termOfUse.aboutCreator')}</Text>

          <Text variant="sectionTitle">
            {i18n.t('termOfUse.paymentsTitle')}
          </Text>
          <Text variant="thirdTitle">{i18n.t('termOfUse.payments')}</Text>
        </View>
      </ScrollView>
    </ContentContainer>
  );
};
