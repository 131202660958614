export const colors = {
  GREEN_OFFICIAL: '#15c76d',
  GREEN_DARK: '#1d9128',
  GREEN_LIGHT: '#23B574',
  DUSTY_GRAY: '#daded9',
  GRAY: '#475467',
  GRAY_LIGHT: '#DEE4E7',
  ORANGE: '#ffb01f',
  PURPLE: '#b81dcc',
  BLACK: 'black',
  RED: 'red',
  CONCRETE: '#F2F2F2',
};
