import {Row, View, Text} from 'components';
import React, {useCallback, useMemo} from 'react';
import {forEach, isNumber} from 'lodash';
import {i18n} from 'localize';
import { StyleSheet } from 'react-native';

type TProps = {
  nbExclusivePosts: {
    [key in string]: number;
  };
};

export const ExclusivePostsInfo: React.FC<TProps> = ({nbExclusivePosts}) => {
  const allExclusivePostAmount = useMemo(() => {
    let amount = 0;
    forEach(nbExclusivePosts, nb => {
      if (isNumber(nb)) {
        amount += nb;
      }
    });
    return amount;
  }, [nbExclusivePosts]);

  const renderExclusivePostsPerCategory = useCallback(() => {
    const postsPerCategory: JSX.Element[] = [];
    forEach(nbExclusivePosts, (amount, contentType) => {
      if (amount > 0) {
        postsPerCategory.push(
          <Text style={{fontSize: 22}} center>
            <Text variant="secondaryTitle">{amount}</Text>
            {`\n${i18n.t('contentTypes.' + contentType)}`}
          </Text>,
        );
      }
    });
    return (
      <Row columnCenter rowCenter containsSimilarItems>
        {postsPerCategory}
      </Row>
    );
  }, [nbExclusivePosts]);

  return (
    <>
      {allExclusivePostAmount > 0 && (
        <View variant="sectionContainer" style={styles.container} bordered>
          <Text center variant="secondaryTitle">
            {`${i18n.t('creatorPage.benefitsTitles')} ${i18n.t(
              'creatorPage.exclusivePosts',
            )}\n${i18n.t(
              'creatorPage.allExclusivePosts',
            )}: ${allExclusivePostAmount}`}
          </Text>
          {renderExclusivePostsPerCategory()}
        </View>
      )}
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    paddingBottom: 12
  }
})