import {FlatList, ActivityIndicator, TouchableOpacity, StyleSheet} from 'react-native';
import React, {useState} from 'react';
import {useAsync} from 'react-use';
import { toUpper } from 'lodash'
import {Button, Text, View} from 'components';
import {colors} from '@sponsor/assets';
import {CryptoAddressQRCode} from './CryptoAddressQRCode.component';
import {i18n} from 'localize';
import {TCoinInfo} from '../crypto-payment/crypto-payment.types';
import {CryptoCard} from '../crypto-payment/CryptoCard.component';
import {
  getAvailableCoinsForCurrency,
  postGetPaymentInfo,
} from '../crypto-payment/crypto-payment.api';

type TProps = {
  currencyName: string;
  tierId: string;
};

export const SubscriptionCryptoPaymentScreen: React.FC<TProps> = ({
  currencyName,
  tierId,
}) => {
  const [chosenCoin,  setChosenCoin] = useState<TCoinInfo>();
  const [paymentStep, setPaymentStep] = useState<'choosing' | 'address_dispay'>(
    'choosing',
  );

  const addressRetrivingState = useAsync(async () => {
    if (paymentStep == 'address_dispay' && !!chosenCoin?.code) {
      const response = await postGetPaymentInfo({
        currency: chosenCoin?.code,
        tierId,
      });
      return response?.data;
    }
  }, [chosenCoin, paymentStep]);

  const state = useAsync(async () => {
    const response = await getAvailableCoinsForCurrency({currency:currencyName});
    return response?.data;
  }, []);


  if(String(addressRetrivingState.value?.errorCode) === '404'){
    return (
      <Text variant='formHint'>
          {i18n.t('subscriptionCryptoPaymentForm.disabledCryptoPaymentMessage')}
      </Text>
    )
  }

  return (
    <View>
      {state.loading && <ActivityIndicator />}
      {/* <Text>
                {i18n.t()}
            </Text> */}
      {paymentStep == 'address_dispay' && !!chosenCoin && (
        <View variant='sectionContainer'>
          <View variant='sectionContainer'>
            <CryptoCard
              name={chosenCoin.name}
              icon={{uri: chosenCoin.icons?.png_2x}}
            />
            <Button
              containerStyle={{marginTop: 10}}
              title={i18n.t('subscriptionCryptoPaymentForm.chooseAnotherCryptoBtnTitle',)}
              onPress={() => setPaymentStep('choosing')}
            />
          </View>
          {addressRetrivingState?.loading && <ActivityIndicator />}
          {!!addressRetrivingState.value?.address && (
            <View variant="sectionContainer">
              <CryptoAddressQRCode
                address={addressRetrivingState.value?.address || ''}
                memo={addressRetrivingState.value?.memo || ''}
              />
              {addressRetrivingState?.value?.amount && (
                <><Text style={styles.feeSectionTitle}>
                  {`${i18n.t('subscriptionCryptoPaymentForm.amountToTransfer')} ${toUpper(
                    addressRetrivingState?.value?.currency,
                  )}`}
                </Text>
                <Text bold style={styles.amountText}>{addressRetrivingState?.value?.amount}</Text>
              </>)}

              <Text color={colors.GREEN_OFFICIAL} variant='formHint'>
                {i18n.t("subscriptionCryptoPaymentForm.cryptoPaymentFinishMessage")}
              </Text>

            </View>
          )}
          <Text variant="inputError">
            {JSON.stringify(addressRetrivingState?.error)}
          </Text>
        </View>
      )}

      {paymentStep == 'choosing' && (
        <>
          {!!chosenCoin && (
            <View style={{marginVertical: 15}}>
              <CryptoCard
                name={chosenCoin.name}
                icon={{uri: chosenCoin.icons?.png_2x}}
              />
              <Button
                containerStyle={{marginTop: 10}}
                title={i18n.t('subscriptionCryptoPaymentForm.next')}
                onPress={() => setPaymentStep('address_dispay')}
              />
            </View>
          )}
          <FlatList
            data={state.value}
            style={{marginTop: 50}}
            ItemSeparatorComponent={() => (
              <View variant="verticalListSeparator" />
            )}
            renderItem={({item}) => (
              <TouchableOpacity
                style={{
                  borderRadius: 10,
                  padding: 10,
                  borderColor: colors.GREEN_OFFICIAL,
                  borderWidth: chosenCoin?.code === item.code ? 1 : 0,
                }}
                onPress={() => setChosenCoin(item)}
              >
                  <CryptoCard 
                    name={item.name} 
                    icon={{uri: item.icons?.png_2x}} 
                  />
              </TouchableOpacity>
            )}
          />
        </>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  amountText: {
    marginTop: 5,
    textTransform: 'uppercase',
  },
  feeSectionTitle: {
    marginTop: 15
  }
});
