import {firebase} from '../../libs/src/firebase/firebase';

import {LOG} from 'utils';

class _CrashlyticsService {

  init = () => {
  }
  
  recordError = (error: any): void => {
    try {
      if (typeof error === 'string') {
        error = new Error(error);
      }
      firebase.crashlytics().recordError(error);
    } catch (err) {
      LOG(err);
    }
  };

  setCrashlyticsUserId = async (userId: string): Promise<void> => {
    try {
      await firebase.crashlytics().setUserId(userId);
    } catch (err) {
      LOG(err);
    }
  };

  crashApp = (): void => {
    try {
      firebase.crashlytics().crash();
    } catch (err) {
      LOG(err);
    }
  };

  setCrashlyticsAttributes = async (params: any): Promise<void> => {
    try {
      if (params) {
        await firebase.crashlytics().setAttributes(params);
      }
    } catch (err) {
      LOG(err);
    }
  };
}

export const CrashlyticsService = new _CrashlyticsService();
