import {FlatList, TouchableOpacity} from 'react-native';
import React, {useState} from 'react';
import {Button, ContentContainer, ScrollViewOnlyOnMobile, Text, TierBase, View} from 'components';
import {useSelector} from 'react-redux';
import {selectTiers, TStore} from 'store';
import {TiersList} from '../tiers/TiersList.component';
import {selectTierTranslations} from './tiersTranslations.selectors';
import {TTier} from 'types';
import {TierTranslationEditPreviewContainer} from './TierTranslationEditPreview.container';
import {i18n} from 'localize';
import {useNavigation} from 'hooks';
import {EscreenNames} from 'navigation';
import {colors} from '@sponsor/assets';
import { DEVICE_WIDTH, TIER_WIDTH } from '@sponsor/constants';
import { isMobile } from 'utils';

export const TiersTranslationsScreen = () => {
  const [selectedTier, setSeletedTier] = useState<null | TTier>(null);

  const tiers = useSelector(selectTiers);
  const tierTranslations = useSelector((state: TStore) =>
    selectTierTranslations(state, selectedTier?.id || ''),
  );

  const {navigate} = useNavigation();

  const getSelectedTierStyles = (item:TTier) => {
    if(selectedTier?.id === item?.id) return {
        borderColor: colors.GREEN_OFFICIAL,
        borderWidth: 3,
    }
    return {}
  }

  return (
    <ScrollViewOnlyOnMobile>
      <View variant="screenContainer">
        <Text center variant='sectionTitle'>
          {i18n.t('creatorTiersTranslationScreen.screenTitle')}
        </Text>
        <TiersList
          data={tiers}
          //todo: replace with component
          title={i18n.t('creatorTiersTranslationScreen.chooseTier')}
          subTitle={i18n.t('creatorTiersTranslationScreen.chooseTierListSubtitle')}
          renderItem={({item}) => {
            return (
              <TouchableOpacity
                style={{flex:1}}
                onPress={() => {
                  setSeletedTier(item);
                }}
                >
                <TierBase
                  {...item}
                  containerStyle={{
                    ...getSelectedTierStyles(item),
                    marginHorizontal: 10, 
                    // view padding + marginHorizontal = 50
                    width: isMobile ? DEVICE_WIDTH - 50: TIER_WIDTH, 
                  }}
                />
              </TouchableOpacity>
            );
          }}
        />
        <ContentContainer
          
        >
          {!!selectedTier && (
            <FlatList
              data={tierTranslations}
              ItemSeparatorComponent={() => (
                <View variant="verticalListSeparator" />
              )}
              ListEmptyComponent={() => (
                <View variant="sectionContainer">
                  <Text variant="thirdTitle">
                    {i18n.t('creatorTiersTranslationScreen.noTranslation')}
                  </Text>
                </View>
              )}
              ListHeaderComponent={() => (
                <View variant="sectionContainer">
                  <Button
                    title={`${i18n.t(
                      'creatorTiersTranslationScreen.addTranslationButtonTitle',
                    )}`}
                    onPress={() => {
                      navigate(EscreenNames.EditTierTranslation, {
                        parentId: selectedTier?.id,
                      });
                    }}
                  />
                </View>
              )}
              renderItem={({item}) => (
                <TierTranslationEditPreviewContainer
                  id={item?.id}
                  parentId={selectedTier?.id}
                />
              )}
            />
          )}
        </ContentContainer>
      </View>
    </ScrollViewOnlyOnMobile>
  );
};
