
import { ActionResult, Button, ContentContainer, Text, View } from 'components'
import { ActionResultDefaultRenders } from 'components/src/ActionResult/ActionResultDefaultRenders'
import { useDispatchAction } from 'hooks'
import { i18n } from 'localize'
import React from 'react'
import { StyleSheet } from 'react-native' 
import { useSelector } from 'react-redux'
import { selectCreatorBasicInfo, updateCreatorStart } from 'store'
import { ECreatorSettings } from 'types'

export const AdsPlatformAccess = () => {

    const creator = useSelector(selectCreatorBasicInfo)

    const {
        isLoading,
        error,
        submitAction
    } = useDispatchAction(updateCreatorStart)

    const isSearchConnected = creator.settings[ECreatorSettings.connectAdvertiserSearch] === true

    return (
        <View variant='screenContainer'>
            <Text center variant='creatorPageSectionTitle'>
                {i18n.t('adsPlatformAccessScreen.searchTitle')}
            </Text>
            <Text 
                variant='postDescription'
                style={{marginTop: 30}}
            >
                {i18n.t('adsPlatformAccessScreen.searchDesription')}
            </Text>
            <Button 
                primary
                containerStyle={{marginTop: 30}}
                title={i18n.t('adsPlatformAccessScreen.connectBtnTitle')}
                onPress={() => submitAction({
                    settings: {
                        [ECreatorSettings.connectAdvertiserSearch]: true
                    }
                })}
                disabled={
                    isSearchConnected || isLoading
                }
            />
            {isSearchConnected && (
                <Text variant='postDescription'>
                    {i18n.t('adsPlatformAccessScreen.successMessage')}
                </Text>
            )}
            <ActionResult 
                isLoading={isLoading}
                error={error}
                {...ActionResultDefaultRenders}
            />
        </View>
    )
}

const styles = StyleSheet.create({
  
})

