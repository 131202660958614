import React, {useMemo} from 'react';
import {Formik} from 'formik';
import {ECreatorWithdrawMethodsTypes} from '../money-withdraw-methods/money-withdraw-methods.types';
import {Button, FormInput, KeyboardAvoidingView, Text, View} from 'components';
import {i18n} from 'localize';
import {Picker, TDataItem} from '../components/src/Picker/Picker';
import {map} from 'lodash';
import {allPaymentCurrencies} from '../payment/payment.constants';
import {getMerchantEntrepreneurWithdrawMethodSchema} from '../money-withdraw-methods/merchantWithdrawMethodValidation';
import {useSelector} from 'react-redux';
import {selectCreatorBasicInfo, TStore} from 'store';
import {selectWithdrawMethodsById} from '../money-withdraw-methods/monet-withdraw-methods.selectors';
import {EditMerchantFinancialFormContainer} from './EditMerchantFinancialForm.container';

type TProps = {
  onSubmit: (values: any) => void;
  id?: string;
};

export const EditMerchantForm: React.FC<TProps> = ({id, onSubmit}) => {

  const merchant = useSelector((state: TStore) =>
    selectWithdrawMethodsById(state, id || ''),
  );

  //todo: move to constant
  const pickerFiatCurrencies = useMemo(() => {
    return map(allPaymentCurrencies, p => ({
      label: p.label,
      value: p.value,
    }));
  }, []);

  const creator = useSelector(selectCreatorBasicInfo);

  //todo: move to selector
  const hasIban = merchant?.type == 'merchant_entrepreneur' 
  && merchant?.financialConfig?.iban 

  return (
    <KeyboardAvoidingView>
      <Formik
        initialValues={{
          type: ECreatorWithdrawMethodsTypes.merchant_entrepreneur,
          // companyName:"",
          country: creator?.country || 'UA',
          edrpou:
            (merchant?.type ==
              ECreatorWithdrawMethodsTypes.merchant_entrepreneur &&
              merchant?.edrpou) ||
            '',
          phone:
            (merchant?.type ==
              ECreatorWithdrawMethodsTypes.merchant_entrepreneur &&
              merchant?.phone) ||
            '',
          currency: {
            code:
            //todo: check code and name, are they valid ???
              (merchant?.type ==
                ECreatorWithdrawMethodsTypes.merchant_entrepreneur &&
                merchant?.currency?.name) ||
              '',
            name: '',
          },
        }}
        validationSchema={getMerchantEntrepreneurWithdrawMethodSchema()}
        onSubmit={values =>
          onSubmit({
            ...values,
            currency: values?.currency?.code
              ? values?.currency?.code
              : values?.currency,
          })
        }>
        {({values, errors, handleSubmit, handleChange}) => (
          <View>
            {/*
                    todo: create component for the form picker
                    with ability to search
                */}
            <View variant="sectionContainer">
              <Text variant="inputLabel">
                {i18n.t('editWithdrawMethodScreen.currencyInputLabel')}
              </Text>
              <Picker
                data={pickerFiatCurrencies}
                enabled={!merchant?.id}
                selectedValue={values?.currency.code}
                onValueChange={(item: TDataItem) => {
                  console.log('new currency', item);
                  if (item) {
                    handleChange('currency.code')(item?.value);
                    handleChange('currency.name')(item?.label);
                  }
                }}
              />
            </View>

            {/* <FormInput
                    multiline
                    numberOfLines={2}
                    label={i18n.t('editWithdrawMethodScreen.companyNameInputLabel')}
                    value={values?.companyName}
                    onChangeText={handleChange('companyName')}
                    error={errors?.companyName}
                /> */}
            <FormInput
              multiline
              numberOfLines={1}
              label={i18n.t('editWithdrawMethodScreen.edrpouInputLabel')}
              value={values?.edrpou}
              disabled={!!merchant}
              onChangeText={handleChange('edrpou')}
              error={errors?.edrpou}
            />
            <FormInput
              multiline
              numberOfLines={1}
              disabled={!!merchant}
              label={i18n.t('editWithdrawMethodScreen.phoneInputLabel')}
              value={values?.phone}
              // placeholder={i18n.t('editWithdrawMethodScreen.cryptoWalletMemoInputPlaceholder')}
              onChangeText={handleChange('phone')}
              error={errors?.phone}
            />
            <Button
              title={i18n.t('submitEdit')}
              onPress={handleSubmit}
              primary
              disabled={!!merchant}
              containerStyle={{paddingVertical: 25}}
            />
          </View>
        )}
      </Formik>

      {!!merchant && <EditMerchantFinancialFormContainer id={id} />}

      {hasIban && merchant.status === 'not_active' && (
          <View variant='sectionContainer'>
            <Text variant='thirdTitle'>
              {i18n.t('editWithdrawMethodScreen.activateYourCompanyMessage')}
            </Text>
          </View>

        )}
    </KeyboardAvoidingView>
  );
};
