import { Text, View} from 'components';
import { SectionList} from 'react-native';
import React, {useMemo} from 'react';
import { useSelector} from 'react-redux';
import { map, reduce, values} from 'lodash';
import { selectActiveSubscriptions, selectFollowings} from 'store';
import { i18n} from 'localize';
import { SubscribedCreatorCard } from '../../../containers/SubscribedCreatorCard.container';

export const CreatorsList = () => {
  const subscriptions = useSelector(selectActiveSubscriptions);
  const followings = useSelector(selectFollowings);

  const supportedCreators = useMemo(() => {
    return values(
      reduce(
        subscriptions,
        (r, s) => {
          if(!s?.tier?.creator){
            return r
          }

          r[s?.tier?.creator] = {
            ...s,
            amount: (r[s?.tier?.creator]?.amount || 0) + s?.price,
          };
          return r;
        },
        {} as any,
      ),
    );
  }, [subscriptions]);
  
  const sections = useMemo(() => {
    return [
      {
        title: i18n.t('postsScreen.supportingListTitle'),
        data: map(supportedCreators, s => {
          return {
            creatorTitle: s?.tier?.creatorTitle,
            creatorAvatarUri: s?.tier?.creatorAvatarUri,
            creatorPageURL: s?.tier?.creatorPageURL,
            price: s.amount,
            //todo: double check 
            currency: s?.tier?.currency,
          }
        })
      },
      {
        title: i18n.t('postsScreen.followingListTitle'),
        data: map(followings, f => ({
          creatorTitle: f?.title,
          creatorAvatarUri: f?.avatarUri,
          price: null,
          currency: null,
          creatorPageURL: f?.pageURL,
        })),
      },
    ];
  }, [followings, supportedCreators]);

  return (
    <View>
      <SectionList
        sections={sections}
        stickySectionHeadersEnabled={false}
        // keyExtractor={(item, index) => item?.id || index}
        renderItem={({item}) => (
          <SubscribedCreatorCard
            creatorTitle={item?.creatorTitle}
            creatorAvatarUri={item?.creatorAvatarUri}
            creatorPageURL={item?.creatorPageURL}
            price={item?.price}
            currency={item?.currency}
          />
        )}
        renderSectionFooter={({section}) =>
          section?.data?.length == 0 ? (
            <Text center variant="thirdTitle">
              {i18n.t('listEmptyTitle')}
            </Text>
          ) : null
        }
        ItemSeparatorComponent={() => <View style={{marginTop: 5}} />}
        renderSectionHeader={({section: {title}}) => (
          <View variant='sectionContainer'>
            <Text variant="secondaryTitle">{title}</Text>
          </View>
        )}
      />
    </View>
  );
};
