import {StyleSheet, Text, View} from 'react-native';
import React from 'react';
import {i18n} from 'localize';
import {toUpper} from 'lodash';

type TProps = {
  availableBalance: {
    currencyName: string;
    amount: number;
  };
  networkFeeRange?: {
    min?: number;
    max?: number;
    currencyName: string;
  };
  minimalWithdraw?: {
    currencyName: string;
    amount: number;
  };
  //todo: add limits for withdrawals ???
};

export const WithdrawalInfo: React.FC<TProps> = ({
  minimalWithdraw,
  availableBalance,
  networkFeeRange,
}) => {

  return (
    <View>
      <Text 
        testID={"available-balance-text"}
        style={styles.feeSectionTitle}
      >
        {`${i18n.t('cryptoWithdrawalScreen.coinBalanceTitle')} ${toUpper(availableBalance?.currencyName)}`}
      </Text>
      <Text style={styles.amountText}>{availableBalance?.amount}</Text>

      {!!minimalWithdraw && minimalWithdraw?.amount && (
        <>
            <Text style={styles.feeSectionTitle}>
              {`${i18n.t('cryptoWithdrawalScreen.minimalWithdrawWarningTitle')} ${toUpper(availableBalance?.currencyName)}`}
            </Text>
            <Text style={styles.amountText}>{minimalWithdraw?.amount}</Text>
        </>
      )}

      {!!networkFeeRange && networkFeeRange?.min && networkFeeRange?.max && (
        <>
        <Text style={styles.feeSectionTitle}>
          {`${i18n.t('cryptoWithdrawalScreen.withdrawFeeRangeTitle')} ${toUpper(availableBalance?.currencyName)}`}
        </Text>
        <Text 
          testID='withdraw-withdraw-network-fee-text'
          style={styles.amountText}
        >
          {`${networkFeeRange.min || 0} - ${networkFeeRange.max || 0}`}
        </Text>
        </>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  amountText: {
    marginTop: 5,
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
  feeSectionTitle: {
    marginTop: 15
  }
});
