import React from 'react';
import {View, StyleSheet} from 'react-native';
import { Image } from '../Image';

type TProps = {
  uri?: string;
  size?: number;
};

export const Avatar: React.FC<TProps> = ({uri, size = 50}) => {
  if(uri?.includes('localhost') && !uri?.includes('http://')){
    uri = 'http://' + uri
  }
  return (
    <View
      style={[
        styles.container,
        !!size &&
          size > 0 && {width: size, height: size, borderRadius: size / 2},
      ]}>
      <Image
        // defaultSource={{uri: LOGO_TRANSPARENT_URI}}
        style={[styles.image,
          !!size &&
          size > 0 && {width: size, height: size, borderRadius: size / 2},
        ]}
        source={{uri}}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    width: 55,
    height: 55,
    borderRadius: 50,
    borderColor: 'gray',
    //todo: think about avatar borderWidth
    borderWidth: 0.5,
  },
  image: {
    resizeMode: 'cover',
    flex: 1,
    borderWidth: 0.2,
    borderRadius: 50,
  },
});
