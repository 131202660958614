import { Button, Text } from 'components'
import { i18n } from 'localize'
import React from 'react'

type TPostListFooterProps = {
    isLoading: boolean
    isEmpty: boolean
    hasMorePosts: boolean
    error: any,
    onFetch: () => void,
}

export const PostListFooter:React.FC<TPostListFooterProps> = ({
    isLoading,
    isEmpty,
    hasMorePosts,
    error,
    onFetch
}) => {
  return  (
    <>
      {isLoading ? (
          //we already have pullRefresh indicator
          null
        ) : isEmpty ? (
          //todo: add tests for no more posts
          hasMorePosts ? (
            <>
            {!error && <Button
              title={i18n.t('postsScreen.downloadMorePosts')}
              onPress={onFetch}
            />}
            <Text center variant='inputError'>
              {error}
            </Text>
            </>
          ) : null
        ) : null
      }
    </>
  )
}

