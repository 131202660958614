import { Storage } from "redux-persist"
import { mmkvStorage } from "../mmkv"
import {persistStore, persistReducer} from 'redux-persist';
import {configureStore} from '../configureStore';
import { rootReducer } from "../rootReducer";


export const persistStorage: Storage = {
    setItem: (key, value) => {
      mmkvStorage.set(key, value)
      return Promise.resolve(true)
    },
    getItem: (key:string) => {
      const value = mmkvStorage.getString(key)
      return Promise.resolve(value || null)
    },
    removeItem: (key) => {
      mmkvStorage.delete(key)
      return Promise.resolve()
    },
}

const persistConfig = {
    key: 'root',
    storage: persistStorage,
    blacklist: ['posts.posts'],
};

const persistedReducers = persistReducer(persistConfig, rootReducer);

const {store} = configureStore(persistedReducers);
const persistor = persistStore(store);

export {store, persistor, persistedReducers, configureStore};