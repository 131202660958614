import React, {ReactElement} from 'react';
import {usePrevious} from 'react-use';

type TProps = {
  isLoading: boolean;
  error?: string;
  renderError?: (error: any) => ReactElement;
  renderLoading?: () => ReactElement;
  renderSuccessMessage?: () => ReactElement;
};

export const ActionResult: React.FC<TProps> = ({
  isLoading,
  error,
  renderError,
  renderLoading,
  renderSuccessMessage,
}) => {
  const prevIsLoading = usePrevious(isLoading);

  if (isLoading === true && !!renderLoading) {
    return renderLoading?.();
  }

  if (!isLoading && !!error && !!renderError) {
    return renderError?.(error) || null;
  }

  //prevIsLoading to avoid first render when action wasn't dispatched
  if (!error && !isLoading && prevIsLoading === true && !!renderSuccessMessage) {
    console.log('renderSuccessMessage')
    return renderSuccessMessage?.();
  }
  console.log('null')
  return null;
};