import {takeEvery} from 'redux-saga/effects';
//todo: fix absolute path here
import {fetchSaga} from '../store/src/common';
import {
  deleteWithdrawMethods,
  uploadWithdrawMethods,
} from './money-withdraw-methods.actions';
import {MoneyWithdrawMethodApiService} from './withdraw-methods.api';

export function* moneyWithdrawMethodFlow() {
  yield takeEvery(
    uploadWithdrawMethods?.toString?.(),
    fetchSaga(MoneyWithdrawMethodApiService?.postUploadWithdrawMethod),
  );
  yield takeEvery(
    deleteWithdrawMethods?.toString?.(),
    fetchSaga(MoneyWithdrawMethodApiService?.deleteWithdrawMethod),
  );
}
