import {call, put, takeEvery} from '@redux-saga/core/effects';
import {SagaIterator} from '@redux-saga/types';
import {createSelector, createSlice} from '@reduxjs/toolkit';
import {Alert} from 'react-native';
import {ActionType, createAction} from 'typesafe-actions';
import {i18n} from 'localize';
import {
  ApiService,
  CrashlyticsService,
  AnalyticsService,
  SentryService,
  NetworkAuthorizationService,
  NetworkService,
} from 'services';
import {EAnalyticsEventNames} from '../../types/src/analytics';
import {TPostLoginInput, TPostLoginOutput, TPostSignupInput} from 'types';
import {LOG} from '../../utils/LOG';
import {clearStore, createCRUDActionCreators, fetchSaga, TMetaInput, TStore} from './common';
import {setUser} from './user';

export type TAuthStore = {
  isAuthenticated: boolean;
  tokens: {
    access: {
      token: string;
      expires: string;
    };
    refresh: {
      token: string;
      expires: string;
    };
  };
};

export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    isAuthenticated: false,
    tokens: null,
  },
  reducers: {
    setToken: ({tokens}, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      Object.assign(tokens || {}, action.payload);
    },
    loginSuccess: state => {
      state.isAuthenticated = true;
      return state;
    },
    logoutSuccess: state => {
      state.isAuthenticated = false;
      return state;
    },
  },
});

export const LOGIN_START = 'auth/LOGIN_START';
export const LOGOUT_START = 'auth/LOGOUT_START';
export const SIGNUP_START = 'auth/SIGNUP_START';
export const FORGOT_PASSWORD = 'auth/FORGOT_PASSWORD';
export const RESET_PASSWORD = 'auth/RESET_PASSWORD';

// Action creators are generated for each case reducer function
export const {loginSuccess, logoutSuccess, setToken} = authSlice.actions;
export const loginStart = createAction(LOGIN_START)<
  TPostLoginInput,
  TMetaInput
>();

export const {
  uploadAccountDeletion,
} = createCRUDActionCreators('AccountDeletion', '')

export const logoutStart = createAction(LOGOUT_START)();

export const signupStart = createAction(SIGNUP_START)<
  TPostSignupInput,
  TMetaInput
>();

export const forgotPassword = createAction(FORGOT_PASSWORD)<
  {
    email: string,
    lang: string
  },
  TMetaInput
>();

export const resetPassword = createAction(RESET_PASSWORD)<
  {
    token: string;
    password: string;
  },
  TMetaInput
>();

export const selectAccessToken = createSelector(
  (state: TAuthStore) => state?.tokens?.access?.token,
  token => token,
);

export const selectIsAuthenticated = (state: TStore) =>
  state?.auth?.isAuthenticated;

export default authSlice.reducer;

function* logoutSaga(): SagaIterator {
  console.log('logout flow start')
  try {
    if (NetworkAuthorizationService.getAuthParams()?.access) {
      yield call(NetworkAuthorizationService.setAuthParams, null);
    }
    AnalyticsService.logEvent(EAnalyticsEventNames.LOGOUT_SUCCEEDED);
    NetworkAuthorizationService.setAuthParams(null);
    yield put(clearStore());
    yield put(logoutSuccess());
  } catch (error: any) {
    LOG('logout', error);
    CrashlyticsService.recordError(error);
    AnalyticsService.logEvent(EAnalyticsEventNames.LOGOUT_FAILED);
    // if (error?.code === EFirebaseErrors.MESSAGING_UNKNOWN) {
    //   Alert.alert(
    //     i18n.t("errorHandling.firebaseMessaging.title"),
    //     l10n.errorHandling.firebaseMessaging.subtitle
    //   );
    // } else {
    Alert.alert(
      error?.name || i18n.t('errorHandling.logOut.title'),
      error?.message || i18n.t('errorHandling.logOut.subtitle'),
    );
    // }
  }
}

function* loginSaga({
  payload: {email, password},
  meta: {onSuccess, onFail},
}: ActionType<typeof loginStart>) {
  try {
    console.log('send login saga')
    const {data}: TPostLoginOutput = yield call(ApiService.postLogin, {
      email,
      password,
    });
    // console.log('response data',data)
    // console.log('tokens', JSON.stringify(data.tokens).length)
    if (data?.user) {
      const user = data?.user;
      yield call(NetworkAuthorizationService.setAuthParams, data.tokens);
      yield put(loginSuccess());
      yield put(setUser(user));
      onSuccess?.();
      AnalyticsService.logEvent(EAnalyticsEventNames.LOGIN_SUCCEEDED);
    } else if (data?.errorCode) {
      if (data?.errorCode === 401) {
        onFail?.({
          message: i18n.t('errors.invalidCredantials'),
        });
      } else if (data?.errorCode === 429) {
        onFail?.({
          message: i18n.t('errors.tooManyRequests'),
        });
      } else {
        onFail?.({
          message: i18n.t('errors.somethingWrong'),
        });
      }
      SentryService.captureEvent(data);
      AnalyticsService.logEvent(EAnalyticsEventNames.LOGIN_FAILED, {
        email,
        errorCode: data?.errorCode,
      });
    }
  } catch (err: any) {
    console.log('loginError',err)
    LOG('loginError', err);
    yield put(logoutSuccess());
    if (err?.response?.status === 401 || err?.statusCode === 401) {
      onFail?.({
        message: i18n.t('errors.invalidCredantials'),
      });
    } else if (err?.response?.status === 429 || err?.statusCode === 429) {
      onFail?.({
        message: i18n.t('errors.tooManyRequests'),
      });
    } else {
      onFail?.({
        message: i18n.t('errors.somethingWrong'),
      });
    }
    SentryService.captureException(err);
    AnalyticsService.logEvent(EAnalyticsEventNames.LOGIN_FAILED, {
      email,
      errorCode: err?.response?.status || err?.statusCode,
    });
  }
}

function* signupSaga({
  payload: {last_name, email, password, name},
  meta: {onSuccess, onFail},
}: ActionType<typeof signupStart>) {
  try {
    yield call(ApiService.postSignup, {
      name,
      email,
      password,
      last_name,
    });
    onSuccess?.();
  } catch (err: any) {
    // console.log(err.response.status())
    let errorMessage = '';
    if (err?.response?.status == 400) {
      errorMessage = i18n.t('errors.emailIsTaken');
    } else {
      errorMessage = i18n.t('errors.somethingWrong');
    }
    onFail?.({
      message: errorMessage,
    });
    AnalyticsService.logEvent(EAnalyticsEventNames.SIGNUP_FAILED);
    LOG(err);
  }
}

function* forgotPasswordSaga({
  payload: {lang, email},
  meta: {onSuccess, onFail},
}: ActionType<typeof forgotPassword>) {
  try {
    yield call(ApiService.postForgotPassword, {
      email,
      lang
    });
    onSuccess?.();
  } catch (err: any) {
    if (err?.response?.status === 404) {
      onFail?.({
        message: i18n.t('errors.emailNotFound'),
      });
    } else {
      onFail?.({
        message: i18n.t('errors.somethingWrong'),
      });
    }
    yield put(logoutStart());
    LOG(err);
  }
}

function* resetPasswordSaga({
  payload: {token, password},
  meta: {onSuccess, onFail},
}: ActionType<typeof resetPassword>) {
  try {
    yield call(ApiService.postResetPassword, {
      password,
      token,
    });
    onSuccess?.();
  } catch (err: any) {
    if (err?.response?.status === 401) {
      onFail?.({
        message: i18n.t('errors.invalidCredantials'),
      });
    } else if (err?.response?.status === 429) {
      onFail?.({
        message: i18n.t('errors.tooManyRequests'),
      });
    } else {
      onFail?.({
        message: i18n.t('errors.somethingWrong'),
      });
    }
    yield put(logoutStart());
    LOG(err);
  }
}

export function* authFlow() {
  yield takeEvery(loginStart, loginSaga);
  yield takeEvery(logoutStart, logoutSaga);
  yield takeEvery(signupStart, signupSaga);
  yield takeEvery(forgotPassword, forgotPasswordSaga);
  yield takeEvery(resetPassword, resetPasswordSaga);
  yield takeEvery(uploadAccountDeletion?.toString?.(), fetchSaga(ApiService.postAccountDeletion))
}
