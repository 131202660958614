
import { createSelector } from '@reduxjs/toolkit';
import { getStore } from 'store';

export const selectPostViewed = createSelector(
    getStore,
    store => store?.viewed?.['posts'],
); 


