import * as Yup from 'yup';
import {i18n} from 'localize';

export const getPostSchema = () =>
  Yup.object().shape({
    title: Yup.string()
      .min(2, i18n.t('validationSchema.tooShort'))
      .max(300, i18n.t('validationSchema.tooLong'))
      .required(i18n.t('validationSchema.requiredField')),
    text: Yup.string()
      .min(2, i18n.t('validationSchema.tooShort'))
      .max(2000, i18n.t('validationSchema.tooLong')),
    // .required(i18n.t('validationSchema.requiredField'))
    videoLink: Yup.string()
      .min(2, i18n.t('validationSchema.tooShort'))
      .max(1000, i18n.t('validationSchema.tooLong')),
    tizer: Yup.string()
      .min(2, i18n.t('validationSchema.tooShort'))
      .max(1000, i18n.t('validationSchema.tooLong')),
  //   .required(i18n.t("validationSchema.requiredField)"),
  });
