
import { createSelector } from '@reduxjs/toolkit';
import { find, values } from 'lodash';
import { getStore, TStore } from 'store';
import { TGoalTranslation } from './goalsTranslations.types';

// only for creators
export const selectGoalTranslationById = createSelector(
    [
      getStore,
      (_: TStore, id?: string) => id,
      (__: TStore, _, parentId?: string) => parentId,
    ],
    (store, id, parentId) =>
      find(
        store.goalsTranslations?.results,
        translation => translation?.id === id && translation?.parentId === parentId,
      ),
  );
  
export const selectGoalsTranslations = createSelector(
    [getStore, (_: TStore, parentId: string) => parentId],
    (store, parentId) => {
        const translationIds: string[] = values(store.creator.basicInfo.goals[parentId]?.translations || {}).map(t => t.id)
        const translations:TGoalTranslation[] = [];
        for(let id of translationIds){
            const tr = store?.goalsTranslations?.results[id]
            if(tr){
                translations.push(tr)
            }
        }
        return translations
    }
);



