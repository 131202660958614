import styled from 'styled-components/native';

import {Button as CustonButton} from '../../../../components';

import {responsiveSize} from '../../../../utils/font';

export const Container = styled.View`
  flex-direction: row;
  padding: ${responsiveSize(36)} ${responsiveSize(150)};

  ${props =>
    props.media.lg &&
    `
    padding: ${responsiveSize(48)} ${responsiveSize(60)};
  `};

  ${props =>
    props.media.md &&
    `
    padding: ${responsiveSize(32)} ${responsiveSize(60)};
  `};

  ${props =>
    props.media.sm &&
    `
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    padding: ${responsiveSize(24)} ${responsiveSize(16)};
  `};
`;

export const Logo = styled.Image`
  width: ${responsiveSize(224)};
  height: ${responsiveSize(90)};
  margin-right: auto;

  ${props =>
    props.media.sm &&
    `
    margin: 0 8px 0 8px;
    width: ${responsiveSize(224 / 1.5)};
    height: ${responsiveSize(90 / 1.5)};
  `};
`;

export const Actions = styled.View`
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  flex: 1;
`;

export const Button = styled(CustonButton)`
  margin-top: 10px;
  height: 40px;
  margin-left: ${responsiveSize(40)};

  ${props =>
    props.media.sm &&
    `
    margin-left: ${responsiveSize(16)};
  `};

  ${props =>
    props.media.md &&
    `
    margin-left: ${responsiveSize(24)};
  `};
`;

export const ButtonLogin = styled(CustonButton)`
  margin-top: 10px;
  height: 40px;
  margin-left: ${responsiveSize(40)};

  ${props =>
    props.media.md &&
    `
    margin-left: ${responsiveSize(24)};
  `};

  ${props =>
    props.media.sm &&
    `
    margin-left: ${responsiveSize(16)};
  `};
`;
