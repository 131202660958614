import {
  View,
  Text,
  Row,
  Switch,
  KeyboardAvoidingView,
  Column,
} from 'components';
import React, {useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {selectCreatorBasicInfo, updateCreatorStart} from 'store';
import {i18n} from 'localize';
import {ECreatorSettings} from 'types';

export const CreatorPrivacy = () => {
  const creator = useSelector(selectCreatorBasicInfo);
  const dispatch = useDispatch();
  const changeEarnAmountVisibility = useCallback(
    value => {
      dispatch(
        updateCreatorStart(
          {settings: {[ECreatorSettings.showMonthlyEarnings]: value}},
          {},
        ),
      );
    },
    [dispatch],
  );

  return (
    <KeyboardAvoidingView>
      <View variant="screenContainer">
        <Row
          columnCenter
          fullWidth
          // containsSimilarItems
          containerStyle={{justifyContent: 'flex-start', flexWrap: 'wrap',}}>
          <Column flex={3}
        >
            <Text variant="inputLabel">
              {i18n.t('editCreatorPrivacy.showPubliclyEarnedAmount')}
            </Text>
          </Column>
          <Column flex={1} containerStyle={{alignItems: 'flex-end'}}>
            <Switch //todo: move to separate component
              style={{marginLeft: 10}}
              testID="earnings-privacy-switch"
              onValueChange={changeEarnAmountVisibility}
              value={
                //sometimes we receive boolean in string ???
                Boolean(
                  creator?.settings?.[ECreatorSettings.showMonthlyEarnings],
                ) === false
                  ? false
                  : true
              }
            />
          </Column>
        </Row>
      </View>
    </KeyboardAvoidingView>
  );
};
