import {
    Row,
    Text,
    TierEditing,    
    View,
  } from 'components';
  import {EscreenNames} from 'navigation';
  import React, {useCallback} from 'react';
  import {Platform, StyleSheet, TouchableOpacity} from 'react-native';
  import {useSelector} from 'react-redux';
  import {useNavigation} from 'hooks';
  import {i18n} from 'localize';
  import {
    selectTiers,
  } from 'store';  
import { FlatList } from 'react-native';
import { isMobile } from 'utils';
import { map } from 'lodash';
import { BackHeader } from '../../../../containers/Header/BackHeader';
import { editTiersStyles } from './EditTiers.styles';

export const EditTiersTab = () => {

    const tiers = useSelector(selectTiers);
    const {navigate} = useNavigation();    
  
    const goToTierScreen = useCallback(() => {
      navigate(EscreenNames.TierEdit);
    }, [navigate]);
  

  const renderTier = useCallback(({item: tier}) => {
    return (
      <View style={editTiersStyles.listContainer}>
          <TierEditing
              onEdit={() => navigate(EscreenNames.TierEdit, {id: tier?.id})}
              testID="tier-edit-card"
              {...tier}
          />
      </View>
    )
  },[navigate])

  return (
    <View variant="screenContainer">
      {/*
        todo: add another container for editing page with
        custom header and title
      */}
      {!isMobile && <BackHeader />}
      <Row rowCenter={Platform.OS === 'web'}>
        <Text variant="sectionTitle">{i18n.t('editTiers.yoursTiers')}</Text>
        <TouchableOpacity
          testID="add-button"
          style={styles.addBtn}
          onPress={goToTierScreen}>
          <Text variant="sectionTitle">+</Text>
        </TouchableOpacity>
      </Row>

      {tiers?.length === 0 && (
        <View
          variant='sectionContainer'
        >
            <Text center variant="thirdTitle">
                {i18n.t('listEmptyTitle')}
            </Text>
        </View>
      )}
      {isMobile ? <FlatList
        data={tiers}
        centerContent
        // numColumns={3}
        // columnWrapperStyle={{flexWrap: 'wrap'}}
        ItemSeparatorComponent={() => <View variant='verticalListSeparator'/>}
        contentContainerStyle={styles.listContentContainer}
        renderItem={renderTier}
      />
      : (
        <Row
          flexWrap
          rowCenter
          containsSimilarItems
        >
          {map(tiers, (item) => (
            <>
            {renderTier({item})}
            <View variant='verticalListSeparator'/>
            </>
          ))}
        </Row>  
      )}
  </View>
  )
}


const styles = StyleSheet.create({
    addBtn: {
      marginLeft: 25,
    },
    listContentContainer : Platform.select({
      web: {flex:1, alignItems:'center'},
      default: {},
    })
  });
  