import {FlatList, ScrollView} from 'react-native';
import React from 'react';
import {useSelector} from 'react-redux';
import {selectCreatorBasicInfo} from 'store';
import {CreatorRevenueListItemContainer} from './CreatorRevenueListItem.container';
import {i18n} from 'localize';
import {Column, Row, Text, View} from 'components';

export const CreatorRevenueListContainer = () => {
  const cretorBasicInfo = useSelector(selectCreatorBasicInfo);

  return (
    <FlatList
      testID='balances-table'
      ItemSeparatorComponent={() => (
        <View variant="verticalListSeparator" style={{borderWidth: 1}} />
      )}
      ListHeaderComponent={() => (  
            <Row variant="tableHeader">
                <Column width={80}>
                  <Text variant="tableCeilTitle">
                    {i18n.t("myRevenuePage.currencyTitle")}
                  </Text>
                </Column>
                <Column width={150} rowCenter columnCenter>
                  <Text variant="tableCeilTitle">
                    {i18n.t("myRevenuePage.balanceAvailableTableTitle")}
                  </Text>
                </Column>
                <Column rowCenter width={150} />
            </Row>
      )}
      // style={{borderWidth: 1, borderRadius: 12, padding: 15}}
      data={cretorBasicInfo?.balances}
      renderItem={({item}) => <CreatorRevenueListItemContainer id={item?.id} />}
    />
  );
};
