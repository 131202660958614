
import { createCRUDActionCreators } from "../store/src/common";

export const {
    uploadPostTranslation,
    uploadPostTranslationSuccess,
    uploadPostTranslationFailed,
    updatePostTranslation,
    updatePostTranslationFailed,
    updatePostTranslationSuccess,
    deletePostTranslation,
    deletePostTranslationFailed,
    deletePostTranslationSuccess
} = createCRUDActionCreators('PostTranslation','')

export const {
    getPostTranslations,
    getPostTranslationsSuccess,
    getPostTranslationsFailed
} = createCRUDActionCreators('PostTranslations','')