import {TouchableOpacity, StyleSheet, FlatList} from 'react-native';
import React, {useCallback} from 'react';
import {Text, View} from 'components';
import {colors} from '../../../assets';

type TProps = {
  emojiInfo: {
    emoji: string;
    count: number;
    leftByUser: boolean;
    emojiUnicode: string;
  }[];
  userCanLeaveEmodji: boolean;
  onEmojiPress: (event: any, emoji: any) => void;
};

export const PostEmoji: React.FC<TProps> = ({
  emojiInfo,
  userCanLeaveEmodji,
  onEmojiPress,
}) => {
  const renderEmojii = useCallback(
    ({item, _}) => {
      return (
        <TouchableOpacity
          disabled={!userCanLeaveEmodji}
          style={[
            styles.emodjiContainer,
            item?.leftByUser && styles.emodjiActiveContainer,
          ]}
          onPress={() => onEmojiPress(null, {unified: item?.emojiUnicode})}>
          <Text style={{fontSize: 22}}>{`${item?.emoji} ${item?.count}`}</Text>
        </TouchableOpacity>
      );
    },
    [onEmojiPress, userCanLeaveEmodji],
  );

  return (
    <FlatList
      horizontal
      contentContainerStyle={styles.bottomRow}
      keyExtractor={item => item?.emojiUnicode}
      ItemSeparatorComponent={() => <View style={{margin: 4}} />}
      data={emojiInfo}
      renderItem={renderEmojii}
    />
  );
};

const styles = StyleSheet.create({
  bottomRow: {
    paddingVertical: 10,
    //todo: add height depends on emodji count
    // height: 100,
    alignItems: 'center',
    // justifyContent: 'space-between',
    // flexWrap: 'wrap',
    // flex:1,
  },
  emodjiRow: {
    justifyContent: 'flex-start',
    flexDirection: 'row',
    flexWrap: 'wrap',
    flex: 1,
  },
  emodjiContainer: {
    borderRadius: 10,
    padding: 5,
    backgroundColor: colors.DUSTY_GRAY,
    alignItems: 'center',
    justifyContent: 'center',
    borderWidth: 0,
    height: 50,
  },
  emodjiActiveContainer: {
    borderColor: colors.ORANGE,
    borderWidth: 2,
  },
});
