// todo: not to copy each time object - make helper to crete structure needed
export const advertiserSearchLocalization = {
    uk: {
      adsPlatformAccessScreen: {
            screenTitle: "Пошук рекламодавців",
            connectBtnTitle: "Підключити",
            searchTitle: "Анонімний пошук рекламодавців.",
            searchDesription: "Ви описуєте свій досвід, очікування від проектів та побажання по ціні, а рекламодавці пропонують рекламні проекти. Тільки ви вирішуєте, кому і коли відкрити контакти.",
            successMessage: "Найближчим часом Ми надішлемо Вам електронного листа з деталями та інструкцією."
      },
    },
    en: {
        adsPlatformAccessScreen: {
            screenTitle: "Advertiser search",
            connectBtnTitle: "Connect",
            searchTitle: "Anonymous ad search engine.",
            searchDesription: "You describe your experience, project expectations and price preferences, and advertisers offer advertising projects. Only you decide to whom and when to open contacts.",
            successMessage: "We will send you an email with details and instructions soon"
        },
    }
}