import {Column, Icon, Logo, Row, Text} from 'components';
import {View} from 'components';
import React from 'react';
import {StyleSheet, useWindowDimensions, Image, ViewProps, Touchable, Linking} from 'react-native';
import {TouchableOpacity} from 'react-native';
import {colors} from '@sponsor/assets';
import {FOOTER_HEIGHT} from '@sponsor/constants';
import {useNavigation} from 'hooks';
import {i18n} from 'localize';
import {EscreenNames} from 'navigation';
import {LanguagePicker} from '../../../containers/LanguagePicker/LanguagePicker';
import { openMail } from '../../../emails/emails.utils';

export const Footer = () => {
  const {navigate} = useNavigation();

  const layout = useWindowDimensions();

  const ContentWrapper =
    layout.width < 800
      ? (props: any) => <Column rowCenter {...props} />
      : (props: ViewProps) => (
          <Row
            {...props}
            // containsSimilarItems
            containerStyle={{flex:1, flexWrap: 'wrap', justifyContent: 'flex-end'}}
          />
        );

  return (
    <View style={[styles.container]}>
      <ContentWrapper>

        {/* todo: make normal design of footer */}
        <Column flex={layout.width < 800 ? 1  :0.5}>
          <Logo />
        </Column>

        <ContentWrapper>
          <Column flex={1}>
            <View variant="sectionContainer">
              <LanguagePicker />
            </View>
          </Column>

          <Column flex={1.5}>
            <View variant="sectionContainer">
              <TouchableOpacity onPress={() => navigate(EscreenNames.TermOfUse)}>
                <Text variant="thirdTitle">{i18n.t('footer.termOfUse')}</Text>
              </TouchableOpacity>
              <TouchableOpacity
                onPress={() => navigate(EscreenNames.PublicOffer)}>
                <Text variant="thirdTitle">{i18n.t('footer.publicOffer')}</Text>
              </TouchableOpacity>
            </View>

            <View variant='sectionContainer'>
              <Row containsSimilarItems>
                <TouchableOpacity onPress={() => Linking.openURL("http://releases.gmhost.space/Sponsor.financial-0.1.3.dmg")}>
                    <Icon name="macos-logo" size={25}/>
                  </TouchableOpacity>
                  <TouchableOpacity onPress={() => Linking.openURL("https://play.google.com/store/apps/details?id=com.kailuas.sponsor.financial")}>
                    <Icon name="android-logo" size={25}/>
                  </TouchableOpacity>
                  <TouchableOpacity onPress={() => Linking.openURL("https://apps.apple.com/us/app/sponsor-financial/id6444585507")}>
                    <Icon name="ios-logo" size={25}/>
                  </TouchableOpacity>
              </Row>
            </View>


          </Column>

          <Column
            flex={1}
            containerStyle={{ alignItems:'center'}}
          >
            <View variant="sectionContainer">
              <Image
                style={{width: 40, height: 50}}
                resizeMode="contain"
                source={require('@sponsor/assets/src/icons/mastercard.svg')}
              />
            </View>
          </Column>

          <Column flex={2} containerStyle={{alignItems: 'flex-end'}}>
            <View variant="sectionContainer">
              <Text variant="thirdTitle">{i18n.t('footer.support')}</Text>
              {/* todo: add test for selectable text */}
              <TouchableOpacity onPress={() => openMail({subject: 'Support', to: "support@sponsor.financial"})}>
                <Text selectable numberOfLines={1}>support@sponsor.financial</Text>
              </TouchableOpacity>
              {/* <TouchableOpacity onPress={() => openMail({subject: 'Support', to: "sponsor.financial@gmail.com"})}>
                <Text selectable  numberOfLines={1}>sponsor.financial@gmail.com</Text>
              </TouchableOpacity> */}
              <Text style={{marginTop: 10}} selectable>
                {i18n.t('footer.publicCompanyAddress')}
              </Text>
            </View>
            {/* <Text>063 1887 463</Text> */}
          </Column>
        </ContentWrapper>
      </ContentWrapper>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    borderTopColor: colors.GREEN_OFFICIAL,
    borderTopWidth: 1,
    minHeight: FOOTER_HEIGHT,
    padding: 22,
    zIndex: 0,
  },
});
