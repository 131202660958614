import React from 'react';
import {View, TouchableOpacity} from 'react-native';
import {i18n} from 'localize';
import {colors} from '@sponsor/assets';
import {Text} from 'components';

type TProps = {
  onPress?: () => void;
};

export const NotAuthorizedUser: React.FC<TProps> = ({onPress}) => {
  return (
    <View style={{flexDirection: 'row', marginTop: 15}}>
      <Text>{i18n.t('profile.unlocktext')}</Text>
      <TouchableOpacity onPress={onPress}>
        <Text style={[{color: colors.GREEN_OFFICIAL}]}>
          {i18n.t('profile.logintext')}
        </Text>
      </TouchableOpacity>
    </View>
  );
};
