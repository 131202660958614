import {
  forEach,
  set,
  findIndex,
  filter,
  isEmpty,
  sortBy,
  fromPairs,
  toPairs,
} from 'lodash';
import {createSelector, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {
  fetchSaga,
  getStore,
  TMetaInput,
  TPublicPostsState,
  TStore,
} from '../common';

import {TGetCreatorOutput, TGetPostsOutput, TPost} from '../../../types/src';
import {
  getCreatorPublicInfoSuccess,
  togglePostEmodjiSuccess,
} from './creatorsPosts';
import {getInfeedPostsSuccess} from './infeedPosts';
import {takeEvery} from 'redux-saga/effects';
import {ApiService} from 'services';
import {createAction} from 'typesafe-actions';

const initialState: TPublicPostsState = {};

/**
 * We created another slice only for post data because of
 * incorrect behavior of immutability library inside redux-toolkit createSlice func
 *
 * changing nested object also was changing  parent object
 *
 * all posts are stored in this slice,
 * other might need contain only ids
 * you should take this ids and select post from publicPosts slice
 * todo: remove other places where posts are store fully
 *
 */
export const publicPostsSlice = createSlice({
  name: 'publicPosts',
  initialState,
  reducers: {
    getPublicPostsSuccess: (state, action: PayloadAction<TGetPostsOutput['data']>) => {
      const posts = action.payload.results;
      // todo: clean not saved posts
      // todo: prevent bugs in future, return if state assigment or state modification only allowed 
      let newState: TPublicPostsState = {}
      // todo: refactor, handle case for opening post from post screen separetly
      if(action.payload?.page == 1 && action.payload.totalResults > 1){
        forEach(posts, p => {
          newState[p?.id] = p;
        });
      }
      else{
        newState = state;
        forEach(posts, p => {
          state[p?.id] = p;
        });
      }
      return newState
    },
  },
  extraReducers: {
    [getInfeedPostsSuccess.toString()]: (state, action) => {
      forEach(action.payload?.results, p => {
        state[p?.id] = p;
      });
    },
    [getCreatorPublicInfoSuccess.toString()]: (
      state: TPublicPostsState,
      action: PayloadAction<TGetCreatorOutput['data']>,
    ) => {
      const posts = action.payload?.recentPosts;
      forEach(posts, p => {
        state[p?.id] = p;
      });
    },
    [togglePostEmodjiSuccess.toString()]: (
      state: TPublicPostsState,
      {payload: {userId, postId, emodjiUnicode}},
    ) => {
      // let post = state?.[postId]
      if (!isEmpty(state?.[postId])) {
        const emodjiUsers =
          state?.[postId].reactions?.emodji?.[emodjiUnicode] || [];
        const hasUserLeftEmodji =
          findIndex(emodjiUsers, emodjiUser => emodjiUser === userId) > -1;
        const nbReactions =
          state?.[postId].reactions?.nbReactions?.[emodjiUnicode] || 0;

        if (!state[postId].reactions) {
          state[postId].reactions = {
            emodji: {},
            nbReactions: {},
          };
        }
        if (hasUserLeftEmodji) {
          state[postId].reactions = set(
            state?.[postId].reactions,
            `emodji.${emodjiUnicode}`,
            filter(emodjiUsers, eu => eu !== userId),
          );
          state[postId].reactions = set(
            state?.[postId].reactions,
            `nbReactions.${emodjiUnicode}`,
            nbReactions - 1,
          );

          state[postId].reactions.emodji = fromPairs(
            sortBy(
              toPairs(state[postId].reactions.emodji),
              ([_, v]) => v?.length,
            ).reverse(),
          );
        } else {
          state[postId].reactions = set(
            state?.[postId].reactions,
            `emodji.${emodjiUnicode}`,
            [...emodjiUsers, userId],
          );

          state[postId].reactions.emodji = fromPairs(
            sortBy(
              toPairs(state[postId].reactions.emodji),
              ([_, v]) => v?.length,
            ).reverse(),
          );

          state[postId].reactions = set(
            state?.[postId].reactions,
            `nbReactions.${emodjiUnicode}`,
            nbReactions + 1,
          );
        }
      }
    },
  },
});

export const GET_PUBLIC_POSTS = 'publicPosts/getPublicPosts';

export const getPublicPosts = createAction(GET_PUBLIC_POSTS)<
  {
    ids: string[];
    lang: string,
  },
  TMetaInput
>();

export const selectPublicPostState = createSelector(
  getStore,
  state => state.posts.publicPosts,
);

export const selectPublicPostById = createSelector(
  [
    selectPublicPostState, 
    (state: TStore, postId: string) => postId
  ],
  (posts, postId) => posts?.[postId] || {},
);

//todo: delete this seelctor ???
export const selectPublicPostReactionByPostId = createSelector(
  [selectPublicPostById, (state: TStore, postId: string) => postId],
  (post, _) => post?.reactions,
);

export const selectPublicPostComments = createSelector(
  [selectPublicPostById],
  post => {
    return post.comments;
  },
);
export default publicPostsSlice.reducer;

export function* publicPostsFlow() {
  yield takeEvery(getPublicPosts, fetchSaga(ApiService.getUserPosts));
}
