import {takeEvery} from 'redux-saga/effects';

import {fetchSaga} from '../store/src/common';
import {
  uploadCreatorMerchantEntrepreneur,
  uploadCreatorMerchantEntrepreneurFinancial,
  uploadCreatorMerchantEntrepreneurFinancialSuccess,
} from './creatorMerchant.actions';
import {CreatorMerchantApiService} from './creatorMerchant.api';

export function* creatorMerchantFlow() {
  yield takeEvery(
    uploadCreatorMerchantEntrepreneur.toString(),
    fetchSaga(CreatorMerchantApiService.postUploadCreatorMerchant),
  );
  yield takeEvery(
    uploadCreatorMerchantEntrepreneurFinancial.toString(),
    fetchSaga(CreatorMerchantApiService.patchUpdateCreatorMerchant),
  );
}
