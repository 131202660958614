import React, {useCallback, useMemo, useState} from 'react';
import {View, Text, FormInput, Button} from 'components';
import {colors} from '@sponsor/assets';
import {i18n} from 'localize';
import moment from 'moment';
import {EPaymentPeriod, TTier} from '../types';
import {useDispatch} from 'react-redux';
import {uploadUnathorizedSubscription} from './unathorized-subscription.saga';
import {UnathorizedSubscriptionSuccessInfo} from './UnathorizedSuccessInfo.component';
import {ActivityIndicator} from 'react-native';
import {Row} from '../components';
import {toNumber} from 'lodash';
import {TGeneralError} from 'app/errors/errors.types';

type TProps = {
  tier: TTier;
};

export const UnathorizedSubscriptionPayForm: React.FC<TProps> = ({tier}) => {

  const [customPrice] = useState('');
  const [paymentPeriod] = useState(EPaymentPeriod.oneMonth);
  const [userEmail, setUserEmail] = useState('');
  const [error, setError] = useState('');

  const [emailWasSent, setEmailWasSent] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();

  const dispatchUnathorizedPay = useCallback(() => {
    const subscribe_date_start = moment().format('YYYY-MM-DD HH:mm:ss');
    const price = toNumber(customPrice) || tier?.price;

    if (price < tier?.price) {
      return setError(i18n.t('validationSchema.minimalPrice'));
    }

    const currencyName = tier.currency?.name || 'UAH';

    if (!userEmail) {
      return setError(i18n.t('validationSchema.requiredField'));
    }
    setIsLoading(true);
    dispatch(
      uploadUnathorizedSubscription(
        {
          email: userEmail,
          amount: price,
          btn_title: i18n.t('buySubscriptionBtnTitle'),
          description: tier?.description,
          subscribe_date_start,
          currencyName,
          info: JSON.stringify({
            tierId: tier?.id || null,
            // userId: user?.id || null,
            email: userEmail,
            price,
            creatorId: tier?.creator,
            creatorTitle: tier?.creatorTitle,
            creatorAvatarUri: tier?.creatorAvatarUri,
            creatorPageURL: tier?.creatorPageURL,
          }),
        },
        {
          onSuccess: () => {
            setEmailWasSent(true);
            setError('');
          },
          onFail: (err: TGeneralError) => {
            if (err?.errorCode == 409) {
              setError(i18n.t('paymentScreen.subscriptionAlreadyBought'));
            } else {
              setError(i18n.t('errors.somethingWrong'));
            }
          },
          onFinish: () => setIsLoading(false),
        },
      ),
    );
  }, [dispatch, customPrice, userEmail, tier]);

  const nextPaymentDate = useMemo(() => {
    let nbMonths = 1;
    switch (paymentPeriod) {
      case EPaymentPeriod.oneMonth:
        nbMonths = 1;
        break;
      case EPaymentPeriod.threeMonths:
        nbMonths = 3;
        break;
      case EPaymentPeriod.sixMonths:
        nbMonths = 6;
        break;
      case EPaymentPeriod.twelveMonths:
        nbMonths = 12;
        break;
      default:
        nbMonths = 1;
    }
    return moment().add(nbMonths, 'month').format('LL');
  }, [paymentPeriod]);

  if (emailWasSent) {
    return <UnathorizedSubscriptionSuccessInfo />;
  }

  return (
    <>
      {/* <FormInput
        value={customPrice}
        placeholder={i18n.t('paymentScreen.writeCustomPrice')}
        keyboardType="decimal-pad"
        onChangeText={setCustomPrice}
      /> */}
      <FormInput
        value={userEmail}
        placeholder={i18n.t('paymentScreen.writeUserEmailPlaceholder')}
        onChangeText={setUserEmail}
      />
      <Text variant="inputError">{error}</Text>
      <View variant="sectionContainer">
        <Row columnCenter containsSimilarItems>
          <Button
            primary
            title={i18n.t('paymentScreen.buy')}
            onPress={dispatchUnathorizedPay}
            disabled={isLoading}
          />
          {isLoading && <ActivityIndicator />}
        </Row>
        <Text color={colors.GREEN_OFFICIAL} variant="formHint">
          {i18n.t('paymentScreen.subscriptionCancel')}
        </Text>
        <Text color={colors.GREEN_OFFICIAL} variant="formHint">
          {`${i18n.t('paymentScreen.nextPaymentDate')}: ${nextPaymentDate}`}
        </Text>
      </View>
    </>
  );
};
