import {CreatorCard, Text, TierBase, View} from 'components';
import React from 'react';

import {isEmpty, map} from 'lodash';
import {i18n} from 'localize';
import {TTier} from 'types';
import {Platform, StyleSheet, TouchableOpacity} from 'react-native';
import {colors} from '../assets';
import {EscreenNames} from '../navigation';
import {useNavigation} from '../hooks';
import { useMedia } from '../hooks/useMedia';

type TProps = {
  tier: TTier;
};

export const SubscriptionInfo: React.FC<TProps> = ({tier}) => {
  const media = useMedia()

  return (
    <View>
      <TierBase
        {...tier}
        // TODO: remove this prop
        containerStyle={[{width: media.sm ? '100%' : undefined}]}
        price={tier.price}
      />
      {/* <View variant="sectionContainer"> */}
        {/* <Text variant="formLabel">{i18n.t("paymentScreen.payForPeriod")}</Text>
            <Row containerStyle={styles.subscriptionOptions}>
            <Button
                primary={paymentPeriod === EPaymentPeriod.oneMonth}
                onPress={() => setPaymentPeriod(EPaymentPeriod.oneMonth)}
                title={i18n.t("paymentScreen.oneMonth")}
            />
            <Button
                primary={paymentPeriod === EPaymentPeriod.threeMonths}
                onPress={() => setPaymentPeriod(EPaymentPeriod.threeMonths)}
                title={i18n.t("paymentScreen.threeMonth")}
            />
            <Button
                primary={paymentPeriod === EPaymentPeriod.sixMonths}
                onPress={() => setPaymentPeriod(EPaymentPeriod.sixMonths)}
                title={i18n.t("paymentScreen.sixMonth")}
            />
            <Button
                primary={paymentPeriod === EPaymentPeriod.twelveMonths}
                onPress={() => setPaymentPeriod(EPaymentPeriod.twelveMonths)}
                title={i18n.t("paymentScreen.twelveMonth")}
            />
            </Row> */}
      {/* </View> */}

      {!isEmpty(tier?.coCreators) && (
        <View variant="sectionContainer" style={{alignItems: 'flex-start'}}>
          <Text center variant="secondaryTitle">
            {i18n.t('coCreators')}
          </Text>
          {map(tier?.coCreators, c => (
            <CreatorCard containerStyle={styles.coCreatorContainer} {...c} />
          ))}
        </View>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  coCreatorContainer: {
    marginVertical: 5,
  },
});
