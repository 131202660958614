import React, {useMemo, useState} from 'react';
import {ActivityIndicator} from 'react-native';
import {ColorPicker, Text, View, Button, FormInput} from 'components';
import {i18n} from 'localize';
import {selectCreatorBasicInfo, updateCreatorStart} from 'store';
import {useSelector} from 'react-redux';
import {ActionResult} from '../../../components/src/ActionResult/ActionResult.component';
import {useDispatchAction} from 'hooks';
import { ActionResultDefaultRenders } from 'components/src/ActionResult/ActionResultDefaultRenders';
import { Formik } from 'formik';
import { COLOR_REQ_EXP, getBrandColorValidationSchema } from './editCustomizationValidationSchema';

export const EditCustomization = () => {
  const creator = useSelector(selectCreatorBasicInfo);
  const [brandColor, setBrandColor] = useState(
    creator?.customization?.brandColor,
  );

  const {isLoading, error, submitAction} = useDispatchAction(
    updateCreatorStart,
    {
      data: {
        ['customization']: {
          brandColor,
        },
      },
    },
  );

  const isNewSettingsAvailable = useMemo(() => {
    return creator?.customization?.brandColor !== brandColor;
  }, [creator?.customization, brandColor]);

  return (
    <View variant="screenContainer">
      <Text variant="secondaryTitle">
        {i18n.t('editCreatorPage.brandColorButtonTitle')}
      </Text>
      <Text variant="inputLabel">
        {i18n.t('editCustomizationScreen.brandColorButtonSubTitle')}
      </Text>
      
      <View style={{width: 250, marginVertical: 35, height: 280}}>
        <Text variant="thirdTitle" color={brandColor}>
          {brandColor}
        </Text>
        <ColorPicker
          color={brandColor}
          onColorChangeComplete={color => setBrandColor(color)}
          discrete={true}
        />
      </View>
      <Formik
        initialValues={{
          brandColor: brandColor || ""
        }}
        onSubmit={(values) => setBrandColor(values.brandColor)}
        validate={(values) => {
        if (
          COLOR_REQ_EXP.test(values.brandColor)
        ) {
          setBrandColor(values.brandColor)
        }
      }}
      >
        {({values, handleChange}) => (
          <FormInput 
            label={""}
            value={values.brandColor}
            onChangeText={(text) => handleChange('brandColor')(text)}
          />
        )}
      </Formik>
      <Button
        primary
        title={i18n.t('saveSettings')}
        disabled={!isNewSettingsAvailable}
        onPress={() => submitAction()}
      />
      <ActionResult
        isLoading={isLoading}
        error={error}
        {
          ...ActionResultDefaultRenders
        }
      />
    </View>
  );
};
