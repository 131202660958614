import { ESubscriptionStatus } from "types";

/* eslint-disable max-len */
export default {
  locale: 'en-US',
  name: 'Name',
  surname: 'Surname',
  delete: 'Delete',
  accept: 'Accept',
  decline: 'Decline',
  edit: 'Edit',

  cancelButtonTitle: "Cancel",
  language: "Language",
  pricingPlan: "Pricing plan",

  translations: 'Translations',
  addTranslation: "Add translation",
  soon: 'Soon',
  share: 'Share',
  updatedAt: "Updated at",
  view: 'View',
  operationSuccess: 'Operation success',
  supportCreator: 'Support',
  linkCopied: 'Link is copied',
  buySubscriptionBtnTitle: 'Buy subscription',
  saveSettings: 'Save settings',
  pickPhoto: 'Choose photo',
  logout: 'Log out',
  unpublish: 'Hide',
  publish: 'Publish',
  login: 'Log in',
  menu: "Menu",
  createAccount: 'Sign up',
  register: 'Register',
  submitEdit: 'Submit changes',
  isPublished: 'Is published',
  isDeclined: 'Declined',
  isNotPublished: 'Is not published',
  twitch: 'Twitch',
  reddit: 'Reddit',
  telegram: 'Telegram',
  youtube: 'Youtube',
  twitter: 'Twitter',
  follow: 'Follow',
  unfollow: 'Don’t follow',
  seeMore: 'See more',
  hideText: 'Hide text',
  coCreators: 'Co-creators',
  friends: 'Friends',
  imageSizeLimit: 'Limit 5 megabytes, .png, .jpg',
  noArticles: 'The author has not published posts yet',

  chooseFilter: "Choose filter",

  btns: {
    titles: {
        repeat: "Repeat"
    }
  },
  languages: {
    restartAppAlertTitle: "Restart app",
    restartAppAlertMessage: "To fully apply changes, restart app",
  },

  chooseLanguagePickerLabel: "Choose language:",
  listEmptyTitle: 'List is empty',
  unsavedChangesWarning: 'You have unsaved changes, are you sure?',
  socials: {
    megogo: 'Megogo',
    wiki: 'Wiki',
  },
  post: 'Post',
  month: {
    '1': 'January',
    '2': 'February',
    '3': 'March',
    '4': 'April',
    '5': 'May',
    '6': 'June',
    '7': 'July',
    '8': 'August',
    '9': 'September',
    '10': 'October',
    '11': 'Nobember',
    '12': 'December',
  },
  currencies: {
    uah: 'UAH',
  },
  loginScreen: {
    dontHaveAccount: 'Don’t have an account',
    forgotPassword: 'Forgot your password?',
    passwordPlaceholder: 'Password',
    passwordLabel: 'Password',
    emailPlaceholder: 'Email',
    emailLabel: 'Email',
  },
  dialogs: {
    deletionConfirmationDialogTitle: "Item deletion",
    confirmationDialogTitle: "Are you sure ?",
    cancelBtnTitle: "Cancel",
  },
  supportIntervalTitle: "every month",
  accountDeletionScreen: {
    screenTitle: "Account deletion",
  },
  signupScreen: {
    haveAccount: 'Have an account',
    forgotPassword: 'Forgot your password?',
    passwordPlaceholder: 'Password',
    namePlaceholder: 'Name',
    lastnamePlaceholder: 'Last name',
    passwordLabel: 'Password',
    emailPlaceholder: 'Email',
    emailLabel: 'Email',
    confirmEmailLabel: 'Confirm email',
    confirmEmailPlaceholder: 'Enter email one more time',
    signupTitle: 'Sign up',
    aggreement:
      "I agree to the collection and processing of personal data in accordance with the Law of Ukraine 'On Personal Data Protection' and confirm that I will not post information with limited access and any other information or data other than public",
  },
  forgotPassword: {
    haveAccount: 'Have an account',
    resetPassword: 'Reset password',
    forgotPassword: 'Forgot your password?',
    resetEmailSendAlertMessage: 'Email with reset link was sent',
  },
  resetPassword: {
    haveAccount: 'Have an account',
    resetPasswordTitle: 'Reset password',
    newPassword: 'New password',
    resetPassword: 'Reset password',
    emailText: `Dear user,
    To verify your email, click on this link: %{verificationEmailUrl}
    If you did not create an account, then ignore this email.`,

    successfullyReset: 'Password successfully reset',
    passwordConfirmation: 'Password confirmation',
  },
  bottomTab: {
    creatorTabLabel: 'Creator',
    searchTabLabel: 'Search',
    profileTabLabel: 'Profile',
  },

  landing: {
    donationForUkraine: '10% of revenue - donation for Ukriane',
    raiseFunds:
      'Sponsor.financial - platform for money collection for your projects with the help of monthly payments',
    postUniqueContent: 'Publish unique content only for your pre-payers',
    paymentsOnBankAccount: '',
    supportUkraine: 'Support Ukraine',
    supportUkraineBtnTitle: 'Support Ukraine',

    createPaidSubscriptionBtn: 'Create a paid subscription',

    buildTransparentRelationship:
      'Build a transparent relationship with the audience',
    withoutAlgorithms: 'without algorithms, bots or trolls',
    onlyYouAreModerator:
      'Only you determine what your subscribers will see and who',
    createCollaboration: 'Collaborate with other creators',
    receiveEqualPayments: 'And receive equal payments',
    opportunityToCollaborate:
      'Unique an opportunity to publicly collaborate with your partners',

    shareSubscription: 'SHARE SUBSCRIPTION WITH YOUR AUDIENCE',
    shareYouAreOnSponsorFinancial:
      'Tell your prepayers that you are on Sponsor.financial',
    joinUs: 'Join up',

    pricingPlans: {
      sectionTitle: "Pricing",
    },
    features: {
      basicPricingPlanTitle: "Basic",
      translations: "Page, posts, goals translations",
      collaborations: "Collaboration with other creators",
      intantPayout: "Instant payouts to the bank card",
      fees: "8.5% fee + transactions fees for all features",
      analytics: "Analytics",
      mobileApp: "Data managment from the mobile app",
      offlineSupport: "Offline mobile support",
      oneClickPayment: "1-tap payment for users. No account required",
      paymentWays: "Pay by Credit card, Apple Pay, Google Pay",
      publicPrivatePosts: "Public posts and Exclusives",
      officialVerification: "Opportunity officially verify page",
      militarySupport: "Military support",
    },
  },
  activeSubscriptions: {
    tabTitle: 'Active',
    noSubscriptions:
      'You are not signed up for anything yet. Ask the authors you know about the Sponsor.financial page',
  },
  deletedButActiveSubscriptions: {
    tabTitle: 'Deleted subscriptions',
  },
  errors: {
    somethingWrong: 'Something went wrong',
    alreadyExists: 'Already exists',
    tooManyRequests: 'Too many requests. Please try again later',
    invalidCredantials: 'Invalid credantials',
    pageUrlUnique: 'The link to the page must be unique',
    emailIsTaken: 'Email is already taken',
    profileImageRequired: 'Select a profile image',
    backgroundImageRequired: 'Select background image',
    imageSizeInvalid:
    'Image must be larger than %{minWidth} * %{minHeight} and less than %{maxWidth} * %{maxHeight}. Current dimensions: %{width} * %{height}. Sorry for the inconvenience',
    avatarImageInvalid:
      'Profile image must be larger than 200 * 200 and less than 2000 * 2000. Sorry for the inconvenience',
    backgroundImageInvalid:
      'The main image must be larger than 500 * 300 and less than 5000 * 5000. Sorry for the inconvenience',
    // todo: check if next messaage is secure
    emailNotFound: 'Email not found',
    userNotFound: 'The user is not found',
    
    itemIsMissed: "We didn't find %{itemName}",

    limitReachContactAdmin: 'You have reached the limit. Contact admins',
  },
  header: {
    updateImage: 'Update the cover',
  },
  myRevenuePage: {
    screenTitle: "Balance",
    monthlyEarnings: 'Monthly earnings',
    withdrawBalance: 'Withdraw',
    yourPayouts: 'Your Payouts',
    paymentMethodChange: 'Change payment card',
    noPayouts: 'No payouts yet',
    receiver: 'Receiver',
    amount: 'Amount',
    dateOfWithdraw: 'Withdraw date',

    currencyTitle: 'Currency',
    balanceAvailableTableTitle: 'Available',

    withdrawContinueBtnTitle: 'Continue',

    creatorBalancesTitle: 'Available costs',
  },
  calcelSubscriptionScreen: {
    screenTitle: 'Cancel subscription',
  },
  // todo: screen or page
  paymentScreen: {
    screenTitle: 'Payment',
    subscriptionNotFound: 'No subscription data found. Please try again later',
    // payForPeriodButton: 'Continue paying',
    // payForPeriod: 'Продовжувати передплату',
    paymentTitle: 'Completion of purchase for creator:',
    oneMonth: 'One month',
    threeMonth: 'Three months',
    sixMonth: 'Six months',
    twelveMonth: 'Twelve months',
    buy: 'Buy',
    subscriptionCancel: 'You can cancel your subscription at any time',
    nextPaymentDate: 'Next payment date',
    cancelSubscription: 'Cancel the subscription',
    writeCustomPrice: 'Enter your price, if you want',
    writeUserEmailPlaceholder: 'Write your email',

    emailPlaceholder: 'Required - your email',

    subscriptionAlreadyBought: 'Looks like you already bought a subscription',

    refundPolicyMessage:
      'Sponsor financial does not refund on behalf of the authors.',

    chooseYourPaymentMethod: 'Choose your payment method',
    bankPaymentMethod: 'Bank',
    cryptoPaymentMethod: 'Crypto',
  },
  cancelSubscriptionScreen: {
    monthAccessAlertMessage: "You still have an access for private posts untill subscription end date",
  },
  subscriptionCancelingByToken: {
    invalidTokenWarningMessage: 'Check your url and try again',
    successMessage: 'Subscription was deleted',
  },
  search: {
    search: 'Search',
    searchInputPlaceholder: "Type name, creator title or url",
    noResults: 'Authors were not found to your search',
  },
  // todo: change naming
  creatorCard: {
    nbSponsors: 'Sponsors',
    writeTitle: 'What is the name of your project ?',
    writeDesc: 'Provide a description of your activities',
    supportIntervalTitle: "every month",
  },
  creatorPosts: {
    screenTitle: "Creator posts",
  },
  creatorPage: {
    creatorPageTitle: 'Creator page',

    creatorNotFound: 'User not found',
    chooseSubscription: 'Select a subscription',
    seeAllTiersButtonTitle: "Open on new screen",

    aboutAuthor: 'About the author',
    lastPosts: 'Last post',
    collaborations: 'Collaboration',
    benefitsTitles: 'When you become a sponsor, you will have access to',
    exclusivePosts: 'Exclusive posts',
    allExclusivePosts: 'All',

    socials: 'Socials',

    showAllTiers: 'Show all subscriptions',
    hideTiers: 'Hide subscriptions',

    nbsponsorsTitle: 'Sponsors:',
    monthlyPaidAmountTitle: 'Per month:',

    createCommunityTierTitle:
      "Didn't find your subscription ? Create your own !",

    createYourOwnGoalTitle: 'Create goal for this creator',
  },
  profileDropdown: {
    myPage: 'My page',
    createPage: 'Create page',
    editMyPage: 'Edit page',
    inviteCreator: 'Invite to the platform',
    searchCreators: 'Search for creators',
    postsFromCreators: 'Posts from Creators',
    mySubscriptions: 'My subscriptions',
    myRevenue: 'My revenue',
    profileSettings: 'Profile settings',
    subscribers: 'My subscribers',
  },
  editCreatorCustomizationScreen: {
    screenTitle: 'Customization',
  },
  editCreatorTranslationsScreen: {
    screenTitle: 'Translations',
  },
  editCreatorPage: {
    countryLabel: "Country code",
    reloadPageLater: 'Reload the page later if something is wrong',
    editMenuTitle: 'Creator settings',
    preview: 'Preview',
    basicSettings: 'Basic',
    lastMonthLabel: 'Last month',
    paymentSettings: 'Payments',
    goalsSettingsButtonTitle: 'Goals',
    privacySettingButtonTitle: 'Privacy',

    friendsSettingsButtonTitle: 'Friends',
    customizatonSettingsButtonTitle: 'Customization',
    translationsSettingsButtonTitle: "Creator info's translations",

    tierSettings: 'Tiers',
    dragImageHere: 'Put files here ...',
    startDragging: 'Drag a photo here or click to select',
    subscriptions: 'Level of support',
    addTier: 'Add a subscription',
    titleLabel: 'Profile name ',
    subtitleLabel: 'Subtitle',
    titleInputPlaceholder: 'Required - Short title',
    subtitlePlaceholder: 'Start with create, share, promote ...',
    descriptionPlaceholder: 'Required - write what do you do',

    descriptionLabel: 'Description of the activity',
    creatorProfileImageLabel: 'Creator photo',
    pageURLLabel: 'Part of the link ',
    pageURLSubLabel: "Unique words for you page full link",
    pageURLPlaceholder: 'User can search you by this shortname',
    videoURLLabel: 'Video link ',
    backgroundImageLabel: 'Background Photo ',
    posts: 'Posts',
    greetings: 'Greetings',
    drafts: 'Drafts',

    socialsTitle: 'Socials',

    socials: 'Socials',
    privacy: 'Privacy',
    addPaymentMethod: 'Add payment method ',
    paymentMethods: 'Methods to withdraw money',

    paymentMethodBankCard: 'Bank card',
    paymentMethodCryptoWallet: 'Crypto wallet',
    paymentMethodsMerchantsListTitle: 'Merchants',

    fiveMbLimit: 'Limit 5 megabytes, .png, .jpg',
    publishCreatorPage: 'Publish',

    thisOptionsAvaiableOnWeb: 'This option is available only on web',
    tiersBlockedMessage: 'Add payment method first',

    brandColorButtonTitle: 'Brand color',

    creatorMainLanguageLabel: 'Main language',

    notVerifiedAccountMessage:
      'Your account is not verified.\nYou can use your account without limitation.\nContact us via email for verification: \n%{email}.',

    tiersTranslationsBtnTitle: 'Tier translation',
    adsPlatformAccess: "Advertiser search",

    newPostButtonTitle: "New post",
    newTierButtonTitle: "New tier",

    categoryInputLabel: "Category",

    scienceCategoryLabel: "Science",
    zcuCategoryLabel: "Armed Forces of Ukraine"

  },
  richEditor: {
    turnOn: "Turn on text editor",
    tunOff: "Turn off text editor",
  },
  editCreatorSocials: {
    screenTitle: "Socials",
  },
  editCreatorPrivacy: {
    screenTitle: 'Privacy',
    showPubliclyEarnedAmount: 'Show publicly how much you earn per month',
  },
  editCreatorGreetings: {
    screenTitle: 'Greetings',
    screenSubtitle: "We will use your greetings in emails for your subscribers",
    showGreetings: 'Show greetings for new subscribers',
    showGeneralGreeting: 'General',
    showTierSeparateGreeting: 'Unique for tier',
  },
  editTiers: {
    yoursTiers: 'Your tiers',
    yourCollaborationTiers: 'Your collaboration tiers',

    publishedTypeLabel: 'Published',
    waitingAcceptanceTypeLabel: 'Waiting Acceptance',
    declinedAcceptanceTypeLabel: 'Declined',
    expiredTypeLabel: 'Expired',
    cancelledTypeLabel: 'Cancelled',

    userHasWaitingResponseCollaborations:
      'You have collaborations that are waiting for your response',
  },
  editCreatorFriends: {
    screenTitle: 'Friends',
    friendsTitle: 'Friends',
    friendInputPlaceholder: 'Search creators to add to friends',
  },
  subscribers: {
    subscribersScreenHeader: 'Subscribers',
    subscriberList: 'List of subscribers',
    subscribersPerTier: 'Subscriber count per each subscription',
    noSubscribers:
      'No information available. Share with your audience by referring to your profile',
    exportSubscribers: 'Export to csv',
    lastExportAt: 'Last export:',
    nextExportAt: 'Next export:',
    subscribersNameLabel: 'Name',
    subscribersStatusLabel: 'Status',
    subscribersPriceLabel: 'Price',
    subscribersStartDateLabel: 'Subscription date',

    chartScrollAvilability:
      'You can scroll with keyboard keys or fingers on mobile',

    exportSuccessMessage: "Check your email",
    exportSuccessTitle: "Export succeed",

    statuses: {
      [ESubscriptionStatus.subscribed]: 'subscribed',
      [ESubscriptionStatus.unsubscribed]: 'unsubscribed',
    },

    lastSubscribersSectionTitle: "Last subscribers",

  },
  unathorizedSubscription: {
    successMessage: 'Letter with link for payment was send. Check you email',
  },
  tier: {
    buySubscription: 'Buy a subscription',
  },
  tierEdit: {
    tierDescription: 'Description of the tier',
    tierDescriptionInputPlaceholder: 'Write the description',
    tierTitleInputLabel: 'Tier title',
    tierTitleInputPlaceholder: 'Add a title',

    collabEditableByCreatorWarning: "Only initial creator could change all fields",

    tierCurrencyInputLabel: "Currency",
    tierPrice: 'The price of the monthly subscription',
    tierPriceInputPlaceholder: 'How much do you think it costs?',
    subscribersLimit: 'The number of subscribers is limited',
    addTier: 'Add tier',
    imageRequired: 'Add image',
    collaborationTitle: 'Collaboration',
    creatorsInputPlaceholder: 'Find partners for collaboration',
    creatorsNotFound: 'Creators not found',

    creatorSearchResult: 'Search results:',
    selectedCoCreators: 'Selected creators:',

    addTranslationBtnTitle: 'Add translation',
  },
  postScreen: {
    screenTitle: 'Post',
  },
  editTierTranslationScreen: {
    screenTitle: 'Tier translations',
  },
  editPost: {
    title: 'Title',
    titleInputPlaceholder: 'place for post title ...',
    description: 'Main Text',
    descriptionPlaceholder: 'Tell your followers something ',
    videoLink: 'Video ',
    videoLinkSubtitle: "Video will replace main photo",
    videoLinkPlaceholder: 'Here is a place to link to the video',
    submitNew: 'Create a post',
    saveDraft: 'Save draft',
    publicPost: 'Public post',
    postForSubscriber: 'For subscribers',
    tizerTitle: 'Post tizer',
    createTierFirst: 'First, add a paid subscriber',
    chooseTier: 'Choose Tier',
    tizerTitlePlaceholder:
      "Write a short description for people who haven't bought a subscription yet",
  },
  editPosts: {
    newPost: 'New post',
    publishedPosts: 'Published posts',
    drafts: 'Unpublished posts',
    noPosts: 'No articles. Please add your first post',
  },
  editCreatorGoals: {
    screenTitle: 'Your goals',
    goalHiddenText: 'Your goals are hidden',
    newGoalButtonTitle: 'New goal',
    emptyGoalListTitle: 'Add your first goal',
    goalForSubscribersLabel: 'Subscribers:',
    goalForEarningsLabel: 'Earnings:',

    subscribersGoalTypeLabel: 'Subscribers',
    earningsGoalTypeLabel: 'Earnings',
    noneGoalTypeLabel: 'Hide',
    showGoalSettingTitle: 'Show goal:',
    noGoalsListTitle: 'No goals :(.\nTry to change filters or create new goal',
  },
  editCreatorGoalScreen: {
    screenTitle: 'Goal',
    goalTitleLabel: 'Goal title',
    
    titleInputPlaceholder: 'Write short title for your goal',
    goalDescriptionLabel: 'Goal description',
    goalDescriptionInputPlaceholder: 'Why did you choose this goal',
    goalCountForEarningLabel: 'Target income amount',
    goalCountForSubscribersLabel: 'Target subscribers amount',
    addGoalButtonTitle: 'Add goal',

    subscribersGoalTypeLabel: 'Subscribers',
    earningsGoalTypeLabel: 'Earnings',
  },
  creatorGoals: {
    goalListTitle: 'Goals',
    prevButtonTitle: 'Prev',
    nextButtonTitle: 'Next',
    subscribersGoalTypeLabel: 'Subscribers',
    earningsGoalTypeLabel: 'Earnings',
  },
  validationSchema: {
    tooShort: 'Add more text',
    tooLong: 'Shorten text',
    requiredField: 'This field is required',
    invalidURL: 'Incorrect URL',
    invalidEmail: 'Incorrect email',
    minOneLetter: 'Minimum of one letter',
    minOneDigit: 'Minimum of one digit',
    confirmEmailIsNotCorrect: 'The emails do not match',
    confirmPasswordsNotCorrect: 'Passwords do not match',
    trim: 'Remove unnecessary spaces at the beginning and at the end',
    minimalPrice: 'Price shoud be higher that minimal',

    validURL: 'Provide valid url',
    minTierPrice: 'Price is too small',
    positiveNumber: "Number greeter than zero",
    numberRequired: 'Only numbers are allowed',
    phoneNumberInvalid: 'Phone number is not correct',
    colorIsInvalid: "Color is not correct",
  },
  withdrawMethods: {
    screenTitle: "Payments",
    liqpayDescription: 'Card authorization',
    paymentDescription: 'Reauthorize card',

    checkboxAllowCryptoPaymentTitle: "Allow users pay in crypto",
    paymentSettingTitle: "Payment settings",
  },
  payoutsPage: {
    accountBalance: 'Available funds',
    withdrawBtnTitle: 'Withdraw funds',
  },
  subscriptionPage: {
    screenTitle: "My subscriptinos",
    subscriptionsSettings: 'Subscription settings',
  },
  postsScreen: {
    findCreatorsBtnTitle: 'Find creators',
    noPosts:
      'Supporting financially or begin to track creators to see their posts',
    supportingListTitle: 'Support financially',
    followingListTitle: 'Following updates',
    seeAllComments: 'Get more comments',
    writeYourComment: 'Write a comment...',
    downloadMorePosts: 'See next',
    posts: 'Posts',
    creators: 'Creators',
    retryDownloadPosts: "Refresh",

    seeAllCommentsBtnTitle: "See all comments",
    subscirbersOnlyCommentMessage: "Only paid subscribers can leave comments",
  },
  postCard: {
    addEmodji: 'Add a emodji',
    toUnlockSubscribe: 'To unlock this post, become a subscriber',
  },
  contentTypes: {
    video: 'Video',
    text: 'Text',
    link: 'Link',
    poll: 'Poll',
  },
  editCustomizationScreen: {
    brandColorButtonSubTitle: "Buttons, icons and some other part will be affected on creator main page"
  },  
  profileSettings: {
    profileSettingHeader: 'Profile settings',
    nameInputLabel: 'Name',
    emailLabel: 'Email',
    lastNameInputLabel: 'Surname',
    profileImageLabel: "Profile photo"
  },
  userPrivacyScreen: {
    screenTitle: "Security",
    enableBiometryCheckboxTitle: "Enable biometry authentication (fingerprint scanner, etc) ",
  },
  viewedPostsScreen: {
    screenTitle: "Viewed posts",
    emptyListTitle: "There are no posts viewed by you",
  },
  profile: {
    addresses: 'Addresses',
    myorders: 'My orders',
    privatepolicy: 'Pricate policy',
    language: 'English',
    invitefriends: 'Invite friends',
    unlocktext: 'To unlock all feature please:',
    logintext: 'Log in/registeer',
    longpress: 'For language change long press is needed',
    profile: 'Profile',
    mycreatoraccount: 'Creator',
    creatorDetails: 'Creator details',
    help: 'Help',

    security: "Security",
    dltAccountBtnTitle: "Delete account",
    
    noListings: "User haven't got any listings yet",
    noReviews: "User haven't got any reviews yet",
    noBio: "The user doesn't say his bio",
    listings: 'Listings',
    reviews: 'Reviews',
    testTextBio:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, seddo eiusmod tempor incididu...',
    mywishlist: 'My wishlist',
    mysaveditems: 'My saved items',
    myfavorites: 'My favorites',
    settings: 'Settings',
    subscriptions: 'Subscriptions',
    myalerts: 'My Alerts',
    myitems: 'My Items',
    myaccount: 'My account',
    myvestiaire: 'My Vestiaire',
    mystats: 'My Stats',
    myselling: 'My selling',
    mydetails: 'My Details',
    pushnotifications: 'Push notifications',
    priceoffersent: 'Price offer sent',

    logoutButtonTitle: "Log out",
    languageChangeTitle: "Language",

    creatorRequestErrorMesssage: "Creator info request failed.",
    creatorLoadingMessage: "Downloading creator info ...",
  },
  termOfUse: {
    termOfUseTitle: 'Terms of Use',
    aboutCompany:
      'Sponsor.financial is an online platform that allows creators to receive payment from their members. Creators get more freedom than on other platforms.',
    liabilityTitle: 'Liability',
    liability:
      'If you lose money as a result of using the platform, any payments to you will be limited to the amount of money we received from the transaction. To the extent permitted by law, we will not be liable to you for any incidental, punitive damages resulting from these conditions, or for the use or attempted use of the platform. ',
    rateTitle: 'Tariffs',
    rate: 'The fee for the platform is 10%',
    yourAccountTitle: 'Your Account',
    yourAccount:
      "Any user can register and start supporting other authors with a monthly subscription. To do this, find the page of your favorite author, select a subscription and go to the subscription page. You will then be redirected to the payment page.  n  nYou must provide us with accurate information in good faith when creating your account, and you agree to update your information if it changes. You must be at least 12 years old to create an account. You must be at least 14 years old or have parental permission to join the creator's membership or pay a paid subscription. You are responsible for everything that happens when someone logs in to your account, as well as for the security of your account. Please contact us immediately if you believe your account has been compromised.",
    warantlyDisclaimerTitle: 'Disclaimer of warranties',
    warantlyDisclaimer:
      "The platform is provided 'as is' and without any warranty.",
    accountDeletionTitle: 'Deletion of the account',
    accountDeletion:
      "You can permanently delete your account by notifying us via support (via email or viber). We may terminate or suspend your account at any time in our sole discretion. We may also unsubscribe from any membership subscriptions and remove any descriptions, publications or preferences at our discretion. You cannot sue us to suspend or terminate another person's account, and you agree not to make such claims. If you try to make such a claim, you are liable for damages, including court costs and attorney's fees. These conditions remain in effect even if you no longer have an account.",
    aboutCreatorTitle: 'Authors',
    aboutCreator:
      'To become an author, simply fill in the basic information on your page. When you join the platform, you become part of the community. You can use the creative tools provided by the platform to showcase your creations and receive a steady income from your page. On the platform you can offer your sponsors something fascinating, which gives them unique benefits, as they want, for example, additional access, comrades, exclusivity and valuable experience. For your part, you get loyal support from your sponsors and a steady income from the membership the sponsors have acquired through our platform',
    sponsorTitle: 'Sponsors',
    aboutSponsors:
      'Anyone can become a sponsor by subscribing to a paid subscription on the site. All funds, except for the commission of the platform, will be transferred to the authors whose subscription was issued by the user. Price, term and other parameters depend on the subscription',
    paymentsTitle: 'Payments',
    payments:
      'As a creator, you make your membership available on the platform, and we make membership available to your members as a signatory. We also resolve payment issues such as fraud, chargebacks, and payment disputes. We try to provide immediate access to your funds, but sometimes you may experience delays in access to your funds. We may also block or withhold payments for violations of our policies or due diligence, including collection of tax information. If payments are blocked or withheld, we will try to notify you without delay of the reason. If you have questions about blocked payments, please contact us. To protect the creators, we can block payments to patrons if we think they are fraudulent. Sometimes such actions as returning money may negatively affect your account balance. If your balance becomes excessive, we reserve the right to deduct these funds from future payments',
  },
  publicOfferScreen: {
    screenTitle: 'Public offer',
  },
  notFoundScreen: {
    message: "Page is not found"
  },
  footer: {
    support: 'Support: ',
    aboutUs: 'About Us',
    termOfUse: 'Terms of use',
    publicOffer: 'Offer Agreement',
    publicCompanyAddress: 'Kyiv, Ukriane, 01103',
  },
  appLock: {
    biometryTitle: 'Use Face or Touch ID',
    touchIDconfig: {
      promptMessage: 'Authentication',
      fallbackLabel: 'Try to use Passcode',
    },
    delete: 'Delete',
    unlock: 'Unlock',
    cancel: 'Cancel',
    androidModalTitle: 'Sign in with fingerprint',
  },
  
  publicCreatorTiersScreen: {
    screenTitle: "Creator tiers",
  },
};
