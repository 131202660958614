export const postsTranslationsLocalization = {
  uk: {
    editPostTranslationScreen: {
      screenTitle: "Edit post translation",
      titleInputLabel: 'Заголовок',
      textInputLabel: 'Основний текст',
      tizerTitleInputLabel: 'Тізер',
    },
    creatorPostTranslationsScreen: {
      chooseTier: 'Виберіть допис',
      addTranslationButtonTitle: '+ Додати переклад',
      noTranslation: 'Не вдалося знайти переклади',
      screenTitle: "Переклади для дописів",
    },
  },
  en: {
    editPostTranslationScreen: {
      screenTitle: "Редагування перекладу для статті",
      titleInputLabel: 'Title',
      textInputLabel: 'Description',
      // todo: connect with form input field key
      tizerTitleInputLabel: 'Tizer',
    },
    creatorPostTranslationsScreen: {
      chooseTier: 'Choose a post',
      addTranslationButtonTitle: '+ Add translation',
      noTranslation: 'Translations are not found',
      screenTitle: "Posts translations",
    },
  },
};
