import {createSelector, createSlice} from '@reduxjs/toolkit';
import {takeEvery} from 'redux-saga/effects';
import {createAction} from 'typesafe-actions';
import {getStore, TMetaInput} from './common';
import {AnalyticsService, ApiService} from '../../services/src/index';
import {fetchSaga} from './common';
import {ESearchAnalyticsEventNames} from '../../search/search.types';

export const searchSlice = createSlice({
  name: 'search',
  initialState: {
    creators: [],
  },
  reducers: {
    searchCreatorsSuccess: (state, action) => {
      state.creators = action.payload;
    },
  },
});

export const SEARCH_CREATORS = 'search/searchCreators';
// Action creators are generated for each case reducer function
export const {searchCreatorsSuccess} = searchSlice.actions;

export const searchCreators = createAction(SEARCH_CREATORS)<
  {
    query: string;
  },
  TMetaInput
>();

export default searchSlice.reducer;

// selectors
export const selectFoundCreators = createSelector(
  getStore,
  state => state?.search.creators,
);

export function* searchFlow() {
  yield takeEvery(
    searchCreators,
    fetchSaga(ApiService.getCreators, {
      analytics: {
        logEvent: async event => await AnalyticsService.logEvent(event),
        onSuccessEvent: ESearchAnalyticsEventNames.UPLOAD_SEARCH_SUCCEEDED,
        onFailedEvent: ESearchAnalyticsEventNames.UPLOAD_SEARCH_FAILED,
      },
    }),
  );
}
