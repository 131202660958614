import {TCheckoutData} from 'cloudipsp-node-js-sdk';
import {NetworkService} from '../services';
import {ApiRequest, TWithdrawCreatorBalanceInput, TWithdrawCreatorBalanceOutput} from '../types';
import {
  TPostGetVerificationLinkOutput,
  TDeleteWithdrawCardInput,
  TDeleteWithdrawCardOutput,
  TGetCryptoWithdrawFeesInput,
  TGetCryptoWithdrawFeesOutput,
} from './money-withdraw.types';

export const postGetCardVerificationPymentLink = async (
  inputs: TCheckoutData,
): ApiRequest<TPostGetVerificationLinkOutput> => {
  return NetworkService.post('/v1/creator/verification-payment-link', inputs);
};

export const withdrawCreatorBalance = async (
  input: TWithdrawCreatorBalanceInput,
): ApiRequest<TWithdrawCreatorBalanceOutput> => {
  return NetworkService.post('/v1/creator/withdraw', input);
};

export const deleteWithdrawCard = async (
  params: TDeleteWithdrawCardInput,
): ApiRequest<TDeleteWithdrawCardOutput> => {
  return NetworkService.delete('/v1/creator/withdraw-cards', params);
};

export const getCryptoWithdrawFees = async (
  params: TGetCryptoWithdrawFeesInput
): ApiRequest<TGetCryptoWithdrawFeesOutput> => {
  return NetworkService.get(`/v1/creator/withdraw/fees/${params?.currency}`);
};

//todo: probably move verification methods to separate feature
export const MoneyWithdrawApiService = {
  postGetCardVerificationPymentLink,
  deleteWithdrawCard,
  withdrawCreatorBalance,

  getCryptoWithdrawFees
};
