import {
  Button,
  View,
  Image,
  Row,
  Text,
  TierBase,
  ContentContainer,
  TierList,
  Checkbox,
  renderFormInput,
  TFormInputInfo,
} from 'components';
import {Formik} from 'formik';
import React, {useCallback, useMemo, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {xor, isEmpty} from 'lodash';
import {
  Platform,
  ScrollView,
  StyleSheet,
  TouchableOpacity,
} from 'react-native';
import moment from 'moment';
import {EscreenNames} from 'navigation';
import {decodeHTMLEntities, isMobile} from 'utils';
import {ActivityIndicator} from 'react-native';
import {i18n} from 'localize';
import {
  uploadPost,
  selectPostById,
  addDraft,
  selectTiers,
  TStore,
  updatePost,
  removePost,
} from 'store';
import {EPostEnum, EPostPublishedStatus} from 'types';
import {getPostSchema} from './postValidationSchema';
import {colors} from '@sponsor/assets';
import {useDispatchAction, useNavigation, useParams} from 'hooks';
import {alertMessage} from 'utils';
import {
  TIER_WIDTH,
  TIER_SEPARATOR_WIDTH,
  TIERS_CONTAINER_MARGIN_LEFT,
  DEVICE_WIDTH,
} from '@sponsor/constants';
import {useBeforeUnload} from '../../../hooks/useBeforeUnload/useBeforeUnload';
import { useImagePicker } from '../../../hooks/useImagePicker';
import { ConfirmationDialog } from 'components/src/ConfirmationDialog';

/**
 * this screen provide an opportunity to edit or create new posts
 * local posts could be saved locally only
 * remote posts couldn't be saved locally, only remotely or deleted
 */

const getNewPostLocalId = () => moment().toISOString();

const toggleArraItem = (item: any, arr: any[]): any[] => {
 return xor(arr, [item])
}

export const EditPostPage = () => {
  const {id: postId} = useParams();

  const selectedPost = useSelector((state: TStore) =>
    selectPostById(state, postId || ''),
  );
  const creatorTiers = useSelector(selectTiers);

  const [IsLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  const dispatch = useDispatch();
  const {navigate} = useNavigation();

  useBeforeUnload(true, i18n.t('unsavedChangesWarning'));

  const {openPicker: pickImage, image, error:imageError} = useImagePicker({
    initial: {uri: selectedPost?.imageUri},
    base64: true,
    maxHeight: 2000,
    minHeight: 100,
    maxWidth: 2000,
    minWidth: 100,
  })

  const handlePostSubmit = useCallback(
    values => {
      const tiers = values?.tiers?.length  ? values?.tiers?.trim?.()?.split?.('-') : []
      setIsLoading(true);
      dispatch(
        uploadPost(
          {
            ...values,
            image: image?.uri,
            tiers,
            id: selectedPost?.id || getNewPostLocalId(), // draft could have id
          },
          {
            onFail: err => setError(err?.message),
            onSuccess: () => navigate(EscreenNames.CreatorPostsEdit),
            onFinish: () => setIsLoading(false),
          },
        ),
      );
    },
    [navigate, image, selectedPost, dispatch],
  );

  const handleUpdate = useCallback(
    values => {
      const tiers = values?.tiers?.length  ? values?.tiers?.trim?.()?.split?.('-') : []
      setIsLoading(true);
      dispatch(
        updatePost(
          {
            ...values,
            id: selectedPost?.id,
            image: image?.uri !== selectedPost?.imageUri ? image?.uri  : undefined,
            tiers: tiers.length > 0 ? tiers : undefined,
          },
          {
            onFail: err => setError(err.message),
            onSuccess: () => navigate(EscreenNames.CreatorPostsEdit),
            onFinish: () => setIsLoading(false),
          },
        ),
      );
    },
    [dispatch, image, selectedPost, navigate],
  );

  const handleSaveLocally = useCallback(
    values => {
      const tiers = values?.tiers?.length  ? values?.tiers?.trim?.()?.split?.('-') : []
      dispatch(
        addDraft({
          ...values,
          id: moment().toISOString(),
          tiers,
        }),
      );
      navigate(EscreenNames.CreatorPostsEdit, {postStatus: EPostPublishedStatus.draft});
    },
    [dispatch, navigate],
  );

  //todo: rename removePost -> deletePost
  const {
    submitAction: handleDelete
  } = useDispatchAction(removePost, {
      data: {id: postId},
      // onSuccess: () => navigate(EscreenNames.CreatorPostsEdit)
  })


  const isCreatingNewPost = useMemo(
    () => isEmpty(selectedPost?.id),
    [selectedPost],
  );

  const isLocalPost = useMemo(
    () => moment(selectedPost?.id).isValid(),
    [selectedPost],
  );

  //TODO: add available keys for inputs 
  const fields:TFormInputInfo[] = [
    {
      name: "title",
      type: 'text',
      label:i18n.t('editPost.title'),
      placeholder:i18n.t('editPost.titleInputPlaceholder'),
      requiredField: true,
    },
    {
      name: "text", //TODO: sync naming, text or description ???
      type: 'richText',
      label:i18n.t('editPost.description'),
      placeholder:i18n.t('editPost.descriptionPlaceholder'),
    },
    {
      name: "videoLink",
      type: 'text',
      label:i18n.t('editPost.videoLink'),
      subLabel: i18n.t("editPost.videoLinkSubtitle"),
      placeholder:i18n.t('editPost.videoLinkPlaceholder'),
      numberOfLines:1,
    },
    {  
      name: 'tizerTitle',
      type: 'text',
      label:i18n.t('editPost.tizerTitle'),
      placeholder:i18n.t('editPost.tizerTitlePlaceholder'),
      multiline: true,
      numberOfLines:5,
    }
  ];

  return (
    <ContentContainer>
      <ScrollView>
        <Formik
          initialValues={{
            id: selectedPost?.id,
            title: selectedPost?.title,
            text: selectedPost?.text || '',
            videoLink: selectedPost?.videoLink,

            // TODO: add another types
            contentType: 'text',
            visibilityType: selectedPost?.visibilityType || EPostEnum.public,
            tizerTitle: selectedPost?.tizerTitle,

            // tier: selectedPost?.tier,
            tiers:  selectedPost?.tiers.length ? selectedPost?.tiers?.join?.('-') : ""
            // tizer_image: selectedPost?.tizer_image,
          }}
          validationSchema={getPostSchema()}
          onSubmit={values => {
            (isCreatingNewPost || isLocalPost) ? handlePostSubmit(values) : handleUpdate(values);
          }}
        >
          {({values, touched, isSubmitting, setTouched, errors, handleSubmit, handleChange}) => (
            // TODO: remove repeated container styles
            <View variant='screenContainer'>
              <View style={{justifyContent: 'flex-start'}}>
                {fields.map((field) => (
                    renderFormInput({
                      ...field,
                      value: values?.[field?.name as unknown as keyof typeof  values],
                      handleChange: (data:any) => {
                        if(field?.name){
                          setTouched({[field?.name]: true})
                        }
                        return handleChange(field?.name)(data?.value)
                      },
                      error: (touched?.[field?.name as unknown as keyof typeof  values] || isSubmitting) ? errors?.[field?.name  as unknown as keyof typeof errors] : " ",
                    })
                ))}
          
                <View variant="sectionContainer">
                  {image?.uri && (
                    <Image variant="postImage" source={{uri: image?.uri}} />
                  )}
                  {/* <View style={{ marginTop: 25 }}>
                    {!image && (
                      <Text style={{ color: colors.RED }}>
                        {i18n.t("tierEdit.imageRequired")}
                      </Text>
                    )}
                  </View> */}

                  <Button
                    title={i18n.t('pickPhoto')}
                    onPress={pickImage}
                    containerStyle={{paddingVertical: 15}} //todo: standarlize image buttons
                  />
                <TouchableOpacity onPress={() => pickImage({allowsEditing: false})}> 
                  <Text>+ gif</Text>
                </TouchableOpacity>
                  <Text variant="formHint">{i18n.t('imageSizeLimit')}</Text>
                  <Text variant='inputError'>
                      {imageError}
                  </Text>
                </View>
              </View>

              <View variant='sectionContainer'>
                <View>
                  <Checkbox 
                    title={i18n.t('editPost.publicPost')}
                    onPress={() => handleChange('visibilityType')(EPostEnum.public)}
                    value={values?.visibilityType === EPostEnum.public}
                  />
                </View>
                <View variant='checkboxContainer'>
                  <Checkbox 
                    title={i18n.t('editPost.postForSubscriber')}
                    onPress={() => {
                      if (isEmpty(creatorTiers)) {
                        alertMessage('', i18n.t('editPost.createTierFirst'));
                      } else {
                        handleChange('visibilityType')(EPostEnum.subscribers_only);
                      }
                    }}
                    value={values?.visibilityType === EPostEnum.subscribers_only}
                  />
                </View>
                {/* TODO: add another types - paid_only */}
              </View>
              {/* TODO: create standalone horizontal list with tiers */}
              {values?.visibilityType === EPostEnum.subscribers_only && (
                <View style={styles.tierContainer}>
                  <Text variant="secondaryTitle">
                    {i18n.t('editPost.chooseTier')}
                  </Text>
                  <TierList 
                      data={creatorTiers}
                      renderItem={({item}) => (
                        <TouchableOpacity
                          onPress={() => {
                            const oldTiers = values?.tiers?.length ? values?.tiers.split('-') : []
                            const newTiers = toggleArraItem(item?.id, oldTiers)
                            // todo
                            handleChange('tiers')(newTiers.join("-"))
                          }}>
                          <TierBase
                            containerStyle={
                              // todo: optimize 
                              values?.tiers.includes(item?.id)
                                ? {
                                  borderColor: colors.GREEN_DARK
                                }
                                : {
                                  marginHorizontal: TIER_SEPARATOR_WIDTH / 2, 
                                  //TODO: add screen padding constant ???
                                  width: isMobile ? DEVICE_WIDTH - TIER_SEPARATOR_WIDTH - 25 : TIER_WIDTH, 
                                  maxWidth: '100%'
                                }
                            }
                            {...item}
                          />
                        </TouchableOpacity>
                      )}
                  />
                </View>
              )}
              <Row // add autodetecting needs of usage flexWrap, prevent bugs in future
                containsSimilarItems 
                columnCenter 
                flexWrap
              > 
                  <Button
                    title={
                      isCreatingNewPost || isLocalPost
                        ? i18n.t('editPost.submitNew')
                        : i18n.t('submitEdit')
                    }
                    onPress={handleSubmit}
                  />
                  {isLocalPost && (
                    <Button
                      title={i18n.t('editPost.saveDraft')}
                      onPress={() =>
                        !isCreatingNewPost
                          ? handleUpdate(values)
                          : handleSaveLocally(values)
                      }
                    />
                  )}
                  {!!postId && (
                      <ConfirmationDialog
                            title={i18n.t("dialogs.deletionConfirmationDialogTitle")}
                            desc={i18n.t("dialogs.confirmationDialogTitle")}
                            onPress={() => handleDelete()}
                            render={({onPress}) => (
                              <TouchableOpacity onPress={onPress}>
                                <Text variant='dangerous'>{i18n.t('delete')}</Text>
                            </TouchableOpacity>
                          )}
                      />
                  )}
              </Row>
              {IsLoading && <ActivityIndicator />}
              <Text variant="inputError">{error}</Text>
            </View>
          )}
        </Formik>
      </ScrollView>
    </ContentContainer>
  );
};

const styles = StyleSheet.create({
  tierContainer: Platform.select({
    web: {
      paddingTop: 50,
      paddingBottom: 25,
    },
    default: {
      // marginRight: 10,
      // marginLeft: TIERS_CONTAINER_MARGIN_LEFT,
    },
  }),
  tiersContentContainer: {
    outline: 'none',
    justifyContent: 'center',
    flex: 1,
    // maxWidth: DEVICE_WIDTH - TIERS_CONTAINER_MARGIN_LEFT,
    // justifyContent:'center',
  },
});
