import {createSelector} from '@reduxjs/toolkit';
import {ECreatorWithdrawMethodsTypes} from '../money-withdraw-methods/money-withdraw-methods.types';
import {filter} from 'lodash';
import {getStore} from 'store';

/**
 *
 */
export const selectCreatorMerchantsForWithdraw = createSelector(
  getStore,
  store =>
    filter(
      store?.creator?.withdrawMethods,
      w => w.type === ECreatorWithdrawMethodsTypes.merchant_entrepreneur,
    ),
);
