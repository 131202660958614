import React from 'react';
import { useNavigation } from 'hooks';
import { useSelector } from 'react-redux';
import { TStore } from 'store';
import { selectCreatorRevenueById } from './creator-revenue.selectors';
import { CreatorRevenueListItem } from './CreatorRevenueListItem.component';
import { EscreenNames } from 'navigation';
import { ECurrencyTypes } from '../payment/payments.types';
import { toLower } from 'lodash';

type TProps = {
  id: string;
};

export const CreatorRevenueListItemContainer: React.FC<TProps> = ({id}) => {

  const creatorBalance = useSelector((state: TStore) =>
    selectCreatorRevenueById(state, id),
  );

  const {
    navigate
  } = useNavigation();

  return (
    <CreatorRevenueListItem
      currency={creatorBalance?.currency}
      amount={creatorBalance?.amount || 0}
      onWithdrawPress={() => navigate(
        toLower(creatorBalance?.currency?.type) === ECurrencyTypes.crypto_coin
        ? EscreenNames.CryptoWithdrawalScreen
        : EscreenNames.FiatWithdrawalScreen
        , 
        {
          currencyName: creatorBalance?.currency?.name,
        })
      }
    />
  );
};
