import {ScrollView, StyleSheet} from 'react-native';
import React from 'react';
import {Button, Column, Row, Text, View} from 'components';
import {i18n} from 'localize';
import { ICurrency } from '../payment/payments.types';
import { toLower } from 'lodash';

type TProps = {
  onWithdrawPress: () => void;
  amount: number;
  currency?: ICurrency;
};

export const CreatorRevenueListItem: React.FC<TProps> = ({
  onWithdrawPress,
  amount,
  currency,
}) => {
  return (
      <Row variant="tableRow">
        <Column columnCenter width={75}>
          <Text   
            selectable 
            variant="tableCeilText"
          >
            {currency?.name}
          </Text>
        </Column>

        <Column columnCenter width={120}>
          <Text 
            testID={`${toLower(currency?.name)}-balance-amount`}
            variant="tableCeilText"
          >
            {
            amount // todo: format number 
            }
          </Text>
        </Column>
        {!!onWithdrawPress && (
          <Column rowCenter width={150}>
            <Button
              onPress={onWithdrawPress}
              containerStyle={{alignSelf: 'flex-end'}}
              //todo: add limits for currencies codes
              title={`${i18n.t('myRevenuePage.withdrawBalance')} ${currency?.name}`}
            />
          </Column>
        )}
    </Row>
  );
};

const styles = StyleSheet.create({
  ceilContainer: {
    padding: 10,
  },
});
