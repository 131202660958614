import React, { useEffect, useState } from 'react';
import * as Font from 'expo-font';
import { AppContainer } from './app/screens/src/AppContainer';

export default () => {

    const [fontsLoaded, setFontLoaded] = useState(false);
    
    useEffect(() => {
        const loadFonts = async () => {
            await Font.loadAsync({
                icomoon: require('./app/assets/src/icons/icomoon.ttf'),
              });
            await Font.loadAsync({
                'GolosText': require('./app/assets/src/GolosText-VariableFont_wght.ttf'),
            })
            setFontLoaded(true);
        }
        loadFonts()
    }, []);
    
    if (fontsLoaded) {
        return <AppContainer />;
    }
    return null;  
}
