import React, {useCallback, useMemo, useState} from 'react';
import {
  selectUser,
  togglePostEmodji,
  selectPublicPostById,
  TStore,
} from 'store';

import {EscreenNames} from 'navigation';
// import ParsedText from 'react-native-parsed-text';
import {useDispatch, useSelector} from 'react-redux';
import {forEach, includes, isEmpty, reduce} from 'lodash';
import {useNavigation} from 'hooks';
import {MAX_EMODJI_COUNT_PER_USER} from '@sponsor/constants';
import {PostBase} from './PostBase';
import {View, EmojiPicker} from 'components';
import {PostEmoji} from './PostEmoji.component';
import {PostHeaderContainer} from './PostHeader';
import {Icon, Row, Text} from '..';
import {StyleSheet, TouchableOpacity} from 'react-native';
import {TPost, TViewCustomization} from 'types';
import {DEFAULT_POST_CONTAINER_WIDTH} from '../../../posts/posts.constants';
import { i18n } from 'localize';

type TProps = {
  postId: string;
  customization?: TViewCustomization;
  width?: number | string;
  reduxSelector?: any;
  onToggleEmodji?: (params: {emodjiUnicode: string; post: string}) => void;
};

export const PostContainer: React.FC<TProps> = React.memo(
  ({
    postId,
    customization,
    reduxSelector,
    //todo: replace by flex ???
    width = DEFAULT_POST_CONTAINER_WIDTH,
    onToggleEmodji,
    ...otherProps
  }) => {
    const {btnColor = 'black'} = customization || {};

    const post:TPost = useSelector((state: TStore) =>
      reduxSelector
        ? reduxSelector?.(state, postId) // TODO: add typing for selector prop
        : selectPublicPostById(state, postId),
    );
    console.log('post',post)
    const user = useSelector(selectUser);
    const dispatch = useDispatch();
    const {navigate} = useNavigation();

    const {id, reactions, unlocked} = post || {};

    const [emodjiPicking, setEmodjiPicking] = useState(false);
    const toggleEmodjiPicking = useCallback(() => {
      setEmodjiPicking(isPicking => !isPicking);
    }, []);

    const userCanLeaveEmodji = useMemo(() => {
      if (!unlocked) {
        return false;
      }
      if (isEmpty(user?.id)) {
        return false;
      }
      return (
        reduce(
          post?.reactions?.emodji,
          (s, usersIdx) => {
            if (includes(usersIdx, user?.id)) {
              s += 1;
            }
            return s;
          },
          0,
        ) <= MAX_EMODJI_COUNT_PER_USER
      );
    }, [unlocked, post?.reactions, user?.id]);

    const triggerToggleEmodji = useCallback(
      (_, emojiObject: any) => {
        dispatch(
          togglePostEmodji(
            {
              creatorId: post?.creator?.id || '',
              emodjiUnicode: emojiObject?.unified,
              postId: id,
            },
            {
              onFinish: () => {
                setEmodjiPicking(false);
                onToggleEmodji?.(emojiObject?.unified);
              },
            },
          ),
        );
      },
      [onToggleEmodji, post?.creator?.id, dispatch, id],
    );

    //todo: move logic to feature (tier?)
    const goToPaymentPage = useCallback(() => {
      console.log('post',post?.tiers)
      // 
      if(post?.tiers?.length > 1){
        // todo: typing for navigation, what params are possible 
        navigate(EscreenNames.CreatorPublicTiersScreen, {
            pageURL: post?.creator?.pageURL,
           tiersIds: post?.tiers?.join(',')
        });
        return;
      }
      else if(post?.tiers?.length == 1){
        navigate(EscreenNames.TierBuy, {tierId: post?.tiers?.[0]});
        return;
      }
      navigate(EscreenNames.TierBuy, {tierId: post?.tier});
    }, [post?.tiers, post?.tier, navigate]);

    // console.log('reactions', reactions);

    const emojiInfo = useMemo(() => {
      const emoji: any[] = [];
      forEach(reactions?.emodji, (ids, emojiUnicode) => {
        //TODO: fix, not all emojii work
        const codePoint = parseInt(emojiUnicode, 16);
        const count = ids.length;
        const isEmodjiLeftByUser = includes(ids, user?.id);

        if (codePoint && count > 0) {
          emoji.push({
            leftByUser: isEmodjiLeftByUser,
            count,
            emoji: String.fromCodePoint(codePoint),
            emojiUnicode,
          });
        }
      });

      return emoji;
    }, [reactions?.emodji, user?.id]);

    if(!post || !post?.id){
      console.log('no found')
      return <Text center variant="secondaryTitle">
        {i18n.t("errors.itemIsMissed", {itemName: i18n.t('post')})}
      </Text>
    }

    return (
      <View 
        bordered 
        style={{
          width, 
        }}
      >
        <PostBase
            {...otherProps}
            post={post}
            viewCustomization={{
              btnColor,
            }}
            Header={<PostHeaderContainer  
                translations={post.translations} 
                id={post.id} 
                creator={post?.creator} 
                unlocked={unlocked}
                />}
            onLockedPostPress={goToPaymentPage}
        >
            {userCanLeaveEmodji && <Row>
              <Row columnCenter>
                <TouchableOpacity
                  disabled={!userCanLeaveEmodji}
                  style={[styles.smileAddBtn, {borderColor: btnColor}]}
                  onPress={toggleEmodjiPicking}
                >
                  <Icon color={btnColor} name="smile" size={20} />
                  <Text style={{fontSize: 22, color: btnColor}}> + </Text>
                </TouchableOpacity>
              </Row>
              {/* todo: add test that emodji is visible if user cannot leave one */}
              <PostEmoji
                emojiInfo={emojiInfo}
                userCanLeaveEmodji={userCanLeaveEmodji}
                onEmojiPress={triggerToggleEmodji}
              />
            </Row>}
        </PostBase>
        {emodjiPicking && (
          <EmojiPicker
            onEmojiClick={triggerToggleEmodji}
            onClose={toggleEmodjiPicking}
            pickerStyle={{height: '450px', width: '100%'}}
          />
        )}
      </View>
    );
  },
);

const styles = StyleSheet.create({
  smileAddBtn: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 5,
    borderWidth: 0.5,
    paddingHorizontal: 8,
    marginRight: 10,
    height: 50,
    width: 60,
  },
});
