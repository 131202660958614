import React, {useCallback} from 'react';
import {Button} from './Button';
import {useSelector} from 'react-redux';
import {selectUser} from 'store';
import {useNavigation} from 'hooks';
import {isEmpty} from 'lodash';
import {EscreenNames} from 'navigation';
import {TButtonProps} from './Button';

type TProps = {
  onPress?: () => void;
};

export const OnlyAuthorizedButton: React.FC<TButtonProps & TProps> = ({
  onPress,
  ...props
}) => {
  const user = useSelector(selectUser);
  const {navigate} = useNavigation();

  const authorizedOnlyOnPress = useCallback(() => {
    if (isEmpty(user) || !user?.id) {
      navigate(EscreenNames.Login);
    } else {
      onPress?.();
    }
  }, [navigate, user, onPress]);

  return <Button onPress={authorizedOnlyOnPress} {...props} />;
};
