import React from 'react';
import {useNavigation, useParams} from 'hooks';
import {useDispatchAction} from 'hooks';
import {
  Button,
  ContentContainer,
  FormInput,
  FormInputLabel,
  LanguagePicker,
  RichEditor,
  ScrollViewOnlyOnMobile,
  Text,
  View,
} from 'components';
import {i18n} from 'localize';
import {Formik} from 'formik';
import {useSelector} from 'react-redux';
import {selectPostTranslationById} from './posts-translations.selectors';
import {selectPostById, TStore} from 'store';
import { decodeHTMLEntities, isMobile } from 'utils';
import { BackHeader } from '../containers/Header/BackHeader';
import { Platform } from 'react-native';
import { updatePostTranslation, uploadPostTranslation } from './posts-translations.actions';
import { useTranslationsLanguages } from '../translations/useTranslationsLanguages';

export const EditPostTranslationsScreen = () => {
  const {id, parentId} = useParams();

  const {goBack} = useNavigation();
  const translationsLanguages = useTranslationsLanguages()
  const {
      isLoading, 
      error, 
      submitAction: submitPostTranslationUploading
  } = useDispatchAction(
      uploadPostTranslation,
      {
        onSuccess: goBack
      }
  );

  const {
      isLoading: isPostUpdating, 
      error: postUpdateError, 
      submitAction: submitPostTranslationUpdating
  } = useDispatchAction(
      updatePostTranslation,
      {
        onSuccess: goBack
      }
  );

  //todo: add general naming, such as editing entity
  const editedEntity = useSelector((state: TStore) =>
    selectPostTranslationById(state, id, parentId),
  );
  console.log('editedEntity',editedEntity)
  const parentItem = useSelector((state:TStore) => selectPostById(state, parentId))
    
  return (
    <ContentContainer>
      <ScrollViewOnlyOnMobile>
        {!isMobile && <BackHeader />}
        <Formik
          enableReinitialize
          initialValues={{
              lang: editedEntity?.lang || "uk",
              title: editedEntity?.title || "",
              tizerTitle: editedEntity?.tizerTitle || "",
              text: editedEntity?.text || "",
              parentId,
              id: editedEntity?.id || '',
            }
          }
          // validationSchema={getTierTranslationValidationSchema()}
          onSubmit={(values) => {
            if(editedEntity?.id){
              submitPostTranslationUpdating(values)
              return;
            }
            // console.log('upload translation')
            submitPostTranslationUploading(values)
          }}
      >
          {({values, handleChange, handleSubmit, errors}) => (
            <View style={{padding: 15}}>
              <View 
                variant="sectionContainer"
              >
                {/* primary keys cannot be changed in already created entity
                            that's why we disable language picker

                            todo: add helper function, hook,
                                think about unificaiton
                        */}
                {/* TODO: center or flex-start */}
                <Text center variant='thirdTitle'>
                  {parentItem?.title}
                </Text>
                <View style={{marginTop: 15,alignItems: 'center'}}>
                  <FormInputLabel
                    label={i18n.t('chooseLanguagePickerLabel')} 
                    requiredField
                  />
                  <LanguagePicker
                    value={values?.lang}
                    // todo: add test here
                    langs={translationsLanguages}
                    enabled={!editedEntity}
                    onLanguageChange={lang => handleChange('lang')(String(lang))}
                  />
                </View>
              </View>
              {!!values?.lang && (
                <>
                  {/* todo: move to the array */}
                  <FormInput
                    requiredField
                    label={i18n.t('editPostTranslationScreen.titleInputLabel')}
                    value={values.title}
                    numberOfLines={2}
                    onChangeText={handleChange('title')}
                    error={errors.title}
                  />
                  <FormInput
                    label={i18n.t('editPostTranslationScreen.tizerTitleInputLabel')}
                    value={values.tizerTitle}
                    numberOfLines={2}
                    onChangeText={handleChange('tizerTitle')}
                    error={errors.tizerTitle}
                  />
                  <FormInputLabel
                    label={i18n.t('editPostTranslationScreen.textInputLabel')}
                  />
                    <RichEditor
                      html={decodeHTMLEntities(values.text)}
                      onChange={(descriptionText: string) => {
                        handleChange('text')(descriptionText);
                      }}
                    />
                  <Text variant="inputError">{errors?.text}</Text>

                  <Button
                    primary
                    disabled={isLoading || isPostUpdating}
                    isLoading={isLoading || isPostUpdating}
                    title={i18n.t('saveSettings')}
                    onPress={handleSubmit}
                  />
                  {error && <Text variant="inputError">{error || postUpdateError}</Text>}
                </>
              )}

            </View>
          )}
        </Formik>
      </ScrollViewOnlyOnMobile>
    </ContentContainer>
  );
};
