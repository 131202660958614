import { colors } from "@sponsor/assets";
import { DEVICE_WIDTH } from "@sponsor/constants";
import { Platform, StyleSheet } from "react-native";

export const imageStyles = StyleSheet.create({
    tierImage: {
      width: 200,
      height: 200,
      borderRadius: 10,
    },
    profileImage: Platform.select({
      web: {
        width: 222,
        height: 222,
        borderRadius: 150,
        borderWidth: 0.5,
        borderColor: colors.DUSTY_GRAY,
      },
      default: {
        width: 125,
        height: 125,
        borderRadius: 75,
        borderWidth: 0.5,
        borderColor: colors.DUSTY_GRAY,
      }
    }),
    postPreview: {
      width: 100,
      height: 100,
    },
    logo: {
      width: 125,
      height: 75,
      resizeMode: 'contain',
    },
    postImage: {
      resizeMode: 'contain',
      width: '100%',
      height: 300,
    },
    mainCreatorImage: {
      // zIndex: 1,
      aspectRatio: 4 / 1, 
      height: Platform.OS === 'web' ? 350 : undefined, 
      width: DEVICE_WIDTH,
      resizeMode: 'cover',
      borderBottomWidth: 1,
      // borderBottomColor: colors.DUSTY_GRAY,
    },
  });
  