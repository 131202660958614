import {createSelector} from '@reduxjs/toolkit';
import { ECreatorWithdrawMethodsTypes } from '../money-withdraw-methods/money-withdraw-methods.types';
import {filter, find} from 'lodash';
import {getStore, TStore} from 'store';

//todo: replace with object
export const selectWithdrawCardsState = createSelector(
  getStore,
  state => filter(state.creator.withdrawMethods, w => w.type === ECreatorWithdrawMethodsTypes.card)
);

//todo: replace with object
export const selectWithdrawCryptoWallet = createSelector(
  getStore, 
  state => {
    return filter(state.creator.withdrawMethods, p => p.type ==  ECreatorWithdrawMethodsTypes.crypto_wallet);
  }
);

export const selectMerchants = createSelector(getStore, state => {
  return filter(state.creator.withdrawMethods, p => p.type ==  ECreatorWithdrawMethodsTypes.merchant_entrepreneur);
});

export const selectCardInfoById = createSelector(
  [selectWithdrawCardsState, (state: TStore, cardId: string): string => cardId],
  (cards, cardId) => {
    return find(cards, c => c?.id === cardId);
  },
);
