import {Config} from 'libs';
import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
} from 'axios';
import {ApiService} from './ApiService';
import {
  INetworkAuthorizationServiceInterface,
  NetworkAuthorizationService,
} from './NetworkAuthorizationService';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import {logoutStart} from 'store';

export const REQUEST_TIMEOUT = 7500;

type TAxiosConfig = AxiosRequestConfig & {
  [key in string]: any;
};

class _NetworkService {
  private NetworkAuthorizationService: INetworkAuthorizationServiceInterface;
  private onReadyCallback: any;

  private refreshAuthLogic = async (failedRequest: AxiosError) => {
    const refreshToken =
      this.NetworkAuthorizationService.getAuthParams()?.refresh?.token;
    if (!refreshToken) {
      this.NetworkAuthorizationService.storeDispatch?.(logoutStart());
      await this.NetworkAuthorizationService.setAuthParams(null);
      return;
    }
    try {
      const {data} = await ApiService.postRefreshToken(refreshToken, {
        skipAuthRefresh: true,
      });
      if (failedRequest?.response?.config?.headers) {
        failedRequest.response.config.headers.Authorization =
          'Bearer ' + data.access.token;
      }

      await this.NetworkAuthorizationService.setAuthParams(data);
    } catch (err) {
      await this.NetworkAuthorizationService.setAuthParams(null);
      this.NetworkAuthorizationService?.storeDispatch?.(logoutStart());
    }
  };

  private axiosInstance: AxiosInstance = axios.create({
    baseURL: Config.API_URL,
    // baseURL: "https://d59c-176-113-167-41.eu.ngrok.io",
    timeout: REQUEST_TIMEOUT,
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
  });

  constructor(authorizationService: typeof NetworkAuthorizationService) {
    this.NetworkAuthorizationService = authorizationService;
    createAuthRefreshInterceptor(this.axiosInstance, this.refreshAuthLogic, {
      pauseInstanceWhileRefreshing: true,
      statusCodes: [401]
    });
    this.axiosInstance.interceptors.request.use(
      config => {
        if (!config.headers?.Authorization) {
          config.headers = {
            ...(config.headers || {}),
            Authorization: `Bearer ${
              this.NetworkAuthorizationService.getAuthParams()?.access?.token
            }`,
          };

          return config;
        }
      },
      error => {
        return Promise.reject(error);
      },
    );
    this.init();
  }

  private init = async (): Promise<void> => {
    const KCAuthParams =
      await this.NetworkAuthorizationService.getKCAuthParams();
    if (KCAuthParams) {
      this.axiosInstance.defaults.headers.common.Authorization = `Bearer ${KCAuthParams?.access?.token}`;
    }

    // this.setAxiosInterceptors();
    this.onReadyCallback?.();
  };

  // Api
  private request = async <T>(
    url: string,
    method: TAxiosConfig['method'],
    data: any,
    config?: TAxiosConfig,
  ): Promise<AxiosResponse<T>> => {
    return this.axiosInstance.request({
      ...config,
      url,
      method,
      /**
       * on iOS13, it is not allowed to add a body in GET request
       * https://stackoverflow.com/questions/56955595/1103-error-domain-nsurlerrordomain-code-1103-resource-exceeds-maximum-size-i
       */
      data: data ? data : undefined,
    });
  };

  public get = async <T>(
    url: string,
    config?: TAxiosConfig,
  ): Promise<AxiosResponse<T>> => this.request<T>(url, 'GET', null, config);

  public post = async <T>(
    url: string,
    data?: any,
    config?: TAxiosConfig,
  ): Promise<AxiosResponse<T>> => this.request<T>(url, 'POST', data, config);

  public put = async <T>(
    url: string,
    data?: any,
    config?: TAxiosConfig,
  ): Promise<AxiosResponse<T>> => this.request<T>(url, 'PUT', data, config);

  public patch = async <T>(
    url: string,
    data?: any,
    config?: TAxiosConfig,
  ): Promise<AxiosResponse<T>> => this.request<T>(url, 'PATCH', data, config);

  public delete = async <T>(
    url: string,
    data?: any,
    config?: TAxiosConfig,
  ): Promise<AxiosResponse<T>> => this.request<T>(url, 'DELETE', data, config);

  public onReady = (callback: any) => {
    this.onReadyCallback = callback;
  };
}

export const NetworkService = new _NetworkService(NetworkAuthorizationService);
