import {StyleSheet, TouchableOpacity, FlatList, Platform, ScrollViewProps} from 'react-native';
import React, {useCallback, useMemo, useState} from 'react';
import {
  Image,
  PostContainer,
  CreatorProfileCard,
  AboutAuthor,
  Text,
  View,
  ContentContainer,
  CreatorGoalList,
  ScrollViewOnlyOnMobile,
  LanguagePicker,
  Row,
  Button,
  viewStyles,
} from 'components';
import {useNavigation, useParams} from 'hooks';
import {isEmpty, keys, map, round, values} from 'lodash';
import {i18n} from 'localize';
import {useActiveLanguage, useFetchCreatorInfo} from 'hooks';
import {TIER_WIDTH} from '@sponsor/constants';
import {TCreatorGoalType} from 'types';
import {LoadingPlaceholder} from './LoadingPlaceholder.component';
import {Friends} from './Friends.component';
import {CreatorSocials} from './CreatorSocials.component';
import {CreatorPublicStatistics} from './CreatorPublicStatistics.component';
import {FollowButton} from './FollowButton.container';
import {ExclusivePostsInfo} from './ExclusivePostsInfo.component';
import {colors} from '../../../assets';
import {DEVICE_WIDTH} from '../../../constants';
import {isMobile} from 'utils';
import {getCreatorPageShareLink} from '../../../utils/getCreatorPageShareLink';
import {TierWithTranslation} from '../../../tiersTranslations/TierWithTranslation.container';
import { EscreenNames } from 'navigation';
import { ErrorBoundary } from '../../../components/src/ErrorBoundary';
import { useMedia } from '../../../hooks/useMedia';
import { useScreenTitle } from '../../../hooks/useScreenTitle';
import { useSelector } from 'react-redux';
import { selectUser } from 'store';
import { onSharePress } from '../../../share/onSharePress';

const TIER_SEPARATOR_WIDTH = 25;

//todo: refactor creatorPage
export const CreatorPage = () => {
  const params = useParams();
  const urlParams = useParams({urlParams: true});
  const pageURL = params?.pageURL || urlParams?.pageURL;

  const {
    error, 
    isLoading, 
    creatorInfo, 
    tiers, 
    collaborationTiers, 
    posts
  } = useFetchCreatorInfo({pageURL});

  const {navigate} = useNavigation()
  useScreenTitle({title: 'Sponsor.financial - ' + (creatorInfo?.title || "")})

  const [visibleItem, setVisibleItem] = useState(1)
  const user = useSelector(selectUser);

  const setVisibleScrollItem:ScrollViewProps["onScroll"] = ({nativeEvent: {contentOffset, contentSize}}) => {
    // console.log('contentSize',contentSize?.width)
    // console.log('contentOffset',contentOffset?.x)
    setVisibleItem(round(contentOffset?.x / (contentSize.width / tiers?.length + 1)) + 1)
  }

  //show language of user who is seeing the page
  const activeLanguage = useActiveLanguage();
  const [shownLang, setShownLang] = useState(activeLanguage);
  
  const postIdx = useMemo(() => {
    return map(posts, p => p?.id);
  }, [posts]);


  const translatedCreatorInfo = useMemo(() => {
    if (!shownLang) {
      return creatorInfo;
    }
    return creatorInfo?.translations?.[shownLang] || creatorInfo;
  }, [creatorInfo, shownLang]);

  const mainCreatorImageSource = useMemo(() => {
    return {uri: creatorInfo?.backgroundImageUri, method: 'GET'};
  }, [creatorInfo]);

  const renderCreatorNotFound = useCallback(() => {
    return (
      <ContentContainer>
        <Text
          style={{textAlign: 'center', paddingVertical: 15}}
          variant="sectionTitle">
          {i18n.t('creatorPage.creatorNotFound')}
        </Text>
      </ContentContainer>
    );
  }, []);

  // const goToGoalCreatePage = useCallback(() => {
  //   navigate(EscreenNames.PublicCreateGoal);
  // }, [navigate]);

  // if(creatorInfo){
  //   creatorInfo.nbSponsors = 144
  //   creatorInfo.monthlyPaidAmount = 1111
  //   creatorInfo.settings.showMonthlyPaidAmount = true;
  // }

  const copyCreatorPageLink = useCallback(async () => {
    const link = getCreatorPageShareLink(creatorInfo?.pageURL || '');
    await onSharePress({text: link})
  }, [creatorInfo?.pageURL]);

  const buttonBackgroundColor =
    creatorInfo?.customization?.brandColor || colors.GREEN_OFFICIAL;
  const goalsColor =
    creatorInfo?.customization?.brandColor || colors.GREEN_OFFICIAL;

  const pickerBorderColor = creatorInfo?.customization?.brandColor

  const media = useMedia()

  const renderPost = useCallback(({item: postId}) => {
    return (
      <PostContainer
        postId={postId}
        customization={{btnColor: buttonBackgroundColor}}
      />
    );
  },[buttonBackgroundColor])

  if (isLoading) {
    return <LoadingPlaceholder />;
  }

  if (error) {
    return (
      <View variant="sectionContainer">
        <Text center variant="sectionTitle">
          {i18n.t('errors.somethingWrong')}
        </Text>
      </View>
    );
  }

  if (!creatorInfo) {
    return renderCreatorNotFound();
  }

  const goals = values(creatorInfo?.goals);
  const translationsLanguagesCodes = [
    ...keys(creatorInfo?.translations),
    creatorInfo?.lang,
  ];

  const tierMinWidth =
    tiers?.length * TIER_WIDTH + tiers?.length * TIER_SEPARATOR_WIDTH;
  //todo: add tests here
  const collaborationTierMinWidth =
    collaborationTiers?.length * TIER_WIDTH +
    collaborationTiers?.length * TIER_SEPARATOR_WIDTH;


  return (
    <ErrorBoundary>
      <ScrollViewOnlyOnMobile
        // app crashes on 11 android without this prop for scroll
        // and webview video player ???
        overScrollMode={'never'}
        removeClippedSubviews
        nestedScrollEnabled
      >
          <View style={{flex: 1, backgroundColor: 'white'}}>
              <Image
                variant="mainCreatorImage"
                source={mainCreatorImageSource}
                accessible
                accessibilityLabel={'Creator background image'}
              />
              <View style={styles.profileCardContainer}>
                <CreatorProfileCard
                  subtitle={translatedCreatorInfo?.subtitle}
                  title={creatorInfo?.title}
                  verified={creatorInfo?.verified}
                  avatarUri={creatorInfo?.avatarUri}
                  iconColor={buttonBackgroundColor}
                  translations={creatorInfo?.translations}
                />
                {/* {translationsLanguagesCodes?.length > 1 && (
                  <View variant="sectionContainer">
                    <LanguagePicker
                      onLanguageChange={lang => setShownLang(String(lang))}
                      value={shownLang}
                      langs={translationsLanguagesCodes}
                      style={{borderColor: pickerBorderColor}}
                    />
                  </View>
                )} */}
              </View>

              {!isEmpty(tiers) && (
                <>
                  <Text
                    center
                    style={{paddingVertical: Platform.OS === 'web' ? 25 : 10}}
                    variant='creatorPageSectionTitle'
                  >
                    {i18n.t('creatorPage.chooseSubscription')}
                  </Text>

                  <TouchableOpacity
                    onPress={() => navigate(EscreenNames.CreatorPublicTiersScreen, {pageURL})}
                  >
                    <Text
                      center
                      bold
                      style={[styles.sectionSubtitle, {
                        color: buttonBackgroundColor,
                      }]}
                    >
                      {i18n.t('creatorPage.seeAllTiersButtonTitle')}
                    </Text>
                  </TouchableOpacity>

                <View
                  style={
                    styles.tierContainer
                  }
                >
                  <FlatList
                    horizontal
                    accessible
                    showsHorizontalScrollIndicator={tierMinWidth > DEVICE_WIDTH}
                    // showsHorizontalScrollIndicator
                    centerContent
                    pagingEnabled
                    data={tiers}
                    testID="tiers-list"
                    accessibilityLabel="Creator's paid subscriptions"
                    style={{
                        //@ts-ignore
                        outline: 0,
                    }}
                    contentContainerStyle={[styles.tiersContentContainer, viewStyles.bordered]}
                    onScroll={setVisibleScrollItem}
                    indicatorStyle="black"
                    keyExtractor={item => item?.id}
                    renderItem={({item}) => (
                      <TierWithTranslation
                        {...item}
                        lang={shownLang}
                        containerStyle={{
                          marginHorizontal: 10, 
                          width: isMobile ? DEVICE_WIDTH - 20 : TIER_WIDTH, 
                          maxWidth: '100%'
                        }}
                        btnColor={buttonBackgroundColor}
                      />
                    )}
                  />
                  {/* user should know if there are any tiers */}
                  {isMobile || (media?.sm) && <Text center>
                    {`${visibleItem} / ${tiers?.length}`}
                  </Text>}
                  {/* <TouchableOpacity
                      onPress={() => navigate(EscreenNames.TierEdit,{
                        ideaOwner: user?.id
                      })}
                    >
                      <Text variant='creatorHintText' center>{i18n.t("creatorPage.createCommunityTierTitle")}</Text>
                  </TouchableOpacity> */}
                </View>
                </>
              )}

              {!isEmpty(collaborationTiers) && (
                <View
                  style={styles.tierContainer}
                >
                  <Text 
                    center 
                    variant="creatorPageSectionTitle"
                    style={{paddingVertical: Platform.OS === 'web' ? 25 : 10}}
                  >
                    {i18n.t('creatorPage.collaborations')}
                  </Text>
                  <FlatList
                    horizontal
                    accessible
                    showsHorizontalScrollIndicator={collaborationTierMinWidth > DEVICE_WIDTH}
                    pagingEnabled
                    accessibilityLabel="Creator's paid collaborations"
                    centerContent={true}
                    style={{
                      //@ts-ignore
                        outline: 0
                    }}
                    contentContainerStyle={[styles.tiersContentContainer]}
                    data={collaborationTiers}
                    indicatorStyle="black"
                    keyExtractor={item => item?.id}
                    renderItem={({item}) => (
                      <TierWithTranslation
                        {...item}
                        lang={shownLang}
                        containerStyle={{
                          marginHorizontal: 10, 
                          width: isMobile ? DEVICE_WIDTH - 20 : TIER_WIDTH, 
                          maxWidth: '100%'
                        }}
                        btnColor={buttonBackgroundColor}
                      />
                    )}
                  />
                </View>
              )}
              
              <ContentContainer
                containerStyle={{
                  minHeight: 0, 
                  paddingHorizontal: 10,
                  flex:1,
                }}
              >
              <View>
                <AboutAuthor
                  videoURL={creatorInfo.videoURL}
                  description={translatedCreatorInfo?.description || ''}
                />

                {goals?.length > 0 && (
                  <View>
                    <CreatorGoalList
                      containerStyle={{marginTop: 15}}
                      barColor={goalsColor}
                      // TODO: change default currenct and replace UAH
                      goalsLabel={
                        //todo:
                        goals[0]?.type === TCreatorGoalType.subscribers
                          ? `${i18n.t('creatorGoals.subscribersGoalTypeLabel')}:`
                          : `${i18n.t('creatorGoals.earningsGoalTypeLabel')} (${
                              creatorInfo?.mainCurrency?.name || 'UAH'
                            }) : `
                      }
                      currentCount={
                        (goals[0].type === TCreatorGoalType.subscribers
                          ? creatorInfo?.nbSubscribers
                          : creatorInfo.totalMonthlyEarnings?.amount) || 0
                      }
                      goals={goals}
                    />
                    {/* // todo: add limit for goal count */}
                    {/* <TouchableOpacity onPress={goToGoalCreatePage}>
                    <Text variant="thirdTitle">{i18n.t("creatorPage.createYourOwnGoalTitle")}</Text>
                  </TouchableOpacity> */}
                  </View>
                )}

                <ExclusivePostsInfo
                  nbExclusivePosts={creatorInfo?.nbExclusivePosts}
                />
                {/* Subscribers information */}
                <View style={styles.contentContainer}>
                  {!!creatorInfo?.nbSponsors && 
                  creatorInfo?.nbSponsors > -1 && 
                  (<CreatorPublicStatistics
                    nbSponsors={creatorInfo?.nbSponsors || 0}
                    monthlyEarnings={creatorInfo?.totalMonthlyEarnings}
                  />)}
                  <Row
                    containerStyle={{marginTop: 15}}
                    rowCenter
                    columnCenter
                    containsSimilarItems
                  >
                    <FollowButton
                      testID="follow-button"
                      creatorId={creatorInfo?.id}
                      color={buttonBackgroundColor}
                    />
                    {!!creatorInfo?.pageURL && (
                      <Button
                        primary
                        title={i18n.t('share')}
                        color={buttonBackgroundColor}
                        onPress={copyCreatorPageLink}
                      />
                    )}
                  </Row>
                </View>

                {creatorInfo?.friends?.length > 0 && (
                    <View variant="sectionContainer">
                      <Friends friends={creatorInfo?.friends} />
                    </View>
                )}
                {keys(creatorInfo?.socials).length > 0 && (
                    <CreatorSocials
                      socials={creatorInfo?.socials}
                      customizaton={{
                        btnColor: buttonBackgroundColor,
                      }}
                    />
                )}

                <View style={styles.contentContainer}>
                  <FlatList
                    data={postIdx}
                    ListHeaderComponent={
                      postIdx?.length > 0 ? (
                        <Text 
                          variant="creatorPageSectionTitle"
                          style={{paddingVertical: Platform.OS === 'web' ? 25 : 10}}
                        >
                          {i18n.t('creatorPage.lastPosts')}
                        </Text>
                      ) : null
                    }
                    ItemSeparatorComponent={() => (
                      <View variant="verticalListSeparator" />
                    )}
                    keyExtractor={item => item}
                    renderItem={renderPost}
                  />
                </View>

                </View>
              </ContentContainer>
          </View>
      </ScrollViewOnlyOnMobile>
    </ErrorBoundary>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    zIndex: 1,
  },
  profileCardContainer: {
    marginTop: Platform.OS === 'web' ?  -100 : -50,
    alignItems: 'center',
    paddingHorizontal: 50,
  },
  contentContainer: {
    paddingVertical: 25,
    alignItems: 'center',
    justifyContent: 'center',
  },
  tierContainer: Platform.select({
    web: {
      // paddingTop: 50,
      paddingBottom: 25,
      // alignItems: 'center',
      // width: DEVICE_WIDTH,
    },
    default: {
      // marginRight: 10,
      // paddingLeft: TIERS_CONTAINER_MARGIN_LEFT,
    },
  }),
  tiersContentContainer: {
    // on web blue border is visible without outline style
    // outline: 'none',
    justifyContent: 'center',
    minWidth: DEVICE_WIDTH,
  },
  sectionSubtitle: {                   
    textTransform: 'lowercase',
    marginTop: -15,
    marginBottom: 25,
  }
});
