import {View, StyleSheet} from 'react-native';
import React from 'react';
import {
  Navigate,
  BrowserRouter as Router,
  Route,
  Routes,
} from 'react-router-dom';
import {DEVICE_HEIGHT, FOOTER_HEIGHT, HEADER_HEIGHT} from '@sponsor/constants';
import {useSelector} from 'react-redux';
import {Header} from 'components';
import {Footer} from 'screens';
import {selectIsAuthenticated} from 'store';
import {commonRoutes} from './commonRoutes.web';

import Landing from '../../screens/src/Landing/index.web';
import Signup from '../../screens/src/Auth/Signup';
import ForgotPassword from '../../screens/src/Auth/ForgotPassword';
import Login from '../../screens/src/Auth/Login';
import ResetPassword from '../../screens/src/Auth/ResetPassword';

import AuthorizedRoutes from './AuthorizedRoutes';
import config from '../../libs/src/config/config';
// const Landing = React.lazy(() => import('../../screens/src/Landing/index.web'));
// const Signup = React.lazy(() => import('../../screens/src/Auth/Signup'));
// const ForgotPassword = React.lazy(
//   () => import('../../screens/src/Auth/ForgotPassword'),
// );
// const Login = React.lazy(() => import('../../screens/src/Auth/Login'));
// const ResetPassword = React.lazy(
//   () => import('../../screens/src/Auth/ResetPassword'),
// );

// const AuthorizedRoutes = React.lazy(
//   () => import('./AuthorizedRoutes'),
// );

export const RootNavigator = () => {
  const isAuthenticated = useSelector(selectIsAuthenticated);

  return (
    <View style={styles.container}>
      {/* <Suspense fallback={() => <ActivityIndicator />}> */}
      <Router>
        {/* A <Switch> looks through its children <Route>s and
              renders the first one that matches the current URL. */}
        {!isAuthenticated ? (
          <View style={styles.contentContainer}>
            <Routes>
              <Route path="/home" element={<Landing />} />
              <Route path="/" element={<Landing />} />
              <Route
                path="*"
                element={
                  <View>
                    <Header />
                    <Routes>
                      <Route path="/signup" element={<Signup />} />
                      <Route
                        path="/forgot-password"
                        element={<ForgotPassword />}
                      />
                      <Route
                        path="/reset-password"
                        element={<ResetPassword />}
                      />
                      <Route path="/login" element={<Login />} />
                      {commonRoutes}
                      <Route path="*" element={<Navigate to="/" />} />
                    </Routes>
                  </View>
                }
              />
            </Routes>
          </View>
        ) : (
          <AuthorizedRoutes />
        )}
        <Footer />
      </Router>
      {/* </Suspense> */}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  contentContainer: {
    minHeight: DEVICE_HEIGHT - FOOTER_HEIGHT / 2 - HEADER_HEIGHT,
  },
});
