import {Button, PostEditPreview, Row, Text, View} from 'components';
import {EscreenNames} from 'navigation';
import React, {useCallback, useMemo, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigation} from 'hooks';
import {i18n} from 'localize';
import {getUploadedPosts, selectDraftPosts, selectPublishedPosts} from 'store';
import {EPostPublishedStatus, TPost} from 'types';
import {FlatList} from 'react-native-gesture-handler';
import { useParams } from 'hooks';
import { Picker } from 'components/src/Picker/Picker';

export const EditPosts = () => {
  const {postStatus}: {postStatus?:EPostPublishedStatus} = useParams()
  const dispatch = useDispatch();
  const publishedPosts = useSelector(selectPublishedPosts);
  const draftPosts = useSelector(selectDraftPosts);
  const [, setIsLoading] = useState(false);

  const [selectedPostType, setSelectedPostType] = useState(
    postStatus || EPostPublishedStatus.published,
  );

  const {navigate} = useNavigation();

  const openNewPostScreen = useCallback(() => {
    navigate(EscreenNames.PostNew);
  }, [navigate]);

  //todo: replace with custom hook
  useEffect(() => {
    dispatch(
      getUploadedPosts(null, {
        onFinish: () => setIsLoading(false),
      }),
    );
  }, [dispatch]);

  const renderFilters = useCallback(
    () => (
      <>
      <Text variant='thirdTitle'>
        {i18n.t('chooseFilter')}
      </Text>
      {/* todo: custom picker component */}
      <View style={{marginBottom: 15, marginTop: 10}}>
      <Picker
        style={{
          fontSize: 16,
          color: 'black'
        }}
        selectedValue={selectedPostType}
        onValueChange={itemValue => setSelectedPostType(itemValue?.value)}
        data={[
          {
            label: i18n.t('editPosts.drafts'),
            value: EPostPublishedStatus.draft
          },
          {
            label: i18n.t('editPosts.publishedPosts'),
            value: EPostPublishedStatus.published
          }
        ]}
       />
      </View>

      </>
    ),
    [selectedPostType],
  );

  // const sections = useMemo(() => [
  //   {
  //     title: i18n.t('editPosts.drafts'),
  //     data: draftPosts,
  //   },
  //   {
  //     title: i18n.t('editPosts.publishedPosts'),
  //     data: publishedPosts,
  //   }
  // ],[draftPosts, publishedPosts])

  const visibleData = useMemo(() => {
    const postPerType = {
      [EPostPublishedStatus.published]: publishedPosts,
      [EPostPublishedStatus.draft]: draftPosts,
    };

    const listTitles = {
      [EPostPublishedStatus.published]: i18n.t('editPosts.publishedPosts'),
      [EPostPublishedStatus.draft]: i18n.t('editPosts.drafts'),
    };

    return {
      title: listTitles[selectedPostType],
      data: postPerType[selectedPostType],
    };
  }, [selectedPostType, draftPosts, publishedPosts]);

  return (
    <View variant="screenContainer">
      <FlatList
        data={visibleData?.data}
        keyExtractor={(item:TPost) => item?.id}
        extraData={visibleData?.title}
        // stickySectionHeadersEnabled={false}
        ListHeaderComponent={() => (
          <View variant='sectionContainer'>
            {renderFilters()}
            <Row containerStyle={{justifyContent: 'space-between'}} columnCenter flexWrap>
              <Text variant="thirdTitle">{visibleData?.title}</Text>
              <Button
                title={i18n.t('editPosts.newPost')}
                onPress={openNewPostScreen}
                primary
              />
            </Row>
          </View>
        )}
        ListEmptyComponent={() => (
          <Text center variant="thirdTitle">
            {i18n.t('listEmptyTitle')}
          </Text>
        )}
        // renderSectionFooter={({section}) =>
        //   section?.data?.length === 0 ? (
        //       <Text center  variant='thirdTitle'>
        //         {i18n.t('listEmptyTitle')}
        //       </Text>
        //   )
        //   : null
        // }
        // List={({section}) => (
        //     <Text variant="sectionTitle">
        //       {section?.title}
        //     </Text>
        // )}
        renderItem={({item}: {item: TPost}) => (
          <PostEditPreview
            id={item?.id}
            title={item?.title}
            text={item?.text}
            tizer_image={item?.tizer_image}
            visible={item?.visible || false}
            translations={item.visible ? item?.translations : {}}
            imageUri={item.imageUri}
          />
        )}
      />
      {/* {emptyPublishedPosts && noDrafts && (
            <Text variant="sectionTitle">{i18n.t('editPosts.noPosts')}</Text>
          )}
        {!noDrafts && selectedPostType == EPostPublishedStatus.draft && (
          <FlatList
            // ListHeaderComponent={
            //   <Text variant="sectionTitle">{i18n.t('editPosts.drafts')}</Text>
            // }
            data={draftPosts}
            renderItem={({item}) => <PostEditPreview {...item} />}
          />
        )}
        {!emptyPublishedPosts &&
          selectedPostType == EPostPublishedStatus.published && (
            <FlatList
              // style={{marginTop: 25}}
              // ListHeaderComponent={
              //   <Text variant="sectionTitle">
              //     {i18n.t('editPosts.publishedPosts')}
              //   </Text>
              // }
              data={publishedPosts}
              renderItem={({item}) => <PostEditPreview {...item} />}
            />
          )} */}
      {/* <Button
        title={i18n.t('editPosts.newPost')}
        containerStyle={{marginTop: 25}}
        onPress={openNewPostScreen}
      /> */}
    </View>
  );
};
