import * as Yup from 'yup';
import {i18n} from 'localize';

export const COLOR_REQ_EXP = /^#([0-9a-f]{3}|[0-9a-f]{6})$/i

const getBrandColorValidationSchema = () => 
    Yup.object().shape({
        brandColor: Yup.string()
          .matches(COLOR_REQ_EXP, i18n.t('validationSchema.colorIsInvalid'))
});

export {
    getBrandColorValidationSchema
}