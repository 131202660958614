import {Platform, StyleSheet} from 'react-native';
import {colors} from '@sponsor/assets';
import {DEVICE_WIDTH} from '@sponsor/constants';

export default StyleSheet.create({
  container: {
    // overflow: 'hidden',
    //TODO: remove this style
    minWidth: Platform.OS == 'web' ? 0 :150,
    maxWidth: Platform.OS === 'web' ? 222 : DEVICE_WIDTH,
  },
  marginHorizontal: {
    margin: 5,
  },
  smallBtn: {
    minWidth: 0,
  },
  red: {
    backgroundColor: 'red',
    borderWidth: 0,
  },
  button: {
    padding: 15,
  },
  view: {
    // backgroundColor: colors.button.backgroundColor,
    borderColor: 'black',
    borderWidth: 2,
    borderRadius: 8,
  },
  text: {
    color: 'black',
    textAlign: 'center',
    textAlignVertical: 'center',
  },
  contentContainer: {
    flexDirection: 'row',
    // justifyContent: 'center',
    alignItems: 'center',
  },
  primaryView: {
    backgroundColor: colors.GREEN_OFFICIAL,
    // shadowColor: colors.button.shadowColor,

    // todo: turn on only on web
    // shadowOffset: {
    //   width: 0,
    //   height: 3,
    // },
    // elevation: 3,
    // shadowOpacity: 0.3,
    // shadowRadius: 4.7,

    borderWidth: 0,
  },
  disable: {
    backgroundColor: colors.DUSTY_GRAY,
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0,
    shadowRadius: 0,
    elevation: 0,
    borderColor: colors.DUSTY_GRAY,
  },
});
