import {NetworkService} from '../services';
import {ApiRequest} from '../types';
import {
  TDeleteTranslationInput,
  TDeleteTranslationOutput,
  TPostUploadTranslationInput,
  TPostUploadTranslationOutput,
  TUpdateTranslationInput,
  TUpdateTranslationOutput,
} from './types';

export const postUploadTranslation = async (
  inputs: TPostUploadTranslationInput,
): ApiRequest<TPostUploadTranslationOutput> => {
  return NetworkService.post('/v1/creator/translations', inputs);
};

export const updateTranslation = async (
  update: TUpdateTranslationInput,
): ApiRequest<TUpdateTranslationOutput> => {
  return NetworkService.put('/v1/creator/translations', update);
};

export const deleteTranslation = async (
  params: TDeleteTranslationInput,
): ApiRequest<TDeleteTranslationOutput> => {
  return NetworkService.delete('/v1/creator/translations', params);
};

export const TranslationApiService = {
  postUploadTranslation,
  updateTranslation,
  deleteTranslation,
};
