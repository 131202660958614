import React from 'react';

import * as S from './home-footer.styled';

import useMedia from '../../../../hook/media';

import mask from '../../../../images/home/mask.jpg';
import footer from '../../../../images/home/footer.webp';
import logo from '../../../../images/logo_black_background.png';
// import facebook from '../../../../images/social/facebook.png';
// import twitter from '../../../../images/social/twitter.png';
// import linkedIn from '../../../../images/social/linkedIn.png';
// import youtube from '../../../../images/social/youtube.png';

export default function HomeFooterView() {
  const media = useMedia();

  return (
    <S.Container
      source={media.lg ? mask : footer}
      resizeMode="cover"
      media={media}>
      <S.Logo source={logo} resizeMode="contain" media={media} />

      {/* <S.Social>
        <TouchableOpacity>
          <S.SocialItem source={facebook} resizeMode="contain" media={media} />
        </TouchableOpacity>
        <TouchableOpacity>
          <S.SocialItem source={twitter} resizeMode="contain" media={media} />
        </TouchableOpacity>
        <TouchableOpacity>
          <S.SocialItem source={linkedIn} resizeMode="contain" media={media} />
        </TouchableOpacity>
        <TouchableOpacity>
          <S.SocialItem source={youtube} resizeMode="contain" media={media} />
        </TouchableOpacity>
      </S.Social> */}
    </S.Container>
  );
}
