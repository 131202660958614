
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { uploadPostViewedSuccess } from './viewed.actions';
import { TViewedState } from './viewed.types';

export const viewedSlice = createSlice<TViewedState, any, 'viewed'>({
    name: 'viewed',
    initialState: {
        'posts': [],
    },
    extraReducers: {    
        [uploadPostViewedSuccess?.toString()]: (state, action:PayloadAction<string>) => {
            const viededPostId = action.payload;
            if(!state['posts']){
                state['posts'] = [viededPostId];
            }
            else{
                if(state['posts']?.[0] !== viededPostId){
                    state['posts'] = [viededPostId, ...state['posts'].slice(0,49)]
                }      
            }
   
        }
    },
    reducers: {

    },
});

export const viewedReducer = viewedSlice.reducer
  
  

