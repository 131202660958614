import React from 'react';
import {Platform, ScrollViewProps, View} from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';

export function ScrollViewOnlyOnMobile({children, ...otherProps}: {children: any} & ScrollViewProps) {
  if (Platform.OS === 'web') {
    return <View style={{flexGrow: 1}}>{children}</View>;
  }

  return <ScrollView 
    showsVerticalScrollIndicator={false}
    style={{flex:1}} {...otherProps}>{children}</ScrollView>;
}
