import {NetworkService} from '../services';
import {ApiRequest} from '../types';
import {
  TDeleteGreetingsInput,
  TDeleteGreetingsOutput,
  TPostUploadGreetingsInput,
  TPostUploadGreetingsOutput,
  TUpdateGreetingsInput,
  TUpdateGreetingsOutput,
} from './types';

export const postUploadGreetings = async (
  inputs: TPostUploadGreetingsInput,
): ApiRequest<TPostUploadGreetingsOutput> => {
  return NetworkService.post('/v1/creator/greetings', inputs);
};

export const updateGreetings = async (
  update: TUpdateGreetingsInput,
): ApiRequest<TUpdateGreetingsOutput> => {
  return NetworkService.put('/v1/creator/greetings', update);
};

export const deleteGreetings = async (
  params: TDeleteGreetingsInput,
): ApiRequest<TDeleteGreetingsOutput> => {
  return NetworkService.delete('/v1/creator/greetings', params);
};

export const GreetingsApiService = {
  postUploadGreetings,
  updateGreetings,
  deleteGreetings,
};
