import React from 'react';
import {View} from 'react-native';
// import 'video-react/dist/video-react.css'; // import css
// import loadable from '@loadable/component';
import ReactPlayer from 'react-player';

// const ReactPlayer = loadable(() => import('react-player'));

type TProps = {
  src: string;
  style?: any;
  width?: number
};

export const VideoPlayer: React.FC<TProps> = React.memo(({width, src, style}) => {
  return (
    <View style={style}>
      <ReactPlayer
        url={src}
        controls
        width={width || '100%'}
        //todo: check all cases, replace with height
        height={'100%'}
      />
    </View>
  );
});
