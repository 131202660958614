import {isEmpty} from 'lodash';
import {EscreenNames} from 'navigation';
import React, {useCallback} from 'react';
import {View, StyleSheet} from 'react-native';
import {TouchableOpacity} from 'react-native';
import {useDispatch} from 'react-redux';
import {colors} from '@sponsor/assets';
import {useNavigation} from 'hooks';
import {i18n} from 'localize';
import {decodeHTMLEntities} from 'utils';
import {TPost} from 'types';
import {RenderHtml, Collapsible, Text, Row, Image} from 'components';
import {removePost} from 'store';
import { ConfirmationDialog } from '../ConfirmationDialog';

export const PostEditPreview: React.FC<Partial<TPost> & {
  id: string,
  visible: boolean
}> = ({
  id,
  title,
  text,
  imageUri,
  tizer_image,
  visible,
  translations = {},
}) => {
  const {navigate} = useNavigation();
  const dispatch = useDispatch();

  const edit = useCallback(() => {
    navigate(EscreenNames.PostEdit, {id});
  }, [id, navigate]);

  const remove = useCallback(() => {
    dispatch(removePost({id}, {}));
  }, [dispatch, id]);

  const view = useCallback(() => {
    navigate(EscreenNames.PostScreen, {id}, {newTab: true});
  }, [navigate, id]);

  const openTranslations = useCallback(() => {
    navigate(EscreenNames.PostsTranslations, {parentId: id}, {newTab: false});
  }, [navigate, id]);

  const openEditTranslationScreen = useCallback(() => {
    navigate(EscreenNames.EditPostTranslations, {parentId: id}, {newTab: false});
  }, [navigate, id]);

  return (
    <View style={styles.container}>
      <Row containsSimilarItems flexWrap containerStyle={{paddingBottom: 10}}>
        <ConfirmationDialog
              title={i18n.t("dialogs.deletionConfirmationDialogTitle")}
              desc={i18n.t("dialogs.confirmationDialogTitle")}
              onPress={remove}
              render={({onPress}) => (
                <TouchableOpacity onPress={onPress}>
                  <Text variant='dangerous'>{i18n.t('delete')}</Text>
              </TouchableOpacity>
            )}
        />
        <TouchableOpacity onPress={edit}>
          <Text>{i18n.t('edit')}</Text>
        </TouchableOpacity>
        {visible && (
            <TouchableOpacity onPress={view}>
              <Text>{i18n.t('view')}</Text>
            </TouchableOpacity>
        )}
        {visible && (
            <TouchableOpacity onPress={openEditTranslationScreen}>
              <Text>{i18n.t('addTranslation')}</Text>
            </TouchableOpacity>
        )}
        {Object.keys(translations)?.length > 0 && (
          <TouchableOpacity onPress={openTranslations}>
              <Text>{i18n.t('translations')}</Text>
          </TouchableOpacity>
        )}
      </Row>
      <Row>
        <View style={{flex:1}}>
          <Text bold variant="previewText">{title}</Text>
          {/* <Text>{text}</Text> */}
          {!!text && <Collapsible showHideButton collapsedHeight={150}>
            <RenderHtml source={decodeHTMLEntities(text || '')} />
          </Collapsible>}
        </View>
        {!!imageUri && <View style={{padding: 15}}>
          <Image 
            source={{uri: imageUri}}
            variant="postPreview"
          />
          <View style={{marginTop: 25}}>
            {!isEmpty(tizer_image) && (
              <Image source={{uri: tizer_image}} variant="postPreview" />
            )}
          </View>
        </View>}
      </Row>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    borderWidth: 1,
    borderColor: colors.DUSTY_GRAY,
    padding: 15,
  },
});
