import { StyleSheet, FlatList, Platform } from 'react-native';
import React, {useCallback, useMemo, useState} from 'react';
import {
    Image,
    CreatorProfileCard,
    Text,
    View,
    ContentContainer,
    ScrollViewOnlyOnMobile,
} from 'components';
import { useParams } from 'hooks';
import {i18n} from 'localize';
import {useFetchCreatorInfo} from 'hooks';
import {LoadingPlaceholder} from '../CreatorPage/LoadingPlaceholder.component';
import {colors} from '../../../assets';
import {DEVICE_WIDTH} from '../../../constants';
import { selectPostByCreatorIdAndPostId, TStore } from 'store';
import { PostListContainer } from '../../../posts/PostList.container';

export const CreatorPublicPosts = () => {
    
    //todo: refactor creatorPage
    const params = useParams();
    const pageURL = params?.pageURL;

    const {
        error, 
        isLoading, 
        creatorInfo, 
        posts
    } = useFetchCreatorInfo({pageURL});

    const mainCreatorImageSource = useMemo(() => {
        return {uri: creatorInfo?.backgroundImageUri, method: 'GET'};
    }, [creatorInfo]);

    const renderCreatorNotFound = useCallback(() => {
        return (
            <ContentContainer>
            <Text
                style={{textAlign: 'center', paddingVertical: 15}}
                variant="sectionTitle">
                {i18n.t('creatorPage.creatorNotFound')}
            </Text>
            </ContentContainer>
        );
    }, []);

    const buttonBackgroundColor =
    creatorInfo?.customization?.brandColor || colors.GREEN_OFFICIAL;
    const goalsColor =
    creatorInfo?.customization?.brandColor || colors.GREEN_OFFICIAL;

    const pickerBorderColor = creatorInfo?.customization?.brandColor

    if (isLoading) {
        return <LoadingPlaceholder />;
    }

    if (error) {
        return (
            <View variant="sectionContainer">
            <Text center variant="sectionTitle">
                {i18n.t('errors.somethingWrong')}
            </Text>
            </View>
        );
    }

    if (!creatorInfo) {
        return renderCreatorNotFound();
    }

    return (
        <View style={{flex: 1, backgroundColor: 'white'}}>
            <ScrollViewOnlyOnMobile>
                <Image
                    variant="mainCreatorImage"
                    source={mainCreatorImageSource}
                    accessible
                    accessibilityLabel={'Creator background image'}
                />
                <View style={styles.profileCardContainer}>
                    <CreatorProfileCard
                        subtitle={creatorInfo?.subtitle}
                        title={creatorInfo?.title}
                        verified={creatorInfo?.verified}
                        avatarUri={creatorInfo?.avatarUri}
                        iconColor={buttonBackgroundColor}
                    />
                </View>

                <ContentContainer
                    containerStyle={{
                    minHeight: 0, 
                    paddingHorizontal: 10,
                    flex:1,
                }}>
                    <View>
                        <View style={styles.contentContainer}>
                            <PostListContainer 
                                postsIdx={posts.map(p => p?.id)}
                                getOnePostSelector={(state:TStore, postId:string) => selectPostByCreatorIdAndPostId(state, creatorInfo.id, postId)}
                            />
                        </View>
                    </View>
                </ContentContainer>
            </ScrollViewOnlyOnMobile>
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
    flex: 1,
    zIndex: 1,
    },
    profileCardContainer: {
    marginTop: Platform.OS === 'web' ?  -100 : -50,
    alignItems: 'center',
    paddingHorizontal: 50,
    },
    contentContainer: {
    paddingVertical: 25,
    alignItems: 'center',
    justifyContent: 'center',
    },
    tierContainer: Platform.select({
    web: {
        // paddingTop: 50,
        paddingBottom: 25,
        // alignItems: 'center',
        // width: DEVICE_WIDTH,
    },
    default: {
            // marginRight: 10,
            // paddingLeft: TIERS_CONTAINER_MARGIN_LEFT,
        },
    }),
    tiersContentContainer: {
        // on web blue border is visible without outline style
        // outline: 'none',
        justifyContent: 'center',
        minWidth: DEVICE_WIDTH,
    },
    sectionSubtitle: {                   
        textTransform: 'lowercase',
        marginTop: -15,
        marginBottom: 25,
    }
});

