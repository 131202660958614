import {useLocation, useParams as useReactRouterParams } from 'react-router-dom';

export const useParams = (options: {urlParams?: boolean}) => {
  const up = useReactRouterParams();
  if(options?.urlParams){
    return up
  }
  return Object.fromEntries(
    //@ts-ignore
    new URLSearchParams(useLocation().search).entries(),
  );
};
