import styled from 'styled-components/native';

import {responsiveSize} from '../../../../utils/font';

export const Container = styled.View`
  width: 100%;
  height: ${responsiveSize(777)};
  padding: ${responsiveSize(226)} 0 ${responsiveSize(269)}
    ${responsiveSize(190)};

  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  ${props =>
    props.media.lg &&
    `
    height: auto;
    flex-direction: column-reverse;
    padding: ${responsiveSize(120)} ${responsiveSize(60)};
  `};

  ${props =>
    props.media.md &&
    `
   
    padding: ${responsiveSize(110)} ${responsiveSize(60)};
  `};

  ${props =>
    props.media.sm &&
    `
    padding: ${responsiveSize(90)} ${responsiveSize(16)};
  `};
`;

export const Image = styled.Image`
  height: ${responsiveSize(611)};
  width: ${responsiveSize(882)};

  position: absolute;
  right: ${responsiveSize(90)};

  ${props =>
    props.media.lg &&
    `
    position: relative;
    right: auto;
    height: ${responsiveSize(611 / 1.5)};
    width: ${responsiveSize(882 / 1.5)};
    margin-bottom: ${responsiveSize(32)};
  `};

  ${props =>
    props.media.md &&
    `
    height: ${responsiveSize(611 / 1.2)};
    width: ${responsiveSize(882 / 1.2)};
    max-width: 100%;
  `};

  ${props =>
    props.media.sm &&
    `
    height: ${responsiveSize(611 / 2.4)};
    width: ${responsiveSize(882 / 2.4)};
    
  `};
`;

export const Content = styled.View`
  flex: 1;
  align-items: flex-start;

  ${props =>
    props.media.lg &&
    `
    flex: none;
    align-items: center;
  `};
`;

export const Title = styled.Text`
  max-width: ${responsiveSize(950)};
  margin-bottom: ${responsiveSize(24)};

  font-weight: bold;
  font-size: ${responsiveSize(51)};
  line-height: ${responsiveSize(79)};
  letter-spacing: 0.03em;
  text-transform: uppercase;
  font-family: inherit;

  ${props =>
    props.media.lg &&
    `
    max-width: ${responsiveSize(700)};
    
    font-size: ${responsiveSize(51 / 1.4)};
    line-height: ${responsiveSize(79 / 1.4)};
    text-align: center;
  `};

  ${props =>
    props.media.md &&
    `
    font-size: ${responsiveSize(51 / 1.2)};
    line-height: ${responsiveSize(79 / 1.2)};
    
  `};

  ${props =>
    props.media.sm &&
    `
    margin-bottom: ${responsiveSize(16)};

    font-size: ${responsiveSize(51 / 2)};
    line-height: ${responsiveSize(79 / 2)};
  `};
`;

export const Description = styled.Text`
  margin-bottom: ${responsiveSize(24)};

  font-size: ${responsiveSize(22)};
  line-height: ${responsiveSize(33)};
  letter-spacing: 0.03em;
  font-family: inherit;

  ${props =>
    props.media.md &&
    `
    font-size: ${responsiveSize(22 / 1.2)};
    line-height: ${responsiveSize(33 / 1.2)};
    text-align: center;
  `};

  ${props =>
    props.media.sm &&
    `
    font-size: ${responsiveSize(22 / 1.6)};
    line-height: ${responsiveSize(33 / 1.6)};
  `};
`;
