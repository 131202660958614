import React from 'react';
import {Platform, StyleSheet, View} from 'react-native';
import {TabView, SceneMap} from 'react-native-tab-view';
import {TabBar} from 'components';
import {CreatorsList} from './CreatorsList';
import {PostList} from './PostList';
import {i18n} from 'localize';
import {DEVICE_WIDTH} from '@sponsor/constants';

export const PostFromCreators = () => {
  const [index, setIndex] = React.useState(0);

  const routes = [
    {key: 'first', title: i18n.t('postsScreen.posts')},
    {key: 'second', title: i18n.t('postsScreen.creators')},
  ];

  const renderScene = SceneMap({
    first: PostList,
    second: CreatorsList,
  });

  return (
    <View style={styles.container}>
      <TabView
        navigationState={{index, routes}}
        renderScene={renderScene}
        renderTabBar={props => {
          return <TabBar {...props} />;
        }}
        onIndexChange={setIndex}
        sceneContainerStyle={styles.tabContentContainerStyle}
        style={styles.tabContainer}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: 'white',
    alignItems: Platform.OS === 'web' ? 'center' : 'stretch',
    paddingBottom: Platform.OS === 'web' ? 50 : 0,
  },
  tabContainer: {
    flex: 1,
    maxWidth:
      Platform.OS === 'web'
        ? DEVICE_WIDTH < 650
          ? DEVICE_WIDTH - 50
          : 650
        : '100%',
  },
  tabContentContainerStyle: {
    paddingHorizontal: 25,
    flex: 1,
  },
});
