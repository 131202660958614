import {ActionResult, Button, FormInput, View} from 'components';
import {Formik} from 'formik';
import React from 'react';

import {useSelector} from 'react-redux';
import {i18n} from 'localize';
import {getSocialsSchema} from './socialsValidationSchema';
import {selectCreatorBasicInfo, updateCreatorStart} from 'store';
import {ScrollView} from 'react-native';
import { ActionResultDefaultRenders } from 'components/src/ActionResult/ActionResultDefaultRenders';
import { useDispatchAction } from 'hooks';

export const EditSocials = () => {
  const creator = useSelector(selectCreatorBasicInfo);

  const {
    error,
    isLoading,
    submitAction: updateCreator
  } = useDispatchAction(updateCreatorStart);
  
  const onSubmit = (values:any) => updateCreator({
      socials: values,
  })

  return (
    // TODO: add validation for links
    <View variant='screenContainer'>
        <ScrollView>
          <Formik
            initialValues={{
              //todo: add iteration here
              reddit: creator?.socials?.reddit || "",
              youtube: creator?.socials?.youtube,
              twitch: creator?.socials?.twitch,
              wiki: creator?.socials?.wiki,
              megogo: creator?.socials?.megogo,
            }}
            validationSchema={getSocialsSchema()}
            onSubmit={onSubmit}>
            {({values, handleChange, handleSubmit, errors}) => (
              <View>
                {/* <FormInput
                  label={i18n.t('twitter')}
                  value={values.twitter}
                  onChangeText={handleChange('twitter')}
                  error={errors.twitter}
                /> */}
                <FormInput
                  label={i18n.t('reddit')}
                  value={values.reddit}
                  onChangeText={handleChange('reddit')}
                  error={errors.reddit}
                />
                <FormInput
                  label={i18n.t('youtube')}
                  value={values.youtube}
                  onChangeText={handleChange('youtube')}
                  error={errors.youtube}
                  autoCapitalize="none"
                />
                <FormInput
                  label={i18n.t('twitch')}
                  value={values.twitch}
                  onChangeText={handleChange('twitch')}
                  error={errors.twitch}
                  autoCapitalize="none"
                />
                <FormInput
                  label={i18n.t('socials.wiki')}
                  value={values.wiki}
                  onChangeText={handleChange('wiki')}
                  error={errors.wiki}
                  autoCapitalize="none"
                />
                <FormInput
                  label={i18n.t('socials.megogo')}
                  value={values.megogo}
                  onChangeText={handleChange('megogo')}
                  error={errors.megogo}
                  autoCapitalize="none"
                />
                <View variant="sectionContainer">
                  <Button
                    primary
                    // disabled={isEqual(values, {
                    //     title: creator.title,
                    //     description: creator.description,
                    // })}
                    title={i18n.t('saveSettings')}
                    onPress={handleSubmit}
                  />
                  <ActionResult 
                    error={error}
                    isLoading={isLoading}
                    {...ActionResultDefaultRenders}
                  />
                </View>
              </View>
            )}
          </Formik>
        </ScrollView>
    </View>
  );
};
