import {combineReducers} from 'redux';
import inFeedPostsReducer, {infeedPostsFlow} from './infeedPosts';
import ownPostsReducer, {ownPostsFlow} from './ownPosts';
import publicPostsReducer from './posts';
import creatorsPostsReducer, {creatorsPostsFlow} from './creatorsPosts';
import {fork} from '@redux-saga/core/effects';

export * from './infeedPosts';
export * from './creatorsPosts';
export * from './ownPosts';
export * from './posts';

export default combineReducers({
  inFeedPosts: inFeedPostsReducer,
  ownPosts: ownPostsReducer,
  creatorsPosts: creatorsPostsReducer,
  publicPosts: publicPostsReducer,
});

export function* rootPostsSaga() {
  yield fork(infeedPostsFlow);
  yield fork(ownPostsFlow);
  yield fork(creatorsPostsFlow);
}
