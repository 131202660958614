import React from 'react';

import * as S from './home-create-collaborations.styled';

//todo: remove, why we need 2 hooks similar ???
import useMedia from '../../../../hook/media';

import mask from '../../../../images/home/mask.jpg';
import create from '../../../../images/home/create.webp';
import {i18n} from 'localize';
import {Config} from 'libs';
import { isDesktop } from '../../../../../../../utils/platform';

export default function HomeCreateCollaborationsView() {
  const media = useMedia();
  return (
    <S.Container
      source={media.lg ? mask : create}
      resizeMode="cover"
      media={media}>
      <S.Image
        source={isDesktop() ? 
          require('../../../../images/collaboration.webp') 
          : {
            uri: `${Config.STATIC_IMAGES_URL}/landing/collaboration.webp`,
          }
        }
        resizeMode="contain"
        loading="lazy"
        media={media}
      />

      <S.Content media={media}>
        <S.Title media={media}>{i18n.t('landing.createCollaboration')}</S.Title>

        <S.Description media={media}>
          {i18n.t('landing.receiveEqualPayments')}
        </S.Description>

        <S.Description media={media}>
          {i18n.t('landing.opportunityToCollaborate')}
        </S.Description>
      </S.Content>
    </S.Container>
  );
}
