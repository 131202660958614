import * as Yup from 'yup';
import {i18n} from 'localize';

/**
 * to make validation work with language changing dynamically
 * we created generation function,
 * it's called from react component
 * // todo: use constants shared between back-end and front
 */
export const getBasicSettingsCreatorSchema = () => {
  const BasicSettingsCreatorSchema = Yup.object().shape({
    title: Yup.string()
      .min(2, i18n.t('validationSchema.tooShort'))
      .max(50, i18n.t('validationSchema.tooLong'))
      .required(i18n.t('validationSchema.requiredField')),
    description: Yup.string()
      .min(2, i18n.t('validationSchema.tooShort'))
      .max(2500, i18n.t('validationSchema.tooLong')),
    subtitle: Yup.string()
      .min(2, i18n.t('validationSchema.tooShort'))
      .max(500, i18n.t('validationSchema.tooLong')),
    //todo: find way to input to rich editor from tests
    // .required(i18n.t('validationSchema.requiredField'))
    videoURL: Yup.string().url(i18n.t('validationSchema.validURL')),
    pageURL: Yup.string()
      .min(2, i18n.t('validationSchema.tooShort'))
      .max(250, i18n.t('validationSchema.tooLong'))
      .required(i18n.t('validationSchema.requiredField')),
    lang: Yup.string().required(i18n.t('validationSchema.requiredField')),
    countryAplha3Code: Yup.string()
        .required(i18n.t('validationSchema.requiredField')),
  });
  return BasicSettingsCreatorSchema;
};
