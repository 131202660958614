import {useMediaQuery} from 'react-responsive';

export default function useMedia() {
  const sm = useMediaQuery({maxWidth: 576});
  const md = useMediaQuery({maxWidth: 768});
  const lg = useMediaQuery({maxWidth: 992});
  const xl = useMediaQuery({maxWidth: 1200});

  return {sm, md, lg, xl};
}
