import styled from 'styled-components/native';

import {responsiveSize} from '../../utils/font';

export const Container = styled.TouchableOpacity`
  justify-content: center;
  align-items: center;
  border-radius: ${responsiveSize(10)};
  padding: 0px 0px;
  margin-top: ${responsiveSize(24)};

  ${props => props.theme === 'white' && 'background: #FFFFFF;'}
  ${props => props.theme === 'green' && 'background: #13BF6C;'}


  ${props =>
    props.size === 'base' &&
    `
    min-width: ${responsiveSize(200)};
    height: ${responsiveSize(50)};

    ${
      props.media.sm &&
      `
      padding: 0px 24px;
      min-width: auto;
    `
    };
  `}

  ${props =>
    props.size === 'big' &&
    `
    padding: 0px 46px;
    min-width: ${responsiveSize(208)};
    height: ${responsiveSize(62)};

    ${
      props.media.md &&
      `
      padding: 0px 24px;
    `
    };
  `}
`;

export const Title = styled.Text`
  font-weight: 600;
  text-transform: uppercase;
  font-family: inherit;

  ${props => props.theme === 'white' && 'color: #000000;'}
  ${props => props.theme === 'green' && 'color: #ffffff;'}

  ${props =>
    props.size === 'base' &&
    `
    font-size: ${responsiveSize(20)};
    line-height: ${responsiveSize(25)};
  `}

  ${props =>
    props.size === 'big' &&
    `
    font-size: ${responsiveSize(24)};
    line-height: ${responsiveSize(29)};
    text-align: center;
  `}
`;
