import * as Sentry from 'sentry-expo';
import {Config} from 'libs';
import {LOG} from '../../utils/LOG';

class _SentryService {
  constructor() {
    this.init();
  }

  init() {
    Sentry.Browser.init({
      dsn: Config.SENTRY_DSN,
      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: 1.0,
      // integrations: [new BrowserTracing()],S
    });
  }

  async captureEvent(error: any) {
    try {
      await Sentry.Browser.captureEvent(error);
    } catch (err) {
      LOG(err);
    }
  }

  async captureMessage(message: string) {
    try {
      await Sentry.Browser.captureMessage(message);
    } catch (err) {
      LOG(err);
    }
  }

  async captureException(error: any) {
    try {
      await Sentry.Browser.captureException(error);
    } catch (err) {
      LOG(err);
    }
  }
}

export const SentryService = new _SentryService();
