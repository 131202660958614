import { includes, isArray } from 'lodash';
import moment from 'moment';
import React from 'react';
import {
  ImageProps,
  ImageSourcePropType,
  ImageStyle,
  Platform,
  Image as RNImage
} from 'react-native';
import { imageStyles } from './Image.styles';

interface IProps {
  variant?: keyof typeof imageStyles;
  center?: boolean;
  style?: ImageStyle;
  cache?: boolean,
}

const _Image: React.FC<IProps & ImageProps & {source: ImageSourcePropType | number}> = ({
  variant = 'tierImage',
  style = {},
  source,
  //todo: add image caching
  cache = false,
  ...otherProps
}) => {

  const isNetworkimage = (source: ImageSourcePropType | number) => {
    if(typeof source != 'number' && !isArray(source)){
      return includes(source?.uri, 'http')
    }
    return false;
  }

  //todo: check for other image types, not png and jpeg only
  const getCacheFreeNetworkURL = (source: ImageSourcePropType | number) => {
    if(Platform.OS === 'web') return source;
    if(isArray(source) || typeof source === 'number'){
      console.warn('not supported behaviour')
      return source
    }
    return {
      uri: source?.uri + '?datetime=' + moment().minutes()
    }
  }

  return (
    <RNImage
      {...otherProps}
      // add some random number `cause of bug on rn image cache with
      // the same url
      // image will reload after datetime changed
      source={cache === false && isNetworkimage(source) ? getCacheFreeNetworkURL(source) : source}
      // onError={({nativeEvent: {error}}) => LOG('image loading error', error)}
      style={[variant && imageStyles[variant], style]}
    />
  );
};

export const Image = React.memo(_Image);