import React, {useEffect, useState} from 'react';
import {useActiveLanguage, useDispatchAction, useParams} from 'hooks';
import {
  View,
  ContentContainer,
  PostContainer,
  PostCommentProvider,
  CommentLongCard,
  Text,
} from '../../../../components';
import {useDispatch} from 'react-redux';
import {
  getPublicPosts,
  selectCanUserLeaveComment,
  selectPublicPostComments,
} from 'store';
import {PostLoadingPlaceholder} from '../../../../components/src/Post/PostLoadingPlaceholder.component';
import {usePostCommentCRUDOperations} from '../../../../posts/posts.hooks';
import {
  ActivityIndicator,
  FlatList,
  Platform,
  StyleSheet,
  TouchableOpacity,
} from 'react-native';
import {PostCommentForm} from '../../../../post-comments/PostCommentForm.component';
import {isEmpty} from 'lodash';
import {i18n} from 'localize';
import {DEVICE_WIDTH} from '@sponsor/constants';
import { ScrollView } from 'react-native-gesture-handler';
import { uploadPostViewedSuccess } from '../../../../viewed/viewed.actions';
import { isMobile } from 'utils';
import { BackHeader } from '../../../../containers/Header/BackHeader';
import { ActionResult } from '../../../../components/src/ActionResult/ActionResult.component';

export const PostScreen = ({}) => {

  const {id} = useParams();
  const dispatch = useDispatch();
  const activeLanguage = useActiveLanguage();

  const {deleteComment, submitComment, fetchMoreComments} =
    usePostCommentCRUDOperations();

  useEffect(() => {
    dispatch(uploadPostViewedSuccess(id))
  }, [id]);

  const {
    submitAction: submitGetPublicPosts,
    error: postError,
    isLoading: postLoading
  } = useDispatchAction(getPublicPosts);
    
  useEffect(() => {
    if(!!id){
      submitGetPublicPosts?.({ids: [id], lang: activeLanguage})
    }
  },[submitGetPublicPosts, id])

  return (
        <ContentContainer containerStyle={{flex:1}}>
            <ScrollView>
            {!isMobile && <BackHeader />}
            <View 
              style={styles.container}
              variant='screenContainer'
            >
            <ActionResult 
              error={postError}
              isLoading={postLoading}
              renderLoading={() => (<PostLoadingPlaceholder />)}
              renderError={(error) => (
                <Text variant='inputError'>
                  {error}
                </Text>
              )}
            />
            {!postLoading && !postError && (
              /**
               * maxWidth is to fix bug when comment screen goes off screen
               */
              <View style={styles.contentContainer}>
                <PostContainer 
                  width={'100%'}
                  postId={id} 
                />
                <PostCommentProvider
                  canUserLeaveCommentSelector={selectCanUserLeaveComment}
                  meta={{
                    identificationField: 'id',
                    postId: id,
                  }}
                  initialDataSelector={selectPublicPostComments}
                  // initialData={item?.comments || []}
                  onDelete={deleteComment}
                  onFetch={fetchMoreComments}
                  onSubmit={submitComment}
                >
                  {({
                    commentText,
                    userCanLeaveComment,
                    handleCommentTextChange,
                    submitComment: dispatchSubmitComment,
                    handleFetchMore,
                    handleDelete,
                    isFetching,
                    comments,
                    noMoreComments,
                  }) => {
                    return (
                      <View style={{flex: 1}}>
                        {userCanLeaveComment && (
                          // todo: add hide/show form for comments
                          <View variant="sectionContainer">
                            <PostCommentForm
                              commentText={commentText}
                              handleCommentTextChange={handleCommentTextChange}
                              submitComment={dispatchSubmitComment}
                            />
                          </View>
                        )}
                        {!noMoreComments && !isEmpty(comments) && (
                          <TouchableOpacity onPress={handleFetchMore}>
                            <Text variant="secondaryTitle">
                              {i18n.t('postsScreen.seeAllComments')}
                            </Text>
                          </TouchableOpacity>
                        )}
                        {isFetching && <ActivityIndicator />}
                        <FlatList
                          data={comments}
                          keyExtractor={item => item?.id || item?._id}
                          renderItem={({item}) => (
                            <CommentLongCard
                              createdAt={item?.createdAt}
                              text={item?.text}
                              user={item?.user}
                              onDelete={handleDelete(item)}
                            />
                          )}
                        />
                      </View>
                    );
                  }}
                </PostCommentProvider>
              </View>
            )}
            </View>
          </ScrollView>
      </ContentContainer>
  );
};

const styles = StyleSheet.create({
  container: Platform.select({
    web: {
      alignItems: 'center'
    },
    default: {
    }
  }),
  //todo: unified styles for post lists
  contentContainer: {
    flex: 1,
    width: '100%',
    maxWidth:
      //todo: remove dublication
      Platform.OS === 'web'
        ? DEVICE_WIDTH < 650
          ? DEVICE_WIDTH - 50
          : 650
        : '100%',
  },
});
