import React, {useEffect} from 'react';
import {ContentContainer, Button, Row, Text, View, Icon} from 'components';
import {map} from 'lodash';
import {useCallback, useMemo} from 'react';
import {Platform, StyleSheet} from 'react-native';
import {ScrollView, TouchableOpacity} from 'react-native';
import {useDispatch, useSelector} from 'react-redux';
import {EscreenNames} from 'navigation';
import {i18n} from 'localize';
import {
  getCreatorInfo,
  selectExternalCollaborationsWaitingAcceptanceCount,
  selectCreatorBasicInfo,
} from 'store';

import {useNavigation} from 'hooks';
import {DEVICE_WIDTH} from '@sponsor/constants';
import {alertMessage} from 'utils';
import { useRoute } from '../../../hooks/useRoute';
import { colors } from '@sponsor/assets';

const Outlet =
  Platform.OS === 'web' ? require('react-router-dom').Outlet : () => null;

export const EditCreatorPage = () => {
  // only for web
  const loc = useRoute()
  const {navigate} = useNavigation();
  const creator = useSelector(selectCreatorBasicInfo);
  // const tiers = useSelector(selectTiers);

  const collaborationsWaitingAcceptanceCount = useSelector(
    selectExternalCollaborationsWaitingAcceptanceCount,
  );
  const isCreatorPresent = creator?.id;

  const dispatch = useDispatch();

  const openCreatorPage = useCallback(() => {
    // navigate(`/${creator?.pageURL}`);
    navigate(
      EscreenNames.CreatorPublicPage,
      {
        pageURL: creator?.pageURL
      },
      {
        urlParam: true,
        newTab: true
      },
    );
  }, [creator, navigate]);

  //todo: investigate conflicts and probably add loading spinner
  useEffect(() => {
    dispatch(getCreatorInfo(null, {}));
  }, [dispatch]);

  // const publishPage = useCallback(() => {}, []);
  // const isBasicSettingsComplete = useMemo(() => {
  //   return (
  //     creator?.title &&
  //     creator?.description &&
  //     creator?.pageURL &&
  //     creator?.avatarUri &&
  //     creator?.backgroundImageUri
  //   );
  // }, [creator]);

  // const isTiersSettingsComplete = useMemo(() => {
  //   return !isEmpty(tiers);
  // }, [tiers]);

  // const isPaymentSettingsComplete = useMemo(() => {
  //   return !isEmpty(creator?.cardInfo);
  // }, [creator]);

  const ICON_SIZE = 20
  const actions = useMemo(
    () => [
      {
        testId: 'basic-info-button',
        title: i18n.t('editCreatorPage.basicSettings'),
        onPress: () => navigate(EscreenNames.CreatorSettingEdit),
        active: loc === EscreenNames.CreatorSettingEdit,
        LeftIcon: ({color, style}) => (
          <Icon size={ICON_SIZE} style={style} type="material" name="list" color={color}/>
        )
      },
      {
        title: i18n.t('editCreatorPage.paymentSettings'),
        disabled: !isCreatorPresent || Platform.OS !== 'web',
        onDisabledPress: () => {
          if (Platform.OS !== 'web') {
            return alertMessage(
              '',
              i18n.t('editCreatorPage.thisOptionsAvaiableOnWeb'),
            );
          }
        },
        onPress: () => {
          //todo: rename, leave payments or withdraw methods
          navigate(EscreenNames.CreatorPaymentsEdit);
        },
        active: loc === EscreenNames.CreatorPaymentsEdit,
        LeftIcon: ({color, style}) => (
          <Icon size={ICON_SIZE} style={style} name="wallet" color={color}/>
        )
      },
      {
        testId: 'tier-button',
        title: i18n.t('editCreatorPage.tierSettings'),
        onPress: () => navigate(EscreenNames.CreatorTiersEdit),
        active: loc === EscreenNames.CreatorTiersEdit,
        RightIcon:
          collaborationsWaitingAcceptanceCount > 0 ? (
            <View
              style={{
                marginLeft: 5,
                backgroundColor: 'red',
                width: 20,
                height: 20,
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: 12,
              }}>
              <Text style={{color: 'white'}}>
                {collaborationsWaitingAcceptanceCount}
              </Text>
            </View>
          ) : null,
        onDisabledPress:
          Platform.OS !== 'web'
            ? () => {
                alertMessage('', i18n.t('editCreatorPage.tiersBlockedMessage'));
              }
            : null,
        disabled: !isCreatorPresent,
        LeftIcon: ({color, style}) => (
          <Icon size={ICON_SIZE} style={style} name="star-full" color={color}/>
        )
      },
      {
        title: i18n.t('editCreatorPage.posts'),
        onPress: () => navigate(EscreenNames.CreatorPostsEdit),
        active: loc === EscreenNames.CreatorPostsEdit,
        disabled: !isCreatorPresent,
        LeftIcon: ({color, style}) => (
          <Icon size={ICON_SIZE} style={style} name="article" color={color}/>
        )
      },
      {
        title: i18n.t('editCreatorPage.greetings'),
        onPress: () => navigate(EscreenNames.CreatorGreetingsEdit),
        active: loc === EscreenNames.CreatorGreetingsEdit,
        disabled: !isCreatorPresent,
        LeftIcon: ({color, style}) => (
          <Icon size={ICON_SIZE} style={style} name="welcome" color={color}/>
        )
      },
      {
        title: i18n.t('editCreatorPage.socialsTitle'),
        onPress: () => navigate(EscreenNames.CreatorSocialsEdit),
        active: loc === EscreenNames.CreatorSocialsEdit,
        disabled: !isCreatorPresent,
        LeftIcon: ({color, style}) => (
          <Icon size={ICON_SIZE} style={style} name="share" color={color}/>
        )
      },
      {
        title: i18n.t('editCreatorPage.privacy'),
        onPress: () => navigate(EscreenNames.CreatorEditPrivacy),
        active: loc === EscreenNames.CreatorEditPrivacy,
        disabled: !isCreatorPresent,
        LeftIcon: ({color, style}) => (
          <Icon size={ICON_SIZE} style={style} name="key" color={color}/>
        )
      },
      {
        title: i18n.t('editCreatorPage.goalsSettingsButtonTitle'),
        onPress: () => navigate(EscreenNames.CreatorEditGoals),
        active: loc === EscreenNames.CreatorEditGoals,
        disabled: !isCreatorPresent,
        LeftIcon: ({color, style}) => (
          <Icon size={ICON_SIZE} style={style} name="goal" color={color}/>
        )
      },
      {
        title: i18n.t('editCreatorPage.friendsSettingsButtonTitle'),
        onPress: () => navigate(EscreenNames.CreatorEditFriends),
        active: loc === EscreenNames.CreatorEditFriends,
        disabled: !isCreatorPresent,
        LeftIcon: ({color, style}) => (
          <Icon size={ICON_SIZE} style={style} name="people" color={color}/>
        )
      },
      {
        title: i18n.t('editCreatorPage.customizatonSettingsButtonTitle'),
        onPress: () => navigate(EscreenNames.CreatorEditCustomization),
        active: loc === EscreenNames.CreatorEditCustomization,
        disabled: !isCreatorPresent,
        LeftIcon: ({color, style}) => (
          <Icon size={ICON_SIZE} style={style} name="adjust" color={color}/>
        )
      },
      {
        title: i18n.t('editCreatorPage.translationsSettingsButtonTitle'),
        onPress: () => navigate(EscreenNames.CreatorEditTranlsation),
        active: loc === EscreenNames.CreatorEditTranlsation,
        disabled: !isCreatorPresent,
        LeftIcon: ({color, style}) => (
          <Icon size={ICON_SIZE} style={style} name="translate" color={color}/>
        )
      },
      {
        title: i18n.t('editCreatorPage.tiersTranslationsBtnTitle'),
        onPress: () => navigate(EscreenNames.CreatorTiersTranslation),
        //todo: find another way to setup active prop
        active: loc.includes(EscreenNames.CreatorTiersTranslation),
        disabled: !isCreatorPresent,
        LeftIcon: ({color, style}) => (
          <Icon size={ICON_SIZE} style={style} name="translate" color={color}/>
        )
      },
      {
        title: i18n.t('editCreatorPage.adsPlatformAccess'),
        onPress: () => navigate(EscreenNames.AdsPlatformAccess),
        //todo: find another way to setup active prop
        active: loc.includes(EscreenNames.AdsPlatformAccess),
        disabled: !isCreatorPresent,
        // LeftIcon: ({color, style}) => (
        //   <Icon size={ICON_SIZE} style={style} name="translate" color={color}/>
        // )
      },
    ],
    [navigate, loc, isCreatorPresent, collaborationsWaitingAcceptanceCount],
  );

  return (
    <ContentContainer>
      <ScrollView>
        <Row
          containsSimilarItems
          // rowCenter
          containerStyle={{
            flexWrap: 'wrap',
            marginBottom: 25,
            borderWidth: 1,
            borderColor: colors.DUSTY_GRAY,
            borderRadius: 8,
            padding: 8,
            paddingVertical: 12
          }}>
          {map(actions, ({title, LeftIcon, RightIcon, testId, ...action}) => (
            <TouchableOpacity
              {...action}
              testID={testId}
              style={styles.settingsButton}
            >
              <Row rowCenter>
                {LeftIcon ? <LeftIcon style={{paddingHorizontal:4}} color={action.active ? colors.GREEN_OFFICIAL: colors.GRAY}/> : undefined}
                <Text style={[action.active ? {color: colors.GREEN_OFFICIAL} : {color: colors.GRAY}, styles.settingsButtonTitle]}>
                  {title}
                </Text>
                {RightIcon || undefined}
              </Row>
            </TouchableOpacity>
          ))}
          <TouchableOpacity
            disabled={!creator?.pageURL}
            onPress={openCreatorPage}
            style={styles.settingsButton}
          >
            <Text bold style={styles.settingsButtonTitle}>
              {i18n.t('editCreatorPage.preview')}
            </Text>
          </TouchableOpacity>
        </Row>
        <Outlet />
      </ScrollView>
    </ContentContainer>
  );
};

const styles = StyleSheet.create({
  checkmarkIcon: {
    marginHorizontal: 10,
  },
  settingsButton: Platform.select({
    web: {
      // width: DEVICE_WIDTH > 150 ? 150 : DEVICE_WIDTH - 20,
      paddingHorizontal: 8
    },
    default: {
      width: DEVICE_WIDTH - 50,
      maxWidth: DEVICE_WIDTH - 50,
    },
  }),
  settingsButtonTitle: {
    fontSize: 16,
    // textTransform: 'uppercase',
  },
});
