import * as Yup from 'yup';
import {i18n} from 'localize';

export const getSocialsSchema = () =>
  Yup.object().shape({
    youtube: Yup.string().url(i18n.t('validationSchema.invalidURL')),
    twitch: Yup.string().url(i18n.t('validationSchema.invalidURL')),
    // twitter: Yup.string().url(i18n.t('validationSchema.invalidURL')),
    reddit: Yup.string().url(i18n.t('validationSchema.invalidURL')),
    // telegram: Yup.string().url(i18n.t('validationSchema.invalidURL')),
    //   .required(i18n.t("validationSchema.requiredField)"),
  });
