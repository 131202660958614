import {AxiosResponse, TCreatorTranslation} from '../types';

export enum ECreatorTranslationAnalyticsEventNames {
  UPDATE_CREATOR_TRANSLATION_SUCCEEDED = 'creator_translation_update_succeeded',
  UPDATE_CREATOR_TRANSLATION_FAILED = 'creator_translation_update_failed',
  UPLOAD_CREATOR_TRANSLATION_SUCCEEDED = 'creator_translation_upload_succeeded',
  UPLOAD_CREATOR_TRANSLATION_FAILED = 'creator_translation_upload_failed',
  DELETE_CREATOR_TRANSLATION_SUCCEEDED = 'creator_translation_delete_succeeded',
  DELETE_CREATOR_TRANSLATION_FAILED = 'creator_translation_delete_failed',
}

//todo: remove partial
export type TPostUploadTranslationInput = Partial<TCreatorTranslation>;
export type TPostUploadTranslationOutput = AxiosResponse<TCreatorTranslation>;

export type TUpdateTranslationInput = Partial<TCreatorTranslation>;
export type TUpdateTranslationOutput = AxiosResponse<TCreatorTranslation>;

export type TDeleteTranslationInput = Partial<TCreatorTranslation>;
export type TDeleteTranslationOutput = AxiosResponse<null>;
