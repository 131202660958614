import {Column, Row, FormInput, Button, Text} from 'components';
import React, {useCallback, useState} from 'react';
import {StyleSheet, View, Platform, ActivityIndicator} from 'react-native';
import {Formik} from 'formik';
import {EscreenNames} from 'navigation';
import {ContentContainer} from 'components';
import {TouchableOpacity} from 'react-native';
import {useDispatch} from 'react-redux';
import {colors} from '@sponsor/assets';
import {i18n} from 'localize';
import {alertMessage} from 'utils';
import {useNavigation, useParams} from 'hooks';
import {resetPassword} from 'store';
import {getResetPasswordValidationSchema} from './resetPasswordValidationSchema';

export const ResetPassword = () => {
  const {token} = useParams();

  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const {navigate} = useNavigation();

  const sendSignup = useCallback(
    ({password}) => {
      if (!token) {
        return;
      }
      setIsLoading(true);
      dispatch(
        resetPassword(
          {
            token,
            password,
          },
          {
            onSuccess: () => {
              setIsLoading(false);
              alertMessage('', i18n.t('resetPassword.successfullyReset'), [
                {
                  text: 'ok',
                  onPress: () => navigate(EscreenNames.Login),
                },
              ]);
            },
            onFail: (err: any) => {
              setIsLoading(false);
              setError(err?.message);
            },
          },
        ),
      );
    },
    [dispatch, navigate, token],
  );

  return (
    <ContentContainer>
      <Text variant='authFormTitle'>
        {i18n.t('resetPassword.resetPasswordTitle')}
      </Text>
      <Formik
        initialValues={{
          confirmPassword: '',
          password: '',
        }}
        validationSchema={getResetPasswordValidationSchema()}
        validate={(values: any) => {
          const errors: any = {};
          if (values.password !== values.confirmPassword) {
            errors.confirmPassword = i18n.t(
              'validationSchema.confirmPasswordsNotCorrect',
            );
          }
          return errors;
        }}
        onSubmit={(values: any) => {
          sendSignup(values);
        }}>
        {({values, handleSubmit, handleChange, errors}) => (
          <View
            style={{
              borderWidth: 0.5,
              borderColor: colors.CONCRETE,
              padding: 25,
            }}>
            <FormInput
              label={i18n.t('resetPassword.newPassword')}
              value={values?.password}
              secureTextEntry
              placeholder={i18n.t('resetPassword.newPassword')}
              onChangeText={handleChange('password')}
              error={errors?.password}
            />
            <FormInput
              label={i18n.t('resetPassword.passwordConfirmation')}
              value={values?.confirmPassword}
              placeholder={i18n.t('resetPassword.passwordConfirmation')}
              secureTextEntry
              onChangeText={handleChange('confirmPassword')}
              error={errors?.confirmPassword}
            />
            {error && <Text variant="inputError">{error}</Text>}
            <Row containerStyle={{justifyContent: 'space-between'}}>
              <Row>
                <Button
                  containerStyle={{marginVertical: 25}}
                  onPress={handleSubmit}
                  title={i18n.t('resetPassword.resetPassword')}
                />
                {isLoading && <ActivityIndicator style={{paddingLeft: 15}} />}
              </Row>

              <Column rowCenter columnCenter>
                <Text>{i18n.t('resetPassword.haveAccount')}</Text>
                <TouchableOpacity onPress={() => navigate(EscreenNames.Login)}>
                  <Text variant="thirdTitle">{i18n.t('login')}</Text>
                </TouchableOpacity>
              </Column>
            </Row>
          </View>
        )}
      </Formik>
    </ContentContainer>
  );
};

const styles = StyleSheet.create({
  pageContainer: {
    justifyContent: 'center',
    marginVertical: 50,
  },
  formTitle: {
    fontSize: 24,
    textAlign: 'center',
    paddingVertical: 15,
  },
  formContainer: {
    padding: 15,
    borderWidth: 0.5,
    borderColor: colors.DUSTY_GRAY,
    flex: 1,
  },
  errorText: {
    color: 'red',
    fontSize: 15,
  },
  inputContainer: {
    width: '100%',
    flex: 1,
    height: 75,
    backgroundColor: colors.CONCRETE,
    padding: 15,
    outlineColor: Platform.OS === 'web' ? 'orange' : null,
  },
});
