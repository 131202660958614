import { selectPostViewed } from '../../../viewed/viewed.selectors'
import { ContentContainer, KeyboardAvoidingView, PostContainer, Text, View } from 'components'
import React, { useCallback } from 'react'
import { FlatList, Platform, StyleSheet } from 'react-native'
import { useSelector } from 'react-redux'
import { isMobile } from 'utils'
import { i18n } from 'localize'
import { BackHeader } from '../../../containers/Header/BackHeader'

export const ViewedPosts = () => {

    const postsIds = useSelector(selectPostViewed)
    const renderPost = useCallback(({item: postId}) => {
        return (
            <PostContainer
                // width={"100%"}
                postId={postId} 
            />
        )
    },[])
    
    return (
        <KeyboardAvoidingView>
            <ContentContainer>
            {!isMobile && <BackHeader />}
                <View variant='screenContainer'>
                    {/* todo: move post list to standalone component */}
                    <FlatList 
                        data={postsIds}
                        contentContainerStyle={{alignItems:'center',}}
                        renderItem={renderPost}
                        ListHeaderComponent={!isMobile ? (
                            <Text variant='sectionTitle'>
                                {i18n.t("viewedPostsScreen.screenTitle")}
                            </Text>
                        ) : null}
                        ItemSeparatorComponent={() => <View variant='verticalListSeparator'/>}
                        ListEmptyComponent={() => (
                            <Text center variant='thirdTitle'>
                                {i18n.t("viewedPostsScreen.emptyListTitle")}
                            </Text>
                        )}
                    />
                </View>               
            </ContentContainer>
        </KeyboardAvoidingView>
    )
}

const styles = StyleSheet.create({
    container: Platform.select({
      web: {
        flex:1,
      },
      default: {
      }
    }),
  });