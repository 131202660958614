import {FlatList, ScrollView, TouchableOpacity} from 'react-native';
import React, {useMemo, useState} from 'react';
import {useSelector} from 'react-redux';
import {selectCreatorCurrencyBalance, TStore} from 'store';
import {Button, ContentContainer, Text, View} from 'components';
import {Picker, PickerItem} from '../../../components/src/Picker/Picker';
import {CryptoCoins} from '../../..//crypto-payment/cryypto-payment.constants';
import {selectWithdrawCryptoWallet} from '../../../money-withdraw/money-withdraw.selectors';
import {map} from 'lodash';
import {WithdrawMethodContainer} from '../../../money-withdraw-methods/WithdrawMethod.container';
import {useDispatchAction, useNavigation, useParams} from 'hooks';
import {WithdrawalInfo} from '../../../money-withdraw/WithdrawalInfo.component';
import {i18n} from 'localize';
import {EscreenNames} from 'navigation';
import {withdrawCreatorBalance} from '../../../money-withdraw/money-withdraw.saga';
import { useAsync } from 'react-use';
import { MoneyWithdrawApiService } from '../../../money-withdraw/money-withdraw.api';

export const CryptoWithdrawalScreen = ({}) => {

  const {currencyName: initialCurrencyName} = useParams();
  const {navigate} = useNavigation();

  const [currencyName, setCurrencyName] = useState(initialCurrencyName || '');
  const [withdrawalMethodId, setWithdrawalMethodId] = useState<null | string>(
    null,
  );
  const balance = useSelector((state: TStore) =>
    selectCreatorCurrencyBalance(state, currencyName),
  );
  const cryptoWithdrawMethod = useSelector(selectWithdrawCryptoWallet);

  const cryptoWithdrawMethodsIds = useMemo(() => {
    return map(cryptoWithdrawMethod, w => w?.id);
  }, [cryptoWithdrawMethod]);

  const {
    isLoading: isWithdrawaInProgress, 
    error: withdrawalError, 
    submitAction
  } = useDispatchAction(withdrawCreatorBalance, {
    data: {
      withdrawMethodId: withdrawalMethodId || "",
      currencyName,
    },
    onSuccess: () => {
      navigate(EscreenNames.Revenue)
    }
  });

  const {loading, value: withdrawFees} =  useAsync(async () => {
    const {data} = await MoneyWithdrawApiService.getCryptoWithdrawFees({currency: currencyName})
    return data;
  }, [currencyName])

  return (
    <ContentContainer>
      <View variant='screenContainer'>
        <ScrollView>
          <View variant="sectionContainer">
            <Text variant="inputLabel">
              {i18n.t('cryptoWithdrawalScreen.coinPickerLabel')}
            </Text>
            <Picker
              data={CryptoCoins.map(c => ({...c, label: c.name}))}
              selectedValue={currencyName}
              onValueChange={item => {
                setCurrencyName(item?.code);
              }}
            />
          </View>

          <View variant="sectionContainer">
            <FlatList
              data={cryptoWithdrawMethodsIds}
              extraData={withdrawalMethodId}
              ItemSeparatorComponent={() => <View variant='verticalListSeparator'/>}
              ListEmptyComponent={() => (
                <TouchableOpacity
                  onPress={() => {
                    navigate(EscreenNames.CreatorPaymentsEdit);
                  }}>
                  <Text style={{fontWeight: 'bold'}} variant="inputLabel">
                    {i18n.t(
                      'cryptoWithdrawalScreen.cryptowithdrawMethodsEmptyListTitle'
                    )}
                  </Text>
                </TouchableOpacity>
              )}
              ListHeaderComponent={() => (
                <Text variant="inputLabel">
                  {i18n.t('cryptoWithdrawalScreen.withdrawCryptoWalletPickerLabel')}
                </Text>
              )}
              renderItem={({item}) => (
                <WithdrawMethodContainer
                  id={item}
                  isChoosen={withdrawalMethodId == item}
                  onChoose={() => {
                    setWithdrawalMethodId(item);
                  }}
                />
              )}
            />
          </View>

          {!!withdrawalMethodId && (
            <>
              <Button
                title={i18n.t('cryptoWithdrawalScreen.withdrawBtnTitle')}
                onPress={submitAction}
                isLoading={isWithdrawaInProgress}
              />
              <Text variant='inputError'>
                {withdrawalError}
              </Text>
            </>
          )}
          
          { !loading && <View variant="sectionContainer">
            <WithdrawalInfo
              availableBalance={{
                currencyName,
                amount: balance?.amount || 0,
              }}
              minimalWithdraw={{
                amount: withdrawFees?.min_withdraw?.amount || 0,
                currencyName: withdrawFees?.min_withdraw?.currency || "",
              }}
              networkFeeRange={
                {
                  min: withdrawFees?.withdraw_fees?.amount || 0,
                  max: withdrawFees?.withdraw_fees?.amount || 0,
                  currencyName: withdrawFees?.withdraw_fees?.currency || ""
                }
              }
            />
          </View>}
        </ScrollView>
      </View>
    </ContentContainer>
  );
};
