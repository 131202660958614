import React, {useCallback} from 'react';
import {TranslationEditPreview} from './TranslationEditPreview.component';
import {useSelector} from 'react-redux';
import {TStore} from 'store';
import {selectPostTranslationById} from './posts-translations.selectors';
import {useDispatchAction, useNavigation} from 'hooks';
import {EscreenNames} from 'navigation';
import {deletePostTranslation} from './posts-translations.actions';

type TProps = {
  id: string;
  parentId: string;
};

export const TranslationEditPreviewContainer: React.FC<TProps> = ({
  id,
  parentId,
}) => {
  const {navigate} = useNavigation();

  const translation = useSelector((state: TStore) =>
    selectPostTranslationById(state, id, parentId),
  );

  const {
    submitAction: submitDeletion
  } = useDispatchAction(deletePostTranslation, {
    data: {
      id, 
      lang: translation?.lang, 
      parentId
    },
  });

  const onEditPress = useCallback(() => {
    navigate(EscreenNames.EditPostTranslations, {
      id,
      parentId,
    });
  }, [navigate, id, parentId]);

  return (
    <TranslationEditPreview
      {...(translation || {})}
      id={id}
      onEditPress={onEditPress}
      onDeletePress={submitDeletion}
    />
  );
};
