export const cryptoWithdrawalScreenLocalization = {
  en: {
    cryptoWithdrawalScreen: {
      screenTitle: "Crypto withdrawal",
      coinBalanceTitle: 'Balance',
      coinPickerLabel: 'Choose coin',
      withdrawCryptoWalletPickerLabel: 'Choose crypto wallet address',
      withdrawBtnTitle: 'Withdraw',
      cryptowithdrawMethodsEmptyListTitle: 'Add crypto wallet address firstly',

      withdrawFeeRangeTitle: "Network fees",
      minimalWithdrawWarningTitle: "Minimal withdraw",
    },
  },
  uk: {
    cryptoWithdrawalScreen: {
      screenTitle: "Списання криптовалют",
      coinPickerLabel: 'Виберіть валюту',
      coinBalanceTitle: 'Баланс',
      withdrawCryptoWalletPickerLabel:
        'Виберіть адрес криптогаманця для зарахування',
      withdrawBtnTitle: 'Вивести',
      cryptowithdrawMethodsEmptyListTitle:
        'Спочатку додайте криптогаманець для зарахування коштів',
      withdrawFeeRangeTitle: "Комісія мережі",
      minimalWithdrawWarningTitle: "Мінімальна сума зняття",
    },
  },
};
