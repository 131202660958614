import {combineReducers} from 'redux';
import creatorReducer from './creator';
import creatorStatsReducer from './creatorStats';
import authReducer from './auth';
import tiersReducer from './tiers';
import userReducer from './user';
import postsReducer from './posts';
import searchReducer from './search';
import settingsReducer from './settings';
import { viewedReducer } from '../../viewed/viewed.reducer'
import {CLEAR_STORE} from './common';
import { postsTranslationsReducer } from '../../posts-translations/posts-translations.reducer';
import { goalsTranslationsReducer } from '../../goalsTranslations/goalsTranslations.reducer';

// todo: auto adding to the storage slice 
const rootReducer = (state: any, action: any) => {
  // TODO: check undefined value;
  if (action.type === CLEAR_STORE) {
    state = undefined;
  }

  return combineReducers({
    auth: authReducer,
    user: userReducer,
    creator: creatorReducer,
    creatorStats: creatorStatsReducer,
    tiers: tiersReducer,
    posts: postsReducer,
    search: searchReducer,
    settings: settingsReducer,
    viewed: viewedReducer,
    postsTranslations: postsTranslationsReducer,
    goalsTranslations: goalsTranslationsReducer
  })(state, action);
};

export {
    rootReducer
}