import React from 'react';
import {Fade, Placeholder, PlaceholderLine} from 'rn-placeholder';
import {DEFAULT_POST_CONTAINER_WIDTH} from '../../../posts/posts.constants';

export function PostLoadingPlaceholder() {
  return (
    <Placeholder style={{width: DEFAULT_POST_CONTAINER_WIDTH}} Animation={Fade}>
      <PlaceholderLine style={{borderRadius: 0, height: 60}} />
      <PlaceholderLine
        style={{
          width: 100,
          height: 25,
        }}
      />
      <PlaceholderLine style={{width: 200, height: 35}} />
      <PlaceholderLine
        style={{width: DEFAULT_POST_CONTAINER_WIDTH, height: 150}}
      />
    </Placeholder>
  );
}
