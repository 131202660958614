//todo: split and think about another data structure 
export const EscreenNames = {
  Auth: 'Auth',
  App: 'App',
  AppNavigator: 'AppNavigator',

  Signup: '/signup',
  Login: '/login',
  ForgotPassword: '/forgot-password',
  Home: '/home',

  Search: '/search',
  ProfileSettings: '/profile-settings',
  Profile: '/profile',

  PostNew: '/edit/posts/new',
  PostEdit: '/edit/posts/edit',

  TierNew: '/edit/tier/new',
  TierEdit: '/edit/tier',
  TierBuy: '/purchase',

  Subscriptions: '/subscriptions',
  CancelSubscription: '/cancel-subscription',
  Subscribers: '/subscribers',
  Revenue: '/revenue',
  CreatorPublicPage: '/c', // /creator-public
  CreatorPosts: '/creator/posts',
  CreatorPublicTiersScreen: '/creator-tiers',

  CreatorGeneralInfo: '/creator',
  CreatorTiersEdit: '/edit/about/tiers',
  CreatorEditPrivacy: '/edit/about/privacy',
  CreatorEditGoals: '/edit/about/goals',
  CreatorEditFriends: '/edit/about/friends',
  CreatorEditTranlsation: '/edit/about/translations',

  PublicCreateGoal: '/public/edit/goal',
  CreatorGoalEdit: '/edit/goal',

  MoneyWithdrawMethodEdit: '/edit/withdraw-method',
  WithdrawScreen: '/withdraw',
  EditCreatorMerchantScreen: '/edit/creator-merchant',

  CryptoWithdrawalScreen: '/crypto-withdrawal',
  FiatWithdrawalScreen: '/fiat-withdrawal',
  
  //todo: one schema for all routes
  CreatorSettingEditMenu: 'CreatorSettingEditMenu',
  CreatorSettingEdit: '/edit/about',
  CreatorPaymentsEdit: '/edit/about/payments',
  CreatorPostsEdit: '/edit/about/posts',
  CreatorGreetingsEdit: '/edit/about/greetings',
  CreatorEditCustomization: '/edit/about/customization',

  CreatorSocialsEdit: '/edit/about/socials',

  PostScreen: '/post',
  TermOfUse: '/policy/legal',
  PublicOffer: '/public-offer',

  EditTierTranslation: '/tiers-translations/edit',
  //todo: make one approach for writting routes names ( with / and without )
  CreatorTiersTranslation: 'tiers-translations',

  PricingPlanScreen: '/pricing-plans',

  UserPrivacy: '/user-privacy',
  AccountDeletion: '/account/delete',


  ViewedPosts: "/posts/viewed",

  EditPostTranslations: '/edit/post/translation/edit',
  PostsTranslations: '/edit/post/translations',
  
  EditTranslationsScreen: '/edit/translations',
  TranslationsScreen: '/translations',

  AdsPlatformAccess: 'ads-platform-access',

};
