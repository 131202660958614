import { Platform, StyleSheet } from 'react-native'
import React, { useMemo } from 'react'
import { Button, Row } from 'components'
import { map } from 'lodash'
import Animated, { FadeInLeft } from 'react-native-reanimated'

export const CreatorMenuList = React.memo(({buttons}:any) => {

    const Wrapper = Platform.OS === 'web' ?
    ({children}:any) => <Row flex={1} flexWrap>{children}</Row> : 
    ({children}:any) => <>{children}</> 

    return (
        <Wrapper>
            {map(buttons, (b,i:number) => (
                <Animated.View
                    entering={
                        //todo: unified
                        FadeInLeft.delay(i * 150).duration(600)
                    }
                >
                    <Button
                        key={b.title}
                        title={b.title}
                        onPress={b.onPress}
                        titleStyle={styles.creatorContentButtonTitle}
                        containerStyle={styles.creatorContentButtonContainer}
                        primary
                    />
                </Animated.View>
            ))}
        </Wrapper>
    )
})

const styles = StyleSheet.create({
    creatorContentButtonContainer: {
      width: '100%',
      padding: 5,
    },
creatorContentButtonTitle: {
    textTransform: 'uppercase',
},
});
