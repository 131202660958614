import { ActivityIndicator, FlatList, Platform, StyleSheet, TouchableOpacity } from 'react-native'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useActiveLanguage, useDispatchAction, useNavigation } from 'hooks';
import {
    getPosts,
    selectCanUserLeaveComment,
    selectInfeedPostComments,
    selectPostSearchResult,
} from 'store';
import { PostListFooter } from './PostListFooter.component';
import { EscreenNames } from 'navigation';
import { i18n } from 'localize';
import { Button, CommentLongCard, PostCommentProvider, PostContainer, Text, View } from 'components';
import { TComment } from 'types';
import { ApiService } from 'services';
import { LOG } from 'utils';
import { isEmpty, slice } from 'lodash';
import { useSelector } from 'react-redux';
import { PostCommentForm } from '../post-comments/PostCommentForm.component';

type TPostListContainerProps = {
  postsIdx?: null | string[],
  getPostsSelector?: any,
  getOnePostSelector?: any
}

export const PostListContainer:React.FC<TPostListContainerProps> = ({
  postsIdx = null,
  getOnePostSelector
}) => {

    const postSearchResult = useSelector(selectPostSearchResult);
    const locale = useActiveLanguage()

    const renderedPostsIdx = useMemo(() => {
      return postsIdx || Object.keys(postSearchResult?.results || {});
    }, [postsIdx, postSearchResult?.results]);
    
    const { navigate } = useNavigation()
    const [hasMorePosts, setHasMorePosts] = useState(true);

    const fetchMoreComments = useCallback(
        // todo: add pagination
        async (_, {postId}) => {
        // TODO: add pagination, return not array;
        let comments: TComment[] = [];
        try {
            const {data} = await ApiService.getPostComments(postId);
            comments = data?.results;
        } catch (err) {
            LOG(err);
        } finally {
            return comments;
        }
        },
        [],
    );

    // TODO: move to hook
    const submitComment = useCallback(async (text, meta) => {
        try {
            const {data} = await ApiService.postAddNewComment({
                text,
                post: meta?.postId,
            });
            return data;
        } catch (err) {
            LOG(err);
        }
    }, []);

    const deleteComment = useCallback(async (comment: TComment) => {
        try {
            await ApiService.deleteComment(comment?.id);
        } catch (err) {
            LOG(err);
        }
    }, []);

    const COMMENTS_SHOWN_LIMIT = 5

    const navigateToPostPage = useCallback((id) => {
        //todo: think what's better (new tab or not )
        navigate(EscreenNames.PostScreen, {id}, {newTab: true});
      }, [navigate]);

      
    const renderPost = useCallback(
        ({item: postId}) => {
          // null
          return (<View style={styles.postContainer}>
            <PostContainer
              width={'100%'}
              // reduxSelector={selectUserCreatorsPostById}
              reduxSelector={getOnePostSelector}
              postId={postId}
            />
            <PostCommentProvider
              canUserLeaveCommentSelector={selectCanUserLeaveComment}
              meta={{
                identificationField: 'id',
                postId: postId,
              }}
              initialDataSelector={selectInfeedPostComments}
              // initialData={item?.comments || []}
              onDelete={deleteComment}
              onFetch={fetchMoreComments}
              onSubmit={submitComment}>
              {({
                commentText,
                userCanLeaveComment,
                handleCommentTextChange,
                submitComment: providerSubmitComment,
                handleFetchMore,
                handleDelete,
                isFetching,
                comments,
                noMoreComments,
              }) => {
                return (
                  <View>
                    {!noMoreComments && !isEmpty(comments) && (
                      <TouchableOpacity onPress={handleFetchMore}>
                        <Text variant="secondaryTitle">
                          {i18n.t('postsScreen.seeAllComments')}
                        </Text>
                      </TouchableOpacity>
                    )}
                    {isFetching && <ActivityIndicator />}
                    <FlatList
                        //todo: check all limits 
                        data={comments?.length ? slice(comments, 0, COMMENTS_SHOWN_LIMIT) : []}
                        //todo: id or _id
                        //@ts-ignore
                        keyExtractor={(item:TComment) => item?.id || item?._id}
                        ListFooterComponent={() => comments?.length > COMMENTS_SHOWN_LIMIT ? (
                            <TouchableOpacity onPress={() => navigateToPostPage(postId)}>
                                <Text>{i18n.t("postsScreen.seeAllCommentsBtnTitle")}</Text>
                            </TouchableOpacity>
                        ): null}
                        renderItem={({item}) => (
                            <CommentLongCard
                            {...item}
                            onDelete={handleDelete(item)}
                            />
                        )}
                    />
                    {userCanLeaveComment && (
                      <View style={{marginTop: 10}}> 
                          <PostCommentForm
                            commentText={commentText}
                            handleCommentTextChange={handleCommentTextChange}
                            submitComment={providerSubmitComment} 
                          />
                      </View>

                    )}
                  </View>
                );
              }}
            </PostCommentProvider>
          </View>)
          }, [
          deleteComment,
          fetchMoreComments,
          submitComment,
          selectInfeedPostComments
    ])
    
    const {
        isLoading: isPostsLoading,
        error: postsError, 
        submitAction: fetchMorePosts
      } = useDispatchAction(getPosts, {
        // data: {page: (postSearchResult?.page || 0) + 1},
        onSuccess: (data) => {
          if (!data?.results?.length ||
            // todo: check posts limits inside the app, too much storage used probably
              data?.page > 6) {
                setHasMorePosts(false);
          }
        }
      })

    useEffect(() => {
        fetchMorePosts({ 
            page: 1, 
            lang: locale
        })
    },[fetchMorePosts, locale])

    return (
        <FlatList
            data={renderedPostsIdx || []}
            showsVerticalScrollIndicator={false}
            ListFooterComponent={<PostListFooter 
                error={postsError}
                isLoading={isPostsLoading}
                hasMorePosts={hasMorePosts}
                isEmpty={isEmpty(renderedPostsIdx)}
                onFetch={() => fetchMorePosts({
                  page: postSearchResult?.page + 1,
                  lang: locale 
                })}
            />}
            onRefresh={() => {
                if(!isPostsLoading){
                    fetchMorePosts({
                      page:1, 
                      lang: locale
                    })}
                }
            }
            refreshing={isPostsLoading}
            ItemSeparatorComponent={() => <View variant='verticalListSeparator'/>}
            ListEmptyComponent={
                <View style={{alignItems: 'center'}}>
                    <Text variant="sectionTitle" style={styles.noPostsText}>
                        {i18n.t('postsScreen.noPosts')}
                    </Text>
                    <Button
                        title={i18n.t('postsScreen.findCreatorsBtnTitle')}
                        onPress={() => navigate(EscreenNames.Search)}
                    />
                    <Button
                        containerStyle={{marginTop: 10}}
                        title={i18n.t('postsScreen.retryDownloadPosts')}
                        onPress={() => fetchMorePosts({
                            page:1,
                            lang: locale
                        })}
                    />
                </View>
            }
            keyExtractor={item => item}
            renderItem={renderPost}
        />
    )
}


const styles = StyleSheet.create({
    container: {
      flex:1
    },
    marginHorizontal: {
      marginHorizontal: 5,
      // padding:20
    },
    noPostsText: {
      textAlign: 'center',
      marginTop: 50,
      paddingHorizontal: 20,
    },
      postContainer: {
        paddingVertical: Platform.OS == 'web' ? 0 : 12
      }
  });
  

