import React from 'react'
import { Column, Image } from 'components'
import {Config} from 'libs'
import { DEVICE_WIDTH } from '@sponsor/constants'
import { Platform, StyleSheet } from 'react-native'

export const AuthImage = () => {
  return (
    <Column 
      rowCenter 
      containerStyle={styles.container}
      flex={1} 
    >
      <Image 
          source={{uri: `${Config.STATIC_IMAGES_URL}/landing/tatry.webp`}}
          resizeMode="contain"
          style={styles.image}
      />
    </Column>
  )
}

const styles = StyleSheet.create({
  container: {    
    padding: 25,
    ...Platform.select({
      web: {
        minHeight: 300, 
        minWidth: 500, 
      },
      default: {
        width: DEVICE_WIDTH,
      }
    })
  },
  image: {
    borderRadius: 8, 
    ...Platform.select({
      web: {
        flex:1, 
        width: '100%', 
        height: '100%',
      },
      default: {
        width: DEVICE_WIDTH - 50,
        height: 200
      }
    })  
  }
})
