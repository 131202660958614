import React from 'react';
import {
  Text as RNText,
  StyleSheet,
  TextProps,
  TextStyle,
  Platform,
} from 'react-native';
import {colors} from '@sponsor/assets';

interface IProps {
  variant?: keyof typeof textStyles;
  color?: string;
  center?: boolean;
  style?: TextStyle;
  white?: boolean;
  bold?: boolean;
}

export const Text: React.FC<IProps & TextProps> = ({
  variant,
  style = {},
  white,
  center,
  bold,
  color,
  ...otherProps
}) => {
  return (
    <RNText
      selectable={Platform.OS !== 'web'}
      style={[
        {color: 'black'},
        textStyles.fontFamily,
        variant && textStyles[variant],
        style,
        center && {textAlign: 'center'},
        !!color && {color},
        white && {color: 'white'},
        bold && {fontWeight: '800', fontFamily: 'GolosTextBold'},
      ]}
      {...otherProps}
    />
  );
};

export const textStyles = StyleSheet.create({
  // landingTitle: {
  //   textTransform: 'uppercase',
  //   color: 'white',
  //   fontSize: RFPercentage(2.5),
  //   lineHeight: RFPercentage(3.5),
  //   fontWeight: 'bold',
  // },
  // landingSubtitle: {
  //   color: 'white',
  //   fontSize: RFPercentage(2),
  //   lineHeight: RFPercentage(2.8),
  // },
  bold: {
    fontWeight: 'bold',
    fontFamily: 'GolosTextBold',
  },
  description: {
    fontSize: 18,
  },
  fontFamily: {
    fontFamily: 'GolosText'
  },
  formTitle: {
    fontSize: 22,
    fontWeight: 'bold',
    fontFamily: 'GolosTextBold',
    marginVertical: 25,
    textAlign: 'center',
  },
  authFormTitle: {
    fontWeight: 'bold',
    fontFamily: 'GolosTextBold',
    fontSize: 24,
    textAlign: 'center',
    paddingVertical: 15,
  },
  formLabel: {
    fontSize: 18,
    paddingVertical: 12,
  },
  formHint: {
    color: colors.ORANGE,
    fontSize: 15,
    paddingVertical: 5,
  },
  creatorTitle: {
    fontWeight: 'bold',
    fontFamily: 'GolosTextBold',
    fontSize: 20,
    lineHeight: 24,
  },
  creatorSubtitle: {
    fontSize: 16,
    color: colors.GRAY,
    // textTransform: 'lowercase',
  },
  creatorPageMainText: {
    fontSize: 22,
    color: 'black',
    textAlign: 'left',
    paddingVertical: 5,
    fontFamily: 'GolosText'
  },
  buttonText: {
    fontWeight: 'bold',
    fontFamily: 'GolosTextBold',
    fontSize: 16,
  },
  sectionTitle: {
    fontSize: 20,
    fontWeight: 'bold',
    fontFamily: 'GolosTextBold',
    paddingVertical: 5,
  },
  tierTitle: {
    fontSize: 22,
    fontWeight: 'bold',
    fontFamily: 'GolosTextBold',
    paddingVertical: 6,
  },
  secondaryTitle: {
    fontWeight: 'bold',
    fontFamily: 'GolosTextBold',
    paddingVertical: 20,
    textTransform: 'uppercase',
  },
  thirdTitle: {
    fontWeight: '600',
    fontFamily: 'GolosTextBold',
    textTransform: 'uppercase',
  },
  checkboxTitle: {
    fontSize: 16,
  },
  tableCeilTitle: {
    fontSize: 18,
  },
  tableCeilText: {
    fontSize: 18,
    // fontWeight: '500',
    textTransform: 'uppercase'
  },
  creatorHintText: {
    color: 'gray',
    textTransform: "lowercase",
    fontSize: 16
  },
  creatorPageSectionTitle: {
    fontSize: 28,
    color: 'black',
    lineHeight: 40
  },
  postDescription: {
    fontSize: 16,
    lineHeight: 24,
    paddingVertical: 8,
  },
  inputLabel: {
    fontSize: 16,
  },
  inputSubLabel: {
    fontSize: 14
  },
  inputError: {
    color: 'red',
    fontSize: 16,
  },
  urlInsideText: {
    color: colors.GREEN_OFFICIAL,
    fontWeight: 'bold',
    fontFamily: 'GolosTextBold',
  },
  dangerous: {
    color: "red",
    // fontSize: 16 // todo: update styles, fontsizes
  },
  previewText: {
    lineHeight: 35,
    fontSize: 18
  }
});
