import React from 'react';
import {Platform, StyleSheet} from 'react-native';
import {Text} from '../Text';
import {VideoPlayer} from '../VideoPlayer/VideoPlayer';
import {i18n} from 'localize';
import {TCreator} from 'types';
import {View} from '../View';
import {colors} from '@sponsor/assets';
import {RenderHtml} from '..';
import {decodeHTMLEntities} from 'utils';
import {textStyles} from '../Text/Text';
import { DEVICE_WIDTH } from '@sponsor/constants';

//  = 'https://www.youtube.com/watch?v=pAajYvbitQ4'
export const AboutAuthor: React.FC<Partial<TCreator>> = ({
  description = '',
  videoURL = '',
}) => {
  // const handleUrlPress = useCallback(url => {
  //   Linking.openURL(url);
  // }, []);

  return (
    <View style={styles.container}>
      <View bordered style={styles.contentStyle}>
      <Text 
        center 
        variant="creatorPageSectionTitle"
        style={{paddingVertical: Platform.OS === 'web' ? 25 : 10}}
      >
        {i18n.t('creatorPage.aboutAuthor')}
      </Text>
        {Boolean(videoURL) === true && (
          <VideoPlayer
            style={styles.videoPlayerContainer}
            src={videoURL} // Can be a URL or a local file.
          />
        )}
        {/* <ParsedText
          parse={[
            {type: 'url', style: styles.url, onPress: handleUrlPress},
            // {type: 'phone',                     style: styles.phone, onPress: handlePhonePress},
            // {type: 'email',                     style: styles.email, onPress: handleEmailPress},
          ]}
          style={{fontSize: 20, marginTop: 25}}>
          {trim(description)}
        </ParsedText> */}
        <RenderHtml
          containerStyle={{
            maxWidth: 750,
            overflow: 'hidden',
            marginTop: 10
          }}
          textStyle={StyleSheet.flatten(textStyles.creatorPageMainText)}
          source={decodeHTMLEntities(description)}
        />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,    
    marginTop: 10,
    // move to general styles, used also with posts
  },
  description: {
    fontSize: 20,
    paddingVertical: 10,
  },
  contentStyle: {
    flex: 1,
    padding: 25,
  },
  videoPlayerContainer: {
    width: '100%',
    ...Platform.select({
      web: {
        maxHeight: 400, 
        aspectRatio: 1.5/1,
      },
      default: {
        width: DEVICE_WIDTH - 75,
        aspectRatio: 1.5/1,
      }
    })
  }
});
