import React from 'react';
import {View as RNView, StyleSheet, ViewProps, ViewStyle, Platform} from 'react-native';
import {colors} from '@sponsor/assets';
import { isMobile } from 'utils';

interface IProps {
  variant?: keyof typeof viewStyles;
  children?: any;
  // TODO: add correct typing for next prop - style
  style?: ViewStyle;
  bordered?: boolean;
  green?: boolean
}

export const View: React.FC<IProps & ViewProps> = ({
  variant = 'empty',
  style,
  bordered,
  green,
  children,
  ...otherProps
}) => {
  return (
    <RNView
      style={[
        // {borderWidth:1,},
        // {
        //   borderWidth: 1, 
        // },
        variant && viewStyles[variant],
        bordered && viewStyles.bordered,
        green && viewStyles.green,
        style,
      ]}
      {...otherProps}>
      {children}
    </RNView>
  );
};

export const viewStyles = StyleSheet.create({
  empty: {},
  sectionContainer: {
    marginVertical: isMobile ? 12 : 15, //todo: refactor
  },
  screenContainer: {
    padding: 15,
    flex: 1,
    backgroundColor: 'white',
  },
  checkboxContainer: {
    paddingVertical: 8
  },
  bordered: {
    borderColor: colors.DUSTY_GRAY,
    borderWidth: 1,
    borderRadius: 8,
    padding: 8
  },
  verticalListSeparator: {
    marginVertical: 5,
  },

  tableCell: {
    padding: 10,
    flex:1,
    borderWidth: 1
  },

  tableHeader: {
    backgroundColor: colors.CONCRETE,
    borderRadius: 5,
    paddingVertical: 12,
    paddingHorizontal: 5
  },
  tableRow: {
    padding: 10,
    paddingVertical: 15,
    // borderBottomWidth: 1,
    borderBottomColor: colors.CONCRETE,
  },
  //todo: dangerous styles
  sectionBlocked: {
    backgroundColor: "rgba(232,232,232, 0.8)",
    ...StyleSheet.absoluteFillObject
  },
  green: {
    borderColor: colors.GREEN_OFFICIAL
  }
});
