import {TTier, TTierInResponse} from 'types';

const transformTierTranslation = (
  translations: TTierInResponse['translations'],
) => {
  // const transformTranslation:{
  //     [key in string]: ITierTranslation
  // } = {}
  // forEach(translations, ts => {
  //     transformTranslation[ts?.id] = ts
  // })

  return translations;
};

export const transformTier = (tier: TTierInResponse): TTier => {
  return {
    ...tier,
    translations: transformTierTranslation(tier?.translations),
  };
};
