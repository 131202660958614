import React from 'react';
import {StyleSheet} from 'react-native';
import { View } from '../View';

const styles = StyleSheet.create({
  col: {
    flexDirection: 'column',
  },
  alignCenter: {
    alignItems: 'center',
  },
  justifyCenter: {
    justifyContent: 'center',
  },
  fullFlex: {
    flex: 1,
  },
});

interface IProps {
  rowCenter?: boolean;
  columnCenter?: boolean;
  fullWidth?: boolean;
  containerStyle?: any;
  children?: any;
  flex?: number;
  width?: number,
  variant?: any
}

export const Column: React.FC<IProps> = ({
  containerStyle = {},
  rowCenter,
  columnCenter,
  fullWidth,
  children,
  flex,
  width,
  ...otherProps
}) => {
  return (
    <View
      {...otherProps}
      style={[
        styles.col,
        width && {width},
        containerStyle,
        columnCenter && styles.justifyCenter,
        rowCenter && styles.alignCenter,
        fullWidth && styles.fullFlex,
        flex && {
          flex,
        },
      ]}>
      {children}
    </View>
  );
};
