import React, {useCallback} from 'react';
import {Button, Column, Row, Text, View} from 'components';
import {useState, useEffect} from 'react';
import {ActivityIndicator, AppState, Platform} from 'react-native';

import {useSelector} from 'react-redux';
import {isEmpty} from 'lodash';
import {ContentContainer} from 'components';
import {i18n} from 'localize';
import {selectUser, selectSubscriptionByTierId, TStore, getProfile} from 'store';
import {
  SubscriptionInfo,
  SubscriptionPayForm,
  SubscriptionCreator,
} from '../../../subscription';
import {UnathorizedSubscriptionPayForm} from '../../../unathorized-subscription';

import {useDispatchAction, useNavigation, useParams} from 'hooks';
import {ApiService} from 'services';
import {ESubscriptionStatus, TTier, TTierStatus} from 'types';
import {LOG} from 'utils';
import {EscreenNames} from 'navigation';
import {SubscriptionCryptoPaymentScreen} from '../../../subscription/SubscriptionCryptoPaymentScreen';
import { ScrollView } from 'react-native-gesture-handler';
import { PaymentConditions } from './PaymentConditions';

// https://www.liqpay.ua/documentation/en/api/aquiring/widget/doc
/**
 * TODO: think about creating another screens
 * for this purposes
 * - to buy a subscription
 *  - buy with and without authorization
 * - to cancel a subsctiption
 */

export const PaymentPage = () => {
  const [paymentMethod, setPaymentMethod] = useState<'bank' | 'crypto'>('bank');

  const {tierId, creator, tier: redirectedTier} = useParams();
  const user = useSelector(selectUser);
  // const [paymentPeriod, setPaymentPeriod] = useState(EPaymentPeriod.oneMonth);
  const [tier, setTier] = useState<TTier | null>(redirectedTier || null);
  const [isLoading, setIsLoading] = useState(false);

  const {navigate} = useNavigation();

  const userSubscription = useSelector((state: TStore) =>
    selectSubscriptionByTierId(state, tierId || ''),
  );

  const {
    submitAction: dispatchGetProfile
  } = useDispatchAction(getProfile)

  //todo: investigate how to do redirection
  useEffect(() => {
    if (
      !isEmpty(userSubscription) && 
      tierId && 
      userSubscription?.status === ESubscriptionStatus.subscribed
    ) {
      navigate(EscreenNames.CancelSubscription, {
        tierId,
      });
    }
  }, [tierId, userSubscription, navigate]);

  // todo: handle case when response is succesfull and tier is not found (deleted by creator)
  const fetchTier = async () => {
    if (!tierId) {
      return;
    }
    try {
      setIsLoading(true);
      const {data} = await ApiService.getTier({tierId});
      setTier(data);
    } catch (err) {
      LOG('getTier error', err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!userSubscription?.id && userSubscription?.status !== ESubscriptionStatus.subscribed) {
      fetchTier();
    }
  }, [tierId, userSubscription]);

  // todo: refactor, improve flow,ux
  useEffect(() => {
    AppState.addEventListener('change', (state) => {
        if(state === 'active'){
          if (!userSubscription) {
            fetchTier();
          }
          dispatchGetProfile()
        }
    })
  }, [dispatchGetProfile])

  const renderPaymentMethodChoose = useCallback(() => {
    return (
      <Column containerStyle={{marginVertical: Platform.OS === 'web' ? 50 : 10}}>
        <Text variant="secondaryTitle">
          {i18n.t('paymentScreen.chooseYourPaymentMethod')}
        </Text>
        <View>
          <Button
            small
            title={i18n.t('paymentScreen.bankPaymentMethod')}
            onPress={() => setPaymentMethod('bank')}
            primary={paymentMethod == 'bank'}
          />
          {/* todo: add unathorized crypto payments */}
          {user?.id && (
          <View style={{marginTop: 12}}>
            <Button
              title={i18n.t('paymentScreen.cryptoPaymentMethod')}
              onPress={() => setPaymentMethod('crypto')}
              primary={paymentMethod == 'crypto'}
              testID="pay-with-crypto-button"
              disabled
            />
            <View variant='sectionBlocked' style={{justifyContent: 'center'}}>
              <Text color="black" center>{`${i18n.t('soon')}\n crypto`}</Text>
            </View>
          </View>)}
        </View>
      </Column>
    );
  }, [paymentMethod]);

  const renderBankPaymentFlow = useCallback(() => {
    if (!tier) {
      return;
    }
    return (
      <>
        {user?.id && (
          <View>
            <SubscriptionPayForm
              testID="payment-page-pay-form"
              minPrice={tier?.price || 1}
              initialPrice={tier?.price}
              currency={tier?.currency}
              tierId={tier?.id}
              description={tier?.description || i18n.t('')}
              creatorId={tier?.creator || creator?.id}
              creatorTitle={tier?.creatorTitle || creator?.title}
              creatorAvatarUri={tier?.creatorAvatarUri || creator?.avatarUri}
              creatorPageURL={tier?.creatorPageURL || creator?.pageURL}
            />
          </View>
        )}

        {/* user didn't login */}
        {/* todo: redirect to another screen - UnathorizedPaymentPage */}
        {!user?.id && <UnathorizedSubscriptionPayForm tier={tier} />}
      </>
    );
  }, [tier, creator, user]);

  const renderCryptoPaymentFlow = useCallback(() => {
    if(!tier)return null;
    return (
      <SubscriptionCryptoPaymentScreen
        currencyName={tier?.currency?.name}
        tierId={tier?.id}
      />
    );
  }, [tier]);

  if (isLoading) {
    return <ActivityIndicator />;
  }

  if (!tier) {
    return (
      <View>
        <Text
          style={{padding: 20, textAlign: 'center'}}
          variant="sectionTitle"
          testID="subscription-not-found-text"
        >
          {i18n.t('paymentScreen.subscriptionNotFound')}
        </Text>
      </View>
    );
  }

  return (
    <ScrollView>
      <ContentContainer>
          <View variant='screenContainer'>
            <Text testID="payment-page-title" variant="formTitle">
              {`${i18n.t('paymentScreen.paymentTitle')} ${tier.creatorTitle}`}
            </Text>

            <SubscriptionCreator 
              creatorTitle={tier?.creatorTitle}
              creatorAvatarUri={tier?.creatorAvatarUri}
              creatorPageURL={tier?.creatorPageURL}
            />
            <SubscriptionInfo tier={tier} />
            <PaymentConditions />
            {/* {renderPaymentMethodChoose()} */}
            <View style={{marginTop: 12, borderRadius: 8, width: 150}}>
              <Button
                title={i18n.t('paymentScreen.cryptoPaymentMethod')}
                onPress={() => setPaymentMethod('crypto')}
                primary={paymentMethod == 'crypto'}
                testID="pay-with-crypto-button"
                disabled
              />
                <View variant='sectionBlocked' style={{justifyContent: 'center', borderRadius: 8}}>
                  <Text color="black" center>{`${i18n.t('soon')}\n crypto`}</Text>
                </View>
            </View>

            {paymentMethod == 'bank' && renderBankPaymentFlow()}
            {paymentMethod == 'crypto' && renderCryptoPaymentFlow()}
          </View>
      </ContentContainer>
    </ScrollView>
  );
};
