import React from 'react';
// import {MaterialCommunityIcons} from '@expo/vector-icons'
import { IconProps } from '@expo/vector-icons/build/createIconSet';
import createIconSetFromIcoMoon from '@expo/vector-icons/createIconSetFromIcoMoon';
import icoMoonConfig from './selection.json';

export const RNIcon = createIconSetFromIcoMoon(
  icoMoonConfig,
  'icomoon',
  'icomoon.ttf',
);

export const Icon:React.FC<IconProps<any>& {type: string}> = ({
  color='black',
  type,
  ...otherProps
}) => {
  // if(type == 'material'){
  //   return <MaterialCommunityIcons 
  //       color={color}
  //       {...otherProps}
  //   />
  // }
  return (
    <RNIcon 
      color={color}
      {...otherProps}
    />
  )
}