import {filter} from 'lodash';
import type {TTier} from 'types';

//todo: rename functions
export const filterCollaborations = (tiers: TTier[]) => {
  return filter(tiers, t => !!t?.coCreators && t?.coCreators.length > 0);
};

export const filterTiers = (tiers: TTier[]) => {
  return filter(tiers, t => !t?.coCreators || t?.coCreators?.length === 0);
};
