import React, {ReactNode} from 'react';

// import {selectIsBiometryEnabled} from 'store';
// import {i18n} from 'localize';

interface IProps {
  children: ReactNode;
}

// const biometryConfig = {
//   promptMessage: i18n.t('appLock.touchIDconfig.promptMessage'),
//   fallbackLabel: i18n.t('appLock.touchIDconfig.fallbackLabel'),
// };

// const ICON_SIZE = 50;

export const BiometryCheck: React.FC<IProps> = React.memo(({children}) => {
  // const isBiometryEnabled = useSelector(selectIsBiometryEnabled);

  // const [isBiometryCheckPassed, setIsBiometryCheckPassed] = useState(false);
  // const [isAndroidModalVisible, setAndroidModalVisible] = useState(false);

  return <>{children}</>;
});
