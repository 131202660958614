import React from 'react';
import {StyleSheet} from 'react-native';
import moment from 'moment';
import {TouchableOpacity} from 'react-native';
import {TComment} from 'types';
import {Avatar} from '../Avatar/Avatar';
import {Column} from '../Column';
import {Row} from '../Row';
import {Text} from '../Text';
import {colors} from '../../../assets';
import {Icon} from '../Icon/Icon';
import { isMobile } from 'utils';
import { DEVICE_WIDTH } from '@sponsor/constants';

type TProps = {
  text: string;
  onDelete: () => void;
};

export const CommentLongCard: React.FC<TProps & Partial<TComment>> = ({
  createdAt,
  text,
  user,
  onDelete,
}) => {
  return (
    <Row containerStyle={styles.container}>
      <Column flex={1}>
        <Row columnCenter>
          <Column>
            <Avatar uri={user?.avatarUri} />
          </Column>
          <Column containerStyle={{flex:1, paddingHorizontal: 15, paddingVertical:5}}>
            <Text variant="thirdTitle">{user?.name}</Text>
            <Text style={styles.commentText}>{text}</Text>
            <Text>{moment(createdAt).format('lll')}</Text>
          </Column>
        </Row>
      </Column>

      {onDelete && (
        /**
         * to be confident that this button is still visible
         * even when text is too large
         * and not to use flexes only for this block
         */
        <Column containerStyle={{position: 'absolute', right: 10, top: 10}}>
          <TouchableOpacity onPress={onDelete}>
            <Icon color={colors.ORANGE} name="cancel" />
          </TouchableOpacity>
        </Column>
      )}
    </Row>
  );
};

const styles = StyleSheet.create({
  container: {
    borderBottomWidth: 0.5,
    borderColor: colors.DUSTY_GRAY,
    padding: 10,
    justifyContent: 'space-between',
    maxWidth: isMobile ?  DEVICE_WIDTH : undefined
  },
  commentText: {
    marginBottom: 15,
    flexShrink: 1
  },
});
