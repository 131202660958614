import React, {useCallback, useState} from 'react';
import {Button, CreatorCard, FormInput, Icon, KeyboardAvoidingView, Row, ScrollViewOnlyOnMobile, Text, View} from 'components';
import {i18n} from 'localize';
import {useSelector} from 'react-redux';
import {
  searchCreators,
  selectCreatorBasicInfo,
  selectFoundCreators,
  updateCreatorStart,
} from 'store';
import {TCreator, TCreatorFriend} from 'types';
import {filter, map, union, uniqBy} from 'lodash';
import {ActivityIndicator, FlatList, StyleSheet} from 'react-native';
import {ActionResult} from '../../../components/src/ActionResult/ActionResult.component';
import { useDispatchAction } from 'hooks';
import { ActionResultDefaultRenders } from 'components/src/ActionResult/ActionResultDefaultRenders';
import { TouchableOpacity } from 'react-native-gesture-handler';

export const EditCreatorFriends = () => {

  const foundCreators = useSelector(selectFoundCreators);
  const creator = useSelector(selectCreatorBasicInfo);

  const [searchQuery, setSearchQuery] = useState('');
  const [friends, setFriends] = useState<TCreatorFriend[]>(
    creator?.friends || [],
  );

  const {
    isLoading,
    error,
    submitAction: dispatchSearchCreators
  } = useDispatchAction(searchCreators)

  const {
    isLoading: updateIsInProgress,
    error: updateError,
    submitAction: updateFriends
  } = useDispatchAction(updateCreatorStart)

  return (
        <KeyboardAvoidingView>
          <View variant="screenContainer">
          <ScrollViewOnlyOnMobile>
            
            <Text variant="secondaryTitle">
              {i18n.t('editCreatorFriends.friendsTitle')}
            </Text>

            <View>
              {friends.map(item => (
                  <>
                    <CreatorCard
                      onPress={() =>
                        setFriends(
                          uniqBy(
                            filter(friends, c => c?.id !== item?.id),
                            'id',
                          ),
                        )
                      }
                      creatorPageURL={item?.pageURL}
                      creatorTitle={item?.title}
                      creatorAvatarUri={item?.avatarUri}
                    />
                    <View variant='verticalListSeparator'/>
                  </>
                ))
              }
            </View>

            <Button
              primary
              containerStyle={{marginTop: 15}}
              title={i18n.t('submitEdit')}
              disabled={isLoading}
              onPress={() => updateFriends(
                {
                  friends: map(friends, f => f?.id),
                },
              )}
            />
            <ActionResult 
              error={updateError}
              isLoading={updateIsInProgress}
              {...ActionResultDefaultRenders}
            />

            <View variant="sectionContainer">
                <FormInput
                  value={searchQuery}
                  placeholder={i18n.t('editCreatorFriends.friendInputPlaceholder')}
                  onChangeText={setSearchQuery}
                  onSubmitEditing={() => dispatchSearchCreators({
                    query: searchQuery,
                  })}   
                  RightComponent={(
                    <TouchableOpacity
                      disabled={isLoading}
                      style={{marginHorizontal: 8, marginTop: 10, justifyContent: 'center', alignItems: 'center'}}
                      onPress={() => dispatchSearchCreators({
                        query: searchQuery,
                      })}                      
                    >
                      <Icon name="search" size={25}/>
                    </TouchableOpacity>
                  )}
            >
              <ActionResult
                isLoading={isLoading}
                error={error}
                {...ActionResultDefaultRenders}
              />
            </FormInput>

              <View variant='sectionContainer'>
                {!isLoading &&  !error && foundCreators?.length < 1 && (
                    <Text center variant="thirdTitle">
                      {i18n.t('tierEdit.creatorsNotFound')}
                    </Text>
                )}
                {foundCreators.map(item => (
                    <View>
                      <CreatorCard
                        onPress={() =>
                          setFriends(
                            uniqBy(
                              union(friends, [
                                {
                                  pageURL: item.pageURL,
                                  avatarUri: item.avatarUri,
                                  title: item?.title,
                                  id: item?.id,
                                },
                              ]),
                              'id',
                            ),
                          )
                        }
                        creatorPageURL={item?.pageURL}
                        creatorTitle={item?.title}
                        creatorAvatarUri={item?.avatarUri}
                      />
                      <View variant='verticalListSeparator'/>
                    </View>
                ))}
              </View>
            </View>
            </ScrollViewOnlyOnMobile>
          </View>
        </KeyboardAvoidingView>
  );
};

const styles = StyleSheet.create({
  creatorsListContainer: {},
});
