import {useSelector} from 'react-redux';
import {selectUser} from 'store';
import {getUserLocales} from 'utils';
import {availableLocales} from 'localize';
import * as RNLocalize from "react-native-localize";

// todo: why "active"
// `cause user could see page without authorization ???

export const useActiveLanguage = () => {
  const userLang = useSelector(selectUser)?.lang;
  const userLocales = getUserLocales({languageCodeOnly: true}) || [];
  const rnLocales = RNLocalize.getLocales() || [];
  // console.log('rnLocales',rnLocales);

  const allUserLocales = [ ...rnLocales.map(l => l.languageCode), ...userLocales,]
  let activeLocale = 'uk';
  if (userLang) {
    activeLocale = userLang;
  } else if (allUserLocales) {
    activeLocale = availableLocales.includes(allUserLocales?.[0])
      ? allUserLocales?.[0]
      : 'uk';
  }
  return activeLocale;
};
