import React from 'react';
import {View, Image, ImageSourcePropType, StyleSheet} from 'react-native';

type IProps = {
  backgroundImage?: ImageSourcePropType;
};

export const CreatorBackgroundImage: React.FC<IProps> = ({backgroundImage}) => {
  return (
    <View style={styles.container}>
      {backgroundImage && (
        <Image
          resizeMode="cover"
          style={styles.image}
          source={backgroundImage}
        />
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    height: 350,
    borderBottomWidth: 1,
  },
  image: {
    height: '100%',
    width: '100%',
  },
});
