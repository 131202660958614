import React, { useState } from 'react'
import { CreatorCard, LanguagePicker, Row, Text, View } from 'components'
import { useActiveLanguage, useFetchCreatorInfo, useParams } from 'hooks';
import { filter, keys, map } from 'lodash';
import { i18n } from 'localize';
import { TierWithTranslation } from '../../../../tiersTranslations/TierWithTranslation.container';
import { colors } from '@sponsor/assets';
import { ActionResult } from '../../../../components/src/ActionResult/ActionResult.component';
import { ActivityIndicator, Platform, ScrollView, StyleSheet } from 'react-native';
import { BackHeader } from '../../../../containers/Header/BackHeader';
import { useMedia } from '../../../../hooks/useMedia';
import { TranslationsIconContainer } from '../../../../translations/TranslationsIcon.container';

export const CreatorPublicTiersScreen = () => {

    const params = useParams();
    const pageURL = params?.pageURL;
    const tiersIds = params?.tiersIds?.split?.(',') || []

    const media = useMedia()

    const {
        error, 
        isLoading, 
        creatorInfo, 
        tiers, 
    } = useFetchCreatorInfo({pageURL});

    const buttonBackgroundColor =
    creatorInfo?.customization?.brandColor || colors.GREEN_OFFICIAL;
    const pickerBorderColor = creatorInfo?.customization?.brandColor

    const activeLanguage = useActiveLanguage();
    const [shownLang, setShownLang] = useState(activeLanguage);

    const translationsLanguagesCodes = filter([
        ...keys(creatorInfo?.translations),
        creatorInfo?.lang || "",
    ], ln => !!ln);

    const borderColor = creatorInfo?.customization?.brandColor

    let filteredTiers = tiers || [];
    if(tiersIds?.length && Array?.isArray(tiersIds)){
        filteredTiers = filteredTiers.filter(t => tiersIds.includes(String(t?.id)))
    }

    return (
        <View variant="screenContainer" style={styles.container}>    
            {Platform.OS === 'web' && <BackHeader />}
            <ScrollView>
                <ActionResult 
                    isLoading={isLoading || false}
                    renderLoading={() => (
                        <View variant='sectionContainer'>
                            <ActivityIndicator />
                        </View>
                    )}
                    error={error}
                    renderError={(error) => (
                        <Text variant='inputError'>
                            {error}
                        </Text>
                    )}
                />
                {!isLoading && creatorInfo?.pageURL && <View variant='sectionContainer' style={{alignItems: 'center'}}>
                    <CreatorCard 
                        creatorAvatarUri={creatorInfo?.avatarUri}
                        creatorTitle={creatorInfo?.title}
                        creatorSubtitle={creatorInfo?.subtitle}
                        creatorPageURL={creatorInfo?.pageURL}
                        containerStyle={{
                            borderColor
                        }}
                    />
                </View>}

                {/* {translationsLanguagesCodes?.length > 1 && (
                    <View style={{alignItems:'center'}} variant="sectionContainer">
                        <LanguagePicker
                            onLanguageChange={lang => setShownLang(String(lang))}
                            value={shownLang}
                            langs={translationsLanguagesCodes}
                            style={{borderColor: pickerBorderColor}}
                        />
                    </View>
                )} */}
                {!isLoading && (
                <>
                <Text
                    center
                    variant='secondaryTitle'
                >
                {i18n.t('creatorPage.chooseSubscription')}
                </Text>
                <Row
                    flexWrap
                    rowCenter
                    // todo: check other cases, move to the row?
                    containsSimilarItems={!media?.sm}
                >
                    {
                        map(filteredTiers, (item) => (
                            <View>
                                <TierWithTranslation
                                    {...item}
                                    lang={shownLang}
                                    containerStyle={{
                                        maxWidth: '95%'
                                    }}
                                    btnColor={buttonBackgroundColor}
                                />
                                <View variant='verticalListSeparator'/>
                            </View>
                        ))
                    }
                </Row>
                </>)} 
            </ScrollView>
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        //todo: make general, check other cases ???
        paddingBottom: Platform.OS === 'web' ? 75 : 25
    }
})
