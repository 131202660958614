import React from 'react';
import {Image, ScrollViewOnlyOnMobile, View} from 'components';
import {map} from 'lodash';
import {Config} from 'libs';

const images = [
  {
    uri: `${Config.STATIC_IMAGES_URL}/publichnyi_dohovir_oferta-1.png`,
  },
  {
    uri: `${Config.STATIC_IMAGES_URL}/publichnyi_dohovir_oferta-2.png`,
  },
  {
    uri: `${Config.STATIC_IMAGES_URL}/publichnyi_dohovir_oferta-3.png`,
  },
  {
    uri: `${Config.STATIC_IMAGES_URL}/publichnyi_dohovir_oferta-4.png`,
  },
  {
    uri: `${Config.STATIC_IMAGES_URL}/publichnyi_dohovir_oferta-5.png`,
  },
  {
    uri: `${Config.STATIC_IMAGES_URL}/publichnyi_dohovir_oferta-6.png`,
  },
];

export const PublicOffer = () => {
  return (
    <ScrollViewOnlyOnMobile>
      <View variant="screenContainer">
        {map(images, image => (
          <Image
            style={{width: '100%', height: 1000}}
            resizeMode="contain"
            source={{uri: image?.uri}}
          />
        ))}
      </View>
    </ScrollViewOnlyOnMobile>
  );
};
