import { useMemo } from "react";
import { useSelector } from "react-redux"
import { selectCreatorBasicInfo } from "store"
import { ALL_TRANSLATIONS_LANGUAGES } from "./translations.contants";

export const useTranslationsLanguages = ():string[] => {

    const creator = useSelector(selectCreatorBasicInfo)
    const availableTranslations  = useMemo(() => {
        const mainLang = creator?.lang;
        return [...ALL_TRANSLATIONS_LANGUAGES].filter(l => l?.code !== mainLang).map(l => l.code);
    }, [creator?.lang]);

    return availableTranslations;
}