import {TProfile} from './user';
import {TTier} from './tier';
import { ICurrency } from '../../payment/payments.types';

export enum ESubscriptionStatus {
  'subscribed' = 'subscribed',
  'unsubscribed' = 'unsubscribed',
}

export type TSubscription = {
  id: string;
  tier: TTier;
  status: ESubscriptionStatus;
  unsubscribedAt?: string;
  totalSpending: number;
  price: number;
  lastPaymentDate: string;
  // todo: add public profile
  user: TProfile;
  currency: ICurrency;

  createdAt: string;
};
