import React from 'react';
import {PaymentCard, Text} from 'components';
import {useSelector} from 'react-redux';
import {selectWithdrawMethodsById} from './monet-withdraw-methods.selectors';
import {selectCreatorBasicInfo, TStore} from 'store';
import {deleteWithdrawMethods} from './money-withdraw-methods.actions';
import {ECreatorWithdrawMethodsTypes} from './money-withdraw-methods.types';
import { useDispatchAction } from 'hooks';

type TProps = {
  id: string;
  showDeleteBtn?: boolean;
  onChoose?: () => void;
  isChoosen?: boolean;
};

// const hideWordsInString = (str: string) => {};

export const WithdrawMethodContainer: React.FC<TProps> = ({
  id,
  onChoose,
  isChoosen,
  showDeleteBtn,
}) => {

  const creator = useSelector(selectCreatorBasicInfo);
  const withdrawMethod = useSelector((state: TStore) =>
    selectWithdrawMethodsById(state, id),
  );

  const {
    isLoading,
    error,
    submitAction: onDelete
  } = useDispatchAction(deleteWithdrawMethods, {
    data: { id }
  })

  // const onChoose = useCallback(() => {
  //     dispatch(updateCreatorStart({
  //         activePaymentMethodInfo: {
  //             type: withdrawMethod.type,
  //             id
  //         }
  //     }, {
  //         onFail: () => {}
  //     }))
  // },[id, dispatch])

  let WithdrawMethodComponentProps = {};
  switch (withdrawMethod?.type) {
    case ECreatorWithdrawMethodsTypes.merchant_entrepreneur:
      WithdrawMethodComponentProps = {
        title: withdrawMethod.companyName,
        subtitle: `${withdrawMethod?.currency?.name || ''} ${withdrawMethod?.financialConfig?.iban}`,
      };
      break;
    case ECreatorWithdrawMethodsTypes.crypto_wallet:
      WithdrawMethodComponentProps = {
        title: withdrawMethod.address,
        subtitle: `${withdrawMethod?.currency?.name || ''} ${withdrawMethod?.memo}`,
      };
      break;
    case ECreatorWithdrawMethodsTypes.card: {
      WithdrawMethodComponentProps = {
        title: withdrawMethod.cardMask,
        subtitle: `${withdrawMethod?.currency?.name || ''} ${
          withdrawMethod?.cardType
        }`,
      };
      break;
    }
  }

  return (
    <>
    <PaymentCard
      {...WithdrawMethodComponentProps}
      onDelete={showDeleteBtn ? onDelete : undefined}
      onChoose={onChoose}
      isLoading={isLoading}
      error={error}
      isMain={
        isChoosen
          ? isChoosen
          : creator.activePaymentMethodInfo?.type === withdrawMethod.type &&
            creator.activePaymentMethodInfo?.id === id
      }
    />
    {error && (
      <Text variant='inputError'>
          {error}
      </Text>
    )}
  </>
  );
};
