import i18n from 'i18n-js';
import {merge} from 'lodash';
import { advertiserSearchLocalization } from '../../advertiserSearch/advertiserSearch.localization';
import {creatorMerchantLocalization} from '../../creatorMerchant/creatorMerchant.localization';
import {WithdrawMethodsLocalization} from '../../money-withdraw-methods/money-withdraw-methods.localization';
import { postsTranslationsLocalization } from '../../posts-translations/posts-translations.localization';
import { pricingPlansLocalizations } from '../../pricingPlans/pricingPlans.localization';
import {cryptoWithdrawalScreenLocalization} from '../../screens/src/CryptoWithdraw/cryptoWithdrawalScreen.localization';
import { fiatWithdrawalScreenLocalization } from '../../screens/src/FiatWithdrawalScreen/fiatWithdrawalScreen.localization';
import {subscriptionLocalization} from '../../subscription/subscription.localize';
import {tiersTranslationsLocalization} from '../../tiersTranslations/tiers-translations.localization';
import { translationsLocalizations } from '../../translations/translations.localizations';

import en from './en';
import uk from './uk';

i18n.locale = 'en';
i18n.fallbacks = 'en';
i18n.defaultLocale = 'en';

i18n.translations = {
  en,
  uk,
};

/**
 * todo: update pattern to automatically merge location from new feature create via cli 
*/
i18n.translations = merge(i18n.translations, subscriptionLocalization);
i18n.translations = merge(i18n.translations, WithdrawMethodsLocalization);
i18n.translations = merge(i18n.translations, tiersTranslationsLocalization);
i18n.translations = merge(i18n.translations, creatorMerchantLocalization);
i18n.translations = merge(i18n.translations, translationsLocalizations);
i18n.translations = merge(
  i18n.translations,
  cryptoWithdrawalScreenLocalization,
);
i18n.translations = merge(
  i18n.translations,
  fiatWithdrawalScreenLocalization,
);
i18n.translations = merge(
  i18n.translations,
  pricingPlansLocalizations,
);
i18n.translations = merge(
  i18n.translations,
  postsTranslationsLocalization,
);
i18n.translations = merge(
  i18n.translations,
  advertiserSearchLocalization
)

const availableLocales = ['en', 'uk'];

export {i18n, availableLocales};
