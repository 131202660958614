import React from 'react'
import { CreatorCard, TCreatorCardProps } from 'components'
import { useNavigation } from 'hooks'
import { EscreenNames } from 'navigation';

export const SubscribedCreatorCard:React.FC<TCreatorCardProps> = ({
    creatorTitle,
    creatorAvatarUri,
    creatorPageURL,
    price,
    currency,
}) => {

    const {navigate} = useNavigation();
    
    return (
        <CreatorCard 
            creatorTitle={creatorTitle}
            creatorAvatarUri={creatorAvatarUri}
            creatorPageURL={creatorPageURL}
            price={price}
            currency={currency}
            onPress={() => navigate(EscreenNames.CreatorPosts, {pageURL: creatorPageURL})}
            onCreatorPress={(() => navigate(EscreenNames.CreatorPublicPage, {
                pageURL: creatorPageURL
            }, {
                urlParam: true,
            }))}
        />
    )
}

