import {createSelector} from '@reduxjs/toolkit';
import {find} from 'lodash';
import {getStore} from 'store';

//todo: think about naming - balance or revenue
export const selectCreatorRevenueById = createSelector(
  [getStore, (_: any, id: string) => id],
  (store, balanceId) =>
    find(store?.creator?.basicInfo?.balances, b => b?.id === balanceId),
);
