import {
    Row,
    Text,
    TierEditing,
    Button,
    View,
  } from 'components';
  import {filter, findIndex, isEmpty, map} from 'lodash';
  import {EscreenNames} from 'navigation';
  import React, {useCallback, useMemo, useState} from 'react';
  import {FlatList, Platform, StyleSheet, TouchableOpacity} from 'react-native';
  import {useDispatch, useSelector} from 'react-redux';
  import {useNavigation} from 'hooks';
  import {i18n} from 'localize';
  import {TTierStatus, TCollaborationAcceptanceStatus, TTier} from 'types';
  import {
    updateCollaborationStatus,
    selectCollaborationTiers,
    selectCreatorBasicInfo,
    selectExternalCollaborationsWaitingAcceptanceCount,
  } from 'store';
import { isMobile } from 'utils';
import { BackHeader } from '../../../../containers/Header/BackHeader';
import { Picker } from 'components/src/Picker/Picker';
import { editTiersStyles } from './EditTiers.styles';
  

export const EditCollaborationTiersTab = () => {

    const [shownTierType, setShownTierType] = useState(
        TTierStatus.waiting_acceptance,
      );
    
      // const tiers = useSelector(selectTiers);
      const creator = useSelector(selectCreatorBasicInfo);
      const collaborationTiers = useSelector(selectCollaborationTiers);

      const {navigate} = useNavigation();
      const dispatch = useDispatch();
    
      const collaborationsWaitingAcceptanceCount = useSelector(
        selectExternalCollaborationsWaitingAcceptanceCount,
      );
    
      const goToTierScreen = useCallback(() => {
        navigate(EscreenNames.TierEdit);
      }, [navigate]);
    
      const dispatchChangeCollaborationStatus = useCallback(
        params => {
          return () => dispatch(updateCollaborationStatus(params));
        },
        [dispatch],
      );
    
      //todo: move outside the component
      const checkIfUserCanAccept = useCallback(
        (tier: TTier) => {
          const canUserAccept =
            tier?.status == TTierStatus.waiting_acceptance &&
            findIndex(
              tier?.coCreators,
              c => c?.creatorPageURL == creator?.pageURL && !c?.acceptance_status,
            ) > -1;
          return canUserAccept;
        },
        [creator],
      );
    
      const filteredCollaborationTiers = useMemo(() => {
        return filter(collaborationTiers, t => t?.status === shownTierType);
      }, [collaborationTiers, shownTierType]);


  const renderTier = useCallback(({item: tier}) => {
    return (
      <View style={editTiersStyles.listContainer}>
        <TierEditing
          onEdit={() =>
            navigate(EscreenNames.TierEdit, {id: tier?.id})
          }
          {...tier}
        />
        {checkIfUserCanAccept(tier) && (
          <Row>
            <Button
              title={i18n.t('accept')}
              containerStyle={{flex: 1, paddingRight: 5}}
              /**
               * todo: create module/library for acceptance logic
               * with all status and process handled inside the box
               */
              onPress={dispatchChangeCollaborationStatus({
                id: tier?.id,
                status: TCollaborationAcceptanceStatus.accepted,
              })}
              primary
            />
            <Button
              title={i18n.t('decline')}
              containerStyle={{flex: 1, paddingLeft: 5}}
              onPress={dispatchChangeCollaborationStatus({
                id: tier?.id,
                status: TCollaborationAcceptanceStatus.declined,
              })}
              red
            />
          </Row>
        )}
    </View>
    )
  },[checkIfUserCanAccept, dispatchChangeCollaborationStatus])
  
  const renderFilters = () => (
    <Picker
      data={[
        {
          label: i18n.t('editTiers.publishedTypeLabel'),
          value: TTierStatus.published,
        },
        {
          label: i18n.t('editTiers.waitingAcceptanceTypeLabel'),
          value: TTierStatus.waiting_acceptance,
        },
        {
          label: i18n.t('editTiers.declinedAcceptanceTypeLabel'),
          value: TTierStatus.declined,
        },
        {
          label: i18n.t('editTiers.expiredTypeLabel'),
          value: TTierStatus.expired,
        },
        {
          label: i18n.t('editTiers.cancelledTypeLabel'),
          value: TTierStatus.cancelled,
        },
      ]}
      containerStyle={styles.filtersContainer}
      selectedValue={shownTierType}
      onValueChange={itemValue => setShownTierType(itemValue?.value)}
     />
  )

  return (
    <View variant="screenContainer">
    {/*
      todo: add another container for editing page with
      custom header and title
    */}
    {/* {collaborationsWaitingAcceptanceCount > 0 && (
      <Text center variant="thirdTitle">
        {i18n.t('editTiers.userHasWaitingResponseCollaborations')}
      </Text>
    )} */}
      {!isMobile && <BackHeader />}

      {(
        <>
          <Row rowCenter={Platform.OS === 'web'}>
            <Text variant="sectionTitle">
              {i18n.t('editTiers.yourCollaborationTiers')}
            </Text>
            <TouchableOpacity style={styles.addBtn} onPress={goToTierScreen}>
              <Text 
                  variant="sectionTitle"
              >
                +
              </Text>
            </TouchableOpacity>
          </Row>

          {isMobile ? <FlatList
            data={filteredCollaborationTiers}
            contentContainerStyle={styles.listContentContainer}
            ListHeaderComponent={renderFilters()}
            ItemSeparatorComponent={() => <View variant='verticalListSeparator'/>}
            renderItem={renderTier}
          />
          :  (
          <>
          <Row rowCenter>
            {renderFilters()}
          </Row>
          <Row
            flexWrap
            rowCenter
            containsSimilarItems
          >
            {map(filteredCollaborationTiers, (item) => renderTier({item}))}
          </Row> 
          </>)}
        </>
      )}
  </View>
  )
}

const styles = StyleSheet.create({
    addBtn: {
      marginLeft: 25,
    },
    listContentContainer : Platform.select({
      web: {flex:1, alignItems:'center'},
      default: {},
    }),
    filtersContainer: {
      marginVertical: 25,
      width: !isMobile ? "50%" : undefined,
      ...Platform.select({
        web: {},
        default: {
          marginHorizontal: 5,
        }
      })
    }
  });
  