import {createSlice, createSelector, PayloadAction} from '@reduxjs/toolkit';
import {takeEvery} from 'redux-saga/effects';
import {set} from 'lodash';
import {TranslationApiService} from '../../../creator-translation/api';

import {createCRUDActionCreators, fetchSaga} from '../common';
import {getStore} from '../common';
import {TCreatorTranslation, TMyGetCreatorOutput} from '../../../types';
import {ECreatorTranslationAnalyticsEventNames} from '../../../creator-translation/types';
import {AnalyticsService} from 'services';
import { getCreatorInfo, getCreatorInfoSuccess } from './creator';
import { AxiosResponse } from 'axios';

type TDeleteCreatorTranslationOutput = AxiosResponse<{
  id: string
}>

//todo: clean such cases, move action to standalone file
export const {
  uploadTranslation,
  uploadTranslationSuccess,
  updateTranslation,
  updateTranslationSuccess,
  deleteTranslation,
  deleteTranslationSuccess,
} = createCRUDActionCreators('Translation', 'creator/');


export type TTranslationsState = {
  [key in string]: TCreatorTranslation;
};

export const creatorTranslationSlice = createSlice({
  name: 'translation',
  initialState: {},
  extraReducers: {
    [getCreatorInfoSuccess?.toString()]: (
      state: TTranslationsState,
      action: PayloadAction<TMyGetCreatorOutput['data']>
    ) => {
      for (const tr of Object.entries(action?.payload?.translations || {})) {
        if(!tr?.[0])return;
        const lang = tr?.[0]
        set(state, lang, {
          ...(tr?.[1] || {}),
        });
      }

    },
    //todo: move to another slice - goals, greetings, translations
    [uploadTranslationSuccess.toString()]: (
      state: TTranslationsState,
      action,
    ) => {
      let lang = action?.payload?.lang;
      set(state, lang, {
        ...(state?.[lang] || {}),
        ...(action.payload || {}),
      });
    },
    [updateTranslationSuccess.toString()]: (
      state: TTranslationsState,
      action,
    ) => {
      let lang = action?.payload?.lang;
      console.log(action.payload);
      set(state, lang, {
        ...(state?.[lang] || {}),
        ...(action.payload || {}),
      });
    },
    [deleteTranslationSuccess.toString()]: (
      state: TTranslationsState,
      action: PayloadAction<TDeleteCreatorTranslationOutput['data']>,
    ) => {
      let id = action?.payload?.id;
      const lang = Object.values(state).find(t => t.id === id)?.lang || ""
      delete state?.[lang];
    },
  },
  reducers: {},
});

export const selectTranslationState = createSelector(
  getStore,
  store => store.creator.translations,
);

export default creatorTranslationSlice.reducer;

export function* creatorTranslationFlow() {
  yield takeEvery(
    uploadTranslation.toString(),
    fetchSaga(TranslationApiService.postUploadTranslation, {
      analytics: {
        logEvent: event => AnalyticsService.logEvent(event),
        onSuccessEvent:
          ECreatorTranslationAnalyticsEventNames.UPLOAD_CREATOR_TRANSLATION_SUCCEEDED,
        onFailedEvent:
          ECreatorTranslationAnalyticsEventNames.UPLOAD_CREATOR_TRANSLATION_FAILED,
      },
    }),
  );
  yield takeEvery(
    updateTranslation.toString(),
    fetchSaga(TranslationApiService.updateTranslation, {
      analytics: {
        logEvent: event => AnalyticsService.logEvent(event),
        onSuccessEvent:
          ECreatorTranslationAnalyticsEventNames.UPDATE_CREATOR_TRANSLATION_SUCCEEDED,
        onFailedEvent:
          ECreatorTranslationAnalyticsEventNames.UPDATE_CREATOR_TRANSLATION_FAILED,
      },
    }),
  );
  yield takeEvery(
    deleteTranslation.toString(),
    fetchSaga(TranslationApiService.deleteTranslation, {
      analytics: {
        logEvent: event => AnalyticsService.logEvent(event),
        onSuccessEvent:
          ECreatorTranslationAnalyticsEventNames.DELETE_CREATOR_TRANSLATION_SUCCEEDED,
        onFailedEvent:
          ECreatorTranslationAnalyticsEventNames.DELETE_CREATOR_TRANSLATION_FAILED,
      },
    }),
  );
}
