import {createSelector, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {takeEvery} from 'redux-saga/effects';
import {includes, omitBy, reduce, values} from 'lodash';
import {GreetingsApiService} from '../../../greetings/api';

import {createCRUDActionCreators, fetchSaga, getStore} from '../common';
import {TGreetings} from '../../../types';
import {
  EGreetingsAnalyticsEventNames,
  TPostUploadGreetingsOutput,
  TUpdateGreetingsOutput,
} from '../../../greetings/types';
import {AnalyticsService} from 'services';

export type TGreetingsState = {
  [key in string]: TGreetings;
};

export const {
  uploadGreetings,
  uploadGreetingsSuccess,
  updateGreetings,
  updateGreetingsSuccess,
  deleteGreetings,
  deleteGreetingsSuccess,
} = createCRUDActionCreators('Greetings', '');

export const GreetingsSlice = createSlice({
  name: 'Greetings',
  initialState: {},
  extraReducers: {
    [uploadGreetingsSuccess?.toString()]: (
      state: TGreetingsState,
      action: PayloadAction<TPostUploadGreetingsOutput['data']>,
    ) => {
      return reduce<any, TGreetingsState>(
        action.payload,
        (s, i) => {
          s[i?.id] = i;
          return s;
        },
        {},
      );
    },
    [updateGreetingsSuccess?.toString()]: (
      state: TGreetingsState,
      action: PayloadAction<TUpdateGreetingsOutput['data']>,
    ) => {
      return reduce<any, TGreetingsState>(
        action.payload,
        (s, i) => {
          s[i?.id] = i;
          return s;
        },
        {},
      );
    },
    [deleteGreetingsSuccess?.toString()]: (state: TGreetingsState, action) => {
      return omitBy(state, g => includes(action.payload, g?.id));
    },
  },
  reducers: {},
});

export const selectCreatorGreetingsState = createSelector(
  getStore,
  store => store.creator.greetings,
);

export const selectCreatorGreetingsArray = createSelector(
  selectCreatorGreetingsState,
  greetingsState => values(greetingsState),
);

export default GreetingsSlice.reducer;

export function* GreetingsFlow() {
  yield takeEvery(
    uploadGreetings.toString(),
    fetchSaga(GreetingsApiService.postUploadGreetings, {
      analytics: {
        logEvent: event => AnalyticsService.logEvent(event),
        onSuccessEvent:
          EGreetingsAnalyticsEventNames.DELETE_GREETINGS_SUCCEEDED,
        onFailedEvent: EGreetingsAnalyticsEventNames.DELETE_GREETINGS_FAILED,
      },
    }),
  );
  yield takeEvery(
    updateGreetings.toString(),
    fetchSaga(GreetingsApiService.updateGreetings, {
      analytics: {
        logEvent: event => AnalyticsService.logEvent(event),
        onSuccessEvent:
          EGreetingsAnalyticsEventNames.DELETE_GREETINGS_SUCCEEDED,
        onFailedEvent: EGreetingsAnalyticsEventNames.DELETE_GREETINGS_FAILED,
      },
    }),
  );
  yield takeEvery(
    deleteGreetings.toString(),
    fetchSaga(GreetingsApiService.deleteGreetings, {
      analytics: {
        logEvent: event => AnalyticsService.logEvent(event),
        onSuccessEvent:
          EGreetingsAnalyticsEventNames.DELETE_GREETINGS_SUCCEEDED,
        onFailedEvent: EGreetingsAnalyticsEventNames.DELETE_GREETINGS_FAILED,
      },
    }),
  );
}
