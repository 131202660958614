import {TCreatorWithdrawMethod} from '../../money-withdraw-methods/money-withdraw-methods.types';
import { EAlpha3Code } from '../../countries/countries.types';
import { ICurrency } from '../../payment/payments.types';
import { IPricingPlan } from '../../pricingPlans/pricingPlans.types';
import {TTier} from './tier';
import { TParentTranslationField } from '../../translations/translations.types';

export type TCreatorFriend = Pick<
  TCreator,
  'id' | 'pageURL' | 'title' | 'avatarUri'
>;

export type TCreatorTranslation = {
  id: string;
  title: string;
  description: string;
  subtitle: string;
  lang: string;
};

export enum ECreatorSettings {
  showMonthlyEarnings = 'showMonthlyEarnings',
  showGoals = 'showGoals',
  showGreetingsType = 'showGreetingsType',
  allowCryptoPayment = 'allowCryptoPayment',
  connectAdvertiserSearch = 'connectAdvertiserSearch'
}

export enum TCreatorGoalType {
  'subscribers' = 'subscribers',
  'earnings' = 'earnings',
}

export enum TCreatorGreetingType {
  'general' = 'general',
  'perTier' = 'perTier',
}

export type TCreatorGoal = {
  id: string;
  title?: string;
  count: number;
  type: TCreatorGoalType;
  persantage: number;
  currentCount?: number;
  description: string;
} & TParentTranslationField;

export type TCryptoWallet = {
  _id: string;
  address: string;
  memo: string;
};

export type TPaymentMethod = {
  type: 'card' | 'crypto_wallet';
} & TCardInfo &
  TCryptoWallet;

export type TCardInfo = {
  _id: string;
  cardNumber?: string;
  cardMask: string;
  cardToken: string;
  cardType: string;
  currencyDebit: string;
  lastName?: string;
  firstName?: string;
};

export interface TGreetings {
  id: string;
  content: any;
  triger: 'tier' | 'general';
  tier: string[];
  channel_name: string;
  specs: {
    v: any;
    k: string;
  }[];
}

export interface ICreatorBalance {
  id: string;
  currency: ICurrency;
  amount: number;
}

export interface TNotificationGreetings extends TGreetings {
  content: {
    title: string;
    subtitle: string;
    image: string;
  };
}

export interface TEmailGreetings extends TGreetings {
  content: {
    text: string;
  };
}

export enum ECreatorSocials {
  youtube = 'youtube',
  twitter = 'twitter',
  twitch = 'twitch',
  telegram = 'telegram',
  reddit = 'reddit',
  wiki = 'wiki',
  megogo = 'megogo',
}

export type TCreator = {
  id: string;
  avatarUri: string;
  lang: string;
  country?: {
    alpha3Code?: EAlpha3Code
  },
  videoURL?: string;
  verified?: boolean;
  title: string;
  subtitle?: string;
  description: string;
  nbImages?: number;
  nbVideos?: number;
  nbAudio?: number;
  nbSponsors?: number;
  currency?: string;
  isPublished?: boolean;
  backgroundImageUri: string;
  tiers: TTier[];

  mainCurrency: ICurrency,
  // todo: remove payment methods
  withdrawMethods: TCreatorWithdrawMethod[];
  activePaymentMethodInfo: {
    type: string;
    id: string;
  };
  pageURL: string;
  socials: {[key in ECreatorSocials]: string};
  goals: {
    [key in string]: TCreatorGoal;
  };

  customization: {
    brandColor?: string;
  };
  friends: TCreatorFriend[];
  nbExclusivePosts: {
    poll: number;
    text: number;
  };
  balance: {
    amount: number;
  };
  balances: ICreatorBalance[];

  nbSubscribers: number;
  //monthlyEarnings
  totalMonthlyEarningsUpdatedAt?: string,
  totalMonthlyEarnings?: ICreatorBalance;

  settings: {
    [key in ECreatorSettings]?: boolean | string;
  };
  lastSubscribersExportAt: string; // ISO  string

  greetings?: {
    [key in string]: TGreetings;
  };

  translations: {
    [key in string]: {
      title: string;
      description: string;
      subtitle: string;
    };
  };

  pricingPlan: IPricingPlan
  category?: string,
};

export type TCreatorStats = {
  creator: string;
  perionInMonth: number;
  startDate: string;
  paidAmount: number;
  subscribersCountPerTier: {
    [key in string]: number;
  };
  subscribersPerPeriodInMonthes: {
    [key in string]: string;
  };
};
