import React from 'react';
import {Platform, StyleSheet} from 'react-native';
import {Route, TabBar as RNTabBar, TabBarProps} from 'react-native-tab-view';
import {colors} from '@sponsor/assets';
import {Layout} from 'react-native-tab-view/lib/typescript/types';
import { DEVICE_WIDTH } from '@sponsor/constants';

type TProps = {
  renderIndicator?: () => void;
  activeColor?: string;
  labelStyle?: any;
  layout: Layout;
};

//todo: remove any
export const TabBar: React.FC<TProps & Partial<TabBarProps<Route>> & any> = ({
  activeColor = colors.GREEN_OFFICIAL,
  renderIndicator,
  layout,
  ...props
}) => {
  return (
    <RNTabBar
      {...props}
      activeColor={activeColor}
      layout={layout}
      style={{
        shadowOffset: {height: 0, width: 0},
        shadowColor: 'transparent',
        shadowOpacity: 0,
        elevation: 0,
        backgroundColor: 'white'
      }}
      indicatorStyle={{
        width: layout.width / 4, 
        left: layout.width / 8,
        backgroundColor: colors.GREEN_OFFICIAL,
        // to large is look weird on web
      }}
      renderIndicator={renderIndicator}
      labelStyle={[styles.labelStyle]}
    />
  );
};

const styles = StyleSheet.create({
  labelStyle: {
    color: 'black',
    textAlign:'center',
    fontWeight: 'bold',
    fontFamily: 'GolosTextBold',
  },
});
