import * as Yup from 'yup';
import {i18n} from 'localize';

export const getProfileSettingsValidationSchema = () =>
  Yup.object().shape({
    name: Yup.string()
      .min(2, i18n.t('validationSchema.tooShort'))
      .max(40, i18n.t('validationSchema.tooLong'))
      .required(i18n.t('validationSchema.requiredField')),
    last_name: Yup.string()
      .min(2, i18n.t('validationSchema.tooShort'))
      .max(40, i18n.t('validationSchema.tooLong'))
      .required(i18n.t('validationSchema.requiredField')),
  });
