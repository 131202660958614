import React from 'react';
import {StyleSheet, TouchableOpacity} from 'react-native';
import {Text} from '../Text';
import {Column} from '../Column';
import {TTier} from 'types';
import {i18n} from 'localize';
import {CreatorCardBase} from './CreatorCardBase';
import { View } from '../View';

export type TCreatorCardProps =  Partial<TTier> & Pick<TTier, 'creatorPageURL'> & {
  subscriptionPrice?: number;
  subscriptionCurrency?: string;
  nbSponsors?: number;
  onPress?: () => void;
  onCreatorPress?: () => void,
  containerStyle?: any;
  creatorSubtitle?: string
};

// TODO: this component is not by solid, create separates components
export const CreatorCard: React.FC<TCreatorCardProps> = ({
  creatorTitle,
  creatorPageURL,
  price,
  currency,
  nbSponsors,
  onPress,
  onCreatorPress,
  containerStyle,
  creatorSubtitle,
  ...otherProps
}) => {

  return (
    <CreatorCardBase
      {...otherProps}
      creatorPageURL={creatorPageURL}
      contentContainerStyle={{
        width: 290,
      }}
      containerStyle={containerStyle}
      onPress={onPress}
      rightElement={
        <>
          <Column
            flex={3}
            containerStyle={[
              styles.marginLeft10,
              {justifyContent: 'flex-start'},
            ]}>
            {/* <TouchableOpacity> */}
              <TouchableOpacity onPress={onCreatorPress}>
                <Text selectable numberOfLines={1} variant="creatorTitle">
                  {creatorTitle}
                </Text>
              </TouchableOpacity>
              {creatorSubtitle && (
                <View
                  style={{paddingVertical: 8}}
                >
                  <Text numberOfLines={3} variant="inputLabel">
                    {`${creatorSubtitle}`}
                  </Text>
                </View>
              )}
            {/* </TouchableOpacity> */}
            <Text
              // selectable
              numberOfLines={1}
              // variant=""
            >
              {'/' + creatorPageURL}
            </Text>
            {/* {Boolean(description) === true && (
              <Text numberOfLines={2} variant="creatorDesc">
                {description.slice(0, 255)}
              </Text>
        )} */}
          {price && currency?.name && (
            <Text numberOfLines={2}>{`${price} ${currency?.name} ${i18n.t("creatorCard.supportIntervalTitle")}`}</Text>
          )}
          </Column>
          {nbSponsors && (
            <Column>
              <Text bold style={styles.sponsorsTitle}>{nbSponsors}</Text>
              <Text>{i18n.t('creatorCard.nbSponsors')}</Text>
            </Column>
          )}
        </>
      }
    />
  );
};

const styles = StyleSheet.create({
  marginLeft10: {
    marginLeft: 10,
  },
  sponsorsTitle: {
    textAlign: 'center',
    fontSize: 22,
  },
});
