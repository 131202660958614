import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import cs

type TButton = {
    text: string,
    onPress: () => {}
}

export const alertMessage = (title = '', message: string, buttons?: TButton[], options?: any) => {
    if(buttons?.length){
        confirmAlert({
            title,
            message,
            buttons: buttons.map(b => ({label: b.text, onClick: b.onPress}))
          });
        return;
    }
    //@ts-ignore
    alert(JSON.stringify(message));
};
