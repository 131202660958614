import {
  Text,
  Column,
  Row,
  FormInput,
  Button,
  ContentContainer,
} from 'components';
import React, {useCallback, useState} from 'react';
import {StyleSheet, View, ActivityIndicator, Platform} from 'react-native';
import {Formik} from 'formik';
import {EscreenNames} from 'navigation';
import {TouchableOpacity} from 'react-native';
import {useDispatch} from 'react-redux';
import {colors} from '@sponsor/assets';
import {i18n} from 'localize';
import {useActiveLanguage, useNavigation} from 'hooks';
import {forgotPassword} from 'store';
import {alertMessage} from 'utils';

export const ForgotPassword = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  const {navigate} = useNavigation();
  const activeLocale = useActiveLanguage();

  const sendLogin = useCallback(
    ({email}) => {
      setIsLoading(true); 
      dispatch(
        forgotPassword(
          {
            email,
            lang: activeLocale
          },
          {
            onSuccess: () => {
              setIsLoading(false);
              alertMessage(
                '',
                i18n.t('forgotPassword.resetEmailSendAlertMessage'),
              );
              navigate(EscreenNames.Login);
            },
            onFail: (err: any) => {
              setIsLoading(false);
              setError(err?.message);
            },
          },
        ),
      );
    },
    [dispatch, navigate],
  );

  return (
    <ContentContainer>
      <Text variant='authFormTitle'>
        {i18n.t('forgotPassword.forgotPassword')}
      </Text>
      <Formik
        initialValues={{
          email: '',
        }}
        validate={(values: any) => {
          const errors: any = {};
          if (!values.email) {
            errors.email = i18n.t('validationSchema.requiredField');
          } else if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
          ) {
            errors.email = i18n.t('validationSchema.invalidEmail');
          }
          return errors;
        }}
        onSubmit={values => {
          sendLogin(values);
        }}>
        {({values, handleSubmit, handleChange, errors}) => (
          <View
            style={{
              borderWidth: 0.5,
              borderColor: colors.CONCRETE,
              padding: 25,
            }}>
            <FormInput
              label="Email"
              value={values?.email}
              placeholder="Email"
              onChangeText={handleChange('email')}
              error={errors?.email}
            />
            <Text variant="inputError">{error}</Text>
            <Row containerStyle={{justifyContent: 'space-between'}}>
              <Row>
                <View>
                  <TouchableOpacity
                    onPress={() => navigate(EscreenNames.Login)}>
                    <Text>{i18n.t('forgotPassword.haveAccount')}</Text>
                  </TouchableOpacity>
                  <Button
                    containerStyle={{marginVertical: 25}}
                    onPress={handleSubmit}
                    disabled={isLoading}
                    title={i18n.t('forgotPassword.resetPassword')}
                  />
                </View>

                {isLoading && <ActivityIndicator style={{paddingLeft: 15}} />}
              </Row>

              <Column rowCenter columnCenter>
                <Text>{i18n.t('loginScreen.dontHaveAccount')}</Text>
                <TouchableOpacity onPress={() => navigate(EscreenNames.Signup)}>
                  <Text variant="thirdTitle">{i18n.t('createAccount')}</Text>
                </TouchableOpacity>
              </Column>
            </Row>
          </View>
        )}
      </Formik>
    </ContentContainer>
  );
};

const styles = StyleSheet.create({
  pageContainer: {
    justifyContent: 'center',
    marginVertical: 50,
  },
  formContainer: {
    padding: 15,
    borderWidth: 0.5,
    borderColor: colors.DUSTY_GRAY,
    flex: 1,
  },
  errorText: {
    color: 'red',
    fontSize: 15,
  },
  inputContainer: {
    width: '100%',
    flex: 1,
    height: 75,
    backgroundColor: colors.CONCRETE,
    padding: 15,
    outlineColor: Platform.OS === 'web' ? 'orange' : null,
  },
});
