import {useCallback, useState} from 'react';
import {LOG} from 'utils';
import {SubscriptionApiService} from './subscription.api';
import {isEmpty} from 'lodash';
import { AxiosError } from 'axios';
import { i18n } from 'localize';

export const useRetrievePaymentLink = (params: {
  tierId: string;
  userId?: string;
  email: string;
  userPrice?: string;
}): {
  link: string;
  loading: boolean;
  error: string;
  getLink: () => Promise<string | void>;
} => {
  const [link, setLink] = useState('');
  const [error, setError] = useState('');

  const [loading, setLoading] = useState(false);

  const getLink = useCallback(async () => {
    let newLink;

    //todo: check exparation date of payment link
    if (loading || link || error) {
      return link;
    }

    if (isEmpty(params) || !params) {
      setError('no params');
    }
    //todo: use react hooks here
    try {
      setLoading(true);
      const { data } = await SubscriptionApiService.postGetPaymentLink(params);
      setLink(data.checkout_url);
      newLink = data.checkout_url;
      setLink(newLink);
    } catch (err: any) {
      LOG(err);
      if(err.response.status === 400){
        setError(i18n.t('errors.somethingWrong'))
      }
      else{
        setError(JSON.stringify(err));
      }
    } finally {
      setLoading(false);
    }
    return newLink;
  }, [params, loading, link, error]);

  return {
    link,
    loading,
    getLink,
    error,
  };
};

/**
 *    //   recurring_data: {
      //     amount: (parseFloat(price) || initialPrice) * 100,
      //     currency: currency || 'USD',
      //     //todo
      //     end_time: moment(new Date()).add('2', 'years').toISOString().slice(0, 10),
      //     every: 1,
      //     //todo: move to back-end
      //     period: "month",
      //     start_time: new Date().toISOString().slice(0, 10)
      // }
 */
