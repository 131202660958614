export enum EAlpha3Code {
    UKR = 'UKR',
    ENG = 'ENG',
}

export const countries = [
    {
        label: 'Ukrainian',
        value: 'UKR',
    },
    // {
    //     label: 'England',
    //     value: 'ENG',
    // },
    // {
    //     label: 'Poland',
    //     value: 'POL',
    // }
] 