import {
  TMetaInput,
} from '../store/src/common';
import {ActionType, createAction} from 'typesafe-actions';
import {EAnalyticsEventNames, TWithdrawCreatorBalanceInput} from 'types';
import {takeEvery, call, put} from 'redux-saga/effects';
import {MoneyWithdrawApiService} from './money-withdraw.api';
import {AnalyticsService} from 'services';
import {LOG} from 'utils';

export const WITHDRAW_CREATOR_BALANCE = 'creator/WITHDRAW_CREATOR_BALANCE';
export const WITHDRAW_CREATOR_BALANCE_SUCCEEDED =
  'creator/WITHDRAW_CREATOR_BALANCE_SUCCEDED';

export const withdrawCreatorBalance = createAction(WITHDRAW_CREATOR_BALANCE)<
  TWithdrawCreatorBalanceInput,
  TMetaInput
>();

export const withdrawCreatorBalanceSucceeded = createAction(
  WITHDRAW_CREATOR_BALANCE_SUCCEEDED,
)<null>();

function* withdrawCreatorBalanceSaga({
  payload: {currencyName, withdrawMethodId},
  meta: {onSuccess, onFinish, onFail},
}: ActionType<typeof withdrawCreatorBalance>) {
  try {
    //todo: change type
    yield call(async () => MoneyWithdrawApiService.withdrawCreatorBalance({currencyName, withdrawMethodId}));
    yield put(withdrawCreatorBalanceSucceeded(null));
    AnalyticsService.logEvent(
      EAnalyticsEventNames.WITHDRAW_CREATOR_BALANCE_SUCCEEDED,
    );
    onSuccess?.();
  } catch (err) {
    LOG('error during updating creator', err);
    AnalyticsService.logEvent(
      EAnalyticsEventNames.WITHDRAW_CREATOR_BALANCE_FAILED,
    );
    //todo: human readable errors
    onFail?.(err);
  } finally {
    onFinish?.();
  }
}

export function* moneyWithdrawFlow() {
  yield takeEvery(withdrawCreatorBalance, withdrawCreatorBalanceSaga);
}
