import {createSelector, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {ActionType, createAction} from 'typesafe-actions';
import {takeEvery, call, put} from 'redux-saga/effects';
import {filter, omit, values, reduce, find, map, toLower} from 'lodash';
import {
  EErrorCodes,
  TGetTiersOutput,
  TUpdateCreatorInput,
  TUpdateCreatorOutput,
  EAnalyticsEventNames,
  ECreatorSettings,
  TUpdateCreatorGoalOutput,
  TDeleteCreatorGoalOutput,
  TMyGetCreatorOutput,
  TTierInResponse,
} from '../../../types';

import {LOG} from '../../../utils/LOG';
import {
  getStore,
  TBasicCreatorInfoState,
  TMetaInput,
  TStore,
  TTiersState,
} from '../common';

import {ApiService, AnalyticsService} from '../../../services';
import {setTiersState} from '../tiers';
import {i18n} from 'localize';
import {createCRUDActionCreators, fetchSaga} from '../common';
import {TGreetingsState} from './greetings';
import {
  withdrawCreatorBalanceSucceeded,
} from '../../../money-withdraw/money-withdraw.saga';
import {transformTier} from '../../../tiers/tiers.dto';
import {setWithdrawMethodsState} from '../../../money-withdraw-methods/money-withdraw-methods.reducer';
import {TPostUploadWithdrawMethodInput} from 'app/money-withdraw-methods/money-withdraw-methods.types';
import { uploadGoalsTranslationsSuccess } from '../../..//goalsTranslations/goalsTranslations.actions';
import { TPostUploadGoalsTranslationsOutput } from '../../..//goalsTranslations/goalsTranslations.types';

//TODO: remove 
export const SET_CREATOR_DATA = 'creator/SET_CREATOR_DATA';

export const setCreatorPageData = createAction(SET_CREATOR_DATA)<any>();

export const creatorSlice = createSlice({
  name: 'creator',
  initialState: {} as TBasicCreatorInfoState,
  extraReducers: {
    [withdrawCreatorBalanceSucceeded.toString()]: (
      state,
      action: PayloadAction<TPostUploadWithdrawMethodInput>,
    ) => {
      return {
        ...state,
        balances: map(state?.balances, b => {
          if (b?.currency?.name == action.payload?.currencyName) {
            return {
              ...b,
              amount: 0,
            };
          }
          return b;
        }),
      };
    },
    [setCreatorPageData?.toString()]: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      if (action.payload.greetings) {
        action.payload.greetings = reduce<any, TGreetingsState>(
          action.payload.greetings,
          (s, i) => {
            s[i?.id] = i;
            return s;
          },
          {},
        );
      }
      return action.payload;
    },
    [uploadGoalsTranslationsSuccess.toString()]: (state, action:PayloadAction<TPostUploadGoalsTranslationsOutput['data']>) => {
      state.goals[action.payload.parentId].translations[action.payload.id] = action.payload
    }
  },
  reducers: {
    // setCreatorPageData: (state, action) => {
    //   // Redux Toolkit allows us to write "mutating" logic in reducers. It
    //   // doesn't actually mutate the state because it uses the Immer library,
    //   // which detects changes to a "draft state" and produces a brand new
    //   // immutable state based off those changes
    //   if (action.payload.greetings){
    //     action.payload.greetings = reduce<any, TGreetingsState>(
    //       action.payload.greetings,
    //       (s, i) => {
    //         s[i?.id] = i;
    //         return s;
    //       },
    //       {},
    //     );
    //   }
    //   return action.payload;
    // },
    updateCreatorPageSuccess: (state, action) => {
      const newState = {
        ...state,
        ...action.payload,
      };
      return newState;
    },
    //todo: move to another slice
    uploadGoalSuccess: (
      state: TBasicCreatorInfoState,
      action: PayloadAction<TUpdateCreatorGoalOutput['data']>,
    ) => {
      const newState = {
        ...state,
        goals: {
          ...(state?.goals || {}),
          [action.payload.id]: {
            ...action.payload,
          },
        },
      };
      return newState;
    },
    deleteGoalSuccess: (
      state: TBasicCreatorInfoState,
      action: PayloadAction<TDeleteCreatorGoalOutput['data']>,
    ) => {
      // delete state[action.payload?.tierId];
      const creatorsGoals = state.goals;
      const newState = {
        ...state,
        goals: omit(creatorsGoals, action.payload.id),
      };
      return newState;
    },
    updateGoalSuccess: (
      state: TBasicCreatorInfoState,
      action: PayloadAction<TUpdateCreatorGoalOutput['data']>,
    ) => {
      const newState = {
        ...state,
        goals: {
          ...(state?.goals || {}),
          [action.payload.id]: {
            ...(state?.goals?.[action.payload.id] || {}),
            ...action.payload,
          },
        },
      };
      return newState;
    },
    // todo: move goals to own reducer slice and store only id 
  },
});

export const UPDATE_CREATOR_PAGE_START = 'creator/UPDATE_CREATOR_PAGE_START';
export const EXPORT_SUBSCRIBERS = 'creator/EXPORT_SUBSCRIBERS';
export const GET_CREATOR_INFO = 'creator/GET_CREATOR_INFO';
export const PUBLISH_CREATOR_PAGE = 'creator/PUBLISH_CREATOR_PAGE';

// Action creators are generated for each case reducer function
export const {updateCreatorPageSuccess, updateGoalSuccess, deleteGoalSuccess} =
  creatorSlice.actions;

export const updateCreatorStart = createAction(UPDATE_CREATOR_PAGE_START)<
  TUpdateCreatorInput,
  TMetaInput
>();

export const {
  getCreatorInfoSuccess,
} = createCRUDActionCreators('CreatorInfo','')

export const getCreatorInfo = createAction(GET_CREATOR_INFO)<null, TMetaInput>();
export const exportSubscribers = createAction(EXPORT_SUBSCRIBERS)<
  null,
  TMetaInput
>();

export const publishCreatorPage = createAction(PUBLISH_CREATOR_PAGE)();

export const {uploadGoal, updateGoal, deleteGoal, getGoal} =
  createCRUDActionCreators('Goal', 'creator/');

export default creatorSlice.reducer;

// selectors
export const selectCreatorBasicInfo = createSelector(
  getStore,
  state => state.creator.basicInfo,
);

export const selectCreatorCurrencyBalance = createSelector(
  [getStore, (state: TStore, currencyName: string) => currencyName],
  (state, currencyName) =>
    find(state.creator.basicInfo?.balances, b => toLower(b?.currency?.name) === toLower(currencyName)),
);

export const selectCreatorShownGoals = createSelector(
  selectCreatorBasicInfo,
  creatorState => {
    return filter(
      values(creatorState?.goals || {}),
      g => g?.type == creatorState?.settings?.[ECreatorSettings.showGoals],
    );
  },
);

function* updateCreatorSaga({
  payload,
  meta: {onSuccess, onFail, onFinish},
}: ActionType<typeof updateCreatorStart>) {
  try {
    console.log('payload', payload);
    const {data}: TUpdateCreatorOutput = yield call(
      ApiService.updateCreator,
      payload,
    );

    if (data?.errorCode) {
      if (data?.errorCode === EErrorCodes.DUBLICATED_DATA) {
        onFail?.({
          message: i18n.t('errors.pageUrlUnique'),
        });
      } else {
        onFail?.({
          message: i18n.t('errors.somethingWrong'),
        });
      }
    } else {
      yield put(updateCreatorPageSuccess(data?.creator));
      onSuccess?.();
    }
  } catch (err) {
    onFail?.({
      message: i18n.t('errors.somethingWrong'),
    });
    LOG('error during updating creator', err);
  } finally {
    onFinish?.();
  }
}

function* getCreatorInfoSaga({
  meta: {onSuccess, onFail, onFinish}, 
}: {meta: TMetaInput}) {
  try {
    //todo: change type
    const {data: creator}: TMyGetCreatorOutput = yield call(
      ApiService.getMyCreatorPage,
    );

    const {data: tiers}: TGetTiersOutput = yield call(ApiService.getTiers);

    const tierState = reduce<TTierInResponse, TTiersState>(
      tiers,
      (s, tier) => {
        s[tier?.id] = transformTier(tier);
        return s;
      },
      {},
    );

    //TODO: replace naming based on events, not set... (with one getCreatorInfoSuccess ??? )
    yield put(setCreatorPageData(creator));
    yield put(setTiersState(tierState));
    yield put(setWithdrawMethodsState(creator?.withdrawMethods));
    // yield put(getCreatorInfoSuccess(creator))
    onSuccess?.();
  } catch (err:any) {
    console.log('err',err)
    LOG('error during getting creator', JSON.stringify(err));
    onFail?.();
  }
  finally{
    onFinish?.()
  }
}

function* exportSubscribersSaga({
  meta: {onFinish},
}: ActionType<typeof exportSubscribers>) {
  try {
    yield call(ApiService.exportSubscribers);
    yield put(
      updateCreatorPageSuccess({
        lastSubscribersExportAt: new Date().toISOString(),
      }),
    );
    AnalyticsService.logEvent(EAnalyticsEventNames.EXPORT_SUBSCRIBERS);
  } catch (err) {
    LOG('error during updating creator', err);
  } finally {
    onFinish?.();
  }
}

export function* creatorFlow() {
  //todo: think about naming - getCreatorInfo, to be consistent
  yield takeEvery(getCreatorInfo, getCreatorInfoSaga);
  //todo: replace by already created fabric methods and rename to updateCreator
  yield takeEvery(updateCreatorStart, updateCreatorSaga);
  yield takeEvery(exportSubscribers, exportSubscribersSaga);

  yield takeEvery(
    uploadGoal.toString(),
    fetchSaga(ApiService.postUploadGoal, {
      analytics: {
        logEvent: event => AnalyticsService.logEvent(event),
        onSuccessEvent: EAnalyticsEventNames.UPLOAD_GOAL_SUCCEEDED,
        onFailedEvent: EAnalyticsEventNames.UPLOAD_GOAL_FAILED,
      },
    }),
  );
  yield takeEvery(
    updateGoal.toString(),
    fetchSaga(ApiService.updateGoal, {
      analytics: {
        logEvent: event => AnalyticsService.logEvent(event),
        onSuccessEvent: EAnalyticsEventNames.UPDATE_GOAL_SUCCEEDED,
        onFailedEvent: EAnalyticsEventNames.UPDATE_GOAL_FAILED,
      },
    }),
  );
  yield takeEvery(
    deleteGoal.toString(),
    fetchSaga(ApiService.deleteGoal, {
      analytics: {
        logEvent: event => AnalyticsService.logEvent(event),
        onSuccessEvent: EAnalyticsEventNames.DELETE_GOAL_SUCCEEDED,
        onFailedEvent: EAnalyticsEventNames.DELETE_GOAL_FAILED,
      },
    }),
  );
}
