import * as Yup from 'yup';
import {i18n} from 'localize';

export const createTierSchema = () =>
  Yup.object().shape({
    title: Yup.string()
      .min(2, i18n.t('validationSchema.tooShort'))
      .max(150, i18n.t('validationSchema.tooLong'))
      .required(i18n.t('validationSchema.requiredField')),
    description: Yup.string()
      .min(2, i18n.t('validationSchema.tooShort'))
      .max(5000, i18n.t('validationSchema.tooLong')),
    currencyName: Yup.string()
      .min(2, i18n.t('validationSchema.tooShort'))
      .max(750, i18n.t('validationSchema.tooLong'))
      .required(i18n.t('validationSchema.requiredField')),
    price: Yup.number()
      .typeError(i18n.t('validationSchema.numberRequired'))
      //for other currencies ???
      .positive(i18n.t('validationSchema.positiveNumber'))
      .required(i18n.t('validationSchema.requiredField')),
    subscribersLimit: Yup.string().max(10),
  });
