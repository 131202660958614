import {takeEvery} from '@redux-saga/core/effects';
import {createCRUDActionCreators, fetchSaga} from '../store/src/common';
import {postSubscriptionCancelingByToken} from './subscription.api';

export const {uploadSubscriptionCanceling} = createCRUDActionCreators(
  'SubscriptionCanceling',
  '',
);

export function* subscriptionFlow() {
  yield takeEvery(
    uploadSubscriptionCanceling?.toString(),
    fetchSaga(postSubscriptionCancelingByToken),
  );
}
