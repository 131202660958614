import styled from 'styled-components/native';

import {responsiveSize} from '../../../../utils/font';

export const Container = styled.ImageBackground`
  width: 100%;

  padding: ${responsiveSize(205)} ${responsiveSize(150)} ${responsiveSize(205)}
    ${responsiveSize(91)};

  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  ${props =>
    props.media.lg &&
    `
  flex-direction: column;
    padding: ${responsiveSize(160)} ${responsiveSize(60)};
  `};

  ${props =>
    props.media.md &&
    `
    
    padding: ${responsiveSize(110)} ${responsiveSize(60)};
  `};

  ${props =>
    props.media.sm &&
    `
    padding: ${responsiveSize(90)} ${responsiveSize(16)};
  `};
`;

export const Image = styled.Image`
  height: ${responsiveSize(697)};
  width: ${responsiveSize(800)};

  ${props =>
    props.media.lg &&
    `
    margin-bottom: ${responsiveSize(50)};
    height: ${responsiveSize(697 / 1.7)};
    width: ${responsiveSize(800 / 1.7)};
  `};

  ${props =>
    props.media.md &&
    `
    height: ${responsiveSize(697 / 1.6)};
    width: ${responsiveSize(800 / 1.6)};
    max-width: 100%;
  `};

  ${props =>
    props.media.sm &&
    `
    margin-bottom: ${responsiveSize(32)};
    height: ${responsiveSize(697 / 2.3)};
    width: ${responsiveSize(800 / 2.3)};
  `};
`;

export const Content = styled.View`
  flex: 1;
  margin-left: ${responsiveSize(61)};

  ${props =>
    props.media.lg &&
    `
    flex: none;
    margin-left: ${responsiveSize(50)};
  `};

  ${props =>
    props.media.md &&
    `
   
    margin-left: ${responsiveSize(20)};
    align-items: center;
  `};

  ${props =>
    props.media.sm &&
    `
    margin-left: ${responsiveSize(0)};
  `}
`;

export const Title = styled.Text`
  margin-bottom: ${responsiveSize(24)};

  font-weight: bold;
  font-size: ${responsiveSize(51)};
  line-height: ${responsiveSize(79)};
  letter-spacing: 0.03em;
  text-transform: uppercase;
  color: #ffffff;
  font-family: inherit;

  ${props =>
    props.media.lg &&
    `
    font-size: ${responsiveSize(51 / 1.4)};
    line-height: ${responsiveSize(79 / 1.4)};
    text-align: center;

    max-width: ${responsiveSize(700)};
  `};

  ${props =>
    props.media.md &&
    `
    font-size: ${responsiveSize(51 / 1.2)};
    line-height: ${responsiveSize(79 / 1.2)};
   
  `};

  ${props =>
    props.media.sm &&
    `
    margin-bottom: ${responsiveSize(16)};

    font-size: ${responsiveSize(51 / 2)};
    line-height: ${responsiveSize(79 / 2)};
  `};
`;

export const Description = styled.Text`
  font-size: ${responsiveSize(22)};
  line-height: ${responsiveSize(33)};
  letter-spacing: 0.03em;
  text-transform: uppercase;
  color: #ffffff;
  font-family: inherit;

  ${props =>
    props.media.md &&
    `
    font-size: ${responsiveSize(22 / 1.2)};
    line-height: ${responsiveSize(33 / 1.2)};
    text-align: center;
  `};

  ${props =>
    props.media.lg &&
    `
    text-align: center;
  `}

  ${props =>
    props.media.sm &&
    `
    font-size: ${responsiveSize(22 / 1.6)};
    line-height: ${responsiveSize(33 / 1.6)};
  `};
`;
