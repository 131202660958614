import { put, takeEvery } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';
import { createCRUDActionCreators } from '../common';
import { updateCreatorStart } from './creator';

export const {
    updateCreatorSettings
} = createCRUDActionCreators('CreatorSettings','')

function* updateCreatorSettingsSaga({
    payload,
    meta,
  }: ActionType<{
    payload: any,
    meta: any,
  }>) {
    yield put(updateCreatorStart({
        settings: payload
    }, meta))
}
  
export function* creatorSettingsFlow() {
    yield takeEvery(updateCreatorSettings?.toString(), updateCreatorSettingsSaga);
}