import {Button, View, Row, Text, Checkbox, Icon} from 'components';
import React, {useCallback, useEffect} from 'react';
import {FlatList, ActivityIndicator, Linking, TouchableOpacity, Platform} from 'react-native';
import {useDispatch, useSelector} from 'react-redux';
import {i18n} from 'localize';
import {selectUser, getCreatorInfo, selectCreatorBasicInfo} from 'store';
import {useRetrieveFondyCardVerificationLink} from './useRetrieveFondyCardVerificationLink';
import {PaymentCardContainer} from '../../../components/src/PaymentCard/PaymentCard.container';
import {
  selectWithdrawCardsState,
  selectWithdrawCryptoWallet,
} from '../../../money-withdraw/money-withdraw.selectors';
import {EscreenNames} from 'navigation';
import {useDispatchAction, useNavigation} from 'hooks';
//todo: add absolute path for each feature
import {WithdrawMethodContainer} from '../../../money-withdraw-methods/WithdrawMethod.container';
import {selectCreatorMerchantsForWithdraw} from '../../../creatorMerchant/creatorMerchant.selectors';
import {isArray} from 'lodash';
import {alertMessage} from 'utils';
import {MerchantCardContainer} from '../../../creatorMerchant/MerchantCard.container';
import { updateCreatorSettings } from '../../../store/src/creator/settings';
import { ECreatorSettings } from 'types';
import { ScrollView } from 'react-native-gesture-handler';

export function WithdrawMethodsPage() {
  const dispatch = useDispatch();

  const withdrawnCards = useSelector(selectWithdrawCardsState);
  const cryptoWallets = useSelector(selectWithdrawCryptoWallet);
  const merchants = useSelector(selectCreatorMerchantsForWithdraw);
  const creatorBasicInfo = useSelector(selectCreatorBasicInfo);
  const user = useSelector(selectUser);
  const {navigate} = useNavigation();

  useEffect(() => {
    dispatch(getCreatorInfo(null, {}));
  }, [dispatch]);

  const {
    submitAction: submitUpdateCreatorSettings
  } = useDispatchAction(updateCreatorSettings)

  const {
    isLoading: isCreatorLoading,
    error: getCreatorError,
    submitAction: submitGetCreatorInfo
  } = useDispatchAction(getCreatorInfo)

  const {
    link,
    loading: isLinkLoading,
    error: linkError,
  } = useRetrieveFondyCardVerificationLink({
    // todo: hide all unneccessaries fields
    merchant_data: JSON.stringify({
      userId: user?.id || null,
      email: user?.email,
    }),
  });

  const renderErrorMessage = useCallback(() => {
    if (!linkError) {
      return null;
    }
    return (<View>
        <Text variant="inputError">
          {i18n.t('errors.somethingWrong')}
      </Text>
    </View>)
  }, [linkError]);

  const renderAuthorizationCardButton = useCallback(() => {
    if (!link) {
      return null;
    }
    return (
      <TouchableOpacity
        onPress={() => {
          Linking.openURL(link);
        }}
      >
        <Text style={{fontSize: 24}}>+</Text>
      </TouchableOpacity>
    );
    // return (
    //   <>
    //     {Platform.OS === 'web' ? (
    //       ReactHtmlParser(formHTML)
    //     ) : (
    //       <WebView
    //         source={{
    //           html:formHTML
    //         }}
    //         style={{flex: 1}}
    //       />
    //     )}
    //   </>
    // )
  }, [link]);

  if (isLinkLoading) {
    return <View style={{flex:1, justifyContent: 'center', alignItems: 'center'}}>
          <ActivityIndicator />
      </View>;
  }

  return (
    <View variant='screenContainer'>
      <ScrollView>
        {/* <Text variant="secondaryTitle">
              {i18n.t('withdrawMethods.screenTitle')}
        </Text> */}

        {/* <View bordered variant='sectionContainer'>
          <Text variant="thirdTitle">
            {i18n.t('withdrawMethods.paymentSettingTitle')}
          </Text>
          <Checkbox
              containerStyle={{paddingTop: 15}}
              // containerStyle={styles.checkboxContainer}
              testID="checkbox-disable-crypto-payment"
              disabled={creatorBasicInfo?.settings?.[ECreatorSettings.allowCryptoPayment] !== true}
              onPress={() => submitUpdateCreatorSettings({[ECreatorSettings.allowCryptoPayment]: !creatorBasicInfo?.settings?.[ECreatorSettings.allowCryptoPayment]})}
              title={i18n.t('withdrawMethods.checkboxAllowCryptoPaymentTitle')}
              value={
                creatorBasicInfo?.settings?.[ECreatorSettings.allowCryptoPayment] === true
              }
          />
        </View> */}

        <View bordered variant='sectionContainer'>
        <FlatList
          // TODO: remove
          // contentContainerStyle={{alignItems: 'flex-start', flexGrow: 1}}
          ListHeaderComponent={
            <View variant="sectionContainer">
              <Text style={{marginRight: 10}} variant="thirdTitle">
                {i18n.t('editCreatorPage.paymentMethods')}
              </Text>
              <Row 
                columnCenter 
                containerStyle={{
                  flex:1,
                  justifyContent: Platform.OS === 'web' ? 'space-between' : 'flex-start'
                }} 
                flexWrap
              >
                <Text style={{marginRight: 10}} variant="secondaryTitle">
                  {i18n.t('editCreatorPage.paymentMethodBankCard')}
                </Text>
                {renderErrorMessage()}
                <Row columnCenter>
                  {renderAuthorizationCardButton()}
                  <View style={{paddingLeft: 15}}>
                    {!isCreatorLoading ? (<TouchableOpacity onPress={() => submitGetCreatorInfo()}>
                      <Icon 
                        color="black" 
                        size={25}  // todo: unified icons sizes
                        name="circle-arrows"
                      /> 
                    </TouchableOpacity>) : (
                      <View>
                          <ActivityIndicator  size="small"/>
                      </View>
                    )}
                  </View>
                </Row>
              </Row>
            </View>
          }
          ItemSeparatorComponent={() => <View variant='verticalListSeparator' />}
          ListEmptyComponent={
            <Text variant="inputLabel">
              {i18n.t('editCreatorPage.reloadPageLater')}
            </Text>
          }
          data={withdrawnCards}
          renderItem={({item}) => <PaymentCardContainer id={item?.id} />}
        />
        </View>

        {/* MERCHANTs */}
        <View>
          <FlatList
            // TODO: remove
            contentContainerStyle={{alignItems: 'flex-start', padding: 10}}
            ListHeaderComponent={
              <View variant="sectionContainer">
                <Row columnCenter containerStyle={{marginBottom: 10}} flexWrap>
                  <Text style={{marginRight: 10}}  variant="secondaryTitle">
                    {i18n.t('editCreatorPage.paymentMethodsMerchantsListTitle')}
                  </Text>
                  <Button 
                    small
                    containerStyle={{
                      width: 50
                    }}
                    onDisabledPress={() => {
                      alertMessage('', i18n.t('errors.limitReachContactAdmin'));
                    }}
                    disabled={isArray(merchants) && merchants?.length > 5}
                    onPress={() => {
                      navigate(EscreenNames.EditCreatorMerchantScreen);
                    }}
                    title={'+'}
                  />
                </Row>
              </View>
            }
            ItemSeparatorComponent={() => <View style={{margin: 5}} />}
            data={merchants}
            renderItem={({item}) => <MerchantCardContainer id={item?.id} />}
          />
          <View variant='sectionBlocked' style={{justifyContent: 'center'}}>
            <Text variant='secondaryTitle' color="black" center>{`${i18n.t('soon')}\n${i18n.t('editCreatorPage.paymentMethodsMerchantsListTitle')}`}</Text>
          </View>
        </View>

        <View>
          <FlatList
            // TODO: remove
            contentContainerStyle={{alignItems: 'flex-start', padding: 10}}
            style={{marginTop: 25}}
            ListHeaderComponent={
              <View variant="sectionContainer">
                <Row columnCenter containerStyle={{marginBottom: 10}} flexWrap>
                  <Text style={{marginRight: 10}} variant="secondaryTitle">
                    {i18n.t('editCreatorPage.paymentMethodCryptoWallet')}
                  </Text>
                  <Button
                    small
                    containerStyle={{
                      width: 50
                    }}
                    onPress={() => {
                      navigate(EscreenNames.MoneyWithdrawMethodEdit);
                    }}
                    title={'+'}
                  />
                </Row>
              </View>
            }
            ItemSeparatorComponent={() => <View style={{margin: 5}} />}
            data={cryptoWallets}
            renderItem={({item}) => <WithdrawMethodContainer showDeleteBtn id={item?.id} />}
          />
          <View variant='sectionBlocked' style={{justifyContent: 'center'}}>
            <Text variant='secondaryTitle' color="black" center>{`${i18n.t('soon')}\n crypto`}</Text>
          </View>
        </View>

        {/* {withdrawnCards?.length > 1 && (
          <Button
            containerStyle={{paddingTop: 25}}
            primary
            title={i18n.t('saveSettings')}
            onPress={saveActiveCard}
          />
        )} */}
      </ScrollView>
    </View>
  );
}
