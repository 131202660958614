import React from 'react';
import {View, ActivityIndicator} from 'react-native';

type TProps = {
  color?: string;
  large?: boolean;
  containerStyle?: any;
};

const Loader: React.FC<TProps> = ({color, large, containerStyle = {}}) => (
  <View style={{...containerStyle}}>
    <ActivityIndicator size={large ? 'large' : 'small'} color={color} />
  </View>
);

export default Loader;
