import {CreatorCard, View} from 'components';
import React from 'react';

import {noop} from 'lodash';
import { Platform } from 'react-native';
import { isMobile } from 'utils';
import { useMedia } from '../hooks/useMedia';

type TProps = {
  creatorTitle: string;
  creatorAvatarUri: string;
  creatorPageURL: string
};

export const SubscriptionCreator: React.FC<TProps> = ({
  creatorTitle,
  creatorAvatarUri,
  creatorPageURL,
}) => {

  const media = useMedia()

  return (
    <View>
      <View variant="sectionContainer" style={{alignItems: 'flex-start'}}>
        <CreatorCard
          creatorTitle={creatorTitle}
          creatorPageURL={creatorPageURL}
          onPress={noop}
          creatorAvatarUri={creatorAvatarUri}
          containerStyle={{
            borderColor: 'black',
            width: media.sm ? '100%' : undefined
          }}
        />
      </View>
    </View>
  );
};
