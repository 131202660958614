import {firebase} from '../../libs/src/firebase/firebase';
import {AxiosRequestConfig} from 'axios';

import {LOG} from '../../utils/LOG';

type HttpMethod = 'GET' | 'POST' | 'DELETE' | 'PUT' | 'PATCH';

export type TConfigWithPerfMetrics = AxiosRequestConfig & {
  metadata: {
    // httpMetric: FirebasePerformanceTypes.HttpMetric;\
    httpMetric: any;
  };
};

class _PerformanceService {
  private userUid = '';

  setUserUid = (id: string): void => {
    this.userUid = id;
  };

  getUserUid = (): string => {
    return this.userUid;
  };

  startHttpMetric = async (config: any): Promise<any> => {
    try {
      const httpMetric = firebase
        .perf?.()
        .newHttpMetric?.(
          config?.url,
          config?.method.toUpperCase() as HttpMethod,
        );
      config.metadata = {
        httpMetric,
      };
      await httpMetric.start?.();

      return config;
    } catch (err) {
      return config;
    }
  };

  stopHttpMetric = async (response: any, httpMetric?: any): Promise<void> => {
    // when connection is offline response is undefined that causes errors
    // TODO: handle offline connection case
    const {userUid} = this;
    try {
      // Request was successful, e.g. HTTP code 200
      if (!httpMetric) {
        const config: any = response?.config || {};
        httpMetric = config.metadata?.httpMetric;
      }

      if (userUid) {
        httpMetric?.putAttribute?.('userUid', userUid);
      }

      httpMetric?.setHttpResponseCode?.(response.status);
      httpMetric?.setResponseContentType?.(response.headers['content-type']);
      await httpMetric?.stop?.();
    } catch (err) {
      LOG(err);
    }
  };
}

export const PerformanceService = new _PerformanceService();
