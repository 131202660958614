import {AxiosResponse, TGreetings} from '../types';

export enum EGreetingsAnalyticsEventNames {
  UPDATE_GREETINGS_SUCCEEDED = 'greetings_update_succeeded',
  UPDATE_GREETINGS_FAILED = 'greetings_update_failed',
  UPLOAD_GREETINGS_SUCCEEDED = 'greetings_upload_succeeded',
  UPLOAD_GREETINGS_FAILED = 'greetings_upload_failed',
  DELETE_GREETINGS_SUCCEEDED = 'greetings_delete_succeeded',
  DELETE_GREETINGS_FAILED = 'greetings_delete_failed',
}

//todo: remove partial
export type TPostUploadGreetingsInput = Partial<TGreetings>;
export type TPostUploadGreetingsOutput = AxiosResponse<TGreetings[]>;

export type TUpdateGreetingsInput = Partial<TGreetings>[];
export type TUpdateGreetingsOutput = AxiosResponse<TGreetings[]>;

export type TDeleteGreetingsInput = string[];
export type TDeleteGreetingsOutput = AxiosResponse<null>;
