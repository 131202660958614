import {QRCode} from '../components/src/QRCode/QRCode.component';
import {Icon, Row, Text, View} from 'components';
import {i18n} from 'localize';
import React, {useCallback} from 'react';
import {TouchableOpacity} from 'react-native';
import {Clipboard} from '../utils/Clipboard/Clipboard';

export const CryptoAddressQRCode: React.FC<{
  address: string;
  memo: string;
}> = ({address, memo}) => {
  const copyAddress = useCallback(() => {
    Clipboard.setString(address);
  }, [address]);

  const copyMemo = useCallback(() => {
    Clipboard.setString(memo);
  }, [memo]);

  return (
    <View>
      <QRCode value={address} />

      <View variant="sectionContainer">
        <Text variant="thirdTitle">
          {i18n.t('subscriptionCryptoPaymentForm.cryptoAddress')}
        </Text>
        <Row columnCenter >
          <Text>{address}</Text>
          <TouchableOpacity style={{marginLeft: 25}} onPress={copyAddress}>
            <Icon size={20} name="copy" />
          </TouchableOpacity>
        </Row>
      </View>

      {!!memo && (
        <View>
          <QRCode value={memo} />
          <View variant="sectionContainer">
            <Text variant="thirdTitle">
              {i18n.t('subscriptionCryptoPaymentForm.memo')}
            </Text>
            <Row columnCenter>
              <Text>{memo}</Text>
              <TouchableOpacity style={{marginLeft: 25}} onPress={copyMemo}>
                <Icon size={25} name="copy" />
              </TouchableOpacity>
            </Row>
          </View>
        </View>
      )}
    </View>
  );
};
