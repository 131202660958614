import React, {useState, useCallback, useEffect} from 'react';
import {StyleSheet} from 'react-native';

import {useSelector, useDispatch} from 'react-redux';
import {EscreenNames} from 'navigation';
import {colors} from '@sponsor/assets';
import {Column} from '../Column';
import {Logo} from '../Logo/Logo';
import {Row} from '../Row';
import { View } from '../View'
import {i18n} from 'localize';
import {Text} from '../Text';
import {selectCreatorBasicInfo, selectUser} from 'store';
import {selectIsAuthenticated, logoutStart} from 'store';

import {Button} from '../Button/Button';

import {useNavigation} from 'hooks';
import {LOG} from 'utils';
import {TouchableOpacity} from 'react-native';
import {Avatar} from '../Avatar/Avatar';
import {HEADER_HEIGHT} from '@sponsor/constants';
import {Icon} from '..';
import {LanguagePicker} from '../../../containers/LanguagePicker/LanguagePicker';
import {useMedia} from '../../../hooks/useMedia';
import { useKeyPress } from '../../../hooks/useKeyPress/useKeyPress';

export const Header = () => {
  const user = useSelector(selectUser);
  const creator = useSelector(selectCreatorBasicInfo);
  const isAuthenticated = useSelector(selectIsAuthenticated);
  LOG('isAuthenticated', isAuthenticated);

  const media = useMedia()
  const dispatch = useDispatch();
  const [isDropDownVisible, setIsDropdownVisible] = useState(false);
  const {navigate} = useNavigation();

  const [pressed] = useKeyPress('Escape')
  const [mPressed] = useKeyPress('m')

  const logout = useCallback(() => {
    dispatch(logoutStart());
  }, [dispatch]);

  useEffect(() => {
    if(pressed){
      setIsDropdownVisible(false)
    }
    if(mPressed){
      setIsDropdownVisible(true)
    }
  },[pressed, mPressed])

  return (
    <>
     {/* rowCenter overrided cases */}
      <Row columnCenter containerStyle={[styles.container, {justifyContent: 'space-between'}]}>
        {/* <Link to="/home"> */}
        <TouchableOpacity style={{paddingTop: media?.sm ? 10: 0, paddingHorizontal: 8, justifyContent: 'center', alignItems: 'center'}} onPress={() => navigate(EscreenNames.Home)}>
          <Logo imageStyle={media?.sm ? {height: 50, width: 100} : {}}/>
        </TouchableOpacity>
        {/* </Link> */}

        <Column>
          <Row fullWidth rowCenter columnCenter containsSimilarItems>
            {/* <TouchableOpacity onPress={() => navigate(EscreenNames.Search)}>
              <Icon name="search" />
            </TouchableOpacity> */}
            <LanguagePicker />
            {isAuthenticated ? (
              <TouchableOpacity
                testID="toggle-header-dropdown-button"
                onPress={() => {
                  setIsDropdownVisible(!isDropDownVisible);
                }}
              >
                <View variant="bordered" green> 
                  <Row columnCenter>
                    {user?.avatarUri && (
                      <View style={{marginRight: 10}}>
                        <Avatar 
                          size={32} 
                          uri={user?.avatarUri} // check sm sizes
                        /> 
                      </View>
                    )}
                    {!media?.sm && <View>
                      <Row>
                        <Text
                          numberOfLines={media.sm ? 1 : 2}
                          style={{maxWidth: media?.sm ? 40 : 100}}
                          selectable={false}
                        >
                          {`${user?.name}`} 
                          {/*  ${media?.sm ? "" : user?.last_name || ''} */}
                        </Text>
                      </Row>
                      <Text bold style={{textTransform: 'lowercase'}}>{i18n.t('menu')}</Text>                    
                    </View>}
                    <Text>{!isDropDownVisible ? '▼' : '▲'}</Text>
                  </Row>
                </View>
              </TouchableOpacity>
            ) : (
              <Row>
                <Button
                  onPress={() => navigate(EscreenNames.Login)}
                  title={i18n.t('login')}
                  containerStyle={{width: 100, marginRight: 10}}
                />
                {!media?.sm && (<Button
                  primary
                  onPress={() => navigate(EscreenNames.Signup)}
                  title={i18n.t('createAccount')}
                  // containerStyle={{width: 100}}
                />)}
              </Row>
            )}
          </Row>
        </Column>
      </Row>
      {isDropDownVisible && (
        <View
          style={{
            ...StyleSheet.absoluteFillObject,
            zIndex: 100,
            flex: 1,
            backgroundColor: 'rgba(21, 199, 109, 0.2);',
          }}
        />
      )}
      {isAuthenticated && isDropDownVisible && (
        <View style={[styles.dropdownContainer]}>
          {/* <TouchableOpacity onPress={() => setIsDropdownVisible(false)}> */}
          {creator?.pageURL ? (
            <View
              style={{
                borderColor: colors.GREEN_OFFICIAL,
                // borderTopWidth: 1,
                borderBottomWidth: 2,
                marginBottom: 25,
                paddingBottom: 25,
              }}
            >
              <TouchableOpacity
                onPress={() => {
                  navigate(EscreenNames.CreatorPublicPage, {
                    pageURL: `${creator?.pageURL}`,
                  }, {
                    urlParam: true
                  });
                  setIsDropdownVisible(false);
                }}
                style={styles.dropdownBtn}
              >
                <Row columnCenter containsSimilarItems>
                  <Icon size={25} name="lightbulb" />
                  <Text variant="buttonText">
                    {i18n.t('profileDropdown.myPage')}
                  </Text>
                </Row>
              </TouchableOpacity>

              <TouchableOpacity
                onPress={() => {
                  navigate(EscreenNames.CreatorSettingEdit);
                  setIsDropdownVisible(false);
                }}
                style={styles.dropdownBtn}>
                <Row columnCenter containsSimilarItems>
                  <Icon size={20} name="edit" />
                  <Text variant="buttonText">
                    {i18n.t('profileDropdown.editMyPage')}
                  </Text>
                </Row>
              </TouchableOpacity>

              <TouchableOpacity
                onPress={() => {
                  setIsDropdownVisible(false);
                  navigate(EscreenNames.Subscribers);
                }}
                style={styles.dropdownBtn}>
                <Row columnCenter containsSimilarItems>
                  <Icon size={25} name="team" />
                  <Text variant="buttonText">
                    {i18n.t('profileDropdown.subscribers')}
                  </Text>
                </Row>
              </TouchableOpacity>

              <TouchableOpacity
                onPress={() => {
                  setIsDropdownVisible(false);
                  navigate(EscreenNames.Revenue);
                }}
                style={styles.dropdownBtn}>
                <Row columnCenter containsSimilarItems>
                  <Icon size={25} name="wallet" />
                  <Text variant="buttonText">
                    {i18n.t('profileDropdown.myRevenue')}
                  </Text>
                </Row>
              </TouchableOpacity>
            </View>
          ) : (
            <TouchableOpacity
              onPress={() => {
                navigate(EscreenNames.CreatorSettingEdit);
                setIsDropdownVisible(false);
              }}
              style={styles.dropdownBtn}>
              <Row columnCenter containsSimilarItems>
                <Icon color={colors.ORANGE} size={25} name="hammer" />
                <Text style={{color: colors.ORANGE}} variant="buttonText">
                  {i18n.t('profileDropdown.createPage')}
                </Text>
              </Row>
            </TouchableOpacity>
          )}
          <TouchableOpacity
            onPress={() => {
              navigate(EscreenNames.Home);
              setIsDropdownVisible(false);
            }}
            style={styles.dropdownBtn}>
            <Row columnCenter containsSimilarItems>
              <Icon size={25} name="list" />
              <Text variant="buttonText">
                {i18n.t('profileDropdown.postsFromCreators')}
              </Text>
            </Row>
          </TouchableOpacity>
          {/* <TouchableOpacity style={styles.dropdownBtn}>
              <Text variant="buttonText">
                {i18n.t("profileDropdown.inviteCreator")}
              </Text>
            </TouchableOpacity> */}
          <TouchableOpacity
            onPress={() => {
              setIsDropdownVisible(false);
              navigate(EscreenNames.Search);
            }}
            style={styles.dropdownBtn}>
            <Row columnCenter containsSimilarItems>
              <Icon size={25} name="search" />
              <Text variant="buttonText">
                {i18n.t('profileDropdown.searchCreators')}
              </Text>
            </Row>
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => {
              setIsDropdownVisible(false);
              navigate(EscreenNames.Subscriptions);
            }}
            style={styles.dropdownBtn}>
            <Row columnCenter containsSimilarItems>
              <Icon size={25} name="circle-arrows" />
              <Text variant="buttonText">
                {i18n.t('profileDropdown.mySubscriptions')}
              </Text>
            </Row>
          </TouchableOpacity>

          <TouchableOpacity
            onPress={() => {
              setIsDropdownVisible(false);
              navigate(EscreenNames.ProfileSettings);
            }}
            style={styles.dropdownBtn}>
            <Row columnCenter containsSimilarItems>
              <Icon size={25} name="user" />
              <Text variant="buttonText">
                {i18n.t('profileDropdown.profileSettings')}
              </Text>
            </Row>
          </TouchableOpacity>
          <TouchableOpacity 
            testID="logout-button"
            onPress={logout} 
            style={styles.dropdownBtn}
          >
            <Row columnCenter containsSimilarItems>
              <Icon size={25} color="red" name="logout" />
              <Text color="red" variant="buttonText">
                {i18n.t('logout')}
              </Text>
            </Row>
          </TouchableOpacity>
          {/* </TouchableOpacity> */}
        </View>
      )}
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    minHeight: HEADER_HEIGHT,
    alignItems: 'center',
    borderTopWidth: 0,
    borderLeftWidth: 0,
    borderRightWidth: 0,
    borderBottomWidth: 3,
    borderColor: colors.GREEN_OFFICIAL,
    padding: 15,
    zIndex: 102,
  },
  searchInput: {
    borderRadius: 14,
    backgroundColor: colors.DUSTY_GRAY,
    padding: 10,
    marginRight: 10,
  },
  dropdownContainer: {
    position: 'absolute',
    zIndex: 102,
    right: 0,
    top: HEADER_HEIGHT + 5,
    padding: 25,
    height: 500,
    borderWidth: 1,
    borderColor: colors.DUSTY_GRAY,
    backgroundColor: 'white',
  },
  dropdownBtn: {
    zIndex: 103,
    padding: 0,
  },
});
