import React from 'react';
import {View, StyleSheet, ViewStyle, TextStyle} from 'react-native';
import {TouchableOpacity} from 'react-native';
import {colors} from '@sponsor/assets';
import {Row, Text} from '..';

//todo: why we decided to create own checkbox ???
type TProps = {
  title?: string;
  value: boolean;
  onPress: () => void;
  testID?: string,
  titleStyle?: TextStyle,
  containerStyle?: ViewStyle;
  disabled?: boolean
};

export const Checkbox: React.FC<TProps> = ({
  containerStyle,
  titleStyle = {},
  title,
  value,
  testID,
  disabled,
  onPress,
}) => {
  return (
    <Row containerStyle={containerStyle} fullWidth columnCenter>
      <TouchableOpacity disabled={disabled} testID={testID} onPress={onPress} style={styles.container}>
        {value && <View style={styles.innerContainer} />}
      </TouchableOpacity>
      {title && (
        <Text style={{paddingLeft: 10, ...titleStyle}} variant="checkboxTitle" >
          {title}
        </Text>
      )}
    </Row>
  );
};

const styles = StyleSheet.create({
  container: {
    borderColor: colors.GREEN_OFFICIAL,
    borderWidth: 1,
    width: 25,
    height: 25,
    borderRadius: 15,
    justifyContent: 'center',
    alignItems: 'center',
  },
  innerContainer: {
    borderRadius: 10,
    width: 15,
    height: 15,
    backgroundColor: colors.GREEN_OFFICIAL,
  },
});
