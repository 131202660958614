import {Text} from 'components';
import React from 'react';
import {useParams} from 'hooks';
import {i18n} from 'localize';
import {ActivityIndicator} from 'react-native';
import {useAsync} from 'react-use';
import {SubscriptionApiService} from '../../../subscription/subscription.api';

export const CancelSubscriptionByTokenScreen = () => {
  const {cancelToken} = useParams();

  const state = useAsync(async () => {
    if (cancelToken) {
      await SubscriptionApiService.postSubscriptionCancelingByToken({
        cancelToken,
      });
    }
  }, [cancelToken]);

  if (!cancelToken) {
    return (
      <Text center variant="secondaryTitle">
        {i18n.t('subscriptionCancelingByToken.invalidTokenWarningMessage')}
      </Text>
    );
  }

  if (state.loading) {
    return <ActivityIndicator />;
  }

  if (state.error) {
    return (
      <Text center variant="secondaryTitle">
        {i18n.t('errors.somethingWrong')}
      </Text>
    );
  }

  return (
    <Text center variant="secondaryTitle">
      {i18n.t('subscriptionCancelingByToken.successMessage')}
    </Text>
  );
};
