export enum EAnalyticsEventNames {
  LOGIN_SUCCEEDED = 'login_succeeded',
  LOGIN_FAILED = 'login_failed',
  SIGNUP_FAILED = 'signup_failed',
  LOGOUT_SUCCEEDED = 'logout_succeeded',
  LOGOUT_FAILED = 'logout_failed',

  SUBSCRIPTION_CANCELING_SUCCEEDED = 'subscription_canceling_succeeded',
  SUBSCRIPTION_CANCELING_FAILED = 'subscription_canceling_failed',

  GETTING_PROFILE_SUCCEEDED = 'getting_profile_succeeded',
  GETTING_PROFILE_FAILED = 'getting_profile_failed',

  CLICK_ADD_PAYMENT_METHOD = 'click_add_payment_method',
  START_FOLLOWING_CREATOR = 'start_following_creator',
  STOP_FOLLOWING_CREATOR = 'stop_following_creator',
  EXPORT_SUBSCRIBERS = 'export_subscribers',

  UPDATE_PROFILE = 'update_profile',

  POST_UPDATE_SUCCEEDED = 'post_update_succeeded',
  POST_UPDATE_FAILED = 'post_update_failed',
  POST_UPLOAD_SUCCEEDED = 'post_upload_succeeded',
  POST_UPLOAD_FAILED = 'post_upload_failed',
  POST_REMOVAL_SUCCEEDED = 'post_removal_succeeded',
  POST_REMOVAL_FAILED = 'post_removal_failed',

  TIER_UPDATE_SUCCEEDED = 'tier_update_succeeded',
  TIER_UPDATE_FAILED = 'tier_update_failed',
  TIER_LINK_COPIED = 'tier_link_copied',
  TIER_UPLOAD_SUCCEEDED = 'tier_upload_succeeded',
  TIER_UPLOAD_FAILED = 'tier_upload_failed',
  TIER_REMOVAL_SUCCEEDED = 'tier_removal_succeeded',
  TIER_REMOVAL_FAILED = 'tier_removal_failed',

  UPDATE_GOAL_SUCCEEDED = 'goal_update_succeeded',
  UPDATE_GOAL_FAILED = 'goal_update_failed',
  UPLOAD_GOAL_SUCCEEDED = 'goal_upload_succeeded',
  UPLOAD_GOAL_FAILED = 'goal_upload_failed',
  DELETE_GOAL_SUCCEEDED = 'goal_delete_succeeded',
  DELETE_GOAL_FAILED = 'goal_delete_failed',

  WITHDRAW_CREATOR_BALANCE_SUCCEEDED = 'withdraw_creator_balance_succeeded',
  WITHDRAW_CREATOR_BALANCE_FAILED = 'withdraw_creator_balance_failed',
}

export enum EUserProperties {
  user_id = 'user_id',
}
