import {NetworkService} from '../services';
import {ApiRequest} from '../types';
import {
  TDeleteWithdrawMethodInput,
  TDeleteWithdrawMethodOutput,
  TPostUploadWithdrawMethodInput,
  TPostUploadWithdrawMethodOutput,
} from './money-withdraw-methods.types';

export const postUploadWithdrawMethod = async (
  inputs: TPostUploadWithdrawMethodInput,
): ApiRequest<TPostUploadWithdrawMethodOutput> => {
  return NetworkService.post('/v1/creator/withdraw-methods', inputs);
};

export const deleteWithdrawMethod = async (
  params: TDeleteWithdrawMethodInput,
): ApiRequest<TDeleteWithdrawMethodOutput> => {
  return NetworkService.delete('/v1/creator/withdraw-methods', params);
};

//todo: probably move verification methods to separate feature
export const MoneyWithdrawMethodApiService = {
  postUploadWithdrawMethod,
  deleteWithdrawMethod,
};
