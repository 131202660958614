import styled from 'styled-components/native';

import {createGlobalStyle} from 'styled-components';

import HomeTopLine from './components/top-line';

const responsiveFont = (responsive, min) => {
  const responsiveUnitless = responsive / (responsive - responsive + 1);
  const dimension = 'width';

  const minBreakpoint = (min / responsiveUnitless) * 100;

  return `
    @media (max-${dimension}: ${minBreakpoint}) {
      font-size: ${min}rem;
    }

    font-size: ${responsive}vw;
  `;
};

export const Container = styled.View`
  position: relative;
  font-size: 0.85rem;
`;

export const TopLine = styled(HomeTopLine)`
  position: absolute;
  top: 20px;
  left: 0px;
  right: 0px;

  z-index: 1;
`;

export const GlobalStyle = createGlobalStyle`
  body, html {
    margin: 0;
    padding: 0;
    font-family: Titillium Web;
    ${responsiveFont(0.835, 0.3)};

    @media (max-width: 992px) {
      font-size: 0.85rem;
    }
  }
`;
