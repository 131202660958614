import * as Yup from 'yup';
import {i18n} from 'localize';

export const getSignupValidationSchema = () =>
  Yup.object().shape({
    password: Yup.string()
      .min(8, i18n.t('validationSchema.tooShort'))
      .matches(/\d/, i18n.t('validationSchema.minOneDigit'))
      .matches(/[a-zA-Z]/, i18n.t('validationSchema.minOneLetter'))
      .required(i18n.t('validationSchema.requiredField')),
    email: Yup.string()
      .email(i18n.t('validationSchema.invalidEmail'))
      .trim(i18n.t('validationSchema.trim'))
      .required(i18n.t('validationSchema.requiredField')),
    // confirmEmail: Yup.string()
    //   .email(i18n.t('validationSchema.invalidEmail'))
    //   .trim(i18n.t('validationSchema.trim'))
    //   .required(i18n.t('validationSchema.requiredField')),
    name: Yup.string()
      .min(2, i18n.t('validationSchema.tooShort'))
      .max(250, i18n.t('validationSchema.tooLong'))
      .trim(i18n.t('validationSchema.trim'))
      .required(i18n.t('validationSchema.requiredField')),
    last_name: Yup.string()
      .min(2, i18n.t('validationSchema.tooShort'))
      .max(250, i18n.t('validationSchema.tooLong'))
      .trim(i18n.t('validationSchema.trim'))
      .required(i18n.t('validationSchema.requiredField')),
    isAggrementApprove: Yup.bool().isTrue(
      i18n.t('validationSchema.requiredField'),
    ),
  });
