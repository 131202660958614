import React from 'react';
import {
  View,
  Row,
  Button,
  Checkbox,
  Text,
  ContentContainer,
  FormInputLabel,
  ActionResult,
  TFormInputInfo,
  renderFormInput,
} from 'components';
import {Formik} from 'formik';
import {useCallback, useMemo} from 'react';
import {ScrollView} from 'react-native';
import {useSelector} from 'react-redux';
import {isEmpty} from 'lodash';
import {EscreenNames} from 'navigation';
import {i18n} from 'localize';
import {TCreatorGoalType} from 'types';
import {useDispatchAction, useNavigation, useParams} from 'hooks';

import {uploadGoal, updateGoal, selectCreatorBasicInfo} from 'store';
import {createGoalSchema} from './validationSchema';
import { ActionResultDefaultRenders } from 'components/src/ActionResult/ActionResultDefaultRenders';

export const EditCreatorGoalPage = () => {
  const {id: editingGoalId} = useParams();
  const creator = useSelector(selectCreatorBasicInfo);
  const editingGoal = useMemo(() => {
    return creator?.goals?.[editingGoalId] || {};
  }, [editingGoalId, creator]);

  const {navigate, goBack} = useNavigation();

  const {
      error: uploadError,
      isLoading: uploadIsInProgress,
      submitAction: addNewGoal,
  } = useDispatchAction(uploadGoal, {
    onSuccess: () => navigate(EscreenNames.CreatorEditGoals),
  })

  const {
    error: updateError,
    isLoading: updateIsInProgress,
    submitAction: handleUpdate,
  } = useDispatchAction(updateGoal, {
    onSuccess: () => navigate(EscreenNames.CreatorEditGoals),
  })

  const isCreatingNew = useMemo(() => isEmpty(editingGoal), [editingGoal]);

  const onSubmit = useCallback((values): void => {
      const goalData = {
        ...values,
        count: parseFloat(values.count) || 1,
      };
      if (isCreatingNew) {
        addNewGoal(goalData);
      } else {
        handleUpdate(
          {
            id: editingGoalId,
            ...goalData,
          },
        );
      }
  }, [editingGoalId, isCreatingNew, addNewGoal, handleUpdate])

  const fields:TFormInputInfo[] = [
    {
      name: "title",
      type: 'text',
      label:i18n.t('editCreatorGoalScreen.goalTitleLabel'),
      placeholder:i18n.t('editCreatorGoalScreen.titleInputPlaceholder'),
      requiredField: true,
    },
    {
      name: "description",
      type: 'text',
      label:i18n.t('editCreatorGoalScreen.goalDescriptionLabel'),
      placeholder:i18n.t('editCreatorGoalScreen.goalDescriptionInputPlaceholder'),
      requiredField: true,
      multiline: true,
      numberOfLines:10
    },
    {
      // TODO: add restriction for number only
      name: "count",
      type: 'text',
      label: ({values}: {values:any}) => values.type === TCreatorGoalType.earnings
          ? i18n.t('editCreatorGoalScreen.goalCountForEarningLabel')
          : i18n.t(
              'editCreatorGoalScreen.goalCountForSubscribersLabel',
      ),
      keyboardType:"decimal-pad",
    },
  ];

  return (
    <ContentContainer>
      <View variant='screenContainer'>
        <ScrollView>
          <Formik
            initialValues={{
              title: editingGoal?.title,
              type: editingGoal?.type || TCreatorGoalType.earnings,
              description: editingGoal?.description,
              count: String(editingGoal?.count || 1),
            }}
            validationSchema={createGoalSchema()}
            onSubmit={onSubmit}>
            {({values, setTouched, touched, isSubmitting, handleSubmit, errors, handleChange}) => (
              <Row>
                <View style={{flex: 1}}>
                  {fields.map((field) => (
                      renderFormInput({
                        ...field,
                        label: typeof field.label === 'string' ? field.label : field.label?.({values}),
                        value: values?.[field?.name as unknown as keyof typeof  values],
                        handleChange: (data:any) => {
                          if(field?.name){
                            setTouched({[field?.name]: true})
                          }
                          return handleChange(field?.name)(data?.value)
                        },
                        error: (touched?.[field?.name as unknown as keyof typeof  values] || isSubmitting) ? errors?.[field?.name  as unknown as keyof typeof errors] : " ",
                      })
                  ))}
                  <View>
                    <Row
                      containsSimilarItems
                      containerStyle={{marginBottom: 10}}
                      columnCenter
                    >
                      <FormInputLabel
                        label={i18n.t('editCreatorGoalScreen.subscribersGoalTypeLabel')}
                        requiredField
                      />
                      <Checkbox
                        onPress={() => {
                          handleChange('type')(TCreatorGoalType.subscribers);
                        }}
                        value={
                          TCreatorGoalType.subscribers == values.type
                            ? true
                            : false
                        }
                      />
                    </Row>
                    <Row containsSimilarItems columnCenter>
                      <Text>
                        {i18n.t('editCreatorGoalScreen.earningsGoalTypeLabel')}
                      </Text>
                      <Checkbox
                        testID="checkbox--show-earnings-goals"
                        onPress={() => {
                          handleChange('type')(TCreatorGoalType.earnings);
                        }}
                        value={
                          TCreatorGoalType.earnings == values.type ? true : false
                        }
                      />
                    </Row>
                    {errors?.type}
                  </View>
                  <Button
                    primary
                    containerStyle={{marginTop: 25}}
                    title={
                      isCreatingNew
                        ? i18n.t('editCreatorGoalScreen.addGoalButtonTitle')
                        : i18n.t('saveSettings')
                    }
                    onPress={handleSubmit}
                  />
                  <ActionResult 
                    error={uploadError || updateError}
                    isLoading={uploadIsInProgress || updateIsInProgress}
                    {...ActionResultDefaultRenders}
                  />
                </View>
              </Row>
            )}
          </Formik>
        </ScrollView>
      </View>
    </ContentContainer>
  );
};
