
import {NetworkService} from '../services';
import {ApiRequest} from '../types';
import { 
    TPostUploadPostsTranslationsInput, 
    TPostUploadPostsTranslationsOutput,
    TPutUpdatePostsTranslationsInput,
    TPutUpdatePostsTranslationsOutput,
    TDeletePostsTranslationsInput,
    TDeletePostsTranslationsOutput,
    TGetPostsTranslationsInput,
    TGetPostsTranslationsOutput
} from './postsTranslations.types';

const getPostsTranslations = async (
  inputs: TGetPostsTranslationsInput
): ApiRequest<TGetPostsTranslationsOutput> => {
  return NetworkService.get(
    '/v1/posts-translations',
    inputs,
  );
}

const postUploadPostsTranslations = async (
  inputs: TPostUploadPostsTranslationsInput
): ApiRequest<TPostUploadPostsTranslationsOutput> => {
  console.log('send request')
  return NetworkService.post(
    '/v1/posts-translations',
    inputs,
  );
}

const putUpdatePostsTranslations = async (
  params: TPutUpdatePostsTranslationsInput,
): ApiRequest<TPutUpdatePostsTranslationsOutput> => {
  return NetworkService.put('/v1/posts-translations', params);
};

const deletePostsTranslations = async (
  params: TDeletePostsTranslationsInput,
): ApiRequest<TDeletePostsTranslationsOutput> => {
  return NetworkService.delete('/v1/posts-translations', params);
};


export const PoststranslationsService = {
    getPostsTranslations,
    postUploadPostsTranslations,
    putUpdatePostsTranslations,
    deletePostsTranslations,
};
  
  

