import {
  Button,
  CreatorBackgroundImage,
  FormInput,
  Image,
  Text,
  View,
  LanguagePicker,
  TFormInputInfo,
  FormInputLabel,
  renderFormInput,
  ContentContainer,
} from 'components';
import {Formik} from 'formik';
import React, {useCallback, useMemo, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {i18n} from 'localize';
import {selectCreatorBasicInfo, updateCreatorStart} from 'store';
import {getBasicSettingsCreatorSchema} from './basicSettingsValidationSchema';
import {colors} from '@sponsor/assets';
import {Platform, ScrollView, StyleSheet, TouchableOpacity} from 'react-native';
import {RichEditor} from '../../../../components';
import {decodeHTMLEntities} from 'utils';
import {isEmpty} from 'lodash';
import {useBeforeUnload} from '../../../../hooks/useBeforeUnload/useBeforeUnload';
import { ActionResult } from '../../../..//components/src/ActionResult/ActionResult.component';
import { Picker } from '../../../../components/src/Picker/Picker';
import { countries, EAlpha3Code } from '../../../../countries/countries.types';
import { useImagePicker } from '../../../..//hooks/useImagePicker';
import { EscreenNames } from 'navigation';
import { useNavigation } from 'hooks';

export const EditBasicCreatorSettings = () => {
  const creator = useSelector(selectCreatorBasicInfo);
  const dispatch = useDispatch();
  const {goBack, navigate} = useNavigation();

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  const {openPicker: pickAvatarImage, image:avatarImage, error:avatarError} = useImagePicker({
      initial: {uri: creator?.avatarUri},
      base64: true,
      aspect: [1,1],
      maxHeight: 2000,
      minHeight: 100,
      maxWidth: 2000,
      minWidth: 100,
  })

  const {openPicker: pickBackgroundImage, image:backgroundImage, error:backgroundImageError} = useImagePicker({
      initial: {uri: creator?.backgroundImageUri},
      quality: 1,
      base64: true,
      aspect: [4,3],
      maxHeight: 4000,
      minHeight: 400,
      maxWidth: 6000,
      minWidth: 600,
  })

  const updateCreator = useCallback(
    ({title, countryAplha3Code, subtitle, ...values}) => {
      if (!avatarImage?.uri) {
        return setError(i18n.t('errors.profileImageRequired'));
      }
      if (!backgroundImage?.uri) {
        return setError(i18n.t('errors.backgroundImageRequired'));
      }
      
      setIsLoading(true);
      dispatch(
        updateCreatorStart(
          {
            ...values,
            //todo: do not send always all data, only one was changed
            title: creator?.title !== title ? title : undefined,
            // todo: fix server validation for string.length = 0
            category: values?.category?.length > 0 ? values?.category : undefined,
            subtitle: creator?.subtitle !== subtitle ? subtitle : undefined,
            avatar:
              creator?.avatarUri !== avatarImage?.uri
                ? avatarImage?.uri
                : undefined,
            backgroundImage:
              creator?.backgroundImageUri !== backgroundImage?.uri
                ? backgroundImage?.uri
                : //we should update only when user changed
                  undefined,
            country: countryAplha3Code ? {
              alpha3Code: countryAplha3Code
            } : undefined
          },
          {
            onFail: (err: any) => {
              setError(err?.message);
            },
            onSuccess: () => {
              setError('');
              if(!creator?.id){
                setTimeout(goBack, 500)
              }
            },
            onFinish: () => {
              setIsLoading(false);
            },
          },
        ),
      );
    },
    [creator, avatarImage, backgroundImage, dispatch, navigate],
  );

  const initialValues = useMemo(
    () => ({
      title: creator?.title,
      subtitle: creator?.subtitle,
      description: decodeHTMLEntities(creator?.description || '') || '',
      pageURL: creator?.pageURL,
      videoURL: creator?.videoURL,
      lang: creator?.lang || 'uk',
      //TODO: find way easily add nested object keys to the form 
      countryAplha3Code: EAlpha3Code.UKR,
      category: creator?.category || "",
    }),
    [creator],
  );

  useBeforeUnload(true, i18n.t('unsavedChangesWarning'));

  const inputs:Partial<TFormInputInfo>[] = [
    {
      name: "category",
      type: 'dropdown',
      disabled: false,
      options: [
        {
          label: i18n.t("editCreatorPage.scienceCategoryLabel"),
          value: 'science',
        },
        {
          label: i18n.t("editCreatorPage.zcuCategoryLabel"),
          value: 'zcu',
        },
      ],
      requiredField:false,
      label: i18n.t("editCreatorPage.categoryInputLabel")
    }
  ]

  return (
    <ContentContainer 
      oneColumn
    >
      <ScrollView
      >
        <Formik
          initialValues={initialValues}
          // validateOnMount={false}
          //todo: investigate re-renders here
          validationSchema={getBasicSettingsCreatorSchema()}
          onSubmit={updateCreator}
        >
        {({
            values, 
            handleChange, 
            handleSubmit, 
            setTouched, 
            touched,
            isSubmitting,
            errors
        }) => (
            <View style={styles.container}>
              <FormInput
                label={`${i18n.t('editCreatorPage.titleLabel')}*`}
                value={values.title}
                testID="title-input"
                onChangeText={text => {
                  // todo: explain why we need it ???
                  setTouched({
                    title: true,
                  });
                  handleChange('title')(text);
                }}
                placeholder={`${i18n.t('editCreatorPage.titleInputPlaceholder')}`}
                error={(isSubmitting || touched.title) ? errors.title : " "}
              />
              <FormInput
                label={i18n.t('editCreatorPage.subtitleLabel')}
                value={values.subtitle}
                testID="subtitle-input"
                numberOfLines={2}
                placeholder={i18n.t('editCreatorPage.subtitlePlaceholder')}
                onChangeText={text => {
                  setTouched({
                    subtitle: true,
                  });
                  handleChange('subtitle')(text);
                }}
                error={(isSubmitting || touched.subtitle) ? errors.subtitle : " "}
              />

              <View>
                <FormInputLabel 
                  label={i18n.t('editCreatorPage.descriptionLabel')}
                  // requiredField // todo: make it required, and other rich editors
                />
                <RichEditor
                  html={values.description || ''}
                  onChange={(descriptionText: string) => {
                    handleChange('description')(descriptionText);
                  }}
                />
              </View>
              <Text variant="inputError">{errors?.description || " "}</Text>

              <FormInput
                label={`${i18n.t('editCreatorPage.pageURLLabel')}`}
                requiredField
                subLabel={`${i18n.t('editCreatorPage.pageURLSubLabel')}`}
                value={values.pageURL}
                // editable={!creator?.pageURL}
                onChangeText={(text) => {
                  setTouched({pageURL: true})
                  handleChange('pageURL')(text)
                }}
                placeholder={i18n.t('editCreatorPage.pageURLPlaceholder')}
                error={(isSubmitting || touched.pageURL) ? errors.pageURL : " "}
              />

              <View>
                <FormInputLabel 
                  label={`${i18n.t('editCreatorPage.countryLabel')}`}
                />
                <Picker
                  data={countries}
                  selectedValue={values?.countryAplha3Code}
                  // enabled={!creator?.lang}
                  onValueChange={(data) => {
                    setTouched({countryAplha3Code: true})
                    handleChange('countryAplha3Code')(data?.value)
                  }}
                />
              </View>
              <Text variant="inputLabel" color={colors.RED}>
                  {(isSubmitting || touched.countryAplha3Code) ? errors?.countryAplha3Code : ' '}
              </Text>
              {/* <Text>
                {i18n.t("")}
              </Text> */}

              <View variant='sectionContainer'>
                <FormInputLabel 
                  label={`${i18n.t('editCreatorPage.creatorMainLanguageLabel')}`}
                />
                <LanguagePicker
                  value={values?.lang}
                  // enabled={!creator?.lang}
                  onLanguageChange={lang => handleChange('lang')(String(lang))}
                />
              </View>
              <Text variant="inputLabel" color={colors.RED}>
                {errors?.lang || ' '}
              </Text>

              <View variant="sectionContainer">
                <Text variant="inputLabel">
                  {`${i18n.t('editCreatorPage.creatorProfileImageLabel')}*`}
                </Text>
                <Text variant="formHint">
                  {i18n.t('editCreatorPage.fiveMbLimit')}
                </Text>
                {avatarImage?.uri && (
                  <View variant="sectionContainer">
                    <Image
                      variant="profileImage"
                      source={{uri: avatarImage.uri}}
                    />
                  </View>
                )}
              </View>

              <Button
                title={i18n.t('pickPhoto')}
                onPress={pickAvatarImage}
                containerStyle={{paddingVertical: 25}}
              />
              <TouchableOpacity onPress={() => pickAvatarImage({allowsEditing: false})}> 
                <Text>+ gif</Text>
              </TouchableOpacity>

              <Text variant="inputError" color={colors.RED}>
                {avatarError}
              </Text>

              <View variant='sectionContainer'/>

              <Text variant="inputLabel">
                {`${i18n.t('editCreatorPage.backgroundImageLabel')}*`}
              </Text>
              <Text variant="formHint">
                {i18n.t('editCreatorPage.fiveMbLimit')}
              </Text>
              {backgroundImage?.uri && (
                <CreatorBackgroundImage
                  backgroundImage={{uri: backgroundImage?.uri}}
                />
              )}
              <Button
                title={i18n.t('pickPhoto')}
                onPress={pickBackgroundImage}
                containerStyle={{paddingVertical: 25}}
              />
              <Text variant="inputError" color={colors.RED}>
                {backgroundImageError}
              </Text>

              <FormInput
                label={i18n.t('editCreatorPage.videoURLLabel')}
                value={values.videoURL}
                onChangeText={(text) => {
                  setTouched({videoURL: true})
                  handleChange('videoURL')(text)
                }}
                placeholder={"https://"}
                error={errors.videoURL}
              />
              {inputs.map(item => renderFormInput({
                ...item, 
                value: values?.[item?.name as unknown as keyof typeof  values],
                handleChange: (data:any) => {
                  if(item?.name){
                    setTouched({[item?.name]: true})
                  }
                  return handleChange(item?.name)(data?.value)
                },
                error: errors?.[item?.name  as unknown as keyof typeof errors]
              }))}

              {!isEmpty(creator) && !creator?.verified && (<View variant='sectionContainer'>
                  <Text color={colors.RED} variant="inputError">
                    {i18n.t('editCreatorPage.notVerifiedAccountMessage', {email: "sponsor.financial@gmail.com"})}
                  </Text>
              </View>)}

              <View variant="sectionContainer">
                <Button
                  primary
                  // disabled={isEqual(values, {
                  //   title: creator.title,
                  //   description: creator.description,
                  // })}
                  title={i18n.t('saveSettings')}
                  isLoading={isLoading || false}
                  onPress={handleSubmit}
                />
              </View>

              <ActionResult 
                isLoading={isLoading}
                error={error}
                renderSuccessMessage={() =>
                  <Text variant="formHint">{i18n.t('operationSuccess')}</Text>
                }           
                renderError={(error) =>
                  <Text variant="inputError" color={colors.RED}>
                  {error}
                </Text>
                }       
                />
            </View>
          )}
        </Formik>
      </ScrollView>
    </ContentContainer>
  );
};

const styles = StyleSheet.create({
  container : {
    flex:1,
    padding: Platform.OS === 'web' ? 5 : 15,
  }
})
