import {NetworkService} from '../services';
import {ApiRequest} from '../types';
import {
  TGetPaymentLinkInput,
  TGetPaymentLinkOutput,
  TPostSubscriptionCancelingInput,
  TPostSubscriptionCancelingOutput,
} from './subscription.types';

export const postSubscriptionCancelingByToken = async (
  inputs: TPostSubscriptionCancelingInput,
): ApiRequest<TPostSubscriptionCancelingOutput> => {
  return NetworkService.post(
    '/v1/subscriptions/subscription-canceling',
    inputs,
  );
};

export const postGetPaymentLink = async (
  inputs: TGetPaymentLinkInput,
): ApiRequest<TGetPaymentLinkOutput> => {
  return NetworkService.post(
    '/v1/subscriptions/subscription-payment-link',
    inputs,
  );
};

export const SubscriptionApiService = {
  postSubscriptionCancelingByToken,
  postGetPaymentLink,
};
