import {NetworkService} from '../services';
import {ApiRequest} from '../types';
import {
  TPostUploadCreatorMerchantInput,
  TPostUploadCreatorMerchantOutput,
  TPutUpdateCreatorMerchantInput,
  TPutUpdateCreatorMerchantOutput,
  TDeleteCreatorMerchantInput,
  TDeleteCreatorMerchantOutput,
} from './creatorMerchant.types';

export const postUploadCreatorMerchant = async (
  inputs: TPostUploadCreatorMerchantInput,
): ApiRequest<TPostUploadCreatorMerchantOutput> => {
  return NetworkService.post(
    '/v1/creator/withdraw-methods/merchant_entreprenuer',
    inputs,
  );
};

export const patchUpdateCreatorMerchant = async (
  params: TPutUpdateCreatorMerchantInput,
): ApiRequest<TPutUpdateCreatorMerchantOutput> => {
  return NetworkService.patch(
    '/v1/creator/withdraw-methods/merchant_entreprenuer',
    params,
  );
};

export const deleteCreatorMerchant = async (
  params: TDeleteCreatorMerchantInput,
): ApiRequest<TDeleteCreatorMerchantOutput> => {
  return NetworkService.delete('/v1/', params);
};

export const CreatorMerchantApiService = {
  postUploadCreatorMerchant,
  patchUpdateCreatorMerchant,
  deleteCreatorMerchant,
};
