import {ActivityIndicator, TouchableOpacity} from 'react-native';
import React from 'react';
import {PaymentCardBase} from './PaymentCardBase';
import {Checkbox} from '../Checkbox';
import {Icon} from '../Icon';
import { Text } from '../Text';
import { ConfirmationDialog } from '../ConfirmationDialog';
import { i18n } from 'localize';

export const PaymentCard: React.FC<any> = ({
  onChoose,
  onDelete,
  isLoading,
  error,
  isMain,
  ...otherProps
}) => {
  return (
    <PaymentCardBase
      {...otherProps}
      Left={!!onChoose && <Checkbox value={isMain} onPress={onChoose} />}
      Right={
        <>
          {!!onDelete && !isLoading && (
            <ConfirmationDialog
              title={i18n.t("dialogs.deletionConfirmationDialogTitle")}
              desc={i18n.t("dialogs.confirmationDialogTitle")}
              onPress={onDelete}
              render={({onPress}) => (
                <TouchableOpacity onPress={onPress}>
                <Icon size={12} name="cancel" />
              </TouchableOpacity>
              )}
            />
          )}
          {isLoading && (
            <ActivityIndicator />
          )}
          {error && (
            <Text style={{color: 'red'}}>!</Text>
          )}
      </>
      }
    />
  );
};
