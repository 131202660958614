import {Dispatch} from 'react';
import {AxiosError} from 'axios';
import {isNull, split} from 'lodash';
import jwt_decode from 'jwt-decode';
import moment from 'moment';
import {Platform} from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';

import {ApiService, TConfigWithPerfMetrics} from 'services';
import {LOG} from 'utils';
import {KeychainService} from './KeychainService';
import {CrashlyticsService} from '.';
import {logoutStart} from 'store';
import {
  TPostRefreshToken,
  TPostLoginOutput,
  AxiosResponse,
} from '../../types/src';

export type TRefreshResult = TPostRefreshToken['data'];
export type TAuthorizeResult = TPostLoginOutput['data']['tokens'];

type TAuthParams = TAuthorizeResult;

//TODO: move to another service
interface IAuthStorage {
  setAuthParams: (authParams?: TAuthParams | null) => Promise<void>;
  getAuthParams: () => Promise<TAuthParams>;
}

export type TAxiosResponseWithPerfMetrics = AxiosResponse & {
  config: TConfigWithPerfMetrics;
};

export type TAxiosErrorWithPerfMetrics = AxiosError & {
  config: TConfigWithPerfMetrics;
};

export type TRefreshTokenFunction =
  | ((params: {refreshToken: string}) => Promise<TRefreshResult>)
  | null;

export interface INetworkAuthorizationServiceInterface {
  storeDispatch: Dispatch<any> | null;
  checkRequestAccessAndReturn: (request: any) => Promise<{
    requestConfig?: any;
    accessToken?: string | null;
  }>;
  checkResponseAndReturnNewRequest: (response: any) => Promise<{
    requestConfig?: any;
    accessToken?: string | null;
  }>;
  connectToStore: (dispatch: any) => void;
  getAuthParams: () => TAuthParams | null;
  setAuthParams: (authParams: TAuthParams | null) => Promise<void>;

  getKCAuthParams: () => Promise<TAuthorizeResult | null>;
}

class _NetworkAuthorizationService
  implements INetworkAuthorizationServiceInterface
{
  private authParams: null | TAuthParams = null;
  private authTokenRefreshPromise?: null | Promise<TRefreshResult> = null;
  private refreshToken: TRefreshTokenFunction = null;
  private AuthStorage: IAuthStorage;
  public storeDispatch: null | Dispatch<any> = null;

  constructor(storage: IAuthStorage, refreshToken: TRefreshTokenFunction) {
    this.AuthStorage = storage;
    this.refreshToken = refreshToken;
    this.init();
  }

  public connectToStore = (dispatch: Dispatch<any>): void => {
    this.storeDispatch = dispatch;
  };

  init = async () => {
    const initialAuthParams = await this.getKCAuthParams();
    if (initialAuthParams) {
      this.authParams = initialAuthParams;
    } else {
      this.storeDispatch?.(logoutStart());
    }
  };

  //TODO: move this method to separate service
  public refreshAuthToken = async (
    authParams: TAuthParams | null,
  ): Promise<string | null> => {
    if (!authParams) {
      // TODO: introduce custom Error class
      throw new Error(`Nothing to refresh, authParams is ${this.authParams}`);
    } else {
      let newAccessToken = null;
      try {
        if (this.authTokenRefreshPromise) {
          const newAuthParams = await this.authTokenRefreshPromise;
          newAccessToken = newAuthParams?.access?.token || null;
          this.setAuthParams(newAuthParams || null);
        } else {
          LOG('witohut authTokenRefreshPromise');
          this.authTokenRefreshPromise = this.refreshToken?.({
            refreshToken: authParams.refresh.token,
          });
          const newAuthParams = await this.authTokenRefreshPromise;
          newAccessToken = newAuthParams?.access.token || null;
          this.setAuthParams(newAuthParams || null);
        }
      } catch (err) {
        LOG(err);
      } finally {
        this.authTokenRefreshPromise = null;
        return newAccessToken;
      }
    }
  };

  public checkRequestAccessAndReturn = async (requestConfig: any) => {
    const accessToken = split(
      requestConfig.headers?.Authorization,
      'Bearer ',
    )[1];
    let newAccessToken;
    try {
      if (accessToken) {
        const decoded: {iat: number} = jwt_decode(accessToken);
        if (decoded?.iat > moment.utc().valueOf()) {
          newAccessToken = await this.refreshAuthToken?.(this.authParams);
          requestConfig = {
            ...requestConfig,
            headers: {
              ...requestConfig.headers,
              Authorization: `Bearer ${newAccessToken}`,
            },
          };
        }
      }
    } catch (err) {
      //todo: delete and replace by callbacks
      this.storeDispatch?.(logoutStart());
    }
    return {
      requestConfig,
      accessToken: newAccessToken,
    };
  };

  checkResponseAndReturnNewRequest = async (response: any) => {
    const originalRequestConfig = response?.config;
    let newRequestConfig = null;
    let newAccessToken = null;
    if (
      response?.data?.code === 401 ||
      response?.data?.errorCode === 401 ||
      response?.response?.status === 401
    ) {
      if (response.config._retry) {
        this.storeDispatch?.(logoutStart());
      } else {
        if (!this.authParams) {
          this.storeDispatch?.(logoutStart());
        } else {
          try {
            LOG('token is expired, errro code 401');
            newAccessToken = await this.refreshAuthToken(this.authParams);
            //TODO: move retry to network service
            newRequestConfig = {
              ...originalRequestConfig,
              _retry: true,
              headers: {
                ...originalRequestConfig.headers,
                Authorization: `Bearer ${newAccessToken}`,
              },
            };
          } catch (error) {
            LOG('error interceptors.response during refreshing', error);
            this.storeDispatch?.(logoutStart());
            CrashlyticsService.recordError?.(error);
          }
        }
      }
    } else {
      newRequestConfig = null;
    }
    return {
      requestConfig: newRequestConfig,
      accessToken: newAccessToken,
    };
  };

  public getKCAuthParams = async (): Promise<TAuthParams | null> => {
    try {
      const authParams: TAuthParams = await this.AuthStorage?.getAuthParams?.();
      if (!authParams) {
        //TODO: refactor it
        this.storeDispatch?.(logoutStart());
        return null;
      }
      this.authParams = authParams;
      // todo: check old Keychain data for auth, whether it has the rest of params?
      return authParams;
    } catch (error) {
      LOG('error getKCAuthParams', error);
      // TODO: to add analytics
      return null;
    }
  };

  public getAuthParams = (): null | TAuthParams => this.authParams;

  public setAuthParams = async (
    authParams: TAuthorizeResult | TRefreshResult | null,
  ): Promise<void> => {
    try {
      // if cases of logout
      if (isNull(authParams)) {
        await this.AuthStorage.setAuthParams(null);
        this.authParams = null;
      } else {
        const nextAuthParams = authParams;
        await this.AuthStorage.setAuthParams(nextAuthParams);
        this.authParams = nextAuthParams;
      }
    } catch (error) {
      LOG('error setAuthParams', error);
      // TODO: to add analytics
    }
  };
}

export const NetworkAuthorizationService = Platform.select({
  web: new _NetworkAuthorizationService(
    {
      setAuthParams: async params => {
        AsyncStorage.setItem('authParams', JSON.stringify(params));
      },
      getAuthParams: async () => {
        const authParamsString = await AsyncStorage.getItem('authParams');
        return JSON.parse(authParamsString || '');
      },
    },
    async ({refreshToken}) => {
      const {data} = await ApiService.postRefreshToken(refreshToken);
      return data;
    },
  ),
  native: new _NetworkAuthorizationService(
    {
      setAuthParams: async params => {
        await KeychainService.setItem?.('authParams',params);
      },
      getAuthParams: async () => {
        const res = await KeychainService.getItem('authParams');
        return res;
      },
    },
    async ({refreshToken}) => {
      const {data} = await ApiService.postRefreshToken(refreshToken);
      return data;
    },
  ),
  default: new _NetworkAuthorizationService(
    {
      setAuthParams: async params => {
        console.log('default setAuthParams', params)
        await KeychainService.setItem?.('authParams', params);
      },
      getAuthParams: async () => {
        const res = await KeychainService.getItem?.('authParams');
        return res;
      },
    },
    async ({refreshToken}) => {
      const {data} = await ApiService.postRefreshToken(refreshToken);
      return data;
    },
  ),
});
