import {createSelector} from '@reduxjs/toolkit';
import {find, values} from 'lodash';
import {getStore, TStore} from 'store';

export const selectTierTranslationById = createSelector(
  [
    getStore,
    (_: TStore, id?: string) => id,
    (__: TStore, _, parentId?: string) => parentId,
  ],
  (store, id, parentId) =>
    find(
      store.tiers[parentId || '']?.translations,
      translation => translation?.id === id,
    ),
);

export const selectTierTranslations = createSelector(
  [getStore, (_: TStore, parentId: string) => parentId],
  (store, parentId) => values(store.tiers?.[parentId]?.translations),
);
