import {Platform, StyleSheet, View} from 'react-native';
import React from 'react';
import {FormInput, Icon, Row, viewStyles} from 'components';
import {i18n} from 'localize';
import { TouchableOpacity } from 'react-native-gesture-handler';
import { colors } from '@sponsor/assets';

type TProps = {
  submitComment: () => void;
  commentText: string;
  handleCommentTextChange: (text: string) => void;
};

export const PostCommentForm: React.FC<TProps> = ({
  commentText,
  handleCommentTextChange,
  submitComment,
}) => {

  const getInputStyles = ({commentText}: Pick<TProps, 'commentText'>) => {
    const borderColorGradientMap = {
      [500]: '#2EB62C',
      250: '#57C84D',
      100: '#83D475',
      50: '#ABE098'
    };

    const borderColor = Object
      .entries(borderColorGradientMap)
      .map(([k,v]) => ([parseInt(k), v]))
      .find(([length, color]) => commentText?.length > length)?.[0] || borderColorGradientMap['100']

    return {
      borderColor: String(borderColor),
      borderWidth: 2
    }
  }

  return (
    // todo: make search box
    //todo: add constant for input minHeight
    <Row 
      containerStyle={StyleSheet.flatten({
        // height: 100, 
        flex:1,
        alignItems: 'flex-end',
        ...getInputStyles({commentText}), 
        ...viewStyles.bordered,
      })}
    >
      <View style={{
        flex: 1, 
        // borderWidth: 4,
        marginBottom: Platform.OS === 'web' ? -30 : -20, // TODO: refactor
      }}
      >
        <FormInput
          value={commentText}
          inputStyles={{
            fontSize: 16,
            //@ts-ignore
            outline: 'none'
          }}
          multiline
          inputContainerStyle={{
            borderWidth: 0,
            minHeight: 20,
          }}
          //todo: think about comment length
          maxLength={280}
          onChangeText={handleCommentTextChange}
          placeholder={i18n.t('postsScreen.writeYourComment')}
        />
      </View>
      {/* <View style={{alignItems:'flex-end', justifyContent: 'center', paddingHorizontal: 0}}> */}
      <TouchableOpacity 
        style={{paddingBottom: 12}} 
        disabled={!commentText} 
        onPress={submitComment}
      >
        <Icon name="send" size={20} color={!!commentText ? colors.GREEN_OFFICIAL : colors.DUSTY_GRAY}/>
      </TouchableOpacity>
    </Row>
  );
};
