import React from 'react';
import {i18n} from 'localize';
import {TTier} from 'types';
import {Button} from '../Button/Button';
import {TierBase} from './TierBase';
import { View } from '../View';
import { useMedia } from '../../../hooks/useMedia';

type TProps = {
  showEdit?: boolean;
  onEdit: () => void;
  testID?: string;
  first?: boolean;
};

export const TierEditing: React.FC<TTier & TProps> = ({
  onEdit,
  ...otherProps
}) => {
  
  const media = useMedia()

  return (
    <TierBase 
      // containerStyle={{width:/ media.sm ? DEVICE_WIDTH - 25 : undefined}}
      {...otherProps}
    >
      <View
        style={{alignItems:'center', flex:1}}
      >
        <Button
          onPress={onEdit}
          testID="edit-tier-button"
          containerStyle={{width: '100%', marginVertical: 15}}
          title={i18n.t('edit')}
        />
      </View>
    </TierBase>
  );
};
