import {createSelector, createSlice} from '@reduxjs/toolkit';
import {takeEvery, call, put} from 'redux-saga/effects';
import {TGetCreatorOutput} from 'types';

import {LOG} from 'utils';
import {getStore} from './common';

import {ApiService} from 'services';

export const creatorStatsSlice = createSlice({
  name: 'creatorStats',
  initialState: {},
  reducers: {
    getCreatorStats: state => state,
    getCreatorStatsSuccess: (state, action) => {
      return action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {getCreatorStatsSuccess, getCreatorStats} =
  creatorStatsSlice.actions;

export default creatorStatsSlice.reducer;

// selectors
export const selectCreatorStats = createSelector(
  getStore,
  state => state.creatorStats,
);

function* getCreatorStatsSaga() {
  try {
    //todo: change type
    const {data: creatorStats}: TGetCreatorOutput = yield call(
      ApiService.getCreatorStats,
    );

    yield put(getCreatorStatsSuccess(creatorStats));
  } catch (err) {
    LOG('error during updating creator', err);
  }
}

export function* creatorStatsFlow() {
  yield takeEvery(getCreatorStats, getCreatorStatsSaga);
}
