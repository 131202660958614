import {StyleSheet, TouchableOpacity} from 'react-native';
import React from 'react';
import {PaymentCardBase} from '../components/src/PaymentCard/PaymentCardBase';
import {ICreatorMerchantWithdrawMethod} from './creatorMerchant.types';
import {i18n} from 'localize';
import {Icon, Text} from 'components';

export const MerchantCard: React.FC<
  ICreatorMerchantWithdrawMethod & {
    onFinancialConfigPress?: () => void;
    onEdit?: () => void
  }
> = ({
  country,
  currency,
  financialConfig,
  onFinancialConfigPress,
  merchantId,
  onEdit,
  status,
}) => {

  return (
    <PaymentCardBase 
      title={`${merchantId} ${currency?.name} ${country || ''}`}
      Right={
        onEdit && (<TouchableOpacity onPress={onEdit}>
          <Icon name="edit"/>
        </TouchableOpacity>)
      }
    >
        {
        !financialConfig?.iban && (
          <TouchableOpacity
            style={styles.financialConfigContainer}
            onPress={onFinancialConfigPress}>
            <Text variant="inputError">
              {i18n.t('creatorMerchant.merchantFinancialNotSet')}
            </Text>
          </TouchableOpacity>
        )}

      {
        financialConfig?.iban  && 
        status === 'not_active' && (
          <TouchableOpacity
            style={styles.financialConfigContainer}
            onPress={onFinancialConfigPress}
          >
            <Text style={{flex:1, flexWrap: 'wrap'}} variant='thirdTitle'>
              {i18n.t('editWithdrawMethodScreen.activateYourCompanyShortMessage')}
            </Text>
          </TouchableOpacity>
      )}
    </PaymentCardBase>
  );
};

const styles = StyleSheet.create({
  financialConfigContainer: {
    marginTop: 10,
  },
});
