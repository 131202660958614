import {TProfile} from '.';
import { ICurrency } from '../../payment/payments.types';
import {ITierTranslation} from '../../tiersTranslations/tiersTranslations.types';

export enum TTierStatus {
  'published' = 'published',
  'waiting_acceptance' = 'waiting_acceptance',
  'expired' = 'expired',
  'cancelled' = 'cancelled',
  'declined' = 'declined',
}

export enum TCollaborationAcceptanceStatus {
  'accepted' = 'accepted',
  'declined' = 'declined',
  'missed' = 'missed',
  'cancelled' = 'cancelled',
}

export type TCoCreator = {
  acceptance_status?: TCollaborationAcceptanceStatus;
  creator: string;
  creatorPageURL: string;
  creatorAvatarUri: string;
  creatorTitle: string;
};

export type TTierInResponse = {
  id: string;
  title: string;
  price: number;
  currency: ICurrency;
  description: string;
  imageUri: string;
  creatorAvatarUri: string;
  creatorPageURL: string;
  creatorTitle: string;
  creator: string;
  subscribersLimit?: number;

  ideaOwner?: Pick<TProfile, 'name' | 'last_name' | 'avatarUri' | 'id'>;
  status: TTierStatus;
  coCreators?: TCoCreator[];

  translations: {
    [key in string]: ITierTranslation;
  };
};

export type TTier = {
  id: string;
  title: string;
  price: number;
  currency: ICurrency;
  description: string;
  imageUri: string;
  creatorAvatarUri: string;
  creatorPageURL: string;
  creatorTitle: string;
  creator: string;
  subscribersLimit?: number;

  ideaOwner?: Pick<TProfile, 'name' | 'last_name' | 'avatarUri' | 'id'>;
  status: TTierStatus;
  coCreators?: TCoCreator[];

  translations: {
    [lang in string]: ITierTranslation;
  };
};
