import {View} from 'react-native';
import React from 'react';
import {i18n} from 'localize';
import {Text} from 'components';

export const UnathorizedSubscriptionSuccessInfo = () => {
  return (
    <View>
      <Text variant="secondaryTitle" center>
        {i18n.t('unathorizedSubscription.successMessage')}
      </Text>
    </View>
  );
};
