import {FlatList, FlatListProps, Platform, StyleSheet} from 'react-native';
import React from 'react';
import {Text, View} from 'components';
import {
  DEVICE_WIDTH,
  TIERS_CONTAINER_MARGIN_LEFT,
  TIER_SEPARATOR_WIDTH,
  TIER_WIDTH,
} from '@sponsor/constants';
import {TTier} from 'types';

export const TiersList: React.FC<
  FlatListProps<TTier> & {
    title?: string;
    subTitle?: string
  }
> = ({data = [], subTitle, title, renderItem}) => {
  const dataLength = data?.length || 0;
  const tierMinWidth =
    dataLength * TIER_WIDTH + dataLength * TIER_SEPARATOR_WIDTH;
  //todo: add tests here
  const tiersWidth =
    dataLength * TIER_WIDTH + dataLength * TIER_SEPARATOR_WIDTH;

  return (
    <View
      style={[
        styles.tierContainer,
      ]}
    >
      {!!title && (
        <Text center variant="thirdTitle">
          {title}
        </Text>
      )}
      {!!subTitle && (
        <View style={{paddingVertical: 10}}>
          <Text variant='inputLabel' center >
            {subTitle}
          </Text>
        </View>
      )}
      <View
        //todo: comment this block, why we need margin ??? create method for creating styles
        style={[
          // {
          //   marginLeft:
          //   Platform.OS == 'web'
          //   ? tiersWidth > DEVICE_WIDTH 
          //     ? TIERS_CONTAINER_MARGIN_LEFT 
          //     : 0
          //   : 0,
          // },
        ]}
      >
      <FlatList
        renderItem={renderItem}
        horizontal
        accessible
        showsHorizontalScrollIndicator
        pagingEnabled
        accessibilityLabel="Creator's paid collaborations"
        centerContent={true}
        contentContainerStyle={[
          // styles.tiersContentContainer,
          {
            minWidth: tierMinWidth,
          },
        ]}
        data={data}
        indicatorStyle="black"
        keyExtractor={item => item?.id}
        // ItemSeparatorComponent={() => <View style={{width: 10}} />}
        // data={collaborationTiers}
        // horizontal
        // showsHorizontalScrollIndicator
        // indicatorStyle="black"
        // pagingEnabled
        // centerContent
        // contentContainerStyle={[
        //   styles.tiersContentContainer,
        //   {
        //     minWidth:tierMinWidth
        //   },
        // ]}
        // ItemSeparatorComponent={() => (
        //   <View style={{width: TIER_SEPARATOR_WIDTH}} />
        // )}
        // renderItem={({item}) => (
        //   <Tier btnColor={buttonBackgroundColor} {...item} />
        // )}
      />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  tierContainer: Platform.select({
    web: {
      paddingTop: 25,
      paddingBottom: 25,
    },
    default: {
      // marginRight: 10,
      // marginLeft: Platform.OS === 'web' ? TIERS_CONTAINER_MARGIN_LEFT : 0,
    },
  }),
  tiersContentContainer: {
    outline: 'none',
    justifyContent: Platform.OS === 'web' ? 'center' : 'flex-start',
    flex: 1,
    // maxWidth: DEVICE_WIDTH - TIERS_CONTAINER_MARGIN_LEFT,
    // justifyContent:'center',
  },
});
