import React from 'react';
import {View, FlatList} from 'react-native';
import {useSelector} from 'react-redux';
import {TierBase} from 'components';
import {selectDeletedButActiveSubscriptions} from 'store';
import { TouchableOpacity } from 'react-native-gesture-handler';
import { useNavigation } from 'hooks';
import { EscreenNames } from 'navigation';

export const DeletedButActiveSubscriptions = () => {
  const deletedButActiveSubscriptions = useSelector(
    selectDeletedButActiveSubscriptions,
  );

  const { navigate } = useNavigation();

  return (
    <View style={{flex: 1, padding: 25, alignItems: 'center'}}>
      <FlatList
        data={deletedButActiveSubscriptions}
        contentContainerStyle={{flexDirection: 'row', flexWrap: 'wrap'}}
        // ListEmptyComponent={
        //   <Text center variant="secondaryTitle">
        //     {i18n.t('activeSubscriptions.noSubscriptions')}
        //   </Text>
        // }
        renderItem={({item}) => (
            <TouchableOpacity 
              style={{margin: 5}}
              onPress={() => navigate(EscreenNames.TierBuy, {tierId: item.tier?.id})}
            >
              <TierBase 
                // TODO: add information about creator 
                {...item.tier}
                // TODO: remove additional prop - price or rename, because of confusing
                price={item.price}
              />
            </TouchableOpacity>
        )}
      />
    </View>
  );
};
