import React, { useCallback } from 'react';
import {useNavigation, useParams} from 'hooks';
import {useDispatchAction} from 'hooks';
import {
  Button,
  ContentContainer,
  FormInput,
  FormInputLabel,
  LanguagePicker,
  RichEditor,
  ScrollViewOnlyOnMobile,
  Text,
  View,
} from 'components';
import {i18n} from 'localize';
import {Formik} from 'formik';
import {useSelector} from 'react-redux';
import {selectPostById, TStore} from 'store';
import { decodeHTMLEntities, isMobile } from 'utils';
import { BackHeader } from '../containers/Header/BackHeader';
import { Platform } from 'react-native';
import { translationsItems } from './translations.contants';
import { TGoalTranslation } from 'app/goalsTranslations/goalsTranslations.types';
import { useTranslationsLanguages } from './useTranslationsLanguages';

export const EditTranslationsScreen = () => {
  const {id, parentId, itemName} = useParams();

  const {goBack} = useNavigation();
  const translationsLanguages = useTranslationsLanguages()
  const {
      isLoading, 
      error, 
      submitAction: submitTranslationUploading
  } = useDispatchAction(
      translationsItems[itemName].uploadActionCreator,
      {
        onSuccess: goBack
      }
  );

  const {
      isLoading: isPostUpdating, 
      error: postUpdateError, 
      submitAction: submitTranslationUpdating
  } = useDispatchAction(
    translationsItems[itemName].updateActionCreator,
    {
        onSuccess: goBack
      }
  );

  //todo: add general naming, such as editing entity
  const editedEntity = useSelector((state: TStore) =>
    translationsItems[itemName].getOneSelector<TGoalTranslation>(state, id, parentId),
  );
  const parentItem = useSelector((state:TStore) => selectPostById(state, parentId))

  const itemKeys = translationsItems[itemName].getItemKeys()
  
  const initialValues = itemKeys.reduce((s, i) => {
    //@ts-ignore
    s[i.fieldName] = editedEntity?.[i.fieldName]
    return s;
  },{} as {[key in string]: string})

  const renderInputField = useCallback((
    {fieldName, handleChange, requiredField, type, value, error}: 
    {type: string, fieldName: string, requiredField?:boolean, handleChange: (text: string) => void, value: any, error: string}) => {
    if(type === 'richText'){  
        return (
          <>
          <FormInputLabel
            label={i18n.t(fieldName, {scope: 'translationsScreen.labels'})}
            requiredField={requiredField}
          />
            <RichEditor
              html={decodeHTMLEntities(value)}
              onChange={(descriptionText: string) => {
                handleChange(descriptionText);
              }}
            />
          <Text variant="inputError">{error}</Text>
        </>
        )
    }
    return (
      <FormInput
        requiredField
        label={i18n.t(fieldName, {scope: 'translationsScreen.labels'})}
        value={value}
        numberOfLines={2}
        onChangeText={handleChange}
        error={error}
      />
    )
  },[])

  return (
    <ContentContainer>
      <ScrollViewOnlyOnMobile>
        {!isMobile && <BackHeader />}
        <Formik
          enableReinitialize
          initialValues={{
            ...initialValues,
            lang: editedEntity?.lang || "uk",
            parentId
          }}
          // validationSchema={getTierTranslationValidationSchema()}
          onSubmit={(values) => {
            if(editedEntity?.id){
              submitTranslationUpdating(values)
              return;
            }
            // console.log('upload translation')
            submitTranslationUploading(values)
          }}
      >
          {({values, handleChange, handleSubmit, errors}) => (
            <View style={{padding: 15}}>
              <View 
                variant="sectionContainer"
              >
                {/* primary keys cannot be changed in already created entity
                            that's why we disable language picker

                            todo: add helper function, hook,
                                think about unificaiton
                        */}
                {/* TODO: center or flex-start */}
                <Text center variant='thirdTitle'>
                  {parentItem?.title}
                </Text>
                <View style={{marginTop: 15,alignItems: 'center'}}>
                  <FormInputLabel
                    label={i18n.t('chooseLanguagePickerLabel')} 
                    requiredField
                  />
                  {/* todo: prevent in future bugs; picker has some value, but callback wasn't fired on mount */}
                  <LanguagePicker
                    value={values?.lang}
                    // todo: add test here
                    enabled={!editedEntity}
                    langs={translationsLanguages}
                    onLanguageChange={lang => handleChange('lang')(String(lang))}
                  />
                </View>
              </View>
              {!!values?.lang && (
                <>
                  {itemKeys.map(k => renderInputField({
                      ...k, 
                      handleChange: handleChange(k.fieldName), 
                      value: values[k.fieldName as unknown as keyof typeof values],
                      // todo: investigate why errors could be array of string
                      error: JSON.stringify(errors[k.fieldName as unknown as keyof typeof errors]) || ""
                    })
                  )}
                  <Button
                    primary
                    disabled={isLoading || isPostUpdating}
                    isLoading={isLoading || isPostUpdating}
                    title={i18n.t('saveSettings')}
                    onPress={handleSubmit}
                  />
                  {error && <Text variant="inputError">{error || postUpdateError}</Text>}
                </>
              )}

            </View>
          )}
        </Formik>
      </ScrollViewOnlyOnMobile>
    </ContentContainer>
  );
};
