import React, {useCallback} from 'react';
import {PaymentCard} from './PaymentCard';
import {useDispatch, useSelector} from 'react-redux';
import {selectCardInfoById} from '../../../money-withdraw/money-withdraw.selectors';
import {TStore} from 'store';
import { ECreatorWithdrawMethodsTypes } from '../../../money-withdraw-methods/money-withdraw-methods.types';
import { deleteWithdrawMethods } from '../../../../app/money-withdraw-methods/money-withdraw-methods.actions';

type TProps = {
  id: string;
};

export const PaymentCardContainer: React.FC<TProps> = ({id}) => {
  const dispatch = useDispatch();
  // const withdrawMethod = useSelector((state:TStore) => selectCardInfoById(state, id));
  const cardInfo = useSelector((state: TStore) =>
    selectCardInfoById(state, id),
  );

  const onDelete = useCallback(() => {
    dispatch(deleteWithdrawMethods({id}));
  }, [dispatch, id]);

  // const onChoose = useCallback(() => {
  //   dispatch(
  //     updateCreatorStart(
  //       {
  //         activePaymentMethodInfo: {
  //           type: 'card',
  //           id,
  //         },
  //       },
  //       {
  //         onFail: () => {},
  //       },
  //     ),
  //   );
  // }, [id, dispatch]);

  return (
    <PaymentCard
      title={cardInfo?.type === ECreatorWithdrawMethodsTypes.card ? `${cardInfo?.cardMask}` : ""}
      subtitle={cardInfo?.type === ECreatorWithdrawMethodsTypes.card ? `${cardInfo?.currency?.name} ${cardInfo?.cardType}`: ""}
      onDelete={onDelete}
      // onChoose={onChoose}
      // isMain={
      //   creator.activePaymentMethodInfo?.type == 'card' &&
      //   creator.activePaymentMethodInfo?.id === id
      // }
    />
  );
};
