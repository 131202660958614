export const translationsLocalizations = {
    'en': {
        translationsScreen: {
            screenTitle: "Translations: %{itemName}",
            itemNames: {
                goals: "Goals",
            },
            labels: {
                description: 'Description'
            }
        },
        translationsFeature: {
            postHasTranslationsMessage: "Post has such translations: %{languages}",
            postHasTranslationsTitle: "Translations",
            
            entityHasTranslationsMessage: "%{entityName} has such translations: %{languages}",
            enittyHasTranslationsTitle: "Translations",

            entities: {
                creator: 'Creator'
            }
        },
        editTranslationsScreen: {
            screenTitle: "Edit translation"
        }
    },
    'uk': {
        translationsScreen: {
            screenTitle: "Переклади %{itemName}",
            itemNames: {
                goals: "Цілі",
            },
            labels: {
                description: 'Опис'
            }
        },
        translationsFeature: {
            postHasTranslationsMessage: "Пост має такі переклади: %{languages}",
            postHasTranslationsTitle: "Переклади",

            entityHasTranslationsMessage: "%{entityName} має такі переклади: %{languages}",
            enittyHasTranslationsTitle: "Переклади",

            entities: {
                creator: 'Автор'
            }

        },
        editTranslationsScreen: {
            screenTitle: "Редагування перекладу"
        }
    }
}