
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { deletePostTranslationSuccess, getPostTranslationsSuccess, updatePostTranslationSuccess, uploadPostTranslationSuccess } from './posts-translations.actions';
import { TDeletePostsTranslationsInput, TGetPostsTranslationsOutput, TPostsTranslationsState, TPostUploadPostsTranslationsOutput, TPutUpdatePostsTranslationsOutput } from './postsTranslations.types';

const initialState:TPostsTranslationsState = {
    results: {
        
    }
}

export const postsTranslationsSlice = createSlice({
    name: 'postsTranslations',
    initialState,
    extraReducers: {    
        [getPostTranslationsSuccess.toString()]: (
            state: TPostsTranslationsState,
            action: PayloadAction<TGetPostsTranslationsOutput['data']>
        ) => {
            for(let i of action?.payload){
                state.results[i?.id] = {
                    ...i
                }
            }
        },
        [uploadPostTranslationSuccess.toString()]: (
            state: TPostsTranslationsState,
            action: PayloadAction<TPostUploadPostsTranslationsOutput['data']>
        ) => {
            state.results[action?.payload?.id] = {
                ...action?.payload
            }
        },
        [updatePostTranslationSuccess.toString()]: (
            state: TPostsTranslationsState,
            action: PayloadAction<TPutUpdatePostsTranslationsOutput['data']>
        ) => {
            state.results[action?.payload?.id] = {
                ...state?.results[action?.payload?.id],
                ...action?.payload
            }
        },
        [deletePostTranslationSuccess.toString()]: (
            state: TPostsTranslationsState,
            action: PayloadAction<TDeletePostsTranslationsInput>
        ) => {
            delete state.results[action?.payload?.id]
        },
    },
    reducers: {},
});

export const postsTranslationsReducer  = postsTranslationsSlice.reducer
  
  

