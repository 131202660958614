import {EscreenNames} from 'navigation';
import React, {useCallback} from 'react';
import {View, StyleSheet} from 'react-native';
import {TouchableOpacity} from 'react-native';
import {useDispatch} from 'react-redux';
import {colors} from '@sponsor/assets';
import {useNavigation} from 'hooks';
import {i18n} from 'localize';
import {Text, Row} from 'components';
import {deleteGoal} from 'store';
import {TCreatorGoal, TCreatorGoalType} from '../../../types/src/creators';
import { ICurrency } from 'app/payment/payments.types';
import { TranslationsMenuOptions } from '../../../translations/TranslationsMenuOptions.component';
import { ERegisteredTranslations, TranslationMenuOptionsIds } from '../../../translations/translations.contants';
import { ConfirmationDialog } from '../ConfirmationDialog';

export const CreatorGoalEditPreview: React.FC<
  TCreatorGoal & {currency: ICurrency}
> = ({id, count, currency, title, description, type}) => {
  const {navigate} = useNavigation();
  const dispatch = useDispatch();
  const edit = useCallback(() => {
    navigate(EscreenNames.CreatorGoalEdit, {id});
  }, [id, navigate]);

  //todo: add useDispatchAction
  const remove = useCallback(() => {
    dispatch(deleteGoal({id}, {}));
  }, [dispatch, id]);

  const editTranslation = useCallback(() => {
    navigate(EscreenNames.EditTranslationsScreen, {
      itemName: ERegisteredTranslations.goals, 
      parentId: id
    })
  }, [navigate, id]);

  const seeAllTranslations = useCallback(() => {
    // dispatch(deleteGoal({id}, {}));
    navigate(EscreenNames.TranslationsScreen, {itemName: ERegisteredTranslations.goals, parentId: id})
  }, [navigate, id]);

  return (
    <View style={styles.container}>
      <Row containsSimilarItems flexWrap containerStyle={{paddingBottom: 10}}>
        <ConfirmationDialog
              title={i18n.t("dialogs.deletionConfirmationDialogTitle")}
              desc={i18n.t("dialogs.confirmationDialogTitle")}
              onPress={remove}
              render={({onPress}) => (
                <TouchableOpacity onPress={onPress}>
                  <Text variant='dangerous'>{i18n.t('delete')}</Text>
              </TouchableOpacity>
            )}
        />
        <TouchableOpacity onPress={edit}>
          <Text>{i18n.t('edit')}</Text>
        </TouchableOpacity>

        {/* todo: remove such unnecessary rows */}
        <TranslationsMenuOptions 
          onPressMap={{
            [TranslationMenuOptionsIds.addTranslation]: editTranslation,
            [TranslationMenuOptionsIds.seeAllTranslations]: seeAllTranslations 
          }}
        />

      </Row>
      {/* {!isEmpty(image) && <Image source={{uri: image}} variant="postPreview" />} */}
      <Text  variant='previewText' bold>{title}</Text>
      <Text variant='previewText'>{description}</Text>
      <Text variant='previewText' bold>
        {type == TCreatorGoalType.subscribers
          ? `${i18n.t('editCreatorGoals.goalForSubscribersLabel')}${count}`
          : `${i18n.t('editCreatorGoals.goalForEarningsLabel')}${count} ${
              currency?.name || 'UAH'
            }`}
      </Text>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    borderWidth: 1,
    borderColor: colors.DUSTY_GRAY,
    padding: 15,
  },
});
