import {
  View,
  FormInput,
  Row,
  Image,
  Button,
  Text,
  CreatorCard,
  ContentContainer,
  TFormInputInfo,
  renderFormInput,
  FormInputContainer,
} from 'components';
import {Formik} from 'formik';
import React, {useState, useCallback, useMemo} from 'react';
import {ScrollView, StyleSheet} from 'react-native';
import {useSelector} from 'react-redux';
import {filter, find, isEmpty, map, toUpper, union} from 'lodash';
import {EscreenNames} from 'navigation';
import {FlatList, TouchableOpacity} from 'react-native';
import {i18n} from 'localize';
import {alertMessage, decodeHTMLEntities} from 'utils';
import {
  TTier,
  TCreator,
  TCoCreator,
  TCollaborationAcceptanceStatus,
} from 'types';
import {
  deleteTier,
  updateTier,
  uploadTier,
  selectTierById,
  selectCreatorBasicInfo,
  updateCollaborationStatus,
} from 'store';
import {colors} from '@sponsor/assets';
import {useDispatchAction, useNavigation, useParams} from 'hooks';
import {TTierStatus} from 'types';
import {TStore, selectFoundCreators, searchCreators} from 'store';
import {createTierSchema} from '.';
import {useBeforeUnload} from '../../../hooks/useBeforeUnload/useBeforeUnload';
import {allPaymentCurrencies} from '../../../payment/payment.constants';
import { useImagePicker } from '../../..//hooks/useImagePicker';
import { ActionResult } from '../../../components/src/ActionResult/ActionResult.component';
import { ConfirmationDialog } from 'components/src/ConfirmationDialog';
import { ActionResultDefaultRenders } from '../../../components/src/ActionResult/ActionResultDefaultRenders';

/**
 * this screen render form to create new tier
 * independent on who is creating it
 */
export const EditTierPage = () => {
  const {id: tierId} = useParams();
  const selectedTier = useSelector((state: TStore) =>
    selectTierById(state, tierId || ''),
  );
  const creatorInfo = useSelector(selectCreatorBasicInfo);
  const foundCreators = useSelector(selectFoundCreators);
  // const searchResultIsNotEmpty = useMemo(() => {
  //   return !isEmpty(foundCreators);
  // }, [foundCreators]);

  const {navigate} = useNavigation();
  const [coCreators, setCoCreators] = useState<TCoCreator[]>(
    selectedTier?.coCreators || [],
  );
  const [searchQuery, setSearchQuery] = useState('');

  useBeforeUnload(true, i18n.t('unsavedChangesWarning'));

  const {openPicker, image, error:imageError} = useImagePicker({
    initial: {uri: selectedTier?.imageUri},
    base64: true,
    aspect: [1,1],
    maxHeight: 2000,
    minHeight: 100,
    maxWidth: 2000,
    minWidth: 100,
  })

  const {
    submitAction: dispatchSearchCreators,
    isLoading: isSearchLoading,
    error: searchError
  } = useDispatchAction(searchCreators, {
    data: {
      query: searchQuery,
    }
  })

  const {
    submitAction: addTier,
    isLoading: isCreateLoading,
    error: createTierError
  } = useDispatchAction(uploadTier, {
    onSuccess: () => {
      navigate(EscreenNames.CreatorTiersEdit);
    },
  })

  const {
    submitAction: handleUpdate,
    isLoading: isUpdateLoading,
    error: updateError,
  } = useDispatchAction(updateTier, {
    onSuccess: () => navigate(EscreenNames.CreatorTiersEdit),
  })

  const {
    submitAction: handleDelete,
    isLoading: isDeleteLoading,
    error: deleteError
  } = useDispatchAction(deleteTier, {
    onSuccess: () => navigate(EscreenNames.CreatorTiersEdit),
  })

  // const dispatchChangeCollaborationStatus = useCallback(
  //   params => {
  //     return () => dispatch(updateCollaborationStatus(params));
  //   },
  //   [dispatch],
  // );

  const {
    submitAction: dispatchChangeCollaborationStatus,
    error: changeCollaborationStatusError,
    isLoading: isChangeCollabStatusLoading,
  } = useDispatchAction(updateCollaborationStatus, {
    // onSuccess: () => alertMessage("", "")
  })

  const {
    submitAction: handlePublish,
    error: publishError,
    isLoading: isPublishLoading,
  }  = useDispatchAction(updateTier, {
    data: {
      id: tierId,
      status: TTierStatus.published,
    }
  })

  const {
    submitAction: handleUnpublish,
    error: unpublishError,
    isLoading: isUnpublishLoading,
  }  = useDispatchAction(updateTier, {
    data: {
      id: tierId,
      status: TTierStatus.cancelled,
    }
  })

  const isCreatingNew = useMemo(() => isEmpty(selectedTier), [selectedTier]);
  const isTierPublished = selectedTier?.status === TTierStatus.published;

  const isCollaborationTier =
    !!selectedTier?.coCreators && selectedTier?.coCreators?.length > 0;
  const isCollaborationTierOfAnotherCreator =
    isCollaborationTier && selectedTier?.creator !== creatorInfo?.id;

  const isCollaborationTierAcceptedByUser =
    isCollaborationTier &&
    find(selectedTier?.coCreators, c => c.creator === creatorInfo?.id)
      ?.acceptance_status === TCollaborationAcceptanceStatus.accepted;
  const isCollaborationTierDisabledByUser =
    isCollaborationTier &&
    find(selectedTier?.coCreators, c => c.creator === creatorInfo?.id)
      ?.acceptance_status === TCollaborationAcceptanceStatus.declined;

  const renderTierStatus = useCallback(() => {
    let status;

    switch (selectedTier?.status) {
      case TTierStatus.published:
        status = i18n.t('isPublished');
        break;
      case TTierStatus.declined:
        status = i18n.t('isDeclined');
        break;
      default:
        status = i18n.t('isNotPublished');
    }

    return (
      <View variant="sectionContainer">
        <Text bold style={styles.statusTitle}>{status}</Text>
      </View>
    );
  }, [selectedTier]);

  const fields:TFormInputInfo[] = [
    {
      name: "title",
      type: 'text',
      label:i18n.t('tierEdit.tierTitleInputLabel'),
      placeholder:i18n.t('tierEdit.tierTitleInputPlaceholder'),
      editable: !isCollaborationTierOfAnotherCreator,
      accessibilityLabel:'tier-title-form-input',
      requiredField: true, //TODO: connect props with type definitions
    },
    {
      name: "description",
      type: 'richText',
      label:i18n.t('tierEdit.tierDescription'),
      placeholder:i18n.t('tierEdit.tierDescriptionInputPlaceholder'),
    },
    {
      name: "currencyName",
      type: 'dropdown',
      label:i18n.t('tierEdit.tierCurrencyInputLabel'),
      options: allPaymentCurrencies,
      enabled:!isCollaborationTierOfAnotherCreator,
      requiredField: true
    },
    {  
      name: 'price',
      type: 'text',
      label:i18n.t('tierEdit.tierPrice'),
      placeholder:i18n.t('tierEdit.tierPriceInputPlaceholder'),
      editable:!isCollaborationTierOfAnotherCreator,
      requiredField: true,
      keyboardType:"decimal-pad",
    }
  ];

  return (
    <ContentContainer>
      <ScrollView>
        <Formik
          initialValues={{
            title: selectedTier?.title,
            description: decodeHTMLEntities(selectedTier?.description || ''),
            price: String(selectedTier?.price || 0),
            currencyName: toUpper(selectedTier?.currency?.name || "UAH"),
            // currencyName: selectedTier?.currency?.name
            // subscribersLimit: selectedTier?.subscribersLimit,
          }}
          validationSchema={createTierSchema()}
          onSubmit={(values): void => {
            if (isCreatingNew) {
              const tierData: TTier & any = values;
              // tierData.currency = 'uah';
              tierData.price = parseFloat(tierData.price) || 0;
              addTier({
                  ...tierData,
                  image: image?.uri,
                  coCreators,
              });
            } else {
              handleUpdate(
                {
                  ...values,
                  id: selectedTier?.id,
                  image: image?.uri !== selectedTier?.imageUri ? image?.uri  : undefined,
                  coCreators: map(coCreators, c => ({
                    creatorPageURL: c.creatorPageURL,
                    creatorAvatarUri: c.creatorAvatarUri,
                    creatorTitle: c?.creatorTitle,
                    creator: c?.creator,
                  }))
                  .filter(c => coCreators.findIndex(cItem => cItem.creator === c.creator) < 0),
                },
              );
            }
          }}
          >
          {({values, setTouched, isSubmitting, touched, handleSubmit, errors, handleChange}) => (
            <View style={{flex: 1, padding: 15}}>
              {isCollaborationTierOfAnotherCreator && <Text variant='formHint'>
                {i18n.t("tierEdit.collabEditableByCreatorWarning")}
              </Text>}
              {/* todo: replace all forminput labels with new component */}
              {fields.map((field, index) => (
                <FormInputContainer 
                  {...field}
                  {...{
                    key: index,
                    value: values?.[field?.name as unknown as keyof typeof  values],
                    handleChange: (data:any) => {
                      if(field?.name){
                        setTouched({[field?.name]: true})
                      }
                      return handleChange(field?.name)(data?.value)
                    },
                    error: (
                      (touched?.[field?.name as unknown as keyof typeof  values] 
                      || isSubmitting 
                      || field.type === 'richText' // on web, limitations
                    ) 
                    ) ? 
                    errors?.[field?.name  as unknown as keyof typeof errors] || ' ' 
                    : " ",
                  }}
                />
              ))}
              <View variant='sectionContainer'>
                {image?.uri && (
                  <Image variant="tierImage" source={{uri: image?.uri}} />
                )}
                <Button
                  title={i18n.t('pickPhoto')}
                  onPress={openPicker}
                  // testID="pick-photo-button"
                  // accessibilityLabel="pick-photo-button"
                  //todo: think about updating collaboration tier
                  //todo: leave only editable or disabled
                  containerStyle={{paddingVertical: 15}}
                />
                <TouchableOpacity onPress={() => openPicker({allowsEditing: false})}> 
                  <Text>+ gif</Text>
                </TouchableOpacity>
                <Text variant="formHint">{i18n.t('imageSizeLimit')}</Text>
                <Text variant='inputError'>
                  {!image?.uri ?  //todo: move to one imageError
                        i18n.t('tierEdit.imageRequired')
                  : imageError}
                </Text>
              </View>

              {isCreatingNew && (
                <View>
                  <View>
                    <Text variant="secondaryTitle">
                      {i18n.t('tierEdit.collaborationTitle')}
                    </Text>
                  </View>

                  <Row>
                    <View style={{flex: 1, paddingRight: 10}}>
                      <FormInput
                        value={searchQuery}
                        placeholder={i18n.t(
                          'tierEdit.creatorsInputPlaceholder',
                        )}
                        onChangeText={setSearchQuery}
                      />
                    </View>

                    <Button
                      title={i18n.t('search.search')}
                      primary
                      disabled={isSearchLoading}
                      onPress={dispatchSearchCreators}
                    />
                  </Row>
                  <ActionResult 
                      isLoading={isSearchLoading}
                      error={searchError}
                      renderError={(error) => (
                        <Text variant='inputError'>
                            {error}
                        </Text>
                      )}
                  />
                </View>
              )}

              {!isEmpty(coCreators) && (
                <Text variant="secondaryTitle">
                  {i18n.t('tierEdit.selectedCoCreators')}
                </Text>
              )}
              {/* todo: add flatlist */}
              {map(coCreators, coCreator => {
                return (
                  <CreatorCard
                    onPress={
                      //for now
                      isCreatingNew
                        ? () =>
                            setCoCreators(
                              filter(
                                coCreators,
                                c => c?.creator !== coCreator?.creator,
                              ),
                            )
                        : undefined
                    }
                    creatorPageURL={coCreator?.creatorPageURL}
                    creatorTitle={coCreator?.creatorTitle}
                    creatorAvatarUri={coCreator?.creatorAvatarUri}
                  />
                );
              })}
              {/* TODO: add profit distribution */}

              {/* todo: add explanation why user cannot change co-creators later */}
              {isCreatingNew && (
                <FlatList
                  ListHeaderComponent={
                    <Text variant="secondaryTitle">
                      {i18n.t('tierEdit.creatorSearchResult')}
                    </Text>
                  }
                  ItemSeparatorComponent={() => (
                    <View variant="verticalListSeparator" />
                  )}
                  style={styles.creatorsListContainer}
                  data={foundCreators}
                  ListEmptyComponent={
                    <Text center variant="thirdTitle">
                      {i18n.t('tierEdit.creatorsNotFound')}
                    </Text>
                  }
                  renderItem={({item}: {item: TCreator}) => (
                    <View>
                      <CreatorCard
                        onPress={() =>
                          setCoCreators(
                            filter(
                              union(coCreators, [
                                {
                                  creatorPageURL: item.pageURL,
                                  creatorAvatarUri: item.avatarUri,
                                  creatorTitle: item?.title,
                                  creator: item?.id,
                                },
                              ]),
                              c => coCreators.findIndex(
                                item => item.creator === c.creator 
                              ) < 0
                              && creatorInfo?.id !== c.creator 
                            ),
                          )
                        }
                        creatorPageURL={item?.pageURL}
                        creatorTitle={item?.title}
                        creatorAvatarUri={item?.avatarUri}
                      />
                    </View>
                  )}
                />
              )}
              <Row
                containerStyle={{
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
                flexWrap
              >
                <Button
                  testID="submit-button"
                  title={
                    isCreatingNew
                      ? i18n.t('tierEdit.addTier')
                      : i18n.t('submitEdit')
                  }
                  onPress={handleSubmit}
                  primary
                  disabled={isCreateLoading || isUpdateLoading}
                  // disabled={isCollaborationTier && selectedTier?.creator !== creatorInfo?.id}
                  containerStyle={{marginTop: 25}}
                />

                {!isCreatingNew && (
                  <Row containsSimilarItems>
                    {isTierPublished
                      ? !isCollaborationTier && (
                          <TouchableOpacity onPress={handleUnpublish}>
                            <Text>{i18n.t('unpublish')}</Text>
                          </TouchableOpacity>
                        )
                      : !isCollaborationTier && (
                          <TouchableOpacity onPress={handlePublish}>
                            <Text>{i18n.t('publish')}</Text>
                          </TouchableOpacity>
                        )}
                    {/* todo: move to another screen */}
                    {!isCollaborationTierOfAnotherCreator && (
                      <>
                      <ConfirmationDialog
                            title={i18n.t("dialogs.deletionConfirmationDialogTitle")}
                            desc={i18n.t("dialogs.confirmationDialogTitle")}
                            onPress={() => handleDelete({
                              id: tierId,
                            })}
                            render={({onPress}) => (
                              <TouchableOpacity onPress={onPress}>
                                <Text variant='dangerous'>{i18n.t('delete')}</Text>
                            </TouchableOpacity>
                          )}
                      />
                      </>
                    )}
                  </Row>
                )}
              </Row>

              <ActionResult 
                isLoading={isPublishLoading || isUnpublishLoading}
                error={publishError || unpublishError}
                renderError={(error) => (
                  <Text variant='inputError'>
                    {error}
                  </Text>
                )}
              />

              <ActionResult 
                isLoading={isDeleteLoading}
                error={deleteError}
                renderError={(error) => (
                  <Text variant='inputError'>
                    {error}
                  </Text>
                )}
              />

              {renderTierStatus()}

              <View variant="sectionContainer">
                {/* todo: replace with inputError variant all cases */}
                <Text variant="inputLabel" color={colors.RED}>
                  {createTierError || updateError}
                </Text>
              </View>

              {/* todo: think about right place for this redirection button */}
              {!isCreatingNew && <Button
                title={i18n.t('tierEdit.addTranslationBtnTitle')}
                onPress={() => {
                  navigate(EscreenNames.EditTierTranslation, {
                    parentId: tierId,
                  });
                }}
              />}

              {!isCreatingNew && isCollaborationTierOfAnotherCreator && (
                <View style={{flex:1}}>
                    <Row containerStyle={styles.collabButtonsRow}>
                      <Button
                        title={i18n.t('accept')}
                        containerStyle={{flex: 1, paddingRight: 5}}
                        disabled={isCollaborationTierAcceptedByUser || isChangeCollabStatusLoading}
                        /**
                         * todo: create module/library for acceptance logic
                         * with all status and process handled inside the box
                         */
                        onPress={() => dispatchChangeCollaborationStatus({
                          id: selectedTier?.id,
                          status: TCollaborationAcceptanceStatus.accepted,
                        })}
                        primary
                      />
                      <Button
                        red
                        title={i18n.t('decline')}
                        disabled={isCollaborationTierDisabledByUser || isChangeCollabStatusLoading}
                        containerStyle={{flex: 1, paddingLeft: 5}}
                        onPress={() => dispatchChangeCollaborationStatus({
                          id: selectedTier?.id,
                          status: TCollaborationAcceptanceStatus.declined,
                        })}
                      />
                    </Row>
                    <View variant='sectionContainer'>
                      <ActionResult 
                        isLoading={true}
                        error={changeCollaborationStatusError}
                        {...ActionResultDefaultRenders}
                      />
                    </View>
                </View>

              )}
            </View>
          )}
        </Formik>
      </ScrollView>
    </ContentContainer>
  );
};

const styles = StyleSheet.create({
  creatorsListContainer: {
    marginTop: 15,
  },
  collabButtonsRow: {
    marginTop: 10
  },
  statusTitle: {
    textTransform: 'uppercase',
  }
});
