export const WithdrawMethodsLocalization = {
  uk: {
    editWithdrawMethodScreen: {
      screenTitle: "Методи виводу коштів",

      withdrawMethodCryptoType: 'Крипто-гаманець',
      cryptoWalletAdressInputLabel: 'Адреса гаманця',
      cryptoWalletMemoInputLabel: 'Мемо',
      cryptoWalletMemoInputPlaceholder:
        'Деякі криптовалюти вимагають додаткові дані для переказів, як наприклад memo',
      withdrawMethodMerchantentEnepreneurType: 'Компанія',

      companyNameInputLabel: 'Назва компанії',
      edrpouInputLabel: 'Код ЄДРПОУ',
      phoneInputLabel: 'Телефон',

      currencyInputLabel: 'Валюта',
      activateYourCompanyMessage: "Вашу компанію потрібно активувати. Напишіть нам на електронну пошту",
      activateYourCompanyShortMessage: "Напишіть нам для активації",

    },
  },
  en: {
    editWithdrawMethodScreen: {
      screenTitle: "Edit withdraw method",

      withdrawMethodCryptoType: 'Crypto wallet',
      cryptoWalletAdressInputLabel: 'Wallet address',
      cryptoWalletMemoInputLabel: 'Wallet memo',
      cryptoWalletMemoInputPlaceholder:
        'Some crypto requires memo to transfer coins',
      withdrawMethodMerchantentEnepreneurType: 'Merchant',

      companyNameInputLabel: 'Company name',
      //TODO: find general name
      edrpouInputLabel: 'Code ЄДРПОУ (UA)',
      phoneInputLabel: 'Phone',

      currencyInputLabel: 'Currency',

      activateYourCompanyMessage: "Please contact us via emails to activiate your company.",
      activateYourCompanyShortMessage: "Contact us to activate",
    },
  },
};
