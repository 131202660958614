import {useCallback, useState} from 'react';
import {useDispatch} from 'react-redux';

type F = (...args:any) => any

export const useDispatchAction = <T extends F>(action: T, params?: {
  onFinish?: () => void,
  onSuccess?: (data?:any) => void, 
  data?: Parameters<T>[0]
}) => {
  type TPayload = Parameters<T>[0];

  if(params?.data){
    params.data = params.data as unknown as TPayload
  }

  const dispatch = useDispatch();
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const {onSuccess} = params || {}

  const submitAction = useCallback(
    (payload?: TPayload) => {
      let providedPayload: any = payload
      // console.log('submit');
      //for the cases, when dev sudenly pass submitAction as a callback to the onPress 
      // onPress by default pass event object to the callback
      // that's why we have  Converting circular structure to JSON error
      // dispatchConfig property - on mobile
      // _reactName property - on web
      if(!!providedPayload?.["_reactName"] || !!providedPayload?.["dispatchConfig"]){
        console.warn('pass invalid payload for the action: event object')
        providedPayload = undefined
      }
      setIsLoading(true);
      dispatch(
        action(providedPayload || params?.data, {
          onSuccess: (data?:any) => {
            setError('')
            onSuccess?.(data)
          },
          onFail: (err: any) => {
            setError(JSON.stringify(err?.message));
          },
          onFinish: () => {
            setIsLoading(false);
          },
        }),
      );
      //todo: add timeout ???
    },
    [dispatch, action, params?.data],
  );

  return {
    isLoading,
    error,
    submitAction,
  };
};
