import {
  View,
  Text,
  Row,
  Switch,
  KeyboardAvoidingView,
  Column,
  ContentContainer,
} from 'components';
import React, {useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {selectIsBiometryEnabled, setIsBiometryEnabled} from 'store';
import {i18n} from 'localize';


export const UserPrivacy = () => {
  const isBiometryEnabled = useSelector(selectIsBiometryEnabled);
  const dispatch = useDispatch();

  const changeBiometryStatus = useCallback(
    value => {
      dispatch(
        setIsBiometryEnabled(value),
        )
    },
    [dispatch],
  );

  return (
    <ContentContainer>
      <KeyboardAvoidingView>
        <View variant="screenContainer">
          <Row
            columnCenter
            fullWidth
            // containsSimilarItems
            containerStyle={{justifyContent: 'flex-start', flexWrap: 'wrap'}}
          >
            <Column flex={3}>
              <Text variant="secondaryTitle">
                {i18n.t('userPrivacyScreen.enableBiometryCheckboxTitle')}
              </Text>
            </Column>
            <Column flex={1}>
              <Switch
                style={{marginLeft: 10}}
                testID="earnings-privacy-switch"
                onValueChange={changeBiometryStatus}
                value={
                  //sometimes we receive boolean in string ???
                  Boolean(isBiometryEnabled)
                }
              />
            </Column>
          </Row>
        </View>
      </KeyboardAvoidingView>
    </ContentContainer>
  );
};
