import { View, Modal, ModalProps, TouchableOpacity, StyleSheet, Platform } from 'react-native'
import React, { forwardRef, RefObject, useImperativeHandle, useState } from 'react'
import { Icon } from 'components'
import { LanguagePicker } from '../LanguagePicker/LanguagePicker';
import { isMobile } from 'utils';
import { DEVICE_WIDTH } from '@sponsor/constants';

export type TLanguagePickerRef = {
    toggleModal: () => void
}

export const LanguageModalPicker : React.FC<ModalProps & Partial<RefObject<TLanguagePickerRef>>> = forwardRef(({
    ...otherProps
}, ref) => {

    const [isModalVisible, setIsModalVisible] = useState(false);

    useImperativeHandle(ref, () => ({
        toggleModal: () => {
          setIsModalVisible((v) => !v)
        }
    }));
      
    return (
        <>
        <TouchableOpacity onPress={() => setIsModalVisible((v) => !v)}>
            <Icon size={20} name="world"/>
        </TouchableOpacity>
        <Modal transparent={!isMobile} visible={isModalVisible}>
            <View style={styles.modalContainer}>
                <TouchableOpacity hitSlop={{left: 10, bottom: 10}} style={{top: 70, right: 25, position: "absolute"}} onPress={() => setIsModalVisible(false)}>
                    <Icon name="cancel" />
                </TouchableOpacity>
                <View>
                    <LanguagePicker short={false}/>
                </View>
            </View>
        </Modal>
        </>
    )
})

const styles = StyleSheet.create({
    modalContainer: {
        flex:1, 
        alignItems:'center', 
        backgroundColor: 'white',
        justifyContent:'center',
        ...Platform.select({
            web: {
                marginVertical: 50,
                marginHorizontal: DEVICE_WIDTH < 650 ? 50 : '30%', 
                borderRadius: 8
            },
            default: {
            }
        })
    }
})