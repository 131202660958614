import {Column, Row, FormInput, Button, Text, Checkbox} from 'components';
import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {StyleSheet, View, ActivityIndicator, ScrollView, Platform} from 'react-native';
import {Formik, FormikValues} from 'formik';
import {useDispatch} from 'react-redux';
import {EscreenNames} from 'navigation';
import {ContentContainer} from 'components';
import {TouchableOpacity} from 'react-native';
import {colors} from '@sponsor/assets';
import {loginStart, signupStart} from 'store';
import {useNavigation} from 'hooks';
import {getSignupValidationSchema} from './signupValidationSchema';
import {i18n} from 'localize';
import {useKeyPress} from '../../../../hooks/useKeyPress/useKeyPress';
import {useBeforeUnload} from '../../../../hooks/useBeforeUnload/useBeforeUnload';
import { isMobile } from 'utils';
import { AuthImage } from '../AuthImage';
import { DEVICE_WIDTH } from '@sponsor/constants';

export const Signup = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const {navigate} = useNavigation();

  useBeforeUnload(true, i18n.t('unsavedChangesWarning'));

  const formRef = useRef<FormikValues>();
  const [isEnterPressed] = useKeyPress('Enter');

  useEffect(() => {
    if (isEnterPressed && formRef.current && !isLoading) {
      formRef.current?.submitForm?.();
    }
  }, [isEnterPressed, isLoading]);

  const initialValues = useMemo(
    () => ({
      name: '',
      last_name: '',
      email: '',
      confirmEmail: '',
      password: '',
      isAggrementApprove: false,
    }),
    [],
  );

  const sendLogin = useCallback(
    ({email, password}) => {
      setIsLoading(true);
      dispatch(
        loginStart(
          {
            email,
            password,
          },
          {
            onSuccess: () => {
              setIsLoading(false);
              navigate(EscreenNames.Home);
            },
            onFail: err => {
              navigate(EscreenNames.Login);
            },
          },
        ),
      );
    },
    [dispatch, navigate],
  );

  const sendSignup = useCallback(
    ({email, password, name, last_name}) => {
      setIsLoading(true);
      dispatch(
        signupStart(
          {
            email,
            password,
            name,
            last_name,
          },
          {
            onSuccess: () => {
              setIsLoading(false);
              sendLogin({
                email,
                password
              });
            },
            onFail: err => {
              setIsLoading(false);
              setError(err?.message);
            },
          },
        ),
      );
    },
    [sendLogin, dispatch, navigate],
  );

  return (
    <ContentContainer
      oneColumn
      keyboardAvoidingView={false}
    >
      <ScrollView>
        <Row flex={1} flexWrap>
          <Column flex={1}>
              <Text variant='authFormTitle'>
                {i18n.t('signupScreen.signupTitle')}
              </Text>
              <Formik
                initialValues={initialValues}
                innerRef={ref => {
                  if (ref) {
                    formRef.current = ref;
                  }
                }}
                validationSchema={getSignupValidationSchema()}
                onSubmit={(values: any) => {
                  sendSignup(values);
                }}
              >
                {({
                  isSubmitting,
                  values,
                  handleSubmit,
                  handleChange,
                  errors,
                  touched,
                }) => (
                  <View
                    style={styles.formContainer}
                  >
                    <FormInput
                      label={`${i18n.t('name')}`}
                      value={values?.name}
                      requiredField
                      formContainerStyle={styles.inputContainer}
                      placeholder={i18n.t('signupScreen.namePlaceholder')}
                      onChangeText={handleChange('name')}
                      error={isSubmitting || touched?.name ? errors?.name : ''}
                    />
                    <FormInput
                      label={`${i18n.t('surname')}`}
                      requiredField
                      formContainerStyle={styles.inputContainer}
                      value={values?.last_name}
                      placeholder={i18n.t('signupScreen.lastnamePlaceholder')}
                      onChangeText={handleChange('last_name')}
                      error={
                        isSubmitting || touched?.last_name ? errors?.last_name : ''
                      }
                    />
                    <FormInput
                      label={`${i18n.t('signupScreen.emailLabel')}`}
                      requiredField
                      autoCapitalize='none'
                      value={values?.email}
                      formContainerStyle={styles.inputContainer}
                      placeholder={i18n.t('signupScreen.emailPlaceholder')}
                      onChangeText={handleChange('email')}
                      error={isSubmitting || touched?.email ? errors?.email : ''}
                    />
                    {/* <FormInput
                      label={`${i18n.t('signupScreen.confirmEmailLabel')}`}
                      value={values?.confirmEmail}
                      requiredField
                      formContainerStyle={styles.inputContainer}
                      placeholder={i18n.t('signupScreen.confirmEmailPlaceholder')}
                      onChangeText={handleChange('confirmEmail')}
                      error={
                        isSubmitting || touched?.confirmEmail
                          ? errors?.confirmEmail
                          : ''
                      }
                    /> */}
                    <FormInput
                      //todo: add * required symbol for other forms
                      label={`${i18n.t('signupScreen.passwordLabel')}`}
                      value={values?.password}
                      requiredField
                      autoCapitalize='none'
                      formContainerStyle={styles.inputContainer}
                      placeholder={i18n.t('signupScreen.passwordPlaceholder')}
                      secureTextEntry
                      onChangeText={handleChange('password')}
                      error={
                        isSubmitting || touched?.password ? errors?.password : ''
                      }
                    />
                    <Text variant="inputError">{error}</Text>

                    <Checkbox
                      containerStyle={{
                        marginTop: isMobile ? 0 : 15,
                      }}
                      title={i18n.t('signupScreen.aggreement')}
                      onPress={() => handleChange('isAggrementApprove')('true')}
                      value={values.isAggrementApprove}
                    />
                    <Text variant="inputError">{(isSubmitting ||  touched?.password) ? errors.isAggrementApprove : ""}</Text>

                    <Row containerStyle={{justifyContent: 'space-between'}} flexWrap>
                      <Row>
                        <Button
                          containerStyle={{marginVertical: 25}}
                          onPress={handleSubmit}
                          title={i18n.t('createAccount')}
                          disabled={isLoading}
                        />
                        {isLoading && <ActivityIndicator style={{paddingLeft: 15}} />}
                      </Row>

                      <Column 
                        rowCenter columnCenter
                        containerStyle={{padding:5}}
                      >
                        <Text>{i18n.t('signupScreen.haveAccount')}</Text>
                        <TouchableOpacity
                          onPress={() => navigate(EscreenNames.Login)}>
                          <Text variant="thirdTitle">{i18n.t('login')}</Text>
                        </TouchableOpacity>
                      </Column>
                    </Row>
                  </View>
                )}
              </Formik>
          </Column>

          {!isMobile && <AuthImage />}
      </Row>
      </ScrollView>
    </ContentContainer>
  );
};

const styles = StyleSheet.create({
  pageContainer: {
    justifyContent: 'center',
    marginVertical: 50,
  },
  formTitle: {
    fontSize: 24,
    textAlign: 'center',
    paddingVertical: 15,
  },
  formContainer: {
    padding: 25,
    borderWidth: 0.5,
    borderColor: colors.DUSTY_GRAY,
    flex: 1,
    ...Platform.select({
      web: {
        minWidth: 350
      },
      default: {
        width: DEVICE_WIDTH
      }
    })
  },
  errorText: {
    color: 'red',
    fontSize: 15,
  },
  inputContainer: {
    marginVertical: 4,
    // width: '100%',
    // flex: 1,
    // height: 75,
    // backgroundColor: colors.CONCRETE,
    // padding: 15,
    // outlineColor: Platform.OS === 'web' ? 'orange' : null,
  },
});
