import { GestureResponderEvent, StyleSheet, TouchableOpacity } from 'react-native'
import React from 'react'
import { Row, Text } from 'components'
import { i18n } from 'localize'

type TProps = {
    onPressMap: {
        [key in string]: (event: GestureResponderEvent) => void
    }
}

export const TranslationsMenuOptions:React.FC<TProps> = ({
    onPressMap = {}
}) => {

    // constant inside component due to i18n library
    const menuOption = [
        {
            title: i18n.t('addTranslation'),
            id: 'addTranslation' 
        },
        {
            title: i18n.t('translations'),
            id: 'see_all_translations'
        },
    ]

  return (
    <Row childPaddingLeft={5}>
        {
            menuOption.map(i => (
                <TouchableOpacity key={i.id} onPress={onPressMap[i.id]}>
                        <Text>{i.title}</Text>
                </TouchableOpacity>
            ))
        }
    </Row>
  )
}

const styles = StyleSheet.create({})