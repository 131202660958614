
import {NetworkService} from '../services';
import {ApiRequest} from '../types';
import { 
    TPostUploadGoalsTranslationsInput, 
    TPostUploadGoalsTranslationsOutput,
    TPutUpdateGoalsTranslationsInput,
    TPutUpdateGoalsTranslationsOutput,
    TDeleteGoalsTranslationsInput,
    TDeleteGoalsTranslationsOutput,
    TGetGoalsTranslationsInput,
    TGetGoalsTranslationsOutput
} from './goalsTranslations.types';

const url = 'creator/goals/translations'

export const getGoalsTranslations = async (
  params: TGetGoalsTranslationsInput,
): ApiRequest<TGetGoalsTranslationsOutput> => {
  return NetworkService.get(`/v1/${url}`, params);
};


export const postUploadGoalsTranslations = async (
  inputs: TPostUploadGoalsTranslationsInput
): ApiRequest<TPostUploadGoalsTranslationsOutput> => {
  return NetworkService.post(
    `/v1/${url}`,
    inputs,
  );
}

export const putUpdateGoalsTranslations = async (
  params: TPutUpdateGoalsTranslationsInput,
): ApiRequest<TPutUpdateGoalsTranslationsOutput> => {
  return NetworkService.put(`/v1/${url}`, params);
};


export const deleteGoalsTranslations = async (
  params: TDeleteGoalsTranslationsInput,
): ApiRequest<TDeleteGoalsTranslationsOutput> => {
  return NetworkService.delete(`/v1/${url}`, params);
};


export const GoalsTranslationsService = {
    getGoalsTranslations,
    postUploadGoalsTranslations,
    putUpdateGoalsTranslations,
    deleteGoalsTranslations,
};
  
  

