import React from 'react';
import {StyleSheet, TouchableOpacity, View} from 'react-native';
import {Icon, Row, Text} from 'components';
import {useNavigation} from 'hooks';
import {EscreenNames} from 'navigation';
import {colors} from '@sponsor/assets';
import {useSelector} from 'react-redux';
import {selectCreatorBasicInfo} from 'store';
import { round } from 'lodash';
import moment from 'moment';
import { i18n } from 'localize';

export const CreatorStatisticInfo = () => {

  const creator = useSelector(selectCreatorBasicInfo);
  // const paidAmountLastMonth = 15000;
  // const paidAmountCurrentMonth = 1000;
  // const subscribersDifferenceInLastMonth = 50;
  // const cancellationDifferenceInLastMonth = 0;
  // const userCurrency = 'UAH';

  const {navigate} = useNavigation();

  return (
    <View style={statisticsStyles.statisticsContainer}>
      <Text variant="thirdTitle" color="white">
        {`${i18n.t("updatedAt")}: ${moment(creator?.totalMonthlyEarningsUpdatedAt).format('lll')}`}
      </Text>

      <Text style={{fontSize: 20, lineHeight: 36}} color="white">
        {creator?.title}
      </Text>
      <TouchableOpacity
        style={{paddingVertical: 5}}
        onPress={() => {
          navigate(
            EscreenNames.CreatorPublicPage,
            {
              pageURL: creator?.pageURL
            },
            {
              urlParam: true,
            }
          )        
        }}
      >
        <Text style={{fontSize: 18}} color="white">{'/ ' + creator?.pageURL}</Text>
      </TouchableOpacity>

      { creator?.pricingPlan?.id && <TouchableOpacity onPress={() => {
        navigate(EscreenNames.PricingPlanScreen, {
          id: creator?.pricingPlan?.id
        })
      }}>
        <Text  color="white" style={{paddingVertical:5, fontSize: 18}}>
          {`${i18n.t("pricingPlan")}: ${i18n.t(`pricingPlansTitles.${creator?.pricingPlan?.codeName}`)}`}
        </Text>
      </TouchableOpacity> }

      {/* <Text variant="sectionTitle">
          {i18n.t('editCreatorPage.lastMonthLabel')}
        </Text> */}

      {/* <TouchableOpacity onPress={() => navigate(EscreenNames.Revenue)}>
        <Text color={'white'} style={{fontSize: 22}} center>
          {`${
            paidAmountLastMonth > 0 ? '+' : ''
          } ${paidAmountLastMonth} ${userCurrency}`}
        </Text>
      </TouchableOpacity> */}

      <Row columnCenter containsSimilarItems>
        <TouchableOpacity
          onPress={() => {
            navigate(EscreenNames.Revenue)
          }}
        >
          <Text center style={{fontSize: 22}}  color="white">
            {`${round(creator?.totalMonthlyEarnings?.amount || 0, 6)} ${creator?.totalMonthlyEarnings?.currency?.name || creator?.mainCurrency?.name}`}
          </Text>
        </TouchableOpacity>
{/* 
        <TouchableOpacity
          style={{flex: 1}}
          onPress={() => navigate(EscreenNames.Subscribers)}>
          <Row rowCenter columnCenter containsSimilarItems>
            <Text color={'white'} style={{fontSize: 22}} center>
              {`${
                cancellationDifferenceInLastMonth > 0 ? '+' : ''
              } ${cancellationDifferenceInLastMonth}`}
            </Text>
            <Icon name="cancel" color={'white'} size={25} />
          </Row>
        </TouchableOpacity> */}
      </Row>

      <TouchableOpacity
          // style={{flex: 1}}
          onPress={() => navigate(EscreenNames.Subscribers)}>
          <Row containsSimilarItems columnCenter>
            {/* <Text color="white" style={{fontSize: 22}} center>
              {`${
                subscribersDifferenceInLastMonth > 0 ? '+' : ''
              } ${subscribersDifferenceInLastMonth}`}
            </Text> */}
            <Text
              color="white" 
              style={{fontSize: 22, fontWeight: 'bold'}} 
            >
              {creator?.nbSponsors}
            </Text>
            <Icon name="team" color="white" size={35} />
          </Row>
        </TouchableOpacity>
    </View>
  );
};

const statisticsStyles = StyleSheet.create({
  statisticsContainer: {
    backgroundColor: colors.GREEN_OFFICIAL,
    borderRadius: 15,
    padding: 15,
    margin: 10,
    borderBottomWidth: 0.5,
    borderBottomColor: colors.GREEN_OFFICIAL,
  },
  lastMonthPaidAmount: {
    fontSize: 25,
    textAlign: 'center',
    fontWeight: 'bold',
  },
  currentMonthPaidAmount: {},
});
