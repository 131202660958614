const data: {
  [key in string]: any;
} = {};

class _KeychainService {
  setItem = async (key: string, value: any): Promise<void> => {
    data[key] = value;
  };

  getItem = async (key: string): Promise<any | null> => {
    return data[key];
  };
  multiGet = async (_: string[]): Promise<any> => {
    // const results = [];
  };

  removeItem = async (_: string): Promise<any> => {};

  clear = async (): Promise<void> => {};
}

export const KeychainService = new _KeychainService();
