import {reduce, values} from 'lodash';
import {createSelector, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {call, put, select, takeEvery} from 'redux-saga/effects';
import {createAction, ActionType} from 'typesafe-actions';
import {selectUser} from '../user';
import {TPost, TProfile} from 'types';
import {LOG} from 'utils';
import {
  CLEAR_STORE,
  getStore,
  TMetaInput,
  TCreatorPostsState,
  TStore,
  fetchSaga,
} from '../common';
import {ApiService} from 'services';
import {TGetCreatorInput, TGetCreatorOutput} from '../../../types/src';

const initialState: TCreatorPostsState = {};

// Action creators are generated for each case reducer function

//TODO: move to another block
export const getCreatorPublicInfo = createAction(
  'creatorsPosts/getCreatorPublicInfo',
)<TGetCreatorInput, TMetaInput>();

export const getCreatorPublicInfoSuccess = createAction(
  'creatorsPosts/getCreatorPublicInfoSuccess',
)();

export const togglePostEmodji = createAction('togglePostEmodji')<
  {
    creatorId: string;
    emodjiUnicode: string;
    postId: string;
  },
  TMetaInput
>();

export const togglePostEmodjiSuccess = createAction('togglePostEmodjiSuccess')<
  {
    userId: string;
    creatorId: string;
    emodjiUnicode: string;
    postId: string;
  },
  TMetaInput
>();

export const creatorPostsSlice = createSlice({
  name: 'creatorsPosts',
  initialState,
  reducers: {
    [CLEAR_STORE]: () => {
      return initialState;
    },
  },
  extraReducers: {
    [getCreatorPublicInfoSuccess.toString()]: (
      state: TCreatorPostsState,
      {payload}: PayloadAction<TGetCreatorOutput['data']>,
    ) => {
      const creatorId = payload?.creator?.id;

      const postObject = reduce<TPost, {[key in string]: TPost}>(
        payload?.recentPosts || [],
        (s, p) => {
          s[p?.id] = p;
          return s;
        },
        {},
      );

      state[creatorId] = {
        ...(state?.[creatorId] || {}),
        results: postObject,
      };
    },
  },
});

export default creatorPostsSlice.reducer;

// selectors
export const selectCreatorsPostsState = createSelector(
  getStore,
  state => state?.posts?.creatorsPosts,
);

export const selectCreatorPostsResults = createSelector(
  [selectCreatorsPostsState, (state: TStore, creatorId: string) => creatorId],
  (creatorsPostsState, creatorId) => {
    return creatorsPostsState?.[creatorId]?.results || {}
  }
);

//todo: think about naming
export const selectPostsByCreatorId = createSelector(
  selectCreatorPostsResults,
  postsResults => {
    return values(postsResults);
  },
);

export const selectPostByCreatorIdAndPostId = createSelector(
  [
    selectCreatorsPostsState,
    (_state: TStore, creatorId: string) => creatorId,
    (_state: TStore, _creatorId: string, postId: string) => postId,
  ],
  (creatorsPostsState, creatorId, postId) => {
    return creatorsPostsState?.[creatorId]?.results[postId] || {};
  },
);

// export const selectPostById = createSelector(
//   [getPostsState, (state: TStore, postId: string): string => postId],
//   (postState, postId) => {
//     const allPosts = {...postState.published, ...postState.drafts};
//     return allPosts[postId];
//   },
// );

function* togglePostEmodjiSaga({
  payload: {creatorId, emodjiUnicode, postId},
  meta: {onFinish, onFail, onSuccess},
}: ActionType<typeof togglePostEmodji>) {
  try {
    const user: TProfile = yield select(selectUser);

    yield put(
      togglePostEmodjiSuccess(
        {
          postId,
          emodjiUnicode,
          userId: user?.id,
          creatorId,
        },
        {},
      ),
    );
    yield call(ApiService.postToggleEmodji, {
      emodjiUnicode,
      post: postId,
    });
    // yield put(setPublishedPosts(data))
    onSuccess?.();
  } catch (err) {
    LOG('err', err);
    onFail?.();
  } finally {
    onFinish?.();
  }
}

export function* creatorsPostsFlow() {
  yield takeEvery(getCreatorPublicInfo, fetchSaga(ApiService.getCreator));
  yield takeEvery(togglePostEmodji, togglePostEmodjiSaga);
}
