import {filter, forEach, isEmpty} from 'lodash';
import React, {useState, useEffect, useMemo, useCallback} from 'react';
import {Platform, ScrollView} from 'react-native';
import {useDispatch, useSelector} from 'react-redux';
import moment from 'moment';
import {StackedBarChart} from 'react-native-chart-kit';

import {ContentContainer, Button, Column, Row, Text, View, ActionResult} from 'components';
import {DEVICE_WIDTH} from '@sponsor/constants';
import {colors} from '@sponsor/assets';
import {i18n} from 'localize';
import {ApiService} from 'services';
import {TGetCreatorSubscribersOutput, TSubscription, TTierStatus} from 'types';
import {alertMessage, isDesktop, isMobile, LOG} from 'utils';
import {
  selectCreatorStats,
  selectTiers,
  getCreatorStats,
  exportSubscribers,
  selectCollaborationTiers,
  selectCreatorBasicInfo,
} from 'store';
import { useDispatchAction } from 'hooks';
import { ActionResultDefaultRenders } from '../../../components/src/ActionResult/ActionResultDefaultRenders';
import { AbstractChartConfig } from 'react-native-chart-kit/dist/AbstractChart';
import {useMedia} from '../../../hooks/useMedia';

const chartConfig:AbstractChartConfig = {
  backgroundGradientFrom: '#1E2923',
  backgroundGradientFromOpacity: 0,
  backgroundGradientTo: '#1E2923',
  backgroundGradientToOpacity: 0,
  color: () => colors.DUSTY_GRAY,
  labelColor: () => 'black',
  strokeWidth: 1, // optional, default 3
  // barPercentage: 1,
  style: {
    // fontSize: 25
    // fontWeight: 'bold',
  },
  scrollableInfoTextStyle: {
  },
  propsForDots: {
      // fontSize: 55
      // fill: colors.DUSTY_GRAY
    // paddingVertical: 440
  },
  propsForVerticalLabels: {
    // padding: 25
  },
  // count: 
  
  // useShadowColorFromDataset: false // optional
};


export const MySubscribers = () => {
  const dispatch = useDispatch();

  const media = useMedia();
  const creatorStats = useSelector(selectCreatorStats);

  const [subscribers, setSubscribers] = useState<TSubscription[]>([]);

  const subscribersIsNotEmpty = useMemo(
    () => !isEmpty(subscribers),
    [subscribers],
  );

  const [IsLoading, setIsLoading] = useState(false);
  const creatorTiers = useSelector(selectTiers);
  const creatorCollaborations = useSelector(selectCollaborationTiers);

  const allTiers = useMemo(
    () => [...creatorTiers, ...creatorCollaborations.filter(c => c.status === TTierStatus.published)],
    [creatorTiers, creatorCollaborations],
  );

  const creator = useSelector(selectCreatorBasicInfo);

  // const creatorTiersIdx = useMemo(() => {
  //   return map(creatorTiers, t => t?.id);
  // }, [creatorTiers]);

  //TODO: add opportunity to choose which tiers to show statistics
  const subscribersPerTierStatsData = useMemo(() => {
    const labels = new Array<string>();
    const data = new Array<any[]>();
    const legend = new Array<string>();

    //TODO: add more colors
    const barColors = [
      colors.GREEN_OFFICIAL,
      colors.ORANGE,
      colors.PURPLE,
      colors.BLACK,
      colors.RED,
      colors.GREEN_DARK,
    ];

    if (isEmpty(allTiers)) {
      return {
        data,
        legend,
        labels,
        barColors,
      };
    }

    const tiersIndexes: {
      [key in string]: number;
    } = {};

    forEach(allTiers, (tier, index) => {
      tiersIndexes[tier.id] = index;
      //todo: add default currenct
      legend.push(tier?.title + '\n' + tier.price + (tier.currency?.name || 'UAH'));
    });
    
    forEach(
      filter(
        creatorStats?.results || [],  
        stat => {
          if (!media.sm) return true;
          const diff =  moment().valueOf() - moment(stat?.startDate).valueOf();
          // todo: add scrolling on mobile, scrollview is lagging
          return diff < (13 * 2629800000); // 3 month on mobile due to small screen size
        }
      ), 
      (s, i) => {
        const month = moment(s?.startDate).month() + 1;
        labels.push(i18n.t('month.' + month));
        const subscriptionsCount = new Array(allTiers.length).fill(null);

        forEach(s.subscribersCountPerTier, (count, tierId) => {
          subscriptionsCount[tiersIndexes[tierId]] = count;
        });

        data.push(subscriptionsCount);
    });
    return {
      labels,
      legend,
      data,
      barColors,
    };
  }, [creatorStats, allTiers]);

  useEffect(() => {
    setIsLoading(true);
    const fetchSubscribers = async () => {
      try {
        const {data}: TGetCreatorSubscribersOutput =
          await ApiService.getCreatorSubscribers();
        setSubscribers(data.results);
      } catch (err) {
        LOG(err);
      } finally {
        setIsLoading(false);
      }
    };
    dispatch(getCreatorStats());
    fetchSubscribers();
  }, [dispatch]);

  const {
    error: exportError,
    isLoading: exportIsInProgress,
    submitAction: dispatchExportSubscribers
  } = useDispatchAction(exportSubscribers, {
    onSuccess: () => alertMessage(
      i18n.t("subscribers.exportSuccessTitle"), 
      i18n.t("subscribers.exportSuccessMessage")
    )
  })

  const minutesFromLastExport = moment
    .duration(
      moment().diff(moment(creator?.lastSubscribersExportAt || new Date())),
    )
    .asMinutes();

  const isExportAvailable =
    minutesFromLastExport > 60 || minutesFromLastExport === 0;

  const renderItem = ({item}: {item: any}) => {
    return (
        <Row
          containerStyle={{
            borderWidth: 1,
            minWidth: 450,
            borderRadius: 5,
            padding: 10,
          }}>
          <Column flex={1}>
            <Text>{`${item.user?.name} ${item?.user?.last_name}`}</Text>
          </Column>

          <Column flex={0.7}>
            <Text numberOfLines={1}>{i18n.t(item?.status, {scope: 'subscribers.statuses'})}</Text>
          </Column>

          <Column rowCenter flex={0.75}>
            <Text>{`${item?.price} ${item.currency?.name || ""}`}</Text>
          </Column>

          <Column flex={2}>
            {item?.createdAt && (
              <Text>{moment(item?.createdAt).format('lll')}</Text>
            )}
          </Column>
        </Row>
    )
  }

  const Wrapper = !media.sm ? ScrollView : View

  return (
    <ContentContainer>
      <ScrollView>
        <View style={{padding: 15}}>
          <Row flexWrap containerStyle={{justifyContent: 'space-between'}}>
            <Text variant="sectionTitle">
              {i18n.t('subscribers.subscriberList')}
            </Text>
            <Column>
              <Button
                title={i18n.t('subscribers.exportSubscribers')}
                primary
                disabled={!isExportAvailable}
                onPress={dispatchExportSubscribers}
              />
              <ActionResult 
                error={exportError}
                isLoading={exportIsInProgress}
                {...ActionResultDefaultRenders}
              />
              {/* <Text>{`${i18n.t('subscribers.lastExportAt')} ${moment(creator?.lastSubscribersExportAt).format('HH-mm')}`}</Text> */}
              {!isExportAvailable && (
                <Text>{`${i18n.t('subscribers.nextExportAt')} ${moment(
                  creator?.lastSubscribersExportAt,
                )
                  .add('1', 'hour')
                  .format('HH-mm')}`}
                </Text>
              )}
            </Column>
          </Row>

          {!isDesktop() && !isEmpty(allTiers) && (
            <View variant="sectionContainer">
              <View variant="sectionContainer">
                <Text variant="thirdTitle">
                  {i18n.t('subscribers.subscribersPerTier')}
                </Text>
              </View>

              {!media.sm && <View variant="sectionContainer">
                <Text>{i18n.t('subscribers.chartScrollAvilability')}</Text>
              </View>}

              <Wrapper
                horizontal
                //todo: move to the hook ???
                ref={(ref) => {
                  ref?.scrollToEnd({animated: false})
                }}
              >
                <StackedBarChart
                  // todo: fix freezing 
                  hideLegend={false}
                  data={subscribersPerTierStatsData}
                  width={isMobile ? DEVICE_WIDTH - 30 : DEVICE_WIDTH} //todo: unified width for charts
                  // width={Platform.OS === 'web' ? DEVICE_WIDTH / 2 : DEVICE_WIDTH}
                  height={300}
                  // formatYLabel={}
                  barPercentage={1}
                  chartConfig={chartConfig}
                />
              </Wrapper>
            </View>
          )}

          <ScrollView horizontal>        
              <View>
                <LastSubscribersSectionHeader />
                <LastSubscribersTableHeader subscribersIsNotEmpty={subscribersIsNotEmpty}/>
                {!IsLoading && !subscribers?.length && <LastSubscribersEmptyList />}
                {
                  subscribers.map(item => (
                      <>
                        {renderItem({item})}
                        <View variant='verticalListSeparator'/>
                      </>
                  ))
                }
              </View>

          </ScrollView>
        </View>
      </ScrollView>
    </ContentContainer>
  );
};

function LastSubscribersSectionHeader(){
  return (
    <Text variant='sectionTitle'>
      {i18n.t('subscribers.lastSubscribersSectionTitle')}
    </Text>
  )
}

function LastSubscribersTableHeader({
  subscribersIsNotEmpty
}: {
  subscribersIsNotEmpty: boolean
}){
  if (!subscribersIsNotEmpty) return null;
  return (
      <Row
        containerStyle={{
          minWidth: 400,
          borderRadius: 5,
          paddingHorizontal: 10,
        }}>
        <Column flex={1}>
          <Text variant="secondaryTitle">
            {i18n.t('subscribers.subscribersNameLabel')}
          </Text>
        </Column>

        <Column rowCenter flex={0.7}>
          <Text variant="secondaryTitle">
            {i18n.t('subscribers.subscribersStatusLabel')}
          </Text>
        </Column>

        <Column rowCenter flex={0.5}>
          <Text variant="secondaryTitle">
            {i18n.t('subscribers.subscribersPriceLabel')}
          </Text>
        </Column>
        <Column rowCenter flex={2}>
          <Text variant="secondaryTitle">
            {i18n.t('subscribers.subscribersStartDateLabel')}
          </Text>
        </Column>
    </Row>
  );        
}

function LastSubscribersEmptyList(){
  return (
      <Text center variant="secondaryTitle">
        {i18n.t('subscribers.noSubscribers')}
      </Text>
    )
}