import { StyleSheet, TouchableOpacity } from 'react-native'
import React from 'react'
import { View, Text } from 'components'
import { colors } from '@sponsor/assets'
import { useNavigation } from 'hooks'
import { EscreenNames } from 'navigation'
import { i18n } from 'localize'

export const PaymentConditions = () => {
    const {navigate} = useNavigation();
    return (
        <View style={{alignItems: 'flex-start'}}>
            <View variant='sectionContainer' bordered green>
                <Text color={colors.GREEN_OFFICIAL} variant="formHint">
                    {i18n.t('paymentScreen.refundPolicyMessage')}
                </Text>
                <TouchableOpacity onPress={() => navigate(EscreenNames.PublicOffer)}>
                    <Text color="black" variant="formHint">{i18n.t('seeMore')}</Text>
                </TouchableOpacity>
            </View>
        </View>
    )
}

const styles = StyleSheet.create({})