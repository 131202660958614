import { StyleSheet, TouchableOpacity} from 'react-native';
import React from 'react';
import { Collapsible, RenderHtml, Row, Text, View} from 'components';
import { i18n} from 'localize';
import { decodeHTMLEntities} from 'utils';
import { colors} from '@sponsor/assets';
import { TTranslation } from './translations.types';
import { ConfirmationDialog } from 'components/src/ConfirmationDialog';

type TProps = {
  onEditPress: () => void;
  onDeletePress: () => void;
  // to be used general by all trnaslations 
  title?: string,
  subtitle?: string
};

export const TranslationEditPreview: React.FC<TProps & Partial<TTranslation>> = ({
  title,
  subtitle,
  lang,
  onEditPress,
  onDeletePress,
}) => {
  console.log('subtitle',subtitle)
  return (
    <View style={styles.container}>
      <Row containsSimilarItems>
        <ConfirmationDialog
            title={i18n.t("dialogs.deletionConfirmationDialogTitle")}
            desc={i18n.t("dialogs.confirmationDialogTitle")}
            onPress={onDeletePress}
            render={({onPress}) => (
              <TouchableOpacity onPress={onPress}>
                <Text variant='dangerous'>{i18n.t('delete')}</Text>
            </TouchableOpacity>
          )}
        />
        <TouchableOpacity onPress={onEditPress}>
          <Text>{i18n.t('edit')}</Text>
        </TouchableOpacity>
        {/* {visible && <TouchableOpacity  onPress={onViewPress}>
          <Text>{i18n.t('view')}</Text>
        </TouchableOpacity>} */}
      </Row>
      <View variant='sectionContainer'>
        <Text>{lang}</Text>
        {title && <Text variant="sectionTitle">{title}</Text>}
        {/* <Text>{text}</Text> */}
        <Collapsible showHideButton collapsedHeight={150}>
          <RenderHtml source={decodeHTMLEntities(subtitle || '')} />
        </Collapsible>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    borderWidth: 1,
    borderColor: colors.DUSTY_GRAY,
    padding: 15,
  },
});
