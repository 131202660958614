import React, { useEffect } from 'react'
import { useDispatchAction, useNavigation, useParams } from 'hooks'
import { useSelector } from 'react-redux'
import { TStore } from 'store'
import { Button, ContentContainer, ScrollViewOnlyOnMobile, Text, View } from 'components'
import { i18n } from 'localize'
import { FlatList } from 'react-native'
import { ERegisteredTranslations, translationsItems } from './translations.contants'
import { EscreenNames } from 'navigation'
import { TranslationEditPreview } from './TranslationEditPreview.component'
import { isMobile } from 'utils'

export const TranslationsScreen = () => {
   
    const {
      itemName = '', 
      parentId
    }: {
      itemName?: ERegisteredTranslations | '', 
      parentId?: string
    } = useParams()

    const translations = useSelector((state: TStore) =>
        translationsItems[itemName].getAllSelector(state, parentId || ''),
    );
    const {navigate} = useNavigation();
  
    if(!translationsItems[itemName]){
      console.warn('Translation item should be registered first')
    }

    const {
      submitAction,
    } = useDispatchAction(translationsItems[itemName].getAllActionCreator)
  
    const {
        submitAction: submitDeletion,
    } = useDispatchAction(translationsItems[itemName].deleteOneActionCreator)
  
    // const {
    // submitAction,
    // } = useDispatchAction(translationsItems[itemName].getallActionCreator)
    
    useEffect(() => {
      if(parentId){
        submitAction({parentId})
      }
    },[submitAction, parentId])

    return (
      <ContentContainer>
        <View variant="screenContainer">
          {!isMobile && <Text center variant='sectionTitle'>
              {i18n.t('translationsScreen.screenTitle', {itemName: i18n.t(itemName, {scope: 'translationsScreen.itemNames'})})}
          </Text>}
          <ScrollViewOnlyOnMobile>
            {!!parentId && (
              <FlatList
                data={translations}
                ItemSeparatorComponent={() => (
                  <View variant="verticalListSeparator" />
                )}
                ListEmptyComponent={() => (
                  <View variant="sectionContainer">
                    <Text variant="thirdTitle">
                      {i18n.t('creatorPostTranslationsScreen.noTranslation')}
                    </Text>
                  </View>
                )}
                ListHeaderComponent={() => (
                  <View variant="sectionContainer">
                    <Button
                      title={`${i18n.t(
                        'creatorPostTranslationsScreen.addTranslationButtonTitle',
                      )}`}
                      onPress={() => {
                        navigate(EscreenNames.EditTranslationsScreen, {
                          parentId,
                          itemName
                        });
                      }}
                    />
                  </View>
                )}
                renderItem={({item}) => (
                  <TranslationEditPreview
                    {...translationsItems[itemName].getPreviewComponentProps(item)}
                    onEditPress={() => navigate(EscreenNames.EditTranslationsScreen, {parentId, itemName, id: item.id})}
                    onDeletePress={() => submitDeletion({
                        parentId,
                        lang: item.lang,
                        id: item.id
                    })}
                  />
                )}
              />
            )}
          </ScrollViewOnlyOnMobile>
        </View>
      </ContentContainer>
    );
}
