import React from 'react';

import HomeBuild from './components/build';
import HomeCreateCollaborations from './components/create-collaborations ';
import HomeSubscriptions from './components/subscriptions';
import HomeShare from './components/share';
import HomeFooter from './components/footer';

import * as S from './home.styled';
import { Pricings } from './components/pricings/pricings.view';

export default function HomeView() {
  return (
    <S.Container style={{zIndex: 1}}>
      {/* <TouchableOpacity
        onPress={() => {
          Linking.openURL('https://bank.gov.ua/en/news/all/natsionalniy-bank-vidkriv-spetsrahunok-dlya-zboru-koshtiv-na-potrebi-armiyi')
        }}
      >
        <View style={{transform: [{
           rotate: '315deg',
        }], height: 35,
        zIndex: 1000,
        position:'absolute', left: -25, width: 150, top: 25,
        paddingBottom: 15, backgroundColor: 'black'}}>
          <Text center style={{color: 'white'}}>stop war</Text>
          <Text center style={{color: 'white'}}>in Ukraine</Text>
        </View>
      </TouchableOpacity> */}

      <S.GlobalStyle />

      <S.TopLine />

      <HomeSubscriptions />

      <HomeBuild />

      <HomeCreateCollaborations />

      <Pricings />

      <HomeShare />

      <HomeFooter />
    </S.Container>
  );
}
