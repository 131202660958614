import {StyleSheet, View} from 'react-native';
import React from 'react';
import {Route, Routes} from 'react-router-dom';
import {Header} from 'components';
import {
  PostFromCreators,
  MySubscribers,
  SearchPage,
  WithdrawMethodsPage,
  EditTierPage,
  PayoutsPage,
  MySubscriptionsPage,
  MyRevenue,
  ProfileSettings,
  EditCreatorGoalPage,
  Profile,
  CreatorGeneralInfo,
} from 'screens';

import {
  EditPostPage,
  CreatorPrivacy,
  EditCreatorGoals,
  EditCreatorGreetings,
  EditCustomization,
  EditCreatorFriends,
  EditCreatorTranslations,
} from 'screens';

import {
  EditCreatorPage,
  EditBasicCreatorSettings,
  EditTiers,
  EditPosts,
  EditSocials,
} from '../../screens/src/EditCreatorPage/index';

import {commonRoutes} from './commonRoutes.web';
import {CancelSubscriptionScreen} from '../../subscription/CancelSubscriptionScreen';
import {DEVICE_HEIGHT, FOOTER_HEIGHT, HEADER_HEIGHT} from '../../constants';
import {EscreenNames} from './EscreenNames';
import {EditWithdrawMethodScreen} from '../../money-withdraw-methods/EditWithdrawMethodScreen';
import {EditTiersTranslationScreen} from '../../tiersTranslations/EditTiersTranslationScreen';
import {TiersTranslationsScreen} from '../../tiersTranslations/TiersTranslationsScreen';
import {WithdrawScreen} from '../../money-withdraw/WithdrawScreen';
import {CryptoWithdrawalScreen} from '../../screens/src/CryptoWithdraw/CryptoWithdrawScreen';
import { FiatWithdrawalScreen } from '../../screens/src/FiatWithdrawalScreen/FiatWithdrawScreen';
import { EditCreatorMerchantScreen } from '../../creatorMerchant/EditCreatorMerchantScreen';
import { EditScreen } from '../../screens/src/EditScreen';
import { ViewedPosts } from '../../screens/src/ViewedPosts/ViewedPosts';
import { UserPrivacy } from '../../screens/src/UserPrivacy';
import { AccountDeletionScreen } from '../../screens/src/AccountDeletion';
import { PricingPlanScreen } from '../../screens/src/pricingPlans';
import { EditPostTranslationsScreen } from '../../posts-translations/EditPostTranslationsScreen';
import { PostsTranslationsScreen } from '../../posts-translations/PostsTranslationsScreen';
import { EditTranslationsScreen } from '../../translations/EditTranslationScreen';
import { TranslationsScreen } from '../../translations/TranslationsScreen';
import { UrlNotFoundScreen } from '../../screens/src/UrlNotFoundScreen';
import { AdsPlatformAccess } from '../../screens/src/AdsPlatformAccess';

// todo: update screen creating pattern with feature folder flag
// and automatically add to the router
const AuthorizedRoutes = () => {
  return (
    <View style={{flex: 1}}>
      <Header />
      <View style={styles.contentContainer}>
        {/* <Suspense fallback={() => <ActivityIndicator />}> */}
        <Routes>
          <Route path="/" element={<PostFromCreators />} />
          <Route path="/home" element={<PostFromCreators />} />
          <Route path="/payouts" element={<PayoutsPage />} />
          <Route path="/search" element={<SearchPage />} />
          {/* <Route path="/my-page" element={<MyPage/>} /> */}
          <Route path="/withdrawal-methods" element={<WithdrawMethodsPage />} />

          {/* tiers should take all screen width */}
          <Route path="/edit/about/tiers" element={<EditTiers />} />

          <Route path="/edit">
            {/**
             *  TODO: create array for pages
             *  `cause all route has elements with similar naming
             */}

            <Route path="about" element={<EditCreatorPage />}>
              {/* TODO: add nested navigators */}
              <Route index={true} element={<EditBasicCreatorSettings />} />
              <Route path="customization" element={<EditCustomization />} />
              <Route path="greetings" element={<EditCreatorGreetings />} />
              {/* todo: update navigation hook to navigte inside nested routes */}
              <Route path={'payments'} element={<WithdrawMethodsPage />} />
              <Route path="privacy" element={<CreatorPrivacy />} />
              <Route path="goals" element={<EditCreatorGoals />} />

              <Route path="posts" element={<EditPosts />} />
              <Route path="friends" element={<EditCreatorFriends />} />
              <Route
                path="translations"
                element={<EditCreatorTranslations />}
              />
              <Route path={'socials'} element={<EditSocials />} />
              <Route
                path={EscreenNames.CreatorTiersTranslation}
                element={<TiersTranslationsScreen />}
              />
              <Route
                key={EscreenNames.AdsPlatformAccess}
                path={EscreenNames.AdsPlatformAccess}
                element={<AdsPlatformAccess />}
              />,
            </Route>

            <Route
              path={EscreenNames.EditPostTranslations}
              element={<EditPostTranslationsScreen />}
            />
            <Route
              path={EscreenNames.PostsTranslations}
              element={<PostsTranslationsScreen />}
            />
            <Route 
              path={EscreenNames.EditTranslationsScreen}
              element={<EditTranslationsScreen />}
            />
            <Route path="*" element={<EditScreen />}>
              <Route path="tier" element={<EditTierPage />} />
              <Route path="goal" element={<EditCreatorGoalPage />} />
              <Route path={'posts/new'} element={<EditPostPage />} />
              <Route path={'posts/edit'} element={<EditPostPage />} />
            </Route>
          </Route>

          {/* todo: think about grouping such routes */}
          <Route
            path={EscreenNames.EditTierTranslation}
            element={<EditTiersTranslationScreen />}
          />
          <Route
            path={EscreenNames.EditCreatorMerchantScreen}
            element={<EditCreatorMerchantScreen />}
          />
          <Route
            path={EscreenNames.MoneyWithdrawMethodEdit}
            element={<EditWithdrawMethodScreen />}
          />

          <Route
            path={EscreenNames.WithdrawScreen}
            element={<WithdrawScreen />}
          />

          <Route
            path={EscreenNames.TranslationsScreen}
            element={<TranslationsScreen />}
          />


          <Route
            path={EscreenNames.CryptoWithdrawalScreen}
            element={<CryptoWithdrawalScreen />}
          />

          <Route
            path={EscreenNames.FiatWithdrawalScreen}
            element={<FiatWithdrawalScreen />}
          />

          <Route path="/subscriptions" element={<MySubscriptionsPage />} />
          <Route path="/subscribers" element={<MySubscribers />} />
          <Route path="/revenue" element={<MyRevenue />} />
          <Route path="/profile-settings" element={<ProfileSettings />} />

          <Route path={EscreenNames.ViewedPosts} element={<ViewedPosts />} />

          <Route
            path="/cancel-subscription"
            element={<CancelSubscriptionScreen />}
          />

          {/* todo: hide, not publish yet */}
          <Route
            key={EscreenNames.Profile}
            path={EscreenNames.Profile}
            element={<Profile />}
          />,
          <Route
            key={EscreenNames.UserPrivacy}
            path={EscreenNames.UserPrivacy}
            element={<UserPrivacy />}
          />,
          <Route
            key={EscreenNames.AccountDeletion}
            path={EscreenNames.AccountDeletion}
            element={<AccountDeletionScreen />}
          />,
        
           <Route
            key={EscreenNames.CreatorGeneralInfo}
            path={EscreenNames.CreatorGeneralInfo}
            element={<CreatorGeneralInfo />}
          />,
           <Route
            key={EscreenNames.PricingPlanScreen}
            path={EscreenNames.PricingPlanScreen}
            element={<PricingPlanScreen />}
          />,
          {commonRoutes}
          {/* should be the last one */}
          <Route path="*" element={<UrlNotFoundScreen />} />            
        </Routes>
        {/* </Suspense> */}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  contentContainer: {
    minHeight: DEVICE_HEIGHT - FOOTER_HEIGHT / 2 - HEADER_HEIGHT,
  },
});

export default AuthorizedRoutes;
