import React from 'react';
// import loadable from '@loadable/component';
import {TEmojiPickerProps} from './types';
import Picker from 'emoji-picker-react';

// const Picker = loadable(() => import('emoji-picker-react'));
//@ts-ignore
export const EmojiPicker: React.FC<TEmojiPickerProps> = props => (
  <>
    <Picker {...props} />
  </>
);
