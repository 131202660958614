import React, {useCallback, useEffect, useRef} from 'react';
import {StyleSheet, ScrollView, Platform} from 'react-native';
import {i18n} from 'localize';

import {
  Text,
  ListItem,
  NotAuthorizedUser,
  Avatar,
  Row,
  Column,
  View,
  ContentContainer,
  ActionResult,
  Button,
  textStyles,
} from 'components';

import {moderateScale} from 'react-native-size-matters';
import DeviceInfo from 'react-native-device-info';
import {useDispatch, useSelector} from 'react-redux';
import {
  getCreatorInfo,
  logoutStart,
  selectCreatorBasicInfo,
  selectIsAuthenticated,
  selectUser,
} from 'store';
import {
  useDispatchAction,
  useNavigation
} from 'hooks';
import {
  EscreenNames
} from 'navigation';
import { LanguageModalPicker, TLanguagePickerRef } from '../../../containers/LanguageModalPicker/LanguageModalPicker';
import { ActionResultDefaultRenders } from 'components/src/ActionResult/ActionResultDefaultRenders';
import Animated, { FadeInRight } from 'react-native-reanimated';
import { colors } from '@sponsor/assets';

const S = StyleSheet.create({
  text: {
    fontSize: 16,
  },
  idcode: {
    height: 45,
    justifyContent: 'center',
    backgroundColor: 'white',
    borderRadius: 15,
    padding: 15,
    marginTop: 5,
  },
  avatar: {
    // borderColor: colors.orange,
    // borderWidth: 1,
    borderRadius: 50,
    height: 95,
  },
  langBtnText: {
    fontWeight: 'bold',
    fontSize: 16,
  },
  title: {
    fontSize: 24,
    fontWeight: 'bold',
  },
  footer: {
    opacity: 0.5,
    // fontSize: widthPercentageToDP(5),
    lineHeight: 24,
  },
});

/**
 * screen is only for buttons
 * that navigate to another screen,
 * do not place any logic here 
 */
export const Profile = ({}) => {
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const user = useSelector(selectUser);
  const creator = useSelector(selectCreatorBasicInfo);

  const langRef = useRef<TLanguagePickerRef>();

  const {navigate} = useNavigation();
  const dispatch = useDispatch();

  const {
    error,
    isLoading,
    submitAction: submitGetCreatorInfo
  } = useDispatchAction(getCreatorInfo)

  useEffect(() => {
    if(!creator?.id){
      submitGetCreatorInfo(null)
    }
  }, [
    creator,
    submitGetCreatorInfo
  ]);


  const logout = useCallback(() => {
    dispatch(logoutStart());
  }, [dispatch]);
  // const _renderHeader = () => (
  //   <BackHeaderCenter
  //     title="Me"
  //     hideBack
  //     // rightComponent={<ShippingCartIcon />}
  //   />
  // );

  const profileSections = [
    {
        title: i18n.t('profile.myaccount'),   
        sectionTitle: true,
    },
    {
        title: i18n.t('profile.mydetails'),   
        onPress: () => navigate(EscreenNames.ProfileSettings),
        disabled :!isAuthenticated,
        bottomDivider: true,
        chevron: {color: 'black'}
    },
    {
        title: i18n.t('profile.subscriptions'),   
        onPress: () => navigate(EscreenNames.Subscriptions),
        disabled :!isAuthenticated,
        bottomDivider: true,
        chevron: {color: 'black'}
    },
    {
        title: i18n.t('profile.settings'),   
        sectionTitle: true,
        bottomDivider: true
    },
    {
      title: i18n.t('profile.security'),   
      onPress: () => navigate(EscreenNames.UserPrivacy),
      disabled :!isAuthenticated,
      bottomDivider: true,
      chevron: {color: 'black'}
    },
    {
      title: i18n.t('profile.languageChangeTitle'),   
      disabled :!isAuthenticated,
      bottomDivider: true,
      onPress:() => langRef.current?.toggleModal?.(),
      RightElement: <LanguageModalPicker 
        //@ts-ignore
        ref={langRef}/>
    },
    {
      title: i18n.t('profile.help'),   
      sectionTitle: true,
      bottomDivider: true
    },
    {
      title: "FAQ",   
      onPress: () => navigate(EscreenNames.TermOfUse),
      bottomDivider: true,
      chevron: {color: 'black'}
    },
    {
      title: i18n.t('accountDeletionScreen.screenTitle'),   
      onPress: () => navigate(EscreenNames.AccountDeletion),
      bottomDivider: true,
      chevron: {color: 'black'}
    },
  ] 

  return (
    <ContentContainer>
      <View variant="screenContainer">
        <ScrollView showsVerticalScrollIndicator={false}>
          {/* {_renderHeader()} */}
          <View style={{flex: 1, paddingVertical: 5, backgroundColor: 'white'}}>
            {isAuthenticated === true && (
              <Row containsSimilarItems containerStyle={{padding: 10}}>
                {user?.avatarUri && (<Column>
                  <Avatar uri={user?.avatarUri} />
                </Column>)}
                <Column>
                  <Text variant="secondaryTitle">{`${user?.name}${user?.last_name ? ' ' + user?.last_name : ""}`}</Text>
                </Column>
              </Row>
            )}
            {!isAuthenticated && (
              <View style={{padding: 15}}>
                <NotAuthorizedUser />
              </View>
            )}
            <View
              style={{
                flex: 1,
                borderRadius: 18,
                marginVertical: 5,
              }}
              >
                <ActionResult 
                  error={error}
                  isLoading={isLoading}
                  {...ActionResultDefaultRenders}
                  renderSuccessMessage={() => <></>}
                  renderError={(err) => (
                    <>
                      <Text variant='inputError'>
                        {i18n.t("profile.creatorRequestErrorMesssage")}
                      </Text>
                      <Button
                        title={i18n.t("btns.titles.repeat")} 
                        onPress={() => submitGetCreatorInfo(null)}
                      />
                    </>
                  )}
                  renderLoading={() => (
                    <Text variant='formHint'>
                        {i18n.t('profile.creatorLoadingMessage')}
                    </Text>
                  )}
                />
              {!creator?.id && (
                <>
                  <ListItem
                    titleStyle={styles.sectionTitle}
                    title={i18n.t('profile.mycreatoraccount')}
                  />
                  <ListItem
                    onPress={() => navigate(EscreenNames.CreatorSettingEdit)}
                    disabled={!isAuthenticated}
                    chevron={{color: 'black'}}
                    bottomDivider
                    title={i18n.t('profile.creatorDetails')}
                  />
                </>
              )}
              {profileSections.map((item, index) => (
                <Animated.View
                  entering={FadeInRight.delay(index * 150).duration(600)}
                >
                  <ListItem 
                      {...item}
                      titleStyle={item.sectionTitle ? {...styles.sectionTitle, ...textStyles.bold} : {}}
                  />
                </Animated.View>
              ))}

              <ListItem title={''} />
              {isAuthenticated && (
                <ListItem
                  onPress={logout}
                  title={i18n.t('profile.logoutButtonTitle')}
                  chevron={{color: 'black'}}
                />
              )}
              {/* {version.length> 0 && <ListItem
                  containerStyle={{backgroundColor: colors.gray}}
                  title={version + " " + fontScale}
                  titleStyle={{opacity: 0.5,
                    // fontSize: widthPercentageToDP(5)
                  }}
                />} */}
              {/* <ListItem
                containerStyle={{backgroundColor: colors.gray}}
                title={
                  'The Peony has no association and/or affiliation with the brands whose product are offered for sale on its App.\nThe  authentication of said products is performed independently by The Peony.'
                }
                titleStyle={S.footer}
              /> */}
              {Platform.OS !== 'web'  && (<ListItem
                title={`${DeviceInfo.getVersion() || ""} ${DeviceInfo.getBuildNumber() || ""}`}
                titleStyle={S.footer}
              />)}
            </View>
            {/* end white block */}
          </View>
        </ScrollView>
      </View>
    </ContentContainer>
  );
};

const styles = StyleSheet.create({
  sectionTitle: {
    fontSize: moderateScale(18),
    paddingTop: 15,
    paddingBottom: 10,
  },
});
