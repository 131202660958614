import {useEffect, useState} from 'react';
import {LOG} from 'utils';
import {TCheckoutData} from 'cloudipsp-node-js-sdk';
import {isEmpty} from 'lodash';
import {MoneyWithdrawApiService} from '../../../money-withdraw/money-withdraw.api';

export const useRetrieveFondyCardVerificationLink = (
  params: TCheckoutData,
): {
  link: string;
  error?: string;
  loading: boolean;
} => {
  const [link, setLink] = useState('');
  const [error, setError] = useState('');

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    (async function () {
      if (isEmpty(params) || loading || link || error) {
        return;
      }
      try {
        setLoading(true);
        const {data} =
          await MoneyWithdrawApiService.postGetCardVerificationPymentLink(
            params,
          );
        setLink(data.checkout_url);
      } catch (err: any) {
        LOG(err);
        setError(JSON.stringify(err));
      } finally {
        setLoading(false);
      }
    })();
  }, [params, loading, link, error]);

  return {
    link,
    error,
    loading,
  };
};
