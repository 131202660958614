import React, {ReactNode, useCallback, useState} from 'react';
import {StyleSheet, TouchableOpacity} from 'react-native';
import moment from 'moment';
// import ParsedText from 'react-native-parsed-text';
import {
  Text,
  Image,
  View,
  VideoPlayer,
  Column,
  RenderHtml,
  Collapsible,
  Button,
  Row,
  Icon,
} from 'components';
import { i18n } from 'localize';
import { decodeHTMLEntities } from 'utils';
import { EscreenNames } from 'navigation';
import { useNavigation } from 'hooks';
import { EPostEnum, TPost } from 'types';
import { DEVICE_HEIGHT } from '@sponsor/constants';
import { colors } from '@sponsor/assets';
import { TouchableWithoutFeedback } from 'react-native-gesture-handler';
import { noop } from 'lodash';

type TProps = {
  width?: string | number;
  onLockedPostPress: () => void;
  post: TPost;
  Header: ReactNode;
  viewCustomization: any;
  children: any;
};

export const PostBase: React.FC<TProps> = React.memo(({
  width,
  onLockedPostPress = noop,
  post,
  Header,
  viewCustomization,
  children,
}) => {
  const {btnColor} = viewCustomization || {};

  const {
    id,
    unlocked,
    text,
    title,
    //TODO: remove one
    createdAt,
    videoLink,
    tizerTitle,
    visibilityType,
    tizer_image,
    imageUri,
  } = post || {};
  
  const {navigate} = useNavigation();
  const navigateToPostPage = useCallback(() => {
    //todo: think what's better (new tab or not )
    navigate(EscreenNames.PostScreen, {id}, {newTab: true});
  }, [navigate, id]);

  const [postWidth, setWidth] = useState(0);

  const Wrapper = unlocked ? View : TouchableWithoutFeedback

  return (
    <Wrapper
      onPress={onLockedPostPress}
      style={styles.container}
      onLayout={({nativeEvent: {layout}}) => setWidth(layout?.width)}
    >
      {Header}
      {unlocked ? (
        <>
          {imageUri && !videoLink && (
            <Image variant="postImage" source={{uri: imageUri}} />
          )}
          {videoLink && (
            <VideoPlayer
              style={[
                styles.videoPlayer
              ]}
              width={postWidth}
              src={videoLink}
            />
          )}
        </>
      ) : (
        (tizer_image || imageUri) && (
          <Image
            source={{
              uri: tizer_image || imageUri,
            }}
            resizeMode="cover"
            variant="postImage"
          />
        )
      )}

      <View style={styles.contentContainer}>
          <Column>
              <Row columnCenter containerStyle={{justifyContent: 'space-between'}}>
                  <Text>
                    {`${moment(createdAt).format('LL HH:mm').toLowerCase()}`}
                  </Text>
                  {visibilityType != EPostEnum.public && (
                    unlocked ? (
                      <Icon 
                        size={20}
                        color={colors.GREEN_OFFICIAL}
                        name="unlocked"
                      />
                    ): (
                      <Icon 
                        size={20}
                        name="locked"
                      />
                    )
                  )}
              </Row>

              <TouchableOpacity onPress={navigateToPostPage}>
              <View style={styles.titleRow}>
                  <Text bold style={styles.title}>{title}</Text>
              </View>
              </TouchableOpacity>
           </Column>

        {unlocked === true ? (
          !!text && (
            <Collapsible
              showHideButton
              btnColor={btnColor}
              collapsedHeight={150}>
              <RenderHtml
                source={decodeHTMLEntities(text || '')}
                textStyle={{
                  fontSize: 20,
                }}
              />
            </Collapsible>
          )
        ) : (
          <View style={styles.lockedBlock}>
            <Text variant="secondaryTitle">
              {tizerTitle || i18n.t('postCard.toUnlockSubscribe')}
            </Text>
            <Button
              onPress={onLockedPostPress}
              title={i18n.t('buySubscriptionBtnTitle')}
            />
          </View>
        )}
        <View style={{paddingTop: 8}}>
          {children}
        </View>
      </View>
    </Wrapper>
  );
});

const styles = StyleSheet.create({
  title: {
    fontSize: 22,
    flexShrink: 1,
    marginVertical: 8,
  },
  titleRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  container: {
    // move to general container styles
    // flex:1,
    // padding: 14,
    // backgroundColor: 'white',
  },
  contentContainer: {
    // minHeight: 150,
    padding: 10,
  },
  lockedBlock: {
    paddingBottom: 15,
  },
  videoPlayer: {
    // height: 300,
    width: '100%',
    // recommended from player docs
    minHeight: 270,
    //todo: check ratio, choose best one
    // aspectRatio: isMobile ?  2/1 : 3/1,
    //for tv platforms
    maxHeight: DEVICE_HEIGHT - 150
  },
});
