import React, {useCallback, useEffect, useRef, useState} from 'react';
import {Text, View, Row, Icon } from '..';
import {TCreatorGoal} from '../../../types/src/creators';
import {i18n} from 'localize';
import * as Progress from 'react-native-progress';
import {colors} from '../../../assets';
import Carousel, { ICarouselInstance } from 'react-native-reanimated-carousel';
import { DEVICE_WIDTH } from '@sponsor/constants';
import { FlatList, Platform, TouchableOpacity } from 'react-native';

const SLIDER_MAX_WIDTH = 650;
const SLIDER_CONTAINER_PADDING = 20

export const CreatorGoalList: React.FC<{
  goals: TCreatorGoal[];
  containerStyle: any;
  barColor: string;
  goalsLabel: string;
  currentCount: number;
}> = ({
  goals,
  barColor,
  goalsLabel = '',
  currentCount,
  containerStyle = {},
}) => {

  const initialIndex = goals?.length - 1
  const carRef = useRef<ICarouselInstance | null>(null);
  const slidesHeightRef = useRef<{[key in any]:number}>({});

  const [sliderHeight, setSliderHeight] = useState(0);
  const [slideIndex, setSlideIndex] = useState(initialIndex);

  const calculateGoalProgress = useCallback(
    (goal: TCreatorGoal) => {
      //persantage could be 0
      if (goal.persantage > -1) {
        return goal.persantage;
      }
      //todo: remove goal?.currentCount ???
      return (goal?.currentCount || currentCount) / goal.count;
    },
    [currentCount],
  );

  const renderGoalProgressText = useCallback(
    goal => {
      if (goal.persantage > -1) {
        return `${goal.persantage} %`;
      }
      return `${goal.persantage ? goal.persantage : currentCount} / ${Number(
        goal.count,
      ).toLocaleString()}`;
    },
    [currentCount],
  );
  
  const renderItem: (params: {item: TCreatorGoal, index: number}) => JSX.Element = ({item, index}) => (
    <View 
      key={item?.id || index}
      style={{ 
        // flexShrink: 1,
        width: DEVICE_WIDTH > (SLIDER_MAX_WIDTH + SLIDER_CONTAINER_PADDING * 2) ? SLIDER_MAX_WIDTH : DEVICE_WIDTH - 50
      }} 
      // don't use flex:1, we setup height manually, because slider don't know how
      // to dynamically change height :(
      onLayout={(event) => {
        const height = event.nativeEvent?.layout?.height;

        if(
          !slidesHeightRef.current[index]
        ){
          slidesHeightRef.current[index] = height
        }
        // need to setup height for initial render
        if(
          !sliderHeight && 
          height && 
          index === slideIndex
        ){
          setSliderHeight(height)
        }
      }}
    >
      <View variant="sectionContainer">
        <Text variant="description">
          <Text variant="description">{goalsLabel}</Text>
          {renderGoalProgressText(item)}
        </Text>
      </View> 
      <View>
        <Progress.Bar
          progress={calculateGoalProgress(item)}
          color={barColor || colors.GREEN_OFFICIAL}
          width={DEVICE_WIDTH < 650 ? DEVICE_WIDTH - 75 : 650}
        />
      </View>
      <View variant="sectionContainer">
        <Text
          style={{textAlign: 'left',}}
          variant="description"
        >
          {item.description}
        </Text>
      </View>
    </View>
  )

  useEffect(() => {
    carRef?.current?.next({count: initialIndex})
  }, [])

  useEffect(() => {
    const h = slidesHeightRef.current[slideIndex];
    if(h){
      setSliderHeight(slidesHeightRef.current[slideIndex])
    }
  },[slideIndex]);

  return (
    <View
      bordered
      style={[
        {
          flex:1,
          borderWidth: 1,
          padding: SLIDER_CONTAINER_PADDING,
        },
        containerStyle,
      ]}>
      <Text variant="sectionTitle">{i18n.t('creatorGoals.goalListTitle')}</Text>
      {/* todo: test on web new slider */}
      {Platform.OS === 'web' ? (<Carousel
          // loop
          ref={carRef}
          // todo: refactor
          width={DEVICE_WIDTH > (SLIDER_MAX_WIDTH + SLIDER_CONTAINER_PADDING * 2) ? SLIDER_MAX_WIDTH : DEVICE_WIDTH - 50} 
          height={sliderHeight}
          // autoPlay
          data={goals}
          scrollAnimationDuration={1000}
          onSnapToItem={(index) => setSlideIndex(index % goals.length)}
          renderItem={renderItem}
      />) : (
        <View>
        {renderItem({item: goals[slideIndex], index:slideIndex})}
      </View>
      )}
      <Row containerStyle={{paddingTop: 10}} rowCenter>
        <TouchableOpacity onPress={() => {
            carRef?.current?.prev()
            if(Platform.OS !== 'web'){
              const nextIndex = ((slideIndex - 1) + goals.length) % goals.length
              setSlideIndex(nextIndex)
            }
          }}>
          <Icon size={18} name="arrow-left"/>
        </TouchableOpacity>
        <TouchableOpacity onPress={() => {
              carRef?.current?.next();
              if(Platform.OS !== 'web'){
                setSlideIndex((slideIndex + 1) % goals.length)
              }
          }} 
          style={{transform: [{rotate: '180deg'}]}}
        >
          <Icon size={18} name="arrow-left"/>
        </TouchableOpacity>
      </Row>
      <Text style={{paddingTop: 10}} center>{`${slideIndex+1} / ${goals?.length}`}</Text>
      {/* <Swiper
        innerContainerStyle={{flex: 1}}
        // containerStyle={{height: 250}}
        controlsProps={{
          dotsTouchable: true,
          dotActiveStyle: {backgroundColor: barColor || colors.GREEN_OFFICIAL},
          prevTitle: i18n.t('creatorGoals.prevButtonTitle'),
          nextTitle: i18n.t('creatorGoals.nextButtonTitle'),
          prevPos: goals?.length !== 0,
          nextPos: goals?.length !== 0,
        }}
        loop>
        {map(goals, (goal, index) => (
          <View key={goal?.id || index} style={{flex: 1}}>
            <View variant="sectionContainer">
              <Text variant="creatorPageMainText">
                <Text variant="thirdTitle">{goalsLabel}</Text>
                {renderGoalProgressText(goal)}
              </Text>
            </View>
            <View>
              <Progress.Bar
                progress={calculateGoalProgress(goal)}
                color={barColor || colors.GREEN_OFFICIAL}
                width={750}
              />
            </View>
            <View variant="sectionContainer">
              <Text
                style={{textAlign: 'left'}}
                variant="creatorPageMainText"
                // numberOfLines={5}
              >
                {goal.description}
              </Text>
            </View>
          </View>
        ))}
      </Swiper> */}
    </View>
  );
};
