import {Linking, StyleSheet} from 'react-native';
import React, {useMemo} from 'react';
import {Icon, Row, Text, View} from '../../../components';
import {forEach, keys, map} from 'lodash';
import {colors} from '../../../assets';
import {TouchableOpacity} from 'react-native';
import {i18n} from 'localize';
import {ECreatorSocials} from 'types';

type TProps = {
  socials: {
    [key in ECreatorSocials]: string;
  };
  customizaton: {
    btnColor?: string;
  };
};

export const CreatorSocials: React.FC<TProps> = ({
  socials = {},
  customizaton,
}) => {
  const iconColor = customizaton?.btnColor || colors.DUSTY_GRAY;

  const SocialsAvailable = useMemo(() => {
    let availableSocials: any[] = [];
    forEach(keys(socials), (key) => {
      if(!socials[key as unknown as ECreatorSocials])return;
      availableSocials.push({
        name: key,
        //@ts-ignore
        link: socials[key],
      });
    });
    return availableSocials;
  }, [socials]);

  return (
    <View style={{paddingHorizontal: 10}}>
      <Text center variant="secondaryTitle">
        {i18n.t('creatorPage.socials')}
      </Text>
      <Row rowCenter flexWrap containerStyle={{marginTop: 5}}>
        {map(SocialsAvailable, ({name, link}, index) => (
          <TouchableOpacity
            style={[styles.iconContainer, index == 0 && {marginLeft: 0}]}
            key={name}
            onPress={() => Linking.openURL(link)}
          >
            <Icon name={name} size={22} color={iconColor} />
          </TouchableOpacity>
        ))}
      </Row>
    </View>
  );
};

const styles = StyleSheet.create({
  iconContainer: {
    margin: 10,
    justifyContent: 'center',
    alignItems: 'center',
    width: 40 // todo: fix icons sizes in svg
  },
});
