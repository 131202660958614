import {
  Button,
  ContentContainer,
  CreatorCard,
  FormInput,
  Row,
  View,
  Text,
  ActionResult,
} from 'components';
import React, {useState} from 'react';
import {FlatList, Keyboard, Platform} from 'react-native';
import {useSelector} from 'react-redux';
import {Placeholder, PlaceholderLine, Shine} from 'rn-placeholder';
import {trim} from 'lodash';
import {i18n} from 'localize';
import {searchCreators, selectFoundCreators} from 'store';
import { isMobile } from 'utils';
import { useDispatchAction } from 'hooks';

export const SearchPage = () => {
  
  const [query, setQuery] = useState('');

  const {
    isLoading,
    error,
    submitAction: submitSearchCreators
  } = useDispatchAction(searchCreators, {
    onFinish: Keyboard.dismiss
  });

  const foundCreators = useSelector(selectFoundCreators);
  
  return (
    <ContentContainer>
      <View style={{paddingHorizontal: 15, flex:1}}>
        <Row containerStyle={{paddingVertical: 20}} flexWrap>
          <FormInput
            onChangeText={text => setQuery(text)}
            value={query}
            placeholder={i18n.t('search.searchInputPlaceholder')}
            autoCapitalize="none"
            onEndEditing={() => submitSearchCreators({         
              query: trim(query),
            })}
            formContainerStyle={{
              padding: 0, 
              height: 60, //TODO: remove
              flex: Platform.OS == 'web' ? 1 : undefined, 
              minWidth: isMobile ? '100%' : undefined
            }}
          />
          {/* <Column> */}
          <Button
            title={i18n.t('search.search')}
            primary
            containerStyle={isMobile ? { width: '100%'} : {paddingLeft: 10}}
            onPress={() => submitSearchCreators({         
              query: trim(query),
            })}
          />
          {/* </Column> */}
        </Row>
        <View variant='sectionContainer'>
          <ActionResult 
                isLoading={isLoading}
                error={error}
                renderLoading={() => (
                  // @ts-ignore
                  <Placeholder Animation={Shine}>
                    <PlaceholderLine style={{borderRadius: 0, height: 75}} />
                    <PlaceholderLine style={{borderRadius: 0, height: 75}} />
                  </Placeholder>
                )}
                renderError={(err) => (
                  <Text variant='inputError'>
                      {err}
                  </Text>
                )}
              />
          </View>

        {!isLoading && (
          <FlatList
            data={foundCreators}
            contentContainerStyle={{justifyContent: 'center'}}
            ItemSeparatorComponent={() => (
              <View variant="verticalListSeparator" />
            )}
            ListEmptyComponent={
              !query
              ? null
              : (<Text center variant="secondaryTitle">
                {i18n.t('search.noResults')}
              </Text>)
            }
            renderItem={({item}) => (
              <CreatorCard
                creatorTitle={item?.title}
                creatorAvatarUri={item.avatarUri}
                description={item.description}
                creatorPageURL={item.pageURL}
              />
            )}
          />
        )}
      </View>
    </ContentContainer>
  );
};
