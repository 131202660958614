import { AxiosResponse } from 'axios';
import {
  TPostForgotPasswordOutput,
  TPostForgotPasswordInput,
  TPostResetPasswordInput,
  TPostResetPasswordOutput,
  TGetPayoutsOutput,
  TPostPublishCreatorInput,
  TPostPublishCreatorOutput,
  TUpdateProfileOutput,
  TUpdateProfileInput,
  TGetCreatorSubscribersOutput,
  TPostAddNewCommentInput,
  TPostAddNewCommentOutput,
  ApiRequest,
  TGetTiersInput,
  TGetTiersOutput,
  TGetCreatorPostsInput,
  TGetCreatorPostsOutput,
  TGetPostsOutput,
  TPostPostInput,
  TPostPostOutput,
  TUpdatePostInput,
  TUpdatePostOutput,
  TUpdateTierInput,
  TUpdateTierOutput,
  TDeleteTierOutput,
  TDeleteTierInput,
  TGetProfileOutput,
  TGetCreatorsOutput,
  TPostToggleEmodjiInput,
  TPostToggleEmodjiOutput,
  TPostAddNewTierInput,
  TPostAddNewTierOutput,
  TGetCreatorOutput,
  TGetTierOutput,
  TGetTierInput,
  TPostLoginInput,
  TPostLoginOutput,
  TUpdateCreatorInput,
  TUpdateCreatorOutput,
  TPostSignupInput,
  TPostSignupOutput,
  TDeleteSubscriptionOutput,
  TDeleteSubscriptionInput,
  TPostRefreshToken,
  TSearchInput,
  TGetPostComments,
} from 'types';
import {
  TCreator,
  TDeleteCreatorGoalInput,
  TDeleteCreatorGoalOutput,
  TGetCreatorInput,
  TGetCreatorStats,
  TMyGetCreatorOutput,
  TPostAccountDeletionInput,
  TPostAccountDeletionOutput,
  TPostAddNewCreatorGoalInput,
  TPostAddNewCreatorGoalOutput,
  TPostChangeAcceptanceStatusInput,
  TPostChangeAcceptanceStatusOutput,
  TUpdateCreatorGoalInput,
  TUpdateCreatorGoalOutput,
} from '../../types/src';

import {NetworkService} from './NetworkService';

/**
 * main purpose of this service
 * is provide clear interface for APIs inputs/outputs
 * post requests, unlike update requests, doesn't always update data
 * post requests are used for adding new data
 */

//TODO: move functions to the feature folders
class _ApiService {
  postSignup = (inputs: TPostSignupInput): ApiRequest<TPostSignupOutput> => {
    return NetworkService.post('/v1/auth/register', inputs);
  };

  postLogin = (inputs: TPostLoginInput): ApiRequest<TPostLoginOutput> => {
    return NetworkService.post('/v1/auth/login', inputs);
  };

  postForgotPassword = (
    {lang, ...inputs}: TPostForgotPasswordInput,
  ): ApiRequest<TPostForgotPasswordOutput> => {
    return NetworkService.post(`/v1/auth/forgot-password?lang=${lang}`, inputs);
  };

  postResetPassword = ({
    token,
    password,
  }: TPostResetPasswordInput): ApiRequest<TPostResetPasswordOutput> => {
    return NetworkService.post(`/v1/auth/reset-password?token=${token}`, {
      password,
    });
  };

  postAccountDeletion = (params: {}): ApiRequest<TPostAccountDeletionOutput> => {
    return NetworkService.post(`/v1/auth/account/delete`);
  };

  // creator

  postPublishCreator = async (
    inputs: TPostPublishCreatorInput,
  ): ApiRequest<TPostPublishCreatorOutput> => {
    return NetworkService.post('/v1/creator', inputs);
  };

  updateCreator = async (
    inputs: TUpdateCreatorInput,
  ): ApiRequest<TUpdateCreatorOutput> => {
    return NetworkService.put('/v1/creator', inputs);
  };

  getCreator = async (params: TGetCreatorInput): ApiRequest<TGetCreatorOutput> => {
    return NetworkService.get(`/v1/creator/${params.pageURL}?lang=${params.lang}`);
  };

  exportSubscribers = async () => {
    return NetworkService.get('/v1/creator/export-subscribers');
  };

  getMyCreatorPage = async (): ApiRequest<TMyGetCreatorOutput> => {
    return NetworkService.get('/v1/creator');
  };

  // profile
  getProfile = async (): ApiRequest<TGetProfileOutput> => {
    return NetworkService.get('/v1/users/profile');
  };

  // profile
  updateProfile = async (
    data: TUpdateProfileInput,
  ): ApiRequest<TUpdateProfileOutput> => {
    return NetworkService.put('/v1/users/profile', data);
  };

  // tiers
  postAddNewTier = async (
    inputs: TPostAddNewTierInput,
  ): ApiRequest<TPostAddNewTierOutput> => {
    return NetworkService.post('/v1/creator/tiers', inputs);
  };

  getTier = async ({tierId}: TGetTierInput): ApiRequest<TGetTierOutput> => {
    return NetworkService.get(`/v1/creator/tiers/${tierId}`);
  };

  getTiers = async (): ApiRequest<TGetTierOutput> => {
    return NetworkService.get('/v1/creator/tiers');
  };

  getCreatorTiers = async ({
    pageURL,
  }: TGetTiersInput): ApiRequest<TGetTiersOutput> => {
    return NetworkService.get(`/v1/creator/${pageURL}/tiers`);
  };

  updateTier = async (
    update: TUpdateTierInput,
  ): ApiRequest<TUpdateTierOutput> => {
    return NetworkService.put('/v1/creator/tiers', {
      ...update,
    });
  };

  // TODO: make delete request at one form
  deleteTier = async (
    params: TDeleteTierInput,
  ): ApiRequest<TDeleteTierOutput> => {
    return NetworkService.delete('/v1/creator/tiers', params);
  };

  // posts
  getCreatorPosts = async ({
    pageURL,
  }: TGetCreatorPostsInput): ApiRequest<TGetCreatorPostsOutput> => {
    return NetworkService.get(`/v1/posts?pageURL=${pageURL}`);
  };

  getUploadedPosts = async () => {
    return NetworkService.get('/v1/posts?creator=true');
  };

  //todo: rename with getPublicPosts
  getUserPosts = async (params?: TSearchInput): ApiRequest<TGetPostsOutput> => {
    return NetworkService.get(
      `/v1/posts?page=${params?.page || 1}&limit=${params?.limit || 10}&ids=${
        params?.ids ? params?.ids.join(',') : ''
      }&lang=${params?.lang || ''}`,
    );
  };

  // getPost = async ({id}: {id: string}): ApiRequest<TGetPostsOutput> => {
  //   return NetworkService.get(`/v1/posts?ids=${id}`);
  // };

  postPost = async (post: TPostPostInput): ApiRequest<TPostPostOutput> => {
    return NetworkService.post('/v1/posts', post);
  };

  updatePost = async ({
    id,
    ...update
  }: TUpdatePostInput): ApiRequest<TUpdatePostOutput> => {
    return NetworkService.put('/v1/posts', {id, ...update});
  };

  removePost = async (id: string): ApiRequest<any> => {
    return NetworkService.delete(`/v1/posts/${id}`);
  };

  // subscriptions
  deleteSubscription = async (
    params: TDeleteSubscriptionInput,
  ): ApiRequest<TDeleteSubscriptionOutput> => {
    return NetworkService.delete(`/v1/subscriptions/${params?.subscriptionId}`);
  };

  postStartFollowingCreator = async (params: any) => {
    return NetworkService.post('/v1/followers', params);
  };

  // TODO: check all delete method, is it ok to use id in the url???
  deleteFollowingForCreator = async (params: any) => {
    return NetworkService.delete(`/v1/followers/${params?.creatorId}`);
  };

  getCreators = async ({
    query,
  }: {
    query: string;
  }): ApiRequest<TGetCreatorsOutput> => {
    return NetworkService.get(`/v1/search?q=${query}`);
  };

  // reactions
  postToggleEmodji = async (
    params: TPostToggleEmodjiInput,
  ): ApiRequest<TPostToggleEmodjiOutput> => {
    return NetworkService.post('/v1/posts/reaction', params);
  };

  // subscribers
  getCreatorSubscribers =
    async (): ApiRequest<TGetCreatorSubscribersOutput> => {
      return NetworkService.get('/v1/creator/subscribers');
    };

  getPostComments = async (postId: string): ApiRequest<TGetPostComments> => {
    return NetworkService.get(`/v1/comments?post=${postId}`);
  };

  postAddNewComment = async (
    inputs: TPostAddNewCommentInput,
  ): ApiRequest<TPostAddNewCommentOutput> => {
    return NetworkService.post('/v1/comments', inputs);
  };

  deleteComment = async (commentId: string) => {
    return NetworkService.delete(`/v1/comments/${commentId}`);
  };

  // payouts
  getCreatorPayouts = async (): ApiRequest<TGetPayoutsOutput> => {
    return NetworkService.get('/v1/creator/payouts');
  };

  postRefreshToken = async (
    refreshToken: string,
    config?: any,
  ): ApiRequest<TPostRefreshToken> => {
    return NetworkService.post(
      '/v1/auth/refresh-tokens',
      {
        refreshToken,
      },
      config,
    );
  };

  //for creator stats
  getCreatorStats = async (): ApiRequest<TGetCreatorStats> => {
    return NetworkService.get('/v1/creator/stats');
  };

  //
  //todo: add fabric to create repetable methods
  postUploadGoal = async (
    inputs: TPostAddNewCreatorGoalInput,
  ): ApiRequest<TPostAddNewCreatorGoalOutput> => {
    return NetworkService.post('/v1/creator/goals', inputs);
  };

  updateGoal = async (
    update: TUpdateCreatorGoalInput,
  ): ApiRequest<TUpdateCreatorGoalOutput> => {
    return NetworkService.put('/v1/creator/goals', {
      ...update,
    });
  };

  deleteGoal = async (
    params: TDeleteCreatorGoalInput,
  ): ApiRequest<TDeleteCreatorGoalOutput> => {
    return NetworkService.delete('/v1/creator/goals', params);
  };

  changeCollaborationStatus = async ({
    status,
    id,
  }: TPostChangeAcceptanceStatusInput): ApiRequest<TPostChangeAcceptanceStatusOutput> => {
    return NetworkService.post(`/v1/creator/tiers/${id}/acceptance`, {status});
  };
}

export const ApiService = new _ApiService();
