import React, {useMemo} from 'react';
import {filter, includes, isEmpty, lowerCase, map, upperCase} from 'lodash';
import {Platform, ViewStyle} from 'react-native';
import { Picker } from '../Picker/Picker';
import { PickerProps } from '@react-native-picker/picker';
import { ALL_TRANSLATIONS_LANGUAGES } from '../../../translations/translations.contants';

/**
 * languages are present
 * in ISO 639-1 Code
 */

type TProps = {
  onLanguageChange: (lang: string | boolean | number) => void;
  value: string;
  langs?: string[];
  short?: boolean;
  style?: ViewStyle;
  emptyValue?: boolean
};

export const LanguagePicker: React.FC<TProps & PickerProps> = ({
  onLanguageChange,
  value,
  langs,
  short,
  style,
  enabled = true,
  emptyValue,
  ...otherProps
}) => {
  // codes - https://www.loc.gov/standards/iso639-2/php/code_list.php
  const languages = useMemo(() => {
    let renderedLanguages = [...ALL_TRANSLATIONS_LANGUAGES]
    if(emptyValue){
      renderedLanguages.unshift(
        {
          label: '',
          code: '',
        },
      )
    }
    else{
      if(!value){
        console.error("Empty value for picker is provided.", )
      }
    }
    if (!isEmpty(langs)) {
      renderedLanguages = filter(renderedLanguages, l =>
        includes(langs, l?.code),
      );
    }
    return renderedLanguages;
  }, [langs]);

  return (
    <Picker
      enabled={enabled}
      data={languages.map(l => ({label: short ? upperCase(l.code) : lowerCase(l.label), value: l.code}))}
      testID={`lang_picker`}
      testIDPrefix="lang_picker_item_"
      selectedValue={value || ''}
      containerStyle={{
        width: short ? 68 : Platform.OS === 'web' ? 125 : 180,
      }}
      style={[{
        elevation: 0,
      },
        style
      ]}
      pickerContentContainerStyles={{
        width: short ? 52 : Platform.OS === 'web' ? 110 : 165, //TODO:fix safari styles
      }}
      onValueChange={(itemValue:any) => {
        onLanguageChange(itemValue?.value);
      }}
      {...otherProps}
      />

    // </View>/
  );
};
