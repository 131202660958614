import {
  Row,
  Text,
  View,
  Icon,
  KeyboardAvoidingView,
} from 'components';
import {Platform, StyleSheet} from 'react-native';
import React, {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {TouchableOpacity, TouchableWithoutFeedback} from 'react-native';

import {EscreenNames} from 'navigation';
import {
  getCreatorInfo,
  getProfile,
} from 'store';

import {EPostFilter} from 'types';
import {useDispatchAction, useNavigation} from 'hooks';
import {colors} from '@sponsor/assets';
import {isMobile} from 'utils';
import { PostListContainer } from '../../../posts/PostList.container';

export const PostList = () => {
  const [isDropDownVisible, setIsDropdownVisible] = useState(false);

  const dispatch = useDispatch();
  const {navigate} = useNavigation();

  const {
    error,
    isLoading,
    submitAction: submitGetCreatorInfo
  } = useDispatchAction(getCreatorInfo)

  useEffect(() => {
    if(Platform.OS === 'web'){
      submitGetCreatorInfo(null)
    }
  }, [
    submitGetCreatorInfo
  ]);

  useEffect(() => {
    dispatch(getProfile(null, {}));
  }, [
    dispatch
  ]);

  return (
    <KeyboardAvoidingView>
      <View style={styles.container}>
        {isDropDownVisible && (
          <View style={styles.dropdownContainer}>
            <TouchableWithoutFeedback onPress={() => setIsDropdownVisible(false)}>
              <View>
                <TouchableOpacity onPress={() => {}} style={styles.dropdownBtn}>
                  <Text variant="buttonText">{EPostFilter.ONLY_ME}</Text>
                </TouchableOpacity>
                <TouchableOpacity onPress={() => {}} style={styles.dropdownBtn}>
                  <Text variant="buttonText">{EPostFilter.ALL_POSTS}</Text>
                </TouchableOpacity>
              </View>
            </TouchableWithoutFeedback>
          </View>
        )}
        {!isMobile && <View variant='sectionContainer'>
          <Row>
              <TouchableOpacity onPress={() => navigate(EscreenNames.ViewedPosts)}> 
                <Icon 
                  name="list"
                />
              </TouchableOpacity>
          </Row>
        </View>}
        <PostListContainer />
      </View>
    </KeyboardAvoidingView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex:1
  },
  marginHorizontal: {
    marginHorizontal: 5,
    // padding:20
  },
  noPostsText: {
    textAlign: 'center',
    marginTop: 50,
    paddingHorizontal: 20,
  },

  // TODO: make dropdown
  dropdownContainer: {
    position: 'absolute',
    zIndex: 111,
    top: 25,
    padding: 25,
    borderWidth: 1,
    borderColor: colors.DUSTY_GRAY,
    backgroundColor: 'white',
  },
  dropdownBtn: {
    zIndex: 115,
    padding: 12,
  },
  creatorDropdownContainer: {
    position: 'absolute',
    zIndex: 111,
    top: 25,
    padding: 25,
    left: 100,
    borderWidth: 1,
    borderColor: colors.DUSTY_GRAY,
    backgroundColor: 'white',
  },
  postContainer: {paddingVertical: 25}
});
