
import { ContentContainer, Text, View } from 'components'
import { i18n } from 'localize'
import React from 'react'
import { StyleSheet } from 'react-native' 

export const UrlNotFoundScreen = () => {
    return (
        <ContentContainer>
            <View  variant='sectionContainer'>
                <Text variant='secondaryTitle' center>{i18n.t("notFoundScreen.message")}</Text>
            </View>
        </ContentContainer>
    )
}

const styles = StyleSheet.create({
  
})

