export const creatorMerchantLocalization = {
  en: {
    creatorMerchant: {
      merchantFinancialNotSet: 'Add financial config for the merchant',
    },
    creatorMerchantFinancial: {
      ibanInputLabel: 'IBAN',
      ibanInputPlaceholder: 'Valid international bank account number',
    },
  },
  uk: {
    creatorMerchant: {
      merchantFinancialNotSet: 'Додайте фінансові налаштування',
    },
    creatorMerchantFinancial: {
      ibanInputLabel: 'IBAN',
      ibanInputPlaceholder: 'Коректний міжнародний номер банківського рахунку',
    },
  },
};
