
import { Icon, Row, View } from 'components'
import { useNavigation } from 'hooks'
import React from 'react'
import { StyleSheet, TouchableOpacity } from 'react-native' 
import { Outlet } from 'react-router-dom'

export const EditScreen = () => {

    const {goBack} = useNavigation()

    return (
        <View variant='screenContainer' style={{flex:1}}>
            <Row>
                <TouchableOpacity onPress={goBack}>
                    <Icon 
                        name="arrow-left"
                        size={25}
                    />
                </TouchableOpacity>
            </Row>
            
            <Outlet />
        </View>
    )
}

const styles = StyleSheet.create({
  
})

