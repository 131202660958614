import React from 'react'
import { Row, Text, View } from 'components';
import { PricingCard } from 'components/src/PricingCard/PricingCard';
import { i18n } from 'localize';
import { EscreenNames } from 'navigation';
import { useNavigation } from 'hooks';

export const Pricings = () => {

    const { navigate } = useNavigation(); 

    return (
        <View variant="sectionContainer">
            <Text center variant='sectionTitle'>
                {i18n.t('landing.pricingPlans.sectionTitle')}
            </Text>
            <Row rowCenter>
                <PricingCard 
                    title={
                        i18n.t("landing.features.basicPricingPlanTitle")
                    }
                    info={[
                        i18n.t("landing.features.translations"),
                        i18n.t("landing.features.collaborations"),
                        i18n.t("landing.features.intantPayout"),
                        i18n.t("landing.features.analytics"),
                        i18n.t("landing.features.mobileApp"),
                        i18n.t("landing.features.oneClickPayment"),
                        i18n.t("landing.features.paymentWays"),
                        i18n.t("landing.features.publicPrivatePosts"),
                        i18n.t("landing.features.offlineSupport"),
                        i18n.t("landing.features.militarySupport"),
                        i18n.t("landing.features.officialVerification"),
                        i18n.t("landing.features.fees"),
                    ]}
                    button={{
                        title: i18n.t("landing.joinUs"),
                        onPress: () => navigate(EscreenNames.Login),
                    }}
                    // onButtonPress,
                />
                {/* <PricingCard /> */}
            </Row>
        </View>
    )
}
