import React from 'react';
import {Fade, Placeholder, PlaceholderLine} from 'rn-placeholder';

export const LoadingPlaceholder = () => {
  return (
    <Placeholder Animation={Fade}>
      <PlaceholderLine style={{borderRadius: 0, height: 350}} />
      <PlaceholderLine
        style={{
          marginTop: -100,
          width: 220,
          borderRadius: 220,
          height: 220,
          alignSelf: 'center',
        }}
      />
      <PlaceholderLine style={{alignSelf: 'center', width: 100, height: 25}} />
      <PlaceholderLine style={{alignSelf: 'center', width: 200, height: 25}} />

      <PlaceholderLine style={{alignSelf: 'center', width: 100, height: 25}} />
      <PlaceholderLine style={{alignSelf: 'center', width: 200, height: 25}} />
    </Placeholder>
  );
};
