import { Platform } from 'react-native';
import {useNavigate} from 'react-router-dom';
import { isDesktop } from 'utils';

export const useNavigation = () => {
  const navigate = useNavigate();
  return {
    navigate: (
      path: string,
      params?: any,
      options?: {
        newTab?: boolean;
        urlParam?: boolean // instead of ? use / on web: /c/:pageURL
      },
    ) => {
      let url = path;
      if(options?.urlParam){
        console.log('params',params)
        url = path + '/' + Object.values(params)[0]
      }
      else{
        const searchParams = params ? `?${new URLSearchParams(params)}` : '';
        url = searchParams ? path + searchParams : path;
      }
      //todo: create standalone module for tv ???
      if (options?.newTab && !isDesktop()) {
        return window.open(url);
      }
      navigate(url);
    },
    goBack: () => navigate(-1),
  };
};
