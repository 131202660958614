import React, {useEffect} from 'react';
import {useWindowDimensions} from 'react-native';
import {ContentContainer, TabBar, Text, View} from 'components';
import {SceneMap, TabView} from 'react-native-tab-view';
import {i18n} from 'localize';
import {ActiveSubscriptions} from '../ActiveSubscriptions';
import {colors} from '@sponsor/assets';
import {DeletedButActiveSubscriptions} from './DeletedButActiveSubscriptions.container';
import {useDispatch} from 'react-redux';
import {getProfile} from 'store';

const renderScene = SceneMap({
  activeSubscriptions: () => <ActiveSubscriptions />,
  deletedButActiveSubscriptions: () => <DeletedButActiveSubscriptions />,
});

/**
 * We display only active
 * paid subscriptions
 * (active or unsubscribed and active yet)
 */
export const MySubscriptionsPage = () => {
  const layout = useWindowDimensions();

  const [index, setIndex] = React.useState(0);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getProfile(null, {}));
  }, [dispatch]);

  const [routes] = React.useState([
    {key: 'activeSubscriptions', title: i18n.t('activeSubscriptions.tabTitle')},
    {
      key: 'deletedButActiveSubscriptions',
      title: i18n.t('deletedButActiveSubscriptions.tabTitle'),
    },
  ]);

  return (
      <ContentContainer>
        <Text center  variant="sectionTitle">
          {i18n.t('subscriptionPage.subscriptionsSettings')}
        </Text>
        {/* <Row></Row> */}
        <TabView
          renderTabBar={props => (
            <TabBar
              {...props}
              indicatorContainerStyle={{alignSelf: 'center'}}
              indicatorStyle={{
                backgroundColor: colors.GREEN_OFFICIAL,
                width: 10,
              }}
              style={{backgroundColor: 'white'}}
              tabStyle={{justifyContent: 'center'}}
              labelStyle={{color: 'black'}}
            />
          )}
          navigationState={{index, routes}}
          renderScene={renderScene}
          onIndexChange={setIndex}
          initialLayout={{width: layout.width}}
        />
      </ContentContainer>
  );
};
