
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TMyGetCreatorOutput } from 'types';
import { deleteGoalsTranslationsSuccess, updateGoalsTranslationsSuccess, uploadGoalsTranslationsSuccess } from './goalsTranslations.actions';
import { TDeleteGoalsTranslationsInput, TGoalsTranslationsState, TPostUploadGoalsTranslationsOutput } from './goalsTranslations.types';

const initialState:TGoalsTranslationsState = {
    results: {

    }
}

export const goalsTranslationsSlice = createSlice({
    name: 'goalsTranslations',
    initialState,
    extraReducers: {  
        // todo: fix incorrect matching between action and PayloadAction
        ['creator/SET_CREATOR_DATA']: (
            state, 
            action:PayloadAction<TMyGetCreatorOutput['data']>
        ) => {
            console.log('getCreatorInfo', action.payload);
            const goals = Object.values(action.payload.goals)
            if(!state.results){
                state.results = {}
            }
            for(let g of goals){
                const translations = Object.values(g.translations)
                for(let t of translations){
                    console.log('t',t)
                    state.results[t.id] = t
                }
            }
        },
        // todo: move rest operation to the pattern
        [uploadGoalsTranslationsSuccess.toString()]: (
            state, 
            action:PayloadAction<TPostUploadGoalsTranslationsOutput['data']>
        ) => {
            console.log('action.payload',action.payload)
            state.results[action.payload.id] = {
                ...( state.results[action.payload.id] || {}),
                ...action.payload
            }
        },
        [updateGoalsTranslationsSuccess.toString()]: (
            state, 
            action:PayloadAction<TPostUploadGoalsTranslationsOutput['data']>
        ) => {
            state.results[action.payload.id] = {
                ...( state.results[action.payload.id] || {}),
                ...action.payload
            }
        },
        [deleteGoalsTranslationsSuccess.toString()]:(
            state,
            action: PayloadAction<TDeleteGoalsTranslationsInput>
        ) => {
            delete state.results[action.payload.id]
        }
    },
    reducers: {},
});

export const goalsTranslationsReducer = goalsTranslationsSlice.reducer
  
  

