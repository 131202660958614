import React from 'react';

import * as S from './home-build.styled';

import useMedia from '../../../../hook/media';

import check from '../../../../images/home/build/check.png';
import {i18n} from 'localize';
import {Config} from 'libs';
import { isDesktop } from '../../../../../../../utils/platform';

export default function HomeShareView() {
  const media = useMedia();

  return (
    <S.Container media={media}>
      <S.Content media={media}>
        <S.Title media={media}>
          {i18n.t('landing.buildTransparentRelationship')}
        </S.Title>

        <S.Item>
          <S.Check media={media} source={check} />

          <S.Description media={media}>
            {i18n.t('landing.withoutAlgorithms')}
          </S.Description>
        </S.Item>

        <S.Item>
          <S.Check media={media} source={check} />

          <S.Description media={media}>
            {i18n.t('landing.onlyYouAreModerator')}
          </S.Description>
        </S.Item>
      </S.Content>

      <S.Image
        media={media}
        source={isDesktop() ? 
          require('../../../../images/build-relationship.webp') 
          : {
              uri: `${Config.STATIC_IMAGES_URL}/landing/build-relationship.webp`,
          }
        }
        resizeMode="contain"
      />
    </S.Container>
  );
}
