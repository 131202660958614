import React, {useCallback} from 'react';
import {TierTranslationEditPreview} from './TierTranslationEditPreview.component';
import {useSelector} from 'react-redux';
import {TStore} from 'store';
import {selectTierTranslationById} from './tiersTranslations.selectors';
import {useDispatchAction, useNavigation} from 'hooks';
import {EscreenNames} from 'navigation';
import {deleteTierTranslation} from './tiersTranslations.actions';

type TProps = {
  id: string;
  parentId: string;
};

export const TierTranslationEditPreviewContainer: React.FC<TProps> = ({
  id,
  parentId,
}) => {
  const {navigate} = useNavigation();

  const translation = useSelector((state: TStore) =>
    selectTierTranslationById(state, id, parentId),
  );

  const {submitAction} = useDispatchAction(deleteTierTranslation, {
    data: {id, lang: translation?.lang, parentId},
  });

  const onEditPress = useCallback(() => {
    navigate(EscreenNames.EditTierTranslation, {
      id,
      parentId,
    });
  }, [navigate, id, parentId]);

  return (
    <TierTranslationEditPreview
      {...(translation || {})}
      id={id}
      onEditPress={onEditPress}
      onDeletePress={() => submitAction()}
    />
  );
};
