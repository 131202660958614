import React from 'react';
import {selectSubscriptionByTierId, TStore} from 'store';
import {useSelector} from 'react-redux';
import {useParams} from 'hooks';
import {ContentContainer, ScrollViewOnlyOnMobile, Text, View} from 'components';
import {SubscriptionDelete} from './SubscriptionDelete.component';
import {SubscriptionInfo} from './SubscriptionInfo.component';
import {i18n} from '../localize';

export const CancelSubscriptionScreen = ({}) => {
  const {tierId} = useParams();

  const userSubscription = useSelector((state: TStore) =>
    selectSubscriptionByTierId(state, tierId || ''),
  );

  // if (isLoading) {
  //     return <ActivityIndicator />;
  // }

  if (!userSubscription) {
    return (
      <View>
        <Text
          style={{padding: 20, textAlign: 'center'}}
          variant="sectionTitle"
          testID="subscription-not-found-text">
          {i18n.t('paymentScreen.subscriptionNotFound')}
        </Text>
      </View>
    );
  }

  return (
    <ContentContainer>
      <ScrollViewOnlyOnMobile>
        <View variant="screenContainer">
          <SubscriptionInfo tier={userSubscription.tier} />
          <SubscriptionDelete subscription={userSubscription} />  
        </View>
      </ScrollViewOnlyOnMobile>
    </ContentContainer>
  );
};
