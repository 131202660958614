export const pricingPlansLocalizations = {
    'en': {
        pricingPlansTitles: {
            'basic': "Basic"
        },
        pricingPlanScreen: {
            screenTitle: "Pricing plan",
            instantPaymentFee: "Merchant withdraw fee",
            paymentFee: "Payment fee",
            paymentSystemFee: "+ payment system fees",
        }
    },
    "uk": {
        pricingPlansTitles: {
            'basic': "Базовий"
        },
        pricingPlanScreen: {
            screenTitle: "Тарифний план",
            instantPaymentFee: "Комісія на виплати на ФОП",
            paymentFee: "Комісія від зарахування на карту",
            paymentSystemFee: "+ комісія платіжної системи"
        }
    }
}