import React, {useCallback, useEffect, useMemo} from 'react';
import {
  RefreshControl, 
  StyleSheet, 
  ScrollView, 
  Platform
} from 'react-native';
import {ContentContainer, Icon, View, Text} from 'components';
import {i18n} from 'localize';
import {useDispatchAction, useNavigation} from 'hooks';
import {EscreenNames} from 'navigation';
import {FAB} from 'react-native-elements/dist/buttons/FAB';
import {useActionSheet} from '@expo/react-native-action-sheet';
import {CreatorStatisticInfo} from './CreatorStatisticInfo';
import {alertMessage} from 'utils';
import {useSelector} from 'react-redux';
import {
  getCreatorInfo,
  selectCreatorBasicInfo,
  selectExternalCollaborationsWaitingAcceptanceCount,
} from 'store';
import { CreatorMenuList } from './CreatorMenuList';
import { colors } from '@sponsor/assets';

export const CreatorGeneralInfo = () => {
  const {navigate} = useNavigation();

  const {showActionSheetWithOptions} = useActionSheet();

  const options = [
    i18n.t('editCreatorPage.newPostButtonTitle'),
    i18n.t('editCreatorPage.newTierButtonTitle'), 
    i18n.t('cancelButtonTitle')
  ];
  const cancelButtonIndex = 2;

  const creator = useSelector(selectCreatorBasicInfo);
  const collaborationsWaitingAcceptanceCount = useSelector(
    selectExternalCollaborationsWaitingAcceptanceCount,
  );
  
  const isCreatorPresent = !!creator?.id;

  const onActionClick = useCallback(
    buttonIndex => {
      switch (buttonIndex) {
        case 0:
          navigate(EscreenNames.PostEdit);
          break;
        case 1:
          navigate(EscreenNames.TierEdit);
          break;
      }
    },
    [navigate],
  );

  const buttons = useMemo(
    () => [
      {
        testId: 'basic-info-button',
        title: i18n.t('editCreatorPage.basicSettings'),
        onPress: () => navigate(EscreenNames.CreatorSettingEdit),
      },
      {
        title: i18n.t('editCreatorPage.paymentSettings'),
        disabled: Platform.OS !== 'web',
        onDisabledPress: () => {
          if (Platform.OS !== 'web') {
            return alertMessage(
              '',
              i18n.t('editCreatorPage.thisOptionsAvaiableOnWeb'),
            );
          }
        },
        onPress: () => {
          //todo: rename, leave payments or withdraw methods
          navigate(EscreenNames.CreatorPaymentsEdit);
        },
      },
      {
        testId: 'tier-button',
        title: i18n.t('editCreatorPage.tierSettings'),
        onPress: () => navigate(EscreenNames.CreatorTiersEdit),
        Icon:
          collaborationsWaitingAcceptanceCount > 0 ? (
            <View
              style={{
                backgroundColor: 'red',
                width: 25,
                height: 25,
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: 15,
              }}>
              <Text style={{color: 'white'}} variant="thirdTitle">
                {collaborationsWaitingAcceptanceCount}
              </Text>
            </View>
          ) : null,
        onDisabledPress:
          Platform.OS !== 'web'
            ? () => {
                alertMessage('', i18n.t('editCreatorPage.tiersBlockedMessage'));
              }
            : null,
        disabled: !isCreatorPresent,
      },
      {
        title: i18n.t('editCreatorPage.posts'),
        onPress: () => navigate(EscreenNames.CreatorPostsEdit),
        disabled: !isCreatorPresent,
      },
      {
        title: i18n.t('editCreatorPage.greetings'),
        onPress: () => navigate(EscreenNames.CreatorGreetingsEdit),
        disabled: !isCreatorPresent,
      },
      {
        title: i18n.t('editCreatorPage.socialsTitle'),
        onPress: () => navigate(EscreenNames.CreatorSocialsEdit),
        disabled: !isCreatorPresent,
      },
      {
        title: i18n.t('editCreatorPage.privacy'),
        onPress: () => navigate(EscreenNames.CreatorEditPrivacy),
        disabled: !isCreatorPresent,
      },
      {
        title: i18n.t('editCreatorPage.goalsSettingsButtonTitle'),
        onPress: () => navigate(EscreenNames.CreatorEditGoals),
        disabled: !isCreatorPresent,
      },
      {
        title: i18n.t('editCreatorPage.friendsSettingsButtonTitle'),
        onPress: () => navigate(EscreenNames.CreatorEditFriends),
        disabled: !isCreatorPresent,
      },
      {
        title: i18n.t('editCreatorPage.customizatonSettingsButtonTitle'),
        onPress: () => navigate(EscreenNames.CreatorEditCustomization),
        disabled: !isCreatorPresent,
      },
      {
        title: i18n.t('editCreatorPage.translationsSettingsButtonTitle'),
        onPress: () => navigate(EscreenNames.CreatorEditTranlsation),
        disabled: !isCreatorPresent,
      },
      {
        title: i18n.t('editCreatorPage.tiersTranslationsBtnTitle'),
        onPress: () => navigate(EscreenNames.CreatorTiersTranslation),
        disabled: !isCreatorPresent,
      },
      {
        title: i18n.t('editCreatorPage.adsPlatformAccess'),
        onPress: () => navigate(EscreenNames.AdsPlatformAccess),
        disabled: !isCreatorPresent,
      },
      // {
      //   onPress: () => navigate(EscreenNames.CreatorPostsEdit),
      //   title: i18n.t('editCreatorPage.posts'),
      // },
      // {
      //   onPress: () => navigate(EscreenNames.CreatorTiersEdit),
      //   title: i18n.t('editCreatorPage.tierSettings'),
      // },
      // {
      //   onPress: () => navigate(EscreenNames.CreatorEditGoals),
      //   title: i18n.t('editCreatorPage.goalsSettingsButtonTitle'),
      // },
      // {
      //   onPress: () => navigate(EscreenNames.CreatorEditPrivacy),
      //   title: i18n.t('editCreatorPage.privacySettingButtonTitle'),
      // },
      // {
      //   onPress: () => navigate(EscreenNames.CreatorEditCustomization),
      //   title: i18n.t('editCreatorPage.customizatonSettingsButtonTitle'),
      // },
      // {
      //   onPress: () => navigate(EscreenNames.CreatorEditTranlsation),
      //   title: i18n.t('editCreatorPage.translationsSettingsButtonTitle'),
      // }
    ],
    [navigate, collaborationsWaitingAcceptanceCount, isCreatorPresent],
  );


  const { 
    submitAction : dispatchGetCreatorInfo, 
    isLoading: isCreatorLoading,
    error
  } = useDispatchAction(getCreatorInfo)

  useEffect(() => {
    dispatchGetCreatorInfo(null)
  }, [
    dispatchGetCreatorInfo
  ]);
  
  return (
    <ContentContainer>
      <ScrollView
        refreshControl={
          <RefreshControl
            refreshing={isCreatorLoading}
            onRefresh={() => dispatchGetCreatorInfo(null)}
          />
        }
      >
        <Text variant='inputError'>{error}</Text>
        <CreatorStatisticInfo />

        <View variant='screenContainer'>
          <CreatorMenuList 
            buttons={buttons}
          />
        </View>

      </ScrollView>
      <FAB
        visible={true}
        onPress={() =>
          showActionSheetWithOptions(
            {
              options,
              icons: [
                <Icon name="list" size={18}/>,
                <Icon name="circle-arrows" size={25}/>,
                <Icon name="cancel" size={18}/>,
              ],
              cancelButtonIndex,
            },
            onActionClick,
          )
        }
        placement="right"
        title="+"
        titleStyle={{
          fontSize: 24,
          color: 'white',
        }}
        containerStyle={{borderRadius: 6, borderWidth: 2}}
        color={colors.GREEN_OFFICIAL}
      />
    </ContentContainer>
  );
};

const styles = StyleSheet.create({
  creatorContentButtonContainer: {
    width: '100%',
    padding: 5,
  },
  creatorContentButtonTitle: {
    textTransform: 'uppercase',
  },
});
