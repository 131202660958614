import * as Yup from 'yup';
import {i18n} from 'localize';

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

//todo: think about dynamic schema, like for TCreatorWithdrawMethod
export const getMerchantEntrepreneurWithdrawMethodSchema = () =>
  Yup.object().shape({
    type: Yup.string().required(i18n.t('validationSchema.requiredField')),
    phone: Yup.string()
      .matches(phoneRegExp, i18n.t('validationSchema.phoneNumberInvalid'))
      .required(i18n.t('validationSchema.requiredField')),
    country: Yup.string().required(i18n.t('validationSchema.requiredField')),
    edrpou: Yup.string().required(i18n.t('validationSchema.requiredField')),
  });
