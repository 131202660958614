import { TouchableOpacity } from 'react-native'
import React from 'react'
import { alertMessage } from 'utils'
import { i18n } from 'localize'
import { TParentTranslationField } from './translations.types'
import { Icon } from 'components'

type TProps = TParentTranslationField & {
    color?: string;
    alertTitle?:string;
    alertMessage?:string;
}

export const TranslationsIconContainer:React.FC<TProps> = ({
    translations,
    alertTitle,
    alertMessage: message,
    color = 'black'
}) => {
    if(translations){
        const langs = Object.keys(translations) || []
        if(langs.length < 1)return null;
        return (
            <TouchableOpacity
                style={{width: 25,  paddingVertical: 5}}
                onPress={() => alertMessage(
                    alertTitle || i18n.t("translationsFeature.postHasTranslationsTitle"), 
                    message || i18n.t("translationsFeature.postHasTranslationsMessage", {languages: langs.join(', ')})
                    )
                }
            >
                <Icon size={18} color={color} name="world"/>
            </TouchableOpacity>
        )
    }
    return null
}
