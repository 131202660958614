import {StyleSheet} from 'react-native';
import React from 'react';
import {CreatorCardBase} from './CreatorCardBase';
import {TTier} from '../../../types/src/tier';
import {Text} from '../Text/Text';

type TProps = {
  onPress: () => void;
};

export const CreatorCardMinimized: React.FC<TProps & Partial<TTier>> = ({
  creatorTitle,
  creatorPageURL,
  ...otherProps
}) => {
  return (
    <CreatorCardBase
      {...otherProps}
      creatorTitle={creatorTitle}
      creatorPageURL={creatorPageURL}
      containerStyle={{
        width: 120,
      }}
      contentContainerStyle={{
        justifyContent: 'center',
        width: '100%',
      }}>
      <Text style={styles.creatorTitle} center>
        {creatorTitle}
      </Text>
      <Text numberOfLines={1} style={styles.creatorSubtitle} center>
        {'/' + `${creatorPageURL}`}
      </Text>
    </CreatorCardBase>
  );
};

const styles = StyleSheet.create({
  creatorTitle: {
    paddingVertical: 5,
    fontSize: 18,
    color: 'black',
    fontWeight: 'bold',
    fontFamily: 'GolosTextBold',
  },
  creatorSubtitle: {
    paddingVertical: 5,
    fontSize: 16,
    color: 'gray',
  },
});
