import * as Yup from 'yup';
import {i18n} from 'localize';

export const getTranslationValidationSchema = () =>
  Yup.object().shape({
    subtitle: Yup.string()
      .min(5, i18n.t('validationSchema.tooShort'))
      .max(500, i18n.t('validationSchema.tooLong'))
      .required(i18n.t('validationSchema.requiredField')),
    description: Yup.string()
      .min(2, i18n.t('validationSchema.tooShort'))
      .max(10000, i18n.t('validationSchema.tooLong'))
      .required(i18n.t('validationSchema.requiredField')),
  });
