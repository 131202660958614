
import { Button, ContentContainer, KeyboardAvoidingView, Text, View } from 'components'
import { ActionResult } from '../../../components/src/ActionResult/ActionResult.component'
import { ConfirmationDialog } from '../../../components/src/ConfirmationDialog'
import { useDispatchAction } from 'hooks'
import { i18n } from 'localize'
import React from 'react'
import { StyleSheet, ActivityIndicator } from 'react-native' 
import { logoutStart, uploadAccountDeletion } from 'store'

export const AccountDeletionScreen = () => {

    const {
        submitAction: submitLogout,
    } = useDispatchAction(logoutStart)

    const {
        isLoading: isAccountDeletionLoading,
        submitAction: submitAccountDeletion,
        error,
    } = useDispatchAction(uploadAccountDeletion, {
        onSuccess: submitLogout
    })

    return (
        <ContentContainer>
            <KeyboardAvoidingView>
                <View variant="screenContainer">
                <ConfirmationDialog
                    title={i18n.t("profile.dltAccountBtnTitle")}
                    desc={i18n.t("dialogs.confirmationDialogTitle")}
                    onPress={submitAccountDeletion}
                    render={({onPress}) => (
                        <Button 
                            small
                            red
                            title={i18n.t("profile.dltAccountBtnTitle")}
                            onPress={onPress}
                        />
                    )}
                />
                    
                <ActionResult 
                    isLoading={isAccountDeletionLoading}
                    error={error}
                    renderError={() => (
                        <Text variant='inputError'>{error}</Text>
                    )}
                    renderSuccessMessage={() => (
                        <Text variant='formHint'>{i18n.t("operationSuccess")}</Text>
                    )}
                    renderLoading={() => (
                        <ActivityIndicator />
                    )}
                />
                </View>
            </KeyboardAvoidingView>
        </ContentContainer>
    )
}

const styles = StyleSheet.create({
  
})

