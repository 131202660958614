import {Platform} from 'react-native';

const isIos = Platform.OS === 'ios';

export const chooseByPlatform = <T>(ifIos: T, ifAndroid: T): T =>
  isIos ? ifIos : ifAndroid;

export const isMobile = Platform.OS === 'android' || Platform.OS === 'ios'

export const isDesktop = () => {
  try{
    //@ts-ignore
    var userAgent = navigator.userAgent.toLowerCase();
    if (userAgent.indexOf(' electron/') > -1) {
      // Electron-specific code
      return true
    }
  }catch(err){
    return false
  }
}

export const getAppVersion = () => {
  let appVersion: string | undefined;
  if(isDesktop()){
    try{
      appVersion = process.env.npm_package_version
    }catch(err){

    }
  }else{
    appVersion = String(Platform.Version)
  }
  return appVersion
}

export const isSafari = () => {
  if(Platform.OS !== 'web')return false;
    //@ts-ignore
  const isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && safari.pushNotification));
  return isSafari;
}