import {TComment, TCreator} from '.';
import {TReaction} from './reaction';

export enum EPostPublishedStatus {
  published = 'published',
  draft = 'draft',
}

export enum EPostEnum {
  public = 'public',
  subscribers_only = 'subscribers_only',
  paid_only = 'paid_only',
  subscribers_or_paid = 'subscribers_or_paid',
}

export type TPost = {
  id: string;
  title: string;
  imageUri: string;
  text: string;
  createdAt: string;
  likes: number;
  videoLink: string;
  nb_comments: number;
  visibilityType: EPostEnum;
  contentType: 'text' | 'poll';
  commantable: boolean;
  tags: string[];
  tizerTitle: string;
  tizer_image: string;

  tier: string;
  tiers: string[],
  unlocked: boolean;
  //todo: replace by status
  visible?: boolean
  reactions: TReaction;
  comments?: TComment[];
  creator?: Pick<TCreator, 'id' | 'title' | 'avatarUri' | 'pageURL'>;

  translations: {
    [lang in string] : string
  }
};
