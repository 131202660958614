import {find, forEach, set} from 'lodash';
import {createSelector, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {call, put, takeEvery} from 'redux-saga/effects';
import {createAction, ActionType} from 'typesafe-actions';
import moment from 'moment';
import {
  TPostPostInput,
  TPostPostOutput,
  TUpdatePostOutput,
  TUpdatePostInput,
  TGetPostsOutput,
  EAnalyticsEventNames,
  TPost,
} from 'types';
import {LOG} from 'utils';
import {
  CLEAR_STORE,
  getStore,
  TMetaInput,
  TOwnPostsState,
  TStore,
} from '../common';

import {ApiService, AnalyticsService} from 'services';
import { 
  deletePostTranslationSuccess, 
  getPostTranslationsSuccess, 
  uploadPostTranslationSuccess 
} from '../../../posts-translations/posts-translations.actions';
import { 
  TDeletePostsTranslationsInput, 
  TGetPostsTranslationsOutput, 
  TPostUploadPostsTranslationsOutput 
} from '../../../posts-translations/postsTranslations.types';

/**
 * drafts - posts that wasn't uploaded to back-end
 * published - post was uploaded to back-end
 * user can unpublished post
 */

const initialState: TOwnPostsState = {
  drafts: {},
  published: {},
};

export const postsSlice = createSlice({
  name: 'ownPosts',
  initialState,
  reducers: {
    uploadPostStart: state => {
      state;
    },
    addDraft: (state: TOwnPostsState, {payload: {id, ...otherPostData}}) => {
      if (!state?.drafts?.[id]) {
        set(state.drafts, [id], {id, ...otherPostData});
      }
    },
    updateDraft: (
      state: TOwnPostsState,
      action: PayloadAction<{id: string}>,
    ) => {
      const id = action.payload?.id;
      Object.assign(state.drafts[id], action.payload);
    },
    removeDraft: (state: TOwnPostsState, action) => {
      delete state.drafts?.[action.payload?.id];
    },
    removePostSuccess: (state: TOwnPostsState, action) => {
      delete state.published[action.payload?.id];
    },
    updatePostFinish: (state: TOwnPostsState, action) => {
      const id = action.payload?.id;
      Object.assign(state.published[id], action.payload);
    },
    uploadPostFinish: (
      state: TOwnPostsState,
      {payload: {id, ...otherPostData}},
    ) => {
      set(state.published, [id], {id, ...otherPostData});
    },
    setPublishedPosts: (state: TOwnPostsState, action) => {
      forEach(action.payload, p => {
        if (!state?.published) {
          state.published = {};
        }
        state.published[p?.id] = p;
      });
      // todo: check this
      return state;
    },
    [CLEAR_STORE]: () => {
      return initialState;
    },
  },
  extraReducers: {
      // todo: move post and delete from child item to separate flow
      [uploadPostTranslationSuccess.toString()]: (
        state:TOwnPostsState, 
        action:PayloadAction<TPostUploadPostsTranslationsOutput['data']>
      ) => {
        const postId = action?.payload?.parentId;
        let post = state?.drafts?.[postId] || state?.published?.[postId];
        // todo: do we need check for existence here ???
        post.translations[action?.payload?.lang] = action?.payload?.id
      },
      [deletePostTranslationSuccess.toString()]: (
        state:TOwnPostsState, 
        action:PayloadAction<TDeletePostsTranslationsInput>
      ) => {
        const postId = action?.payload?.parentId;
        const translId = action?.payload?.id
        const post = state?.drafts?.[postId] || state?.published?.[postId];
        let lang = '';
        for(let i of Object.entries(post.translations)){
          if(i[1] === translId){
            lang=i[0];
            break
          }
        }
        if(lang){
          delete post.translations[lang]
        }else{
          // todo: handle 
        }
      },
      // todo: add upload, delete methods as well for parentItem, 
      // todo: move all this cases to the layout generation 
      [getPostTranslationsSuccess.toString()]: (state:TOwnPostsState, action: PayloadAction<TGetPostsTranslationsOutput['data']>) => {
        // todo: add check for other cases
        if(!Array.isArray(action.payload))return;
        for(let i of action.payload){
            const postId = i.parentId;
            console.log('i',i)
            const newTranslations = {
              [i.lang] : i.id
            } 
            // let post = state?.drafts?.[postId] || state?.published?.[postId];
            // console.log('state.published?.[postId]',state.published?.[postId])
              // state.published[postId].translations = {
              //   ...(state.published[postId]?.translations || {}),
              //   [i.lang] : i.id
              // }
            if(!!state?.drafts?.[postId]){
              state.drafts[postId].translations = {
                ...(state.drafts[postId]?.translations || {}),
                ...newTranslations
              }
            }
            else if(!!state.published?.[postId]){
              state.published[postId] = {
                ...state.published[postId],
                translations: {
                  ...(state.published[postId]?.translations || {}),
                  ...newTranslations
                }
              }
          }
        }
      } 
    }
});

export const UPLOAD_POST_START = 'UPLOAD_POST_START';
export const UPDATE_POST_START = 'UPDATE_POST_START';
export const REMOVE_POST = 'REMOVE_POST';
export const GET_UPLOADED_POSTS = 'GET_UPLOADED_POSTS';
export const TOGGLE_EMODJI = 'TOGGLE_EMODJI';

// Action creators are generated for each case reducer function
export const {
  updatePostFinish,
  setPublishedPosts,
  uploadPostStart,
  uploadPostFinish,
  removeDraft,
  addDraft,
  updateDraft,
  removePostSuccess,
} = postsSlice.actions;

export const uploadPost = createAction(UPLOAD_POST_START)<
  TPostPostInput & {
    id: string;
  },
  TMetaInput
>();
export const updatePost = createAction(UPDATE_POST_START)<
  TUpdatePostInput & {id: string},
  TMetaInput
>();

export const removePost = createAction(REMOVE_POST)<{id: string}, TMetaInput>();

export const getUploadedPosts = createAction(GET_UPLOADED_POSTS)<
  null,
  TMetaInput
>();

export default postsSlice.reducer;

// selectors
export const getPostsState = createSelector(getStore, state => state.posts);
export const selectPostSearchResult = createSelector(
  getPostsState,
  state => state?.inFeedPosts || {},
);
export const selectPublishedPosts = createSelector(getPostsState, state =>
  Object.values(state.ownPosts.published || {}),
);
export const selectDraftPosts = createSelector(getPostsState, state =>
  Object.values(state?.ownPosts.drafts || {}),
);

export const selectPostById: (state:TStore, id: string) => TPost = createSelector(
  [getPostsState, (state: TStore, postId: string): string => postId],
  (postState, postId) => {
    const allPosts = {
      ...postState.ownPosts.published,
      ...postState.ownPosts.drafts,
    };
    return allPosts[postId];
  },
);

export const selectUserCreatorsPostById = createSelector(
  [getPostsState, (state: TStore, postId: string): string => postId],
  (postState, postId) => {
    return postState?.inFeedPosts?.results?.[postId];
  },
);

function* uploadPostSaga({
  payload,
  meta: {onFinish, onFail, onSuccess},
}: ActionType<typeof uploadPost>) {
  try {
    yield put(addDraft(payload));
    const {data}: TPostPostOutput = yield call(ApiService.postPost, payload);
    yield put(
      uploadPostFinish({
        ...data,
      }),
    );
    yield put(removeDraft({id: payload?.id}));
    AnalyticsService.logEvent(EAnalyticsEventNames.POST_UPLOAD_SUCCEEDED);
    onSuccess?.();
  } catch (err) {
    LOG(err);
    AnalyticsService.logEvent(EAnalyticsEventNames.POST_UPLOAD_FAILED);
    onFail?.(err);
  } finally {
    onFinish?.();
  }
}

function* updatePostSaga({
  payload,
  meta: {onFinish, onFail, onSuccess},
}: ActionType<typeof updatePost>) {
  try {
    if (moment(payload?.id).isValid()) {
      yield put(updateDraft({...payload, id: payload.id}));
    } else {
      const {data}: TUpdatePostOutput = yield call(
        ApiService.updatePost,
        payload,
      );
      yield put(
        updatePostFinish({
          ...data,
        }),
      );
    }
    AnalyticsService.logEvent(EAnalyticsEventNames.POST_UPDATE_SUCCEEDED);
    onSuccess?.();
  } catch (err) {
    LOG(err);
    AnalyticsService.logEvent(EAnalyticsEventNames.POST_UPDATE_FAILED);
    onFail?.();
  } finally {
    onFinish?.();
  }
}

function* removePostSaga({
  payload: {id},
  meta: {onFinish, onFail, onSuccess},
}: ActionType<typeof removePost>) {
  try {
    if (moment(id).isValid()) {
      yield put(removeDraft({id}));
    } else {
      yield call(ApiService.removePost, id);
      yield put(
        //todo: typing ???
        removePostSuccess({
          id,
        }),
      );
    }
    AnalyticsService.logEvent(EAnalyticsEventNames.POST_REMOVAL_SUCCEEDED);
    onSuccess?.();
  } catch (err) {
    LOG(err);
    AnalyticsService.logEvent(EAnalyticsEventNames.POST_REMOVAL_FAILED);
    onFail?.();
  } finally {
    onFinish?.();
  }
}

function* fetchUploadedPostsSaga({
  meta: {onFinish, onFail, onSuccess},
}: ActionType<typeof getUploadedPosts>) {
  try {
    const {data}: TGetPostsOutput = yield call(ApiService.getUploadedPosts);
    // TODO: add pagination
    yield put(setPublishedPosts(data.results));
    onSuccess?.();
  } catch (err) {
    LOG(err);
    onFail?.();
  } finally {
    onFinish?.();
  }
}

export function* ownPostsFlow() {
  yield takeEvery(getUploadedPosts, fetchUploadedPostsSaga);
  yield takeEvery(uploadPost, uploadPostSaga);
  yield takeEvery(updatePost, updatePostSaga);
  yield takeEvery(removePost, removePostSaga);
}
