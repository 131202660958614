import {
  Text,
  Column,
  Row,
  FormInput,
  Button,
  Icon,
  View,
  ContentContainer,
} from 'components';
import React, {useCallback, useEffect, useRef, useState} from 'react';
import {StyleSheet, Platform, ActivityIndicator, Keyboard, ScrollView} from 'react-native';
import {Formik, FormikValues} from 'formik';
import {useDispatch} from 'react-redux';
import {EscreenNames} from 'navigation';
import {TouchableOpacity} from 'react-native';
import {colors} from '@sponsor/assets';
import {loginStart} from 'store';
import {i18n} from 'localize';
import {useNavigation} from 'hooks';
import {useKeyPress} from '../../../../hooks/useKeyPress/useKeyPress';
import { AuthImage } from '../AuthImage';
import { DEVICE_WIDTH } from '@sponsor/constants';
import { isMobile } from 'utils';

const initialValues = {email: '', password: ''};

export const Login = () => {
  const dispatch = useDispatch();

  const formRef = useRef<FormikValues>();

  const [isLoading, setIsLoading] = useState(false);
  const [hidePassword, setHidePassword] = useState(true);

  const [error, setError] = useState('');
  const {navigate} = useNavigation?.();

  const [isEnterPressed] = useKeyPress('Enter');

  useEffect(() => {
    if (isEnterPressed && formRef.current && !isLoading) {
      formRef.current?.submitForm?.();
      Keyboard.dismiss()
    }
  }, [isEnterPressed, isLoading]);

  const sendLogin = useCallback(
    ({email, password}) => {
      setIsLoading(true);
      dispatch(
        loginStart(
          {
            email,
            password,
          },
          {
            onSuccess: () => {
              setIsLoading(false);
              navigate(EscreenNames.Home);
            },
            onFail: err => {
              setIsLoading(false);
              setError(err?.message);
            },
          },
        ),
      );
    },
    [dispatch, navigate],
  );

  return (
      <ContentContainer
        oneColumn
        keyboardAvoidingView={false}
      >
        <ScrollView // TODO: prevent bugs in future: without scrollview here keyboard is not dismissed sometimes
        >
          <Row flex={1} flexWrap>
            <Column flex={1}>
              <Text variant='authFormTitle'>{i18n.t('login')}</Text>
              <Formik
                innerRef={ref => {
                  if (ref) {
                    formRef.current = ref;
                  }
                }}
                initialValues={initialValues}
                validate={(values: any) => {
                  const errors: any = {};
                  if (!values.email) {
                    errors.email = i18n.t('validationSchema.requiredField');
                  } else if (
                    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                  ) {
                    errors.email = i18n.t('validationSchema.invalidEmail');
                  }
                  if (!values.password) {
                    errors.password = i18n.t('validationSchema.requiredField');
                  }
                  return errors;
                }}
                onSubmit={(values: any) => {
                  sendLogin(values);
                }}
              >
                {({
                  values, 
                  handleSubmit, 
                  touched, 
                  handleChange, 
                  errors,
                  isSubmitting,
                }) => (
                  <View style={styles.formContainer}>
                    <FormInput
                      label={i18n.t('loginScreen.emailLabel')}
                      value={values?.email}
                      placeholder={i18n.t('loginScreen.emailPlaceholder')}
                      onChangeText={(text: string) => {
                        handleChange('email')(text);
                        setError('');
                      }}
                      autoCapitalize="none"
                      keyboardType='email-address'
                      // todo: why
                      // textContentType="username"
                      error={errors?.email || " "}
                    />
                    <Row fullWidth>
                      <Column flex={1}>
                        <FormInput
                          label={i18n.t('loginScreen.passwordLabel')}
                          value={values?.password}
                          placeholder={i18n.t('loginScreen.passwordPlaceholder')}
                          onChangeText={(text: string) => {
                            handleChange('password')(text);
                            setError('');
                          }}
                          // autoComplete="password"
                          autoCapitalize="none"
                          textContentType="password"
                          error={(touched?.password || isSubmitting) ? errors?.password : " "}
                          secureTextEntry={hidePassword}
                          RightComponent={(
                            <Column rowCenter columnCenter>
                              <TouchableOpacity
                                style={{alignItems: 'center', paddingHorizontal: 5}}
                                onPressIn={() => setHidePassword(false)}
                                onPressOut={() => setHidePassword(true)}>
                                <Icon
                                  size={20}
                                  color={colors.GREEN_OFFICIAL}
                                  name="opened-eye"
                                />
                              </TouchableOpacity>
                          </Column>
                          )}
                        />
                      </Column>
                    </Row>

                    <Row containerStyle={{justifyContent: 'space-between'}}>
                      <Column containerStyle={{justifyContent: 'flex-end'}}>
                        <View variant="sectionContainer">
                          <Text variant="inputError">{error || ' '}</Text>
                        </View>
                        <TouchableOpacity
                          onPress={() => navigate(EscreenNames.ForgotPassword)}>
                          <Text>{i18n.t('loginScreen.forgotPassword')}</Text>
                        </TouchableOpacity>
                        <Row>
                          <Button
                            containerStyle={{marginTop: 25}}
                            onPress={handleSubmit}
                            disabled={isLoading}
                            testID="login-button"
                            title={i18n.t('login')}
                          />
                          {isLoading && (
                            <ActivityIndicator style={{paddingLeft: 15}} />
                          )}
                        </Row>
                      </Column>

                      <Column rowCenter containerStyle={{justifyContent: 'flex-end'}}>
                        <Text selectable={false}>{i18n.t('loginScreen.dontHaveAccount')}</Text>
                        <TouchableOpacity
                          onPress={() => navigate(EscreenNames.Signup)}>
                          <Text variant="thirdTitle">{i18n.t('createAccount')}</Text>
                        </TouchableOpacity>
                      </Column>
                    </Row>
                  </View>
                )}
              </Formik>
            </Column>
            {!isMobile && <AuthImage />}
          </Row>
        </ScrollView>
      </ContentContainer>
  );
};

const styles = StyleSheet.create({
  pageContainer: {
    justifyContent: 'flex-start',
    marginVertical: 50,
    flex: 1,
  },
  formTitle: {
    fontSize: 24,
    textAlign: 'center',
    paddingVertical: 15,
  },
  formContainer: {
    borderWidth: 0.5,
    borderColor: colors.CONCRETE,
    padding: 25,
    flex: 1,
    ...Platform.select({
      web: {
        minWidth: 350
      },
      default: {
        width: DEVICE_WIDTH
      }
    })
  },
  errorText: {
    color: 'red',
    fontSize: 15,
  },
  inputContainer: {
    width: '100%',
    flex: 1,
    height: 75,
    backgroundColor: colors.CONCRETE,
    padding: 15,
    outlineColor: Platform.OS === 'web' ? 'orange' : null,
  },
});
