import React, { ReactNode } from 'react';
import {
  Platform,
  TextInput,
  StyleSheet,
  TextInputProps,
  TextStyle,
  ViewStyle,
} from 'react-native';
import {noop} from 'lodash';
import {colors} from '@sponsor/assets';
import {Text} from '../Text';
import { Row } from '../Row';
import { View } from '../View';
import { Picker } from '../Picker/Picker';
import { RichEditor } from '../RichEditor';
import { decodeHTMLEntities } from 'utils';

export type TFormInputInfo = Pick<TextInputProps,'accessibilityLabel' | 'editable' | 'keyboardType' | 'multiline' | 'numberOfLines'> & TProps & {
  name: string,
  options?: any[],
  type: 'dropdown' | 'checkbox' | 'text' | 'richText',
  placeholder?: string,
  label?: string | ((data: any) => string);
  enabled?: boolean
}

type TFormInputProps = TFormInputInfo & {
  handleChange: ({value}: {value:any}) => void
}

type TProps = {
  label?: string | (({values}:{values:{[key in string]: any}}) => string);
  subLabel?:string,
  requiredField?: boolean,
  error?: string | any;
  inputStyles?: TextStyle;
  formContainerStyle?: ViewStyle;
  disabled?: boolean;
  testID?: string;
  inputContainerStyle?: ViewStyle,
  RightComponent?: ReactNode
};

export const FormInputLabel: React.FC<{label: string, requiredField?: boolean}> = ({label, requiredField}) => {
  return (
    <View style={{
      paddingVertical: 8,
    }}>
      <Text variant="inputLabel">{`${label}${requiredField ? '*' : ''}`}</Text>
    </View>
  )
}

export const FormInputSubLabel: React.FC<{label: string, requiredField?: boolean}> = ({label, requiredField}) => {
  return (
    <View style={{
      paddingBottom: 8,
    }}>
      <Text variant="inputSubLabel">{`${label}${requiredField ? '*' : ''}`}</Text>
    </View>
  )
}

//todo: rename FormInput - FormTextInput
export const FormInput: React.FC<TProps & TextInputProps> = ({
  label = '',
  value = '',
  placeholder = '',
  editable,
  testID,
  disabled,
  onChangeText = noop,
  requiredField,
  error = ' ',
  inputStyles = {},
  formContainerStyle = {},
  onBlur,
  inputContainerStyle,
  RightComponent,
  subLabel,
  children,
  ...otherProps
}) => {

  return (
    <View testID={testID} style={[styles.formContainer, formContainerStyle]}>
      {Boolean(label) === true && <FormInputLabel label={label} requiredField={requiredField}/>}
      {!!subLabel &&  <FormInputSubLabel label={subLabel}/>}
      <Row containerStyle={[styles.inputContainer, inputContainerStyle]}>
          <TextInput
            {...otherProps}
            editable={editable}
            //@ts-ignore
            disabled={disabled}
            placeholderTextColor="black"
            style={[
              {color: 'black'},
              styles.inputInnerContainer,
              (editable === false || disabled === true) && styles.nonEditableInput,
              Platform.OS === 'web' ? styles.outlineColorOrange : {},
              inputStyles,
            ]}
            value={value}
            placeholder={placeholder}
            onChangeText={onChangeText}
            onBlur={onBlur}
          />
          {RightComponent}
      </Row>
      {!children && String(error).length > 0 && <Text style={{paddingTop: 5}} variant="inputError">{error}</Text>}
      {children}
    </View>
  );
};


export const FormInputContainer = React.memo((props: TFormInputProps) => {
  return renderFormInput(props)
})

// todo: idealy we don't need to export this method, 
// make it private and move logic to the form
export const renderFormInput = ({
  type,
  value,
  key,
  options,
  handleChange,
  requiredField,
  placeholder,
  label,
  subLabel,
  error = ' ', //always render something to not change layout height
}: TFormInputProps & any) => {
  if(type === 'dropdown'){
    return (
        <View>
          <FormInputLabel
            label={label}
            requiredField={requiredField}
           />
          <Picker
            data={options}
            selectedValue={value}
            // enabled={!creator?.lang}
            emptyValue={!requiredField}
            onValueChange={handleChange}
          />
          <Text variant="inputError">{error}</Text>
        </View>
    )
  }
  else if(type === 'text'){
    return (
        <FormInput
          key={label}
          label={label}
          subLabel={subLabel}
          value={value}
          placeholder={placeholder}
          onChangeText={(text) => handleChange({value: text})}
          error={error}
          requiredField={requiredField}
        />
    )
  }
  else if(type === 'richText'){
    return (
      <View key={key}>
        <FormInputLabel label={label} requiredField={requiredField} />
        <RichEditor
          key={key}
          html={value} // decodeHTMLEntities?
          placeholder={placeholder}
          onChange={(descriptionText: string) => {
            handleChange({value: descriptionText})
          }}
        />
        <Text variant="inputError">{error}</Text>
      </View>
    )
  }
  return null
}

const styles = StyleSheet.create({
  formContainer: {
    // flex:1,
    borderColor: colors.DUSTY_GRAY,
  },
  inputContainer: {
    width: '100%',
    // flex: 1,
    minHeight: 50,
    // backgroundColor: colors.CONCRETE,
    //todo: make general, apply to all blocks ???
    borderColor: colors.GREEN_OFFICIAL,
    borderWidth: 1,
    borderRadius: 8,
  },
  inputInnerContainer: {
    flex:1,
    padding: 8,
    borderRadius: 8,
    //@ts-ignore
    ...Platform.select({
      web: { 
        outline: 'none'
      },
      default: {}
    })
  },
  nonEditableInput: {
    backgroundColor: colors.CONCRETE,
  },
  outlineColorOrange: {
    //TODO leave comment why we are using this
    //@ts-ignore
    outlineColor: 'orange',
  },
  label: {
    paddingVertical: 8,
  },
});
