export const fiatWithdrawalScreenLocalization = {
  en: {
    fiatWithdrawalScreen: {
      screenTitle: "Withdrawal",
      currencyPickerLabel: 'Choose currency',
      coinBalanceTitle: 'Balance',

      fiatWithdrawMethodsPickerLabel: 'Choose card',
      withdrawBtnTitle: 'Withdraw',
      fiatWithdrawMethodsEmptyListTitle: 'Add withdraw methods firstly',

      withdrawFeeRangeTitle: "Network fees",
      minimalWithdrawWarningTitle: "Minimal withdraw",
    },
  },
  uk: {
    fiatWithdrawalScreen: {
      screenTitle: "Списання коштів",

      currencyPickerLabel: 'Виберіть валюту',
      coinBalanceTitle: 'Баланс',
      fiatWithdrawMethodsPickerLabel:
        'Виберіть банківську картку',
      withdrawBtnTitle: 'Вивести',
      fiatWithdrawMethodsEmptyListTitle:
        'Спочатку додайте спосіб зарахування коштів',
      withdrawFeeRangeTitle: "Комісія мережі",
      minimalWithdrawWarningTitle: "Мінімальна сума зняття",
    },
  },
};
