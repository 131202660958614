import * as Yup from 'yup';
import {i18n} from 'localize';

export const createGoalSchema = () =>
  Yup.object().shape({
    title: Yup.string()
      .min(2, i18n.t('validationSchema.tooShort'))
      .max(200, i18n.t('validationSchema.tooLong'))
      .required(i18n.t('validationSchema.requiredField')),
    description: Yup.string()
      .min(2, i18n.t('validationSchema.tooShort'))
      .max(750, i18n.t('validationSchema.tooLong'))
      .required(i18n.t('validationSchema.requiredField')),
    count: Yup.number()
      //todo: add reminder add typeError for all number
      .typeError(i18n.t('validationSchema.positiveNumber'))
      .positive(i18n.t('validationSchema.positiveNumber'))
      .required(i18n.t('validationSchema.requiredField'))
      .max(1000000000000),
    type: Yup.string().required(i18n.t('validationSchema.requiredField')),
  });
