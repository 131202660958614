import React from 'react';
import {ContentContainer, Text} from 'components';

export const WithdrawScreen = () => {
  return (
    <ContentContainer>
      <Text />
    </ContentContainer>
  );
};
