import React from 'react';
import {StyleSheet, TouchableOpacity} from 'react-native';
import {isEmpty} from 'lodash';
import {Avatar} from '../Avatar/Avatar';
import {Row} from '../Row';
import {useNavigation} from 'hooks';
import {TTier} from 'types';
import {EscreenNames} from 'navigation';
import {colors} from '../../../assets';

type TProps = {
  subscriptionPrice?: number;
  subscriptionCurrency?: string;
  nbSponsors?: number;
  onPress?: () => void;
  containerStyle?: any;
  contentContainerStyle?: any;
  rightElement?: any;
};

// TODO: this component is not by solid, create separates components
export const CreatorCardBase: React.FC<Partial<TTier> & TProps> = ({
  creatorAvatarUri,
  onPress,
  creatorPageURL,
  containerStyle,
  contentContainerStyle,
  rightElement,
  children,
}) => {
  const {navigate} = useNavigation();

  if (!onPress) {
    onPress = () =>
      navigate(
        EscreenNames.CreatorPublicPage,
        {
          pageURL: creatorPageURL,
        },
        {
          urlParam: true,
          newTab: true,
        },
      );
  }

  return (
    <TouchableOpacity
      style={[styles.container, containerStyle]}
      onPress={onPress}
      testID="creator-card-base"
    >
      <Row containerStyle={[styles.creatorCard, contentContainerStyle]}>
        {!isEmpty(creatorAvatarUri) && (
          <Avatar size={76} uri={creatorAvatarUri} />
        )}
        {rightElement}
      </Row>
      {children}
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  container: {
    justifyContent: 'flex-start',
    backgroundColor: 'white',
    borderColor: colors.DUSTY_GRAY,
    borderWidth: 1,
    borderRadius: 8,
    padding: 14,
  },
  creatorCard: {
    justifyContent: 'flex-start',
    // width:,
    // maxWidth: 350,
  },
});
