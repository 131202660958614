import {TextStyle, ViewStyle} from 'react-native';
import React from 'react';
import { textStyles } from '../Text';

type TProps = {
  source: string;
  textStyle: TextStyle;
  containerStyle: ViewStyle;
};

// String.prototype.toHtmlEntities = function() {
//     return this.replace(/./gm, function(s) {
//         // return "&#" + s.charCodeAt(0) + ";";
//         return (s.match(/[a-z0-9\s]+/i)) ? s : "&#" + s.charCodeAt(0) + ";";
//     });
// };

export const RenderHtml: React.FC<TProps> = ({
  containerStyle,
  source,
  textStyle = {},
}) => {
  return (
    <div
      //@ts-ignore
      style={{
        flexGrow: 0,
        ...containerStyle,
        ...textStyles.fontFamily,
        ...textStyle,
      }}
      //todo: think how to replace
      dangerouslySetInnerHTML={{__html: source}}
    />
  );
};
