import AsyncStorage from "@react-native-async-storage/async-storage"
import { MMKV } from "react-native-mmkv"


// couldn't open debugger using mmkv in dev env
export const mmkvStorage = __DEV__ ? {
        set: AsyncStorage.setItem,
        getString: AsyncStorage.getItem,
        delete: AsyncStorage.removeItem
} : new MMKV()

