import React, {useCallback, useState} from 'react';
import {Button, Text} from 'components';
import {useDispatch} from 'react-redux';
import {useNavigation} from 'hooks';
import {cancelSubscription} from 'store';
import {i18n} from 'localize';
import {EscreenNames} from 'navigation';
import {TouchableOpacity} from 'react-native';
import {TSubscription} from 'types';
import { alertMessage } from 'utils';

type TProps = {
  subscription: TSubscription;
};

export const SubscriptionDelete: React.FC<TProps> = ({subscription}) => {
  const dispatch = useDispatch();
  const [, setIsLoading] = useState(false);
  const {navigate} = useNavigation();

  const deleteSubscription = useCallback(async () => {
    if (subscription) {
      dispatch(
        cancelSubscription(
          {
            subscriptionId: subscription?.id,
          },
          {
            onFinish: () => {
              setIsLoading(false);
              alertMessage("", i18n.t('cancelSubscriptionScreen.monthAccessAlertMessage'))
              navigate(EscreenNames.Subscriptions);
            },
          },
        ),
      );
    }
  }, [dispatch, navigate, subscription]);

  return (
    // <TouchableOpacity
    //   testID="subscription-delete-button"
    //   onPress={deleteSubscription}
    // >
    //   <Text testID="subscription-delete-button-text" variant="buttonText">
    //     {i18n.t('paymentScreen.cancelSubscription')}
    //   </Text>
    // </TouchableOpacity>
    <Button 
      testID="subscription-delete-button"
      title={i18n.t('paymentScreen.cancelSubscription')}
      onPress={deleteSubscription}
    />
  );
};
