
import React from 'react'
import { View, Text, StyleSheet } from 'react-native' 

export class ErrorBoundary extends React.Component {
    constructor(props:any) {
      super(props);
      this.state = { hasError: false };
    }
  
    static getDerivedStateFromError(error:any) {
      // Update state so the next render will show the fallback UI.
      return { hasError: true };
    }
  
    componentDidCatch(error:any, errorInfo:any) {
        console.log('error',error)
        // You can also log the error to an error reporting service
        //   logErrorToMyService(error, errorInfo);
    }
  
    render() {
    //   if (this.state.hasError) {
    //     // You can render any custom fallback UI
    //     return <h1>Something went wrong.</h1>;
    //   }
  
      return this.props.children; 
    }
}

const styles = StyleSheet.create({
  
})


