export const CryptoCoins = [
  {
    id: 2,
    code: 'btc',
    name: 'Bitcoin',
    has_memo: false,
    icons: {
      std: 'https://kuna.io/icons/currency/std/btc.svg',
      xl: 'https://kuna.io/icons/currency/xl/btc.svg',
      png_2x: 'https://kuna.io/icons/currency/png/BTC@2x.png',
      png_3x: 'https://kuna.io/icons/currency/png/BTC@3x.png',
    },
    coin: true,
    explorer_link: 'https://blockchair.com/bitcoin/transaction/#{txid}',
    sort_order: 2,
    precision: {
      real: 8,
      trade: 6,
    },
    privileged: false,
    fuel: null,
  },
  {
    id: 6,
    code: 'eth',
    name: 'Ethereum',
    has_memo: false,
    icons: {
      std: 'https://kuna.io/icons/currency/std/eth.svg',
      xl: 'https://kuna.io/icons/currency/xl/eth.svg',
      png_2x: 'https://kuna.io/icons/currency/png/ETH@2x.png',
      png_3x: 'https://kuna.io/icons/currency/png/ETH@3x.png',
    },
    coin: true,
    explorer_link: 'https://etherscan.io/tx/#{txid}',
    sort_order: 3,
    precision: {
      real: 8,
      trade: 4,
    },
    privileged: false,
    fuel: null,
  },
  {
    id: 41,
    code: 'usdt',
    name: 'Tether',
    has_memo: false,
    icons: {
      std: 'https://kuna.io/icons/currency/std/usdt.svg',
      xl: 'https://kuna.io/icons/currency/xl/usdt.svg',
      png_2x: 'https://kuna.io/icons/currency/png/USDT@2x.png',
      png_3x: 'https://kuna.io/icons/currency/png/USDT@3x.png',
    },
    coin: true,
    explorer_link: 'https://etherscan.io/tx/#{txid}',
    sort_order: 4,
    precision: {
      real: 6,
      trade: 6,
    },
    privileged: false,
    fuel: null,
  },
  {
    id: 49,
    code: 'usdc',
    name: 'USD Coin',
    has_memo: false,
    icons: {
      std: 'https://kuna.io/icons/currency/std/usdc.svg',
      xl: 'https://kuna.io/icons/currency/xl/usdc.svg',
      png_2x: 'https://kuna.io/icons/currency/png/USDC@2x.png',
      png_3x: 'https://kuna.io/icons/currency/png/USDC@3x.png',
    },
    coin: true,
    explorer_link: null,
    sort_order: 21,
    precision: {
      real: 6,
      trade: 2,
    },
    privileged: false,
    fuel: null,
  },
  {
    id: 16,
    code: 'xrp',
    name: 'Ripple',
    has_memo: true,
    icons: {
      std: 'https://kuna.io/icons/currency/std/xrp.svg',
      xl: 'https://kuna.io/icons/currency/xl/xrp.svg',
      png_2x: 'https://kuna.io/icons/currency/png/XRP@2x.png',
      png_3x: 'https://kuna.io/icons/currency/png/XRP@3x.png',
    },
    coin: true,
    explorer_link: 'https://bithomp.com/explorer/#{txid}',
    sort_order: 25,
    precision: {
      real: 8,
      trade: 6,
    },
    privileged: false,
    fuel: null,
  },
  {
    id: 23,
    code: 'ltc',
    name: 'Litecoin',
    has_memo: false,
    icons: {
      std: 'https://kuna.io/icons/currency/std/ltc.svg',
      xl: 'https://kuna.io/icons/currency/xl/ltc.svg',
      png_2x: 'https://kuna.io/icons/currency/png/LTC@2x.png',
      png_3x: 'https://kuna.io/icons/currency/png/LTC@3x.png',
    },
    coin: true,
    explorer_link: 'https://live.blockcypher.com/ltc/tx/#{txid}',
    sort_order: 13,
    precision: {
      real: 8,
      trade: 6,
    },
    privileged: false,
    fuel: null,
  },
  {
    id: 8,
    code: 'bch',
    name: 'Bitcoin Cash',
    has_memo: false,
    icons: {
      std: 'https://kuna.io/icons/currency/std/bch.svg',
      xl: 'https://kuna.io/icons/currency/xl/bch.svg',
      png_2x: 'https://kuna.io/icons/currency/png/BCH@2x.png',
      png_3x: 'https://kuna.io/icons/currency/png/BCH@3x.png',
    },
    coin: true,
    explorer_link: 'https://blockchair.com/bitcoin-cash/transaction/#{txid}',
    sort_order: 5,
    precision: {
      real: 8,
      trade: 6,
    },
    privileged: false,
    fuel: null,
  },
  {
    id: 22,
    code: 'xlm',
    name: 'Stellar',
    has_memo: true,
    icons: {
      std: 'https://kuna.io/icons/currency/std/xlm.svg',
      xl: 'https://kuna.io/icons/currency/xl/xlm.svg',
      png_2x: 'https://kuna.io/icons/currency/png/XLM@2x.png',
      png_3x: 'https://kuna.io/icons/currency/png/XLM@3x.png',
    },
    coin: true,
    explorer_link: 'https://stellarchain.io/tx/#{txid}',
    sort_order: 24,
    precision: {
      real: 8,
      trade: 8,
    },
    privileged: false,
    fuel: null,
  },
  {
    id: 17,
    code: 'eos',
    name: 'EOS',
    has_memo: true,
    icons: {
      std: 'https://kuna.io/icons/currency/std/eos.svg',
      xl: 'https://kuna.io/icons/currency/xl/eos.svg',
      png_2x: 'https://kuna.io/icons/currency/png/EOS@2x.png',
      png_3x: 'https://kuna.io/icons/currency/png/EOS@3x.png',
    },
    coin: true,
    explorer_link: 'https://eosflare.io/tx/#{txid}',
    sort_order: 10,
    precision: {
      real: 4,
      trade: 4,
    },
    privileged: false,
    fuel: null,
  },
  {
    id: 24,
    code: 'dash',
    name: 'Dash',
    has_memo: false,
    icons: {
      std: 'https://kuna.io/icons/currency/std/dash.svg',
      xl: 'https://kuna.io/icons/currency/xl/dash.svg',
      png_2x: 'https://kuna.io/icons/currency/png/DASH@2x.png',
      png_3x: 'https://kuna.io/icons/currency/png/DASH@3x.png',
    },
    coin: true,
    explorer_link: 'https://live.blockcypher.com/dash/tx/#{txid}',
    sort_order: 8,
    precision: {
      real: 8,
      trade: 6,
    },
    privileged: false,
    fuel: null,
  },
  {
    id: 25,
    code: 'zec',
    name: 'Zcash',
    has_memo: false,
    icons: {
      std: 'https://kuna.io/icons/currency/std/zec.svg',
      xl: 'https://kuna.io/icons/currency/xl/zec.svg',
      png_2x: 'https://kuna.io/icons/currency/png/ZEC@2x.png',
      png_3x: 'https://kuna.io/icons/currency/png/ZEC@3x.png',
    },
    coin: true,
    explorer_link: 'https://explorer.zcha.in/transactions/#{txid}',
    sort_order: 26,
    precision: {
      real: 8,
      trade: 6,
    },
    privileged: false,
    fuel: null,
  },
  {
    id: 7,
    code: 'waves',
    name: 'WAVES',
    has_memo: false,
    icons: {
      std: 'https://kuna.io/icons/currency/std/waves.svg',
      xl: 'https://kuna.io/icons/currency/xl/waves.svg',
      png_2x: 'https://kuna.io/icons/currency/png/WAVES@2x.png',
      png_3x: 'https://kuna.io/icons/currency/png/WAVES@3x.png',
    },
    coin: true,
    explorer_link: 'http://wavesexplorer.com/tx/#{txid}',
    sort_order: 22,
    precision: {
      real: 8,
      trade: 6,
    },
    privileged: false,
    fuel: null,
  },
  {
    id: 46,
    code: 'bnb',
    name: 'Binance Coin',
    has_memo: true,
    icons: {
      std: 'https://kuna.io/icons/currency/std/bnb.svg',
      xl: 'https://kuna.io/icons/currency/xl/bnb.svg',
      png_2x: 'https://kuna.io/icons/currency/png/BNB@2x.png',
      png_3x: 'https://kuna.io/icons/currency/png/BNB@3x.png',
    },
    coin: true,
    explorer_link: 'https://explorer.binance.org/tx/#{txid}',
    sort_order: 6,
    precision: {
      real: 6,
      trade: 6,
    },
    privileged: false,
    fuel: null,
  },
  {
    id: 50,
    code: 'dai',
    name: 'Dai',
    has_memo: false,
    icons: {
      std: 'https://kuna.io/icons/currency/std/dai.svg',
      xl: 'https://kuna.io/icons/currency/xl/dai.svg',
      png_2x: 'https://kuna.io/icons/currency/png/DAI@2x.png',
      png_3x: 'https://kuna.io/icons/currency/png/DAI@3x.png',
    },
    coin: true,
    explorer_link: null,
    sort_order: 7,
    precision: {
      real: 18,
      trade: 2,
    },
    privileged: false,
    fuel: null,
  },
  {
    id: 64,
    code: 'link',
    name: 'Chainlink',
    has_memo: false,
    icons: {
      std: 'https://kuna.io/icons/currency/std/link.svg',
      xl: 'https://kuna.io/icons/currency/xl/link.svg',
      png_2x: 'https://kuna.io/icons/currency/png/LINK@2x.png',
      png_3x: 'https://kuna.io/icons/currency/png/LINK@3x.png',
    },
    coin: true,
    explorer_link: 'https://etherscan.io/tx/#{txid}',
    sort_order: 12,
    precision: {
      real: 6,
      trade: 6,
    },
    privileged: false,
    fuel: null,
  },
  {
    id: 62,
    code: 'trx',
    name: 'TRX',
    has_memo: false,
    icons: {
      std: 'https://kuna.io/icons/currency/std/trx.svg',
      xl: 'https://kuna.io/icons/currency/xl/trx.svg',
      png_2x: 'https://kuna.io/icons/currency/png/TRX@2x.png',
      png_3x: 'https://kuna.io/icons/currency/png/TRX@3x.png',
    },
    coin: true,
    explorer_link: 'https://tronscan.org/#/transaction/#{txid}',
    sort_order: 17,
    precision: {
      real: 6,
      trade: 6,
    },
    privileged: false,
    fuel: null,
  },
  {
    id: 63,
    code: 'uni',
    name: 'UNI',
    has_memo: false,
    icons: {
      std: 'https://kuna.io/icons/currency/std/uni.svg',
      xl: 'https://kuna.io/icons/currency/xl/uni.svg',
      png_2x: 'https://kuna.io/icons/currency/png/UNI@2x.png',
      png_3x: 'https://kuna.io/icons/currency/png/UNI@3x.png',
    },
    coin: true,
    explorer_link: 'https://etherscan.io/tx/#{txid}',
    sort_order: 19,
    precision: {
      real: 6,
      trade: 6,
    },
    privileged: false,
    fuel: null,
  },
  {
    id: 66,
    code: 'shib',
    name: 'Shiba Inu',
    has_memo: false,
    icons: {
      std: 'https://kuna.io/icons/currency/std/shib.svg',
      xl: 'https://kuna.io/icons/currency/xl/shib.svg',
      png_2x: 'https://kuna.io/icons/currency/png/SHIB@2x.png',
      png_3x: 'https://kuna.io/icons/currency/png/SHIB@3x.png',
    },
    coin: true,
    explorer_link: 'https://etherscan.io/tx/#{txid}',
    sort_order: 15,
    precision: {
      real: 6,
      trade: 6,
    },
    privileged: false,
    fuel: null,
  },
  {
    id: 65,
    code: 'doge',
    name: 'Dogecoin',
    has_memo: false,
    icons: {
      std: 'https://kuna.io/icons/currency/std/doge.svg',
      xl: 'https://kuna.io/icons/currency/xl/doge.svg',
      png_2x: 'https://kuna.io/icons/currency/png/DOGE@2x.png',
      png_3x: 'https://kuna.io/icons/currency/png/DOGE@3x.png',
    },
    coin: true,
    explorer_link: 'https://live.blockcypher.com/doge/tx/#{txid}',
    sort_order: 9,
    precision: {
      real: 8,
      trade: 6,
    },
    privileged: false,
    fuel: null,
  },
];
