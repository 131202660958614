import {
  Text,
  View,
} from 'components';
import React from 'react';
//todo: create custom TabView
import {SceneMap, TabView} from 'react-native-tab-view';
import { EditCollaborationTiersTab } from './EditCollaborationTiersTab';
import { EditTiersTab } from './EditTiersTab';
import {TabBar} from 'components';
import { i18n } from 'localize';
import { selectExternalCollaborationsWaitingAcceptanceCount } from 'store';
import { useSelector } from 'react-redux';
import { DEVICE_WIDTH } from '@sponsor/constants';

export const EditTiers = () => {
  const [index, setIndex] = React.useState(0);

  const routes = [
    {key: 'first', title: i18n.t('editTiers.yoursTiers')},
    {key: 'second', title: i18n.t('editTiers.yourCollaborationTiers')},
  ];

  const collaborationsWaitingAcceptanceCount = useSelector(
    selectExternalCollaborationsWaitingAcceptanceCount,
  );

  const renderScene = SceneMap({
    first: EditTiersTab,
    second: EditCollaborationTiersTab,
  });

  return (
    <View style={{flex:1, backgroundColor: 'white'}}>
      {collaborationsWaitingAcceptanceCount > 0 && <Text variant='formHint' center>
        {i18n.t("editTiers.userHasWaitingResponseCollaborations")} 
      </Text>}
      <TabView
        navigationState={{index, routes}}
        renderScene={renderScene}
        renderTabBar={props => {
          return <TabBar {...props} />;
        }}
        onIndexChange={setIndex}
        // sceneContainerStyle={styles.tabContentContainerStyle}
        // initialLayout={{width: DEVICE_WIDTH}}
        // style={styles.tabContainer}
        // sceneContainerStyle={{alignItems: 'center'}}
      />
    </View>
  );
};

