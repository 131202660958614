import {ActivityIndicator, ScrollView, StyleSheet} from 'react-native';
import React, {useMemo, useState} from 'react';
import {Formik} from 'formik';
import {useDispatchAction} from 'hooks';
import {uploadWithdrawMethods} from './money-withdraw-methods.actions';
import {getMoneyWithdrawMethodSchema} from './MoneyWithdrawMethodValidation';
import {
  Button,
  Checkbox,
  ContentContainer,
  FormInput,
  FormInputLabel,
  Text,
  View,
} from 'components';
import {i18n} from 'localize';
import {
  ECreatorWithdrawMethodsTypes,
  TCreatorWithdrawMethod,
} from './money-withdraw-methods.types';
import {Picker} from '@react-native-picker/picker';
import {CryptoCoins} from '../crypto-payment/cryypto-payment.constants';
import {map} from 'lodash';
import {colors} from '@sponsor/assets';
import {useParams} from 'hooks';
import {ActionResult} from '../components/src/ActionResult/ActionResult.component';

export const EditWithdrawMethodScreen = () => {
  
  const {type, id} = useParams();

  const [withdrawMethodType, setWithdrawMethodType] = useState<
    TCreatorWithdrawMethod['type']
  >(type || ECreatorWithdrawMethodsTypes.crypto_wallet);

  const {error, isLoading, submitAction} = useDispatchAction(
    uploadWithdrawMethods,
  );

  const CryptoWalletForm = useMemo(() => {
    return (
      <Formik
        initialValues={{
          address: '',
          memo: '',
          currency: {
            code: '',
            name: '',
          },
          type: ECreatorWithdrawMethodsTypes.crypto_wallet,
        }}
        validationSchema={getMoneyWithdrawMethodSchema()}
        onSubmit={values => {
          submitAction({
            ...values,
            currencyName: values?.currency?.code
              ? values?.currency?.code
              : values?.currency,
          });
        }}
      >
        {({values, errors, handleSubmit, handleChange}) => (
          <View>
            <View variant="sectionContainer">
              <FormInputLabel 
                label={i18n.t('editWithdrawMethodScreen.currencyInputLabel')}
                requiredField
              />
              {/* todo: one style picker */}
              <Picker
                style={{
                  padding: 10,
                  borderRadius: 8,
                  borderColor: colors.GREEN_OFFICIAL,
                }}
                selectedValue={values?.currency}
                // typeof CryptoCoins[0]
                onValueChange={(item) =>
                  //todo: fix it
                  //@ts-ignore 
                  handleChange('currency')(item)
                }>
                <Picker.Item value={''} label={''} />
                {map(CryptoCoins, c => (
                  <Picker.Item value={c.code} label={c.name} />
                ))}
                <Picker.Item />
              </Picker>
            </View>
            <FormInput
              multiline
              numberOfLines={3}
              requiredField
              label={`${i18n.t(
                'editWithdrawMethodScreen.cryptoWalletAdressInputLabel',
              )}`}
              // style={{padding:10}}
              value={values?.address}
              onChangeText={handleChange('address')}
              error={errors?.address}
            />
            <FormInput
              numberOfLines={1}
              label={i18n.t(
                'editWithdrawMethodScreen.cryptoWalletMemoInputLabel',
              )}
              value={values?.memo}
              placeholder={i18n.t(
                'editWithdrawMethodScreen.cryptoWalletMemoInputPlaceholder',
              )}
              onChangeText={handleChange('memo')}
              error={errors?.memo}
            />
            <Button
              title={i18n.t('submitEdit')}
              onPress={handleSubmit}
              primary
              containerStyle={{paddingVertical: 25}}
            />
          </View>
        )}
      </Formik>
    );
  }, [submitAction]);

  return (
    <ContentContainer>
      <View variant='screenContainer'>
      <ScrollView>
        <View variant="sectionContainer">
          <Checkbox
            containerStyle={styles.checkboxContainer}
            title={i18n.t('editWithdrawMethodScreen.withdrawMethodCryptoType')}
            onPress={() =>
              setWithdrawMethodType(ECreatorWithdrawMethodsTypes.crypto_wallet)
            }
            value={withdrawMethodType === 'crypto_wallet'}
          />
        </View>

        {withdrawMethodType == 'crypto_wallet' && CryptoWalletForm}

        <ActionResult
          isLoading={isLoading}
          error={error}
          renderError={err => <Text variant="inputError">{err}</Text>}
          renderLoading={() => <ActivityIndicator />}
          renderSuccessMessage={() => (
            <Text variant="formHint">{i18n.t('operationSuccess')}</Text>
          )}
        />
      </ScrollView>
      </View>
    </ContentContainer>
  );
};

const styles = StyleSheet.create({
  checkboxContainer: {
    marginTop: 10,
  },
});
