import {ICreatorMerchantWithdrawMethod} from 'app/creatorMerchant/creatorMerchant.types';
import { ICurrency } from 'app/payment/payments.types';
import {AxiosResponse} from 'types';

export type TMoneyWithdrawMethodsState = {
  [key in string]: TCreatorWithdrawMethod;
};

export enum ECreatorWithdrawMethodsTypes {
  crypto_wallet = 'crypto_wallet',
  card = 'card',
  merchant_entrepreneur = 'merchant_entrepreneur',
}

export interface ICryptoWalletWithdrawMethod {
  id: string;
  type: ECreatorWithdrawMethodsTypes.crypto_wallet;
  currency: ICurrency;

  address: string;
  memo: string;
}

export interface ICardWithdrawMethod {
  id: string;
  type: ECreatorWithdrawMethodsTypes.card;
  currency: ICurrency;

  cardMask: string;
  cardToken: String;
  cardNumber: String;
  cardType: String;
  tokenExperiedAt: String;
  senderFirstName: String;
  senderLastName: String;
}

export type TCreatorWithdrawMethod =
  | ICryptoWalletWithdrawMethod
  | ICardWithdrawMethod
  | ICreatorMerchantWithdrawMethod;

export type TPostUploadWithdrawMethodInput = Omit<TCreatorWithdrawMethod, 'id' | 'currency'> & {
  currencyName: string
};

export type TPostUploadWithdrawMethodOutput =
  AxiosResponse<TCreatorWithdrawMethod>;

export type TDeleteWithdrawMethodInput = {
  id: string;
};
export type TDeleteWithdrawMethodOutput = AxiosResponse<null>;

export type TPostWithdrawOutput = AxiosResponse<any>;
