//todo: replace with custom loader or leave ActivityIndicator
import {ActivityIndicator, ScrollView, StyleSheet} from 'react-native';
import React from 'react';
import {useDispatchAction} from 'hooks';
import {
  ContentContainer,
  Text,
} from 'components';
import {i18n} from 'localize';
import {uploadCreatorMerchantEntrepreneur} from './creatorMerchant.actions';
import {useParams} from 'hooks';
import {ActionResult} from '../components/src/ActionResult/ActionResult.component';
import {EditMerchantForm} from './EditMerchantForm.container';

export const EditCreatorMerchantScreen = () => {
  const {id} = useParams();

  const {
    error: uploadMerchantError,
    isLoading: isUploadMerchantLoading,
    submitAction: uploadMerchant,
  } = useDispatchAction(uploadCreatorMerchantEntrepreneur);

  return (
    <ContentContainer>
      <ScrollView contentContainerStyle={{flex: 1, padding: 10}}>
        <EditMerchantForm 
            id={id} 
            onSubmit={uploadMerchant} 
        />
        <ActionResult
          isLoading={isUploadMerchantLoading}
          error={uploadMerchantError}
          renderError={err => <Text variant="inputError">{err}</Text>}
          renderLoading={() => <ActivityIndicator />}
          renderSuccessMessage={() => (
            <Text variant="formHint">{i18n.t('operationSuccess')}</Text>
          )}
        />
      </ScrollView>
    </ContentContainer>
  );
};

const styles = StyleSheet.create({
  checkboxContainer: {
    marginTop: 10,
  },
});
