import {filter, isEmpty, unionBy} from 'lodash';
import React, {useCallback, useState} from 'react';
import {useSelector} from 'react-redux';
import {LOG} from 'utils';

type TComment = any;

type TProps = {
  meta: {
    identificationField: string;
    postId?: string;
  } & {
    [key in string]: string;
  };
  initialDataSelector?: any;
  onSubmit: (text: string, meta: any) => Promise<TComment>;
  onFetch: (options: any, meta: any) => Promise<TComment[]>;
  onDelete: (comment: any) => Promise<any>;
  initialData?: any[];
  canUserLeaveCommentSelector?: any;
  children: ({
    handleFetchMore,
    comments,
    commentText,
    handleCommentTextChange,
    submitComment,
    isSubmitting,
    isFetching,
    noMoreComments,
    handleDelete,
    userCanLeaveComment,
  }: {
    // todo: add pagination
    handleFetchMore: () => void;
    comments: any[];
    commentText: string;
    handleCommentTextChange: (text: string) => void;
    submitComment: () => void;
    isSubmitting: boolean;
    isFetching: boolean;
    noMoreComments: boolean;
    handleDelete: (comment: any) => () => Promise<any>;
    userCanLeaveComment: boolean;
  }) => JSX.Element;
};

export const PostCommentProvider: React.FC<TProps> = React.memo(({
  meta = {identificationField: 'id'},
  onFetch,
  onDelete,
  initialData,
  initialDataSelector,
  onSubmit,
  children,
  canUserLeaveCommentSelector,
}) => {
  const [commentText, setCommentText] = useState('');
  const [isFetching, setIsFetching] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [noMoreComments, setNoMoreComments] = useState(false);

  const storeInitialData = useSelector(state =>
    initialDataSelector?.(state, meta?.postId),
  );

  const [comments, setComments] = useState<TComment[]>(
    initialData || storeInitialData || [],
  );

  //todo: check performance, probably create another container for
  const userCanLeaveComment: boolean = useSelector(state =>
    canUserLeaveCommentSelector?.(state, meta?.postId),
  );

  const handleFetchMore = useCallback(async () => {
    setIsFetching(true);
    try {
      // TODO: ADD pagination inside options (first field)
      const fetchedComments: any[] = await onFetch?.(null, meta);
      const updatedComments = unionBy(
        comments,
        fetchedComments,
        meta?.identificationField,
      );
      if (updatedComments.length === comments?.length) {
        setNoMoreComments(true);
      }
      setComments(updatedComments);
    } finally {
      setIsFetching(false);
    }
  }, [comments, onFetch, meta]);

  const submitComment = useCallback(async () => {
    setIsSubmitting(true);
    try {
      const data: TComment = await onSubmit?.(commentText, meta);
      if (!isEmpty(data)) {
        setComments([...comments, data]);
        setCommentText('');
      }
    } finally {
      setIsSubmitting(false);
    }
  }, [commentText, meta, comments, onSubmit]);

  const handleDelete = useCallback(
    item => {
      return async () => {
        try {
          // todo: do something with response
          await onDelete?.(item);
          setComments(
            filter(
              comments,
              c =>
                c?.[meta?.identificationField] !==
                item?.[meta.identificationField],
            ),
          );
        } catch (err) {
          LOG(err);
        }
      };
    },
    [comments, meta, onDelete],
  );

  return children({
    handleFetchMore,
    comments,
    commentText,
    handleCommentTextChange: setCommentText,
    submitComment,
    isFetching,
    isSubmitting,
    noMoreComments,
    handleDelete,
    //todo: default value change
    userCanLeaveComment: canUserLeaveCommentSelector
      ? userCanLeaveComment
      : true,
  });
});
