import {NetworkService} from '../services';
import {ApiRequest} from '../types';
import {
  TGetAvaialbleCoinsOutput,
  TGetPaymentInfoOutput,
  TGetPaymentInfoInput,
} from './crypto-payment.types';

//todo: rename TGetAvaialbleCoinsOutput
export const getAvailableCoinsForCurrency = async (inputs: {
  currency: string;
}): ApiRequest<TGetAvaialbleCoinsOutput> => {
  return NetworkService.post(
    //todo: move to another route, standalone
    '/v1/subscriptions/coins',
    inputs,
  );
};

export const postGetPaymentInfo = async (
  inputs: TGetPaymentInfoInput,
): ApiRequest<TGetPaymentInfoOutput> => {
  return NetworkService.post(
    //todo: move to another route, standalone
    '/v1/subscriptions/crypto-payment-info',
    inputs,
  );
};

export const CryptoPaymentService = {
  getAvailableCoinsForCurrency,
  postGetPaymentInfo,
};
