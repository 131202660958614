import createSagaMiddleware from 'redux-saga';
import {compose, applyMiddleware, createStore} from 'redux';
import {rootSaga} from './rootSaga';

const composeEnhancers =
  (typeof window !== 'undefined' &&
    // @ts-ignore
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose;

export const configureStore = (rootReducer: any) => {
  const sagaMiddleware = createSagaMiddleware();
  const store = createStore(
    rootReducer,
    composeEnhancers(applyMiddleware(sagaMiddleware)),
  );

  sagaMiddleware.run(rootSaga);

  return {
    store,
  };
};
