import React from 'react';
import { i18n } from "localize";
import { ActivityIndicator } from "react-native";
import { Text } from "../Text";

export const ActionResultDefaultRenders = {
    renderLoading: () => (
      <ActivityIndicator />
    ),
    renderError:(error: string) => (
        <Text variant="inputError">{error}</Text>
    ),
    renderSuccessMessage:() => (
      <Text variant='formHint'>
          {i18n.t('operationSuccess')}
      </Text>
    )
}