import {combineReducers} from 'redux';
import translationsReducer, {creatorTranslationFlow} from './translations';
import creatorReducer, {creatorFlow} from './creator';
import greetingsReducer, {GreetingsFlow} from './greetings';
import {fork} from '@redux-saga/core/effects';
import {moneyWithdrawMethodReducer} from '../../../money-withdraw-methods/money-withdraw-methods.reducer';
import {moneyWithdrawMethodFlow} from '../../../money-withdraw-methods/money-withdraw-methods.saga';
import { creatorSettingsFlow } from './settings';

export * from './creator';
export * from './greetings';
export * from './translations';

export default combineReducers({
  basicInfo: creatorReducer,
  translations: translationsReducer,
  greetings: greetingsReducer,
  withdrawMethods: moneyWithdrawMethodReducer,
});

export function* creatorSaga() {
  yield fork(creatorTranslationFlow);
  yield fork(creatorFlow);
  yield fork(GreetingsFlow);
  yield fork(moneyWithdrawMethodFlow);
  yield fork(creatorSettingsFlow)
}
