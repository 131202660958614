import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {
  TDeleteWithdrawMethodInput,
  TMoneyWithdrawMethodsState,
  TPostUploadWithdrawMethodOutput,
} from './money-withdraw-methods.types';
import {
  deleteWithdrawMethodsSuccess,
  uploadWithdrawMethodsSuccess,
} from './money-withdraw-methods.actions';
import {TMyGetCreatorOutput} from 'types';
import {forEach} from 'lodash';
import {createAction} from 'typesafe-actions';

export const SET_WITHDRAW_METHODS_STATE = 'withdrawMethods/setState';

export const setWithdrawMethodsState = createAction(
  SET_WITHDRAW_METHODS_STATE,
)<any>();

const initialState: TMoneyWithdrawMethodsState = {

}

export const moneyWithdrawMethodsSlice = createSlice({
  name: 'withdrawMethods',
  initialState,
  extraReducers: {
    [uploadWithdrawMethodsSuccess.toString()]: (
      state: TMoneyWithdrawMethodsState,
      action: PayloadAction<TPostUploadWithdrawMethodOutput['data']>,
    ) => {
      if (action.payload?.id) {
        state[action.payload?.id] = action.payload;
      }
    },
    [deleteWithdrawMethodsSuccess.toString()]: (
      state: TMoneyWithdrawMethodsState,
      action: PayloadAction<TDeleteWithdrawMethodInput>,
    ) => {
      if (action.payload?.id) {
        delete state[action.payload.id];
      }
    },
    [setWithdrawMethodsState?.toString?.()]: (
      state: TMoneyWithdrawMethodsState,
      action: PayloadAction<TMyGetCreatorOutput['data']['withdrawMethods']>,
    ) => {
      if (action?.payload) {
        forEach(action?.payload, w => {
          if (w?.id) {
            state[w.id] = w;
          }
        });
      }
    },
  },
  reducers: {},
});

export const moneyWithdrawMethodReducer = moneyWithdrawMethodsSlice.reducer;
