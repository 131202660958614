import {fork} from 'redux-saga/effects';
import {authFlow} from './auth';
import {creatorSaga} from './creator';
import {creatorStatsFlow} from './creatorStats';
import {initializationFlow} from './initialization';
import {publicPostsFlow, rootPostsSaga} from './posts';
import {searchFlow} from './search';
import {tiersFlow} from './tiers';
import {userFlow} from './user';

import {unathorizedSubscriptionFlow} from '../../unathorized-subscription/unathorized-subscription.saga';
import {subscriptionFlow} from '../../subscription/subscription.saga';
import {moneyWithdrawFlow} from '../../money-withdraw/money-withdraw.saga';
import {tiersTranslationsFlow} from '../../tiersTranslations/tiersTranslations.saga';
import {creatorMerchantFlow} from '../../creatorMerchant/creatorMerchant.saga';
import { postsTranslationsFlow } from '../../posts-translations/posts-translations.saga'
import { goalsTranslationsFlow } from '../../goalsTranslations/goalsTranslations.saga';

// notice how we now only export the rootSaga
// single entry point to start all Sagas at once
export function* rootSaga() {
  yield fork(postsTranslationsFlow);

  yield fork(userFlow);
  yield fork(authFlow);
  yield fork(initializationFlow);
  yield fork(creatorSaga);
  yield fork(tiersFlow);
  yield fork(rootPostsSaga);
  yield fork(creatorStatsFlow);
  yield fork(searchFlow);
  yield fork(publicPostsFlow);
  yield fork(moneyWithdrawFlow);

  yield fork(unathorizedSubscriptionFlow);
  yield fork(subscriptionFlow);
  yield fork(creatorMerchantFlow);

  yield fork(tiersTranslationsFlow);
  yield fork(goalsTranslationsFlow);
}
