import {takeEvery} from 'redux-saga/effects';

import {fetchSaga} from '../store/src/common';
import {
  deleteTierTranslation,
  updateTierTranslation,
} from './tiersTranslations.actions';
import {TiersTranslationsService} from './tiersTranslations.api';

export function* tiersTranslationsFlow() {
  yield takeEvery(
    updateTierTranslation?.toString?.(),
    fetchSaga(TiersTranslationsService.putUpdateTiersTranslations),
  );
  yield takeEvery(
    deleteTierTranslation?.toString?.(),
    fetchSaga(TiersTranslationsService.deleteTiersTranslations),
  );
}
