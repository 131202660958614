export const allPaymentCurrencies = [
  {
    label: 'Гривня UAH',
    value: 'UAH',
  },
  {
    label: 'United States dollar USD',
    value: 'USD',
  },
  //todo: add crypto coins
];
