
import { i18n } from 'localize';
import React from 'react'
import { Platform } from 'react-native' 
import { alertMessage } from 'utils';

export const ConfirmationDialog: React.FC<{
    title: string,
    desc: string,
    render: ({onPress}: {onPress: any}) => any, 
    onPress: () => void,
}> = ({
    title,
    desc,
    onPress,
    render,
})  => {

    const showAlert = () =>  
        alertMessage(
            title,
            desc,
            [
                {
                    text: "OK",
                    onPress,
                },
                {
                    text: i18n.t("dialogs.cancelBtnTitle"),
                    style: "cancel",
                },
            
            ],
        {
            cancelable: true,
        }
    );

    return render({onPress : showAlert})
}


