import React, {useCallback} from 'react';
import {MerchantCard} from './MerchantCard.component';
import {useSelector} from 'react-redux';
import {TStore} from 'store';
import {selectWithdrawMethodsById} from '../money-withdraw-methods/monet-withdraw-methods.selectors';
import {ECreatorWithdrawMethodsTypes} from '../money-withdraw-methods/money-withdraw-methods.types';
import {useNavigation} from 'hooks';
import {EscreenNames} from 'navigation';

type TProps = {
  id: string;
};

export const MerchantCardContainer: React.FC<TProps> = ({id}) => {
  const withdrawMethod = useSelector((state: TStore) =>
    selectWithdrawMethodsById(state, id),
  );

  const {navigate} = useNavigation();

  const goToEditPage = useCallback(() => {
    navigate(EscreenNames.EditCreatorMerchantScreen, {id});
  }, [navigate, id]);

  if (
    withdrawMethod.type !== ECreatorWithdrawMethodsTypes.merchant_entrepreneur
  ) {
    return null;
  }

  return (
    <MerchantCard 
      onFinancialConfigPress={goToEditPage} 
      onEdit={goToEditPage}
      {...withdrawMethod} 
    />
  );
};
