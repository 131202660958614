import React, {useMemo, useState} from 'react';
import {View, Text, Button} from 'components';
import {colors} from '@sponsor/assets';
import {i18n} from 'localize';
import moment from 'moment';
import {useSelector} from 'react-redux';
import {selectUser} from 'store';
import {EPaymentPeriod} from 'types';
import {useRetrievePaymentLink} from './useRetrievePaymentLink';
import {Linking, Platform} from 'react-native';
import { ICurrency } from 'app/payment/payments.types';

type IProps = {
  minPrice: number;
  testID?: string;
  initialPrice?: number;
  currency: ICurrency;
  tierId: string;
  description: string;
  creatorId: string;
  creatorTitle: string;
  creatorAvatarUri: string;
  creatorPageURL: string;
};

export const SubscriptionPayForm: React.FC<IProps> = ({
  testID,
  initialPrice = 0,
  tierId,
}) => {
  const user = useSelector(selectUser);
  // const [formHTML, setFormHTML] = useState<any | null>(null);
  const [price] = useState(String(initialPrice));
  const [paymentPeriod] = useState(EPaymentPeriod.oneMonth);
  const [error] = useState('');

  const paymentData = useMemo(() => {
    return {
      tierId,
      userId: user?.id || '',
      email: user?.email || '',
      userPrice: price,
      platform: Platform.OS
    };
  }, [price, user, tierId]);

  //todo: update typing here
  const {
    error: paymentLinkError,
    loading,
    getLink,
  } = useRetrievePaymentLink(paymentData);

  const nextPaymentDate = useMemo(() => {
    let nbMonths = 1;
    switch (paymentPeriod) {
      case EPaymentPeriod.oneMonth:
        nbMonths = 1;
        break;
      case EPaymentPeriod.threeMonths:
        nbMonths = 3;
        break;
      case EPaymentPeriod.sixMonths:
        nbMonths = 6;
        break;
      case EPaymentPeriod.twelveMonths:
        nbMonths = 12;
        break;
      default:
        nbMonths = 1;
    }
    return moment().add(nbMonths, 'month').format('LL');
  }, [paymentPeriod]);

  const renderPayButton = () => {
    return (
      <Button
        title={i18n.t('buySubscriptionBtnTitle')}
        disabled={loading}
        onPress={async () => {
          let link = await getLink();
          if (!link) {
            link = await getLink();
          }
          if (link) {
            Linking.openURL(link);
          }
        }}
      />
    );
  };

  return (
    <View testID={testID}>
      {/* <FormInput
        value={price}
        placeholder={i18n.t('paymentScreen.writeCustomPrice')}
        keyboardType="decimal-pad"
        onChangeText={(text) => {
          const parsedPrice  = parseFloat(text)
          setPrice(text)
          if(parsedPrice < initialPrice){
            setError(`${initialPrice} - ${i18n.t('validationSchema.minimalPrice')}`)
          }else{
            setError('')
          }
        }}
      /> */}
      <Text variant="inputError">{error || paymentLinkError || " "}</Text>
      <View variant="sectionContainer">
        {renderPayButton()}
        <View style={{paddingTop: 10}}>
          <Text color={colors.GREEN_OFFICIAL} variant="formHint">
            {i18n.t('paymentScreen.subscriptionCancel')}
          </Text>
          <Text color={colors.GREEN_OFFICIAL} variant="formHint">
            {`${i18n.t('paymentScreen.nextPaymentDate')}: ${nextPaymentDate}`}
          </Text>
        </View>
      </View>
    </View>
  );
};
