 
import { takeEvery } from 'redux-saga/effects';
import { fetchSaga } from '../store/src/common';
import { 
    deleteGoalsTranslations,
    getGoalsTranslations,
    updateGoalsTranslations,
    uploadGoalsTranslations 
} from './goalsTranslations.actions';
import { GoalsTranslationsService } from './goalsTranslations.api';

export function* goalsTranslationsFlow() {
    yield takeEvery(updateGoalsTranslations.toString?.(), fetchSaga(GoalsTranslationsService.putUpdateGoalsTranslations));
    yield takeEvery(uploadGoalsTranslations.toString(), fetchSaga(GoalsTranslationsService.postUploadGoalsTranslations));
    yield takeEvery(getGoalsTranslations.toString?.(), fetchSaga(GoalsTranslationsService.getGoalsTranslations));
    yield takeEvery(deleteGoalsTranslations.toString?.(), fetchSaga(GoalsTranslationsService.deleteGoalsTranslations));
}
  


