import {Row, View, Text} from 'components';
import React from 'react';
import {i18n} from 'localize';
import { ICurrency } from '../../../payment/payments.types';
import {FormattedNumber} from 'react-intl'

type TProps = {
  nbSponsors: number;
  monthlyEarnings?: {
    amount: number;
    currency: ICurrency;
  };
};

export const CreatorPublicStatistics: React.FC<TProps> = ({
  nbSponsors,
  monthlyEarnings,
}) => {
  //todo: add locales, add method for number formatting to use on different platforms
  const formatedEarningAmount =  monthlyEarnings?.amount ? monthlyEarnings?.amount.toLocaleString('uk-UA') : null;

  return (
    <>
      {nbSponsors > -1 && (
        <Row containerStyle={{justifyContent: 'center', width: '100%'}}>
          <View style={{paddingHorizontal: 10}}>
            <Text center variant="secondaryTitle">{`${i18n.t(
              'creatorPage.nbsponsorsTitle',
            )}`}</Text>
            <Text center variant="description">
              {`${nbSponsors}`}
            </Text>
          </View>
          {!!formatedEarningAmount && (
            <View style={{paddingHorizontal: 10}}>
              <Text center variant="secondaryTitle">{`${i18n.t(
                'creatorPage.monthlyPaidAmountTitle',
              )}`}</Text>
              <Text
                center
                variant="description"
              >{`${formatedEarningAmount} ${monthlyEarnings?.currency?.name}`}</Text>
            </View>
          )}
        </Row>
      )}
    </>
  );
};
