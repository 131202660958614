 
import { takeEvery } from 'redux-saga/effects';
import { fetchSaga } from '../store/src/common';
import { 
    deletePostTranslation, 
    // getPostTranslations, 
    updatePostTranslation, 
    uploadPostTranslation 
} from './posts-translations.actions';
import { PoststranslationsService } from './posts-translations.api';

export function* postsTranslationsFlow() {
    yield takeEvery(updatePostTranslation.toString?.(), fetchSaga(PoststranslationsService.putUpdatePostsTranslations));
    yield takeEvery(uploadPostTranslation.toString(), fetchSaga(PoststranslationsService.postUploadPostsTranslations));
    // yield takeEvery(getPostTranslations.toString?.(), fetchSaga(PoststranslationsService.getPostsTranslations));
    yield takeEvery(deletePostTranslation.toString?.(), fetchSaga(PoststranslationsService.deletePostsTranslations));
}
  

