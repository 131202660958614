import React, {useCallback, useState} from 'react';
import {Platform, StyleSheet, ViewStyle} from 'react-native';
import {TouchableOpacity} from 'react-native';
import {Clipboard} from '../../../utils/Clipboard/Clipboard';
import {Icon} from '../Icon/Icon';
import {Row} from '../Row';
import {Collapsible, View} from 'components';
import { decodeHTMLEntities } from 'utils';
import {alertMessage, getTierShareLink, isDesktop} from 'utils';
import {TTier} from 'types';
import {Image} from '../Image/Image';
import {Text} from '../Text';
import {i18n} from 'localize';
import {RenderHtml} from '../RenderHtml';
import {AnalyticsService} from 'services';
import {EAnalyticsEventNames} from 'types';
import {DEVICE_WIDTH, TIER_WIDTH} from '../../../constants';
import { toUpper } from 'lodash';
import { onSharePress } from '../../../share/onSharePress';
import { useMedia } from '../../../hooks/useMedia';

type TProps = {
  first?: boolean;
  showEdit?: boolean;
  containerStyle?: ViewStyle;
  btnColor?: string;
};

// const TierDescriptionMaxLength = 250;
const MAX_TEXT_HEIGHT = 250;

export const TierBase: React.FC<TTier & TProps> = ({
  id,
  creatorPageURL,
  title,
  price,
  imageUri,
  currency,
  description,
  first,
  btnColor,
  children,
  containerStyle,
  ...otherProps
}) => {

  const media = useMedia()

  const copyShareLink = useCallback(async () => {
    const link = getTierShareLink(creatorPageURL, id);
    await onSharePress({text: link})
    AnalyticsService.logEvent(EAnalyticsEventNames.TIER_LINK_COPIED);
  }, [creatorPageURL, id]);

  // const [descriptionHeight, setDescriptionHeight] = useState(null);
  const [showFullDescription] = useState(false);
  // const [height, setHeight] = useState(null);

  // const setBlockHeight = ({nativeEvent: {layout}}) => {
  //   setHeight(layout.height)
  // }
  
  return (
    <View
      bordered
      style={[
        styles.container, 
        first && { borderTopWidth: 0.5 }, 
        // height && {maxHeight: height},
        containerStyle
      ]}
      // onLayout={setBlockHeight}
      {...otherProps}
    >
      <Row
        containerStyle={{
          justifyContent: 'flex-end',
          width: '100%',
          maxWidth: '100%',
          paddingVertical: 5,
        }}
      >
        {/* {showEdit && <TouchableOpacity onPress={() => {})}>
            <Icon style={styles.icon} name="edit" size={15} />
        </TouchableOpacity>} */}
        <TouchableOpacity hitSlop={{bottom: 5, left: 5, right: 5}} onPress={copyShareLink}>
          <Icon color={btnColor} style={styles.icon} name="share" size={15} />
        </TouchableOpacity>
      </Row>
      <View style={styles.contentContainer}>
          {title && (
            <Text numberOfLines={2} center variant="tierTitle">
              {title}
            </Text>
          )}
          <Text style={[styles.text]}>{`${price} ${toUpper(currency?.name)} ${i18n.t('supportIntervalTitle')}`}</Text>
          {Boolean(imageUri) === true && (
            <Image variant="tierImage" source={{uri: imageUri}} />
          )}
       </View>

      <View
        style={[
          styles.descContainer,
          !showFullDescription
            ? {
                height: 'auto',
              }
            : {
                overflow: 'hidden',
                height: MAX_TEXT_HEIGHT,
              },
        ]}
      >
        {description && <Collapsible
            showHideButton
            btnColor={btnColor}
            collapsedHeight={150}
          >
             <RenderHtml
                contentWidth={'100%'}
                textStyle={{fontSize: 20}}
                source={decodeHTMLEntities(description || '')}
              />
          </Collapsible>}
      </View>
      <View style={{flex:1, width: '100%'}}>
        {children}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    borderRadius: 8,
    borderWidth: 1,
    width: isDesktop() ? TIER_WIDTH * 2 : DEVICE_WIDTH > TIER_WIDTH ? TIER_WIDTH - 30 : DEVICE_WIDTH - 30, //TODO: padding incapsulate
    maxWidth: Platform.OS == 'web' 
      ? DEVICE_WIDTH 
      : isDesktop()
        ? TIER_WIDTH * 2
        :'100%',
    padding: 15,
    // marginBottom: 7,
    borderColor: 'black',
    backgroundColor: 'white',
  },
  // move to text custom component
  text: {
    paddingVertical: 8,
    fontSize: 20,
  },
  icon: {
    // paddingHorizontal: 10
  },
  descContainer: {
    justifyContent: 'flex-start',
    paddingVertical: 15,
  },
  contentContainer: {alignItems: 'center'}
});
