export enum EPaymentPeriod {
  'oneMonth' = 'oneMonth',
  'threeMonths' = 'threeMonths',
  'sixMonths' = 'sixMonths',
  'nineMonths' = 'nineMonths',
  'twelveMonths' = 'twelveMonth',
}

export type TLiqpayData = {
  signature: string;
  data: string;
};

export enum ECurrencies {
  USD = 'USD',
  EUR = 'EUR',
  RUB = 'RUB',
  UAH = 'UAH',
}

export type TBillingReceipt = {
  creator: {
    title: string;
    imageUrl?: string;
  };
  tier: {
    price: number;
  };
  // TODO: add literal type
  paymentStatus: string;
};

export type TPayout = {
  createdAt: string;
  withdrawAmount: number;
  receiver: string;
  creator: string;
};
