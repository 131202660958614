import {isEmpty, map} from 'lodash';
import {EscreenNames} from 'navigation';
import React from 'react';
import {ScrollView} from 'react-native';
import {useNavigation} from 'hooks';
import {i18n} from 'localize';
import {TTier} from 'types';
import {Button} from '../Button/Button';
import {Text} from '../Text';
import {TierBase} from './TierBase';
import {CreatorCardMinimized, View} from '..';
import {TTierComponentProps} from './Tier.types';

export const Tier: React.FC<TTier & TTierComponentProps> = ({
  id,
  creator,
  coCreators,
  containerStyle,
  btnColor,
  ...otherProps
}) => {
  const {navigate} = useNavigation();

  //TODO: create component and containers
  return (
    <TierBase
      containerStyle={containerStyle}
      coCreators={coCreators}
      id={id}
      btnColor={btnColor}
      creator={creator}
      {...otherProps}
    >
      <View style={{width: '100%'}}>      
        {!isEmpty(coCreators) && (
          <View style={{width: '100%'}}>
            <Text variant="secondaryTitle"> {i18n.t('coCreators')}</Text>
            <ScrollView
              pagingEnabled
              contentContainerStyle={{paddingBottom: 15}}
              horizontal
              showsHorizontalScrollIndicator
            >
              {map(coCreators, c => (
                <View key={c?.creatorPageURL} style={{marginLeft: 10}}>
                  <CreatorCardMinimized
                    onPress={() =>
                      navigate(
                        EscreenNames.CreatorPublicPage,
                        {pageURL: c?.creatorPageURL},
                        {
                          newTab: true,
                          urlParam: true,
                        },
                      )
                    }
                    {...c}
                  />
                </View>
              ))}
            </ScrollView>
          </View>
        )}
        <Button
          primary
          testID='tier-buy-button'
          onPress={() =>
            navigate(EscreenNames.TierBuy, {
              tierId: id,
            })
          }
          color={btnColor}
          containerStyle={{width: '100%', marginVertical: 15, alignSelf: 'center'}}
          title={i18n.t('tier.buySubscription')}
        />
      </View>
    </TierBase>
  );
};
