// import Analytics from 'Analytics';
// import googleAnalytics from '@Analytics/google-Analytics';
import {firebase} from '../../libs/index';

import {EAnalyticsEventNames} from '../../types/src/analytics';
import {LOG} from '../../utils/LOG';

class _AnalyticsService {
  // TODO: add restrictions to params fields (count, types)
  constructor() {
    firebase.init();
  }

  logEvent = async (eventName: EAnalyticsEventNames, params?: any) => {
    try {
      await firebase.analytics().logEvent(eventName, params);
    } catch (err) {
      LOG(err);
    }
  };

  async logScreenVisit(screenName: string, params?: any): Promise<void> {
    if (!screenName) {
      return Promise.resolve();
    }
    try {
      await firebase.analytics().logEvent('screen_visit', {
        screen_class: params?.screen_class || screenName,
        screen_name: screenName,
      });
    } catch (err) {
      LOG(err);
    }
  }

  async setUserId(id: string): Promise<void> {
    try {
      await firebase.analytics().setUserId(id);
    } catch (err) {
      LOG(err);
    }
  }

  async removeUserId(): Promise<void> {
    try {
      await firebase.analytics().setUserId(null);
    } catch (err) {
      LOG(err);
    }
  }

  async setUserProperty(name: string, value: string | null): Promise<void> {
    try {
      await firebase.analytics().setUserProperty(name, value);
    } catch (err) {
      LOG(err);
    }
  }
}

export const AnalyticsService = new _AnalyticsService();
