import React from 'react';
import {View, StyleSheet} from 'react-native';
import {TouchableOpacity} from 'react-native';
import {noop} from 'lodash';
import {Icon} from '../Icon/Icon';

type TProps = {
  onDelete?: () => void;
  children?: any;
};

export const DeleteHelper: React.FC<TProps> = ({onDelete = noop, children}) => {
  return (
    <View>
      <TouchableOpacity style={styles.btnContainer} onPress={onDelete}>
        <Icon size={15} name="cancel" />
      </TouchableOpacity>
      <View style={{marginTop: 25}}>{children}</View>
    </View>
  );
};

const styles = StyleSheet.create({
  btnContainer: {
    right: -5,
    position: 'absolute',
    top: 5,
    // padding: 15,
  },
});
