import React, {ReactChild} from 'react';
import {ColorValue, View, ViewStyle} from 'react-native';
import {TouchableOpacity} from 'react-native';
import {Text} from '../Text/Text';
import s from './styles';
import Loader from '../Loader/Loader';

export type TButtonProps = {
  buttonStyle?: any;
  children?: any;
  small?: boolean; // width could be anything
  primary?: boolean;
  cancell?: boolean;
  containerStyle?: ViewStyle;
  disabled?: boolean;
  onPress: () => void;
  isLoading?: boolean;
  title: string;
  titleStyle?: any;
  color?: ColorValue;
  inRowButton?: boolean;
  red?: boolean;
  rightElement?: ReactChild;
  testID?: string;
  onDisabledPress?: null | (() => void);
  accessibilityLabel?: string;
};

export const Button: React.FC<TButtonProps> = ({
  children,
  primary,
  containerStyle,
  disabled,
  onPress,
  isLoading,
  title,
  buttonStyle,
  titleStyle = {},
  red,
  color,
  inRowButton = false,
  onDisabledPress,
  rightElement,
  testID,
  accessibilityLabel,
  small,
  bold = true,
  ...props
}) => {
  return (
    <View
      // testID={testID}
      style={[
        s.container,
        small && s.smallBtn,
        inRowButton && s.marginHorizontal,
        containerStyle,
      ]}>
      <TouchableOpacity
        {...props}
        activeOpacity={0.3}
        accessible={true}
        testID={testID}
        // data-testid={testID}
        accessibilityLabel={accessibilityLabel}
        disabled={!onDisabledPress && disabled}
        onPress={!!disabled && !!onDisabledPress ? onDisabledPress : onPress}>
        <View
          style={[
            s.button,
            s.view,
            primary && s.primaryView,
            red && s.red,
            disabled && s.disable,
            buttonStyle,
            color && {
              backgroundColor: color,
            },
          ]}>
          {!isLoading ? (
            <View style={s.contentContainer}>
              {!!children && <View>{children}</View>}
              <View style={{flex: 3, justifyContent: 'center'}}>
                <Text
                  // bold
                  // mediumSize
                  numberOfLines={1}
                  selectable={false}
                  white={primary || red}
                  bold={bold}
                  // gray={!primary}
                  style={[s.text, titleStyle]}>
                  {title}
                </Text>
              </View>
              {rightElement}
            </View>
          ) : (
            <Loader
            // color={!primary ? colors.loader.secondary : colors.loader.primary}
            />
          )}
        </View>
      </TouchableOpacity>
    </View>
  );
};
