import { ESubscriptionStatus } from "../../types/src/subscriptions";

/* eslint-disable max-len */
export default {
  locale: 'en-US',
  name: "Ім'я",
  surname: 'Прізвище',
  delete: 'Видалити',
  accept: 'Прийняти',
  decline: 'Відхилити',

  cancelButtonTitle: "Відмінити",
  language: "Мова",
  pricingPlan: "Тарифний план",

  translations: 'Переклади',
  addTranslation: "Додати переклад",
  soon: 'Незабаром',
  edit: 'Редагувати',
  updatedAt: "Оновлено",
  share: 'Поділитися',
  view: 'Подивитися',
  operationSuccess: 'Операція пройшла успішно',
  supportCreator: 'Підтримати',
  linkCopied: 'Посилання скопійовано',
  buySubscriptionBtnTitle: 'Оформити підписку',
  saveSettings: 'Зберегти дані',
  pickPhoto: 'Вибрати фото',
  logout: 'Вийти з акаунта',
  unpublish: 'Приховати',
  publish: 'Опублікувати',
  login: 'Увійти',
  menu: "Меню",
  createAccount: 'Приєднатися',
  register: 'Зареєструватися',
  submitEdit: 'Надіслати зміни',
  isPublished: 'Опубліковано',
  isDeclined: 'Відхилено',
  isNotPublished: 'Не опубліковано',
  twitch: 'Twitch',
  reddit: 'Reddit',
  telegram: 'Telegram',
  youtube: 'Youtube',
  twitter: 'Twitter',
  follow: 'Стежити',
  unfollow: 'Не стежити',
  seeMore: 'Подивитися більше',
  hideText: 'Приховати текст',
  coCreators: 'Співавтори',
  friends: 'Друзі',
  imageSizeLimit: 'Ліміт 5 мегабайт, .png, .jpg',
  noArticles: 'Автор не має поки опублікованих постів',

  chooseFilter: "Виберіть фільтр",
  btns: {
    titles: {
        repeat: "Повторити"
    }
  },
  languages: {
    restartAppAlertTitle: "Зміна мови",
    restartAppAlertMessage: "Потрібний перезапуск застосунку",
  },

  chooseLanguagePickerLabel: "Виберіть мову:",
  listEmptyTitle: 'Список пустий',
  unsavedChangesWarning: 'Ви маєте незбереженні дані, бажаєте продовжити ?',
  socials: {
    wiki: 'Wiki',
  },
  post: "Пост",
  month: {
    '1': 'Cічень',
    '2': 'Лютий',
    '3': 'Березень',
    '4': 'Квітень',
    '5': 'Травень',
    '6': 'Червень',
    '7': 'Липень',
    '8': 'Серпень',
    '9': 'Вересень',
    '10': 'Жовтий',
    '11': 'Листопад',
    '12': 'Грудень',
  },
  currencies: {
    uah: 'UAH',
  },
  loginScreen: {
    dontHaveAccount: 'Не маєте акаунту',
    forgotPassword: 'Забули пароль?',
    passwordPlaceholder: 'Сюди впишіть свій пароль',
    passwordLabel: 'Пароль',
    emailLabel: 'Email',
    emailPlaceholder: 'Сюди запишіть свою електронну пошту',
  },
  dialogs: {
    deletionConfirmationDialogTitle: "Операція видалення",
    confirmationDialogTitle: "Ви впевнені ?",
    cancelBtnTitle: "Відмінити",
  },
  supportIntervalTitle: "кожного місяця",
  accountDeletionScreen: {
    screenTitle: "Видалення акаунту",
  },
  signupScreen: {
    haveAccount: 'Уже маєте акаунт?',
    forgotPassword: 'Забули пароль?',
    passwordPlaceholder: "8 символів мінімум",

    namePlaceholder: "Ваше ім'я",
    lastnamePlaceholder: "Ваше прізвище",

    passwordLabel: 'Пароль',
    emailPlaceholder: "Напишіть свою електронну пошту",
    emailLabel: 'Електронна пошта',
    confirmEmailLabel: 'Підтведження електронної пошти',
    confirmEmailPlaceholder: "Електронна пошта важлива",
    signupTitle: 'Зареєструватися',
    aggreement:
      "Я погоджуюся зі збором та обробкою персональних даних згідно закону Закону України 'Про Захист персональних даних' та підтверджую, що не розміщуватиму інформацію з обмеженим доступом та будь-яку іншу інформацію чи дані окрім публічних",
  },
  forgotPassword: {
    haveAccount: 'Уже маєте акаунт?',
    resetPassword: 'Скинути пароль',
    forgotPassword: 'Забули пароль?',
    resetEmailSendAlertMessage:
      'Електронний лист з посиланням для скидання паролю надіслано',
  },
  resetPassword: {
    haveAccount: 'Уже маєте акаунт?',
    resetPasswordTitle: 'Скинути пароль',
    newPassword: 'Новий пароль',
    resetPassword: 'Скинути пароль',
    successfullyReset: 'Пароль успішно скинуто',
    passwordConfirmation: 'Підтвердження паролю',
  },
  bottomTab: {
    creatorTabLabel: 'Творець',
    searchTabLabel: 'Пошук',
    profileTabLabel: 'Профіль',
  },
  landing: {
    donationForUkraine: '10% прибутку - на допомогу Україні',
    raiseFunds:
      'Sponsor.financial - проект українських розробників для збору коштів на свої проекти за допомогою місячних передплат',
    supportUkraine: 'Команда з України',
    supportUkraineBtnTitle: 'Support Ukraine',
    postUniqueContent:
      'Публікуйте унікальний контент тільки для своїх передплатників',
    paymentsOnBankAccount: 'Виплати на картки фіз.осіб та рахунки ФОП',
    createPaidSubscriptionBtn: 'Створити платну підписку',

    buildTransparentRelationship: 'Будуйте прозорі відносини з аудиторією',
    withoutAlgorithms: 'без втручання алгоритмів, ботів і тролів',
    onlyYouAreModerator:
      'тільки ви визначаєте, що побачать ваші підписники і хто саме',

    createCollaboration: 'Створюйте спільні підписки з іншими творцями',
    receiveEqualPayments: 'і отримуйте рівні виплати',
    opportunityToCollaborate:
      'Можливість публічно співпрацювати з Вашими партнерами',

    shareSubscription: 'ПОДІЛІТЬСЯ ПІДПИСКОЮ З ВАШОЮ АУДИТОРІЄЮ',
    shareYouAreOnSponsorFinancial:
      'Розкажіть своїм передплатникам, що ви є на Sponsor.financial',

    joinUs: 'Приєднатися',
    pricingPlans: {
      sectionTitle: "Тарифні плани",
    },
    features: {
      basicPricingPlanTitle: "Базовий",
      translations: "Переклади сторінки, постів, цілей ...",
      collaborations: "Співпраця з іншими авторами",
      intantPayout: "Миттєві виплати на картку",
      fees: "8.5% комісія + комісії платіжної системи \nза всі функції",
      analytics: "Аналітика",
      mobileApp: "Керування з мобільного застосунку",
      offlineSupport: "Офлайн доступ до даних",
      oneClickPayment: "Оплата без реєстрації",
      paymentWays: "Оплата кредитною карткою, Apple Pay, \nGoogle Pay",
      publicPrivatePosts: "Пости публічні та приватні",
      officialVerification: "Офіційна верифікація акаунту",
      militarySupport: "Підтримка військових",
    },
  
  },
  activeSubscriptions: {
    tabTitle: 'Активні',
    noSubscriptions:
      'Ви поки ні на кого не підписані. Поцікавтеся у знайомих вам авторів про сторінку на Sponsor.financial',
  },
  deletedButActiveSubscriptions: {
    tabTitle: 'Скасовані підписки',
  },
  errors: {
    somethingWrong: 'Щось пішло не так',
    alreadyExists: 'Подібні дані уже існують',
    tooManyRequests: 'Забагато запитів. Спробуйте пізніше',
    invalidCredantials: 'Невірні дані',
    pageUrlUnique: 'Посилання на сторінку повинно бути унікальним',
    emailIsTaken: 'Електронна пошта уже зайнята',
    imageSizeInvalid:
    'Розміри зображення повинні бути більшими за %{minWidth} * %{minHeight} та меншими, ніж %{maxWidth} * %{maxHeight}. Розміри вибрані - %{width} * ${height}',
    profileImageRequired:
      'Виберіть зображення профілю  з розширенням більше ніж 200*200 та менше ніж 2000*2000',
    backgroundImageRequired:
      'Виберіть головне зображення з розширенням більше ніж 500*300 та менше ніж 5000*5000.',
    avatarImageInvalid:
      'Зображення профілю повинно бути з розширенням більше ніж 200*200 та менше ніж 2000*2000. Вибачте за тимчасові незручності ',
    backgroundImageInvalid:
      'Головне зображення повинно бути з розширенням більше ніж 500*300 та менше ніж 5000*5000. Вибачте за тимчасові незручності',
    // todo: check if next messaage is secure
    emailNotFound: 'Електронна пошта не знайдена',
    userNotFound: 'Користувач не знайдений',

    limitReachContactAdmin:
      'Ви досягли ліміту. Напишіть нам на електронну пошту',

    itemIsMissed: "Ми не знайшли %{itemName}",

  },
  header: {
    updateImage: 'Обновити обкладинку',
  },
  myRevenuePage: {
    screenTitle: "Баланс",
    monthlyEarnings: 'Щомісячний заробіток',
    withdrawBalance: 'Зняти кошти',
    yourPayouts: 'Ваші виплати',
    paymentMethodChange: 'Змінити карту для зарахування',
    noPayouts: 'Виплати відсутні',
    receiver: 'Отримувач',
    amount: 'Сума',
    dateOfWithdraw: 'Дата списання',

    currencyTitle: 'Валюта',
    balanceAvailableTableTitle: 'Доступно',

    withdrawContinueBtnTitle: 'Продовжити',

    creatorBalancesTitle: 'Доступні кошти',
  },
  calcelSubscriptionScreen: {
    screenTitle: 'Скасування підписки',
  },
  // todo: screen or page
  paymentScreen: {
    screenTitle: 'Покупка',
    subscriptionNotFound: 'Дані про підписку не знайдено. Спробуйте пізніше',
    // payForPeriodButton: 'Продовжувати підписку',
    // payForPeriod: 'Продовжувати передплату',
    paymentTitle: 'Завершення оформлення щомісячної підписки для автора:',
    subscriptionAlreadyBought: 'Схоже ви вже купили підписку',
    oneMonth: '1 місяць',
    threeMonth: '3 місяці',
    sixMonth: '6 місяців',
    twelveMonth: '12 місяців',
    buy: 'Купити',
    subscriptionCancel:
      'Ви можете в будь-який момент скасувати підписку на сайті',
    nextPaymentDate: 'Наступне списання',
    cancelSubscription: 'Скасувати підписку',
    writeCustomPrice: 'Введіть свою ціну, якщо бажаєте',
    emailPlaceholder: "Обов'язково - your email",

    writeUserEmailPlaceholder: 'Напишіть вашу пошту',
    refundPolicyMessage: 'Ми не повертаємо кошти від імені авторів.',

    chooseYourPaymentMethod: 'Виберіть метод оплати',
    bankPaymentMethod: 'Банківська карта',
    cryptoPaymentMethod: 'Криптовалюти',
  },
  cancelSubscriptionScreen: {
    monthAccessAlertMessage: "Ви маєте доступ до приватних публікацій до дати завершення підписки.",
  },
  subscriptionCancelingByToken: {
    invalidTokenWarningMessage: 'Перевірте посилання та спробуйте ще раз',
    successMessage: 'Підписка була видалена',
  },
  search: {
    search: 'Пошук',
    searchInputPlaceholder: "Напишіть заголовок, частину опису або посилання на автора",
    noResults: 'За вашим запитом не знайдено творців',
  },
  // todo: change naming
  creatorCard: {
    nbSponsors: 'Спонсорів',
    writeTitle: 'Як називається ваш проект ?',
    writeDesc: 'Додайте опис вашої діяльності',

    supportIntervalTitle: "щомісяця",
  },
  creatorPosts: {
    screenTitle: "Пости автора",
  },
  creatorPage: {
    creatorPageTitle: 'Сторінка автора',

    creatorNotFound: 'Користувач не знайдений',
    chooseSubscription: 'Виберіть підписку',
    seeAllTiersButtonTitle: "Відкрити у новій вкладці",

    aboutAuthor: 'Про автора',
    lastPosts: 'Останні пости',
    collaborations: 'Співпраця',
    benefitsTitles: 'Ставши спонсором, ви миттєво розблокуєте доступ до',
    exclusivePosts: 'ексклюзивних дописів',
    allExclusivePosts: 'Всього',

    socials: 'Соціальні мережі',

    showAllTiers: 'Показати всі підписки',
    hideTiers: 'Приховати підписки',
    nbsponsorsTitle: 'Спонсори:',
    monthlyPaidAmountTitle: 'В місяць:',

    createCommunityTierTitle:
      'Не знайшли зручну для Вас підписку ? Створіть власну !',
    createYourOwnGoalTitle:
      'Не знайшли цілі, яка вам  до вподоби? Запропонуйте свою !',
  },
  profileDropdown: {
    myPage: 'Моя сторінка',
    createPage: 'Створити сторінку',
    editMyPage: 'Редагувати сторінку',
    inviteCreator: 'Запросити на платформу',
    searchCreators: 'Пошук творців',
    postsFromCreators: 'Пости від творців',
    mySubscriptions: 'Мої підписки',
    myRevenue: 'Мої доходи',
    profileSettings: 'Налаштування профілю',
    subscribers: 'Мої підписники',
  },
  editCreatorCustomizationScreen: {
    screenTitle: 'Кастомізація',
  },
  editCreatorTranslationsScreen: {
    screenTitle: 'Переклади сторінки',
  },
  editCreatorPage: {
    countryLabel: "Код країни",
    reloadPageLater: "Якщо дані не з'явилися, перезавантажте сторінку пізніше",
    editMenuTitle: 'Налаштування сторінки автора',
    preview: 'Переглянути',
    basicSettings: 'Основне',
    paymentSettings: 'Платежі',
    tierSettings: 'Підписки',
    lastMonthLabel: 'Минулий місяць',
    goalsSettingsButtonTitle: 'Цілі',
    friendsSettingsButtonTitle: 'Друзі',
    privacySettingButtonTitle: 'Приватність',
    customizatonSettingsButtonTitle: 'Кастомізація',
    translationsSettingsButtonTitle: 'Переклади основних відомостей',

    dragImageHere: 'Опустіть файли сюди ...',
    startDragging: 'Перетягніть сюди фото або клацніть, щоб вибрати',
    subscriptions: 'Рівні підтримки',
    addTier: 'Додати підписку',
    titleLabel: 'Назва профайлу',

    subtitleLabel: 'Підзаголовок',

    titleInputPlaceholder: "Обов'язково - короткий заголовок",
    subtitlePlaceholder: 'Почніть зі слів: створюю, поширюю, допомагаю ...',
    descriptionPlaceholder: "Обов'язково - напишіть чим ви займаєтеся",

    descriptionLabel: 'Опис діяльності',
    creatorProfileImageLabel: 'Фото автора',
    pageURLLabel: 'Частина посилання',
    pageURLSubLabel: "Унікальний вираз, який буде у повному посиланні на Вашу сторінку. Краще латиницею",
    pageURLPlaceholder: 'Унікальне посилання',
    videoURLLabel: 'Посилання на відео',
    backgroundImageLabel: 'Головне фото',
    posts: 'Пости',
    greetings: 'Привітання',
    drafts: 'Чернетки',
    socialsTitle: 'Соц. мережі',
    privacy: 'Приватність',
    addPaymentMethod: 'Додайте спосіб оплати',
    paymentMethods: 'Способи виводу грошей',

    paymentMethodBankCard: 'Банківська карта',
    paymentMethodCryptoWallet: 'Крипто гаманець',
    paymentMethodsMerchantsListTitle: 'Компанії',

    fiveMbLimit: 'Ліміт 5 мегабайт, .png, .jpg',
    publishCreatorPage: 'Опублікувати',
    thisOptionsAvaiableOnWeb: 'Ця опція доступна лише у веб-версії',
    tiersBlockedMessage: 'Додайте платіжний метод спочатку',

    brandColorButtonTitle: 'Колір бренду',
    creatorMainLanguageLabel: 'Головна мова',

    notVerifiedAccountMessage:
      'Ваш акаунт не верифіковано.\nВи можете користуватися акаунтом без обмежень\nНапишіть нам на пошту для верифікації: %{email}.',

    tiersTranslationsBtnTitle: 'Переклади підписок',
    adsPlatformAccess: "Пошук рекламодавців",

    newPostButtonTitle: "Новий пост",
    newTierButtonTitle: "Нова підписка",

    categoryInputLabel: "Категорія",

    scienceCategoryLabel: "Наука",
    zcuCategoryLabel: "Збройні сили України"
  },
  editCreatorSocials: {
    screenTitle: "Соціальні мережі",
  },
  richEditor: {
    turnOn: "Увімкнути текстовий редактор",
    tunOff: "Вимкнути текстовий редактор",
  },
  editCreatorPrivacy: {
    screenTitle: 'Приватність',
    showPubliclyEarnedAmount:
      'Показувати публічно, скільки ви заробляєте на місяць',
  },
  editCreatorGreetings: {
    screenTitle: 'Привітання',
    screenSubtitle: "Ми використаємо Ваші привітання у листах до Ваших підписників",
    showGreetings: 'Показувати привітання для нових підписників',
    showGeneralGreeting: 'Загальне',
    showTierSeparateGreeting: 'Унікальне для кожного типу підписки',
  },
  editTiers: {
    yoursTiers: 'Ваші підписки',
    yourCollaborationTiers: 'Ваші спільні роботи',

    publishedTypeLabel: 'Опубліковані',
    waitingAcceptanceTypeLabel: 'Очікують підтвердження',
    declinedAcceptanceTypeLabel: 'Відхилено',
    expiredTypeLabel: 'Ліміт часу вичерпано',
    cancelledTypeLabel: 'Відміненні',

    userHasWaitingResponseCollaborations:
      'Ви маєте колаборації, що чекають вашої відповіді',
  },
  editCreatorFriends: {
    screenTitle: "Друзі",
    friendsTitle: 'Друзі',
    friendInputPlaceholder: 'Шукайте творців, щоб додати їх в друзі',
  },
  subscribers: {
    subscribersScreenHeader: 'Підписники',
    subscribersPerTier: 'Кількість підписників по рівнях підписки',
    subscriberList: 'Список підписників',
    noSubscribers:
      'Інформація відсутня. Поділіться з вашою аудиторіює посиланням на Ваш профіль',
    exportSubscribers: 'Експорт в csv',
    lastExportAt: 'Останнє завантаження:',
    nextExportAt: 'Наступне завантаження:',
    subscribersNameLabel: "Ім'я",
    subscribersStatusLabel: 'Статус',
    subscribersPriceLabel: 'Ціна',
    subscribersStartDateLabel: 'Дата підписки',

    chartScrollAvilability: 'Графік можна прокручувати',
    exportSuccessMessage: "Перевірте Вашу електронну пошту",
    exportSuccessTitle: "Успішний експорт",

    statuses: {
      [ESubscriptionStatus.subscribed]: 'активна',
      [ESubscriptionStatus.unsubscribed]: 'скасовано',
    },

    lastSubscribersSectionTitle: "Останні підписники",

  },
  unathorizedSubscription: {
    successMessage:
      'Лист з посилання для оплати був надісланий. Перевірте вашу електронну пошту',
  },
  tier: {
    buySubscription: 'Оформити підписку',
  },
  tierEdit: {
    tierDescription: 'Опис підписки',
    tierDescriptionInputPlaceholder: 'Додайте опис',
    tierTitleInputLabel: 'Заголовок підписки',
    tierTitleInputPlaceholder: 'Додайте заголовок',

    collabEditableByCreatorWarning: "Лише початковий автор рівня підписки може змінювати всі поля",

    tierCurrencyInputLabel: "Валюта",
    tierPrice: 'Вартість щомісячної підписки',
    tierPriceInputPlaceholder: 'Скільки на твою думку це вартує?',
    subscribersLimit: 'Обмеження кількості підписників',
    addTier: 'Додати підписку',
    imageRequired: 'Додайте фото',
    collaborationTitle: 'Співпраця',
    creatorsInputPlaceholder: 'Знайдіть партнерів для співпраці',
    creatorsNotFound: 'Творців не знайдено',
    
    creatorSearchResult: 'Результати пошуку:',
    selectedCoCreators: 'Вибрані співавтори:',

    addTranslationBtnTitle: 'Додати переклад',
  },
  postScreen: {
    screenTitle: 'Публікація',
  },
  editTierTranslationScreen: {
    screenTitle: 'Переклади підписки',
  },
  editPost: {
    title: 'Заголовок',
    titleInputPlaceholder: 'Місце для заголовку ...',
    description: 'Основний текст',
    descriptionPlaceholder: 'Розкажіть щось своїм підписникам',
    videoLink: 'Відео',
    videoLinkSubtitle: "Відео замінить фото",
    videoLinkPlaceholder: 'Тут місце для посилання на відео',
    submitNew: 'Створити пост',
    saveDraft: 'Зберегти чернету',
    publicPost: 'Публічний пост',
    postForSubscriber: 'Для підписників',
    tizerTitle: 'Тизер посту',
    createTierFirst: 'Спочатку додайте платну підписку',
    chooseTier: 'Виберіть підписку',
    tizerTitlePlaceholder:
      'Напишіть короткий опис для людей, які ще не купили підписку',
  },
  editPosts: {
    newPost: 'Новий пост',
    publishedPosts: 'Опубліковані пости',
    drafts: 'Неопубліковані пости',
    noPosts: 'Статті відсутні. Додайте свій перший пост',
  },
  editCreatorGoals: {
    screenTitle: "Ваші цілі",
    pageTitle: 'Ваші цілі:',
    goalHiddenText: 'Ваші цілі приховані',
    newGoalButtonTitle: 'Нова ціль',
    emptyGoalListTitle: 'Додайте свою першу ціль',
    goalForSubscribersLabel: 'Підписники:',
    goalForEarningsLabel: 'Збори:',

    subscribersGoalTypeLabel: 'Підписники',
    earningsGoalTypeLabel: 'Заробіток',
    noneGoalTypeLabel: 'Приховати',
    showGoalSettingTitle: 'Показувати цілі:',
    noGoalsListTitle:
      'Тут поки пусто :(. \nСпробуйте змінити фільтри або створити нову ціль',
  },
  editCreatorGoalScreen: {
    screenTitle: 'Ціль',
    goalTitleLabel: 'Заголовок цілі',
    titleInputPlaceholder: 'Напишіть коротку назву цілі',
    goalDescriptionLabel: 'Опис цілі',
    goalDescriptionInputPlaceholder: 'Опишіть чому ви вибрали саме цю ціль',
    goalCountForEarningLabel: 'Сума, яку потрібно зібрати',
    goalCountForSubscribersLabel: 'Кількість підписників для досягнення цілі',
    addGoalButtonTitle: 'Додати нову ціль',

    subscribersGoalTypeLabel: 'Підписники',
    earningsGoalTypeLabel: 'Заробіток',
  },
  creatorGoals: {
    goalListTitle: 'Цілі',
    prevButtonTitle: 'Попередня',
    nextButtonTitle: 'Наступна',
    subscribersGoalTypeLabel: 'Підписники',
    earningsGoalTypeLabel: 'Заробіток',
  },
  validationSchema: {
    tooShort: 'Додайте більше тексту',
    tooLong: 'Скоротіть текст',
    requiredField: "Це поле обов'язкове",
    invalidURL: 'Неправильне посилання',
    invalidEmail: 'Некоректна електронна пошта',
    minOneLetter: 'Мінімум одна літера',
    minOneDigit: 'Мінімум одна цифра',
    confirmEmailIsNotCorrect: 'Емейли не співпадають',
    confirmPasswordsNotCorrect: 'Паролі не співпадають',
    trim: 'Видаліть непотрібні пробіли на початку та в кінці',
    minimalPrice: 'Ціна повинна бути вищою, ніж мінімальна',

    validURL: 'Посилання некоректне',
    minTierPrice: 'Ціна занадто мала',
    positiveNumber: "Число більше за нуль",
    numberRequired: 'Дозволені лише цифри',

    phoneNumberInvalid: 'Номер телефону неправильний',
    colorIsInvalid: "Колір неправильний",
  },
  withdrawMethods: {
    screenTitle: "Платежі",
    liqpayDescription: 'Авторизація карти',
    paymentDescription: 'Передавторизація карти',

    checkboxAllowCryptoPaymentTitle: "Дозволити оплату в криптовалюті",
    paymentSettingTitle: "Налаштування сторінки оплати",
  },
  payoutsPage: {
    accountBalance: 'Доступні кошти',
    withdrawBtnTitle: 'Вивести кошти',
  },
  subscriptionPage: {
    screenTitle: "Мої підписки",
    subscriptionsSettings: 'Налаштування підписок',
  },
  postsScreen: {
    findCreatorsBtnTitle: 'Знайти творців',
    noPosts:
      'Підтримайте фінансово або почність відстежувати творців, щоб побачити їх пости',
    supportingListTitle: 'Підтримую фінансово',
    followingListTitle: 'Відстежую оновлення',
    seeAllComments: 'Завантажити більше коментарів',
    writeYourComment: 'Напишіть коментар ...',
    downloadMorePosts: 'Дивитися наступні',
    posts: 'Пости',
    creators: 'Творці',

    retryDownloadPosts: "Оновити",
    seeAllCommentsBtnTitle: "Переглянути всі коменти",
    subscirbersOnlyCommentMessage: "Лише підписники можуть залишати коментарі",
  },
  postCard: {
    addEmodji: 'Додати смайл',
    toUnlockSubscribe: 'Щоб розблокувати цей пост станьте підписником',
  },
  contentTypes: {
    video: 'Відео',
    text: 'Стаття',
    link: 'Посилання',
    poll: 'Опитування',
  },
  editCustomizationScreen: {
    brandColorButtonSubTitle: "Це вплине на колір кнопок, іконок та деяких інших частин дизайну на Вашій головній сторінці автора"
  },  
  profileSettings: {
    profileSettingHeader: 'Налаштування профілю',
    nameInputLabel: "Ім'я",
    emailLabel: 'Електронна пошта',
    lastNameInputLabel: 'Прізвище',
    profileImageLabel: "Фото профілю",
  },
  userPrivacyScreen: {
    screenTitle: "Безпека",
    enableBiometryCheckboxTitle: "Увімкнути аутентифікацію за допомогою біометрії ( сканер відбитка пальців, тощо )", 
  },
  viewedPostsScreen: {
    screenTitle: "Переглянуті пости",
    emptyListTitle: "Не знайшли пости, які Ви переглядали",
  },
  profile: {
    privatepolicy: 'Політика конфеденційності',
    language: 'English',
    unlocktext: 'Щоб розблокувати весь функціонал, будь ласка:',
    logintext: 'Увійдіть/зареєструйтеся',
    longpress: 'Щоб змінити мову утримуйте кнопку',
    profile: ' Профіль',
    help: 'Допомога',
    subscriptions: 'Підписки',
    myaccount: 'Мій акаунт',
    mydetails: 'Деталі акаунта',
    pushnotifications: 'Push сповіщення',
    settings: "Налаштування",

    mycreatoraccount: 'Автор',
    creatorDetails: 'Деталі автора',

    security: "Безпека",
    dltAccountBtnTitle: "Видалити акаунт",

    logoutButtonTitle: "Вийти з акаунту",
    languageChangeTitle: "Мова",

    creatorRequestErrorMesssage: "Не вдалося завантажити дані про автора",
    creatorLoadingMessage: "Завантажуємо дані про автора ...",
  },
  termOfUse: {
    termOfUseTitle: 'Умови користування',
    aboutCompany:
      'Sponsor.financial - це онлайн-платформа, яка дозволяє творцям отримувати оплату від своїх шанувальників. Творці отримують більше свободи, ніж на інших платформах.',

    liabilityTitle: 'Відповідальність',
    liability:
      'Якщо ви втратите гроші в результаті використання платформи, будь-які виплати вам обмежуються сумою грошей, які ми отримали від транзакції. У межах, дозволених законом, ми не несемо відповідальності перед вами за будь-які випадкові, штрафні збитки, що виникли внаслідок цих умов, або за використання або спробу використання платформи. ',
    rateTitle: 'Тарифи',
    rate: 'Комісія платформи становить 10%',
    yourAccountTitle: 'Твій акаунт',
    yourAccount:
      'Кожен користувач може зареєструватися і почати підтримувати інших авторів за допомогою щомісячної підписки. Для цього потрібно знайти сторінку улюбленого автора, вибрати підписку та перейти на сторінку оформлення підписки. Після цього вас буду перенаправлено на сторінку оплати. \n\nПід час створення облікового запису ви повинні добросовісно надавати нам точну інформацію, і ви погоджуєтесь оновлювати свою інформацію, якщо вона змінюється. Щоб створити обліковий запис, вам повинно бути не менше 12 років. Щоб приєднатися до членства творця або оплатити платну підписку, вам повинно бути не менше 14 років або мати дозвіл батьків. Ви несете відповідальність за все, що відбувається, коли хтось увійшов у ваш обліковий запис, а також за безпеку облікового запису. Будь ласка, негайно зв’яжіться з нами, якщо вважаєте, що ваш обліковий запис зламано.',
    warantlyDisclaimerTitle: 'Відмова від гарантії',
    warantlyDisclaimer: "Платформа надається 'як є' і без будь-яких гарантій.",
    accountDeletionTitle: 'Видалення облікового запису',
    accountDeletion:
      'Ви можете назавжди видалити свій обліковий запис, повідомивши нам за допомогою служби підтримки (через електронну пошту або вайбер). Ми можемо припинити або призупинити Ваш обліковий запис у будь-який час на наш розсуд. Ми також можемо скасувати будь-які підписки на членство та видалити будь-які описи, публікації чи переваги на власний розсуд. Ви не можете подавати проти нас позов про призупинення або припинення дії облікового запису іншої особи, і ви погоджуєтесь, що не подаватимете такі претензії. Якщо ви намагаєтеся пред’явити таку вимогу, ви несете відповідальність за завдані збитки, включаючи судові витрати та витрати адвоката. Ці умови залишаються в силі, навіть якщо у вас більше немає облікового запису.',
    aboutCreatorTitle: 'Автори',
    aboutCreator:
      'Щоб стати автором, просто заповніть основні відомості про свою сторінку. Коли ви приєднуєтесь до платформи, ви стаєте частиною спільноти. Ви можете використовувати інструменти для творців, які надає платформа, демонструвати свої творіння та отримувати постійний дохід від вашої сторінки. На платформі ви можете запропонувати своїм спонсорам щось захоплююче, що дає їм унікальні переваги, як вони хочуть, наприклад, додатковий доступ, товари, ексклюзивність та цікавий досвід. У свою чергу, ви отримаєте лояльну підтримку від своїх спонсорів та постійний дохід від членства, яке спонсори придбали через нашу платфому',
    sponsorTitle: 'Спонсори',
    aboutSponsors:
      'Будь-хто може стати спонсором, оформивши платну підписку на сайті. Всі кошти, за винятком комісії платформи, будуть перераховані авторам, підписку яких оформив користувач. Ціна, термін та інші параметри залежать від оформленої підписки',
    paymentsTitle: 'Виплати',
    payments:
      "Як творець, ви робите своє членство доступним на платформі, і ми надаємо членство вашим клієнтам в якості підписки. Ми також вирішуємо такі питання платежів, як шахрайство, повернення платежів та вирішення платіжних спорів. Ми намагаємося забезпечити своєчасний доступ до ваших коштів, але ви можете час від часу відчувати затримки в доступі до ваших коштів. Ми також можемо блокувати або утримувати платежі за порушення нашої політики або з міркувань дотримання, включаючи збір інформації про податкову звітність. Коли платежі затримуються або блокуються, ми намагаємося негайно повідомити вам причину. Якщо у вас є запитання щодо блокування платежів, зверніться до нас. З метою захисту творців ми можемо блокувати виплати меценатів, якщо вважатимемо їх шахрайськими. Іноді такі дії, як повернення коштів, можуть негативно вплинути на баланс вашого рахунку. Якщо ваш баланс стане від'ємним, ми залишаємо за собою право стягнути ці кошти з майбутніх платежів",
      
  },
  publicOfferScreen: {
    screenTitle: 'Оферта-договір',
  },
  notFoundScreen: {
    message: "Сторінка не знайдена"
  },
  footer: {
    support: 'Підтримка: ',
    aboutUs: 'Про нас',
    termOfUse: 'Умови користування',
    publicOffer: 'Договір-оферта',
    publicCompanyAddress: 'Київ, Україна, 01103',
  },
  appLock: {
    biometryTitle: 'Використайте Face or Touch ID',
    touchIDconfig: {
      promptMessage: 'Аутентифікація',
      fallbackLabel: 'Спробуйте використати пароль',
    },
    delete: 'Видалити',
    unlock: 'Розблокувати',
    cancel: 'Відмінити',
    androidModalTitle: 'Увійти за допомогою відбитка пальця',
  },

  publicCreatorTiersScreen: {
    screenTitle: "Рівні підписок автора",
  },
};
