
import { createCRUDActionCreators } from "../store/src/common";

export const {
    uploadGoalsTranslations,
    uploadGoalsTranslationsFailed,
    uploadGoalsTranslationsSuccess,
    deleteGoalsTranslationsFailed,
    deleteGoalsTranslationsSuccess,
    deleteGoalsTranslations,
    updateGoalsTranslations,
    updateGoalsTranslationsFailed,
    updateGoalsTranslationsSuccess,
    getGoalsTranslations,
    getGoalsTranslationsFailed,
    getGoalsTranslationsSuccess
} = createCRUDActionCreators('GoalsTranslations','')


