import React, {useCallback} from 'react';
import {
  Row,
  Text,
  Button,
  View,
  Checkbox,
  CreatorGoalEditPreview,
} from 'components';
import {useDispatch, useSelector} from 'react-redux';
import {
  updateCreatorStart,
  selectCreatorShownGoals,
  selectCreatorBasicInfo,
} from 'store';
import {i18n} from 'localize';
import {ECreatorSettings, TCreatorGoalType} from 'types';
import {TouchableOpacity, FlatList, StyleSheet} from 'react-native';
import {EscreenNames} from 'navigation';
import {useNavigation} from 'hooks';
import {isEmpty} from 'lodash';

export const EditCreatorGoals = () => {
  const creatorState = useSelector(selectCreatorBasicInfo);
  const shownGoals = useSelector(selectCreatorShownGoals);

  const {navigate} = useNavigation();
  const dispatch = useDispatch();

  const openNewGoalScreen = useCallback(() => {
    navigate(EscreenNames.CreatorGoalEdit);
  }, [navigate]);

  const changeGoalSettings = useCallback(
    value => {
      //todo: show console warn if no callbacks
      dispatch(
        //todo: find better way to update settings
        updateCreatorStart(
          {
            settings: {
              [ECreatorSettings.showGoals]: value,
            },
          },
          {},
        ),
      );
    },
    [dispatch],
  );

  //todo: add tests here
  const isGoalHidden = !creatorState?.settings?.[ECreatorSettings.showGoals];

  const renderGoalShowFilter = useCallback(() => {
    return (
      <View variant='sectionContainer'>
        <Text variant="sectionTitle">
          {i18n.t('editCreatorGoals.showGoalSettingTitle')}
        </Text>
        <View>
          <Checkbox
            containerStyle={styles.checkboxContainer}
            title={i18n.t('editCreatorGoals.subscribersGoalTypeLabel')}
            onPress={() => changeGoalSettings(TCreatorGoalType.subscribers)}
            value={
              creatorState?.settings?.[ECreatorSettings.showGoals] ===
              TCreatorGoalType.subscribers
            }
          />
          <Checkbox
            containerStyle={styles.checkboxContainer}
            testID="checkbox-show-earnings-goals"
            onPress={() => changeGoalSettings(TCreatorGoalType.earnings)}
            title={i18n.t('editCreatorGoals.earningsGoalTypeLabel')}
            value={
              creatorState?.settings?.[ECreatorSettings.showGoals] ===
              TCreatorGoalType.earnings
            }
          />
          <Checkbox
            containerStyle={styles.checkboxContainer}
            testID="checkbox-hide-goals"
            title={i18n.t('editCreatorGoals.noneGoalTypeLabel')}
            onPress={() => changeGoalSettings(null)}
            value={!creatorState?.settings?.[ECreatorSettings.showGoals]}
          />
        </View>
      </View>
    );
  }, [creatorState, changeGoalSettings]);

  return (
    <View variant="screenContainer">
      <FlatList
        data={shownGoals}
        showsVerticalScrollIndicator={false}
        ListHeaderComponent={() => (
          <>
            {renderGoalShowFilter()}
            <Row containerStyle={{marginTop: 10}}>
              <Text variant="sectionTitle">
                {i18n.t('editCreatorGoals.screenTitle')}
              </Text>
              <TouchableOpacity
                style={styles.addBtn}
                onPress={openNewGoalScreen}>
                <Text variant="sectionTitle">+</Text>
              </TouchableOpacity>
            </Row>
          </>
        )}
        ListFooterComponent={
          !isEmpty(shownGoals) ? (
            <Button
              title={i18n.t('editCreatorGoals.newGoalButtonTitle')}
              containerStyle={{marginTop: 25}}
              onPress={openNewGoalScreen}
            />
          ) : null
        }
        ListEmptyComponent={
          <View style={{alignItems: 'center'}}>
            {isGoalHidden ? (
              <Text center variant="secondaryTitle">
                {i18n.t('editCreatorGoals.goalHiddenText')}
              </Text>
            ) : (
              <>
                <Text center variant="secondaryTitle">
                  {i18n.t('editCreatorGoals.noGoalsListTitle')}
                </Text>
                <Button
                  title={i18n.t('editCreatorGoals.newGoalButtonTitle')}
                  containerStyle={{marginTop: 25}}
                  onPress={openNewGoalScreen}
                />
              </>
            )}
          </View>
        }
        renderItem={({item}) => (
          <CreatorGoalEditPreview
            currency={creatorState?.mainCurrency}
            {...item}
          />
        )}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    padding: 15,
    flex: 1,
    backgroundColor: 'white',
  },
  addBtn: {
    marginLeft: 25,
  },
  checkboxContainer: {
    marginTop: 10,
  },
});
