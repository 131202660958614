import React from 'react';
import {Placeholder, PlaceholderLine, Fade} from 'rn-placeholder';
import {TPlaceholderProps} from './types';

export const ProfilePlaceholder: React.FC<TPlaceholderProps> = ({style}) => {
  return (
    <Placeholder Animation={Fade}>
      <PlaceholderLine
        style={[
          {
            width: 220,
            borderRadius: 220,
            height: 220,
            alignSelf: 'center',
          },
          style,
        ]}
      />
      <PlaceholderLine style={{alignSelf: 'center', width: 100, height: 25}} />
      <PlaceholderLine style={{alignSelf: 'center', width: 200, height: 25}} />
    </Placeholder>
  );
};
