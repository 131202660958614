import {useState, useEffect, useCallback} from 'react';

import {isEmpty, isNull} from 'lodash';
import {TTier, TCreator, TGetCreatorOutput} from 'types';
import {filterTiers, filterCollaborations} from 'utils';
import {selectPostsByCreatorId, getCreatorPublicInfo, TStore, selectUser} from 'store';
import {useDispatch, useSelector} from 'react-redux';
import { useActiveLanguage } from './useActiveLanguage';

export const useFetchCreatorInfo = ({pageURL}: {pageURL?: string}) => {
  const [creatorInfo, setCreatorInfo] = useState<TCreator | null>(null);
  const [tiers, setTiers] = useState<TTier[]>([]);
  const [collaborationTiers, setCollaborationTiers] = useState<TTier[]>([]);
  const [error, setError] = useState();
  // const [postsState, localStateDispatch] = useReducer(reducer, initialState);

  const locale = useActiveLanguage()
  const posts = useSelector((state: TStore) =>
    selectPostsByCreatorId(state, creatorInfo?.id || ''),
  );

  const [isLoading, setIsLoading] = useState<null | boolean>(null);
  const dispatch = useDispatch();

  const toggleEmodji = useCallback(
    async (_?: {emodjiUnicode: string; userId: string; postId: string}) => {
      // LOG('toggleEmodji');
      // localStateDispatch({
      //   type: TOGGLE_EMODJI,
      //   payload: {
      //     emodjiUnicode,
      //     postId,
      //     userId,
      //   },
      // });
    },
    [],
  );

  useEffect(() => {
    const fetchCreator = async () => {
      if (!pageURL || !isNull(isLoading)) {
        return;
      }
      setIsLoading(true);
      dispatch(
        getCreatorPublicInfo({pageURL, lang: locale}, {
          onSuccess: (fetchedCreatorInfo: TGetCreatorOutput['data']) => {
            if (!isEmpty(fetchedCreatorInfo.tiers)) {
              setTiers(filterTiers(fetchedCreatorInfo.tiers));
              setCollaborationTiers(
                filterCollaborations(fetchedCreatorInfo.tiers),
              );
            }
            setCreatorInfo(fetchedCreatorInfo.creator);
            setIsLoading(false);
          },
          onFail: err => {
            setError(err);
          },
          onFinish: () => {
            setIsLoading(false);
          },
        }),
      );
    };
    fetchCreator();
  }, [dispatch, isLoading, pageURL, locale]);

  // const posts = useMemo(() => {
  //   return Object.values(postsState);
  // }, [postsState]);

  return {
    creatorInfo,
    tiers,
    collaborationTiers,
    error,
    posts,
    isLoading,
    toggleEmodji,
  };
};
