import {FlatList, TouchableOpacity} from 'react-native';
import React, {useEffect, useState} from 'react';
import {Button, ContentContainer, ScrollViewOnlyOnMobile, Text, TierBase, View} from 'components';
import {useDispatch, useSelector} from 'react-redux';
import {selectTiers, TStore} from 'store';
import {TiersList} from '../tiers/TiersList.component';
import {selectPostTranslations} from './posts-translations.selectors';
import {TPost, TTier} from 'types';
import {TranslationEditPreviewContainer} from './TranslationEditPreview.container';
import {i18n} from 'localize';
import {useDispatchAction, useNavigation, useParams} from 'hooks';
import {EscreenNames} from 'navigation';
import {colors} from '@sponsor/assets';
import { DEVICE_WIDTH, TIER_WIDTH } from '@sponsor/constants';
import { isMobile } from 'utils';
import { getPostTranslations } from './posts-translations.actions';

export const PostsTranslationsScreen = () => {
  console.log('PostsTranslationsScreen')
  // const [selectedItem, setSeletedItem] = useState<null | TTier>(null);
  const {parentId} = useParams()
  // const tiers = useSelector(selectTiers);
  const translations = useSelector((state: TStore) =>
    selectPostTranslations(state, parentId || ''),
  );
  console.log('translations',translations)
  const {navigate} = useNavigation();

  const {
    submitAction,
  } = useDispatchAction(getPostTranslations)

  useEffect(() => {
    if(parentId){
      submitAction({parentId})
    }
  },[submitAction, parentId])

  // const getSelectedTierStyles = (item:TPost) => {
  //   if(parentId === item?.id) return {
  //       borderColor: colors.GREEN_OFFICIAL,
  //       borderWidth: 3,
  //   }
  //   return {}
  // }

  return (
    <ScrollViewOnlyOnMobile>
      <View variant="screenContainer">
        <Text center variant='sectionTitle'>
          {i18n.t('creatorPostTranslationsScreen.screenTitle')}
        </Text>
        <ContentContainer>
          {!!parentId && (
            <FlatList
              data={translations}
              ItemSeparatorComponent={() => (
                <View variant="verticalListSeparator" />
              )}
              ListEmptyComponent={() => (
                <View variant="sectionContainer">
                  <Text variant="thirdTitle">
                    {i18n.t('creatorPostTranslationsScreen.noTranslation')}
                  </Text>
                </View>
              )}
              ListHeaderComponent={() => (
                <View variant="sectionContainer">
                  <Button
                    title={`${i18n.t(
                      'creatorPostTranslationsScreen.addTranslationButtonTitle',
                    )}`}
                    onPress={() => {
                      navigate(EscreenNames.EditPostTranslations, {
                        parentId,
                      });
                    }}
                  />
                </View>
              )}
              renderItem={({item}) => (
                <TranslationEditPreviewContainer
                  id={item?.id}
                  parentId={parentId}
                />
              )}
            />
          )}
        </ContentContainer>
      </View>
    </ScrollViewOnlyOnMobile>
  );
};
