export const subscriptionLocalization = {
  en: {
    subscriptionCryptoPaymentForm: {
      next: 'Next',
      chooseAnotherCryptoBtnTitle: 'Choose another',
      cryptoAddress: 'Address',
      memo: 'Memo',

      cryptoPaymentFinishMessage: "After we receive your payment, subscription will be added to your account",
      disabledCryptoPaymentMessage: "Crypto payment is disabled by creator",
      amountToTransfer: "Amount to transfer"
    },
  },
  uk: {
    subscriptionCryptoPaymentForm: {
      next: 'Далі',
      chooseAnotherCryptoBtnTitle: 'Вибрати інше',
      cryptoAddress: 'Криптоадреса',
      memo: 'Memo',

      cryptoPaymentFinishMessage: "Після отримання підвердження платежу, підписка буде додана до Вашого акаунту. Очікуйте",
      disabledCryptoPaymentMessage: "Оплата в криптовалюті відключена автором",
      amountToTransfer: "Сума для трансферу"
    },
  },
};
