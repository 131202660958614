import {createSelector, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {call, put, takeEvery} from 'redux-saga/effects';
import {createAction, ActionType} from 'typesafe-actions';
import {filter, find} from 'lodash';
import {
  TUpdateProfileOutput,
  TUpdateProfileInput,
  TDeleteSubscriptionInput,
  TPostStopFollowingCreatorOutput,
  TPostSignupOutput,
  TPostStartFollowingCreatorOutput,
  EAnalyticsEventNames,
} from 'types';

import {LOG} from '../../utils/LOG';
import {getStore, TMetaInput, TStore, TUserStore} from './common';

import {ApiService, AnalyticsService} from '../../services/src/index';

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    email: '',
    followings: [],
  },
  reducers: {
    cancelSubscriptionSuccess: (
      state: TUserStore,
      action: PayloadAction<TDeleteSubscriptionInput>,
    ) => {
      const {subscriptionId} = action.payload;
      delete state.orderIds?.[subscriptionId];
      const subscription = find(
        state.activeSubscriptions,
        s => s?.id === subscriptionId,
      );

      // let newActiveSubscriptions = state.activeSubscriptions
      let updateDeletedButActiveSubscriptions =
        state.deletedButActiveSubscriptions;
      if (subscription) {
        state.activeSubscriptions = filter(
          state.activeSubscriptions,
          s => s?.id !== subscriptionId,
        );
        state.deletedButActiveSubscriptions = [
          ...(updateDeletedButActiveSubscriptions || []),
          subscription,
        ];
      }

      // return {
      //   ...state,
      //   activeSubscriptions: newActiveSubscriptions,
      //   deletedButActiveSubscriptions: updateDeletedButActiveSubscriptions
      // }
    },
    updateProfileSuccess: (state, action) => {
      const newState = {
        ...state,
        ...action.payload,
      };
      return newState;
    },
    subscribeCreatorSuccess: () => {
      // state.followings
    },
    setUser: (_, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      return action.payload;
    },
  },
});

export const SUBSCRIBE_CREATOR = 'user/SUBSCRIBE_CREATOR';
export const UNSUBSCRIBE_CREATOR = 'user/UNSUBSCRIBE_CREATOR';
export const GET_PROFILE = 'user/GET_PROFILE';
export const UPDATE_PROFILE = 'user/UPDATE_PROFILE';
export const CANCEL_SUBSCRIPTION = 'user/CANCEL_SUBSCRIPTION';
// Action creators are generated for each case reducer function
export const {updateProfileSuccess, cancelSubscriptionSuccess, setUser} =
  userSlice.actions;

export const updateProfile = createAction(UPDATE_PROFILE)<
  TUpdateProfileInput,
  TMetaInput & {updateLocally?: boolean}
>();

export const subscribeCreator = createAction(SUBSCRIBE_CREATOR)<
  {
    creatorId?: string;
  },
  TMetaInput
>();

export const unsubscribeCreator = createAction(UNSUBSCRIBE_CREATOR)<
  {
    creatorId?: string;
  },
  TMetaInput
>();

export const getProfile = createAction(GET_PROFILE)<null, TMetaInput>();
export const cancelSubscription = createAction(CANCEL_SUBSCRIPTION)<
  TDeleteSubscriptionInput,
  TMetaInput
>();

export default userSlice.reducer;

// selectors
export const selectUser = createSelector(getStore, state => state?.user);
export const selectFollowings = createSelector(
  selectUser,
  state => state?.followings || [],
);
export const selectActiveSubscriptions = createSelector(
  selectUser,
  state => state?.activeSubscriptions || [],
);
export const selectDeletedButActiveSubscriptions = createSelector(
  selectUser,
  state => state?.deletedButActiveSubscriptions || [],
);
export const selectSubscriptionByTierId = createSelector(
  [selectUser, (state: TStore, tierId: string): string => tierId],
  (user, tierId) => {
    return find(user?.activeSubscriptions, s => s?.tier?.id === tierId);
  },
);

function* getProfileSaga({
  meta: {onSuccess, onFail},
}: ActionType<typeof getProfile>) {
  try {
    const {data}: TPostSignupOutput = yield call(ApiService.getProfile);
    if (!data) {
      return;
    }
    yield put(setUser(data));
    AnalyticsService.logEvent(EAnalyticsEventNames.GETTING_PROFILE_SUCCEEDED);
    onSuccess?.();
  } catch (err) {
    LOG(err);
    AnalyticsService.logEvent(EAnalyticsEventNames.GETTING_PROFILE_FAILED);
    onFail?.();
  }
}

function* cancelSubscriptionSaga({
  payload: {subscriptionId},
  meta: {onSuccess, onFail, onFinish},
}: ActionType<typeof cancelSubscription>) {
  try {
    yield call(ApiService.deleteSubscription, {
      subscriptionId,
    });
    yield put(cancelSubscriptionSuccess({subscriptionId}));
    AnalyticsService.logEvent(
      EAnalyticsEventNames.SUBSCRIPTION_CANCELING_SUCCEEDED,
    );
    onSuccess?.();
  } catch (err) {
    LOG('cancelSubscription', err);
    AnalyticsService.logEvent(
      EAnalyticsEventNames.SUBSCRIPTION_CANCELING_FAILED,
    );
    onFail?.();
  } finally {
    onFinish?.();
  }
}

function* subscribeCreatorSaga({
  payload: {creatorId},
  meta: {onSuccess, onFail, onFinish},
}: ActionType<typeof subscribeCreator>) {
  try {
    const {data}: TPostStartFollowingCreatorOutput = yield call(
      ApiService.postStartFollowingCreator,
      {creatorId},
    );
    AnalyticsService.logEvent(EAnalyticsEventNames.START_FOLLOWING_CREATOR);
    yield put(setUser(data));
    onSuccess?.();
  } catch (err) {
    LOG(err);
    onFail?.();
  } finally {
    onFinish?.();
  }
}

function* unsubscribeCreatorSaga({
  payload: {creatorId},
  meta: {onSuccess, onFail, onFinish},
}: ActionType<typeof unsubscribeCreator>) {
  try {
    const {data}: TPostStopFollowingCreatorOutput = yield call(
      ApiService.deleteFollowingForCreator,
      {creatorId},
    );
    AnalyticsService.logEvent(EAnalyticsEventNames.STOP_FOLLOWING_CREATOR);
    yield put(setUser(data));
    onSuccess?.();
  } catch (err) {
    LOG(err);
    onFail?.();
  } finally {
    onFinish?.();
  }
}

function* updateProfileSaga({
  payload,
  meta: {onFinish, onSuccess, onFail, updateLocally},
}: ActionType<typeof updateProfile>) {
  try {
    if (updateLocally) {
      yield put(updateProfileSuccess(payload));
    }
    const {data}: TUpdateProfileOutput = yield call(
      ApiService.updateProfile,
      payload,
    );

    yield put(updateProfileSuccess(data));
    onSuccess?.();
  } catch (err) {
    onFail?.();
    LOG('error during updating creator', err);
  } finally {
    onFinish?.();
  }
}

export function* userFlow() {
  yield takeEvery(getProfile, getProfileSaga);
  yield takeEvery(updateProfile, updateProfileSaga);
  yield takeEvery(unsubscribeCreator, unsubscribeCreatorSaga);
  yield takeEvery(subscribeCreator, subscribeCreatorSaga);
  yield takeEvery(cancelSubscription, cancelSubscriptionSaga);
}
