import React from 'react';
import {TTier} from 'types';
import {Tier} from 'components';
import {TTierComponentProps} from '../components/src/Tier/Tier.types';

type TProps = {
  //todo: add unified language code, not typical string
  lang: string;
} & TTierComponentProps;

/**
 * this container is place for
 * logic related to the choosing right translation
 * and managing fallbacks
 * 
 * todo: move to the hoc ?
 */
export const TierWithTranslation: React.FC<TTier & TProps> = ({
  translations = {},
  lang,
  title,
  description,
  ...otherProps
}) => {
  
  const translatedData = translations?.[lang];

  return (
    <Tier
      {...otherProps}
      title={translatedData?.title || title}
      description={translatedData?.description || description}
      translations={translations}
    />
  );
};
