import {NetworkService} from '../services';
import {ApiRequest} from '../types';
import {
  TPostUploadTiersTranslationsInput,
  TPostUploadTiersTranslationsOutput,
  TPutUpdateTiersTranslationsInput,
  TPutUpdateTiersTranslationsOutput,
  TDeleteTiersTranslationsInput,
  TDeleteTiersTranslationsOutput,
} from './tiersTranslations.types';

export const postUploadTiersTranslations = async (
  inputs: TPostUploadTiersTranslationsInput,
): ApiRequest<TPostUploadTiersTranslationsOutput> => {
  return NetworkService.post('/v1/tiers-translations', inputs);
};

export const putUpdateTiersTranslations = async (
  params: TPutUpdateTiersTranslationsInput,
): ApiRequest<TPutUpdateTiersTranslationsOutput> => {
  return NetworkService.put('/v1/tiers-translations', params);
};

export const deleteTiersTranslations = async (
  params: TDeleteTiersTranslationsInput,
): ApiRequest<TDeleteTiersTranslationsOutput> => {
  return NetworkService.delete('/v1/tiers-translations', params);
};

export const TiersTranslationsService = {
  postUploadTiersTranslations,
  putUpdateTiersTranslations,
  deleteTiersTranslations,
};
