import PropTypes from 'prop-types';

import React from 'react';

import * as S from './button.styled';

import useMedia from '../../hook/media';

function ButtonView({title, theme, size, style, ...otherProps}) {
  const media = useMedia();

  return (
    <S.Container
      {...otherProps}
      style={style}
      title={title}
      theme={theme}
      size={size}
      media={media}>
      <S.Title theme={theme} size={size}>
        {title}
      </S.Title>
    </S.Container>
  );
}

ButtonView.propTypes = {
  theme: PropTypes.string,
  size: PropTypes.arrayOf(['base', 'big']),
};

ButtonView.defaultProps = {
  theme: 'white',
  size: 'base',
};

export default ButtonView;
