import React from 'react';
import {StyleSheet, TouchableOpacity} from 'react-native';
import {Row, Text, View, Column, Icon} from 'components';
import {colors} from '@sponsor/assets';
import {Avatar} from '../Avatar';
import {useNavigation} from 'hooks';
import {TCreator, TPost} from 'types';
import { generatePostPublicURL } from '../../../posts/posts.utils';
import { EscreenNames } from 'navigation';
import { alertMessage } from 'utils';
import { i18n } from 'localize';
import { onSharePress } from '../../../share/onSharePress';

type TProps = {
  id: string;
  unlocked?: boolean
  creator?: Pick<TCreator, 'title' | 'avatarUri' | 'pageURL'>;
} & Pick<TPost, 'translations'>

const ICON_SIZE = 16

export const PostHeaderContainer: React.FC<TProps> = ({
  unlocked,
  id, 
  creator, 
  translations
}) => {
  const {navigate} = useNavigation();

  const onShare = async () => {
    const postPublicLink = generatePostPublicURL({postId: id})
    await onSharePress({text: postPublicLink})
  }

  const renderTranslationIcon = () => {
    if(translations){
      const langs = Object.keys(translations) || []
      if(langs.length < 1)return null;
      return (
        <TouchableOpacity
            style={{width: 25,  paddingVertical: 5}}
            onPress={() => alertMessage(
              i18n.t("translationsFeature.postHasTranslationsTitle"), 
              i18n.t("translationsFeature.postHasTranslationsMessage", {languages: langs.join(', ')})
              )
            }
          >
            <Icon 
              size={ICON_SIZE} 
              name="world"
            />
        </TouchableOpacity>
      )
    }
    return null
  }

  return (
    <>
      {creator?.title && (
        <View style={styles.titleRow}>
          <Column flex={4}>
            {/* TODO: fix navigate */}
            <TouchableOpacity
              //todo: add navigation to creator post
              disabled={!unlocked}
              onPress={() => navigate(`/${creator?.pageURL}/posts`)}
            >
              <Row
                columnCenter
                containsSimilarItems
                containerStyle={{paddingHorizontal: 10}}>
                {creator?.avatarUri && (
                  <Avatar uri={creator?.avatarUri} size={40} />
                )}
                <Text style={styles.title}>{creator?.title}</Text>
              </Row>
            </TouchableOpacity>
          </Column>

          <Column 
            flex={1}
            containerStyle={{alignItems: 'flex-end', flexWrap: 'wrap'}}
          >
            <Row fullWidth containerStyle={{justifyContent:"flex-end",}}>
              <Column>
                  {renderTranslationIcon()}
              </Column>
              <Column>
                <TouchableOpacity
                  style={{width: 25, paddingVertical: 5}}
                  onPress={() => navigate(EscreenNames.PostScreen, {id}, {newTab: true})}
                >
                  <Icon size={ICON_SIZE} name="opened-eye"/>
                </TouchableOpacity>

                <TouchableOpacity
                  style={{width: 25}}
                  onPress={onShare}
                >
                  <Icon size={ICON_SIZE} name="share"/>
                </TouchableOpacity>
              </Column>
            </Row>

          </Column>
        </View>
      )}
    </>
  );
};

const styles = StyleSheet.create({
  title: {
    fontWeight: 'bold',
    fontSize: 20,
    flexShrink: 1,
    marginVertical: 8,
  },
  titleRow: {
    // flex:1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'wrap',
    paddingBottom: 10
  },
  container: {
    // move to general container styles
    // flex:1,
    // padding: 14,
    // backgroundColor: 'white',
  },
  contentContainer: {
    // minHeight: 150,
    marginBottom: 15,
    padding: 14,
  },
  lockedBlock: {
    alignItems: 'center',
    padding: 20,
    justifyContent: 'center',
  },
  smileAddBtn: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 5,
    borderWidth: 0.5,
    padding: 8,
    height: 50,
    width: 60,
  },
  bottomRow: {
    paddingVertical: 15,
    height: 100,
    // justifyContent: 'space-between',
    // flexWrap: 'wrap',
    // flex:1,
  },
  emodjiRow: {
    justifyContent: 'flex-start',
    flexDirection: 'row',
    flexWrap: 'wrap',
    flex: 1,
  },
  emodjiContainer: {
    borderRadius: 10,
    padding: 5,
    backgroundColor: colors.DUSTY_GRAY,
    alignItems: 'center',
    justifyContent: 'center',
    borderWidth: 0,
    height: 50,
    maxWidth: 100,
  },
  emodjiActiveContainer: {
    borderColor: colors.ORANGE,
    borderWidth: 2,
  },

  videoPlayer: {height: 300, width: '95%'},
});
