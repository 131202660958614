import {createAction} from 'typesafe-actions';
import {
  TSubscription,
  TSearchResult,
  TProfile,
  TCreator,
  TTier,
  TPost,
  TCreatorTranslation,
} from 'types';
import {TCreatorStats} from '../../types/src';
import {call, put} from '@redux-saga/core/effects';
import {LOG} from '../../utils/LOG';
import {forEach} from 'lodash';
import {TGreetingsState} from './creator/greetings';
import {TMoneyWithdrawMethodsState} from '../../money-withdraw-methods/money-withdraw-methods.types';
import {i18n} from 'localize';
import {getResponseErrorMessage} from '../../errors/errors.utils';
import { TViewedState } from '../../viewed/viewed.types';
import { TPostsTranslationsState } from '../..//posts-translations/postsTranslations.types';
import { TGoalsTranslationsState } from '../../goalsTranslations/goalsTranslations.types';

export type TMetaInput = {
  onSuccess?: (data?: any) => void;
  onFail?: (err?: any) => void;
  onFinish?: () => void;
};

export type TInFeedPostsState = TSearchResult<{
  [key in string]: TPost;
}>;

export type TCreatorPostsState = {
  [key in string]: TSearchResult<{[key2 in string]: TPost}>;
};

export type TSearchState = {
  creators: TCreator[];
};

export interface TStore {
  auth: any;
  user: TUserStore;
  creator: TCreatorState;
  creatorStats: TSearchResult<TCreatorStats[]>;
  tiers: TTiersState;
  subscriptions: TSubscriptionsState;
  posts: {
    //todo: comment store structure
    publicPosts: TPublicPostsState;
    ownPosts: TOwnPostsState;

    //search results, store only ids ???
    inFeedPosts: TInFeedPostsState;
    creatorsPosts: TCreatorPostsState;
  };
  search: TSearchState;
  settings: TSettingState;
  viewed: TViewedState,
  postsTranslations: TPostsTranslationsState,

  goalsTranslations: TGoalsTranslationsState;
}

export type TPublicPostsState = {
  [key in string]: TPost;
};

export type TUserStore = Partial<TProfile>;

export type TTiersState = {
  [key in string]: TTier;
};

export type TBasicCreatorInfoState = Omit<
  TCreator,
  'greetings' | 'translations' | 'withdrawMethods'
>;

export type TCreatorTranslationState = {
  [key in string]: TCreatorTranslation;
};

export type TCreatorState = {
  basicInfo: TBasicCreatorInfoState;
  greetings: TGreetingsState;
  translations: TCreatorTranslationState;
  withdrawMethods: TMoneyWithdrawMethodsState;
};

export type TSubscriptionsState = {
  [key in string]: TSubscription;
};

export type TOwnPostsState = {
  drafts: {[key in string]: TPost};
  published: {[key in string]: TPost};
};

export type TSettingState = {
  lang?: string;
  isBiometryEnabled?: boolean;
};

export const getStore = (store: TStore) => store;

export const CLEAR_STORE = 'CLEAR_STORE';
export const clearStore = createAction(CLEAR_STORE)();

export const createCRUDActionCreators = <T extends string, TActionInput>(
  entityName: T,
  actionPrefix: string,
) => {
  actionPrefix = actionPrefix || '';

  const crudOperationNames = ['upload', 'update', 'delete', 'get'] as const;
  const crudOperationStatus = ['Success', 'Failed'] as const;

  type TCrudOperationStatus = typeof crudOperationStatus[number];
  type TCrudOperationNames = typeof crudOperationNames[number];

  type TActionName =
    | `${TCrudOperationNames}${T}${TCrudOperationStatus}`
    | `${TCrudOperationNames}${T}`;

  // const actionNames = reduce<string, {[key in TActionName] : string}>(crudOperationNames, (s, crudOperationName:string) => {
  //   s[crudOperationName+entityName] = `${crudOperationName}${entityName}`
  //   return s
  // }, {})

  let actions: {
    [key in TActionName]: (...args:any) => void;
  } = {} as any;

  forEach(crudOperationNames, crudOperation => {
    //todo: add capitalize
    let index = crudOperation + entityName;
    actions[index as TActionName] = createAction(
      `${actionPrefix}${crudOperation}${entityName}`,
    )?.<TActionInput, TMetaInput>();
    forEach(crudOperationStatus, status => {
      let statusIndex = crudOperation + entityName + status;
      actions[statusIndex as TActionName] = createAction(
        `${actionPrefix}${crudOperation}${entityName}${status}`,
      )?.<any, TMetaInput>();
    });
  });

  return actions;
};

//todo: change any type
export const fetchSaga = (
  api: any,
  options?: {
    analytics?: {
      onFailedEvent: string;
      onSuccessEvent: string;
      logEvent: (event: any) => Promise<void>;
    };
  },
) =>
  function* ({type, payload, meta}: {type: string; payload: any; meta?: any}) {
    const {onFinish, onSuccess, onFail} = meta || {};
    const {analytics} = options || {};
    const {logEvent, onSuccessEvent, onFailedEvent} = analytics || {};

    try {
      const {data} = yield call(api, payload);

      if (data?.errorCode) {
        const errorMessage = getResponseErrorMessage(data?.errorCode);

        yield put({
          type: type + 'Failed',
          payload: payload,
        });
        onFail?.({
          code: data.errorCode,
          message: data?.errorMessage || errorMessage,
        });
        if (logEvent) {
          yield call(logEvent, onSuccessEvent);
        }
      } else {
        yield put({
          type: type + 'Success',
          payload: data || payload,
        });
        onSuccess?.(data || payload);
        if (logEvent) {
          yield call(logEvent, onFailedEvent);
        }
      }
      // yield put(updateCreatorPageSuccess(creator));
    } catch (err: any) {
      LOG(`error during ${type}`, err);

      console.log('err', JSON.stringify(err));
      const message = getResponseErrorMessage(err?.response?.status);

      yield put({
        type: type + 'Failed',
        payload: payload,
      });
      logEvent?.(onFailedEvent);
      //todo: add error code handling
      onFail?.({
        errorCode: err?.response?.status,
        message: message || i18n.t('errors.somethingWrong'),
      });
    } finally {
      onFinish?.();
    }
  };
