import {
    Dimensions
} from 'react-native';
import {
    Config
} from 'libs';

export const DEFAULT_AVATAR_URI = 'https://i.pravatar.cc/300';

export const MAX_EMODJI_COUNT_PER_USER = 4;

export const DEVICE_WIDTH = Dimensions.get('window').width;

export const DEVICE_HEIGHT = Dimensions.get('window').height;

export const FOOTER_HEIGHT = 150;
export const HEADER_HEIGHT = 100;

export const TIER_WIDTH = 350;
export const TIER_SEPARATOR_WIDTH = 25;
export const TIERS_CONTAINER_MARGIN_LEFT = 50;

export const LOGO_TRANSPARENT_URI = `${Config.STATIC_IMAGES_URL}/logo.png`;