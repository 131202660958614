import {createSlice} from '@reduxjs/toolkit';
import {createSelector} from 'reselect';
import {TStore} from 'store';
import {updateProfileSuccess} from './user';

export const settingsSlice = createSlice({
  name: 'settings',
  initialState: {
    isBiometryEnabled: false,
    lang: 'en',
  },
  extraReducers: {
    [updateProfileSuccess?.toString()]: (state, action) => {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
  reducers: {
    setIsBiometryEnabled: (state, action) => {
      state.isBiometryEnabled = action.payload;
    },
  },
});

export const selectSettings = (state: TStore): TStore['settings'] =>
  state.settings;
export const selectIsBiometryEnabled = createSelector(
  selectSettings,
  ({isBiometryEnabled}) => isBiometryEnabled,
);
export const selectLanguage = createSelector(selectSettings, ({lang}) => lang);

export const {setIsBiometryEnabled} = settingsSlice.actions;

export default settingsSlice.reducer;
