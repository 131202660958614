import React from 'react';
import {Route} from 'react-router-dom';

import {CreatorPage} from '../../screens/src/CreatorPage';
import {CancelSubscriptionByTokenScreen} from '../../screens/src/subscription/CancelSubscriptionByTokenScreen';
import {PostScreen} from '../../screens/src/posts/PostScreen';
import TermOfUse from '../../screens/src/TermOfUse';
import PublicOffer from '../../screens/src/PublicOffer';
import PaymentPage from '../../screens/src/PaymentPage';
import { CreatorPublicTiersScreen } from '../../screens/src/tiers/CreatorPublicTiersScreen/CreatorPublicTiersScreen';
import { EscreenNames } from './EscreenNames';
import { CreatorPublicPosts } from '../../screens/src/CreatorPublicPosts';

// const TermOfUse = React.lazy(() => import('../../screens/src/TermOfUse'));
// const PublicOffer = React.lazy(() => import('../../screens/src/PublicOffer'));
// const PaymentPage = React.lazy(() => import('../../screens/src/PaymentPage'));

export const commonRoutes = [
  <Route key={'/policy/legal'} path="/policy/legal" element={<TermOfUse />} />,
  <Route
    key={'/public-offer'}
    path="/public-offer"
    element={<PublicOffer />}
  />,
  <Route key={'/purchase'} path="/purchase" element={<PaymentPage />} />,
  <Route
    key={'/cancel-subscription-by-token'}
    path="/cancel-subscription-by-token"
    element={<CancelSubscriptionByTokenScreen />}
  />,
  <Route // todo: remove later ?
    key={'/creator-public'}
    path="/creator-public"
    element={<CreatorPage />}
  />,
  <Route
    key={'/c'}
    path="/c/:pageURL"
    element={<CreatorPage />}
  />,
  <Route  
    key={'/creator/posts'}
    path="/creator/posts"
    element={<CreatorPublicPosts />}
  />,
  <Route
    key={'/creator-tiers'}
    path={EscreenNames.CreatorPublicTiersScreen}
    element={<CreatorPublicTiersScreen />}
  />,
  <Route key={'/post'} path="/post" element={<PostScreen />} />,
];
