import React from 'react';
import {default as RNColorPicker} from 'react-native-wheel-color-picker';

type TProps = {
  color?: string;
  discrete?: boolean;
  onColorChangeComplete?: (color: string) => void;
};

export const ColorPicker: React.FC<TProps> = ({
  color,
  onColorChangeComplete,
  ...otherProps
}) => {
  return (
    <RNColorPicker
      color={color}
      onColorChangeComplete={onColorChangeComplete}
      thumbSize={40}
      sliderSize={40}
      autoResetSlider={false}
      row={false}
      {...otherProps}
    />
  );
};
