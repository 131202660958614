import {View, StyleSheet, Platform} from 'react-native';
import React, {ReactNode} from 'react';
import {Row} from '../Row';
import { Text } from '../Text';

type TProps = {
  title: string;
  subtitle?: string;
  Left?: ReactNode;
  Right?: ReactNode;
  chidlren?: any;
};

export const PaymentCardBase: React.FC<TProps> = ({
  title,
  subtitle,
  Left,
  Right,
  children,
}) => {
  return (
    <Row columnCenter containerStyle={styles.container}>
      <View>{Left}</View>
      <View style={styles.contentContainer}>
        <Text numberOfLines={1}>{title}</Text>
        {subtitle && <Text numberOfLines={1}>{subtitle}</Text>}
        {children}
      </View>
      <View>
        {Right}
      </View>
    </Row>
  );
};

const styles = StyleSheet.create({
  container: {
    ...Platform.select({
      web: {
        justifyContent: 'flex-start',
        maxWidth: 250,
      },
      default: {
        justifyContent: 'space-between',
        width: '100%',
        // minWidth: 200,
      },
    }),
    padding: 10,
    borderWidth: 0.5,
    // width: 'auto',
    borderRadius: 10,
    borderColor: 'black',
  },
  contentContainer: {
      paddingHorizontal: 10, 
      ...Platform.select({
        web: {flex: 1},
        default: {flex:1},
      })
  }
});
