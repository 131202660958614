import React from 'react';
import * as S from './home-top-line.styled';

import useMedia from '../../../../hook/media';

import {useNavigation} from 'hooks';
import {EscreenNames} from 'navigation';
import {i18n} from 'localize';
import logo from '../../../../images/logo.png';
import {LanguagePicker} from '../../../../../../../containers/LanguagePicker/LanguagePicker';
import {Row, View} from 'components';
import { LanguageModalPicker } from '../../../../../../../containers/LanguageModalPicker/LanguageModalPicker';

export default function HomeTopLineView({style}) {
  const media = useMedia();
  const {navigate} = useNavigation();

  return (
    <S.Container style={style} media={media}>
      <View flex={1}>
        <S.Logo source={logo} resizeMode="contain" media={media} />
      </View>

      <S.Actions media={media}>
        <Row containerStyle={{justifyContent: "flex-end"}} columnCenter flex={1}>
          <View style={{paddingTop: 5}}>
            <LanguageModalPicker />
          </View>
          {!media.sm && !media.md && <S.Button
            title={i18n.t('createAccount')}
            media={media}
            onPress={() => navigate(EscreenNames.Signup)}
          />}

          <S.ButtonLogin
            title={i18n.t('login')}
            theme="green"
            onPress={() => navigate(EscreenNames.Login)}
            media={media}
          />
        </Row>
      </S.Actions>
    </S.Container>
  );
}
