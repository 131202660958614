// @ts-nocheck
import React from 'react';
import {Button} from './Button';

const ButtonBlack = ({
  title = '',
  inverse = false,
  buttonStyle = {},
  titleStyle = {},
  containerStyle = {},
  ...buttonProps
}) => {
  if (inverse) {
    return (
      <Button
        {...buttonProps}
        title={title}
        buttonStyle={{backgroundColor: 'white', ...buttonStyle}}
        containerStyle={{backgroundColor: 'white', ...containerStyle}}
        titleStyle={{color: 'black', ...titleStyle}}
      />
    );
  }

  return (
    <Button
      {...buttonProps}
      title={title}
      buttonStyle={{backgroundColor: 'black', ...buttonStyle}}
      containerStyle={[{backgroundColor: 'white'}, containerStyle]}
      titleStyle={[{color: 'white'}, titleStyle]}
    />
  );
};

export default ButtonBlack;
