import { Selector } from "@reduxjs/toolkit"
import { deleteGoalsTranslations, getGoalsTranslations, updateGoalsTranslations, uploadGoalsTranslations } from "../goalsTranslations/goalsTranslations.actions"
import { selectGoalsTranslations, selectGoalTranslationById } from "../goalsTranslations/goalsTranslations.selectors"
import { TGoalTranslation } from "../goalsTranslations/goalsTranslations.types"
import { TTranslation } from "./translations.types"

enum TranslationMenuOptionsIds {
    seeAllTranslations = 'see_all_translations',
    addTranslation = 'addTranslation'
}

enum ERegisteredTranslations {
    goals = 'goals'
}

type TItemKeyType = 'simpleText' | 'richText'

type TKeyType = {
    fieldName: string, type: TItemKeyType
}

// todo: add typing 
const translationsItems: {
    [key in string]: {
        uploadActionCreator: (data: any) => void,
        updateActionCreator: (data: any) => void,
        deleteOneActionCreator: (data: any) => void,
        getAllSelector: Selector<any, TTranslation[]>,
        getOneSelector: <T>(store:any, id?: string, parentId?: string) => T | undefined,
        getAllActionCreator: () => any[],
        getPreviewComponentProps: <T>(item: T) => {lang: string, title: string, subtitle: string},
        getItemKeys: () => TKeyType[]
    }
} = {
    ['goals']: {
        uploadActionCreator: uploadGoalsTranslations,
        updateActionCreator: updateGoalsTranslations,
        deleteOneActionCreator: deleteGoalsTranslations,
        getAllSelector: selectGoalsTranslations,
        getOneSelector: selectGoalTranslationById,
        getAllActionCreator: getGoalsTranslations,
        getPreviewComponentProps: (item:TGoalTranslation) => {
            return {
                id: item.id,
                subtitle: item.description,
                lang: item.lang,
            }
        },
        getItemKeys: () => [
            {
                requiredField: true,
                fieldName: "description",
                type: 'simpleText'
            }
        ]
        // onPressEdit: 
    }
} as {[key in ERegisteredTranslations & any]: any}

const ALL_TRANSLATIONS_LANGUAGES = [
    {
      label: 'Українська',
      code: 'uk',
    },
    {
      label: 'English',
      code: 'en',
    },
    // {
    //   label: 'Polish',
    //   code: 'pl',
    // },
    // {
    //   label: 'Latvian',
    //   code: 'lv',
    // },
    // {
    //   label: 'Lithuanian',
    //   code: 'lt',
    // },
    // {
    //   label: 'Estonian',
    //   code: 'et',
    // },
  ];

export {
    translationsItems,
    TranslationMenuOptionsIds,
    ERegisteredTranslations,
    ALL_TRANSLATIONS_LANGUAGES
}