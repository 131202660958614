import {createCRUDActionCreators} from '../store/src/common';

export const {
  uploadTierTranslation,
  updateTierTranslation,
  updateTierTranslationFailed,
  updateTierTranslationSuccess,
  deleteTierTranslation,
  deleteTierTranslationSuccess,
} = createCRUDActionCreators('TierTranslation', '');
