import { createSelector, Selector } from '@reduxjs/toolkit';
import { find, values } from 'lodash';
import { getStore, TStore } from 'store';
import { IPostTranslation } from './postsTranslations.types';

// only for creators
export const selectPostTranslationById = createSelector(
    [
      getStore,
      (_: TStore, id?: string) => id,
      (__: TStore, _, parentId?: string) => parentId,
    ],
    (store, id, parentId) =>
      find(
        store.postsTranslations?.results,
        translation => translation?.id === id,
      ),
  );
  
  export const selectPostTranslations: Selector<TStore, IPostTranslation[]> = createSelector(
    [getStore, (_: TStore, parentId: string) => parentId],
    (store, parentId) => {
        let translationIds: string[] = values(store.posts.ownPosts.drafts[parentId]?.translations)
        translationIds = translationIds?.length ?  translationIds : values(store.posts.ownPosts.published[parentId]?.translations)
        console.log('translationIds',translationIds)
        let postTranslations = [];
        for(let id of translationIds){
            const tr = store?.postsTranslations?.results[id]
            if(tr){
                postTranslations.push(tr)
            }
        }
        return postTranslations
    }
  );

