import React from 'react';
import {Formik} from 'formik';
import {useSelector} from 'react-redux';
import {selectWithdrawMethodsById} from '../money-withdraw-methods/monet-withdraw-methods.selectors';
import {TStore} from 'store';
import {useDispatchAction} from 'hooks';
import {uploadCreatorMerchantEntrepreneurFinancial} from './creatorMerchant.actions';
import {i18n} from 'localize';
import {Button, FormInput, Text, View} from 'components';
import {ECreatorWithdrawMethodsTypes} from '../money-withdraw-methods/money-withdraw-methods.types';
import { ActionResult } from '../components/src/ActionResult/ActionResult.component';
import { ActivityIndicator } from 'react-native';

type TProps = {
  id?: string;
};

export const EditMerchantFinancialFormContainer: React.FC<TProps> = ({id}) => {
  const merchant = useSelector((state: TStore) =>
    selectWithdrawMethodsById(state, id || ''),
  );

  const {submitAction, error, isLoading} = useDispatchAction(
    uploadCreatorMerchantEntrepreneurFinancial,
  );

  if (merchant?.type !== ECreatorWithdrawMethodsTypes.merchant_entrepreneur) {
    return null;
  }

  return (
    <Formik
      initialValues={{
        //todo: think about renaming or replacing id with merchantId
        merchantId:merchant?.merchantId,
        iban: merchant?.financialConfig?.iban || '',
      }}
      // validationSchema={getMerchantEntrepreneurWithdrawMethodSchema()}
      onSubmit={({merchantId, iban}) => submitAction({
        merchantId,
        financialConfig: {
          configId: merchant?.financialConfig?.configId,
          iban
        }
      })}>
      {({values, errors, handleSubmit, handleChange}) => (
        <View>
          <FormInput
            multiline
            numberOfLines={1}
            label={i18n.t('creatorMerchantFinancial.ibanInputLabel')}
            value={values?.iban}
            placeholder={i18n.t(
              'creatorMerchantFinancial.ibanInputPlaceholder',
            )}
            onChangeText={handleChange('iban')}
            error={errors?.iban}
          />
          <Button
            title={i18n.t('submitEdit')}
            onPress={handleSubmit}
            primary
            containerStyle={{paddingVertical: 25}}
          />
          <ActionResult 
            error={error}
            isLoading={isLoading}
            renderLoading={() => (
              <ActivityIndicator />
            )}
            renderError={(err) => (
              <Text variant='inputError'>
                {err}
              </Text>
            )}
            renderSuccessMessage={() => (
              <Text variant='inputLabel'>
                {i18n.t("operationSuccess")}
              </Text>
            )}
          />
        </View>
      )}
    </Formik>
  );
};
