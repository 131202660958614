// Import the functions you need from the SDKs you need
import * as webfirebase from 'firebase/app';
import {logEvent as firebaseLogEvent, getAnalytics} from 'firebase/analytics';

import Config from '../config/config';
import {FirebaseApp} from 'firebase/app';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

//duplicated in app.json for this -https://docs.expo.dev/guides/using-firebase/

let app: FirebaseApp;

const firebaseConfig = {
  apiKey: Config.FIREBASE_API_KEY,
  authDomain: Config.FIREBASE_AUTH_DOMAIN,
  projectId: Config.FIREBASE_PROJECT_ID,
  storageBucket: Config.FIREBASE_STORAGE_BUCKET,
  messagingSenderId: Config.FIREBASE_MESSAGING_SENDER_ID,
  appId: Config.FIREBASE_APP_ID,
  measurementId: Config.FIREBASE_MEASUREMENT_ID,
};

// Initialize Firebase
// const app = rnfirebase.initializeApp(firebaseConfig);
// const perf = getPerformance(app);

const analyticsModule = () => ({
  logEvent(name: string, params?: any) {
    // rnfirebase.analytics().logEvent(name, params);
    firebaseLogEvent(getAnalytics(app), name, params);
  },
});

const crashlytics = () => ({
  setAttributes() {},
  recordError() {},
  setUserId() {},
  crash() {},
});

const init = async () => {
  app = webfirebase.initializeApp(firebaseConfig);
  // await Analytics.logEvent('test_event');
  // app.analytics().logEvent('test_event')
};

export const firebase = {
  analytics: analyticsModule,
  crashlytics,
  perf: {},
  // app,
  init,
};
