import React, { useEffect, useState } from 'react';
// import loadable from '@loadable/component';
import 'react-quill/dist/quill.snow.css';
import './customStyles.css'
import {TRichTextEditorProps} from './types';
import ReactQuill from 'react-quill';
import { isSafari } from 'utils';

// const ReactQuill = loadable(() => import('react-quill'));

export const RichEditor: React.FC<TRichTextEditorProps> = ({
  html,
  onChange,
  key,
  style = {},
  placeholder,
  ...otherProps
}) => {

  const richText = React.useRef<ReactQuill | null>(null);

  useEffect(() => {
    richText.current?.getEditor().clipboard?.dangerouslyPasteHTML?.(html);
  }, [])

  const toolbarOptions = [
    ['bold', 'italic', 'underline', 'strike'], // toggled buttons
    ['blockquote', 'code-block'],

    [{header: 1}, {header: 2}], // custom button values
    [{list: 'ordered'}, {list: 'bullet'}],
    [{script: 'sub'}, {script: 'super'}], // superscript/subscript
    [{indent: '-1'}, {indent: '+1'}], // outdent/indent
    [{direction: 'rtl'}], // text direction

    [{size: ['small', false, 'large', 'huge']}], // custom dropdown
    [{header: [1, 2, 3, 4, 5, 6, false]}],

    [{color: []}, {background: []}], // dropdown with defaults from theme
    [{font: []}],
    [{align: []}],

    ['clean'], // remove formatting button
  ];

  return (
    <ReactQuill
      ref={richText}
      //@ts-ignore
      {...otherProps}
      style={{
        flex:1,
        marginBottom: isSafari() ? 45 : 0, //todo: fix it 
        ...style,
      }}
      // theme="snow"
      placeholder={placeholder}
      modules={{
        toolbar: toolbarOptions,
      }}
      onChange={onChange}
    />
  );
};
