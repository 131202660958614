export const tiersTranslationsLocalization = {
  uk: {
    editTierTranslationScreen: {
      titleInputLabel: 'Заголовок',
      descriptionInputLabel: 'Опис',
    },
    creatorTiersTranslationScreen: {
      chooseTier: 'Виберіть підписку',
      chooseTierListSubtitle: "Список можна прокручувати горизонтально",
      addTranslationButtonTitle: '+ Додати переклад',
      noTranslation: 'Не вдалося знайти переклади',
      screenTitle: "Переклади для рівнів підписки",

    },
  },
  en: {
    editTierTranslationScreen: {
      titleInputLabel: 'Title',
      descriptionInputLabel: 'Description',
    },
    creatorTiersTranslationScreen: {
      chooseTier: 'Choose a tier',
      chooseTierListSubtitle: "List is scrollable",
      addTranslationButtonTitle: '+ Add translation',
      noTranslation: 'Translations are not found',
      screenTitle: "Tiers translations",
    },
  },
};
