import React from 'react';
import {View} from 'react-native';
import {PlaceholderLine} from 'rn-placeholder';
import {TPlaceholderProps} from './types';

export const PostPlaceholder: React.FC<TPlaceholderProps> = () => {
  return (
    <View>
      <PlaceholderLine
        style={[
          {
            width: '100%',
            height: 100,
          },
        ]}
      />
      <PlaceholderLine
        style={[
          {
            width: '100%',
            height: 300,
          },
        ]}
      />
    </View>
  );
};
