import {NetworkService} from '../services';
import {ApiRequest} from '../types';
import {
  TPostUploadUnauthorizedSubscriptionInput,
  TPostUploadUnauthorizedSubscriptionOutput,
} from './unathorized-subscrition.types';

export const postUploadUnauthorizedSubscription = async (
  inputs: TPostUploadUnauthorizedSubscriptionInput,
): ApiRequest<TPostUploadUnauthorizedSubscriptionOutput> => {
  console.log('inputs', inputs);
  return NetworkService.post('/v1/subscriptions/send-payment-email', inputs);
};

// export const updateUnauthorizedSubscription = async (
//     update: TUpdateUnauthorizedSubscriptionInput,
//   ): ApiRequest<TUpdateUnauthorizedSubscriptionOutput> => {
//     return NetworkService.put('/v1/creator/greetings', update);
//   };

// export const deleteUnauthorizedSubscription = async (
//   params: TDeleteUnauthorizedSubscriptionInput,
// ): ApiRequest<TDeleteUnauthorizedSubscriptionOutput> => {
//   return NetworkService.delete('/v1/creator/greetings', params);
// };

export const UnauthorizedSubscriptionApiService = {
  postUploadUnauthorizedSubscription,
  // updateUnauthorizedSubscription,
  // deleteUnauthorizedSubscription,
};
