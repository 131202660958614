import {Switch as RNSwitch, SwitchProps} from 'react-native';
import React from 'react';
import { colors } from '@sponsor/assets';

export const Switch: React.FC<SwitchProps> = ({...props}) => {
  return <RNSwitch 
      thumbColor={'white'} 
      trackColor={{true: colors.GREEN_LIGHT, false: colors.GRAY_LIGHT}} 
      {...props} 
    />;
};
