import {createSelector} from '@reduxjs/toolkit';
import { filter } from 'lodash';
import type {TStore} from 'store';
import {getStore} from 'store';
import { ECreatorWithdrawMethodsTypes } from './money-withdraw-methods.types';

export const selectWithdrawMethodsState = createSelector(
  getStore,
  store => store.creator.withdrawMethods,
);

export const selectCardWithdrawMethods = createSelector(
  selectWithdrawMethodsState,
  withdrawMethods => filter(withdrawMethods || [], w => w?.type == ECreatorWithdrawMethodsTypes.card)
)

export const selectWithdrawMethodsById = createSelector(
  [getStore, (_: TStore, id: string) => id],
  (store, id) => store.creator.withdrawMethods[id],
);
