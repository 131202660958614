import styled from 'styled-components/native';

import {responsiveSize} from '../../../../utils/font';

export const Container = styled.ImageBackground`
  width: 100%;
  height: ${responsiveSize(323)};

  padding: 0 ${responsiveSize(190)} ${responsiveSize(85)};

  flex-direction: row;

  align-items: flex-end;
  justify-content: space-between;

  ${props =>
    props.media.lg &&
    `
    height: auto;

    padding: ${responsiveSize(80)} ${responsiveSize(60)};
  `};

  ${props =>
    props.media.md &&
    `
    align-items: center;
  `};

  ${props =>
    props.media.sm &&
    `
    flex-direction: column;
    align-items: center;
   
    padding: ${responsiveSize(50)} ${responsiveSize(16)};

    background-color: #03384B;
  `};
`;

export const Logo = styled.Image`
  width: ${responsiveSize(314)};
  height: ${responsiveSize(71)};

  ${props =>
    props.media.sm &&
    `
    width: ${responsiveSize(314 / 1.3)};
    height: ${responsiveSize(71 / 1.3)};
    margin-bottom: ${responsiveSize(32)};
  `};
`;

export const Social = styled.View`
  flex-direction: row;
`;

export const SocialItem = styled.Image`
  height: ${responsiveSize(32)};
  width: ${responsiveSize(32)};

  margin-right: ${responsiveSize(20)};
`;
