import {createCRUDActionCreators} from '../store/src/common';

export const {
  uploadCreatorMerchantEntrepreneur,
  uploadCreatorMerchantEntrepreneurSuccess,
} = createCRUDActionCreators('CreatorMerchantEntrepreneur', '');

export const {
  uploadCreatorMerchantEntrepreneurFinancial,
  uploadCreatorMerchantEntrepreneurFinancialSuccess,
} = createCRUDActionCreators('CreatorMerchantEntrepreneurFinancial', '');
