import {FlatList, StyleSheet} from 'react-native';
import React, {useCallback, useMemo} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  updateCreatorStart,
  selectTiers,
  selectCreatorGreetingsArray,
  selectCreatorBasicInfo,
} from 'store';
import {Text, Checkbox, View, ContentContainer } from 'components';
import { i18n } from 'localize';
import { TCreatorGreetingType, ECreatorSettings } from 'types';
import { find, map } from 'lodash';
import { GreetingsFormContainer } from './GreetingsForm.container';

//todo: rewrite this screen
export const EditCreatorGreetings = () => {

  const creator = useSelector(selectCreatorBasicInfo);
  const greetings = useSelector(selectCreatorGreetingsArray);
  const tiers = useSelector(selectTiers);

  const dispatch = useDispatch();

  const changeGreetingsVisibility = useCallback(
    value => {
      dispatch(
        updateCreatorStart(
          {
            settings: {
              [ECreatorSettings.showGreetingsType]: value,
            },
          },
          {},
        ),
      );
    },
    [dispatch],
  );

  // const dispatchUploadGreetings = useCallback((greetings) => {
  //     dispatch(uploadGreetings(greetings))
  // }, [])

  const channelInfos = useMemo(() => {
    return [
      {
        greetingsId: find(greetings, g => g?.channel_name === 'general')?.id,
        channel_name: 'general',
        channel_type:  "email" as unknown as 'email',
        title: 'General',
        text: find(greetings, g => g?.channel_name === 'general')?.specs?.[0]?.v,
      },
      ...map(tiers, t => ({
        channel_name: t?.id,
        title: t?.title,
        greetingsId: find(greetings, v => v?.channel_name == t?.id)?.id,
        //todo: Think about retriving data
        channel_type: "email" as unknown as 'email',
        text:
          find(greetings, v => v?.channel_name == t?.id)?.specs?.[0]?.v || '',
      })),
    ];
  }, [tiers, greetings]);


  return (
    // <ContentContainer oneColumn>
      <View variant="screenContainer" style={{paddingBottom: 55}}>
        <FlatList
          data={channelInfos}
          showsVerticalScrollIndicator={false}
          ListHeaderComponent={() => (
            <>
              <Text variant="secondaryTitle">
                {i18n.t('editCreatorGreetings.screenTitle')}
              </Text>
              <Text variant='inputLabel'>
                {i18n.t("editCreatorGreetings.screenSubtitle")}
              </Text>
              <View variant='sectionContainer'>
                <Checkbox
                  title={i18n.t('editCreatorGreetings.showGeneralGreeting')}
                  onPress={() =>
                    changeGreetingsVisibility(TCreatorGreetingType.general)
                  }
                  value={
                    creator?.settings?.[ECreatorSettings.showGreetingsType] ===
                    TCreatorGreetingType.general
                  }
                  containerStyle={styles.checkbox}
                />
                <Checkbox
                  title={i18n.t('editCreatorGreetings.showTierSeparateGreeting')}
                  onPress={() =>
                    changeGreetingsVisibility(TCreatorGreetingType.perTier)
                  }
                  value={
                    creator?.settings?.[ECreatorSettings.showGreetingsType] ===
                    TCreatorGreetingType.perTier
                  }
                  containerStyle={styles.checkbox}
                />
              </View>
            </>
          )}
          keyExtractor={item => item?.channel_name}
          renderItem={({item}) =><GreetingsFormContainer 
            {...item}
            visible={
              (creator?.settings?.[ECreatorSettings.showGreetingsType] ===
                TCreatorGreetingType.general 
                && item?.channel_name === 'general') 
              ||
              (creator?.settings?.[ECreatorSettings.showGreetingsType] ===
                TCreatorGreetingType.perTier 
                && item?.channel_name !== 'general')}
          />}
        />
      </View>
  );
};

const styles = StyleSheet.create({
  checkbox: {
    marginTop: 8,
  },
});
