import React, {ReactChild} from 'react';
import {StyleSheet} from 'react-native';
import { View } from '../View';

interface IProps {
  rowCenter?: boolean;
  columnCenter?: boolean;
  containerStyle?: any;
  children?: any;
  fullWidth?: boolean;
  containsSimilarItems?: boolean;
  flex?: number;
  variant?:any,
  testID?: string,
  flexWrap?: string | boolean;
  childPaddingLeft?: number
}

export const Row: React.FC<IProps> = ({
  containerStyle = {},
  rowCenter,
  columnCenter,
  children,
  fullWidth,
  flexWrap,
  testID,
  flex,
  variant,
  containsSimilarItems = false,
  childPaddingLeft = 0
}) => {
  return (
    <View
      testID={testID}
      variant={variant}
      style={[
        styles.row,
        rowCenter && {
          justifyContent: 'center',
        },
        columnCenter && {
          alignItems: 'center',
        },
        fullWidth && styles.fullWidth,
        flexWrap && {flexWrap: 'wrap'},
        flex && {flex},
        containerStyle,
      ]}
    >
      {containsSimilarItems
        ? React.Children.map(children, (child: ReactChild) => (
            <View
              style={
                // index > 0
                // ? {padding: 10}
                {
                  paddingTop: 10, 
                  paddingRight: 10,
                }
              }
            >
              {childPaddingLeft && React.isValidElement(child) 
                ? React.cloneElement(child, {style: {paddingLeft: childPaddingLeft}}) 
                : child}
            </View>
          ))
        :  React.Children.map(children, (child: ReactChild) => React.isValidElement(child) ? React.cloneElement(child, childPaddingLeft ? {style: {paddingLeft: childPaddingLeft}} : {}): child)
        }
    </View>
  );
};

const styles = StyleSheet.create({
  row: {
    flexDirection: 'row',
  },
  fullWidth: {
    width: '100%',
  },
});
