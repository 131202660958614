import { Keyboard, Platform, StyleSheet, View } from 'react-native'
import React, { useCallback } from 'react'
import {
    uploadGreetings,
    updateGreetings,
  } from 'store';
import { Formik } from 'formik';
import { i18n } from 'localize';
import { ActionResult, Button, RichEditor, Text } from 'components';
import { decodeHTMLEntities, isMobile } from 'utils';
import { useDispatchAction } from 'hooks';
import { ActionResultDefaultRenders } from '../../../components/src/ActionResult/ActionResultDefaultRenders';

type TProps = {
    title: string,
    channel_name: string,
    channel_type: 'email',
    id?: string,
    text: string,
    visible: boolean
}

const GreetingsFormContainer:React.FC<TProps> = ({
    title,
    channel_name,
    channel_type,
    id,
    text = '',
    visible
}) => {

    const {
        error: updateError,
        isLoading: updateIsInProgress,
        submitAction: submitUpdateGreetings
    } = useDispatchAction(updateGreetings)

    const {
        error: uploadError,
        isLoading: uploadIsInProgress,
        submitAction: submitUploadGreetings
    } = useDispatchAction(uploadGreetings)

    const dispatchUpdateGreetings = useCallback(
        ({text, id, ...otherGreeingsInfo}) => {
        if (id) {
            submitUpdateGreetings([
                {
                    ...otherGreeingsInfo,
                    id,
                    specs: [{k: 'content', v: text}],
                },
            ]);
        } else {
            submitUploadGreetings([
                {
                    ...otherGreeingsInfo,
                    specs: [{k: 'content', v: text}],
                },
            ]);
        }
    },
    [submitUpdateGreetings, submitUploadGreetings],
  );

    return (
        <View>
            <Formik
                initialValues={{
                    channel_name,
                    channel_type,
                    id,
                    text,
                }}
                validate={values => {
                    if (!values.text) {
                        return {text: i18n.t('validationSchema.requiredField')};
                    }
                }}
                onSubmit={dispatchUpdateGreetings}
            >
            {({errors, values, handleSubmit, handleChange}) => (
                <View style={styles.formContainer}>
                    <View style={{paddingVertical: 5}}>
                        <Text variant="thirdTitle">{title}</Text>
                    </View>
                    <RichEditor
                        html={decodeHTMLEntities(values?.text || '')}
                        onChange={handleChange('text')}
                    />
                    <Text variant="inputError">{errors.text}</Text>
                    <Button
                        primary
                        containerStyle={styles.submitBtn}
                        title={i18n.t('saveSettings')}
                        onPress={handleSubmit}
                    />
                    {(!visible) && (
                    <View
                        style={[
                        {
                            ...StyleSheet.absoluteFillObject,
                        },
                        {
                            backgroundColor: 'rgba(222, 222, 222,0.5)',
                            borderRadius: 12,
                        },
                        ]}
                    />
                    )}

                    <ActionResult
                        isLoading={uploadIsInProgress || updateIsInProgress}
                        error={uploadError || updateError}
                        {...ActionResultDefaultRenders}
                    />
                </View>
                )}
            </Formik>
        </View>
    );
}

export {
    GreetingsFormContainer
}


const styles = StyleSheet.create({
    submitBtn: {
      // marginTop: 65
    },
    formContainer : {
        marginVertical: isMobile ? 5 : 15, //todo: remove for web too
        padding: 15
    }
});
  