import {TPayout} from './payment';
import {TComment} from './comments';
import {TProfile} from './user';
import {TCreator} from './creators';
import {TPost} from './posts';
import {TTier, TTierInResponse} from './tier';
import {
  TCollaborationAcceptanceStatus,
  TCreatorGoal,
  TCreatorStats,
  TSubscription,
} from '.';
import FormData from 'form-data';

export enum EErrorCodes {
  'DUBLICATED_DATA' = '11000',
}

export interface ErrorOutput {
  errorCode?: number;
  errorMessage?: string;
}

export interface AxiosResponse<T = any> {
  data: T;
  status: number;
  statusText: string;
  headers: any;
  config: any; // TODO: add axios config
  request?: any;
}

export type ApiRequest<T> = Promise<T>;

export type TSearchInput = {
  page: number;
  ids: string[];
  limit: number;
  lang?: string,
};

export type TSearchResult<T> = {
  results: T;
  page: number;
  limit: number;
  totalPages: number;
  totalResults: number;
};

export type TPostSignupInput = {
  email: string;
  password: string;
  name: string;
  last_name: string;
};

export type TPostSignupOutput = AxiosResponse<{
  user: TProfile;
  tokens: any;
}>;

export type TPostLoginInput = {
  email: string;
  password: string;
};

export type TPostLoginOutput = AxiosResponse<
  {
    user: any;
    tokens: {
      access: {
        token: string;
        expires: string;
      };
      refresh: {
        token: string;
        expires: string;
      };
    };
  } & ErrorOutput
>;

export type TPostPublishCreatorInput = Partial<TCreator> & {
  backgroundImage?: string;
  avatar?: string;
};

export type TPostPublishCreatorOutput = AxiosResponse<{
  creator?: TCreator;
  errorCode?: string;
}>;

export type TUpdateCreatorInput = Partial<
  Omit<TCreator, 'friends' | 'backgroundImage'>
> & {
  friends?: string[];
  backgroundImage?: string | FormData;
  avatar?: string;
};

export type TUpdateCreatorOutput = AxiosResponse<{
  creator?: TCreator;
  errorCode?: string;
}>;

export type TPostAddNewTierInput = Partial<TTier> & {
  image?: any;
};

export type TGetCreatorInput = {
  pageURL: string,
  lang: string
}

export type TGetCreatorOutput = AxiosResponse<{
  creator: TCreator;
  recentPosts: TPost[];
  tiers: TTier[];
}>;

export type TMyGetCreatorOutput = AxiosResponse<TCreator>;

export type TGetTierInput = {
  tierId: string;
  signal?: any;
};
export type TGetTierOutput = AxiosResponse<TTier>;

export type TPostAddNewTierOutput = AxiosResponse<TTier>;

export type TGetPostsOutput = AxiosResponse<{
  results: TPost[];
  page: number;
  limit: number;
  totalPages: number;
  totalResults: number;
}>;

export type TGetTiersInput = {
  pageURL: string;
};

export type TGetTiersOutput = AxiosResponse<TTierInResponse[]>;

export type TGetCreatorPostsInput = {
  pageURL: string;
};

export type TGetCreatorPostsOutput = AxiosResponse<{
  results: TPost[];
  page: number;
  count: number;
}>;

export type TGetPostOutput = AxiosResponse<TPost>;

export type TPostPostInput = TPost;
export type TPostPostOutput = AxiosResponse<TPost>;

export type TUpdatePostInput = Partial<
  TPost & {
    id: string;
  }
>;
export type TUpdatePostOutput = AxiosResponse<TPost>;

export type TUpdateTierInput = Partial<TTier> & {id: string};
export type TUpdateTierOutput = AxiosResponse<TTier>;

export type TDeleteTierInput = {id: string};
export type TDeleteTierOutput = AxiosResponse<null>;

export type TGetProfileOutput = AxiosResponse<TProfile>;

export type TDeleteSubscriptionInput = {
  subscriptionId: string;
};

export type TDeleteSubscriptionOutput = AxiosResponse<TProfile>;

export type TPostStartFollowingCreatorOutput = AxiosResponse<TProfile>;
export type TPostStopFollowingCreatorOutput = AxiosResponse<TProfile>;

export type TGetCreatorsOutput = AxiosResponse<TCreator[]>;

export type TPostToggleEmodjiInput = {
  emodjiUnicode: string;
  post: string;
};

export type TPostToggleEmodjiOutput = AxiosResponse<TPost>;


export type TWithdrawCreatorBalanceInput = {
  withdrawMethodId: string,
  currencyName: string,
}

export type TWithdrawCreatorBalanceOutput = AxiosResponse<TCreator>;

export type TUpdateProfileInput = Partial<TProfile> & {
  avatar?: string;
};
export type TUpdateProfileOutput = AxiosResponse<TProfile>;

// TODO: add generic
export type TGetCreatorSubscribersOutput = AxiosResponse<
  TSearchResult<TSubscription[]>
>;

export type TGetPostComments = AxiosResponse<TSearchResult<TComment[]>>;

export type TPostAddNewCommentInput = {
  text: string;
  post: string;
};

export type TPostAddNewCommentOutput = AxiosResponse<TComment>;

export type TPostForgotPasswordInput = {
  email: string;
  lang: string;
};

export type TPostForgotPasswordOutput = AxiosResponse<null>;

export type TPostResetPasswordInput = {
  password: string;
  token: string;
};

export type TPostResetPasswordOutput = AxiosResponse<null>;

export type TPostAccountDeletionInput = {
  refreshToken: string;
};

export type TPostAccountDeletionOutput = AxiosResponse<null>;


export type TGetPayoutsOutput = AxiosResponse<TSearchResult<TPayout[]>>;

export type TPostRefreshToken = AxiosResponse<{
  access: {
    token: string;
    expires: string;
  };
  refresh: {
    token: string;
    expires: string;
  };
}>;

//todo: remove partial
export type TPostAddNewCreatorGoalInput = Partial<TCreatorGoal>;
export type TPostAddNewCreatorGoalOutput = AxiosResponse<TCreatorGoal>;

export type TUpdateCreatorGoalInput = Partial<TCreatorGoal>;
export type TUpdateCreatorGoalOutput = AxiosResponse<TCreatorGoal>;

export type TDeleteCreatorGoalInput = {id: string};
export type TDeleteCreatorGoalOutput = AxiosResponse<TCreatorGoal>;

export type TGetCreatorStats = AxiosResponse<TCreatorStats>;

export type TPostChangeAcceptanceStatusInput = {
  status: TCollaborationAcceptanceStatus;
  id: string;
};
export type TPostChangeAcceptanceStatusOutput = AxiosResponse<TTierInResponse>;
