import React from 'react';
import {Image, Row, Text} from 'components';
import {ImageSourcePropType} from 'react-native';

type TProps = {
  icon: ImageSourcePropType;
  name: string;
};

export const CryptoCard: React.FC<TProps> = ({icon, name}) => {
  return (
    <Row>
      <Image
        source={icon}
        style={{width: 50, height: 50, resizeMode: 'contain'}}
      />
      <Text
        variant="formLabel"
        style={{
          marginLeft: 10,
        }}>
        {name}
      </Text>
    </Row>
  );
};
