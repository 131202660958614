import styled from 'styled-components/native';
import {Image as RNImage} from 'react-native';

import {responsiveSize} from '../../../../utils/font';

export const Container = styled.View`
  width: 100%;
  /* height: ${responsiveSize(677)}; */

  padding: ${responsiveSize(60)} ${responsiveSize(150)} ${responsiveSize(60)}
    ${responsiveSize(190)};
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  ${props =>
    props.media.lg &&
    `
    flex-direction: column-reverse;
    padding: ${responsiveSize(120)} ${responsiveSize(60)};
  `};

  ${props =>
    props.media.md &&
    `
   
    padding: ${responsiveSize(110)} ${responsiveSize(60)};
  `};

  ${props =>
    props.media.sm &&
    `
    padding: ${responsiveSize(90)} ${responsiveSize(16)};
  `};
`;

export const Image = styled(RNImage)`
  height: ${responsiveSize(561)};
  width: ${responsiveSize(690)};

  ${props =>
    props.media.lg &&
    `
    margin-bottom: ${responsiveSize(32)};
    height: ${responsiveSize(561 / 1.5)};
    width: ${responsiveSize(690 / 1.5)};
  `};

  ${props =>
    props.media.md &&
    `
    height: ${responsiveSize(561 / 1.2)};
    width: ${responsiveSize(690 / 1.2)};
    max-width: 100%;
  `};

  ${props =>
    props.media.sm &&
    `
    height: ${responsiveSize(561 / 2.2)};
    width: ${responsiveSize(690 / 2.2)};
  `};
`;

export const Content = styled.View`
  flex: 1;
  align-items: flex-start;

  ${props =>
    props.media.lg &&
    `
    flex: none;
    align-items: center;
  `};
`;

export const Item = styled.View`
  margin-bottom: ${responsiveSize(10)};

  flex-direction: row;
  align-items: center;
`;

export const Check = styled(RNImage)`
  height: ${responsiveSize(10)};
  width: ${responsiveSize(16)};

  margin-right: 14px;

  ${props =>
    props.media.md &&
    `
    height: ${responsiveSize(10 / 1.1)};
    width: ${responsiveSize(16 / 1.1)};
  `};

  ${props =>
    props.media.sm &&
    `
    height: ${responsiveSize(10 / 1.2)};
    width: ${responsiveSize(16 / 1.2)};
  `};
`;

export const Title = styled.Text`
  margin-bottom: ${responsiveSize(24)};

  font-weight: bold;
  font-size: ${responsiveSize(51)};
  line-height: ${responsiveSize(79)};
  letter-spacing: 0.03em;
  text-transform: uppercase;
  font-family: inherit;

  ${props =>
    props.media.lg &&
    `
    max-width: ${responsiveSize(700)};
    
    font-size: ${responsiveSize(51 / 1.4)};
    line-height: ${responsiveSize(79 / 1.4)};
    text-align: center;
  `};

  ${props =>
    props.media.md &&
    `
    font-size: ${responsiveSize(51 / 1.2)};
    line-height: ${responsiveSize(79 / 1.2)};
  `};

  ${props =>
    props.media.sm &&
    `
    margin-bottom: ${responsiveSize(16)};

    font-size: ${responsiveSize(51 / 2)};
    line-height: ${responsiveSize(79 / 2)};
  `};
`;

export const Description = styled.Text`
  font-size: ${responsiveSize(22)};
  line-height: ${responsiveSize(33)};
  letter-spacing: 0.03em;
  text-transform: uppercase;
  font-family: inherit;

  ${props =>
    props.media.md &&
    `
    font-size: ${responsiveSize(22 / 1.2)};
    line-height: ${responsiveSize(33 / 1.2)};
    
  `};

  ${props =>
    props.media.sm &&
    `
    font-size: ${responsiveSize(22 / 1.6)};
    line-height: ${responsiveSize(33 / 1.6)};
  `};
`;
