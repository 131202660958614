import React, { useMemo } from 'react';
import {useNavigation, useParams} from 'hooks';
import {useDispatchAction} from 'hooks';
import {updateTierTranslation} from './tiersTranslations.actions';
import {
  Button,
  ContentContainer,
  FormInput,
  FormInputLabel,
  LanguagePicker,
  RichEditor,
  ScrollViewOnlyOnMobile,
  Text,
  View,
} from 'components';
import {i18n} from 'localize';
import {Formik} from 'formik';
import {useSelector} from 'react-redux';
import {selectTierTranslationById} from './tiersTranslations.selectors';
import {selectTierById, TStore} from 'store';
import { decodeHTMLEntities, isMobile } from 'utils';
import { BackHeader } from '../containers/Header/BackHeader';
import { Platform } from 'react-native';
import { useTranslationsLanguages } from '../translations/useTranslationsLanguages';

export const EditTiersTranslationScreen = () => {
  const {id, parentId} = useParams();

  const {goBack} = useNavigation();
  const translationsLanguages = useTranslationsLanguages();

  const {isLoading, error, submitAction} = useDispatchAction(
    updateTierTranslation,
    {onSuccess: goBack}
  );

  //todo: add general naming, such as editing entity
  const editedEntity = useSelector((state: TStore) =>
    selectTierTranslationById(state, id, parentId),
  );
  const tier = useSelector((state:TStore) => selectTierById(state, parentId))
    
  const initialValues = useMemo(() => {
      return {
        lang: editedEntity?.lang || "uk",
        title: editedEntity?.title || "",
        description: editedEntity?.description || "",
        parentId,
        id: editedEntity?.id || '',
        // price: '',
        // currency:z
      }
  },[editedEntity, parentId])

  return (
    <ContentContainer>
      <ScrollViewOnlyOnMobile>
        {!isMobile && <BackHeader />}
        <Formik
          enableReinitialize
          initialValues={initialValues}
          // validationSchema={getTierTranslationValidationSchema()}
          onSubmit={submitAction}
      >
          {({values, handleChange, handleSubmit, errors}) => (
            <View style={{padding: 15}}>
              <View 
                variant="sectionContainer"
              >
                {/* primary keys cannot be changed in already created entity
                            that's why we disable language picker

                            todo: add helper function, hook,
                                think about unificaiton
                        */}
                {/* TODO: center or flex-start */}
                <Text center variant='thirdTitle'>
                  {tier?.title}
                </Text>
                <View style={{marginTop: 15,alignItems: 'center'}}>
                  <FormInputLabel
                    label={i18n.t('chooseLanguagePickerLabel')} 
                    requiredField
                  />
                  <LanguagePicker
                    value={values?.lang}
                    // todo: add test here
                    enabled={!editedEntity}
                    langs={translationsLanguages}
                    onLanguageChange={lang => handleChange('lang')(String(lang))}
                  />
                </View>
              </View>
              {!!values?.lang && (
                <>
                  <FormInput
                    label={i18n.t('editTierTranslationScreen.titleInputLabel')}
                    value={values.title}
                    numberOfLines={2}
                    onChangeText={handleChange('title')}
                    error={errors.title}
                  />
                  <Text variant="inputLabel">
                    {i18n.t('editTierTranslationScreen.descriptionInputLabel')}
                  </Text>
                    <RichEditor
                      html={decodeHTMLEntities(values.description)}
                      onChange={(descriptionText: string) => {
                        handleChange('description')(descriptionText);
                      }}
                    />
                  <Text variant="inputError">{errors?.description}</Text>

                  <Button
                    primary
                    disabled={isLoading}
                    isLoading={isLoading}
                    title={i18n.t('saveSettings')}
                    onPress={handleSubmit}
                  />
                  {error && <Text variant="inputError">{error}</Text>}
                </>
              )}

            </View>
          )}
        </Formik>
      </ScrollViewOnlyOnMobile>
    </ContentContainer>
  );
};
