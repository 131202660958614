import React, {ReactChildren} from 'react';
import {
  Platform,
  StyleSheet,
  useWindowDimensions,
  ViewStyle,
} from 'react-native';
import {DEVICE_HEIGHT, FOOTER_HEIGHT, HEADER_HEIGHT} from '@sponsor/constants';
import {Header} from '..';
import {Column} from '../Column';
import {Row} from '../Row';
import { KeyboardAvoidingView } from '..';

/**
 * web - padding, background color, mobile devices handle
 * mobile - KeyboardAvoidingView
 */
export const ContentContainer: React.FC<{
  children: ReactChildren & any;
  containerStyle?: ViewStyle;
  keyboardAvoidingView?: boolean
  showWebHeader?: boolean;
  oneColumn?: boolean,
}> = ({
  keyboardAvoidingView,
  oneColumn,
  children, 
  showWebHeader, 
  containerStyle = {}
}) => {
  const layout = useWindowDimensions();

  return (
    <>
      {showWebHeader && Platform.OS === 'web' && <Header />}
      <Row containerStyle={[contentContainerStyles.container, containerStyle]}>
        {!oneColumn && layout.width > 1000 && <Column flex={1} />}
        <Column
          containerStyle={{
            backgroundColor: 'white',
            //todo: remove styles outside this component, move to screenContainer
            paddingHorizontal: Platform.OS === 'web' ? 15 : 0,
          }}
          flex={3}
        >
          {/* 
              todo: auto detect inputs and use KeyboardAvoidingView only on some screen 
              with inputs 
          */}
          {keyboardAvoidingView !== false ? <KeyboardAvoidingView>
              {children}
          </KeyboardAvoidingView>: children}
        </Column>
        {!oneColumn && layout.width > 1000 && <Column flex={1} />}
      </Row>
    </>
  );
};

export const contentContainerStyles = StyleSheet.create({
  container: {
    paddingVertical: Platform.OS == 'web' ? 25 : 0, // todo: remove padding, use view screenContainer instead
    flex: 1,
    minHeight: DEVICE_HEIGHT - FOOTER_HEIGHT - HEADER_HEIGHT,
  },
});
