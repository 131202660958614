import {ContentContainer, Column, Row, Text, View} from 'components';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {
  ActivityIndicator,
  FlatList,
  Platform,
  ScrollView,
} from 'react-native';
import {useDispatch, useSelector} from 'react-redux';
import moment from 'moment';
import {forEach, isEmpty} from 'lodash';
import {LineChart} from 'react-native-chart-kit';

import {i18n} from 'localize';
import {ApiService} from 'services';
import {DEVICE_WIDTH} from '@sponsor/constants';
import {
  getCreatorStats,
  selectCreatorStats,
  getCreatorInfo,
} from 'store';
import {TPayout} from 'types';
import {isDesktop, LOG} from 'utils';
import {CreatorRevenueListContainer} from '../../../creator-revenue/CreatorRevenueList.container';

export const MyRevenue = () => {
  
  const creatorStats = useSelector(selectCreatorStats);

  const earnedAmountStats = useMemo(() => {
    const labels = new Array<string>();
    const data = new Array<any>();

    forEach(creatorStats?.results || [], s => {
      const month = moment(s?.startDate).month() || 12; // 0 - Грудень
      // const monthName = i18n.t('month.' + month);
      labels.push(`${month}`);
      data.push(s.paidAmount);
    });

    return {
      labels,
      data,
    };
  }, [creatorStats]);

  const [isPayoutsLoading, setIsPayoutsLoading] = useState(false);
  const [payouts, setPayouts] = useState<TPayout[]>([]);
  const subscribersIsNotEmpty = useMemo(() => !isEmpty(payouts), [payouts]);

  const dispatch = useDispatch();

  useEffect(() => {
    const fetchPayouts = async () => {
      try {
        setIsPayoutsLoading(true);
        const {data} = await ApiService.getCreatorPayouts();
        setPayouts(data.results);
      } catch (err) {
        LOG(err);
      } finally {
        setIsPayoutsLoading(false);
      }
    };

    dispatch(getCreatorStats());
    dispatch(getCreatorInfo(null, {}));
    fetchPayouts();
  }, [dispatch]);

  return (
    <ContentContainer>
      <ScrollView>
        <View style={{padding: 15}}>
          <Text variant="sectionTitle">
            {i18n.t('myRevenuePage.monthlyEarnings')}
          </Text>
          <Row
            containerStyle={{justifyContent: 'space-between', flexWrap: 'wrap'}}>
            <View>
              {/* <Text variant="sectionTitle">{`${
                creator?.balance?.amount || 0
              } ${i18n.t('currencies.uah')}`}</Text>
              <TouchableOpacity onPress={navigateToPaymentMethods}>
                <Text>{i18n.t('myRevenuePage.paymentMethodChange')}</Text>
              </TouchableOpacity> */}
              {/* <View variant="sectionContainer">
                <Button
                  onPress={withdraw}
                  containerStyle={{paddingVertical: 15}}
                  disabled={
                    isLoading ||
                    !creator?.balance?.amount ||
                    !creator.activePaymentMethodInfo ||
                    creator?.cardInfo?.length === 0
                  }
                  title={i18n.t('myRevenuePage.withdrawBalance')}
                />
                <Text variant="inputError">{error}</Text>
              </View> */}
            </View>
          </Row>

          {/* TODO: add custom chart */}
          {!isDesktop() && earnedAmountStats?.labels.length > 0 && (
            <LineChart
              data={{
                labels: earnedAmountStats.labels,
                datasets: [
                  {
                    data: earnedAmountStats.data,
                    strokeWidth: 2,
                  },
                ],
              }}
              
              renderDotContent={({x, y, index}) => (
                  <View
                    style={{
                      height: 24,
                      width: 24,
                      backgroundColor: "#abc",
                      position: "absolute",
                      top: y - 36, // <--- relevant to height / width (
                      left: x - 12, // <--- width / 2
                    }}
                  >
                    <Text style={{ fontSize: 10 }}>{ earnedAmountStats.data[index]}</Text>
                  </View>
              )}
              //todo: fix width
              onDataPointClick={() => {
                console.log('dot click')
              }}
              width={Platform.OS == 'web' ? DEVICE_WIDTH / 2 : DEVICE_WIDTH - 30}
              height={220}
              chartConfig={{
                backgroundColor: '#1cc910',
                backgroundGradientFrom: '#eff3ff',
                backgroundGradientTo: '#efefef',
                decimalPlaces: 2,
                color: (opacity = 1) => `rgba(0, 0, 0, ${opacity})`,
                style: {
                  borderRadius: 16,
                },
              }}
              style={{
                marginVertical: 8,
                borderRadius: 12,
              }}
            />
          )}

          <View variant='sectionContainer'>
            <ScrollView horizontal>
              <View variant="sectionContainer">
                <CreatorRevenueListContainer />
              </View>
            </ScrollView>
          </View>
          
          <View variant="sectionContainer">
            <Row columnCenter containsSimilarItems>
              <Text variant="sectionTitle">
                {i18n.t('myRevenuePage.yourPayouts')}
              </Text>
              {isPayoutsLoading && <ActivityIndicator />}
            </Row>
            <FlatList
              data={payouts}
              ListEmptyComponent={
                <Text center variant="secondaryTitle">
                  {i18n.t('myRevenuePage.noPayouts')}
                </Text>
              } // TODO: add variant - separator
              ItemSeparatorComponent={() => <View style={{paddingVertical: 5}} />}
              ListHeaderComponent={
                subscribersIsNotEmpty ? (
                  <Row containerStyle={{borderRadius: 5, paddingHorizontal: 10}}>
                    <Column flex={1}>
                      <Text variant="secondaryTitle">
                        {i18n.t('myRevenuePage.receiver')}
                      </Text>
                    </Column>
                    <Column flex={1.5}>
                      <Text variant="secondaryTitle">
                        {i18n.t('myRevenuePage.amount')}
                      </Text>
                    </Column>

                    <Column flex={1}>
                      <Text variant="secondaryTitle">
                        {i18n.t('myRevenuePage.dateOfWithdraw')}
                      </Text>
                    </Column>
                  </Row>
                ) : null
              }
              renderItem={({item}) => (
                <Row
                  containerStyle={{
                    borderWidth: 1,
                    borderRadius: 5,
                    padding: 10,
                  }}>
                  <Column flex={1}>
                    <Text>{item.receiver}</Text>
                  </Column>

                  <Column flex={1}>
                    <Text>{item?.withdrawAmount}</Text>
                  </Column>

                  <Column flex={1}>
                    <Text>{moment(item?.createdAt).format('lll')}</Text>
                  </Column>
                </Row>
              )}
            />
          </View>
        </View>
      </ScrollView>
    </ContentContainer>
  );
};
