import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {LayoutChangeEvent, StyleSheet, TouchableOpacity} from 'react-native';
import {default as RNCollapsive} from '../../../libs/src/react-native-collapsive/Collapsive';
import {isNull, isNumber, toNumber} from 'lodash';
import {Text, View} from 'components';
import {i18n} from 'localize';
import {colors} from '../../../assets';

type TProps = {
  collapsedHeight: number;
  showHideButton: boolean;
  // todo: replace with customization prop
  btnColor?: string;
  children: any;
};

export const Collapsible: React.FC<TProps> = ({
  collapsedHeight = 250,
  showHideButton,
  btnColor,
  children,
}) => {

  // collapsed be default
  const [contentHidden, setContentHidden] = useState<null | boolean>(null);
  const [contentHeight, setContentHeight] = useState<null | number>(null);

  // hide by default, if content is larger that initial threshold
  useEffect(() => {
    if (isNull(contentHidden) && !isNull(contentHeight)) {
      setContentHidden(contentHeight > collapsedHeight ? true : null);
    }
  },[contentHeight])

  const collapseHeight = useMemo(() => isNumber(contentHeight) && contentHeight < collapsedHeight
    ? contentHeight
    : collapsedHeight
  ,[collapsedHeight])

  // console.log('render')

  const renderButton = useCallback(() => (
    <View style={{justifyContent: 'flex-start', alignItems: 'flex-start'}}>
      <TouchableOpacity
        style={[styles.seeMoreBtn, {borderColor: btnColor}]}
        onPress={() => setContentHidden(prev => !prev)}
        disabled={showHideButton === false && (contentHidden || false)}
      >
        <Text
          color={btnColor || colors.GREEN_OFFICIAL}
          variant="thirdTitle"
        >
          {contentHidden === true ? i18n.t('seeMore') : i18n.t('hideText') }
        </Text>
      </TouchableOpacity>
    </View>
  ), [contentHidden]);

  const currentHeight = contentHidden ? collapseHeight : (contentHeight || undefined)

  const handleOnLayout = ({nativeEvent: {layout: {height}}}: LayoutChangeEvent) => {
      if(contentHeight === height){
        return;
      }
      
      if (isNull(contentHeight)) {
          setContentHeight(toNumber(height));
      }
  }

  return (
    <View>
          <RNCollapsive 
            collapsedHeight={collapsedHeight}
            duration={0}
            collapsed={contentHidden || false}
          >
            <View 
                onLayout={handleOnLayout} 
            >
              {children}
            </View>
        </RNCollapsive>
        {!isNull(contentHidden) && renderButton()}
    </View>
  );
};

const styles = StyleSheet.create({
  seeMoreBtn: {
    padding: 8,
    marginTop: 10,
    borderRadius: 12,
    borderWidth: 1,
    alignItems: 'flex-start',
  },
});
