import {StyleSheet, TouchableOpacity} from 'react-native';
import React from 'react';
import {Collapsible, RenderHtml, Row, Text, View} from 'components';
import {i18n} from 'localize';
import {decodeHTMLEntities} from 'utils';
import {colors} from '@sponsor/assets';
import { IPostTranslation } from './postsTranslations.types';
import { ConfirmationDialog } from '../components/src/ConfirmationDialog';

type TProps = {
  onEditPress: () => void;
  onDeletePress: () => void;
};

export const TranslationEditPreview: React.FC<TProps & Partial<IPostTranslation>> = ({
  title,
  text,
  onEditPress,
  lang,
  onDeletePress,
}) => {
  return (
    <View style={styles.container}>
      <Row containsSimilarItems>
        <ConfirmationDialog
              title={i18n.t("dialogs.deletionConfirmationDialogTitle")}
              desc={i18n.t("dialogs.confirmationDialogTitle")}
              onPress={onDeletePress}
              render={({onPress}) => (
                <TouchableOpacity onPress={onPress}>
                  <Text variant='dangerous'>{i18n.t('delete')}</Text>
              </TouchableOpacity>
            )}
        />
        <TouchableOpacity onPress={onEditPress}>
          <Text>{i18n.t('edit')}</Text>
        </TouchableOpacity>
        {/* {visible && <TouchableOpacity  onPress={onViewPress}>
          <Text>{i18n.t('view')}</Text>
        </TouchableOpacity>} */}
      </Row>
      <View variant='sectionContainer'>
        <Text>{lang}</Text>
        <Text variant="sectionTitle">{title}</Text>
        {/* <Text>{text}</Text> */}
        {!!text && <Collapsible showHideButton collapsedHeight={150}>
          <RenderHtml source={decodeHTMLEntities(text || '')} />
        </Collapsible>}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    borderWidth: 1,
    borderColor: colors.DUSTY_GRAY,
    padding: 15,
  },
});
