import React from 'react';
import {StyleSheet} from 'react-native';
import {Placeholder, PlaceholderLine, Fade} from 'rn-placeholder';
import {Column} from '../Column';
import {Row} from '../Row';
import {PostPlaceholder} from './PostPlaceholder';
import {ProfilePlaceholder} from './ProfilePlaceholder';

export const CreatorPostsLoading = () => {
  return (
    <Placeholder Animation={Fade}>
      <PlaceholderLine style={{borderRadius: 0, height: 350}} />
      <Row>
        <Column rowCenter flex={2}>
          <ProfilePlaceholder />
        </Column>
        <Column containerStyle={styles.contentContainer} flex={4}>
          <PostPlaceholder />
        </Column>
      </Row>
    </Placeholder>
  );
};

const styles = StyleSheet.create({
  contentContainer: {
    padding: 20,
  },
});
