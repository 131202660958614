import React from 'react';

import {Button} from '../../../../components';

import * as S from './home-share.styled';

import useMedia from '../../../../hook/media';

import {i18n} from 'localize';
import {useNavigation} from 'hooks';
import {EscreenNames} from 'navigation';
import {Config} from 'libs';
import { isDesktop } from '../../../../../../../utils/platform';
import { LOGO_BASE64_URI } from '@sponsor/constants';

export default function HomeShareView() {
  const media = useMedia();

  const {navigate} = useNavigation();

  return (
    <S.Container media={media}>
      <S.Content media={media}>
        <S.Title media={media}>{i18n.t('landing.shareSubscription')}</S.Title>

        <S.Description media={media}>
          {i18n.t('landing.shareYouAreOnSponsorFinancial')}
        </S.Description>

        <Button
          title={i18n.t('landing.joinUs')}
          onPress={() => navigate(EscreenNames.Signup)}
          theme="green"
          size="big"
        />
      </S.Content>

      <S.Image
        source={{
              uri: `${Config.STATIC_IMAGES_URL}/landing/share.webp`,
        }}
        loading="lazy"
        resizeMode="contain"
        media={media}
      />
    </S.Container>
  );
}
