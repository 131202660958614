import React, {useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {selectUser, updateProfile, updateProfileSuccess} from 'store';
import {View} from 'components';
import {LanguagePicker as LanguagePickerComponent} from '../../components/src/LanguagePicker';
import {useActiveLanguage} from 'hooks';
import { Platform } from 'react-native';
import { alertMessage } from 'utils';
import { i18n } from 'localize';

export function LanguagePicker({style = {}, short = true}) {
  const activeLocale = useActiveLanguage();
  const dispatch = useDispatch();
  const userId = useSelector(selectUser)?.id;

  const changeLanguage = useCallback(
    lang => {
      if (lang === activeLocale) {
        return;
      }

      if (userId) {
        dispatch(
          //todo: fix getProfile and change profile actions
          /**
           * add loading check for all actions
           */
          updateProfile(
            {lang},
            {
              // updateLocally: true,
              onFinish: () => {
                alertMessage(
                  i18n.t("languages.restartAppAlertTitle"), 
                  i18n.t("languages.restartAppAlertMessage")
                )
                // setTimeout(window.location.reload, 500);
              },
            },
          ),
        );
      } else {
        dispatch(updateProfileSuccess({lang}));
        alertMessage(
          i18n.t("languages.restartAppAlertTitle"), 
          i18n.t("languages.restartAppAlertMessage")
        )
      }
      if(Platform.OS === 'web'){
        setTimeout(() => window.location.reload(), 1500);
      }else{
        //todo: 
      }
    },
    [dispatch, userId, activeLocale],
  );

  return (
    <View style={style}>
      <LanguagePickerComponent
        langs={['uk','en']}
        onLanguageChange={changeLanguage}
        value={activeLocale}
        short={short}
      />
    </View>
  );
}