import React from 'react';
import {FlatList, StyleSheet} from 'react-native';
import {useSelector} from 'react-redux';
import {Text, TierEditing, View} from 'components';
import {EscreenNames} from 'navigation';
import {useNavigation} from 'hooks';
import {selectActiveSubscriptions} from 'store';
import {i18n} from 'localize';
import { useMedia } from '../../../hooks/useMedia';

export const ActiveSubscriptions = () => {
  const activeSubscriptions = useSelector(selectActiveSubscriptions);
  const {navigate} = useNavigation();

  const media = useMedia()

  return (
    <View style={{flex: 1, alignItems: 'center'}}>
      <FlatList
        data={activeSubscriptions}
        showsVerticalScrollIndicator={false}
        // TODO: prevent bugs in future, no need to wrap on small screens
        contentContainerStyle={[styles.contentContainer,  media.sm  ? {flexDirection: 'column'}: {}]}
        ListEmptyComponent={
          <Text center variant="secondaryTitle">
            {i18n.t('activeSubscriptions.noSubscriptions')}
          </Text>
        }
        // ItemSeparatorComponent={() => <View variant="verticalListSeparator"/>}
        renderItem={({item}) => (
          <View style={{margin: 10}}>
            <TierEditing
              {...item.tier || {}}  
              onEdit={() => {
                  navigate(EscreenNames.CancelSubscription, {
                    tierId: item?.tier?.id,
                  });
              }}
              // TODO: remove additional prop - price or rename, because of confusing
              price={item.price}
            />
          </View>
        )}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  contentContainer: {
    flex:1,
    flexDirection: 'row', 
    flexWrap: 'wrap',
  }
})