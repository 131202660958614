import React from 'react';
import { ImageStyle } from 'react-native';
import { LOGO_TRANSPARENT_URI} from '../../../constants';
import {Image} from '../Image/Image';

type TProps = {
  imageStyle?: ImageStyle
}

export const Logo:React.FC<TProps> = ({imageStyle}) => {
  return (
    <Image
      variant="logo"
      source={{
          uri: LOGO_TRANSPARENT_URI,
      }}
      style={imageStyle}
    />
  );
};
