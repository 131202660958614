import {View, FlatList, StyleSheet, Platform, FlatListProps} from 'react-native';
import React from 'react';
import {Tier} from '.';
import {TTier} from 'types';
import { TIER_WIDTH } from '@sponsor/constants';

const TIERS_CONTAINER_MARGIN_LEFT = 50;
const TIER_SEPARATOR_WIDTH = 25;

type TProps = {
  data: TTier[];
};

export const TierList: React.FC<TProps & FlatListProps<TTier>>  = ({
  data, 
  horizontal = true,
  renderItem,
  ...otherProps
}) => {

  const tierMinWidth =
  data?.length * TIER_WIDTH + data?.length * TIER_SEPARATOR_WIDTH;

  return (
    <FlatList
      {...otherProps}
      data={data}
      horizontal={horizontal}
      accessible
      testID="tiers-list"
      accessibilityLabel="Creator's paid subscriptions"
      showsHorizontalScrollIndicator
      centerContent={true}
      style={{
          //@ts-ignore
          outline: 0
      }}
      contentContainerStyle={[
        // styles.tiersContentContainer,
        {
          minWidth: tierMinWidth,

        },
      ]}
      indicatorStyle="black"
      pagingEnabled
      keyExtractor={item => item?.id}
      renderItem={renderItem}
    />
      // <View style={styles.tierContainer}>
      //   <FlatList
      //     data={tiers}
      //     horizontal={false}
      //     pagingEnabled

      //     accessible
      //     accessibilityLabel="Creator's paid subscriptions"
      //     showsHorizontalScrollIndicator
      //     centerContent={true}
      //     // style={{
      //     //     //@ts-ignore
      //     //     outline: 0
      //     // }}
      //     // contentContainerStyle={[
      //     //   styles.tiersContentContainer,
      //     //   {
      //     //     minWidth:
      //     //       tiers?.length * TIER_WIDTH + tiers?.length * TIER_SEPARATOR_WIDTH,
      //     //   },
      //     // ]}
      //     indicatorStyle="black"
      //     keyExtractor={item => item?.id}
      //     ItemSeparatorComponent={() => <View style={{width: 10}} />}
      //     renderItem={({item}) => <Tier {...item} />}
      //   />
      // </View>
  );
};

const styles = StyleSheet.create({
  tierContainer: Platform.select({
    web: {
      paddingTop: 50,
      paddingBottom: 25,
    },
    default: {
      marginRight: 10,
      marginLeft: TIERS_CONTAINER_MARGIN_LEFT,
    },
  }),
  tiersContentContainer: {
    outline: 'none',
    justifyContent: 'center',
    flex: 1,
    // maxWidth: DEVICE_WIDTH - TIERS_CONTAINER_MARGIN_LEFT,
    // justifyContent:'center',
  },
});
