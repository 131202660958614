import { i18n } from "localize";
import { alertMessage } from "utils";
import { Clipboard } from "../utils/Clipboard/Clipboard";

const onSharePress = ({text}: {text:string}) => {
    Clipboard.setString(text);
    alertMessage(i18n.t('operationSuccess'), i18n.t('linkCopied'));
}

export {
    onSharePress
}