import {reduce, sortedUniqBy} from 'lodash';
import {createSelector, createSlice, Selector} from '@reduxjs/toolkit';
import {takeEvery} from 'redux-saga/effects';
import {createAction} from 'typesafe-actions';
import {TPost} from 'types';
import {
  fetchSaga,
  TInFeedPostsState,
  CLEAR_STORE,
  TMetaInput,
  TStore,
} from '../common';

import {ApiService} from 'services';

const initialState: TInFeedPostsState = {
  page: 0,
  results: {},
  limit: 0,
  totalPages: 0,
  totalResults: 0,
};

export const inFeedPostsSlice = createSlice({
  name: 'infeedPosts',
  initialState,
  reducers: {
    // ['togglePostEmodjiSuccess']: (
    //   state: TInFeedPostsState,
    //   {payload: {userId, postId, emodjiUnicode}},
    // ) => {
    //   let post = state?.posts?.results?.[postId];
    //   if (post) {
    //     const emodjiUsers = post.reactions?.emodji?.[emodjiUnicode] || [];
    //     const hasUserLeftEmodji =
    //       findIndex(emodjiUsers, emodjiUser => emodjiUser === userId) > -1;
    //     const nbReactions = post.reactions?.nbReactions?.emodjiUnicode || 0;
    //     if (hasUserLeftEmodji) {
    //       post = set(
    //         post,
    //         `reactions.emodji.${emodjiUnicode}`,
    //         filter(emodjiUsers, eu => eu !== userId),
    //       );
    //       post = set(
    //         post,
    //         `reactions.nbReactions.${emodjiUnicode}`,
    //         nbReactions - 1,
    //       );
    //     } else {
    //       post = set(post, `reactions.emodji.${emodjiUnicode}`, [
    //         ...emodjiUsers,
    //         userId,
    //       ]);
    //       post = set(
    //         post,
    //         `reactions.nbReactions.${emodjiUnicode}`,
    //         nbReactions + 1,
    //       );
    //     }
    //   }
    //   state.posts = {...state.posts, [postId]: post};
    // },
    getInfeedPostsSuccess: (state, action) => {
      if (action?.payload?.page > state?.page) {
        const allFetchedPosts = sortedUniqBy(
          [...Object.values(state.results), ...(action.payload?.results || [])],
          'createdAt',
        );

        const postsObject = reduce<TPost, {[key in string]: TPost}>(
          allFetchedPosts,
          (allPosts, p) => {
            allPosts[p?.id] = p;
            return allPosts;
          },
          {},
        );
        state.results = postsObject;
      } else {
        //
        const postsObject = reduce<TPost, {[key in string]: TPost}>(
          action?.payload?.results,
          (s, p) => {
            s[p?.id] = p;
            return s;
          },
          {},
        );
        state.results = postsObject;
      }

      state.page = action?.payload?.page;
      state.limit = action?.payload?.limit;
      state.totalPages = action?.payload?.totalPages;
      state.totalResults = action?.payload?.totalResults;
      return state;
    },
    [CLEAR_STORE]: () => {
      return initialState;
    },
  },
});

export const GET_POSTS = 'infeedPosts/getInfeedPosts';

// Action creators are generated for each case reducer function
export const {getInfeedPostsSuccess} = inFeedPostsSlice.actions;

export const getPosts = createAction(GET_POSTS)<
  {
    page?: number;
    limit?: number;
    lang?: string
  },
  TMetaInput
>();

export default inFeedPostsSlice.reducer;

// selectors
export const getInFeedPostsState = (state: TStore) => state?.posts?.inFeedPosts;

export const selectInfeedPostById:Selector<TStore, TPost | undefined> = createSelector(
  [getInFeedPostsState, (state: TStore, postId: string): string => postId],
  (postState, postId) => {
    return postState?.results?.[postId];
  },
);

//todo: rename
export const selectCanUserLeaveComment = createSelector(
  [selectInfeedPostById],
  post => {
    return post?.unlocked !== false;
  },
);

export const selectInfeedPostComments = createSelector(
  [selectInfeedPostById],
  post => {
    // todo: fix typing, post could be null, why ???
    return post?.comments;
  },
);

export function* infeedPostsFlow() {
  yield takeEvery(getPosts, fetchSaga(ApiService.getUserPosts));
}
