import {TSubscription} from './subscriptions';
import {TCreator} from './creators';

export type TProfile = {
  id: string;
  name: string;
  last_name: string;
  activeSubscriptions: TSubscription[];
  deletedButActiveSubscriptions: TSubscription[];
  followings: TCreator[];
  orderIds: {[key in string]: string};
  email: string;
  isEmailVerified: boolean;
  avatarUri?: string;
  lang: ELanguages;
  settings: {
    [key in EUserSettingsKeys]: any
  }
};

export enum ELanguages {
  en = 'en',
  uk = 'uk',
}


export enum EUserSettingsKeys {
  biometryEnabled = 'biometryEnabled'
}

