import { selectCurrentPricingPlan } from '../../../pricingPlans/pricingPlans.selectors';
import { ContentContainer, Text } from 'components'
import React from 'react'
import { useSelector } from 'react-redux'
import { i18n } from 'localize';
import { useParams } from 'hooks';
import { TStore } from 'store';
import { find, pick } from 'lodash';

//todo: add pricing plan retriving from server logic 
export const PricingPlanScreen = () => {

    const {
        id
    } = useParams();

    // todo: think about - pricing plan is it a creator feature or general for all users
    const creatorPricingPlan = useSelector((state:TStore) => selectCurrentPricingPlan(state, id));
    const instantPaymentFee = pick(
        find(
            creatorPricingPlan?.units, 
            u => u?.unitCode === 'paid_subscription_split_payment'
        ), ['priceType', 'price']
    )
    const paymentFee = pick(
        find(
            creatorPricingPlan?.units, 
            u => u?.unitCode === 'paid_subscription'
        ), ['priceType', 'price']
    )
    
    console.log('instantPaymentFee',instantPaymentFee);

    return (
        <ContentContainer>
            <Text center variant="secondaryTitle" style={{paddingVertical: 7}}>
                {`${i18n.t("pricingPlan")}: ${i18n.t(`pricingPlansTitles.${creatorPricingPlan?.codeName}`)}`}
            </Text>

            {instantPaymentFee?.price && <Text variant='inputLabel' center>
                {`${i18n.t("pricingPlanScreen.instantPaymentFee")}: ${instantPaymentFee?.price} ${instantPaymentFee?.priceType === 'fee' ? '%' : ""}`}
            </Text>}

            {paymentFee?.price && <Text variant='inputLabel' center>
                {`${i18n.t("pricingPlanScreen.paymentFee")}: ${paymentFee?.price} ${paymentFee?.priceType === 'fee' ? '%' : ""} ${i18n.t('pricingPlanScreen.paymentSystemFee')}`}
            </Text>}

        </ContentContainer>
    )
}
