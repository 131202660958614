import {Icon} from 'components';
import React from 'react';
import {StyleSheet, TextStyle, ViewStyle} from 'react-native';
import { textStyles } from 'components/src/Text/Text'
// import {ListItem as RNListItem} from 'react-native-elements/dist/list/ListItem';
import {ListItem as RNListItem, ListItemProps} from 'react-native-elements';

type TProps = {
  titleStyle?: TextStyle;
  title: string;
  subtitle?: string;
  subtitleStyle?: TextStyle;
  chevron?: any;
  // onPress?: () => void;
  // bottomDivider?: boolean;
  // containerStyle?: ViewStyle;
  // disabled?: boolean;
  RightElement?: JSX.Element
  LeftELement?:  JSX.Element
};

export const ListItem: React.FC<TProps & Partial<ListItemProps>> = ({
  titleStyle = {},
  title,
  subtitle,
  subtitleStyle = {},
  chevron,
  RightElement,
  LeftELement,
  children,
  ...props
}) => {
  return (
    //@ts-ignore
    <RNListItem 
      {...props}
    >
      {LeftELement}
      <RNListItem.Content>
        {title && <RNListItem.Title style={[styles.title, textStyles.fontFamily,  titleStyle]}>
          {title}
        </RNListItem.Title>}
        {subtitle && <RNListItem.Subtitle style={[styles.title, textStyles.fontFamily, subtitleStyle]}>
          {subtitle}
        </RNListItem.Subtitle>}
      </RNListItem.Content>
      {RightElement}
      {!!chevron && <Icon name="chevron_right" />}
    </RNListItem>
  );
};

const styles = StyleSheet.create({
  title: {
    color: 'black',
  },
});
