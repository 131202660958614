import React from 'react';
import {
  Platform,
  KeyboardAvoidingView as RNKeyboardAvoidingView,
} from 'react-native';

export const KeyboardAvoidingView = ({children}: {children: any}) => {
  if (Platform.OS === 'web') {
    return children;
  }

  return (
    <RNKeyboardAvoidingView
      // height doesn't work well on android
      behavior={'padding'}
      contentContainerStyle={{flex: 1}}
      keyboardVerticalOffset={Platform.OS === 'android' ? -75 : 65} // todo: check on all platforms
      style={{flex: 1}}
    >
      {children}
    </RNKeyboardAvoidingView>
  );
};
