import React, {useMemo, useState} from 'react';
import {ActivityIndicator, Platform, ScrollView} from 'react-native';
import {View, LanguagePicker, Button, Text, FormInputLabel, ContentContainer, Row, TFormInputInfo, renderFormInput} from 'components';
import {Formik} from 'formik';
import {i18n} from 'localize';
import {useSelector} from 'react-redux';
import {deleteTranslation, selectTranslationState, updateTranslation} from 'store';
import {decodeHTMLEntities, isMobile} from 'utils';
import {getTranslationValidationSchema} from './translationValidationSchema';
import {useDispatchAction, useNavigation} from 'hooks';
import { omit } from 'lodash';
import { ActionResult } from '../../../components/src/ActionResult/ActionResult.component';
import { TouchableOpacity } from 'react-native';
import { EscreenNames } from 'navigation';
import { ConfirmationDialog } from 'components/src/ConfirmationDialog';
import { useTranslationsLanguages } from '../../../translations/useTranslationsLanguages';

export const EditCreatorTranslations = () => {
  const translatiosObject = useSelector(selectTranslationState);
  const {navigate} = useNavigation();
  const translationsLanguages = useTranslationsLanguages();
  //todo: change default language
  const [shownLanguage, setShownLanguage] = useState('en');

  const {isLoading, error, submitAction} = useDispatchAction(updateTranslation);
  const {
    isLoading:deleteIsInProgress, 
    error:deleteError, 
    submitAction:submitDeleteTranslation
  } = useDispatchAction(deleteTranslation, {
    onSuccess: () => navigate(EscreenNames.CreatorEditTranlsation)
  });

  let initialValues = useMemo(() => {
    let translationData = {
      ...(translatiosObject?.[shownLanguage] || {}),
      // todo: prevent bugs in future, not correct initial values
      subtitle: translatiosObject?.[shownLanguage]?.subtitle || "", 
    };

    translationData.description = decodeHTMLEntities(
      translationData?.description || '',
    );
    translationData = omit(translationData, '_id')
    return translationData;
  }, [shownLanguage, translatiosObject?.[shownLanguage]]);
  

  const fields:TFormInputInfo[] = [
    {
      name: "subtitle",
      type: 'text',
      label:i18n.t('editCreatorPage.subtitleLabel'),
      placeholder:i18n.t('editCreatorPage.subtitlePlaceholder'),
      requiredField: true,
      numberOfLines: 2
    },
    {
      name: "description",
      type: 'richText',
      label:i18n.t('editCreatorPage.descriptionLabel'),
      requiredField: true
    }
  ];

  return (
    <ContentContainer 
      oneColumn // todo: make all identical
    >
        <ScrollView>
            {/* TODO: think how to reuse languagePicker container styling */}
            <View style={{alignItems: Platform.OS !== 'web' ? 'center' : 'flex-start'}}>
              <FormInputLabel
                label={i18n.t('chooseLanguagePickerLabel')} 
                requiredField
              />
              <LanguagePicker
                value={shownLanguage}
                mode="dialog"
                langs={translationsLanguages}
                onLanguageChange={lang => setShownLanguage(String(lang))}
              />
            </View>

            <Formik
              enableReinitialize
              initialValues={initialValues}
              validationSchema={getTranslationValidationSchema()}
              onSubmit={values =>
                submitAction({
                  ...values,
                  lang: shownLanguage,
                })
              }>
              {({values, touched, isSubmitting, setSubmitting, setTouched, handleChange, handleSubmit, errors}) => (
                <View style={{padding: 15, flex: 1}}>
                  {fields.map(field => (
                    renderFormInput({
                      ...field,
                      value: values?.[field.name as unknown as keyof typeof  values],
                      handleChange: (data:any) => {
                        if(field.name){
                          setTouched({[field.name]: true})
                        }
                        return handleChange(field.name)(data?.value)
                      },
                      error: (touched?.[field?.name as unknown as keyof typeof  values] || isSubmitting) 
                        ? errors?.[field?.name  as unknown as keyof typeof errors] 
                        : " ",
                    })
                  ))}
                  <View style={!isMobile ? {} : {marginTop: 15}}>
                    <Button
                      primary
                      // disabled={isEqual(values, {
                      //   title: creator.title,
                      //   description: creator.description,
                      // })}
                      disabled={isLoading}
                      title={i18n.t('saveSettings')}
                      onPress={() => {
                        handleSubmit()
                        setSubmitting(true)
                      }}
                    />
                    {initialValues?.description && (
                      <ConfirmationDialog
                          title={i18n.t("dialogs.deletionConfirmationDialogTitle")}
                          desc={i18n.t("dialogs.confirmationDialogTitle")}
                          onPress={() => submitDeleteTranslation({id:initialValues.id})}
                          render={({onPress}) => (
                            <TouchableOpacity onPress={onPress}>
                              <View variant='sectionContainer'>
                                <Text variant='dangerous'>
                                  {i18n.t("delete")}
                                </Text>
                              </View>
                            </TouchableOpacity>
                        )}
                    />)}
                  </View>
                  <ActionResult 
                    isLoading={isLoading}
                    error={error}
                    renderLoading={() => (
                      <ActivityIndicator />
                    )}
                    renderError={(error) => (
                        <Text variant="inputError">{error}</Text>
                    )}
                    renderSuccessMessage={() => (
                      <Text variant='formHint'>
                          {i18n.t('operationSuccess')}
                      </Text>
                    )}
                  />
                </View>
              )}
            </Formik>
        </ScrollView>
    </ContentContainer>
  );
};
