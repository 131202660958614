import {
  ContentContainer,
  Button,
  FormInput,
  Image,
  Text,
  View,
  KeyboardAvoidingView,
  ScrollViewOnlyOnMobile,
  ActionResult,
} from 'components';
import {Formik} from 'formik';
import React, {useCallback, useMemo, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {i18n} from 'localize';
import {getProfileSettingsValidationSchema} from './profileSettingsValidationSchema';
import {colors} from '@sponsor/assets';
import {updateProfile, selectUser} from 'store';
import { Platform, TouchableOpacity } from 'react-native';
import { getAppVersion, isDesktop } from 'utils';
import { useImagePicker } from '../../../hooks/useImagePicker';
import { ActionResultDefaultRenders } from 'components/src/ActionResult/ActionResultDefaultRenders';

export const ProfileSettings = () => {
  const user = useSelector(selectUser);
  const initialFormValues = useMemo(() => {
    return {
      name: user?.name,
      last_name: user?.last_name,
      email: user?.email,
    };
  }, [user]);

  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);

  const {openPicker, image: avatarImage, error:avatarError} = useImagePicker({
    initial: {uri: user?.avatarUri},
    base64: true,
    aspect: [1,1],
    maxHeight: 2000,
    minHeight: 100,
    maxWidth: 2000,
    minWidth: 100,
  })

  const [error, setError] = useState(null);

  const updateUserProfile = useCallback(
    values => {
      // if(!avatarImage?.uri){
      //   return setError(l10n.errors.profileImageRequired)
      // }

      setIsLoading(true);
      dispatch(
        updateProfile(
          {
            ...values,
            avatar:
              user?.avatarUri !== avatarImage?.uri ? avatarImage?.uri : null,
          },
          {
            onFail: err => {
              setError(err?.message);
              setIsLoading(false);
            },
            onSuccess: () => setIsLoading(false),
          },
        ),
      );
    },
    [user, avatarImage, dispatch],
  );

  return (
    <ContentContainer>
        <ScrollViewOnlyOnMobile>
          <View variant="screenContainer">
            <Formik
              initialValues={initialFormValues}
              validationSchema={getProfileSettingsValidationSchema()}
              onSubmit={updateUserProfile}>
              {({values, handleChange, handleSubmit, errors}) => (
                <View>
                  <FormInput
                    label={i18n.t('profileSettings.nameInputLabel')}
                    value={values.name}
                    onChangeText={handleChange('name')}
                    error={errors.name}
                  />
                  <FormInput
                    label={i18n.t('profileSettings.lastNameInputLabel')}
                    value={values.last_name}
                    onChangeText={handleChange('last_name')}
                    error={errors.last_name}
                  />
                  <FormInput
                    label={i18n.t('profileSettings.emailLabel')}
                    value={values.email}
                    editable={false}
                  />

                  <View variant="sectionContainer">
                    <Text variant="inputLabel">
                      {i18n.t('profileSettings.profileImageLabel')}
                    </Text>
                    <Text variant="formHint">
                      {i18n.t('editCreatorPage.fiveMbLimit')}
                    </Text>
                    {avatarImage?.uri && (
                      <View variant="sectionContainer">
                        <Image
                          variant="profileImage"
                          source={{uri: avatarImage.uri}}
                        />
                      </View>
                    )}
                  </View>

                  <Button
                    title={i18n.t('pickPhoto')}
                    onPress={openPicker}
                    containerStyle={{paddingVertical: 25}}
                  />
                  <TouchableOpacity onPress={() => openPicker({allowsEditing: false})}> 
                    <Text>+ gif</Text>
                  </TouchableOpacity>
                  <Text variant='inputError'>
                      {avatarError}
                  </Text>

                  <View variant="sectionContainer">
                    <Button
                      primary
                      //   disabled={isEqual(values, {
                      //     name: user.name,
                      //     last_name: user.last_name,
                      //   })}
                      title={i18n.t('saveSettings')}
                      onPress={handleSubmit}
                    />
                  </View>

                  <View variant="sectionContainer">
                      <ActionResult 
                        error={error || ""}
                        isLoading={isLoading}
                        {...ActionResultDefaultRenders}
                      />
                  </View>

                  <View variant="sectionContainer">
                    {/* todo: move to platform get method */}
                    {isDesktop() && (
                      <Text variant='thirdTitle'>TV</Text>
                    )}
                    {/* todo: move to Platform module */}
                    <Text variant='thirdTitle'>{getAppVersion()}</Text>
                    <Text variant='thirdTitle'>{isDesktop() ? 'desktop' : Platform.OS}</Text>
                  </View>

                </View>
              )}
            </Formik>
          </View>
        </ScrollViewOnlyOnMobile>
    </ContentContainer>
  );
};
