import {takeEvery} from '@redux-saga/core/effects';
import {createCRUDActionCreators, fetchSaga} from '../store/src/common';
import {postUploadUnauthorizedSubscription} from './unathorized-subscription.api';

export const {uploadUnathorizedSubscription} = createCRUDActionCreators(
  'UnathorizedSubscription',
  '',
);

export function* unathorizedSubscriptionFlow() {
  yield takeEvery(
    uploadUnathorizedSubscription?.toString(),
    fetchSaga(postUploadUnauthorizedSubscription),
  );
}
