import React, {useMemo} from 'react';
import {StyleSheet, TouchableOpacity} from 'react-native';
import {Text, View} from 'components';
import {DEFAULT_AVATAR_URI} from '@sponsor/constants';
import {Image} from '../Image/Image';
import {TCreator} from '../../../types/src/creators';
import {Icon, Row} from '..';
import {colors} from '../../../assets';
import { TParentTranslationField } from '../../../translations/translations.types';
import { TranslationsIconContainer } from '../../../translations/TranslationsIcon.container';
import { i18n } from 'localize';

export const CreatorProfileCard: React.FC<TCreator & any & {
  translations: TParentTranslationField
}> = ({
  title = 'test',
  avatarUri = '',
  description,
  iconColor,
  subtitle,
  verified,
  translations
}) => {
  // const [popoverVisible, togglePopoverVisible] = useToggle(false)

  const ProfileImageComponent = useMemo(() => {
    return (
      <Image
        variant="profileImage"
        source={{
          uri: avatarUri || DEFAULT_AVATAR_URI,
        }}
      />
    );
  }, [avatarUri]);

  const Title = useMemo(() => {
    return (
      <View variant="sectionContainer">
        <Row rowCenter columnCenter>
          <Text center variant="creatorTitle">
            {`${title}`}
          </Text>
          {verified && (
            <>
              <TouchableOpacity>
                <Icon
                  name="verification"
                  size={25}
                  style={{marginLeft: 5}}
                  color={iconColor || colors.GREEN_OFFICIAL}
                />
              </TouchableOpacity>
              {/* <Popover
                isVisible={true}
                  from={(
                    <TouchableOpacity onPress={() => {}}>
                      <Icon
                        name="verification"
                        size={25}
                        // ref={sourceRef}
                        style={{marginLeft: 5}}
                        color={iconColor || colors.GREEN_OFFICIAL}
                      />
                    </TouchableOpacity>
                  )}>
                  <Text>This is the contents of the popover</Text>
              </Popover> */}
            </>
          )}
          {translations && (
            <View
              style={{paddingLeft: 8}}
            >
              <TranslationsIconContainer  
                translations={translations}
                color={iconColor}
                alertMessage={i18n.t("translationsFeature.entityHasTranslationsMessage", 
                {
                  entityName: i18n.t("translationsFeature.entities.creator"),
                  languages: Object.keys(translations).join(', ')
                })}
                alertTitle={i18n.t("translationsFeature.enittyHasTranslationsTitle")}
              />
            </View>
          )}
        </Row>
      </View>
    );
  }, [title, iconColor, verified]);

  const Description = useMemo(() => {
    return <Text variant="creatorSubtitle">{description}</Text>;
  }, [description]);

  return (
    <View style={styles.profileContainer}>
      {ProfileImageComponent}
      {Title}
      {subtitle && (
        <Text numberOfLines={5} center variant="creatorSubtitle">
          {`${subtitle}`}
        </Text>
      )}
      {Description}
      {/* {Boolean(nbSubscriber) === true && (
        <Text
          style={
            styles.nbSubscriberTitle
          }>{`${nbSubscriber}\nпідписників ${}`}</Text>
      )} */}

      {/* <Row containerStyle={styles.socialContainer}>
        <TouchableOpacity>
          <Icon size={socialIconSize} name="youtube" />
        </TouchableOpacity>
        <TouchableOpacity>
          <Icon size={socialIconSize} name="youtube" />
        </TouchableOpacity>
        <TouchableOpacity>
          <Icon size={socialIconSize} name="youtube" />
        </TouchableOpacity>
        <TouchableOpacity>
          <Icon size={socialIconSize} name="youtube" />
        </TouchableOpacity>
      </Row> */}
    </View>
  );
};

const styles = StyleSheet.create({
  profileContainer: {
    borderRadius: 4,
    // borderWidth: 0.2,
    // marginHorizontal: 15,
    // padding: 10,
    // justifyContent:'center',
    alignItems: 'center',
  },
  socialContainer: {
    justifyContent: 'space-around',
  },
  image: {
    width: 222,
    height: 250,
    borderRadius: 25,
  },
});
