import * as Yup from 'yup';
import {i18n} from 'localize';

export const getResetPasswordValidationSchema = () =>
  Yup.object().shape({
    password: Yup.string()
      .min(8, i18n.t('validationSchema.tooShort'))
      .matches(/\d/, i18n.t('validationSchema.minOneDigit'))
      .matches(/[a-zA-Z]/, i18n.t('validationSchema.minOneLetter'))
      .required(i18n.t('validationSchema.requiredField')),
    confirmPassword: Yup.string()
      .min(8, i18n.t('validationSchema.tooShort'))
      .matches(/\d/, i18n.t('validationSchema.minOneDigit'))
      .matches(/[a-zA-Z]/, i18n.t('validationSchema.minOneLetter'))
      .required(i18n.t('validationSchema.requiredField')),
  });
