import React from 'react';
import {Picker as RNPicker} from '@react-native-picker/picker';
import {map} from 'lodash';
import { View } from '../View';
import { isMobile } from 'utils';
import { TPickerProps } from './Picker.types';
import { pickerStyles } from './Picker.styles';
import { textStyles } from '../Text';

export type TDataItem = {
  label: string;
  value: any;
};

export const Picker: React.FC<TPickerProps> = ({
  data,
  style = {},
  containerStyle = {},
  onValueChange,
  enabled = true,
  renderItem,
  testIDPrefix = '',
  emptyValue,
  pickerContentContainerStyles,
  ...otherProps
}) => {
  if(emptyValue){
    data = [
      {
        value: '',
        label: '',
      }, 
      ...data,
    ];
  }

  return (
    <View
      variant='bordered'
      green
      style={[{
        width: '100%',
        padding: isMobile ? 0 : undefined,
      }, 
      pickerStyles.container,
      containerStyle
    ]}
    >
      <RNPicker
        {...otherProps}
        enabled={enabled}
        dropdownIconColor="black"
        style={[
          textStyles.fontFamily,
          !enabled && pickerStyles.disabledContainer,
          pickerStyles.contentContainer,
          pickerStyles.text,
          pickerContentContainerStyles,
          style
        ]}
        onValueChange={(itemValue, itemIndex) => onValueChange(data[itemIndex])}
        >
        {map(data, item =>
          renderItem ? renderItem({item})
           : (
            <RNPicker.Item
              key={item?.value}
              label={item.label}
              value={item?.value}
              style={[
                textStyles.fontFamily,
              ]}
              testID={item.testID || testIDPrefix + item.value}
            />
          ),
        )}
      </RNPicker>
    </View>
  );
};

export const PickerItem = RNPicker.Item;
