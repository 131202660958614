import {i18n} from 'localize';

export const getResponseErrorMessage = (code: number) => {
  console.log('code', code);
  let message;
  switch (code) {
    case 409:
      message = i18n.t('errors.alreadyExists');
      break;
    default:
      message = i18n.t('errors.somethingWrong');
  }

  return message;
};
