import {createSelector, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {takeEvery} from 'redux-saga/effects';
import {filter, isString, omit} from 'lodash';

import {getStore, TStore, TTiersState} from './common';

import {AnalyticsService, ApiService} from 'services';
import {createCRUDActionCreators, fetchSaga} from './common';
import {
  TTierStatus,
  EAnalyticsEventNames,
  TPostChangeAcceptanceStatusOutput,
} from 'types';
import {selectCreatorBasicInfo} from './creator';
import {
  deleteTierTranslationSuccess,
  updateTierTranslationSuccess,
} from '../../tiersTranslations/tiersTranslations.actions';
import {
  TDeleteTiersTranslationsInput,
  TPutUpdateTiersTranslationsOutput,
} from 'app/tiersTranslations/tiersTranslations.types';
import {transformTier} from '../../tiers/tiers.dto';

export const {updateCollaborationStatus, updateCollaborationStatusSuccess} =
  createCRUDActionCreators('CollaborationStatus', 'creator/') || {};

export const {
  uploadTier,
  uploadTierSuccess,
  updateTier,
  updateTierSuccess,
  deleteTier,
  deleteTierSuccess,
} = createCRUDActionCreators('Tier', '');

export const tiersSlice = createSlice({
  name: 'tiers',
  initialState: {},
  extraReducers: {
    [deleteTierTranslationSuccess?.toString?.()]: (
      state: TTiersState,
      action: PayloadAction<TDeleteTiersTranslationsInput>,
    ) => {
      if (action?.payload?.parentId) {
        state[action.payload.parentId].translations = omit(
          state[action.payload.parentId].translations,
          action?.payload?.lang,
        );
      }
    },
    [updateTierTranslationSuccess?.toString?.()]: (
      state: TTiersState,
      action: PayloadAction<TPutUpdateTiersTranslationsOutput['data']>,
    ) => {
      if (action?.payload?.tier) {
        if (!state[action.payload.tier].translations) {
          state[action.payload.tier].translations = {};
        }
        state[action.payload.tier].translations[action?.payload?.lang] =
          action?.payload;
      }
    },
    [updateCollaborationStatusSuccess.toString()]: (
      state: TTiersState,
      action: PayloadAction<TPostChangeAcceptanceStatusOutput['data']>,
    ) => {
      state[action.payload?.id] = {
        ...state[action.payload?.id],
        ...transformTier(action.payload),
      };
    },
    [deleteTierSuccess.toString()]: (state: TTiersState, action) => {
      delete state[action.payload?.id];
    },
    [updateTierSuccess.toString()]: (state: TTiersState, action) => {
      if (isString(action?.payload?.id)) {
        const id = action.payload?.id;
        state[id] = transformTier(action.payload);
      }
    },
    [uploadTierSuccess.toString()]: (state: TTiersState, action) => {
      if (isString(action?.payload?.id)) {
        const id = action.payload?.id;
        state[id] = transformTier(action.payload);
      }
    },
  },
  reducers: {
    setTiersState: (_, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes

      //TODO: remove it, define one schema for state
      return action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {setTiersState} = tiersSlice.actions;

export default tiersSlice.reducer;

// selectors
export const getTiersState = createSelector(getStore, state => state.tiers);
export const selectTiers = createSelector(getStore, state =>
  filter(
    Object.values(state.tiers || {}),
    t => !t?.coCreators || t?.coCreators?.length === 0,
  ),
);

export const selectCollaborationTiers = createSelector(
  getStore, 
  state =>
  filter(
    Object.values(state.tiers || {}) || [],
    t => !!t?.coCreators && t?.coCreators?.length > 0,
  ),
);

export const selectExternalCollaborationsWaitingAcceptanceCount =
  createSelector(
    [selectCollaborationTiers, state => selectCreatorBasicInfo(state)],
    (tiers, creator) =>
      filter(
        tiers,
        t =>
          t.status === TTierStatus.waiting_acceptance &&
          t.creator !== creator?.id,
      )?.length || 0,
  );

export const selectTierById = createSelector(
  [getTiersState, (state: TStore, tierId: string): string => tierId],
  (tiersState, tierId) => {
    return tiersState[tierId];
  },
);

export function* tiersFlow() {
  //todo: fix typing
  yield takeEvery(
    uploadTier.toString(),
    fetchSaga(ApiService.postAddNewTier, {
      analytics: {
        logEvent: event => AnalyticsService.logEvent(event),
        onSuccessEvent: EAnalyticsEventNames.TIER_UPLOAD_SUCCEEDED,
        onFailedEvent: EAnalyticsEventNames.TIER_UPLOAD_FAILED,
      },
    }),
  );
  yield takeEvery(
    updateTier.toString(),
    fetchSaga(ApiService.updateTier, {
      analytics: {
        logEvent: event => AnalyticsService.logEvent(event),
        onSuccessEvent: EAnalyticsEventNames.TIER_UPDATE_SUCCEEDED,
        onFailedEvent: EAnalyticsEventNames.TIER_UPLOAD_FAILED,
      },
    }),
  );
  yield takeEvery(
    deleteTier.toString(),
    fetchSaga(ApiService.deleteTier, {
      analytics: {
        logEvent: event => AnalyticsService.logEvent(event),
        onSuccessEvent: EAnalyticsEventNames.TIER_REMOVAL_SUCCEEDED,
        onFailedEvent: EAnalyticsEventNames.TIER_REMOVAL_FAILED,
      },
    }),
  );

  yield takeEvery(
    updateCollaborationStatus.toString(),
    fetchSaga(ApiService.changeCollaborationStatus),
  );
}
