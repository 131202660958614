import React from 'react';
import {View} from 'react-native';
import {useDropzone} from 'react-dropzone';

type TProps = {
  onDrop?: (files: any[]) => void;
  children?: any;
};

export const DraggableArea: React.FC<TProps> = ({onDrop, children}) => {
  const {getRootProps, getInputProps} = useDropzone({
    preventDropOnDocument: true,
    onDrop,
  });

  return (
    //@ts-ignore
    <View {...getRootProps()}>
      <input {...getInputProps()} />
      {children}
    </View>
  );
};
