import {Button, Column, Row, Text} from 'components';
import React, {useCallback} from 'react';
import {View} from 'react-native';
import {useSelector} from 'react-redux';
import {i18n} from 'localize';
import {selectCreatorBasicInfo} from 'store';

export function PayoutsPage() {
  const creator = useSelector(selectCreatorBasicInfo);

  const withdraw = useCallback(() => {}, []);

  return (
    // TODO: add layout component
    <Row>
      <Column flex={1} />
      <Column flex={3}>
        <Row containerStyle={{justifyContent: 'space-between'}}>
          <View>
            <Text>{i18n.t('payoutsPage.accountBalance')}</Text>
            <Text variant="sectionTitle">
              {String(creator.balance?.amount || 0)}
            </Text>
          </View>
          <View>
            <Button
              title={i18n.t('payoutsPage.withdrawBtnTitle')}
              onPress={withdraw}
            />
          </View>
        </Row>
      </Column>
      <Column flex={1} />
    </Row>
  );
}
