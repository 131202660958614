import { Linking } from "react-native"

const openMail = (params: {
    subject: string,
    to: string,
    body?: string
}) => {
    Linking.openURL(`mailto:${params.to}?subject="${params.subject}"&body=${params.body}`)
}

export {
    openMail
}