import React, {useCallback, useMemo} from 'react';
import {findIndex} from 'lodash';
import {useDispatch, useSelector} from 'react-redux';
import {unsubscribeCreator, selectUser, subscribeCreator} from 'store';
import {OnlyAuthorizedButton} from '../../../components';
import {i18n} from 'localize';

type TProps = {
  creatorId: string;
  color: string;
  testID?: string,
};

export const FollowButton: React.FC<TProps> = ({testID, creatorId, color}) => {
  const dispatch = useDispatch();

  const followCreator = useCallback(() => {
    dispatch(
      subscribeCreator(
        {
          creatorId,
        },
        {
          onFinish: () => {},
        },
      ),
    );
  }, [dispatch, creatorId]);

  const unfollowCreator = useCallback(() => {
    dispatch(
      unsubscribeCreator(
        {
          creatorId,
        },
        {
          onFinish: () => {},
        },
      ),
    );
  }, [dispatch, creatorId]);

  const user = useSelector(selectUser);

  // TODO: check this functions
  const isFollowing = useMemo(() => {
    return findIndex(user?.followings, s => s?.id === creatorId) > -1;
  }, [creatorId, user]);

  return (
    <OnlyAuthorizedButton
      primary
      testID={testID}
      color={color}
      title={isFollowing ? i18n.t('unfollow') : i18n.t('follow')}
      onPress={isFollowing ? unfollowCreator : followCreator}
    />
  );
};
