import styled from 'styled-components/native';

import {responsiveSize} from '../../../../utils/font';

export const Container = styled.ImageBackground`
  position: relative;
  padding: ${responsiveSize(234)} ${responsiveSize(150)};
  flex-direction: row;
  align-items: center;

  ${props =>
    props.media.lg &&
    `
    flex-direction: column;
    padding: ${responsiveSize(170)} ${responsiveSize(60)} ${responsiveSize(
      140,
    )};
  `};

  ${props =>
    props.media.md &&
    `
    padding: ${responsiveSize(240)} ${responsiveSize(60)} ${responsiveSize(
      110,
    )};
  `};

  ${props =>
    props.media.sm &&
    `
    flex-direction: column;
    padding: ${responsiveSize(260)} ${responsiveSize(16)} ${responsiveSize(90)};
  `};
`;

export const Image = styled.Image`
  height: ${responsiveSize(549)};
  width: ${responsiveSize(507)};

  ${props =>
    props.media.lg &&
    `
    margin-bottom: ${responsiveSize(24)};
    height: ${responsiveSize(549 / 1.5)};
    width: ${responsiveSize(507 / 1.5)};
  `};

  ${props =>
    props.media.md &&
    `
    height: ${responsiveSize(549 / 1.1)};
    width: ${responsiveSize(507 / 1.1)};
    max-width: 100%;
  `};

  ${props =>
    props.media.sm &&
    `
    height: ${responsiveSize(549 / 1.6)};
    width: ${responsiveSize(507 / 1.6)};
  `};
`;

export const Content = styled.View`
  align-items: flex-start;
  margin-left: ${responsiveSize(100)};
  flex: 1;

  ${props =>
    props.media.lg &&
    `
    flex: none;
    align-items: center;
    margin-left: ${responsiveSize(70)};
  `};

  ${props =>
    props.media.md &&
    `
    margin-left: ${responsiveSize(30)};
    align-items: center;
  `};

  ${props =>
    props.media.sm &&
    `
    margin-left: ${responsiveSize(0)};
    background-color: rgba(0,0,0,0.35);
  `}
`;

export const Title = styled.Text`
  margin-bottom: ${responsiveSize(24)};

  font-weight: bold;
  font-size: ${responsiveSize(45)};
  line-height: ${responsiveSize(79)};
  letter-spacing: 0.03em;
  text-transform: uppercase;
  color: #ffffff;
  font-family: inherit;

  ${props =>
    props.media.lg &&
    `
    max-width: ${responsiveSize(700)};

    font-size: ${responsiveSize(45 / 1.4)};
    line-height: ${responsiveSize(79 / 1.4)};
    text-align: center;
  `};

  ${props =>
    props.media.md &&
    `
    font-size: ${responsiveSize(51 / 1.2)};
    line-height: ${responsiveSize(79 / 1.2)};
  `};

  ${props =>
    props.media.sm &&
    `
    margin-bottom: ${responsiveSize(16)};

    font-size: ${responsiveSize(51 / 2)};
    line-height: ${responsiveSize(79 / 2)};
  `};
`;

export const Description = styled.Text`

  font-size: ${responsiveSize(22)};
  line-height: ${responsiveSize(33)};
  letter-spacing: 0.03em;
  text-transform: uppercase;
  color: #ffffff;
  font-family: inherit;

  ${props =>
    props.media.md &&
    `
    font-size: ${responsiveSize(22 / 1.2)};
    line-height: ${responsiveSize(33 / 1.2)};
    text-align: center;
  `};

  ${props =>
    props.media.sm &&
    `
    font-size: ${responsiveSize(22 / 1.6)};
    line-height: ${responsiveSize(33 / 1.6)};
    
  `};
`;
