import {View, StyleSheet, FlatList} from 'react-native';
import React, {useCallback} from 'react';
import {
  CreatorBackgroundImage,
  Column,
  Row,
  Tier,
  CreatorProfileCard,
  Button,
  Text,
  DraggableArea,
} from 'components';
import {useDispatch, useSelector} from 'react-redux';
import {EscreenNames} from 'navigation';
import FormData from 'form-data';
// import { posts } from '../../fixtures/posts'
import {i18n} from 'localize';
import {selectCreatorBasicInfo, updateCreatorStart} from 'store';
import {useNavigation} from 'hooks';
import {ApiService} from 'services';
import {selectTiers} from 'store';

export const MyPage = () => {
  const creator = useSelector(selectCreatorBasicInfo);
  const tiers = useSelector(selectTiers);

  const dispatch = useDispatch();
  const {navigate} = useNavigation();

  const onDrop = useCallback(async acceptedFiles => {
    const inputData = new FormData();
    inputData.append('backgroundImage', acceptedFiles[0]);

    ApiService.updateCreator({
      backgroundImage: inputData,
    });
    // dispatch(updateCreatorStart({
    //     'backgroundImage': {
    //         type: acceptedFiles[0].type,
    //         name: acceptedFiles[0].name,
    //         uri: acceptedFiles[0].path,
    //     }
    // }, {
    //     onSuccess: () => {},
    //     onFail: () => {},
    // }))
  }, []);

  const updateProfileImage = useCallback(
    acceptedFiles => {
      dispatch(
        updateCreatorStart(
          {
            avatar: acceptedFiles[0],
          },
          {
            onSuccess: () => {},
            onFail: () => {},
          },
        ),
      );
    },
    [dispatch],
  );

  const openTierEditScreen = useCallback(() => {
    navigate(EscreenNames.TierEdit);
  }, [navigate]);

  return (
    <View style={styles.container}>
      <DraggableArea onDrop={onDrop}>
        <CreatorBackgroundImage
          backgroundImage={{uri: creator?.backgroundImageUri}}
        />
      </DraggableArea>
      <Text style={{position: 'absolute', top: 25, left: 25}}>
        {i18n.t('myCreatorPage.startDragging')}
      </Text>
      <Row>
        <Column containerStyle={{padding: 12}} flex={1}>
          <CreatorProfileCard
            editMode
            title={creator?.title}
            description={creator?.description}
            onProfileImageUpdate={updateProfileImage}
            avatarUri={creator?.avatarUri}
          />
        </Column>
        <Column
          flex={2.5}
          containerStyle={{paddingHorizontal: 15, paddingVertical: 12}}>
          {/* <AboutAuthor editMode /> */}
          {/* <FlatList
            data={posts as TPost[]}
            renderItem={({item: post, index}) => {
              return (
                <>
                  <Post post={post} />
                  <CommentList />
                </>
              );
            }}
          /> */}
        </Column>
        <Column containerStyle={{padding: 12}} flex={1}>
          <Row containerStyle={{justifyContent: 'space-between'}}>
            <Text variant="sectionTitle">
              {i18n.t('myCreatorPage.subscriptions')}
            </Text>
            <Button
              title="+"
              containerStyle={{width: 'auto'}}
              onPress={openTierEditScreen}
              primary
            />
          </Row>
          <FlatList data={tiers} renderItem={({item}) => <Tier {...item} />} />
        </Column>
      </Row>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
});
