import { TouchableOpacity } from 'react-native'
import React from 'react'
import { Icon } from '../../components/src/Icon'
import { Row } from '../../components/src/Row'
import { useNavigation } from 'hooks'

export const BackHeader = () => {

    const {goBack} = useNavigation()

    return (
        <Row>
            <TouchableOpacity onPress={goBack}>
                <Icon 
                    name="arrow-left"
                    size={25}
                />
            </TouchableOpacity>
        </Row>
    )
}

