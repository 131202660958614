import React from 'react';
import {ScrollView} from 'react-native';
import {Text, View, CreatorCardMinimized} from 'components';
import {i18n} from 'localize';
import {map} from 'lodash';
import {useNavigation} from 'hooks';
import {EscreenNames} from 'navigation';
import {TCreatorFriend} from 'types';

type TProps = {
  friends: TCreatorFriend[];
};

export const Friends: React.FC<TProps> = ({friends}) => {
  const {navigate} = useNavigation();

  return (
    <View style={{width: '100%'}}>
      <Text variant="secondaryTitle">{i18n.t('friends')}</Text>
      <ScrollView
        pagingEnabled
        contentContainerStyle={{paddingBottom: 15}}
        horizontal
        showsHorizontalScrollIndicator>
        {map(friends, c => (
          <View style={{marginLeft: 10}}>
            <CreatorCardMinimized
              creatorTitle={c?.title}
              creatorPageURL={c?.pageURL}
              creatorAvatarUri={c?.avatarUri}
              onPress={() =>
                navigate(
                  EscreenNames.CreatorPublicPage,
                  {
                    pageURL: c?.pageURL,
                  },
                  {
                    newTab: true,
                    urlParam: true,
                  },
                )
              }
              {...c}
            />
          </View>
        ))}
      </ScrollView>
    </View>
  );
};
