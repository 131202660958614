import React from 'react';

import {Button} from '../../../../components';

import * as S from './home-subscriptions.styled';

import useMedia from '../../../../hook/media';

import mask from '../../../../images/home/mask.jpg';
// import subscriptionBackground from '../../../../images/home/subscriptions.webp';
import subscriptionBackground from '../../../../images/home/carpathian.webp'
import {i18n} from 'localize';
import {useNavigation} from 'hooks';
import {EscreenNames} from 'navigation';
import {Config} from 'libs';
import {Linking, TouchableOpacity} from 'react-native';
import {Text, View} from 'components';
import { isDesktop } from '../../../../../../../utils/platform';
import buildSubscription from '../../../../images/subscribe.webp';

export default function HomeSubscriptionsView() {
  const media = useMedia();

  const {navigate} = useNavigation();

  return (
    <S.Container
      // source={media.lg ? mask : subscriptionBackground}
      source={subscriptionBackground}
      resizeMode="cover"
      media={media}>
      <S.Image
        // source={require('./pic.jpeg')}
        source={isDesktop() ? 
          buildSubscription
          : {
            uri: `${Config.STATIC_IMAGES_URL}/landing/subscribe.webp`,
          }
        }
        resizeMode="contain"
        media={media}
      />

      <S.Content media={media}>
        <S.Title media={media}>{i18n.t('landing.raiseFunds')}</S.Title>
        {/*
        <S.Description media={media}>
          {i18n.t('landing.supportUkraine')}
        </S.Description> */}
        <View
          style={!media?.sm ? {backgroundColor: 'rgba(0,0,0,0.4)'} : {}}
        >
          <S.Description media={media}>
            {i18n.t('landing.postUniqueContent')}{'\n'}
            {i18n.t('landing.paymentsOnBankAccount')}
          </S.Description>
          {/* <S.Description media={media}>
            {i18n.t('landing.paymentsOnBankAccount')}
          </S.Description> */}
        </View>

        {<Button
          v={media}
          title={i18n.t('landing.createPaidSubscriptionBtn')}
          theme="green"
          onPress={() => navigate(EscreenNames.Signup)}
          size="big"
        />}

        <TouchableOpacity
          style={{marginTop: 15}}
          onPress={() => {
            Linking.openURL(
              'https://bank.gov.ua/en/news/all/natsionalniy-bank-vidkriv-spetsrahunok-dlya-zboru-koshtiv-na-potrebi-armiyi',
            );
          }}>
          <Text style={{color: 'white', fontSize: 20}}>
            {`🇺🇦 ${i18n.t('landing.supportUkraineBtnTitle')}`}
          </Text>
        </TouchableOpacity>
      </S.Content>
    </S.Container>
  );
}
